import React from "react"
import {
  ClinicalFacility,
  Maybe,
  OriginatorEnum,
  SupplierLegalEntity,
  User,
} from "../../../../../../../graphql/__generated__/graphql"
import formatDate from "date-fns/format"

interface Props {
  clinicalFacility: Maybe<ClinicalFacility> | undefined
  creator: Maybe<User> | undefined
  supplier: Maybe<SupplierLegalEntity> | undefined
  orderDate: string
  originatorType: OriginatorEnum
}

export const CreatorDetails = ({
  clinicalFacility,
  creator,
  supplier,
  orderDate,
  originatorType,
}: Props) => {
  const creatorName = `${creator?.firstName} ${creator?.lastName}`
  const supplierGeneratedOrder = originatorType === OriginatorEnum.Csr
  const showSupplierName = supplierGeneratedOrder && supplier

  return (
    <>
      {creator && (
        <p>
          {creatorName} {showSupplierName && `(${supplier.name})`}
        </p>
      )}
      {clinicalFacility && (
        <p>
          {supplierGeneratedOrder && <span className="italic">for </span>}
          {clinicalFacility.name}
        </p>
      )}
      <p>{formatDate(orderDate, "MM/DD/YYYY")}</p>
    </>
  )
}
