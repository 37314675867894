import React from "react"
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core"
import { CatalogSeed } from "../../../../types/sharedTypes"
import { pluralize } from "../../../../../../utilities/string"
import CmsDrawer from "../../../../components/CmsDrawer"
import ArchivedLabel from "../ArchivedTooltip"
import StyledTable from "../../../../components/StyledTable"

export type SeedPackagesDrawerProps = {
  catalogSeed: CatalogSeed
  open: boolean
  onClose(): void
}

const SeedPackagesDrawer = ({
  open,
  onClose,
  catalogSeed,
}: SeedPackagesDrawerProps) => {
  return (
    <CmsDrawer
      title={`${catalogSeed.name} Seed: Packages`}
      onClose={onClose}
      open={open}
    >
      <>
        <div className="d-flex justify-content-space-between canopy-mbs-4x canopy-mbe-8x">
          <div>
            <div className="canopy-typography-heading-medium canopy-mbe-4x">
              Seed Packages
            </div>
            <Typography>
              This seed contains{"  "}
              <span className="font-weight-bold">
                {`${catalogSeed.packages!.length} ${pluralize(
                  "package",
                  catalogSeed.packages!.length
                )}`}
              </span>
            </Typography>
          </div>
        </div>
        {catalogSeed.packages && (
          <StyledTable>
            <Table data-testid="seed-packages-table" size="small">
              <TableHead>
                <TableRow>
                  <TableCell component="th" scope="col">
                    Package
                  </TableCell>
                  <TableCell component="th" scope="col">
                    Package ID
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {catalogSeed.packages.map((catalogPackage) => (
                  <TableRow key={catalogPackage.id}>
                    <TableCell>
                      <a href={catalogPackage.url}>{catalogPackage.name}</a>
                      {catalogPackage.archivedAt && (
                        <ArchivedLabel message="This global Package is archived and will not be added during seed import" />
                      )}
                    </TableCell>
                    <TableCell>{catalogPackage.externalId}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </StyledTable>
        )}
      </>
    </CmsDrawer>
  )
}
export default SeedPackagesDrawer
