import React from "react"
import PaginationButton from "components/PaginationButton/index"
import * as styles from "./index.module.scss"

interface Props {
  onClick: () => void
  disabled: boolean
}

const ViewMoreButton: React.FC<Props> = ({ onClick, disabled }) => {
  return (
    <div className={`text-center ${styles.viewMoreButton}`}>
      <PaginationButton
        onClick={onClick}
        loading={disabled}
        loadingText="Loading More Suppliers"
      >
        Load More Suppliers
      </PaginationButton>
    </div>
  )
}

export default ViewMoreButton
