import React, { useEffect, useState } from "react"
import { CanopyButton } from "@parachutehealth/canopy-button"
import * as styles from "./index.module.scss"
import NotificationDropdownMenu from "applications/NotificationDropdown/NotificationDropdownMenu"
import { fetchNotificationEvents } from "applications/NotificationDropdown/api"

const NotificationDropdown: React.FC = () => {
  const [showDropdown, setShowDropdown] = useState(false)
  const [events, setEvents] = useState([])
  const [loading, setLoading] = useState(false)
  useEffect(() => {
    setLoading(true)
    fetchNotificationEvents()
      .then(({ data }) => {
        setEvents(data)
      })
      .finally(() => setLoading(false))
  }, [])

  const badgeContent = events.length > 99 ? "99+" : events.length

  return (
    <div style={{ position: "relative" }} className="dropdown">
      <CanopyButton
        accessibleText="Notifications"
        data-analytics="notification_dropdown"
        data-analytics-replay-event
        data-toggle="dropdown"
        iconStart="bell"
        onClick={() => setShowDropdown(!showDropdown)}
        size="small"
        type="button"
        variant="tertiary"
      />
      {events.length > 0 && (
        <span className={styles.badge}>{badgeContent}</span>
      )}
      <NotificationDropdownMenu
        events={events}
        loading={loading}
        show={showDropdown}
      />
    </div>
  )
}

export default NotificationDropdown
