import React from "react"
import { NewOrderFormPatient } from "../sharedTypes"
import FacilitySelectForm from "applications/FacilitySelectForm"
import NewPatientForm from "./NewPatientForm"
import PatientOrderButton from "./PatientOrderButton"
import { format } from "utilities/date"
import { ContextEmployer, EmployerType } from "sharedTypes"
import { ChooseSupplierModal } from "components/SupplierOrganizationChooseSupplierModal"
import { Supplier } from "components/SupplierOrganizationChooseSupplierModal/api"

type Props = {
  employer?: ContextEmployer
  patient?: NewOrderFormPatient
  setPatient(patient: NewOrderFormPatient): void
  patientValidate(patient: NewOrderFormPatient): Promise<{}>
  patientSearch?: {
    firstName: string
    lastName: string
    dateOfBirth: string
    sex: string
  }
  facilitySubmitButtonText: string
  facilitySubmit(
    clinicalFacilityId,
    doctorId,
    selectedLegalEntityId,
    selectedExternalSystemBranchId,
    selectedSalesRepId,
    legalEntityAssignmentMethod
  ): Promise<void>
  documentFacility: { id: string; name: string }
  openModal(): void
  closeModal(): void
  showModal: boolean
  newPatient: boolean
  supplierId: string
  suppliers: Supplier[]
  setSupplierId(supplierId: string): void
}

function InternalNewOrderForm({
  employer,
  patient,
  setPatient,
  patientValidate,
  patientSearch,
  documentFacility,
  facilitySubmitButtonText,
  facilitySubmit,
  openModal,
  closeModal,
  showModal,
  newPatient,
  supplierId,
  suppliers,
  setSupplierId,
}: Props) {
  const needsSupplier = () => {
    return (
      employer?.employerType === EmployerType.SupplierOrganization &&
      supplierId === ""
    )
  }

  return (
    <>
      <PatientOrderButton
        openModal={openModal}
        newPatient={!patient || newPatient}
      />
      {!patient && (
        <NewPatientForm
          showModal={showModal}
          closeModal={closeModal}
          setPatient={setPatient}
          patientSearch={patientSearch}
          patientValidate={patientValidate}
        />
      )}
      {needsSupplier() && suppliers && showModal && (
        <ChooseSupplierModal
          suppliers={suppliers}
          show={showModal}
          close={closeModal}
          onSubmit={setSupplierId}
        />
      )}
      {!needsSupplier() && patient && (
        <FacilitySelectForm
          employer={employer}
          showModal={showModal}
          closeModal={closeModal}
          documentFacility={documentFacility}
          modalTitle={`New Order for ${patient.firstName} ${
            patient.lastName
          } ${format(patient.dateOfBirth)}`}
          submit={facilitySubmit}
          submitButtonText={facilitySubmitButtonText}
        />
      )}
    </>
  )
}

export default InternalNewOrderForm
