import React from "react"

interface Props {
  children: Element | string
}

function Callout({ children }: Props) {
  return (
    <span className="canopy-mis-8x float-right uppercase color-brand bold">
      {children}
    </span>
  )
}

export default Callout
