import { InsurancePolicy } from "sharedTypes"
import React from "react"

type Props = {
  insurancePolicies: InsurancePolicy[]
}

const PaymentCard = (props: Props) => {
  const { insurancePolicies } = props
  const primaryPolicy = insurancePolicies.find((p) => p.rank === "primary")
  const secondaryPolicy = insurancePolicies.find((p) => p.rank === "secondary")
  const tertiaryPolicy = insurancePolicies.find((p) => p.rank === "tertiary")

  const policyInfo = (policy) => {
    if (policy) {
      return `${policy.carrierName}, ${policy.memberNumber}`
    }
  }

  return (
    <div>
      <span className="bold color-black">Payment</span>
      <ol>
        <li className="ml-n3">{policyInfo(primaryPolicy)}</li>
        {secondaryPolicy && (
          <li className="ml-n3"> {policyInfo(secondaryPolicy)} </li>
        )}
        {tertiaryPolicy && (
          <li className="ml-n3"> {policyInfo(tertiaryPolicy)} </li>
        )}
      </ol>
    </div>
  )
}

export default PaymentCard
