import React, { useState } from "react"
import { FulfillmentAgreement, SupplierFilter } from "../../sharedTypes"
import Table from "components/Table"
import ActivityManagementButton from "./ActivityManagementButton"
import PinnedManagementCheckbox from "./PinnedManagementCheckbox"
import SupplierName from "./SupplierName"
import orderBy from "lodash/orderBy"

interface Props {
  fulfillmentAgreements: FulfillmentAgreement[]
  pinnedSupplierIds: string[]
  updateFulfillmentAgreements: (params: {
    id: string
    active: boolean
  }) => Promise<void>
  createPinnedSupplier: (supplierId: string) => Promise<void>
  destroyPinnedSupplier: (supplierId: string) => Promise<void>
  supplierFilter: SupplierFilter
}

const FulfillmentAgreementsTable: React.FC<Props> = ({
  createPinnedSupplier,
  destroyPinnedSupplier,
  updateFulfillmentAgreements,
  fulfillmentAgreements,
  pinnedSupplierIds,
  supplierFilter,
}) => {
  const [pinnedSupplierIdsData, setPinnedSupplierIdsData] = useState(
    pinnedSupplierIds
  )

  const updatePinnedSupplier = (supplierId: string, value: boolean) => {
    if (value) {
      createPinnedSupplier(supplierId).then(() =>
        setPinnedSupplierIdsData([...pinnedSupplierIdsData, supplierId])
      )
    } else {
      destroyPinnedSupplier(supplierId).then(() =>
        setPinnedSupplierIdsData(
          pinnedSupplierIdsData.filter((id) => id !== supplierId)
        )
      )
    }
  }

  const filterBySupplierFilter = (
    fulfillmentAgreement: FulfillmentAgreement
  ): boolean =>
    supplierFilter === SupplierFilter.Active
      ? fulfillmentAgreement.active
      : !fulfillmentAgreement.active

  const tableData = fulfillmentAgreements
    .filter(filterBySupplierFilter)
    .map((fulfillmentAgreement) => ({
      ...fulfillmentAgreement,
      pinned: pinnedSupplierIdsData.includes(
        fulfillmentAgreement.supplier.externalId
      ),
      key: fulfillmentAgreement.supplier.externalId,
    }))

  const sortedTableData = orderBy(
    tableData,
    supplierFilter === SupplierFilter.Active
      ? ["supplier.name"]
      : ["recentOrdersCount", "supplier.name"],
    supplierFilter === SupplierFilter.Active ? ["asc"] : ["desc", "asc"]
  )

  const tableColumns = [
    {
      title: "Supplier Name",
      attr: "supplier.name",
      render: ({ newAgreement, supplier }) => (
        <SupplierName name={supplier.name} newAgreement={newAgreement} />
      ),
    },
    {
      title: "Address",
      attr: "supplier.address",
      render: ({ supplier }) => supplier.billingAddress,
    },
    {
      title: "NPI",
      attr: "supplier.npi",
      render: ({ supplier }) => supplier.npi,
    },
    {
      title: "Description",
      attr: "supplier.description",
      render: ({ supplier }) => supplier.description,
    },
    {
      title: "Recent Orders (last 30 days)",
      attr: "recentOrdersCount",
      render: ({ recentOrdersCount }) => recentOrdersCount,
    },
    {
      title: "Pinned",
      attr: "pinned",
      render: ({ pinned, supplier, active }) =>
        active && (
          <PinnedManagementCheckbox
            pinned={pinned}
            onClick={() => updatePinnedSupplier(supplier.externalId, !pinned)}
          />
        ),
    },
    {
      title: "Manage",
      attr: "active",
      render: ({ id, active }) => {
        return (
          <ActivityManagementButton
            active={active}
            onClick={() => updateFulfillmentAgreements({ id, active: !active })}
          />
        )
      },
    },
  ]

  return (
    <Table
      records={sortedTableData}
      tableColumns={tableColumns}
      truncate={false}
      maxHeight="100%"
    />
  )
}

export default FulfillmentAgreementsTable
