export function navigate(location) {
  window.location = location
}

export function reload() {
  window.location.reload()
}

export function openNewTab(url) {
  window.open(url)
}
