import React from "react"
import { Supplier } from "../sharedTypes"
import SelectFilter from "components/SelectFilter"

interface Props {
  suppliers: Supplier[]
  fetchOptions(term: string): Promise<{ label: string; value: string }[]>
  initialSupplierIds: string[]
  onChange(values: any): void
}

function SupplierSelect({
  suppliers,
  fetchOptions,
  initialSupplierIds,
  onChange,
}: Props) {
  const options = suppliers.map((supplier) => {
    return { label: supplier.name, value: supplier.externalId }
  })

  return (
    <SelectFilter
      name="supplierIds"
      label="Suppliers"
      initialValues={initialSupplierIds}
      options={options}
      fetchOptions={fetchOptions}
      onChange={onChange}
      minLength={0}
      enableReinitialize
    />
  )
}

export default SupplierSelect
