import { employerPrefix } from "utilities/url"

export function ordersDashboardUrl(): string {
  return `${employerPrefix()}/dashboard`
}

export function signatureRequestsDashboardUrl(): string {
  return `${employerPrefix()}/documentation_orders`
}

export function inboundDocumentsDashboardUrl(): string {
  return `${employerPrefix()}/inbox`
}

export function carrierAuthorizationsDashboardUrl(): string {
  return `${employerPrefix()}/authorizations`
}

export function initialLeftSidebarDataUrl(): string {
  return `${employerPrefix()}/initial_left_sidebar_data.json`
}
