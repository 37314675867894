import React from "react"
import Modal from "components/Modal"
import DenyOrderForm from "./DenyOrderForm"
import { SelectOption } from "components/form/sharedTypes"

type Props = {
  show: boolean
  close(): void
  deny(params: { reason: string; description: string }): Promise<void>
  denialReasons: SelectOption[]
}

const DenyOrderModal: React.FunctionComponent<Props> = (props) => {
  const { show, close, denialReasons } = props

  const deny = (params, { setSubmitting, setErrors }) => {
    props.deny(params).catch((errors) => {
      setErrors(errors)
      setSubmitting(false)
    })
  }

  const initialValues = { reason: "", description: "" }

  const validate = ({ reason }) => {
    if (!reason) {
      return { reason: "Please select a reason" }
    }
  }

  return (
    <Modal size="md" title="Deny Order" show={show} cancel={close}>
      <Modal.Body>
        <DenyOrderForm
          close={close}
          onSubmit={deny}
          initialValues={initialValues}
          denialReasons={denialReasons}
          validate={validate}
        />
      </Modal.Body>
    </Modal>
  )
}

export default DenyOrderModal
