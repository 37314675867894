// team @destroythefax
import React, { FC } from "react"
import * as styles from "applications/Workflow/containers/Navigation/components/OrderActionsDropdown/OrderActionsDropdown.module.scss"
import { CanopyIcon } from "@parachutehealth/canopy-icon"
import { CanopyIconNames } from "@parachutehealth/canopy-icons/build/canopyIconGlyphNames"
import {
  canopyColorTextDanger,
  canopyColorTextPrimary,
} from "@parachutehealth/canopy-tokens-color"

interface Props {
  iconName: CanopyIconNames
  title: string
  subtitle: string
  danger?: boolean
  onClick(): void
}

const OrderActionsDropdownButton: FC<Props> = ({
  iconName,
  onClick,
  title,
  subtitle,
  danger = false,
}) => {
  return (
    <div className={styles.orderAction} onClick={onClick}>
      <div
        className="d-flex"
        style={{
          color: danger ? canopyColorTextDanger : canopyColorTextPrimary,
        }}
      >
        <CanopyIcon name={iconName} className="canopy-mie-4x" />
        <span className={styles.title}>{title}</span>
      </div>
      <span className={styles.subtitle}>{subtitle}</span>
    </div>
  )
}

export default OrderActionsDropdownButton
