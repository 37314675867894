// @ts-strict-ignore
import React, { Reducer, useEffect, useReducer, useRef, useState } from "react"
import DescriptionIcon from "@material-ui/icons/Description"
import { Popover, Typography } from "@material-ui/core"
import SpinIcon from "components/Icon/SpinIcon"
import { theme } from "themes/theme"
import * as styles from "./index.module.scss"

const LOADING_STATE = { loading: true, url: null, error: false }
type SUCCESS_STATE = { loading: false; url: string; error: false }
const ERROR_STATE = { loading: false, url: null, error: true }
type State = typeof LOADING_STATE | SUCCESS_STATE | typeof ERROR_STATE

type FETCH_URL_SUCCEEDED = { type: "FETCH_URL_SUCCEEDED"; url: string }
type FETCH_URL_FAILED = { type: "FETCH_URL_FAILED" }
type Action = FETCH_URL_SUCCEEDED | FETCH_URL_FAILED

const reducer: Reducer<State, Action> = (state: State, action: Action) => {
  if (action.type === "FETCH_URL_SUCCEEDED")
    return { loading: false, url: action.url, error: false }
  return ERROR_STATE
}

export const FilePreviewer: React.FC<{
  fetchFileUrl: () => Promise<string>
}> = (props) => {
  const anchorEl = useRef()
  const [dataState, dispatch] = useReducer(reducer, LOADING_STATE)
  const [popoverOpen, setPopoverOpen] = useState(false)
  useEffect(() => {
    if (dataState.loading && popoverOpen) {
      props
        .fetchFileUrl()
        .then((url) => {
          dispatch({ type: "FETCH_URL_SUCCEEDED", url })
        })
        .catch(() => {
          dispatch({ type: "FETCH_URL_FAILED" })
        })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataState.loading, popoverOpen])

  const openPopover = () => setPopoverOpen(true)
  const closePopover = () => setPopoverOpen(false)
  return (
    <>
      <DescriptionIcon
        aria-owns={popoverOpen ? "document-preview" : undefined}
        aria-haspopup="true"
        ref={anchorEl}
        onMouseEnter={openPopover}
        onMouseLeave={closePopover}
        aria-label="document preview"
        style={{ color: theme.colors.gray.mid }}
      />
      <Popover
        className={styles.docContent}
        id="document-preview"
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        open={popoverOpen}
        anchorEl={anchorEl.current}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        onClose={closePopover}
        disableRestoreFocus
      >
        <div className={styles.contents}>
          {dataState.loading && <SpinIcon />}
          {dataState.url && (
            <img
              src={dataState.url}
              className={styles.docImage}
              alt="first page preview"
            />
          )}
          {dataState.error && (
            <Typography variant="h4">
              Unable to load document preview
            </Typography>
          )}
        </div>
      </Popover>
    </>
  )
}
