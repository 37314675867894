import React from "react"

type Props = {
  newPatient: boolean
  openModal(): void
}

export default function PatientOrderButton({ newPatient, openModal }: Props) {
  return newPatient ? (
    <button
      className="btn btn-primary btn-block canopy-mbs-12x"
      onClick={openModal}
    >
      Create New Patient
    </button>
  ) : (
    <button className="btn btn-sm btn-primary" onClick={openModal}>
      Create New Order
    </button>
  )
}
