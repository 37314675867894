import React, { useState } from "react"
import Modal from "components/Modal"
import * as api from "./api"
import {
  SendBackRecipient,
  RejectFormValues,
  SelectedRecipient,
  FacilityOption,
} from "./sharedTypes"
import SupplierRejectFormSection from "./SupplierRejectFormSection"
import { navigate } from "utilities/navigation"
import { workflowReviewUrl } from "applications/Workflow/urls"
import withInitialData from "components/withInitialData"

export type InitialData = {
  defaultFacility: FacilityOption
  defaultFacilityRecipient: SendBackRecipient
  selectedRecipients: SelectedRecipient[]
}

type Props = {
  cancel(): void
  initialData: InitialData
}

export type GroupedSendBackRecipients = {
  label: string | React.ReactNode
  options: SendBackRecipient[]
}

function SupplierRejectForm(props: Props) {
  const { cancel, initialData } = props
  const {
    defaultFacility,
    defaultFacilityRecipient,
    selectedRecipients,
  } = initialData

  const [
    searchRecipientRecommendations,
    setSearchRecipientRecommendations,
  ] = useState<string[]>([])

  const supplierReject = (values: RejectFormValues, setErrors) => {
    return api
      .supplierReject(values, searchRecipientRecommendations)
      .then(() => navigate(workflowReviewUrl()))
      .catch(({ response }) => {
        setErrors(response.data.errors)
      })
  }

  return (
    <Modal size="lg" show cancel={cancel}>
      <Modal.Header>
        <button className="close" type="button" onClick={cancel}>
          ×
        </button>
        <h2 className="modal-title">Reject Order</h2>
      </Modal.Header>
      <Modal.Body>
        <SupplierRejectFormSection
          cancel={cancel}
          defaultFacilityRecipient={defaultFacilityRecipient}
          hasFacilityRecipients={defaultFacility.hasRecipients}
          searchFacilityRecipients={api.searchFacilityRecipients}
          supplierReject={supplierReject}
          setSearchRecipientRecommendations={setSearchRecipientRecommendations}
          selectedRecipients={selectedRecipients}
        />
      </Modal.Body>
    </Modal>
  )
}

const fetchInitialData = () =>
  api.getSupplierSendBackData().then(({ data }) => data)
export default withInitialData(fetchInitialData)(SupplierRejectForm)
