import { useEffect, useContext } from "react"
import WebsocketContext from "context/WebSocket"
import { isIe } from "utilities/browser/userAgent"

interface Settings {
  received(data): void
  connected?(): void
  disconnected?(): void
  disabled?: boolean
}

interface Channel {
  channel: string
  id: string
  dmeOrderId?: string
}

export function useWebSockets(
  channel: Channel,
  { received, connected, disconnected, disabled }: Settings
) {
  const { client } = useContext(WebsocketContext)
  useEffect(() => {
    let isMounted = true
    if (!disabled && !isIe()) {
      const subscription = client.subscribe({
        channel,
        connected,
        disconnected,
        received: (data) => isMounted && received(data),
      })
      return () => {
        isMounted = false
        subscription.unsubscribe()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
}
