import React from "react"
import Modal from "components/Modal"
import VerifyEmailMemo from "components/VerifyEmailMemo"

type Props = {
  email: string
  dismiss(): void
  show?: boolean
}

function VerifyEmailModal({ email, dismiss, show = true }: Props) {
  return (
    <Modal closeable show={show} cancel={dismiss}>
      <Modal.Body>
        <button
          className="close icon clickable"
          type="button"
          onClick={dismiss}
        >
          ×
        </button>
        <VerifyEmailMemo email={email} dismissParentModal={dismiss} />
      </Modal.Body>
    </Modal>
  )
}

export default VerifyEmailModal
