import React from "react"

export const PreferredSupplierLearnMoreLink = (): React.ReactElement => (
  <a
    href="/Health Plan Preferred Supplier 9 2024 product.pdf"
    target="_blank"
    aria-label="Learn more about Health Plan Preferred Suppliers"
  >
    Learn more
  </a>
)
