import React from "react"
import { RouteComponentProps } from "react-router"
import InternalFacilitySelection from "./components/InternalFacilitySelection"
import {
  fetchFacilityDetails,
  updateFacility,
  searchFacilities,
  createFacility,
} from "./api"
import { FacilityDetails } from "./sharedTypes"
import { User } from "../FacilitySignUp/shared/sharedTypes"

type Props = {
  defaultFacility?: FacilityDetails
  user: User
  reviewerDashboardDoctorId?: string
} & RouteComponentProps

const FacilitySelection = ({
  defaultFacility,
  user,
  reviewerDashboardDoctorId,
}: Props) => {
  return (
    <InternalFacilitySelection
      defaultFacility={defaultFacility}
      fetchFacilityDetails={fetchFacilityDetails}
      updateFacility={updateFacility}
      search={searchFacilities}
      createFacility={createFacility}
      user={user}
      reviewerDashboardDoctorId={reviewerDashboardDoctorId}
    />
  )
}

export default FacilitySelection
