import React, { FC } from "react"
import { AsyncButton, Result } from "components/AsyncButton"
import Link from "components/Link"

export const ReOrderDocumentsButton: FC<{
  reOrderRequest: () => Promise<Result>
}> = ({ reOrderRequest }) => {
  return (
    <AsyncButton Button={Link} onClick={reOrderRequest}>
      ReOrder
    </AsyncButton>
  )
}
