import React, { ReactNode, useEffect, useState } from "react"
import { MentionsInput, Mention } from "react-mentions"
import classNames from "classnames"
import { useDebounce } from "hooks/useDebounce"

type Props = {
  fetch(query: string): Promise<any[]>
  placeholder?: string
  onChange?(event, newValue, newPlainTextValue, mentions): void
  alert?: ReactNode
  autoFocus?: boolean
  disabled?: boolean
  onKeyDown?: (event) => Promise<void>
  value?: string
  renderSuggestion?: (
    suggestion,
    search: string,
    highlightedDisplay: React.ReactNode,
    index: number,
    focused: boolean
  ) => React.ReactNode
}

const MentionTextArea = ({
  fetch,
  onChange,
  placeholder,
  alert,
  autoFocus,
  disabled,
  onKeyDown,
  value,
  renderSuggestion,
}: Props) => {
  let ref
  const debouncedFetch = useDebounce(fetch)
  const [isFocused, setFocus] = useState(false)
  const onFocus = () => setFocus(true)
  const onBlur = () => setFocus(false)

  useEffect(() => {
    if (autoFocus) {
      ref.focus()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getData = (query, callback) => {
    debouncedFetch(query).then((records) => callback(records))
  }

  return (
    <div
      className={classNames("textarea-container has-autosize", {
        "has-alert": alert,
        "has-focus": isFocused,
      })}
    >
      <MentionsInput
        value={value}
        disabled={disabled}
        onKeyDown={onKeyDown}
        placeholder={placeholder}
        onBlur={onBlur}
        onFocus={onFocus}
        onChange={onChange}
        className="text-editor form-control"
        inputRef={(node) => (ref = node)}
        allowSpaceInQuery
      >
        <Mention
          trigger="@"
          data={getData}
          renderSuggestion={renderSuggestion}
          className="text-editor-item"
          markup="@[__display__](__id__)"
          displayTransform={(id, display) => `@${display}`}
          appendSpaceOnAdd
        />
      </MentionsInput>
      {alert}
    </div>
  )
}

MentionTextArea.defaultProps = {
  value: "",
}

export default MentionTextArea
