import React from "react"
import { Supplier } from "../sharedTypes"
import ParachuteSupplierCard from "../SupplierCard/ParachuteSupplierCard"

interface Props {
  suppliers: Supplier[]
  isOtherSuppliersSection: boolean
}

const SupplierCards: React.FC<Props> = ({
  suppliers,
  isOtherSuppliersSection,
}) => {
  if (suppliers.length === 0) {
    return <></>
  }

  return (
    <div className="row gutter-10">
      {suppliers.map((supplier) => (
        <ParachuteSupplierCard
          supplier={supplier}
          key={supplier.externalId}
          isOtherSuppliersSection={isOtherSuppliersSection}
        />
      ))}
    </div>
  )
}

export default SupplierCards
