import React from "react"
import { Doctor } from "sharedTypes"
import { fullName } from "utilities/person"
import Modal from "components/Modal"
import MobileNumberForm from "./MobileNumberForm"
import { isPhoneValid, PHONE_VALIDATION_MESSAGE } from "utilities/phone"

type Props = {
  doctor: Doctor
  updateDoctor(doctor: Doctor, params: { mobileNumber: string }): Promise<void>
  show: boolean
  close(): void
}

const MobileNumberModal = ({ doctor, updateDoctor, show, close }: Props) => {
  const update = (values, { setErrors }) => {
    updateDoctor(doctor, values)
      .then(close)
      .catch((errors) => setErrors(errors))
  }

  const validate = ({ mobileNumber }) => {
    if (!isPhoneValid(mobileNumber)) {
      return { mobileNumber: PHONE_VALIDATION_MESSAGE }
    }
  }

  return (
    <Modal
      show={show}
      cancel={close}
      title={`Add Mobile Number for ${fullName(doctor)}`}
      size="md"
    >
      <Modal.Body>
        <MobileNumberForm
          initialValues={{ mobileNumber: "" }}
          onSubmit={update}
          validate={validate}
        />
      </Modal.Body>
    </Modal>
  )
}

export default MobileNumberModal
