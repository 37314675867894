// @ts-strict-ignore
import React, { useState } from "react"
import { Route } from "react-router-dom"
import EditDeliveries from "./EditDeliveries"
import EditDeliveryAddresses from "./EditDeliveryAddresses"
import AddressModal from "./AddressModal"
import * as routes from "applications/Workflow/routes"
import { DmeOrder, Address, EmergencyContact } from "sharedTypes"
import { FormikValues } from "formik"

type Props = {
  dmeOrder: DmeOrder
  setDmeOrder(dmeOrder: DmeOrder): Promise<void>
  updateLineItemGroupDeliveryAddress(
    lineItemGroupId: string,
    dmeOrderFulfillment: FormikValues
  ): void
  createDeliveryAddress(
    lineItemGroupId: string,
    deliveryParams: { deliveryAddress: FormikValues }
  ): Promise<void>
  updateDelivery(
    deliveryId: string,
    deliveryParams: { room: string }
  ): Promise<void>
  updateDeliveryAddress(
    deliveryId: string,
    dmeOrderFulfillment: FormikValues
  ): Promise<void>
  updateEmergencyContact(emergencyContact: EmergencyContact): Promise<void>
  deleteEmergencyContact(emergencyContact: EmergencyContact): Promise<void>
  updatePatientInfo(values): Promise<void>
  updateDeliveryData(values): Promise<void>
  nextPagePath: string
  isPageChecked?: boolean
}

const InternalDelivery: React.FC<Props> = ({
  dmeOrder,
  setDmeOrder,
  updateLineItemGroupDeliveryAddress,
  createDeliveryAddress,
  updateDelivery,
  updateDeliveryAddress,
  updateEmergencyContact,
  deleteEmergencyContact,
  updatePatientInfo,
  updateDeliveryData,
  nextPagePath,
  isPageChecked,
}) => {
  const [showAddressModal, setShowAddressModal] = useState<boolean>(false)
  const [
    createAddressLineItemGroupId,
    setCreateAddressLineItemGroupId,
  ] = useState<string>(null)
  const [createAddressFulfillmentId, setCreateAddressFulfillmentId] = useState<
    string
  >(null)
  const [address, setAddress] = useState<Address>(null)

  const addAddressViaLineItemGroup = (lineItemGroupId) => {
    setShowAddressModal(true)
    setCreateAddressLineItemGroupId(lineItemGroupId)
    setCreateAddressFulfillmentId(null)
    setAddress(null)
  }

  const addAddressViaFulfillment = (fulfillmentId) => {
    setShowAddressModal(true)
    setCreateAddressLineItemGroupId(null)
    setCreateAddressFulfillmentId(fulfillmentId)
    setAddress(null)
  }

  const editAddress = (newAddress) => {
    setShowAddressModal(true)
    setAddress(newAddress)
  }

  const saveAddress = (values) => {
    if (address) {
      return updateDeliveryData({
        dmeOrderAddress: { id: address.id, ...values },
      }).then(() => {
        setShowAddressModal(false)
        setAddress(null)
      })
    }
    if (createAddressLineItemGroupId) {
      return createDeliveryAddress(createAddressLineItemGroupId, {
        deliveryAddress: values,
      }).then(() => {
        setShowAddressModal(false)
        setCreateAddressLineItemGroupId(null)
      })
    }
    if (createAddressFulfillmentId) {
      return updateDeliveryAddress(createAddressFulfillmentId, {
        deliveryAddress: values,
      }).then(() => {
        setShowAddressModal(false)
        setCreateAddressFulfillmentId(null)
      })
    }
  }

  return (
    <>
      <Route
        path={routes.deliveryPath.matcher}
        exact
        render={() => (
          <EditDeliveries
            dmeOrder={dmeOrder}
            setDmeOrder={setDmeOrder}
            updateDelivery={updateDelivery}
            updateDeliveryAddress={updateDeliveryAddress}
            addNewAddress={addAddressViaFulfillment}
            editAddress={editAddress}
            updateEmergencyContact={updateEmergencyContact}
            deleteEmergencyContact={deleteEmergencyContact}
            updatePatientInfo={updatePatientInfo}
            nextPagePath={nextPagePath}
            isPageChecked={isPageChecked}
          />
        )}
      />
      <Route
        path={routes.deliveryEditAddressesPath.matcher}
        render={() => (
          <EditDeliveryAddresses
            dmeOrder={dmeOrder}
            updateLineItemGroupDeliveryAddress={
              updateLineItemGroupDeliveryAddress
            }
            addNewAddress={addAddressViaLineItemGroup}
          />
        )}
      />
      <AddressModal
        address={address}
        show={showAddressModal}
        defaultState={dmeOrder.clinicalFacility.state}
        saveAddress={saveAddress}
        cancel={() => {
          setShowAddressModal(false)
        }}
      />
    </>
  )
}

export default InternalDelivery
