import React from "react"
import Logo from "components/Logo"

interface Props {
  title: string
  content: string
}

function FacilityPrompt({ title, content }: Props) {
  return (
    <div className="image image-sm with-description canopy-mbs-8x">
      <Logo width="24" />
      <div className="content">
        <p className="font-subparagraph">
          <strong>{title}</strong>
          <br />
          {content}
        </p>
      </div>
    </div>
  )
}

export default FacilityPrompt
