// @ts-strict-ignore
import React, { useContext, useState, useEffect, useMemo } from "react"
import Activity from "./containers/Activity"
import Cart from "./containers/Cart"
import Documents from "./containers/Documents"
import PatientPortal from "./containers/PatientPortal"
import {
  PatientPortalData,
  build as buildPatientPortalEndpoint,
} from "services/patientPortal"
import PatientChatIcon from "./containers/PatientPortal/components/PatientChatIcon"
import ChatSettings from "./containers/PatientPortal/components/ChatSettings"
import TransferredOrderOriginalCart from "./containers/TransferredOrderOriginalCart"
import { DmeOrder, DmeOrderWarning, EmployerType } from "sharedTypes"
import RightSidebar from "./RightSidebar"
import GlobalContext from "context/Global"
import Icon from "components/Icon"
import { getActivityTitle } from "./utilities/activity"
import classNames from "classnames"
import { DEFAULT_FILTER_OPTION } from "./containers/Activity/utilities/filters"
import ErrorBoundary from "components/ErrorBoundary"
import { SidebarTab } from "../Navigation/sharedTypes"
import { usePatientPortalQuery } from "applications/Workflow/api"
import { useFeatureFlags } from "components/FeatureFlagContext"

interface Analytics {
  closed: string
  open: string
}

export interface Tab {
  icon: React.ReactNode
  sidebarTab: SidebarTab
  alertDot?: boolean
  tooltip?: string
  analytics?: Analytics
  tabName: string
  title: React.ReactNode
  subtitle?: React.ReactNode
  content: React.ReactNode
  headerButton?: React.ReactNode
}

type Props = {
  hasNewComments: boolean
  tab: SidebarTab
  setSidebarTab(tab: SidebarTab): void
  close(): void
  refreshDmeOrder(): Promise<void>
  setDmeOrder(dmeOrder: DmeOrder): void
  dmeOrder: DmeOrder
  warnings: DmeOrderWarning[]
}

const Sidebar: React.FC<Props> = ({
  hasNewComments,
  tab,
  setSidebarTab,
  close,
  refreshDmeOrder,
  setDmeOrder,
  dmeOrder,
  warnings,
}) => {
  const { currentEmployer } = useContext(GlobalContext)
  const patientPortalEndpoint = buildPatientPortalEndpoint()
  const { isFeatureEnabled } = useFeatureFlags()

  const [selectedOption, setSelectedOption] = useState(DEFAULT_FILTER_OPTION)
  const [unreadPatientMessages, setUnreadPatientMessages] = useState(false)

  const portalDataParams = {
    orderId: dmeOrder.id,
    globalFacilityEnabled: dmeOrder.clinicalFacility.patientPortalEnabled,
    globalSupplierEnabled: dmeOrder.supplier?.patientPortalEnabled,
    latestPatientPortalEventTimestamp:
      dmeOrder.latestPatientPortalEventTimestamp,
  }

  const { data: portalData } = usePatientPortalQuery(
    portalDataParams,
    currentEmployer.employerType === EmployerType.Supplier &&
      dmeOrder.supplier?.patientPortalEnabled
  )

  useEffect(() => {
    if (portalData) {
      setUnreadPatientMessages(!!portalData.unreadPatientMessages)
    }
  }, [portalData])
  const patientChatAvailable =
    portalData &&
    portalData.phoneCanReceiveSms &&
    portalData.portalMessagingEnabled

  const tabs = useMemo(() => {
    const shouldRenderMessagingTab = (
      portalData: PatientPortalData
    ): boolean => {
      if (isFeatureEnabled("chatCommercialization")) {
        return !!(portalData && portalData.chatId)
      } else {
        return !!(
          portalData &&
          portalData.chatId &&
          portalData.portalMessagingSupplierEnabled
        )
      }
    }

    const tabs: Tab[] = [
      {
        icon: (
          <div
            className={classNames("far fa-comments", {
              "new-comments": hasNewComments,
            })}
          ></div>
        ),
        tabName: "Activity",
        title: getActivityTitle(
          currentEmployer.employerType,
          dmeOrder.lineItemGroups,
          dmeOrder.permissions.comment
        ),
        subtitle: !dmeOrder.permissions.comment && (
          <p className="color-dark-gray font-subparagraph">
            <Icon type="lock" size="sm" /> Read only
          </p>
        ),
        content: (
          <Activity
            selectedOption={selectedOption}
            setSelectedOption={setSelectedOption}
            dmeOrder={dmeOrder}
            clinicalFacility={dmeOrder.clinicalFacility}
            warnings={warnings}
            employer={currentEmployer}
          />
        ),
        sidebarTab: SidebarTab.Activity,
        tooltip: "Activity Feed",
        analytics: {
          open: "activity-tab:from-open",
          closed: "activity-tab:from-closed",
        },
      },
      {
        icon: <div className="far fa-file-alt"></div>,
        tabName: "Documents",
        title: <>Documents</>,
        sidebarTab: SidebarTab.Documents,
        tooltip: "Order Documents",
        content: (
          <Documents
            refreshDmeOrder={refreshDmeOrder}
            setDmeOrder={setDmeOrder}
            dmeOrderId={dmeOrder.id}
            supplierFaxNumber={dmeOrder.supplierFaxNumber}
            hasSigningClinician={!!dmeOrder.doctor}
            documentationRequirements={dmeOrder.documentationRequirements}
          />
        ),
        analytics: {
          open: "document-tab:from-open",
          closed: "document-tab:from-closed",
        },
      },
    ]
    tabs.push({
      icon: <div className="fas fa-shopping-cart"></div>,
      tabName: "Cart",
      title: <>Cart</>,
      sidebarTab: SidebarTab.Cart,
      tooltip: "Cart",
      content: <Cart dmeOrder={dmeOrder} refreshDmeOrder={refreshDmeOrder} />,
      analytics: {
        open: "cart-tab:from-open",
        closed: "cart-tab:from-closed",
      },
    })

    if (dmeOrder.permissions.sidebar.showTransferredOrderOriginalCart) {
      tabs.push({
        icon: <div className="fas fa-exchange-alt"></div>,
        tabName: "SupplierTransferOriginalOrder",
        title: <>Original Cart</>,
        sidebarTab: SidebarTab.SupplierTransfer,
        tooltip: "Transferred Order Original Cart",
        content: (
          <TransferredOrderOriginalCart
            dmeOrder={dmeOrder}
            warnings={warnings}
            employer={currentEmployer}
          />
        ),
        analytics: {
          open: "supplier-transfer-tab:from-open",
          closed: "supplier_transfer-tab:from-closed",
        },
      })
    }

    if (shouldRenderMessagingTab(portalData)) {
      tabs.push({
        alertDot: unreadPatientMessages,
        icon: (
          <PatientChatIcon classes="d-block canopy-m-auto" fontSize="small" />
        ),
        tabName: "PatientMessaging",
        title: patientChatAvailable ? "Chat with the patient" : "",
        subtitle: patientChatAvailable ? (
          <p className="color-dark-gray font-notice">
            Not visible to referral.
          </p>
        ) : (
          <></>
        ),
        sidebarTab: SidebarTab.PatientMessaging,
        tooltip: "Patient Messaging",
        content: (
          <PatientPortal
            dmeOrderId={dmeOrder.id}
            chatId={portalData.chatId}
            supplierName={portalData.supplierName}
            chatAvailable={patientChatAvailable}
            patientPortalEndpoint={patientPortalEndpoint}
            setUnreadPatientMessages={setUnreadPatientMessages}
            portalMessagingSupplierEnabled={
              portalData.portalMessagingSupplierEnabled
            }
          />
        ),
        analytics: {
          open: "patient-messaging-tab:from-open",
          closed: "patient-messaging-tab:from-closed",
        },
        headerButton: patientChatAvailable && (
          <ChatSettings chatId={portalData.chatId} />
        ),
      })
    }

    return tabs
  }, [
    patientChatAvailable,
    patientPortalEndpoint,
    portalData,
    selectedOption,
    currentEmployer,
    dmeOrder,
    hasNewComments,
    refreshDmeOrder,
    setDmeOrder,
    unreadPatientMessages,
    warnings,
    isFeatureEnabled,
  ])

  return (
    <ErrorBoundary>
      <RightSidebar
        fixed
        tabs={tabs}
        onClick={({ sidebarTab }) => setSidebarTab(sidebarTab)}
        onClose={close}
        currentTab={tab}
        orderStatus={dmeOrder.orderStatus}
        portalMessagingSupplierEnabled={
          !!(portalData && portalData.portalMessagingSupplierEnabled)
        }
      />
    </ErrorBoundary>
  )
}

export default Sidebar
