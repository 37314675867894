import { employerPrefix } from "utilities/url"

export function approvalsUrl(doctorId: string) {
  return `${employerPrefix("Doctor", doctorId)}/approvals.json`
}

export function affiliationsUrl() {
  return `${employerPrefix()}/affiliations.json`
}

export function facilitiesUrl() {
  return `${employerPrefix()}/clinical_facilities.json`
}

export function profileUrl() {
  return `${employerPrefix()}/profile.json`
}

export function resendIdentityVerificationsUrl() {
  return `${employerPrefix()}/profile/resend.json`
}

export function orderUrl(dmeOrderId) {
  return `${employerPrefix()}/orders/${dmeOrderId}`
}
