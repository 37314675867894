import { EventCategory, trackEvent } from "utilities/tracking"

export const PARACHUTE_USER_SIGN_IN = "fax-out-parachute-user-sign-in"

export const trackParachuteUserSignInEvent = (
  signableAccessCodeId
): Promise<void> =>
  trackEvent(
    EventCategory.SignableAccessCode,
    PARACHUTE_USER_SIGN_IN,
    signableAccessCodeId
  )
