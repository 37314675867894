import React from "react"
import EventComponent from "components/Event"
import Timeline from "components/Timeline"
import { CSSTransition, TransitionGroup } from "react-transition-group"
import { transformMessage } from "../utilities/messageTransformer"
import { Chat, Message } from "services/patientPortal"
import { Event } from "sharedTypes"
import { currentOrderId } from "applications/Workflow/urls"

type Props = {
  messages: Message[]
  chat: Chat
  insuranceUploadUrl: (orderId: string | undefined) => string
}

const Messages: React.FC<Props> = ({
  messages,
  chat,
  insuranceUploadUrl,
}: Props) => {
  const insuranceUrl = insuranceUploadUrl(currentOrderId())
  const events = messages.map((message) =>
    transformMessage(message, chat, insuranceUrl)
  )

  const renderEvent = (event: Event) => {
    return (
      <Timeline key={event.eventTimestamp} event={event} internal={false}>
        <EventComponent
          currentUserId={window.parachute?.currentUserId}
          event={event}
        />
      </Timeline>
    )
  }

  return (
    <div data-testid="patient-portal-message-list">
      <TransitionGroup className="timeline-event-list">
        {events.map((event) => (
          <CSSTransition
            key={event.eventTimestamp}
            timeout={{ enter: 300, exit: 250 }}
            mountOnEnter
            unmountOnExit
            classNames="timeline-event"
          >
            {renderEvent(event)}
          </CSSTransition>
        ))}
      </TransitionGroup>
    </div>
  )
}

export default Messages
