// extracted by mini-css-extract-plugin
var _1 = "app-javascript-applications-Workflow-containers-ClinicalFacilityClinical-components-FillablePdfDocumentationRequirement-page-header-module__close-button--oWW_M";
var _2 = "app-javascript-applications-Workflow-containers-ClinicalFacilityClinical-components-FillablePdfDocumentationRequirement-page-header-module__completed--yyyaW";
var _3 = "app-javascript-applications-Workflow-containers-ClinicalFacilityClinical-components-FillablePdfDocumentationRequirement-page-header-module__continue-button--voaYK";
var _4 = "app-javascript-applications-Workflow-containers-ClinicalFacilityClinical-components-FillablePdfDocumentationRequirement-page-header-module__header--Vk_yX";
var _5 = "app-javascript-applications-Workflow-containers-ClinicalFacilityClinical-components-FillablePdfDocumentationRequirement-page-header-module__header-grid--w0Dqh";
var _6 = "app-javascript-applications-Workflow-containers-ClinicalFacilityClinical-components-FillablePdfDocumentationRequirement-page-header-module__helper-text--ILb4J";
var _7 = "app-javascript-applications-Workflow-containers-ClinicalFacilityClinical-components-FillablePdfDocumentationRequirement-page-header-module__mark-complete-button--ayiRT";
var _8 = "app-javascript-applications-Workflow-containers-ClinicalFacilityClinical-components-FillablePdfDocumentationRequirement-page-header-module__save-text--U0cHL";
export { _1 as "closeButton", _2 as "completed", _3 as "continueButton", _4 as "header", _5 as "headerGrid", _6 as "helperText", _7 as "markCompleteButton", _8 as "saveText" }
