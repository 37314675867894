import React, { ReactNode } from "react"

type Props = {
  children: ReactNode
}

const StickyFooter = ({ children }: Props) => {
  return (
    <>
      <div className="sticky-footer-push" />
      <div className="sticky-footer">
        <div className="text-center">{children}</div>
      </div>
    </>
  )
}

export default StickyFooter
