import React, { useState } from "react"
import { DocumentationOrder } from "../fetchOrders"
import Popover from "components/Popover"
import Link from "components/Link"

const ProductNotNeededReason = ({ order }: { order: DocumentationOrder }) => {
  const [otherReasonOpen, setOtherReasonOpen] = useState(false)
  const otherReasonToggle = () =>
    setOtherReasonOpen((otherReasonOpen) => !otherReasonOpen)
  const otherReasonHide = () => setOtherReasonOpen(false)

  if (order.productNotNeededOtherReason) {
    return (
      <Popover
        open={otherReasonOpen}
        onHide={otherReasonHide}
        onClick={otherReasonToggle}
        label={
          <>
            {"Patient doesn't need product: Other. "}
            <Link key="link">View More</Link>
          </>
        }
        className="canopy-mbs-4x canopy-mbe-0"
      >
        {order.productNotNeededOtherReason}
      </Popover>
    )
  } else if (order.productNotNeededReason) {
    return (
      <p className="canopy-mbs-4x  canopy-mbe-0">
        {order.productNotNeededReason}
      </p>
    )
  } else {
    return null
  }
}

const PatientActivityPopover: React.FC<{ order: DocumentationOrder }> = ({
  order,
}) => {
  const [open, setOpen] = useState(false)
  const toggle = () => setOpen((open) => !open)
  const hide = () => setOpen(false)

  const renderSelectedDoctorInfo = () => {
    if (order.patientSelectedDoctor) {
      return (
        <>
          <div>Patient submitted clinician:</div>
          <div>
            {order.patientSelectedDoctor.firstName}{" "}
            {order.patientSelectedDoctor.lastName}
          </div>
          <div>{order.patientSelectedDoctor.npi}</div>
          <div>
            {order.patientSelectedDoctor.city},{" "}
            {order.patientSelectedDoctor.state}{" "}
            {order.patientSelectedDoctor.zip}
          </div>
        </>
      )
    } else {
      return (
        <p>
          Patient did not submit a new clinician. Reach out to the patient for
          more information.
        </p>
      )
    }
  }

  return (
    <div>
      {order.patientActivity === "Wrong Clinician" ? (
        <Popover
          open={open}
          onHide={hide}
          onClick={toggle}
          label={<Link>{order.patientActivity}</Link>}
        >
          {renderSelectedDoctorInfo()}
        </Popover>
      ) : (
        <p className="canopy-mbe-0">{order.patientActivity}</p>
      )}
      <ProductNotNeededReason order={order} />
    </div>
  )
}

export default PatientActivityPopover
