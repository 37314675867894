import React from "react"
import Dropdown from "components/Dropdown"
import Icon from "components/Icon"
import Tooltip from "components/Tooltip"
import {
  FilterOption,
  FACILITY_FILTERS,
  SUPPLIER_FILTERS,
} from "../utilities/filters"
import { trackEvent } from "utilities/analytics"
import { EmployerType } from "sharedTypes"

const ActivitySelector = ({
  employerType,
  selectedOption,
  setSelectedOption,
}: {
  employerType: string
  selectedOption: FilterOption
  setSelectedOption: (option: FilterOption) => void
}) => {
  const selectOption = (option: FilterOption) => {
    const oldFilter = selectedOption.label
    const newFilter = option.label

    trackEvent(["Change Activity Filter", oldFilter, newFilter])
    setSelectedOption(option)
  }

  const renderInfoIcon = () => (
    <Tooltip
      trigger={["click", "hover"]}
      overlay="Referral and Internal Comment filters are only visible to supplier"
      placement={Tooltip.Placement.Top}
      triggerElementClassName="d-inline-block canopy-mie-4x"
    >
      <Icon type="info-circle" className="color-info" />
    </Tooltip>
  )

  const isSupplier = employerType === EmployerType.Supplier
  const labels = isSupplier ? SUPPLIER_FILTERS : FACILITY_FILTERS

  return (
    <div className="filter-dropdown">
      {isSupplier && renderInfoIcon()}
      <span className="canopy-pie-4x">Viewing:</span>
      <Dropdown
        size="lg"
        label={selectedOption.label}
        options={labels.map((label) => ({
          label,
          checked: selectedOption.label === label,
        }))}
        onClick={selectOption}
      />
    </div>
  )
}

export default ActivitySelector
