import { CanopyIcon } from "@parachutehealth/canopy-icon"
import { Doctor } from "sharedTypes"
import React from "react"
import { titlecase } from "utilities/string"
import * as styles from "./selectedDoctor.module.scss"
import classNames from "classnames"

interface Props {
  doctor: Doctor
  onDoctorRemove: (doctorId: string) => void
}

const SelectedDoctor = ({ doctor, onDoctorRemove }: Props) => {
  return (
    <li
      key={doctor.doctorId}
      className={styles.selectedDoctorItem}
      aria-label={`Selected Doctor: ${doctor.firstName} ${doctor.lastName}`}
    >
      <div>
        {titlecase(doctor.firstName)} {titlecase(doctor.lastName)}
      </div>
      <button
        type="button"
        className={classNames("btn-link", styles.xMark)}
        onClick={() => onDoctorRemove(doctor.doctorId)}
      >
        <CanopyIcon
          name="xmark"
          title={`remove ${doctor.firstName} ${doctor.lastName}`}
        />
      </button>
    </li>
  )
}

export default SelectedDoctor
