import React, { useState } from "react"
import classNames from "classnames"
import DropdownIcon from "./DropdownIcon"

interface SectionProps {
  className?: string
  title: string | React.ReactNode
  subtitle?: string
  children?: React.ReactNode
  actions?: React.ReactNode
  hideToggle?: boolean
  initialExpanded?: boolean
  fullWidth?: boolean
  swatch?: string
  badge?: React.ReactNode
}

const Section = ({
  className = "",
  title,
  subtitle,
  children,
  actions,
  hideToggle,
  initialExpanded = false,
  fullWidth = false,
  swatch,
  badge,
}: SectionProps) => {
  const [expanded, setExpanded] = useState(initialExpanded)

  const toggle = () => {
    if (!hideToggle) {
      setExpanded(!expanded)
    }
  }

  const swatchClass = swatch ? `swatch-left-${swatch}` : ""
  return (
    <div
      className={classNames("well canopy-mbe-4x", className, swatchClass, {
        "well-expand": fullWidth,
        "swatch-left": swatch,
      })}
    >
      <div
        className={classNames("canopy-mbs-0", {
          "canopy-mbe-8x": !!subtitle || expanded,
          pointer: !hideToggle,
        })}
        onClick={toggle}
      >
        <span className="h3 canopy-mbs-0">{title}</span>
        <div className="well-actions">
          {actions}
          {badge}
        </div>
        <div className="well-dropdown">
          <span className="h3">
            {!hideToggle && <DropdownIcon expanded={expanded} />}
          </span>
        </div>
      </div>
      {!expanded && subtitle}
      {expanded && children}
    </div>
  )
}

export default Section
