import { useEffect } from "react"
import { isDocumentHidden } from "../utilities/browser/document"

interface Callback {
  (visible: boolean): void
}

const getVisibilityChangeEventName = () => {
  if (typeof document.msHidden !== "undefined") {
    return "msvisibilitychange"
  } else if (typeof document.webkitHidden !== "undefined") {
    return "webkitvisibilitychange"
  }
  return "visibilitychange"
}

const visibilityEventName = getVisibilityChangeEventName()

export function useVisibilityChange(onVisibilityChange: Callback) {
  const onChange = () => {
    onVisibilityChange(!isDocumentHidden())
  }
  useEffect(() => {
    document.addEventListener(visibilityEventName, onChange, false)
    return () => document.removeEventListener(visibilityEventName, onChange)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onVisibilityChange])
}
