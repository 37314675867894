// @ts-strict-ignore
import React from "react"
import AddressSection from "./AddressSection"
import { DmeOrder, AddressType, Address, Fulfillment } from "sharedTypes"
import { Form, RadioButtons, TextArea } from "components/form"
import {
  deliverPrefix,
  deliveryAddressLabel,
} from "../utilities/displayOfferingType"

type Props = {
  dmeOrder: DmeOrder
  fulfillment: Fulfillment
  initialValues?: InitialValues
  addNewAddress(): void
  editAddress(address: Address): void
  onSubmit(values): void
}

type InitialValues = {
  addressId?: number
  room?: string
}

export default function AddressSelector({
  addNewAddress,
  dmeOrder,
  fulfillment,
  initialValues,
  onSubmit,
  editAddress,
}: Props) {
  const { addresses } = dmeOrder
  const billingAddress = addresses.find(
    ({ addressType }) => addressType === AddressType.Billing
  )
  const facilityAddress = addresses.find(
    ({ addressType }) => addressType === AddressType.Facility
  )
  const otherAddresses = addresses.filter(
    ({ addressType }) =>
      ![AddressType.Billing, AddressType.Facility].includes(addressType)
  )
  const hasBillingAddress = !!billingAddress
  const hasFacilityAddress = !!facilityAddress

  const options = [
    hasBillingAddress && {
      label: `${deliverPrefix(fulfillment.offeringType)} home address`,
      value: billingAddress.id,
      addOn: ({ form: { values } }) =>
        values.addressId === billingAddress.id && (
          <AddressSection address={billingAddress} editAddress={editAddress} />
        ),
    },
    hasFacilityAddress && {
      label: `${deliverPrefix(fulfillment.offeringType)} facility address`,
      value: facilityAddress.id,
      addOn: ({ form: { values } }) =>
        values.addressId === facilityAddress.id && (
          <AddressSection
            label={dmeOrder.clinicalFacility.name}
            address={facilityAddress}
            editAddress={editAddress}
          >
            <TextArea
              label="Room/Unit"
              placeholder="Add Room/Unit"
              name="room"
            />
          </AddressSection>
        ),
    },
  ]
    .concat(
      otherAddresses.map((address, index) => {
        return {
          label: `${deliverPrefix(
            fulfillment.offeringType
          )} a different address #${index + 1}`,
          value: address.id,
          addOn: ({ form: { values } }) =>
            values.addressId === address.id && (
              <AddressSection address={address} editAddress={editAddress} />
            ),
        }
      })
    )
    .filter(Boolean)
  return (
    <Form initialValues={initialValues} onSubmit={onSubmit} submitOnChange>
      <RadioButtons
        className="plain font-subparagraph"
        label={deliveryAddressLabel(fulfillment.offeringType)}
        name="addressId"
        options={options}
        unwrapped
      />
      <a
        className="link link-primary font-subparagraph d-block canopy-mbe-8x"
        onClick={addNewAddress}
      >
        + Add new address
      </a>
    </Form>
  )
}
