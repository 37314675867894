import React, { useState } from "react"
import { DocumentationOrder } from "../fetchOrders"
import Link from "../../../components/Link"
import { ShareModal } from "../ShareModal"
import {
  EventCategory,
  trackEvent as trackAnalyticsEvent,
} from "utilities/tracking"

export const ShareLink = ({
  order,
}: {
  order: DocumentationOrder
}): JSX.Element => {
  const [showModal, setShowModal] = useState<boolean>(false)

  const close = () => setShowModal(false)

  const shouldShowShareLink = () => {
    return (
      order.lastNotificationMethod?.includes("Fax: ") &&
      order.signatureResolution?.type !== "signed"
    )
  }

  const handleOpen = () => {
    void trackAnalyticsEvent(
      EventCategory.ShareRequestLink,
      "share-request-modal-opened"
    )
    setShowModal(true)
  }

  return (
    <>
      {shouldShowShareLink() && <Link onClick={() => handleOpen()}>Share</Link>}

      <ShareModal
        showModal={showModal}
        close={close}
        documentationOrder={order}
      />
    </>
  )
}
