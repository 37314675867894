import React, { useState, useContext } from "react"
import {
  Form,
  TextField,
  PhoneField,
  EmailField,
  Select,
} from "components/form"
import { Field } from "formik"
import PreferredLanguageSelect from "./PreferredLanguageSelect"
import Overlay from "components/Overlay"
import Alert from "components/Alert"
import { states } from "utilities/address"
import { Address, EmployerType } from "sharedTypes"
import GlobalContext from "context/Global"

type Props = {
  onClose(): void
  update(values): Promise<void>
  billingAddress?: Address
  initialState?: string
  initialPhoneNumber?: string
  initialPreferredLanguage?: string
  initialEmail?: string
  liaison: boolean
}

function PatientInfoForm({
  onClose,
  update,
  billingAddress,
  initialState,
  initialPhoneNumber,
  initialEmail,
  initialPreferredLanguage,
  liaison,
}: Props) {
  const { currentEmployer } = useContext(GlobalContext)
  const stateOptions = states.map((state) => ({
    label: state[0],
    value: state[1],
  }))
  const initialValues = {
    line1: "",
    line2: "",
    city: "",
    state: initialState || "NY",
    preferredLanguage: initialPreferredLanguage,
    zip: "",
    email: initialEmail || "",
    ...billingAddress,
    phoneNumber: billingAddress?.phoneNumber || initialPhoneNumber || "",
    alternatePhoneNumber: billingAddress?.alternatePhoneNumber || "",
  }

  const onSubmit = (values, { setErrors, setSubmitting }) => {
    if (!patientConsented) {
      alert("You must check the patient consent box to proceed.")
    } else {
      update(values)
        .then(() => {
          onClose()
        })
        .catch(({ errors }) => {
          setErrors(errors)
          setSubmitting(false)
        })
    }
  }

  const [patientConsented, setPatientConsented] = useState(true)

  const togglePatientConsent = () => {
    setPatientConsented(!patientConsented)
  }

  return (
    <Form initialValues={initialValues} onSubmit={onSubmit}>
      {({ isSubmitting }) => {
        return (
          <Overlay active={isSubmitting} showSpinner>
            <div className="row">
              <div className="col-md-6">
                <TextField
                  label="Street - Line 1"
                  name="line1"
                  placeholder="Enter Address Line 1"
                />
              </div>
              <div className="col-md-6">
                <TextField
                  label="Street - Line 2 (Optional)"
                  name="line2"
                  placeholder="Enter Address Line 2"
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <TextField
                  label="City"
                  name="city"
                  placeholder="Enter City"
                  unwrapped
                />
              </div>
              <div className="col-md-3">
                <Select
                  label="State"
                  name="state"
                  options={stateOptions}
                  unwrapped
                />
              </div>
              <div className="col-md-3">
                <TextField
                  label="ZIP"
                  name="zip"
                  numeric
                  placeholder="Enter ZIP"
                  unwrapped
                />
              </div>
            </div>
            <hr />
            <div className="row">
              <div className="col-md-6">
                <PhoneField label="Phone Number" name="phoneNumber" />
              </div>
              <div className="col-md-6">
                <PhoneField
                  label="Alternate Phone (Optional)"
                  name="alternatePhoneNumber"
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <PreferredLanguageSelect />
              </div>
              <div className="col-md-6">
                <EmailField label="Email (Optional)" name="email" />
              </div>
            </div>
            {currentEmployer.employerType === EmployerType.ClinicalFacility &&
              !liaison && (
                <Alert status="info">
                  <div className="d-flex align-items-start">
                    <div className="canopy-mie-4x">
                      <Field
                        type="checkbox"
                        name="patientConsent"
                        checked={patientConsented}
                        onChange={togglePatientConsent}
                      />
                    </div>
                    <div>
                      <strong>Heads up!</strong> The supplier may use this
                      information to contact the patient via phone, SMS, or
                      email for assistance with completing the order. To proceed
                      with your order, you confirm that, to the best of your
                      knowledge, the patient info is true and accurate, and that
                      the patient consents to this communication.
                    </div>
                  </div>
                </Alert>
              )}
            <div className="form-actions text-right">
              <button
                type="submit"
                className="btn btn-brand"
                disabled={isSubmitting || !patientConsented}
              >
                Save
              </button>
            </div>
          </Overlay>
        )
      }}
    </Form>
  )
}

export default PatientInfoForm
