import ProductSearchTabs from ".././CatalogBrowseTabs"
import { CatalogBrowseTab } from "sharedTypes"
import React from "react"

interface Props {
  currentTab: CatalogBrowseTab
  setSelectedTab: (tab: CatalogBrowseTab) => void
  showSearchByProductTabFirst: boolean
}

const ProductsAndServicesHeader: React.FC<Props> = ({
  currentTab,
  setSelectedTab,
  showSearchByProductTabFirst,
}: Props) => {
  return (
    <>
      <h1 className="canopy-mbe-12x">Products & Services</h1>
      <ProductSearchTabs
        selectedTab={currentTab}
        setSelectedTab={setSelectedTab}
        showSearchByProductTabFirst={showSearchByProductTabFirst}
      ></ProductSearchTabs>
    </>
  )
}

export default ProductsAndServicesHeader
