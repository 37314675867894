import { get } from "services/api"

export type DocumentationOrderNotificationHistory = {
  sentAt: string
  channel: string
  destination?: string
  type: string
}

export const documentationOrderNotificationHistory = (
  documentationOrderId: string
): Promise<DocumentationOrderNotificationHistory[]> => {
  return get(
    `/web_api/documentation_orders/${documentationOrderId}/notifications`
  ).then((res) => res.data.data)
}
