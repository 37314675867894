/* This function acts as a wrapper around the native Zendesk chat widget.
   Zendesk injects an iframe as well as JS code that makes it difficult to test
   that the chat widget is being opened.

   See documents for reference:
   https://developer.zendesk.com/api-reference/widget/introduction/
   https://developer.zendesk.com/documentation/zendesk-web-widget-sdks/sdks/web/messaging-web-widget-qs/#defining-the-custom-launcher-and-web-widget-behavior
 */
export const openChat = () => {
  // @ts-ignore
  zE("webWidget", "open")
}
