import { get, post } from "services/api"
import {
  emailCumulativeReportUrl,
  searchFacilitiesUrl,
  searchSuppliersUrl,
} from "./urls"
import { CumulativeInvoiceFormFields, Supplier } from "./sharedTypes"
import { ClinicalFacility } from "sharedTypes"

export const emailCumulativeReport = (
  cumulativeInvoice: CumulativeInvoiceFormFields
) =>
  post(emailCumulativeReportUrl(), { cumulativeInvoice })
    .then(({ data }) => data)
    .catch(({ response }) => Promise.reject(response.data))

export const getFacilities = (term: string): Promise<ClinicalFacility[]> =>
  get(searchFacilitiesUrl(), { term }).then(({ data }) => data)

export const getSuppliers = (
  clinicalFacilityId: string,
  term: string
): Promise<Supplier[]> =>
  get(
    `${searchSuppliersUrl()}?clinical_facility_id=${clinicalFacilityId}&term=${term}`
  ).then(({ data }) => data)
