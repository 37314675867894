import React from "react"
import UploadButton from "../components/UploadButton"

interface Props {
  name: string
  csvUpload?: {
    enabled: boolean
    supplierOrganizationId: string
    requiredHeaders: string[]
    possibleHeaders: string[]
  }
  tabView?: boolean
}

const style = {
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
}

const Header = (props: Props) => {
  const { name, csvUpload, tabView } = props

  return (
    <h3>
      <div style={style}>
        <div>
          <span className="bold">Orders Dashboard</span>
          <span className="canopy-pis-4x">({name})</span>
        </div>
        {csvUpload?.enabled && !tabView && (
          <UploadButton
            supplierOrganizationId={csvUpload.supplierOrganizationId}
            requiredHeaders={csvUpload.requiredHeaders}
            possibleHeaders={csvUpload.possibleHeaders}
          />
        )}
      </div>
    </h3>
  )
}

export default Header
