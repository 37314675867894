import React, { useState } from "react"
import { CanopyIcon } from "@parachutehealth/canopy-icon"
import Popover from "components/Popover"

type Props = { chatId: string }

const ChatSettings: React.FC<Props> = ({ chatId }) => {
  const [open, setOpen] = useState(false)
  const chatHistoryUrl = `${window.patientPortalUrl}/api/chats/${chatId}/history`

  return (
    <Popover
      open={open}
      onHide={() => setOpen(false)}
      contentLocation={() => ({ top: 28, left: -95 })}
      label={
        <div
          className="icon clickable"
          onClick={() => setOpen((open) => !open)}
          data-testid="chat-settings"
        >
          <CanopyIcon
            name="gear"
            fill="canopyColorPrimitivesGray27"
            size="small"
          />
        </div>
      }
      className="d-inline-block"
    >
      {open && (
        <a href={chatHistoryUrl} target="_blank" rel="noreferrer">
          Download chat history
        </a>
      )}
    </Popover>
  )
}

export default ChatSettings
