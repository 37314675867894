import {
  canopyColorBackgroundDanger,
  canopyColorInteractiveLinkDefault,
} from "@parachutehealth/canopy-tokens-color"
import Alert, { Status } from "components/Alert"
import React, { useState } from "react"
import { CanopyButton } from "@parachutehealth/canopy-button"
import { EmailResponse } from "../../sharedTypes"

type Props = {
  sendEmrResetPasswordEmail(): Promise<EmailResponse>
}

const EmrResetPasswordAlert = ({ sendEmrResetPasswordEmail }: Props) => {
  const [disabled, setDisabled] = useState(false)
  const [message, setMessage] = useState(
    "Your email is not verified. Reset your password to verify your account."
  )
  const [status, setStatus] = useState<Status>("danger")

  const onClick = (event) => {
    event.stopPropagation()
    setDisabled(true)

    sendEmrResetPasswordEmail()
      .then((response) => {
        setMessage(`A password setup email has been sent to ${response.email}.`)
        setStatus("success")
      })

      .catch((response) =>
        setMessage(
          `Encountered an error: ${response.errorMessage}. Please use the Help Center button to contact support.`
        )
      )
  }

  return (
    <Alert status={status} className="w-100-p">
      <span>
        <strong>{disabled ? "" : "Heads up! "}</strong>
        {message}
        <CanopyButton
          accessibleText="resend-email-button"
          variant="tertiary"
          size="small"
          style={{
            color: canopyColorInteractiveLinkDefault,
            backgroundColor: canopyColorBackgroundDanger,
          }}
          onClick={onClick}
          disabled={disabled}
          className={disabled ? "hidden" : ""}
        >
          Resend.
        </CanopyButton>
      </span>
    </Alert>
  )
}

export default EmrResetPasswordAlert
