import React from "react"
import { updateSupplierServiceAreas } from "./api"
import StatesSelectForm from "../SupplierServiceAreaForm/StatesSelectForm"
import { ToastProvider, useToaster } from "../../components/Toaster"

interface Props {
  supplierId: string
  serviceAreaStates: string[]
}

const SupplierAdminServiceAreaForm: React.FC<Props> = ({
  supplierId,
  serviceAreaStates,
}) => {
  return (
    <>
      <ToastProvider>
        <InternalSupplierAdminServiceAreaForm
          supplierId={supplierId}
          serviceAreaStates={serviceAreaStates}
        />
      </ToastProvider>
    </>
  )
}

const InternalSupplierAdminServiceAreaForm: React.FC<Props> = ({
  supplierId,
  serviceAreaStates,
}) => {
  const { persistentAlert } = useToaster()
  const onSubmit = ({ serviceAreaStates }) => {
    return updateSupplierServiceAreas(supplierId, serviceAreaStates)
      .then(() => {
        persistentAlert({
          message: "Service areas updated",
          severity: "success",
        })
      })
      .catch(() =>
        persistentAlert({
          message:
            "There was an error, please try again or reach out to customer support",
          severity: "error",
        })
      )
  }

  return (
    <>
      <h1>Edit supplier service areas</h1>
      <StatesSelectForm
        onSubmit={onSubmit}
        initialServiceAreaStates={serviceAreaStates}
      />
    </>
  )
}

export default SupplierAdminServiceAreaForm
