// @team @expanse
import React from "react"
import { EmergencyContact } from "sharedTypes"
import OrderReviewAdditionalContact from "./OrderReviewAdditionalContact"

const OrderReviewAdditionalContacts = (props: {
  emergencyContacts: EmergencyContact[]
}) => (
  <>
    {props.emergencyContacts.map((emergencyContact) => (
      <div className="canopy-mbe-4x" key={emergencyContact.id}>
        <OrderReviewAdditionalContact emergencyContact={emergencyContact} />
      </div>
    ))}
  </>
)

export default OrderReviewAdditionalContacts
