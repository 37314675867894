import useServerSideDataGrid, {
  ServerSideDataGridOptions,
  ServerSideDataGridParams,
} from "../../../../hooks/useServerSideDataGrid"
import {
  getProductVariationsForSupplier,
  SupplierProductVariationsDataGridResponse,
} from "./api"
import React, { useState } from "react"
import { DataGridPro, GridColDef } from "@mui/x-data-grid-pro"
import { Supplier } from "../../../../types/sharedTypes"
import { isTest } from "../../../../../../utilities/environment"
import DataGridToolbar from "../../../../components/DataGridToolbar"

type ProductVariationsDataGridOptions = ServerSideDataGridOptions
type ProductVariationsDataGridProps = {
  supplier: Supplier
}

const ProductVariationsDataGrid: React.FC<ProductVariationsDataGridProps> = ({
  supplier,
}: ProductVariationsDataGridProps) => {
  const [gridData, setGridData] = React.useState<
    SupplierProductVariationsDataGridResponse
  >({
    records: [],
    totalCount: 0,
  })

  const [loading, setLoading] = useState(false)
  const beforeFetch = () => setLoading(true)
  const afterFetch = () => setLoading(false)

  const columns: GridColDef[] = [
    {
      field: "productVariationExternalId",
      headerName: "ID",
      flex: 0.5,
      hide: true,
    },
    {
      field: "productName",
      flex: 1,
      headerName: "Product",
      renderCell: (params) => {
        return (
          <a href={`/cms/catalog/products/${params.row.productExternalId}`}>
            {params.value}
          </a>
        )
      },
    },
    {
      field: "productVariationDescription",
      flex: 2,
      headerName: "Name",
      renderCell: (params) => {
        return (
          <a
            href={`/cms/catalog/skus/${params.row.productVariationExternalId}`}
          >
            {params.value}
          </a>
        )
      },
    },
    {
      field: "virtual",
      flex: 1,
      type: "boolean",
      headerName: "Virtual",
      renderCell: (params) => {
        return params.value ? "Yes" : "No"
      },
    },
    {
      field: "supplierCatalogId",
      flex: 1,
      headerName: "Supplier Catalog ID",
    },
    {
      field: "supplierProductId",
      flex: 1,
      headerName: "Supplier Product ID",
    },
    {
      field: "supplierSystemId",
      flex: 1,
      headerName: "Supplier System ID",
    },
  ]

  const defaultOptions: ProductVariationsDataGridOptions = {
    page: 1,
    // todo: make this be driven by the backend? right now it's set in two different places
    sort: [{ field: "productVariationDescription", sort: "asc" }],
  }

  const fetchFunction = async (
    params: ServerSideDataGridParams
  ): Promise<void> => {
    const data = await getProductVariationsForSupplier(
      supplier.externalId,
      params
    )
    setGridData((prev) => ({
      ...prev,
      records: data.records,
      totalCount: data.totalCount,
    }))
  }

  const {
    filterModel,
    options,
    handlePageChange,
    handleFilterModelChange,
    handleSortModelChange,
  } = useServerSideDataGrid<ProductVariationsDataGridOptions>({
    trackHistory: false,
    defaultOptions,
    columnDefinitions: columns,
    fetchFunction,
    beforeFetch,
    afterFetch,
  })

  return (
    <div>
      <div className="canopy-mbe-12x d-flex align-items-end justify-content-space-between">
        <p className="canopy-mbe-0 canopy-typography-body-medium">
          Supplier SKUs are the current active versions of global Product
          Variations and contain Supplier-specific data.
        </p>
        {/*<CanopyButton
              iconStart="download"
              size="small"
              variant="secondary"
              as="a"
              href={`/cms/catalog/suppliers/${supplier.externalId}/product_variations.csv?${toRailsStyle(optionsToParams(options))}`}
          >
            Export
          </CanopyButton>*/}
      </div>
      <DataGridPro
        className="borderless"
        filterModel={filterModel}
        onFilterModelChange={handleFilterModelChange}
        loading={loading}
        columns={columns}
        rows={gridData.records}
        rowCount={gridData.totalCount}
        autoHeight
        getRowId={(row) => row.id}
        disableVirtualization={isTest()}
        hideFooterRowCount
        hideFooterSelectedRowCount
        filterMode="server"
        paginationMode="server"
        sortingMode="server"
        sortModel={options.sort}
        onSortModelChange={handleSortModelChange}
        components={{
          Toolbar: DataGridToolbar,
        }}
        componentsProps={{
          toolbar: { filter: true },
        }}
        pageSize={50}
        onPageChange={(page) => {
          handlePageChange(page + 1)
        }} // account for DataGrid's zero-based indexing
        //onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
        rowsPerPageOptions={[50]}
        pagination={true}
        density="compact"
      />
    </div>
  )
}

export default ProductVariationsDataGrid
