// @ts-strict-ignore
import React, { useState, useContext } from "react"
import Modal from "components/Modal"
import { Button, Form, Select } from "components/form"
import * as api from "./api"
import { AnalyticsContext } from "context/AnalyticsContext"
import { CreateSupplierTransferAttributes } from "applications/Workflow/containers/Review/sharedTypes"
import { formatAddress } from "utilities/address"
import Suggestion from "components/form/Select/Suggestion"
import InsuranceNetworkWarning from "components/InsuranceNetworkWarning"
import { NetworkCoverage } from "sharedTypes"
type Props = {
  dismiss(): void
  dmeOrderId: string
  onClick(params: CreateSupplierTransferAttributes): void
}

const SUPPLIER_TRANSFER_SELECT_PLACEHOLDER = "Select a Supplier..."
const SUPPLIER_TRANSFER_SEARCH_TERM_LENGTH = 3

const SupplierTransferForm = ({ dismiss, dmeOrderId, onClick }: Props) => {
  const [selectedSupplierId, setSelectedSupplierId] = useState(null)
  const analyticsContext = useContext(AnalyticsContext)
  const SearchResult = ({ result }) => {
    const hasNpi = result.npi === "N/A"
    return (
      <Suggestion key={result.value}>
        <div className="supplier-transfer-search-result">
          <div>
            <strong>
              <div>{result.label}</div>
            </strong>
            <div>{formatAddress(result.address)}</div>
            {hasNpi ? <></> : <div>{"NPI: " + result.npi}</div>}
          </div>

          <div className="out-of-network-warning-pill">
            <InsuranceNetworkWarning networkCoverage={result.networkCoverage} />
          </div>
        </div>
      </Suggestion>
    )
  }

  const getSuppliers = (term) => {
    return api.fetchSuppliers(term, dmeOrderId).then((results) => {
      if (term.length >= SUPPLIER_TRANSFER_SEARCH_TERM_LENGTH) {
        analyticsContext.trackSearchEvent(
          "supplier-transfer",
          "supplier-transfer-search-results"
        )
      }
      return results.map((result) => ({
        label: result.name,
        value: result.id,
        npi: result.npi,
        address: result.address,
        networkCoverage: result.networkCoverage as NetworkCoverage,
      }))
    })
  }

  const handleCloseModal = () => {
    dismiss()
    setSelectedSupplierId(null)
  }

  return (
    <Modal
      size="md"
      show
      cancel={handleCloseModal}
      title="Transfer to Another Supplier"
    >
      <Modal.Body>
        <Form
          onSubmit={() =>
            onClick({ replacementSupplierId: selectedSupplierId })
          }
        >
          {({ isSubmitting }) => (
            <>
              <p>
                Are you sure you want to transfer this order to another
                supplier? You will still be able to view the progress of the
                order.
              </p>
              <Select
                name="clinicalFacilityId"
                label="Transfer order to..."
                isSearchable
                placeholder={SUPPLIER_TRANSFER_SELECT_PLACEHOLDER}
                minLength={3}
                fetchOptions={getSuppliers}
                onChange={(supplierId) => {
                  setSelectedSupplierId(supplierId)
                  analyticsContext.trackSearchEvent(
                    "supplier-transfer",
                    "supplier-transfer-search-selection"
                  )
                }}
                renderOption={(result) => <SearchResult result={result} />}
              />
              <div className="text-right">
                <Button
                  type="button"
                  className="btn btn-link"
                  onClick={handleCloseModal}
                >
                  Cancel
                </Button>
                <Button
                  type="submit"
                  className="btn btn-brand"
                  disabled={!selectedSupplierId || isSubmitting}
                >
                  Transfer
                </Button>
              </div>
            </>
          )}
        </Form>
      </Modal.Body>
    </Modal>
  )
}

export default SupplierTransferForm
