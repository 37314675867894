import Model from "./model"
import { isStorageAvailable } from "utilities/detection"

const ZOOM_DIFF = 25
const MIN_ZOOM = 100
const MAX_ZOOM = 200

export const ZOOM_KEY = "viewer-zoom"
export const OFFSET_TOP_KEY = "viewer-offset-top"
export const OFFSET_LEFT_KEY = "viewer-offset-left"

export default class extends Model {
  fetch() {
    let zoom
    let offsetTop
    let offsetLeft
    const storage = this.options.storage
    if (isStorageAvailable(storage)) {
      zoom = Number(storage.getItem(ZOOM_KEY))
      offsetTop = Number(storage.getItem(OFFSET_TOP_KEY))
      offsetLeft = Number(storage.getItem(OFFSET_LEFT_KEY))
    }
    this.set("zoom", zoom || 100)
    this.set("offsetTop", offsetTop || 0)
    this.set("offsetLeft", offsetLeft || 0)
  }
  save() {
    const storage = this.options.storage
    if (isStorageAvailable(storage)) {
      storage.setItem(ZOOM_KEY, this.get("zoom"))
      storage.setItem(OFFSET_TOP_KEY, this.get("offsetTop"))
      storage.setItem(OFFSET_LEFT_KEY, this.get("offsetLeft"))
    }
  }
  zoomIn() {
    const current = this.get("zoom")
    const next = current + ZOOM_DIFF
    if (next <= MAX_ZOOM) {
      this.set("zoom", next)
    }
  }
  zoomOut() {
    const current = this.get("zoom")
    const next = current - ZOOM_DIFF
    if (next >= MIN_ZOOM) {
      this.set("zoom", next)
    }
  }
}
