import React from "react"
import {
  canToggleSatisfyMethod,
  ToggleSatisfyMethod,
} from "./ToggleSatisfyMethod"
import { DocumentationRowProps } from "./documentation-row-props"

type UploadOnlyDetailsProps = Pick<
  DocumentationRowProps,
  "permissions" | "updateSatisfyMethod" | "documentationRequirement"
>

export const ManualUploadDetails = (props: UploadOnlyDetailsProps) => {
  if (canToggleSatisfyMethod(props)) {
    return <ToggleSatisfyMethod {...props} />
  }
  return (
    <div className="color-dark-gray font-subparagraph">
      Use your own document(s)
    </div>
  )
}
