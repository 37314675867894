import React from "react"
import { SvgIcon } from "@material-ui/core"

export default (props: React.ComponentProps<typeof SvgIcon>) => (
  <SvgIcon {...props}>
    <g>
      <rect fill="none" height="24" width="24" />
    </g>
    <g>
      <g>
        <path d="M6.5 22C5.95 22 5.47933 21.8043 5.088 21.413C4.696 21.021 4.5 20.55 4.5 20V4C4.5 3.45 4.696 2.979 5.088 2.587C5.47933 2.19567 5.95 2 6.5 2H13.675C13.9417 2 14.196 2.05 14.438 2.15C14.6793 2.25 14.8917 2.39167 15.075 2.575L19.925 7.425C20.1083 7.60833 20.25 7.82067 20.35 8.062C20.45 8.304 20.5 8.55833 20.5 8.825V11.1L12.5 19.075V22H6.5ZM15 22C14.8667 22 14.75 21.95 14.65 21.85C14.55 21.75 14.5 21.6333 14.5 21.5V20.3C14.5 20.1667 14.5293 20.0333 14.588 19.9C14.646 19.7667 14.7167 19.6583 14.8 19.575L19.65 14.7L21.8 16.8L16.925 21.7C16.8417 21.8 16.7333 21.875 16.6 21.925C16.4667 21.975 16.3333 22 16.2 22H15ZM22.525 16.1L20.4 13.975L21.1 13.275C21.3 13.075 21.5417 12.975 21.825 12.975C22.1083 12.975 22.3417 13.075 22.525 13.275L23.225 14C23.4083 14.2 23.5 14.4373 23.5 14.712C23.5 14.9873 23.4083 15.2167 23.225 15.4L22.525 16.1ZM14.5 9H18.5L13.5 4V8C13.5 8.28333 13.596 8.52067 13.788 8.712C13.9793 8.904 14.2167 9 14.5 9Z" />
      </g>
    </g>
  </SvgIcon>
)
