import React, { ReactElement } from "react"
import { Assignee, SearchResult } from "sharedTypes"
import Button from "components/form/Button"
import { Form, Select } from "components/form"
import { getAssignees } from "./api"

type Props = {
  assignee?: Assignee
  setAssignee({ assigneeId }: { assigneeId: string }): void
  isSubmitting?: boolean
  fetchAssignees?: (term: string) => Promise<Assignee[]>
}

const getDefaultAssignees = (
  assignee: Assignee | undefined
): SearchResult[] => {
  if (!assignee) {
    return []
  }
  return [{ label: assignee.name, value: assignee.id }]
}

function AssigneeForm({
  assignee,
  setAssignee,
  isSubmitting,
  fetchAssignees = getAssignees,
}: Props): ReactElement {
  const fetchOptions = (term: string): Promise<SearchResult[]> => {
    return fetchAssignees(term).then((assignees: Assignee[]) => {
      return assignees.map((assignee: Assignee) => ({
        label: assignee.name,
        value: assignee.id,
      }))
    })
  }

  return (
    <ul className="list-unstyled list-bordered canopy-pbe-0 canopy-pbs-0 canopy-pis-12x canopy-pie-12x">
      {assignee && (
        <li className="canopy-pbe-12x canopy-pbs-8x">
          <Button
            className="btn btn-primary btn-block"
            disabled={isSubmitting}
            onClick={() => setAssignee({ assigneeId: "" })}
          >
            Unassign
          </Button>
        </li>
      )}
      <li className="canopy-pbe-12x">
        <Form
          initialValues={{ assigneeId: assignee?.id || "" }}
          onSubmit={({ assigneeId }) => setAssignee({ assigneeId })}
          submitOnChange
        >
          <Select
            isDisabled={isSubmitting}
            minLength={1}
            name="assigneeId"
            options={getDefaultAssignees(assignee)}
            fetchOptions={fetchOptions}
            autoFocus
            isSearchable
          />
        </Form>
      </li>
    </ul>
  )
}

export default AssigneeForm
