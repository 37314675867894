import React, { FunctionComponent, PropsWithChildren } from "react"
import { Box, Tab, TabProps } from "@material-ui/core"
import { TabContext, TabList, useTabContext } from "@material-ui/lab"
import * as styles from "./index.module.scss"
import { useHistory } from "react-router-dom"

export type SimpleTabsProps = {
  children: SimpleTabDefinition[]
  selectedTab?: string
  fullWidth?: boolean
  useLocationHash?: boolean
}

export type SimpleTabDefinition = {
  key: string
  label?: React.ReactNode
  content: React.ReactNode
}

const InternalTabPanel: FunctionComponent<PropsWithChildren<TabProps>> = ({
  children,
  value,
  tabIndex,
}): JSX.Element => {
  const { value: contextValue } = useTabContext() || {}
  return (
    <Box
      className="canopy-pbs-8x canopy-px-12x canopy-pbe-12x"
      role="tabpanel"
      style={{ display: value === contextValue ? "block" : "none" }}
      id={`tab-panel-${tabIndex}`}
      aria-labelledby={`tab-control-${tabIndex}`}
      key={value}
    >
      {children}
    </Box>
  )
}

/**
 * A very simple implementation of MaterialUI tabs with no advanced behavior;
 * this is useful for avoiding boilerplate within consuming components.
 * Note: this implementation does not trigger re-renders of each component when they are shown;
 * instead, it uses CSS to toggle visibility.
 *
 * If you're trying to opt in to history handling and it's breaking, make sure that somewhere up the component
 * stack you're wrapping your item in a `Router`.
 */
const SimpleTabs: React.FC<SimpleTabsProps> = (
  props: SimpleTabsProps
): JSX.Element => {
  const history = useHistory()

  const getLocationHash = (): string | undefined => {
    if (!useLocationHash || !history.location?.hash) {
      return undefined
    }

    // if the hash doesn't correspond to a tab key, ignore it
    const hash = history.location.hash.replace("#", "")
    return children.find((c) => hash === c.key)?.key
  }

  const { children, fullWidth, useLocationHash = false } = props

  // prefer, in order: a property passed to the component, the url hash, or the first tab
  const [selectedTab, setSelectedTab] = React.useState<string>(
    props.selectedTab || getLocationHash() || children[0].key
  )

  const handleChange = (event: React.ChangeEvent<{}>, newValue: string) => {
    setSelectedTab(newValue)
    if (useLocationHash) {
      history.push({ hash: newValue })
    }
  }

  return (
    <Box>
      <TabContext value={selectedTab}>
        <TabList
          className={styles.tablist}
          onChange={handleChange}
          indicatorColor="primary"
          variant={fullWidth ? "fullWidth" : undefined}
        >
          {children.map((definition: SimpleTabDefinition, index: number) => (
            <Tab
              value={definition.key}
              key={definition.key}
              label={<div>{definition.label || definition.key}</div>}
              className={styles.tab}
              id={`simple-tab-${index}`}
              aria-controls={`simple-tabpanel-${index}`}
              disableRipple
            />
          ))}
        </TabList>
        {children.map((definition: SimpleTabDefinition, index: number) => (
          <InternalTabPanel
            tabIndex={index}
            key={`tabpanel-${index}`}
            value={definition.key}
          >
            {definition.content}
          </InternalTabPanel>
        ))}
      </TabContext>
    </Box>
  )
}

export default SimpleTabs
