import querystring from "query-string"

export function parse(url) {
  const query = querystring.extract(url)
  return querystring.parse(query)
}

export function stringify(object) {
  return querystring.stringify(object, { arrayFormat: "bracket" })
}

export function params(url, object) {
  return stringify({ ...parse(url), ...object })
}
