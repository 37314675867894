// @ts-strict-ignore
import React from "react"
import { Select } from "components/form"
import { FacilityDetails, FacilitySearchResult } from "../../sharedTypes"
import FacilityOption from "./FacilityOption"
import { groupSearchResults } from "./utilities"

type Props = {
  defaultFacility?: FacilityDetails
  name: string
  search(term: string): Promise<FacilitySearchResult[]>
  onChange?(newValue: string, facility: FacilitySearchResult): void
}

const FacilitySelect = ({ defaultFacility, name, search, onChange }: Props) => {
  const buildOption = (facilitySearchResult: FacilitySearchResult) => ({
    ...facilitySearchResult,
    label: facilitySearchResult.name,
    value: facilitySearchResult.externalId,
  })
  const fetchOptions = (term: string) =>
    search(term).then((r) => groupSearchResults(r.map(buildOption)))
  const options = defaultFacility ? [buildOption(defaultFacility)] : []

  return (
    <Select
      fetchOptions={fetchOptions}
      options={options}
      onChange={onChange}
      isClearable
      isSearchable
      minLength={2}
      name={name}
      placeholder="Search by name or address"
      renderOption={(facilitySearchResult) => (
        <FacilityOption facilitySearchResult={facilitySearchResult} />
      )}
      unwrapped
    />
  )
}

export default FacilitySelect
