import React, { useState } from "react"
import * as styles from "./CanopyBackedFeedbackButton.module.scss"
import FeedbackModal from "./FeedbackModal"
import { EventCategory, trackEvent } from "utilities/tracking"
import { submitFeedback } from "utilities/userFeedback/api"
import { useToaster } from "components/Toaster"
import { EmployerType } from "utilities/url"
import { CanopyButton } from "@parachutehealth/canopy-button"

enum ModalEventTypes {
  OpenModalEvent = "feedback_modal_open",
  CloseModalEvent = "feedback_modal_close",
  SubmitModalEvent = "feedback_modal_submit",
}

type Props = {
  employerId?: string
  employerType?: EmployerType
  topics?: string[]
}

const CanopyBackedFeedbackButton: React.FC<Props> = ({
  employerId,
  employerType,
  topics,
}) => {
  const { persistentAlert } = useToaster()
  const [showModal, setShowModal] = useState(false)

  const cancel = () => {
    setShowModal(false)
    trackEvent(EventCategory.UserFeedback, ModalEventTypes.CloseModalEvent)
  }
  const onSubmit = ({
    feedback,
    topic,
  }: {
    feedback: string
    topic?: string
  }) => {
    setShowModal(false)
    submitFeedback({
      feedback,
      employerId,
      employerType,
      topic,
      submissionUrl: window.location.href,
    })
      .then(() =>
        persistentAlert({
          message: "Your feedback has been submitted!",
          severity: "success",
        })
      )
      .catch((e) =>
        persistentAlert({
          message: `there was an error submitting your feedback: ${JSON.stringify(
            e.response.data
          )} Please try again or reach out to customer support`,
          severity: "error",
        })
      )
    trackEvent(EventCategory.UserFeedback, ModalEventTypes.SubmitModalEvent)
  }

  const onClick = () => {
    setShowModal(true)
    trackEvent(EventCategory.UserFeedback, ModalEventTypes.OpenModalEvent)
  }

  return (
    <>
      <CanopyButton
        className={`${styles.button}`}
        variant="secondary"
        onClick={onClick}
      >
        Share feedback
      </CanopyButton>
      <FeedbackModal
        cancel={cancel}
        onSubmit={onSubmit}
        show={showModal}
        topics={topics}
      />
    </>
  )
}

export default CanopyBackedFeedbackButton
