import React from "react"
import { PackageConfiguration } from "../sharedTypes"
import { Form, NumberField } from "components/form"
import { FormikValues } from "formik"

type Props = {
  onSubmit(params: FormikValues): Promise<PackageConfiguration> | Promise<void>
  packageConfiguration: PackageConfiguration
}

function QuantityForm(props: Props) {
  const { onSubmit, packageConfiguration } = props
  const { quantity } = packageConfiguration

  return (
    <Form initialValues={{ quantity }} onSubmit={onSubmit}>
      <NumberField label="Quantity" name="quantity" min={1} submitOnBlur />
    </Form>
  )
}

export default QuantityForm
