import React, { useRef } from "react"
import { setFavicon } from "utilities/browser/favicon"
import { DmeOrder } from "sharedTypes"
import { useWebSockets } from "hooks/useWebSockets"
import { isDocumentHidden } from "utilities/browser/document"
import { useVisibilityChange } from "hooks/useVisibilityChange"

type Props = {
  dmeOrder: DmeOrder
}

const ALERT_TOGGLE_INTERVAL = 2000

function TitleBar({ dmeOrder }: Props) {
  const initialDocumentTitle = useRef(document.title)
  const alertState = useRef<string>("none")

  const showNewCommentTitle = () => {
    document.title = "New comment"
    if (alertState.current === "newComment") {
      setTimeout(hideNewCommentTitle, ALERT_TOGGLE_INTERVAL)
    }
  }

  const hideNewCommentTitle = () => {
    document.title = initialDocumentTitle.current
    if (alertState.current === "newComment") {
      setTimeout(showNewCommentTitle, ALERT_TOGGLE_INTERVAL)
    }
  }

  useVisibilityChange((visible) => {
    if (visible) {
      alertState.current = "none"
      setFavicon("default")
      hideNewCommentTitle()
    }
  })

  const onNewEvent = () => {
    if (isDocumentHidden()) {
      alertState.current = "newComment"
      setFavicon("alert")
      showNewCommentTitle()
    }
  }
  useWebSockets(
    { channel: "EventsChannel", id: dmeOrder.id },
    { received: onNewEvent }
  )

  return <div />
}

export default TitleBar
