import { InsuranceRank as SharedInsuranceRank } from "sharedTypes"

export interface InsuranceCheckResponse {
  status: string
  policyStatuses: {
    primary: { active: boolean }
    secondary: { active: boolean }
    tertiary: { active: boolean }
  }
}

export type InsuranceRank = SharedInsuranceRank

export type UpdateParams = {
  rank: InsuranceRank
  memberNumber: string
  insuranceProductId: string
  carrierName: string
}

export type CarrierSearchSuggestion = {
  name: string
  externalId: string
}

export type Prediction = {
  payerName?: string
  payerId: string
  probability: number
}

export type CarrierAuthorizationParams = {
  carrierAuthorizationNumber: string
}

export type CarrierAutoUpdateResponse = {
  updated: boolean
  carrier: {
    name: string
    externalId: string
  }
}

export type CarrierAuthorization = {
  parentAuthorizationNumber: string
  updatedAt: string
}

export type CarrierAuthorizationsResponse = {
  carrierAuthorizations: CarrierAuthorization[]
}

export enum InsuranceVerificationInterfaceStatus {
  Blank,
  Dirty,
  Predicting,
  Verifying,
  Verified,
  VerifiedAndUpdated,
  Unverified,
  Error,
}
