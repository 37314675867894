import React, { useContext } from "react"
import { ViewContext } from "../view/viewContext"

export const ExternalSystemBranch = ({
  externalSystemBranchId: branchId,
  supplierId,
}: {
  externalSystemBranchId?: string
  supplierId?: string
}) => {
  const {
    state: {
      filterOptions: { externalSystemBranches },
    },
  } = useContext(ViewContext)

  if (!branchId && supplierId) {
    const defaultBranch = externalSystemBranches.find((branch) =>
      branch.defaultSupplierIds?.includes(supplierId)
    )
    return defaultBranch ? <>{defaultBranch?.name}</> : null
  }

  const branch = externalSystemBranches.find((branch) => branch.id === branchId)
  return <>{branch?.name}</>
}
