import React from "react"
import { Form } from "components/form"
import DatePickerField from "components/form/DatePickerField"
import { deliveryDateLabel } from "../utilities/displayOfferingType"
import { isDateInPast, isDateValid } from "utilities/date"
import * as styles from "./index.module.scss"
import { addYears, isWithinRange, subYears } from "date-fns"
import { FormikValues } from "formik"

interface Props {
  createdAt: Date
  fulfillment: {
    deliveryDate: string
    offeringType?: string
  }
  onSubmit: (
    values: FormikValues,
    { setErrors }: { setErrors: (errors: string[]) => void }
  ) => void
}

const DeliveryDateSelector: React.FC<Props> = ({
  createdAt,
  fulfillment,
  onSubmit,
}) => {
  const isWithinOneYearOfCreation = (deliveryDate): boolean => {
    const startDate = subYears(createdAt, 1)
    const endDate = addYears(createdAt, 1)
    return isWithinRange(deliveryDate, startDate, endDate)
  }
  const validateDeliveryDate = ({ deliveryDate }) => {
    if (!isDateValid(deliveryDate)) {
      return { deliveryDate: "must be a valid date" }
    }
    if (!isWithinOneYearOfCreation(deliveryDate)) {
      return { deliveryDate: "must be +/- 12 months of creating order" }
    }
  }

  const DateInPastWarning = ({ deliveryDate, errors }) => {
    if (isDateInPast(deliveryDate) && !errors.deliveryDate) {
      return (
        <div className={styles.warning}>
          Heads up! Requested delivery date is in the past.
        </div>
      )
    } else {
      return null
    }
  }

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={{ deliveryDate: fulfillment.deliveryDate }}
      validate={validateDeliveryDate}
      submitOnChange
    >
      {({ values: { deliveryDate }, errors }) => (
        <>
          <DatePickerField
            label={deliveryDateLabel(fulfillment.offeringType)}
            name="deliveryDate"
            width="sm"
          />
          <DateInPastWarning deliveryDate={deliveryDate} errors={errors} />
        </>
      )}
    </Form>
  )
}

export default DeliveryDateSelector
