import $ from "jquery"
import { Keycode } from "enums/keycodes"
import { getKeyCode } from "utilities/browser/event"
import { isAndroid } from "utilities/detection"

$(document).on("click", ".js-focus", function () {
  const target = $(this).attr("data-focus")
  $(`[data-target="${target}"]`).focus().trigger("js:focus")
})

if (isAndroid()) {
  $(document).on("keypress", "[data-focus-target]", function (event) {
    if (getKeyCode(event) === Keycode.Enter) {
      event.preventDefault()
      event.stopPropagation()
      const target = this.getAttribute("data-focus-target")
      $(target).first().focus()
    }
  })
}
