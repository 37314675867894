import React from "react"
import SelectFilter from "components/SelectFilter"

export const PatientActivityFilter = (props: {
  patientActivity: string[] | undefined
  onChange: (params: { patientActivity: string[] }) => void
}) => {
  return (
    <SelectFilter
      onChange={(values) => {
        props.onChange(values)
      }}
      options={[
        { label: "Not Contacted", value: "Not Contacted" },
        { label: "Patient Contacted", value: "Patient Contacted" },
        { label: "Clinician Confirmed", value: "Clinician Confirmed" },
        { label: "Wrong Clinician", value: "Wrong Clinician" },
      ]}
      secondaryOptions={[
        { label: "Patient still needs product", value: "still_needed" },
        { label: "Patient doesn't need product", value: "not_needed" },
      ]}
      name="patientActivity"
      secondaryName="productStillNeeded"
      label="Patient Activity"
      secondaryLabel="Product Need (Unsigned/Denied)"
      singular={true}
      initialValues={props.patientActivity}
      size="large"
    />
  )
}
