import React from "react"
import { unique } from "utilities/array"

interface Props {
  error?: string | string[]
}

function renderErrors(errors: string[]) {
  return errors.map((text, index) => {
    if (index === errors.length - 1) {
      return text
    }
    return (
      <React.Fragment key={text}>
        {text}
        <br />
      </React.Fragment>
    )
  })
}

export default function ErrorText({ error }: Props) {
  if (!error) {
    return null
  }
  return (
    <div className="color-danger font-xs text-left canopy-mbs-2x">
      {Array.isArray(error) ? renderErrors(unique(error)) : error}
    </div>
  )
}
