import React from "react"
import { fullName } from "utilities/person"
import { FacilityDetails, User } from "../sharedTypes"
import { SignPath as SignatureRequestsDashboardUrl } from "../.././DoctorReviewerDashboard/routes"
import classNames from "classnames"

interface Props {
  dmeOrderUrl: string
  selectedFacility: FacilityDetails
  onChatLinkClick(): void
  linkBackToReviewDashboardDoctorId: string
}

function RequestSent({
  dmeOrderUrl,
  selectedFacility,
  onChatLinkClick,
  linkBackToReviewDashboardDoctorId,
}: Props) {
  const adminUsers = selectedFacility.adminUsers
  const renderAdmin = (admin: User, index: number) => {
    return (
      <div className="well-section" key={`${admin.email}-${index}`}>
        <strong>{fullName(admin)}</strong>
        <div className="color-dark-gray truncate">{admin.email}</div>
      </div>
    )
  }

  const NeedHelpSection = ({
    children,
    className = "canopy-my-24x",
  }: {
    children?: React.ReactNode
    className?: string
  }) => {
    return (
      <p className={classNames("text-center", className)}>
        {children}
        Need help?{" "}
        <a className="link link-primary" onClick={onChatLinkClick}>
          Chat with us.
        </a>
      </p>
    )
  }

  const ButtonLink = ({
    children,
    href,
    containerStyles,
    buttonStyles,
  }: {
    children: React.ReactNode
    href: string
    containerStyles?: string
    buttonStyles?: string
  }) => {
    return (
      <div className={containerStyles}>
        <a className={classNames("btn btn-brand", buttonStyles)} href={href}>
          {children}
        </a>
      </div>
    )
  }

  const renderFooter = () => {
    if (linkBackToReviewDashboardDoctorId) {
      return (
        <>
          <ButtonLink
            href={SignatureRequestsDashboardUrl(
              linkBackToReviewDashboardDoctorId
            )}
            containerStyles="text-center"
            buttonStyles="btn-large canopy-mbs-12x"
          >
            Return to signature requests
          </ButtonLink>
          <NeedHelpSection />
        </>
      )
    } else if (dmeOrderUrl) {
      return (
        <>
          <NeedHelpSection>
            While you wait to be approved, you can view and track the order you
            just completed.
            <br />
          </NeedHelpSection>
          <ButtonLink href={dmeOrderUrl} buttonStyles="btn-block">
            View Order
          </ButtonLink>
        </>
      )
    } else {
      return <NeedHelpSection />
    }
  }

  return (
    <>
      <h1>Request sent to facility admins</h1>
      <p className="text-center text-xs-left canopy-mbe-24x">
        A request to join {selectedFacility.name} has been sent to the following
        people. Once you’ve been accepted you will be added to the facility to
        view and place orders.
      </p>

      {adminUsers.length > 0 && (
        <div className="sign-up-form">
          <div className="well well-sectioned">
            {adminUsers.map(renderAdmin)}
          </div>
        </div>
      )}
      {renderFooter()}
    </>
  )
}

export default RequestSent
