import React, { ReactNode } from "react"
import * as Sentry from "@sentry/react"

interface Props {
  children: ReactNode
  errorMessage?: string
  onError?: (error?: Error, errorInfo?: any) => Promise<void>
}

interface State {
  hasError: boolean
}

class ErrorBoundary extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = { hasError: false }
  }

  public static getDerivedStateFromError(_: Error): State {
    // Update state so the next render will show the fallback UI.
    return { hasError: true }
  }

  public componentDidCatch(error: Error, errorInfo) {
    this.props.onError && this.props.onError(error, errorInfo)
    Sentry.captureException(error, { extra: { errorInfo: errorInfo } })
  }

  public render() {
    if (this.state.hasError) {
      const DEFAULT_ERROR_MESSAGE =
        "Sorry, an internal error has occurred. Please contact support."
      const message = this.props.errorMessage || DEFAULT_ERROR_MESSAGE

      // custom fallback UI
      return <div className="border-danger canopy-p-12x">{message}</div>
    }

    return this.props.children
  }
}

export default ErrorBoundary
