import { FacilityDetails } from "../../sharedTypes"
import { User } from "../../../FacilitySignUp/shared/sharedTypes"
import {
  trackJoinFacility,
  trackRequestToJoinFacility,
  trackReviewDetails,
} from "../../../FacilitySignUp/shared/tracking"

export const isNewFacility = (facility: FacilityDetails): boolean =>
  !facility.externalId

export const isSignable = (facility: FacilityDetails): boolean =>
  !isNewFacility(facility) && !facility.signedAgreements

const isOnboarded = (facility: FacilityDetails): boolean =>
  !isNewFacility(facility) &&
  facility.signedAgreements &&
  !!facility.adminUsers.length

const DEFAULT_TEXT = "Join Facility"
export const buttonText = (facility?: FacilityDetails): string => {
  if (!facility) {
    return DEFAULT_TEXT
  }
  if (isNewFacility(facility)) {
    return "Review Details and Join Facility"
  }
  if (isOnboarded(facility)) {
    return "Request to Join Facility"
  }
  return DEFAULT_TEXT
}

export const promptTitle = ({ coworkers }: FacilityDetails): string =>
  coworkers ? "Your coworkers are already here!" : "You're the first one here!"

export const promptContent = (facility: FacilityDetails): string => {
  if (isNewFacility(facility)) {
    return "Click the button below to review facility details and join this facility on Parachute Health."
  }
  if (isSignable(facility)) {
    return "Agree to the Facility Terms of Use to join this healthcare facility and start placing orders on Parachute Health."
  }
  if (isOnboarded(facility)) {
    return "Send a request to join them at this healthcare facility. Once you’ve been added you can view and place orders."
  }
  return "Join this healthcare facility and start placing orders on Parachute Health."
}

export const trackFacilityEvent = (user: User, facility?: FacilityDetails) => {
  if (!facility) {
    trackJoinFacility(user)
  } else if (isNewFacility(facility)) {
    trackReviewDetails(user)
  } else if (isOnboarded(facility)) {
    trackRequestToJoinFacility(user)
  } else {
    trackJoinFacility(user)
  }
}
