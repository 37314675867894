import { Event, EventAction } from "sharedTypes"

const REFERRAL_ACTIONS = [
  EventAction.Comment,
  EventAction.SupplierSendBackInstructions,
]

export const isComment = (event: Event): boolean =>
  isGlobalComment(event) || isInternalComment(event) || isSupplierComment(event)

export const isGlobalComment = (event: Event): boolean =>
  REFERRAL_ACTIONS.includes(event.action)

export const isInternalComment = (event: Event): boolean =>
  event.action === EventAction.InternalComment

export const isSupplierComment = (event: Event): boolean =>
  event.action === EventAction.SupplierComment
