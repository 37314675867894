import * as React from "react"
import { format as formatDate } from "utilities/date"
import { GridRenderCellParams } from "@mui/x-data-grid-pro"

import { isNullOrUndefined } from "../../../../../utilities/isNullOrUndefined"

const renderTimestamp = (params: GridRenderCellParams): JSX.Element | null => {
  if (isNullOrUndefined(params.value)) {
    return null
  }

  return (
    <time dateTime={params?.value?.toString()}>
      {formatDate(params.value, "MM/DD/YYYY [at] h:mmA")}
    </time>
  )
}

export default renderTimestamp
