import React, { useState } from "react"
import { DmeOrder } from "sharedTypes"
import InternalFulfillmentEditor from "applications/FulfillmentEditor/components/InternalFulfillmentEditor"
import { FormData } from "applications/FulfillmentEditor/components/FulfillmentForm"
import Modal from "components/Modal"
import Link from "components/Link"
import { EventCategory, trackEvent } from "utilities/tracking"

type Props = {
  dmeOrder: DmeOrder
  updateFulfillment(id: string, values: FormData): Promise<void>
  linkClassNames?: string
}

export default (props: Props) => {
  const [isOpen, setOpen] = useState(false)
  const { dmeOrder, updateFulfillment, linkClassNames } = props

  const doneModal = () => {
    setOpen(false)
    trackEvent(
      EventCategory.FulfillmentStatusModal,
      "done_fulfillment_status_modal"
    )
  }
  const cancelModal = () => {
    setOpen(false)
    trackEvent(
      EventCategory.FulfillmentStatusModal,
      "close_fulfillment_status_modal"
    )
  }

  const openModal = () => {
    setOpen(true)
    trackEvent(
      EventCategory.FulfillmentStatusModal,
      "open_fulfillment_status_modal"
    )
  }

  return (
    <>
      <Link className={`link small ${linkClassNames}`} onClick={openModal}>
        Update Fulfillment Status
      </Link>
      <Modal title="Fulfillment Status" show={isOpen} cancel={cancelModal}>
        <Modal.Body>
          <InternalFulfillmentEditor
            dmeOrder={dmeOrder}
            updateFulfillment={updateFulfillment}
          />
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-primary" type="button" onClick={doneModal}>
            Done
          </button>
        </Modal.Footer>
      </Modal>
    </>
  )
}
