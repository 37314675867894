import React, { useState } from "react"
import Button from "components/form/Button"

type Props = {
  className?: string
  children?: React.ReactNode
  onClick(): Promise<void>
  disabled?: boolean
  type: "submit" | "button" | "reset"
}
function ActionButton({ className, disabled, onClick, children, type }: Props) {
  const [processing, setProcessing] = useState(false)
  const clickHandler = function () {
    setProcessing(true)
    return onClick().catch(() => setProcessing(false))
  }

  return (
    <Button
      className={className}
      disabled={processing || disabled}
      loading={processing}
      onClick={clickHandler}
      type={type}
    >
      {children}
    </Button>
  )
}

export default ActionButton
