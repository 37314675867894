import { post } from "services/api"
import { userFeedbackSubmitUrl } from "./urls"
import { currentOrderId } from "applications/Workflow/urls"
import { getEmployerType, getEmployerId, EmployerType } from "utilities/url"

type FeedbackParams = {
  submissionUrl: string
  feedback: string
  topic?: string
  employerId?: string
  employerType?: EmployerType
}

export const submitFeedback = ({
  submissionUrl,
  feedback,
  topic,
  employerId,
  employerType,
}: FeedbackParams) =>
  post(userFeedbackSubmitUrl, {
    submissionUrl,
    feedback,
    topic,
    dmeOrderId: currentOrderId(),
    feedbackableEmployerId: getEmployerId(employerId),
    feedbackableEmployerType: getEmployerType(employerType),
  })
