import { employerPrefix } from "utilities/url"

export function createdBySupplierSubpageUrl(employerId: string): string {
  return employerPrefix("ClinicalFacility", employerId) + "/created_by_supplier"
}

export function newMessagesSubpageUrl(employerId: string): string {
  return employerPrefix("ClinicalFacility", employerId) + "/new_messages"
}

export function sentBackForReviewSubpageUrl(employerId: string): string {
  return (
    employerPrefix("ClinicalFacility", employerId) + "/sent_back_for_review"
  )
}
