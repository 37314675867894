import { get } from "services/api"
export type Supplier = { id: string; name: string }
export const getSuppliers = async (
  supplierOrganizationId: string,
  allowAllRoles: boolean = false
): Promise<Supplier[]> => {
  const url = `/u/r/${supplierOrganizationId}/suppliers.json`
  return get(url, { allowAllRoles }).then((res) => res.data.result || [])
}

export const urlForCreatingOrderWithSupplier = (supplierExternalId) =>
  `/u/s/${supplierExternalId}/orders/new`
