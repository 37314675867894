// @ts-strict-ignore
import React, { ReactElement } from "react"
import { SearchResult } from "sharedTypes"
import { Form, Select } from "components/form"
import { SalesRep } from "../../sharedTypes"

type Props = {
  salesRep?: SalesRep
  setSalesRep({ salesRepId }: { salesRepId: string }): void
  isSubmitting?: boolean
  getSalesReps(term: string): Promise<SalesRep[]>
}

const getDefaultSalesReps = (salesRep: SalesRep): SearchResult[] => {
  if (!salesRep) {
    return []
  }
  return [{ label: salesRep.name, value: salesRep.externalId }]
}

function SalesRepForm({
  salesRep,
  setSalesRep,
  isSubmitting,
  getSalesReps,
}: Props): ReactElement {
  const fetchOptions = (term: string): Promise<SearchResult[]> => {
    return getSalesReps(term).then((salesReps: SalesRep[]) => {
      return salesReps.map((salesRep: SalesRep) => ({
        label: salesRep.name,
        value: salesRep.externalId,
      }))
    })
  }

  return (
    <>
      <h4>Select Sales Rep</h4>
      <Form
        initialValues={{ salesRepId: salesRep?.externalId || "" }}
        onSubmit={({ salesRepId }) => setSalesRep({ salesRepId })}
        submitOnChange
      >
        <Select
          isDisabled={isSubmitting}
          minLength={1}
          name="salesRepId"
          options={getDefaultSalesReps(salesRep)}
          fetchOptions={fetchOptions}
          autoFocus
          isSearchable
        />
      </Form>
    </>
  )
}

export default SalesRepForm
