// @ts-strict-ignore
import React from "react"
import { ErrorMessage, Field } from "formik"
import SuperText from "components/SuperText"
import SubText from "components/SubText"
import classNames from "classnames"

const errorMsgFor = (name) => (
  <ErrorMessage
    name={name}
    render={(msg) => <div className="color-danger">{msg}</div>}
  />
)

const ADDON_POSITION = { INSIDE: "inside", OUTSIDE: "outside" }

type Option = {
  label: string | React.ReactNode
  value: string | boolean | number
  callout?: React.ReactNode
  addOnPosition?: string
  addOn?(params: { form; field }): React.ReactElement
}

type Props = {
  name: string
  label?: string
  supertext?: string | React.ReactElement
  subtext?: string | React.ReactElement
  id?: string
  block?: boolean
  centered?: boolean
  options: Option[]
  onChange?: React.ChangeEventHandler<HTMLInputElement>
  className?: string
  disabled?: boolean
  deselectable?: boolean
  unwrapped?: boolean
  labelClassName?: string
  render?({
    label,
    renderRadio,
    renderField,
    error,
    options,
    name,
  }): React.ReactElement
}

function RadioButtons({
  id,
  label,
  supertext,
  subtext,
  name,
  options,
  onChange,
  block,
  centered,
  disabled,
  deselectable,
  unwrapped,
  render,
  className,
  labelClassName,
}: Props) {
  const renderRadioButtons = ({ field, form }) =>
    options.map((option) => renderRadio(option, { field, form }))
  const renderRadio = (option, { field, form }) => {
    const inputId = `${id || name}-${option.value}`
    const handleClick = () => {
      if (deselectable && field.value === option.value) {
        form.setFieldValue(name, null)
      }
    }

    const handleChange = (event) => {
      onChange && onChange(event)
      form.setFieldValue(name, option.value)
    }
    const addOnPosition = option.addOnPosition || ADDON_POSITION.OUTSIDE
    const addOn = option.addOn && option.addOn({ field, form })
    return (
      <div
        key={inputId}
        className={classNames("radio", className, {
          block: block,
          centered: centered,
          disabled: disabled,
          "canopy-mbs-0": supertext,
          "canopy-mbe-0": subtext,
        })}
      >
        <input
          type="radio"
          id={inputId}
          name={name}
          value={option.value}
          checked={field.value === option.value}
          onChange={handleChange}
          onClick={handleClick}
          disabled={disabled}
        />
        <label className="col-form-label" htmlFor={inputId}>
          {option.callout}
          {option.label}
          {addOnPosition === ADDON_POSITION.INSIDE && addOn}
        </label>
        {addOnPosition === ADDON_POSITION.OUTSIDE && addOn}
      </div>
    )
  }

  const renderField = (render) => {
    return <Field name={name}>{render}</Field>
  }

  const error = errorMsgFor(name)

  if (render) {
    return render({
      label,
      renderRadio,
      renderField,
      error,
      options,
      name,
    })
  }

  return (
    <div className={classNames({ "form-group": !unwrapped })}>
      {label && (
        <div>
          <label
            className={classNames("col-form-label", labelClassName)}
            htmlFor={name}
          >
            {label}
          </label>
        </div>
      )}
      <SuperText content={supertext} />
      <div>{renderField((props) => renderRadioButtons(props))}</div>
      <SubText content={subtext} />
      {error}
    </div>
  )
}

RadioButtons.defaultProps = {
  className: "primary",
  labelClassName: "",
}

export default RadioButtons
