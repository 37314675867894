import { post } from "services/api"
import { loginUrl } from "./urls"

export const login = (
  loginParams: {
    login: string
    password: string
  },
  redirectTo: string
): Promise<{
  redirectTo?: string
}> => {
  return post(loginUrl(), { user: loginParams, redirect: redirectTo }) // please make sure this stays `redirect`, not `redirect_to`, see https://parachutehealth.atlassian.net/browse/WF-27214
    .then(({ data }) => data)
    .catch(({ response }) => Promise.reject(response.data))
}
