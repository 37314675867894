// @ts-strict-ignore
import React from "react"
import { ErrorMessage, Field } from "formik"
import classNames from "classnames"
import { SelectOption, Form } from "../sharedTypes"
import { Form as FormType } from "components/form/sharedTypes"

const NEW_VALUE = "__new_value__"

type Props = {
  name: string
  label?: string
  disabled?: boolean
  allowBlank?: boolean
  blankLabel?: string
  options: SelectOption[]
  onAddNew?(form: FormType): void
  addNewLabel?: string
  unwrapped?: boolean
  inline?: boolean
  autoFocus?: boolean
  afterChange?(oldValue: string, newValue: string): void
}

function NativeSelect({
  afterChange,
  label,
  name,
  options,
  disabled,
  allowBlank,
  blankLabel,
  onAddNew,
  addNewLabel,
  autoFocus,
  unwrapped,
  inline,
}: Props) {
  type renderSelectProps = {
    field: { value: string }
    form: Form
  }

  const renderSelect = ({ field, form }: renderSelectProps) => {
    const onChange = (event) => {
      const oldValue = field.value
      const newValue = event.target.value
      if (newValue === NEW_VALUE) {
        onAddNew(form)
      } else {
        form.setFieldValue(name, newValue)
      }

      afterChange && afterChange(oldValue, newValue)
    }

    const fullOptions =
      allowBlank || blankLabel
        ? [{ value: "", label: blankLabel || "" } as SelectOption].concat(
            options
          )
        : options
    return (
      <select
        id={name}
        name={name}
        value={field.value}
        disabled={disabled}
        onChange={onChange}
        className={classNames("form-control", { "canopy-mbe-0": inline })}
        autoFocus={autoFocus}
      >
        {fullOptions.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
        {onAddNew && (
          <option key={NEW_VALUE} value={NEW_VALUE}>
            {addNewLabel}
          </option>
        )}
      </select>
    )
  }

  return (
    <div
      className={classNames({
        "form-group": !unwrapped,
        "form-inline": inline,
      })}
    >
      {label && (
        <label
          className={classNames("col-form-label", { "canopy-mie-4x": inline })}
          htmlFor={name}
        >
          {label}
        </label>
      )}
      <Field name={name}>{renderSelect}</Field>
      <ErrorMessage
        name={name}
        render={(msg) => <div className="color-danger">{msg}</div>}
      />
    </div>
  )
}

export default NativeSelect
