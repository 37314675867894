import React, { useState } from "react"
import { CanopyButton } from "@parachutehealth/canopy-button"
import { CanopyDialogModal } from "@parachutehealth/canopy-dialog-modal"
import { CanopyPickerField } from "@parachutehealth/canopy-picker-field"
import { OptionItem } from "@parachutehealth/canopy-combobox-field"
import * as styles from "./ServiceAreaStateModal.module.scss"

interface Props {
  initialServiceAreaState: string
  setServiceAreaState: (state: string) => void
  setShowServiceAreaStateModal: (show: boolean) => void
  showServiceAreaStateModal: boolean
  stateOptions: OptionItem[]
}

const ServiceAreaStateModal: React.FC<Props> = ({
  initialServiceAreaState,
  setServiceAreaState,
  setShowServiceAreaStateModal,
  showServiceAreaStateModal,
  stateOptions,
}) => {
  const [currentState, setCurrentState] = useState<string>(
    initialServiceAreaState
  )

  const cancel = () => setShowServiceAreaStateModal(false)

  return (
    <CanopyDialogModal
      size="medium"
      header="Service Area"
      open={showServiceAreaStateModal}
      onClose={cancel}
      primaryFooterButton={
        <CanopyButton
          variant="primary"
          onClick={() => {
            setServiceAreaState(currentState)
            setShowServiceAreaStateModal(false)
          }}
        >
          Save
        </CanopyButton>
      }
      secondaryFooterButton={
        <CanopyButton variant="secondary" onClick={cancel}>
          Cancel
        </CanopyButton>
      }
    >
      <div className={styles.modalBody}>
        <CanopyPickerField
          label="Service Area State"
          hiddenLabel
          defaultValue={currentState}
          options={stateOptions}
          onChange={(newValue) =>
            setCurrentState((newValue as OptionItem).value)
          }
        />
      </div>
    </CanopyDialogModal>
  )
}

export default ServiceAreaStateModal
