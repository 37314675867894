import React, { ReactElement } from "react"
import SelectFilter from "components/SelectFilter"
import { Category } from "graphql/__generated__/graphql"

interface Props {
  productSubcategories: Category[]
  fetchProductSubcategories(
    term: string
  ): Promise<{ label: string; value: string }[]>
  initialProductSubcategoryIds: string[]
  onChange: (values: { productSubcategoryIds: string[] }) => void
}

function ProductTypeSelect({
  productSubcategories,
  fetchProductSubcategories,
  initialProductSubcategoryIds,
  onChange,
}: Props): ReactElement<Props> {
  const options = productSubcategories.map((productSubcategory) => {
    return {
      label: productSubcategory.name,
      value: productSubcategory.id,
    }
  })

  return (
    <SelectFilter
      name="productSubcategoryIds"
      label="Product Types"
      initialValues={initialProductSubcategoryIds}
      options={options}
      fetchOptions={fetchProductSubcategories}
      onChange={onChange}
      minLength={0}
      enableReinitialize
    />
  )
}

export default ProductTypeSelect
