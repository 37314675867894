import { Address, DmeOrder } from "sharedTypes"

function isBillingAddressValid(dmeOrder: DmeOrder): boolean {
  if (dmeOrder.billingAddressRequired) {
    return !!dmeOrder.billingAddressId
  }
  return true
}

export function isPatientRequiredBillingInfoComplete(
  dmeOrder: DmeOrder
): boolean {
  return !!dmeOrder.billingPhoneNumber && isBillingAddressValid(dmeOrder)
}

export function isDeliveryInfoComplete(
  address: Address,
  billingPhoneNumber: string
): boolean {
  if (!address) return true
  return (
    !!address.line1 &&
    !!address.city &&
    !!address.zip &&
    !!address.state &&
    !!billingPhoneNumber
  )
}
