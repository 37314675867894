import ReactTextMask from "react-text-mask"

export const DATE_MASK = [
  /\d/,
  /\d/,
  "/",
  /\d/,
  /\d/,
  "/",
  /\d/,
  /\d/,
  /\d/,
  /\d/,
]
export const PHONE_NUMBER_MASK = [
  "(",
  /[2-9]/,
  /\d/,
  /\d/,
  ")",
  " ",
  /\d/,
  /\d/,
  /\d/,
  "-",
  /\d/,
  /\d/,
  /\d/,
  /\d/,
]

export default ReactTextMask
