import React from "react"
import { Doctor } from "sharedTypes"
import { highlight } from "utilities/select"
import Suggestion from "components/form/Select/Suggestion"

type Props = {
  doctor: Doctor
  query: string
  isHighlighted?: boolean
}

function DoctorSuggestion({ doctor, query, isHighlighted }: Props) {
  const address = [doctor.city, doctor.state].filter(Boolean).join(", ")

  return (
    <Suggestion key={doctor.doctorId} isHighlighted={isHighlighted}>
      {highlight(
        `${doctor.firstName} ${doctor.lastName} - ${doctor.npi}`,
        query
      )}
      {address && <div className="float-right color-dark-gray">{address}</div>}
      {doctor.clinicalOrganizations.length > 0 && (
        <>
          <br />
          <small>{doctor.clinicalOrganizations.join(", ")}</small>
        </>
      )}
    </Suggestion>
  )
}

DoctorSuggestion.defaultProps = {
  isHighlighted: false,
}

export default DoctorSuggestion
