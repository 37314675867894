import Suggestion from "components/form/Select/Suggestion"
import React from "react"

export type SearchableFields = (
  | "name"
  | "date_of_birth"
  | "supplier_system_patient_id"
)[]

interface Props {
  minlength: number
  searchableFields: SearchableFields
}

const joinWithOrAtTheEnd = (arr: string[]) => {
  if (arr.length === 0) return ""
  if (arr.length === 1) return arr[0]
  if (arr.length === 2) return arr[0] + " or " + arr[1]
  const last = arr[arr.length - 1]
  return `${arr.slice(0, -1).join(", ")}, or ${last}`
}

const displayStrings = {
  name: "Name",
  date_of_birth: "DOB",
  supplier_system_patient_id: "SSPID",
}
const displayField = (key: string) => {
  return displayStrings[key]
}

export const SearchPrompt = ({ minlength, searchableFields }: Props) => {
  const searchableFieldDisplayStrings = searchableFields
    .map(displayField)
    .filter((s) => s)
  const hasSearchableFields = searchableFieldDisplayStrings?.length > 0
  return (
    <Suggestion small unselectable>{`Type ${minlength}${
      hasSearchableFields ? `+` : " or more"
    } characters to search${
      hasSearchableFields ? " by: " : ""
    }${joinWithOrAtTheEnd(searchableFieldDisplayStrings)}`}</Suggestion>
  )
}
