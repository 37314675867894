// @ts-strict-ignore
import React, { useState } from "react"
import Modal from "components/Modal"
import Overlay from "components/Overlay"
import Button from "components/form/Button"
import {
  QuickListProductVariation,
  QuickListCategory,
  QuickListSubcategory,
  QuickListPackage,
} from "../sharedTypes"
import { Form } from "components/form"
import { FilterButtons } from "components/form"
import GroupedProductVariationOptions from "./GroupedProductVariationOptions"
import Search from "./Search"
import { filterProductVariations } from "../utilities"
import { handleError } from "utilities/error"

type Props = {
  close(): void
  submit(values: { productVariationIds: string[] }): Promise<void>
  productVariations: QuickListProductVariation[]
  categories: QuickListCategory[]
  subcategories: QuickListSubcategory[]
  packages: QuickListPackage[]
  formularyPriceEnabled: boolean
}

const InternalQuickList = (props: Props) => {
  const {
    close,
    submit,
    productVariations,
    categories,
    subcategories,
    packages,
    formularyPriceEnabled,
  } = props
  const [showModal, setShowModal] = useState(true)
  const [query, setQuery] = useState<string>("")
  const [categoryId, setCategoryId] = useState<string>("")

  const categoryOptions = categories.map((category: QuickListCategory) => ({
    label: category.name,
    value: category.externalId,
  }))

  const selectCategory = (categoryId?: string) => {
    setCategoryId(categoryId)
  }

  const selectedCategories = categories.filter(
    (c) => !categoryId || c.externalId === categoryId
  )
  const filteredProductVariations = filterProductVariations(
    productVariations,
    query
  )

  const onSubmit = (values, { setSubmitting }) => {
    return submit(values).catch((response) => {
      handleError(response)
      setSubmitting(false)
    })
  }

  const initialValues = { productVariationIds: [] }
  return (
    <Modal
      title="Formulary Quick List"
      show={showModal}
      cancel={() => setShowModal(false)}
      onExited={close}
    >
      <Form initialValues={initialValues} onSubmit={onSubmit}>
        {({ values, isSubmitting }) => (
          <Overlay
            active={isSubmitting}
            showSpinner
            position="top"
            className="overlay-flex-height"
          >
            <Modal.Body className="vertical-scroll">
              <FilterButtons
                name="categoryName"
                label="Filter by Category"
                onChange={selectCategory}
                options={categoryOptions}
              />
              <Search
                name="query"
                onChange={setQuery}
                placeholder="Search by product"
              />
              <hr />
              <GroupedProductVariationOptions
                selectedCategories={selectedCategories}
                subcategories={subcategories}
                packages={packages}
                filteredProductVariations={filteredProductVariations}
                formularyPriceEnabled={formularyPriceEnabled}
              />
            </Modal.Body>
            <Modal.Footer>
              <div className="text-right">
                <Button
                  type="button"
                  className="btn-link canopy-mie-8x"
                  onClick={close}
                >
                  Cancel
                </Button>
                <Button
                  type="submit"
                  className="btn-brand"
                  disabled={isSubmitting || !values.productVariationIds.length}
                >
                  Add to Cart
                </Button>
              </div>
            </Modal.Footer>
          </Overlay>
        )}
      </Form>
    </Modal>
  )
}

export default InternalQuickList
