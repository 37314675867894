// @ts-strict-ignore
import React, { useEffect, useState } from "react"
import Skeleton from "@material-ui/lab/Skeleton"
import { useStyles } from "themes/theme"
import { useEffectThatWontRunOnMount } from "../../../hooks/useEffectThatWontRunOnMount"

interface HasKey {
  key: string
}

const skellingtonStyles = (theme) => ({
  spacer: {
    padding: theme.spacing(0.5),
  },
})

const JackSkellington = ({
  numLoadingRows,
  placeholderRowHeight,
}: {
  numLoadingRows: number
  placeholderRowHeight: number
}) => {
  const classes = useStyles(skellingtonStyles)
  return (
    <>
      {new Array(numLoadingRows).fill(null).map((_, i) => (
        <React.Fragment key={`${i}-skellington-row`}>
          <Skeleton
            className={classes.skeleton}
            variant="rect"
            height={placeholderRowHeight}
          />
          <div className={`${classes.spacer} table-loading-skeleton`} />
        </React.Fragment>
      ))}
    </>
  )
}

interface Props<RecordType extends HasKey, TableProps> {
  loadPage: (
    page: number
  ) => Promise<{
    records: RecordType[]
    hasNextPage: boolean
    totalCount: number
  }>
  placeholderRowHeight?: number
  numLoadingRows?: number
  handleLoadingIndicators?: boolean
  Table: React.FC<TableProps & { records: RecordType[] }>
  tableProps?: TableProps
}

export const InfiniteLoadTable = <RecordType extends HasKey, TableProps>({
  loadPage,
  Table,
  tableProps,
  placeholderRowHeight = 100,
  numLoadingRows = 10,
  handleLoadingIndicators = true,
}: Props<RecordType, TableProps>) => {
  const [loading, setLoading] = useState(true)
  const [records, setRecords] = useState<RecordType[]>([])
  const [totalCount, setTotalCount] = useState<number>(undefined)
  const [currentPage, setCurrentPage] = useState({ page: 1 })
  const [hasNextPage, setHasNextPage] = useState(true)
  useEffectThatWontRunOnMount(() => {
    setCurrentPage({ page: 1 })
    setLoading(true)
  }, [loadPage])
  useEffect(() => {
    loadPage(currentPage.page).then((data) => {
      setRecords((recs) => {
        if (currentPage.page === 1) return data.records

        const newRecs = data.records.filter(
          (r) => !recs.some((pr) => pr.key === r.key)
        )
        return [...recs, ...newRecs]
      })
      setHasNextPage(data.hasNextPage)
      setTotalCount(data.totalCount)
      setLoading(false)
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage])

  if (loading && currentPage.page === 1 && handleLoadingIndicators)
    return (
      <JackSkellington
        placeholderRowHeight={placeholderRowHeight}
        numLoadingRows={numLoadingRows}
      />
    )
  const onLoadMoreClick = () => {
    setLoading(true)
    setCurrentPage((prev) => ({
      page: prev.page + 1,
    }))
  }
  return (
    <>
      <Table records={records} {...tableProps} loading={loading} />
      <div className="text-center">
        <div className="font-subparagraph color-dark-gray">
          Displaying {records.length} of {totalCount}
        </div>
        {hasNextPage && (
          <div>
            <button
              className="btn-link link"
              onClick={onLoadMoreClick}
              disabled={loading}
            >
              Load More Results
            </button>
          </div>
        )}
      </div>
    </>
  )
}
