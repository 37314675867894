// @ts-strict-ignore
import React, { useCallback } from "react"
import debounce from "awesome-debounce-promise"
import { FormikValues } from "formik"
import { Form, TextArea, TextField } from "components/form"
import AddressSelector from "./AddressSelector"
import EditDeliveryAddressButton from "./EditDeliveryAddressButton"
import LineItemGroupThumbnail from "./LineItemGroupThumbnail"
import {
  Address,
  AddressType,
  DmeOrder,
  Fulfillment as FulfillmentType,
} from "sharedTypes"
import { addressForFulfillment, formatAddressWithType } from "utilities/address"
import { getUpdateParams } from "../utilities/address"
import {
  addressLabel,
  deliveryNoteLabel,
  deliveryNotePlaceholder,
  fulfillmentHeader,
  itemsLabel,
} from "../utilities/displayOfferingType"
import DeliveryDateSelector from "./DeliveryDateSelector"
import cx from "classnames"

type Props = {
  fulfillment: FulfillmentType
  dmeOrder: DmeOrder
  addNewAddress(fulfillmentId: string): void
  editAddress(address: Address): void
  updateDelivery(fulfillmenId: string, values: FormikValues): Promise<void>
  updateDeliveryAddress(
    deliveryId: string,
    dmeOrderFulfillment: FormikValues
  ): Promise<void>
  disallowAddressInlineEditing?: boolean
}

const Fulfillment: React.FC<Props> = ({
  fulfillment,
  dmeOrder,
  addNewAddress,
  editAddress,
  updateDelivery,
  updateDeliveryAddress,
  disallowAddressInlineEditing,
}) => {
  const deliveryAddress = addressForFulfillment(dmeOrder.addresses, fulfillment)

  const handleUpdateDelivery = (
    values: FormikValues,
    { setErrors }: { setErrors: (errors: string[]) => void }
  ) => {
    updateDelivery(fulfillment.id, values).catch((errors) => setErrors(errors))
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedUpdateDeliveryDate = useCallback(
    debounce(handleUpdateDelivery, 250),
    []
  )
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedUpdateDeliveryNote = useCallback(
    debounce(handleUpdateDelivery, 250),
    []
  )
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedUpdateDeliveryAddress = useCallback(
    debounce(updateDeliveryAddress, 250),
    []
  )
  const lineItemGroups = fulfillment.lineItemGroupIds
    .map((id) => dmeOrder.lineItemGroups.find((group) => group.id === id))
    .filter(Boolean)
  const billingAddress = dmeOrder.addresses.find(
    ({ addressType }) => addressType === AddressType.Billing
  )
  const facilityAddress = dmeOrder.addresses.find(
    ({ addressType }) => addressType === AddressType.Facility
  )

  return (
    <div key={fulfillment.id} className={cx("wide-fixed-row", "well")}>
      <h3>{fulfillmentHeader(fulfillment.offeringType)}</h3>
      <div className="row">
        <div className="col-md-8">
          {disallowAddressInlineEditing || fulfillment.isConsignmentCloset ? (
            <div>
              <label>{addressLabel(fulfillment.offeringType)}</label>
              <p>
                {formatAddressWithType(deliveryAddress)}
                <EditDeliveryAddressButton
                  deliveryAddress={deliveryAddress}
                  editAddress={editAddress}
                />
              </p>
              {deliveryAddress.addressType === "facility" && (
                <Form
                  initialValues={{ room: deliveryAddress.room || "" }}
                  onSubmit={(values) => {
                    debouncedUpdateDeliveryAddress(fulfillment.id, {
                      deliveryAddress: { id: facilityAddress.id },
                      ...values,
                    })
                  }}
                  submitOnChange
                >
                  <TextField
                    label="Room/Unit"
                    placeholder="Add Room/Unit"
                    name="room"
                  />
                </Form>
              )}
            </div>
          ) : (
            <AddressSelector
              addNewAddress={() => addNewAddress(fulfillment.id)}
              dmeOrder={dmeOrder}
              fulfillment={fulfillment}
              editAddress={editAddress}
              initialValues={{
                addressId: fulfillment.deliveryAddressId || billingAddress.id,
                room: (facilityAddress && facilityAddress.room) || "",
              }}
              onSubmit={(values) => {
                const params = getUpdateParams({
                  values,
                  dmeOrder,
                })
                params && debouncedUpdateDeliveryAddress(fulfillment.id, params)
              }}
            />
          )}
          <DeliveryDateSelector
            createdAt={new Date(dmeOrder.createdAt)}
            fulfillment={fulfillment}
            onSubmit={debouncedUpdateDeliveryDate}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-md-12">
          <Form
            onSubmit={debouncedUpdateDeliveryNote}
            initialValues={{ deliveryNote: fulfillment.deliveryNote || "" }}
            submitOnChange
          >
            <TextArea
              label={deliveryNoteLabel(fulfillment.offeringType)}
              name="deliveryNote"
              rows={3}
              placeholder={deliveryNotePlaceholder(fulfillment.offeringType)}
            />
          </Form>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12">
          <h3>{itemsLabel(fulfillment.offeringType)}</h3>
          <ul className="list-unstyled">
            {lineItemGroups.map((group) => (
              <li key={group.id} className="clearfix">
                <LineItemGroupThumbnail lineItemGroup={group} />
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  )
}

export default Fulfillment
