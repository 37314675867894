const getDocumentHiddenValue = () => {
  if (typeof document.msHidden !== "undefined") {
    return "msHidden"
  } else if (typeof document.webkitHidden !== "undefined") {
    return "webkitHidden"
  }
  return "hidden"
}

const documentHiddenValue = getDocumentHiddenValue()

export const isDocumentHidden = () => {
  return !!document[documentHiddenValue]
}
