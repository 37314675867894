import * as styles from "./index.module.scss"
import React from "react"

export interface Props {
  suppliersCount: number
}

const OtherSuppliersOnParachuteHeader = ({ suppliersCount }: Props) => (
  <h2 className={styles.title}>
    <span>Other Suppliers on Parachute</span>
    <span className={styles.supplierCount}>{` (${suppliersCount})`}</span>
  </h2>
)

export default OtherSuppliersOnParachuteHeader
