import { employerPrefix } from "utilities/url"

export const dmeOrderUrl = (
  clinicalFacilityId: string,
  dmeOrderId: string
): string =>
  `${employerPrefix(
    "ClinicalFacility",
    clinicalFacilityId
  )}/orders/${dmeOrderId}`

export const lastAccessedFacilityEmployerUrl = (
  clinicalFacilityId: string
): string => `${employerPrefix("ClinicalFacility", clinicalFacilityId)}`
