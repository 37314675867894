// @ts-strict-ignore
import React from "react"
import { Link } from "react-router-dom"
import { Form, NativeSelect as Select } from "components/form"
import { formatAddressWithType } from "utilities/address"
import LineItemGroupThumbnail from "./LineItemGroupThumbnail"
import * as routes from "applications/Workflow/routes"
import { DmeOrder, OfferingType } from "sharedTypes"
import { FormikValues } from "formik"
import cx from "classnames"

type Props = {
  dmeOrder: DmeOrder
  updateLineItemGroupDeliveryAddress(
    lineItemGroupId: string,
    dmeOrderFulfillment: FormikValues
  ): void
  addNewAddress(lineItemGroupId: string): void
}

function EditDeliveryAddresses({
  dmeOrder,
  updateLineItemGroupDeliveryAddress,
  addNewAddress,
}: Props) {
  const addressOptions = dmeOrder.addresses.map((address) => ({
    value: address.id,
    label: formatAddressWithType(address),
  }))

  const renderLineItemGroup = (lineItemGroup) => {
    const fulfillment = dmeOrder.fulfillments.find(
      (fulfillment) => fulfillment.id === lineItemGroup.dmeOrderFulfillmentId
    )

    return (
      <div
        key={lineItemGroup.id}
        className="row gutter-0 line-item-group-thumbnail-row"
        data-test-target={lineItemGroup.id}
      >
        <div className="col-6">
          <LineItemGroupThumbnail
            lineItemGroup={lineItemGroup}
            thumbnailClassName="d-none d-md-block"
          />
        </div>
        <div className="col-6">
          <Form
            enableReinitialize
            onSubmit={(values) =>
              updateLineItemGroupDeliveryAddress(lineItemGroup.id, values)
            }
            initialValues={{ deliveryAddressId: fulfillment.deliveryAddressId }}
            submitOnChange
          >
            <Select
              options={addressOptions}
              name="deliveryAddressId"
              onAddNew={() => addNewAddress(lineItemGroup.id)}
              addNewLabel="+ Add new address"
              disabled={lineItemGroup.isConsignmentCloset}
            />
          </Form>
        </div>
      </div>
    )
  }

  return (
    <div>
      <h3>Choose where to send each product or service</h3>
      <div className={cx("well", "wide-fixed-row")}>
        <div className="row gutter-0">
          <div className="col-6">
            <h3>Products/Services</h3>
          </div>
          <div className="col-6">
            <h3>Address</h3>
          </div>
        </div>
        {dmeOrder.lineItemGroups
          .filter(
            (lineItemGroup) =>
              lineItemGroup.offeringType !== OfferingType.O2Recert
          )
          .map((lineItemGroup) => renderLineItemGroup(lineItemGroup))}
      </div>
      <div className={cx("text-right", "wide-fixed-row")}>
        <Link to={routes.deliveryPath()} className="btn btn-brand">
          Continue
        </Link>
      </div>
    </div>
  )
}

export default EditDeliveryAddresses
