import React from "react"
import AssignedToEditor from "components/AssignedToEditor"
import { assignDocument } from "./api"
import { Assignee, CsrInboxDocument } from "sharedTypes"
import { get } from "services/api"
import { getAssigneesUrl } from "components/AssignedToEditor/urls"

type Props = {
  document: CsrInboxDocument
  supplierId?: string
  onChange?(document: CsrInboxDocument): void
  readOnly?: boolean
}
export const fetchAssignees = (supplierId?: string) => (
  term: string
): Promise<Assignee[]> => {
  return get(getAssigneesUrl(supplierId), { term }).then(({ data }) => data)
}

const CsrInboxStateAssigneeEditor = ({
  supplierId,
  document,
  onChange,
  readOnly,
}: Props) => {
  const setAssignee = (id, assigneeId) => {
    return assignDocument(id, assigneeId, supplierId).then(({ data }) => {
      const document = data as CsrInboxDocument
      onChange?.(document)
      return document.assignee
    })
  }
  const updateAssignee = (id, { assigneeId }) => setAssignee(id, assigneeId)
  const deleteAssignee = (id) => setAssignee(id, null)

  return (
    <AssignedToEditor
      id={document.id}
      assignee={document.assignee}
      updateAssignee={updateAssignee}
      deleteAssignee={deleteAssignee}
      canAssign={!readOnly}
      fetchAssignees={fetchAssignees(supplierId)}
    />
  )
}

export default CsrInboxStateAssigneeEditor
