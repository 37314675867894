import React from "react"
import Modal from "components/Modal"
import PatientForm from "./PatientForm"
import { DmeOrder } from "sharedTypes"
import { PatientFormParams } from "../sharedTypes"

type Props = {
  show: boolean
  cancel(): void
  updatePatient(params: PatientFormParams): Promise<void>
  dmeOrder: DmeOrder
  demographicsEditable: boolean
  mrnEditable: boolean
  supplierPatientIdVisible: boolean
  supplierPatientIdEditable: boolean
}

function PatientModal({
  show,
  cancel,
  dmeOrder,
  updatePatient,
  demographicsEditable,
  mrnEditable,
  supplierPatientIdVisible,
  supplierPatientIdEditable,
}: Props) {
  const { patient, patientSnapshot } = dmeOrder
  const patientFormParams = {
    firstName: patient.firstName,
    lastName: patient.lastName,
    dateOfBirth: patient.dateOfBirth,
    sex: patient.sex,
    supplierSystemPatientId: patient.supplierSystemPatientId,
    mrn: patientSnapshot.mrn,
  }

  return (
    <Modal size="md" title="Edit patient" show={show} cancel={cancel}>
      <Modal.Body>
        <PatientForm
          patientFormParams={patientFormParams}
          updatePatient={updatePatient}
          demographicsEditable={demographicsEditable}
          mrnEditable={mrnEditable}
          supplierPatientIdVisible={supplierPatientIdVisible}
          supplierPatientIdEditable={supplierPatientIdEditable}
        />
      </Modal.Body>
    </Modal>
  )
}

export default PatientModal
