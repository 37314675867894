// @team @expanse
import React from "react"
import { DmeOrder, AddressType } from "sharedTypes"
import DataRow from "components/DataRow"
import { addressesByType } from "utilities/address"
import startCase from "lodash/startCase"
import OrderReviewContactAddress from "./components/OrderReviewContactAddress"
import OrderReviewAdditionalContacts from "./components/OrderReviewAdditionalContacts"

type Props = {
  dmeOrder: DmeOrder
}

const ADDRESS_TYPE_LABELS = { billing: "Patient" }

const ContactsSectionContent = ({ dmeOrder }: Props) => {
  const addresses = dmeOrder.addresses
    .filter((address) => address.addressType)
    .map((address) => {
      return {
        addressType: address.addressType,
        key: address.line1,
        label:
          ADDRESS_TYPE_LABELS[address.addressType] ||
          startCase(address.addressType),
        value: (
          <OrderReviewContactAddress
            address={address}
            patientSnapshot={dmeOrder.patientSnapshot}
          />
        ),
      }
    })

  if (dmeOrder.emergencyContacts.length > 0) {
    addresses.push({
      addressType: AddressType.Emergency,
      key: "emergency",
      label: "Additional Contacts",
      value: (
        <OrderReviewAdditionalContacts
          emergencyContacts={dmeOrder.emergencyContacts}
        />
      ),
    })
  }

  return (
    <>
      {addresses.sort(addressesByType).map((address) => {
        return (
          <DataRow
            key={address.key}
            label={address.label}
            value={address.value}
          />
        )
      })}
    </>
  )
}

export default ContactsSectionContent
