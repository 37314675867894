import React, { useState } from "react"
import Modal from "components/Modal"
import { handleError } from "utilities/error"
import AddSupplierForm from "./AddSupplierForm"
import RequestSupplierForm from "./RequestSupplierForm"
import { CreateRequestResponse, SupplierFormFields } from "./sharedTypes"
import { createRequest, supplierSearch } from "./api"
import { PagedContent, RenderPageProps } from "./PagedContent"

interface Props {
  onCancel: () => void
  afterSubmit: (response: CreateRequestResponse) => Promise<void>
  showModal: boolean
  facilityState: string
}

type AddSupplierFormProps = {
  onSubmit: (values: SupplierFormFields) => Promise<void>
  facilityState: string
  onCancel: () => void
}

type RequestSupplierFormProps = {
  onCancel: () => void
  onSubmit: (values: SupplierFormFields) => Promise<void>
}

const addSupplier = "Add Supplier"
export const requestOutsideSupplier = "Request Outside Supplier"

const getAddSupplierFormRender = (props: AddSupplierFormProps) => (
  pageProps: RenderPageProps
) => {
  return (
    <AddSupplierForm
      facilityState={props.facilityState}
      onSubmit={props.onSubmit}
      supplierSearch={supplierSearch}
      onCancel={props.onCancel}
      onRequestSupplier={pageProps.goForward}
    />
  )
}

const getRequestSupplierFormRender = (props: RequestSupplierFormProps) => (
  _pageProps: RenderPageProps
) => {
  return (
    <RequestSupplierForm onCancel={props.onCancel} onSubmit={props.onSubmit} />
  )
}
const AddSupplierModal: React.FC<Props> = ({
  onCancel: handleCancel,
  facilityState,
  afterSubmit,
  showModal,
}) => {
  const [page, setPage] = useState(0)

  const onCancel = () => {
    handleCancel()
    setPage(0)
  }

  const onSubmit = (values: SupplierFormFields) =>
    createRequest(values)
      .then((response) => afterSubmit(response))
      .catch(handleError)

  const pageDefinitions = [
    {
      render: getAddSupplierFormRender({
        onCancel,
        facilityState,
        onSubmit,
      }),
      title: addSupplier,
    },
    {
      render: getRequestSupplierFormRender({
        onCancel,
        onSubmit,
      }),
      title: requestOutsideSupplier,
    },
  ]

  return (
    <Modal
      show={showModal}
      cancel={onCancel}
      title={pageDefinitions[page].title}
      size="md"
    >
      <Modal.Body>
        <PagedContent
          backText={`Back to ${addSupplier}`}
          pages={pageDefinitions}
          onPageChange={setPage}
        />
      </Modal.Body>
    </Modal>
  )
}

export default AddSupplierModal
