import React from "react"
import { HashRouter, withRouter } from "react-router-dom"

const wrap = (Component) => (props) => (
  <HashRouter basename="/">
    <Component {...props} />
  </HashRouter>
)

export const withHashRouter = (Component) => wrap(withRouter(Component))
