import React from "react"
import { RadioButtons } from "components/form"
import { ConsignmentCloset } from "sharedTypes"
import { DeliveryMethod } from "../sharedTypes"

const consignmentClosetLabel = (consignmentCloset) => {
  let label = ""
  if (consignmentCloset.floor) {
    label += `Floor ${consignmentCloset.floor} - `
  }
  label += consignmentCloset.name
  if (consignmentCloset.name.toLowerCase() !== "consignment") {
    label += " (Consignment)"
  }
  return label
}

type Props = {
  availableDeliveryMethods: DeliveryMethod[]
  consignmentCloset: ConsignmentCloset
}

const DeliveryMethodSelector = ({
  availableDeliveryMethods,
  consignmentCloset,
}: Props) => {
  const deliveryMethods = [
    { label: "Delivery", value: DeliveryMethod.Delivery },
  ]
  if (consignmentCloset) {
    deliveryMethods.push({
      label: consignmentClosetLabel(consignmentCloset),
      value: DeliveryMethod.ConsignmentCloset,
    })
  }

  const onlyDeliveryAvailable =
    availableDeliveryMethods.length === 1 &&
    availableDeliveryMethods[0] === DeliveryMethod.Delivery
  if (onlyDeliveryAvailable) {
    return null
  }

  return (
    <RadioButtons
      label="Delivery Method"
      name="deliveryMethod"
      options={deliveryMethods.filter((dm) =>
        availableDeliveryMethods.includes(dm.value)
      )}
    />
  )
}

export default DeliveryMethodSelector
