import React from "react"
import SeverityIcon from "components/SeverityIcon"
import { Severity } from "sharedTypes"

type Props = {
  type: Severity
  children: React.ReactNode
}

function Note({ type, children }: Props) {
  return (
    <div className={`note note-${type}`}>
      <SeverityIcon type={type} />
      <div className="description">{children}</div>
    </div>
  )
}

export default Note
