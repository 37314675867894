import React, { useContext } from "react"
import InternalQuickList from "./components/InternalQuickList"
import * as api from "./api"
import withInitialData from "components/withInitialData"
import {
  QuickListProductVariation,
  QuickListCategory,
  QuickListSubcategory,
  QuickListPackage,
} from "./sharedTypes"
import * as routes from "applications/Workflow/routes"
import { History } from "sharedTypes"
import WorkflowPageContext from "context/WorkflowPage"
import { SidebarTab } from "applications/Workflow/containers/Navigation/sharedTypes"

type InitialData = {
  productVariations: QuickListProductVariation[]
  categories: QuickListCategory[]
  subcategories: QuickListSubcategory[]
  packages: QuickListPackage[]
}

type Props = {
  initialData: InitialData
  history: History
  refreshDmeOrder(): Promise<void>
  supplierId: string | undefined
  formularyPriceEnabled: boolean
}

const QuickList = ({
  formularyPriceEnabled,
  initialData,
  history,
  refreshDmeOrder,
  supplierId,
}: Props) => {
  const { productVariations, categories, subcategories, packages } = initialData
  const { setSidebarTab } = useContext(WorkflowPageContext)

  const close = () => history.push(routes.productsPath(supplierId))

  const showCart = () => {
    setSidebarTab(SidebarTab.Cart)
    close()
  }

  const submit = ({ productVariationIds }) =>
    api
      .quickListBulkAdd(productVariationIds)
      .then(refreshDmeOrder)
      .then(showCart)

  return (
    <InternalQuickList
      formularyPriceEnabled={formularyPriceEnabled}
      productVariations={productVariations}
      categories={categories}
      subcategories={subcategories}
      packages={packages}
      close={close}
      submit={submit}
    />
  )
}

const fetchInitialData = () => api.getQuickList().then(({ data }) => data)
export default withInitialData(fetchInitialData)(QuickList)
