// @ts-strict-ignore
import React from "react"
import Button from "components/form/Button"
import Modal from "components/Modal"

interface Props {
  show?: boolean
  viewName: string
  cancel(): void
  submit(): void
}

function RemoveModal({ show, cancel, submit, viewName }: Props) {
  return (
    <Modal title="Remove View" size="md" show={show} cancel={cancel}>
      <Modal.Body>
        <h4>Are you sure you want to remove this view?</h4>
        <div>
          Custom View '{viewName}' will no longer be available after removal.
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button className="btn-secondary" onClick={cancel}>
          No, don't remove
        </Button>
        <Button
          className="btn-danger canopy-mis-2x"
          type="submit"
          onClick={submit}
        >
          Yes, remove
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default RemoveModal
