import React from "react"
import { pluralize } from "utilities/string"
import { Follower } from "sharedTypes"
import { CanopyButton } from "@parachutehealth/canopy-button"
import { canopyColorPrimitivesTeal41 } from "@parachutehealth/canopy-tokens-color"

type Props = {
  className?: string
  followers: Follower[]
  onClick(): void
}

export function followersText(count) {
  return `${count} ${pluralize("Follower", count)}`
}

function FollowersButton({ followers, className, onClick }: Props) {
  return (
    <CanopyButton
      fullWidth
      onClick={onClick}
      type="button"
      className={className}
      variant="tertiary"
      style={{ color: canopyColorPrimitivesTeal41 }}
    >
      {followersText(followers.length)}
    </CanopyButton>
  )
}

export default FollowersButton
