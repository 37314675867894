import { gql, TypedDocumentNode } from "@apollo/client"
import {
  PayorUserCreateMutation,
  PayorUserCreateMutationVariables,
} from "../../../../graphql/__generated__/graphql"

export const PayorUserCreate: TypedDocumentNode<
  PayorUserCreateMutation,
  PayorUserCreateMutationVariables
> = gql`
  mutation PayorUserCreate(
    $firstName: String
    $lastName: String
    $email: String
    $timeZone: String
    $role: EmploymentRolesEnum
  ) {
    userCreate(
      input: {
        firstName: $firstName
        lastName: $lastName
        email: $email
        timeZone: $timeZone
        role: $role
      }
    ) {
      user {
        id
        firstName
        lastName
        email
        timeZone
        currentEmployment {
          role
        }
      }
    }
  }
`
