import React, { useState } from "react"
import { signReviewerAgreement } from "../../api"
import { useDoctorContext } from "../../hooks/useDoctorContext"
import ReviewerAgreementModal from "./ReviewerAgreementModal"

const ReviewerAgreementWrapper = ({
  children,
}: {
  children: React.ReactNode
}) => {
  const { doctor, refreshCurrentDoctor, updatingDoctor } = useDoctorContext()
  const [submitting, setSubmitting] = useState(false)

  if (updatingDoctor) {
    return <></>
  }
  return doctor.reviewerAgreementIsSigned ? (
    <>{children}</>
  ) : (
    <ReviewerAgreementModal
      doctor={doctor}
      onAgree={() => {
        setSubmitting(true)
        signReviewerAgreement(doctor.id)
          .then(() =>
            refreshCurrentDoctor(doctor.id).then(() => {
              setSubmitting(false)
            })
          )
          .catch(() => setSubmitting(false))
      }}
      submitting={submitting}
    />
  )
}

export default ReviewerAgreementWrapper
