import React from "react"
import Modal from "components/Modal"
import Legal from "./Legal"

interface Props {
  show: boolean
  close(): void
  footer?: React.ReactNode
}

function TermsOfUseModal({ show, close, footer }: Props) {
  return (
    <Modal
      title="Parachute Health Facility Terms of Use"
      show={show}
      cancel={close}
      size="md"
    >
      <Modal.Body>
        <div className="mh-300">
          <Legal />
        </div>
      </Modal.Body>
      <Modal.Footer>
        {footer || (
          <div className="float-right">
            <a className="btn btn-link bold color-dark-gray" onClick={close}>
              Close
            </a>
          </div>
        )}
      </Modal.Footer>
    </Modal>
  )
}

export default TermsOfUseModal
