import React from "react"
import { Follower } from "sharedTypes"
import Icon from "components/Icon"

type Props = {
  followers: Follower[]
}

const ReadOnlyFollowers = ({ followers }: Props) => {
  return (
    <p className="font-xs">
      <Icon type="info-circle" className="canopy-mie-8x" />
      Supplier followers ({followers.length}):{" "}
      {followers.map((follower) => follower.name).join(", ")}
    </p>
  )
}

export default ReadOnlyFollowers
