import { employerPrefix } from "utilities/url"

export const makeUrlProvider = (supplierId) => {
  const urlPrefix = employerPrefix("Supplier", supplierId)
  return {
    csrInboxDocumentsUrl: `${urlPrefix}/inbox.json`,
    csrInboxDocumentAssigneeUrl: (documentId) =>
      `${urlPrefix}/inbox/${documentId}/assignees.json`,
    searchClinicalFacilitiesUrl: `${urlPrefix}/clinical_facilities_search.json`,
    searchDocumentSourcesUrl: `${urlPrefix}/csr_inbox_state_source_search.json`,
    searchReceivingFaxNumbersUrl: `${urlPrefix}/csr_inbox_state_receiving_fax_numbers.json`,
    ordersUrl: urlPrefix,
    authorizationsUrl: `${urlPrefix}/authorizations`,
    getAssigneesUrl: `${urlPrefix}/assignees.json`,
    initialDashboardDataUrl: `${urlPrefix}/inbox/initial_dashboard_data.json`,
  }
}
