import React, { useContext } from "react"
import { Route, RouterChildContext, Switch, useHistory } from "react-router-dom"
import ConsignmentClosetsPage from "./components/ConsignmentClosetsPage"
import ConsignmentClosetDetailsPage from "./components/ConsignmentClosetDetailsPage"
import { withBrowserRouter } from "routers/BrowserRouter"
import { withMaterialTheme } from "themes/theme"
import {
  NoticeContext,
  withNoticeProvider,
} from "applications/Cms/contexts/NoticeContext"
import NewConsignmentClosetForm from "./components/NewConsignmentClosetForm"
import DuplicateConsignmentClosetForm from "./components/DuplicateConsignmentClosetForm"
import { FeatureFlagProvider } from "../../../../components/FeatureFlagContext"
import { Policies, PoliciesProvider } from "../../contexts/PoliciesContext"
import ConsignmentClosetSPCDuplicateForm from "./components/ConsignmentClosetSPCDuplicateForm"

export type FeatureFlags = {
  [key: string]: boolean
}

type ConsignmentClosetWorkflowProps = {
  featureFlags: FeatureFlags
  policies: Policies
}

const ConsignmentClosetsWorkflow: React.FC<ConsignmentClosetWorkflowProps> = ({
  featureFlags,
  policies,
}) => {
  const history: RouterChildContext["router"]["history"] = useHistory()
  const { clearNotice } = useContext(NoticeContext)

  React.useEffect(() => {
    clearNotice()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history.location.pathname])

  return (
    <FeatureFlagProvider flags={featureFlags}>
      <PoliciesProvider policies={policies}>
        <Switch>
          <Route path="/cms/catalog/consignment_closets" exact>
            <ConsignmentClosetsPage />
          </Route>
          <Route path="/cms/catalog/consignment_closets/new" exact>
            <NewConsignmentClosetForm />
          </Route>
          <Route path="/cms/catalog/consignment_closets/:consignmentClosetId/duplicate">
            <DuplicateConsignmentClosetForm />
          </Route>
          <Route
            path="/cms/catalog/consignment_closets/:consignmentClosetId"
            exact
          >
            <ConsignmentClosetDetailsPage />
          </Route>
          <Route
            path="/cms/catalog/consignment_closets/:consignmentClosetId/supplier_package_configurations/:supplierPackageConfigurationId/duplicate"
            exact
          >
            <ConsignmentClosetSPCDuplicateForm />
          </Route>
        </Switch>
      </PoliciesProvider>
    </FeatureFlagProvider>
  )
}

export default withBrowserRouter(
  withNoticeProvider(withMaterialTheme(ConsignmentClosetsWorkflow)),
  {}
)
