import { gql, TypedDocumentNode } from "@apollo/client"
import {
  PayorEmploymentDeleteMutation,
  PayorEmploymentDeleteMutationVariables,
} from "../../../../graphql/__generated__/graphql"

export const PayorEmploymentDelete: TypedDocumentNode<
  PayorEmploymentDeleteMutation,
  PayorEmploymentDeleteMutationVariables
> = gql`
  mutation PayorEmploymentDelete($id: ID!) {
    employmentDelete(input: { id: $id }) {
      id
    }
  }
`
