// @ts-strict-ignore
import React, { useState } from "react"
import { CanopyDialogModal } from "@parachutehealth/canopy-dialog-modal"
import { CanopyButton } from "@parachutehealth/canopy-button"

type LinkClickEvent = React.MouseEvent<HTMLAnchorElement & HTMLButtonElement>

interface Confirm {
  (cb: () => void): (event: LinkClickEvent) => void
}

type Props = {
  title: string
  description?: string | React.ReactNode
  confirmText: string
  cancelText: string
  render(confirm: Confirm): React.ReactElement
}

const Confirm = ({
  title,
  description,
  confirmText,
  cancelText,
  render,
}: Props) => {
  const [isShowing, setShowing] = useState(false)
  const [callback, setCallback] = useState({ fn: null })

  const show = (fn) => (event) => {
    event.preventDefault()
    setShowing(true)
    setCallback({ fn })
  }

  const cancel = () => {
    setShowing(false)
    setCallback(null)
  }

  const confirm = () => {
    if (callback) {
      callback.fn()
      cancel()
    }
  }

  return (
    <>
      {render(show)}
      <CanopyDialogModal
        header={title}
        open={isShowing}
        primaryFooterButton={
          <CanopyButton onClick={confirm}>{confirmText}</CanopyButton>
        }
        secondaryFooterButton={
          <CanopyButton variant="secondary" onClick={cancel}>
            {cancelText}
          </CanopyButton>
        }
        unclosable
      >
        {description}
      </CanopyDialogModal>
    </>
  )
}

export default Confirm
