import React from "react"
import Table, { TableColumnDefinition } from "components/Table"
import * as styles from "./dme-order-patient-card.module.scss"
import PatientCardHeader from "./PatientCardHeader"
import { attachToDmeOrder } from "./attach_to_dme_order"
import classNames from "classnames"
import { employerPrefix } from "utilities/url"
import { ReOrderDocumentsButton } from "./ReOrderDocumentsButton"
import { reorderDocuments } from "./reorder_documents"
import { AsyncButton } from "components/AsyncButton"
import Button from "components/form/Button"
import NewOrderForm from "applications/NewOrderForm"
import { ContextEmployer, CsrInboxDocument, MasterPatient } from "sharedTypes"

const OurStyledButton = (props) => {
  return <Button key="styledButton" className="btn-ghost" {...props} />
}

type Order = {
  id: string
  dmeOrderId: string
  deliveryDate: string
  facility: string
  products: string[]
  status: string
  orderId: string
  canAttachOrder: boolean
  isReorderable: boolean
}

export type PatientSearchResult = {
  patient: MasterPatient
  orders: Order[]
}

export const resultCardKey = (result: PatientSearchResult) =>
  Object.values(result.patient).join("-")

const tableColumnDefinitions: TableColumnDefinition<
  Order & {
    key: string
    supplierId: string
    csrInboxStateId: string
  }
>[] = [
  {
    title: "Delivery Date",
    attr: "deliveryDates",
  },
  {
    title: "Facility",
    attr: "clinicalFacilityName",
  },
  {
    title: "Products",
    attr: "productsWithoutAccessoriesNames",
  },
  {
    title: "Status",
    attr: "status",
  },
  {
    title: "",
    attr: "canAttachOrder",
    key: "attach_to_order_cell",
    render: (s) => (
      <div>
        {s.canAttachOrder && (
          <AsyncButton
            Button={OurStyledButton}
            onClick={attachToDmeOrder(
              s.supplierId,
              s.csrInboxStateId,
              s.dmeOrderId
            )}
          >
            Attach to Order
          </AsyncButton>
        )}
      </div>
    ),
  },
  {
    title: "",
    attr: "isReorderable",
    key: "reorder_view_cell",
    render: (s) => (
      <div>
        <a
          className={classNames("link color-brand")}
          href={
            employerPrefix("Supplier", s.supplierId) + "/orders/" + s.dmeOrderId
          }
        >
          View
        </a>
        {s.isReorderable && (
          <div>
            |
            <ReOrderDocumentsButton
              reOrderRequest={reorderDocuments(s.supplierId, s.dmeOrderId)}
            />
          </div>
        )}
      </div>
    ),
  },
]

const DmeOrderPatientCard = ({
  employer,
  patient,
  orders,
  supplierId,
  csrInboxStateId,
  document,
}: PatientSearchResult & {
  employer: ContextEmployer
  supplierId: string
  csrInboxStateId: string
  document: CsrInboxDocument
}) => {
  return (
    <div className={styles.patientCardContainer}>
      <div className={styles.patientCardInnerContainer}>
        <div className={styles.headerContainer}>
          <PatientCardHeader {...patient} />
          <NewOrderForm
            employer={employer}
            masterPatient={patient}
            document={document}
          />
        </div>

        <Table
          records={orders.map((order) => ({
            ...order,
            key: order.dmeOrderId,
            csrInboxStateId,
            supplierId,
          }))}
          truncate={false}
          tableColumns={tableColumnDefinitions}
        />
      </div>
    </div>
  )
}

export default DmeOrderPatientCard
