import React from "react"
import { EmergencyContact } from "sharedTypes"
import Modal from "components/Modal"
import OtherContactForm from "./OtherContactForm"
import { isEmailValid, EMAIL_VALIDATION_MESSAGE } from "utilities/email"
import { isPhoneValid, PHONE_VALIDATION_MESSAGE } from "utilities/phone"
import { handleError } from "utilities/error"

type Props = {
  otherContact: EmergencyContact
  updateOtherContact(otherContact: EmergencyContact): Promise<void>
  deleteOtherContact(otherContact: EmergencyContact): Promise<void>
  cancel(): void
}

export default ({
  otherContact,
  cancel,
  updateOtherContact,
  deleteOtherContact,
}: Props) => {
  const onSubmit = (data) => {
    updateOtherContact({ id: otherContact.id, ...data })
      .then((result) => {
        cancel()
        return result
      })
      .catch((error) => {
        handleError(error)
        cancel()
      })
  }

  const validate = ({ email, phoneNumber }) => {
    const errors: { email?: string; phoneNumber?: string } = {}
    if (email && !isEmailValid(email)) {
      errors.email = EMAIL_VALIDATION_MESSAGE
    }
    if (phoneNumber && !isPhoneValid(phoneNumber)) {
      errors.phoneNumber = PHONE_VALIDATION_MESSAGE
    }
    return errors
  }

  return (
    <Modal
      show
      cancel={cancel}
      title={otherContact.id ? "Edit other contact" : "Add other contact"}
      size="md"
    >
      <Modal.Body>
        <OtherContactForm
          initialValues={{
            id: otherContact.id,
            firstName: otherContact.firstName,
            lastName: otherContact.lastName,
            relationship: otherContact.relationship,
            phoneNumber: otherContact.phoneNumber,
            email: otherContact.email,
            preferredLanguage: otherContact.preferredLanguage,
          }}
          validate={validate}
          onSubmit={onSubmit}
          otherContact={otherContact}
          onRemove={() => {
            deleteOtherContact(otherContact)
            cancel()
          }}
        />
      </Modal.Body>
    </Modal>
  )
}
