import React, { Dispatch, SetStateAction } from "react"
import {
  DmeOrderEvent,
  SupplierDashboardDmeOrder,
} from "applications/SupplierDashboard/sharedTypes"
import { SupplierOrganizationDashboardDmeOrder } from "applications/SupplierOrganizationDashboard/sharedTypes"
import { reviewsUrl } from "../../urls"
import { getOrderEvents, updateResolution } from "../../api"
import { handleError } from "utilities/error"
import withInitialData from "components/withInitialData"
import startCase from "lodash/startCase"
import { timeAgoInWords } from "utilities/date"
import { truncate } from "utilities/string"
import { EventRawContent } from "../../../../components/Event/EventRawContent"

type Props = {
  dmeOrder: SupplierDashboardDmeOrder | SupplierOrganizationDashboardDmeOrder
  supplierId: string
  orderEvents: DmeOrderEvent[]
  setOrderEvents: Dispatch<SetStateAction<DmeOrderEvent[]>>
  setLastActivityAt: Dispatch<SetStateAction<string>>
  initialData: null
}

const MESSAGE_LIMIT = 60
const CUSTOM_ACTION_TITLES = {
  internal_comment: "New Internal Comment",
  comment: "New Referral Comment",
  snoozed: "Order snoozed",
  unsnoozed: "Order unsnoozed",
}

const CLEAR_ANALYTIC_TAGS = {
  internal_comment: "clear-internal-comment",
  comment: "clear-referral-comment",
}

const UNCLEAR_ANALYTIC_TAGS = {
  internal_comment: "unclear-internal-comment",
  comment: "unclear-referral-comment",
}

const ACTOR_ORGANIZATION_TYPES = {
  SupplierOrganization: "supplier",
  Supplier: "supplier",
  ClinicalFacility: "facility",
}

const UpdatesList = ({
  supplierId,
  dmeOrder,
  orderEvents,
  setOrderEvents,
  setLastActivityAt,
}: Props) => {
  const toggleResolution = (e, index) => {
    e.preventDefault()

    const orderEvent = orderEvents[index]
    const resolved = !orderEvent.resolved

    updateResolution(supplierId, dmeOrder.id, orderEvent.id, resolved)
      .then((updatedEvent: DmeOrderEvent) => {
        setLastActivityAt(new Date().toISOString())

        orderEvents[index] = updatedEvent
        setOrderEvents((events) =>
          events.map((event) =>
            event.id === updatedEvent.id ? updatedEvent : event
          )
        )
      })
      .catch(handleError)
  }

  const renderTitle = (orderEvent: DmeOrderEvent): string => {
    if (orderEvent.action === "cancel") {
      return (
        "Order canceled by " +
        ACTOR_ORGANIZATION_TYPES[orderEvent.actorOrganizationType]
      )
    } else {
      return (
        CUSTOM_ACTION_TITLES[orderEvent.action] || startCase(orderEvent.action)
      )
    }
  }

  const renderAction = (orderEvent, index) => {
    if (!orderEvent.canUpdate) {
      return orderEvent.resolved ? "Cleared" : "Unclear"
    }
    return orderEvent.resolved ? (
      <a
        className="link link-danger"
        data-analytics={UNCLEAR_ANALYTIC_TAGS[orderEvent.action]}
        onClick={(e) => toggleResolution(e, index)}
      >
        <small>Unclear</small>
      </a>
    ) : (
      <a
        className="link"
        data-analytics={CLEAR_ANALYTIC_TAGS[orderEvent.action]}
        onClick={(e) => toggleResolution(e, index)}
      >
        <small>Clear</small>
      </a>
    )
  }

  const renderEventResolution = (orderEvent) => {
    const resolution = orderEvent.resolutions[0]
    if (resolution && orderEvent.resolved) {
      return (
        <p className="color-dark-gray font-notice">
          Cleared by {resolution.userName} -{" "}
          {timeAgoInWords(resolution.createdAt)}
        </p>
      )
    }
  }

  const renderOrderEvent = (orderEvent, index) => {
    return (
      <li
        className="canopy-px-8x canopy-pbs-8x canopy-pbe-0 dme-order-event-list-item"
        key={orderEvent.id}
      >
        <div className="clearfix">
          <div className="float-left bold font-subparagraph">
            {renderTitle(orderEvent)}
          </div>
          <div className="float-right font-subparagraph color-dark-gray">
            {timeAgoInWords(orderEvent.eventTimestamp)}
          </div>
        </div>
        <p className="font-subparagraph color-dark-gray">
          {orderEvent.userName}
        </p>
        {orderEvent.message && (
          <p className="font-subparagraph">
            {orderEvent.rawContent ? (
              <EventRawContent
                event={orderEvent}
                maxLength={MESSAGE_LIMIT}
                disableTooltip
                colorCoded={false}
              />
            ) : (
              truncate(orderEvent.message, MESSAGE_LIMIT)
            )}
          </p>
        )}
        <p className="font-subparagraph">{renderAction(orderEvent, index)}</p>
        {renderEventResolution(orderEvent)}
      </li>
    )
  }

  return (
    <div>
      <ul className="list-unstyled list-bordered">
        {orderEvents.map((orderEvent, index) =>
          renderOrderEvent(orderEvent, index)
        )}
      </ul>
      <div className="text-center canopy-py-4x bold">
        <a
          href={reviewsUrl(supplierId, dmeOrder.id)}
          data-analytics="view-all"
          target="_blank"
          rel="noreferrer"
        >
          VIEW ALL ACTIVITY
        </a>
      </div>
    </div>
  )
}

const fetchInitialData = ({ supplierId, dmeOrder, setOrderEvents }) =>
  getOrderEvents(supplierId, dmeOrder.id).then((data) => setOrderEvents(data))
export default withInitialData(fetchInitialData)(UpdatesList)
