import React, { useState } from "react"
import { FormikValues } from "formik"
import classNames from "classnames"
import * as styles from "./index.module.scss"
import { User } from "../../../../sharedTypes"
import { camelizeKeys } from "humps"
import { useMutation } from "@apollo/client"
import { withApollo } from "../../../../hocs/withApollo"
import Alert from "../../../../components/Alert"

import { PayorUserUpdate } from "../graphql/userUpdateMutation"
import BackToPageButton from "../../../../components/BackToPageButton"
import { UserForm, UserFormAction } from "../components/UserForm"

interface UserPreferenceProps {
  user: User
}

interface MutationStatus {
  status: "success" | "error"
  message: string
}

const SUCCESS_MESSAGE = "Changes have been saved."
const ERROR_MESSAGE =
  "We are unable to save at this time. Please try again in a few minutes."

const UserPreferences = ({ user }: UserPreferenceProps) => {
  const [currentUser, setCurrentUser] = useState<User>(camelizeKeys(user))
  const [updateStatus, setUpdateStatus] = useState<MutationStatus | null>(null)

  const [userUpdate, { loading }] = useMutation(PayorUserUpdate, {
    onCompleted: (data) => {
      const { userUpdate } = data

      setUpdateStatus({
        status: "success",
        message: SUCCESS_MESSAGE,
      })

      if (userUpdate?.user) {
        setCurrentUser(userUpdate?.user as User)
      }
    },
    onError: () =>
      setUpdateStatus({
        status: "error",
        message: ERROR_MESSAGE,
      }),
  })

  const onSubmit = async (values: FormikValues) => {
    setUpdateStatus(null)
    await userUpdate({
      variables: { ...values, timeZone: values.timeZone.value },
    })
  }

  return (
    <div className="canopy-mx-8x">
      {!!updateStatus && (
        <Alert bordered leftIcon status={updateStatus.status}>
          {updateStatus.message}
        </Alert>
      )}
      <div className="canopy-mbs-4x canopy-mbe-8x">
        <BackToPageButton path="." labelText="Back to Member Search" />
      </div>

      <h1 className="canopy-mbe-8x canopy-typography-heading-2xlarge">
        User preferences
      </h1>
      <div
        className={classNames(styles.userPreferencesContainer, "canopy-p-12x")}
      >
        <UserForm
          handleFormSubmit={onSubmit}
          loading={loading}
          initialFormValues={{
            firstName: currentUser.firstName || "",
            lastName: currentUser.lastName || "",
            email: currentUser.email || "",
            timeZone: {
              label: currentUser.timeZone || "",
              value: currentUser.timeZone || "",
            },
          }}
          formAction={UserFormAction.Edit}
        />
      </div>
    </div>
  )
}

export default withApollo(UserPreferences)
