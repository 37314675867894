import React from "react"
import Select from "components/form/Select"
import LabelSubtext from "./LabelSubtext"
import { SendBackFormKeys, SendBackRecipient } from "./sharedTypes"

type Props = {
  supplierFollowables: SendBackRecipient[]
  search(term: string): Promise<any>
}

const SupplierFollowablesSelector = ({
  supplierFollowables,
  search,
}: Props) => {
  const label = (
    <>
      Order Followers (Optional)
      <LabelSubtext>
        What users or teams from your organization should stay up to date with
        this order?
      </LabelSubtext>
    </>
  )

  return (
    <Select
      fetchOptions={search}
      options={supplierFollowables}
      label={label}
      placeholder="Add a user or team from your organization"
      name={SendBackFormKeys.supplierFollowables}
      isMulti
      isSearchable
    />
  )
}

export default SupplierFollowablesSelector
