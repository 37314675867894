import React from "react"
import * as styles from "./index.module.scss"
import classNames from "classnames"

type ContentAreaProps = {
  variant?: "full" | "medium"
} & React.HTMLProps<HTMLDivElement>

const ContentArea = ({
  children,
  className,
  variant = "full",
  ...other
}: ContentAreaProps) => {
  return (
    <div
      className={classNames(styles.mainContainer, className, styles[variant])}
      {...other}
    >
      {children}
    </div>
  )
}

export default ContentArea
