// @ts-strict-ignore
import React, { useEffect } from "react"
import ExpandableTruncatedText from "components/ExpandableTruncatedText"
import { Form, Checkbox } from "components/form"
import { Doctor } from "sharedTypes"
import { EventCategory, trackEvent } from "utilities/tracking"

interface Props {
  doctor: Doctor
  extraAttestationRequested?: boolean
  isExtraAttestationChecked?: boolean
  onChange?(event): void
}

const EVENT_CATEGORY = EventCategory.SignOnScreen

function SignOnScreenAttestation({
  doctor,
  extraAttestationRequested,
  isExtraAttestationChecked,
  onChange,
}: Props) {
  useEffect(() => {
    const eventName = extraAttestationRequested
      ? "extra-attestation-viewed"
      : "attestation-viewed"
    trackEvent(EVENT_CATEGORY, eventName)
  }, [extraAttestationRequested])

  const onClick = (event) => {
    onChange(event)
    trackEvent(EVENT_CATEGORY, "extra-attestation-clicked")
  }

  const extraAttestationForm = () => {
    const name = "extraAttestation"
    return (
      <Form
        initialValues={{ [name]: isExtraAttestationChecked }}
        enableReinitialize
      >
        <Checkbox
          type={Checkbox.Type.Basic}
          name={name}
          onChange={onClick}
          label={
            <ExpandableTruncatedText
              fullText={`I, ${doctor.firstName} ${doctor.lastName}, am the prescribing practitioner and am signing this order, and certify that the medical necessity info contained herein is true accurate and complete, to the best of my knowledge, and is consistent with the information contained in the clinical records for the Patient and/or in the Patient’s permanent medical record. I understand that any falsification, omission, or concealment of material fact in this order with respect to any information contained herein may subject me to civil or criminal liability.`}
            />
          }
        />
      </Form>
    )
  }

  return extraAttestationRequested ? (
    extraAttestationForm()
  ) : (
    <ExpandableTruncatedText
      fullText={`${doctor.firstName} ${doctor.lastName} has reviewed and authorized this order and has certified that the medical necessity info contained herein is true accurate and complete, to the best of their knowledge, and is consistent with the information contained in my clinical records for the Patient and/or in the Patient’s permanent medical record. I certify that the patient/caregiver is capable and has successfully completed training or will be trained on the proper use of the products prescribed on this written order. I understand that any falsification, omission, or concealment of material fact in this order with respect to any information contained herein may subject me to civil or criminal liability.`}
    />
  )
}

export default SignOnScreenAttestation
