// @ts-strict-ignore

import React, { useCallback, useState } from "react"
import Icon from "components/Icon"
import Table, { TableColumnDefinition } from "components/Table"
import { InfiniteLoadTable } from "components/Table/InfiniteLoadTable"
import { useDebounce } from "hooks/useDebounce"
import { Upload } from "applications/DocumentationOrders/api"
import TableOverviewPresentation from "components/TableOverviewPresentation"
import BackToTop from "components/BackToTop"
import UploadsSummaryModal from "applications/Uploads/UploadsTable/UploadsSummaryModal"
import { MaterialThemeProvider } from "themes/theme"

const columnDefinitions = (
  resultsUrlBuilder
): TableColumnDefinition<Upload & { key: string }>[] => {
  return [
    {
      title: "File name",
      attr: "fileName",
    },
    {
      title: "Rows",
      attr: "rows",
    },
    {
      title: "Uploaded By",
      attr: "uploadedBy",
    },
    {
      title: "Upload Date",
      attr: "uploadDate",
    },
    {
      title: "Upload Status",
      attr: "processed",
      render: (record) => {
        const uploadStatus = (record) => {
          if (record.processed) {
            return (
              <Icon
                ariaLabel="Successful, no errors"
                type="check"
                className="color-check-success"
              />
            )
          } else if (record.jobFailed) {
            return (
              <Icon
                type="exclamation-circle"
                className="color-danger"
                ariaLabel="Failed to upload"
              />
            )
          }

          return "Processing"
        }
        return <div>{uploadStatus(record)}</div>
      },
    },
    {
      title: "Results",
      attr: "results",
      render: (record) => {
        if (record.jobFailed) {
          return (
            <React.Fragment>
              <Icon
                type="exclamation-circle"
                className="color-danger canopy-p-4x"
              />
              Upload failed
            </React.Fragment>
          )
        }

        return record.processed ? (
          <>
            {record.numberOfRowsWithErrors === 0 ? (
              <Icon type="check" className="color-check-success canopy-p-4x" />
            ) : (
              <Icon
                type="exclamation-triangle"
                className="color-warning canopy-p-4x"
              />
            )}
            <UploadsSummaryModal
              record={record}
              resultsUrl={resultsUrlBuilder(record.id)}
            ></UploadsSummaryModal>
          </>
        ) : (
          "Pending"
        )
      },
    },
  ]
}

const UploadsTable = ({
  supplierOrganizationId,
  uploadsIndex,
  resultsUrlBuilder,
}: {
  supplierOrganizationId: string
  uploadsIndex: ({ supplierOrganizationId, page }) => Promise<any>
  resultsUrlBuilder: Function
}) => {
  const [currentCount, setCurrentCount] = useState(0)
  const [loading, setLoading] = useState(true)

  const fetchUploads = (supplierOrganizationId: string, page: number) =>
    uploadsIndex({ supplierOrganizationId, page })
      .then((results) => {
        setCurrentCount(results.currentCount)
        setLoading(false)
        return {
          records: results.uploads.map((upload) => ({
            ...upload,
            key: upload.id,
          })),
          totalCount: results.currentCount,
          hasNextPage: results.hasNextPage,
        }
      })
      .catch(({ response }) => Promise.reject(response.data.errors))

  const debouncedFetchUploads = useDebounce(fetchUploads)
  const loadUploads = useCallback(
    (newPage) => debouncedFetchUploads(supplierOrganizationId, newPage),
    [supplierOrganizationId, debouncedFetchUploads]
  )

  return (
    <MaterialThemeProvider>
      <TableOverviewPresentation
        results={`${loading ? "x" : currentCount} uploads`}
        sortingBy="Upload Date"
      />
      <InfiniteLoadTable
        loadPage={loadUploads}
        Table={Table}
        handleLoadingIndicators={false}
        tableProps={{
          loading: loading,
          tableColumns: columnDefinitions(resultsUrlBuilder),
        }}
      />
      <BackToTop />
    </MaterialThemeProvider>
  )
}

export default UploadsTable
