import React from "react"
import * as styles from "./index.module.scss"

interface Props {
  title: string
  description: string
  imageSrc: string
  alt?: string
}

const MarketingContent: React.FC<Props> = ({
  title,
  description,
  imageSrc,
  alt,
}) => {
  return (
    <div className={styles.marketingContentInnerContainer}>
      <img
        src={imageSrc}
        alt={alt || title}
        className={styles.marketingContentImage}
      />
      <div>
        <h3>{title}</h3>
        <p className={styles.featureDescription}>{description}</p>
      </div>
    </div>
  )
}
export default MarketingContent
