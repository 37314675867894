import React from "react"
import Icon from "components/Icon"
import * as styles from "./index.module.scss"
import classNames from "classnames"

type Props = {
  loading?: boolean
  onChange?(value: string): void
  value?: string
  name: string
  placeholder?: string
  size?: "sm" | "md"
  rounding?: "md" | "lg"
}

function SearchInput({
  loading,
  onChange,
  value,
  name,
  size,
  rounding,
  placeholder,
}: Props) {
  return (
    <div className={classNames(styles.container, "has-feedback")}>
      <input
        onChange={(event) => onChange && onChange(event.target.value)}
        value={value}
        name={name}
        className={classNames(
          styles.input,
          "form-control",
          styles.removeClearIconIe,
          {
            [styles.inputSm]: size === "sm",
            [styles.roundedMd]: rounding === "md",
            [styles.roundedLg]: rounding === "lg",
          }
        )}
        placeholder={placeholder}
      />
      <Icon
        className={classNames(styles.feedback, "form-control-feedback")}
        spin={loading}
        type={loading ? "spinner" : "search"}
      />
    </div>
  )
}

SearchInput.defaultProps = {
  value: "",
  size: "md",
  rounding: "md",
}

export default SearchInput
