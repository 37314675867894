import React, { useState } from "react"
import { Checkbox } from "components/form"
import TermsOfUseModal from "components/modal/TermsOfUseModal"

const SignedAgreementCheckbox = () => {
  const [showModal, setShowModal] = useState(false)
  return (
    <>
      <Checkbox
        label={
          <>
            I have read and agree to the{" "}
            <a
              className="link link-primary"
              onClick={(event) => {
                event.preventDefault()
                setShowModal(true)
              }}
            >
              Facility Terms of Use
            </a>
          </>
        }
        name="signedAgreement"
        size={Checkbox.Size.Small}
        type={Checkbox.Type.Basic}
      />
      <TermsOfUseModal show={showModal} close={() => setShowModal(false)} />
    </>
  )
}

export default SignedAgreementCheckbox
