import React, { useMemo, useState } from "react"
import * as styles from "./index.module.scss"
import Tooltip from "../Tooltip"
import { useDebounce } from "../../hooks/useDebounce"
import classNames from "classnames"

interface Props {
  className?: string
  text: string
  maxLines?: number
}

const TruncatedText = ({ className, maxLines = 1, text }: Props) => {
  const [hideToolTip, setHideToolTip] = useState<boolean>(true)

  const showToolTipIfTruncated = useDebounce(
    (entries: ResizeObserverEntry[]) => {
      const container = entries[0].target as HTMLDivElement
      const isTruncatedWebkit = container.scrollHeight > container.clientHeight
      setHideToolTip(!isTruncatedWebkit)
    }
  )

  const resizeObserver = useMemo(
    () => window.ResizeObserver && new ResizeObserver(showToolTipIfTruncated),
    [showToolTipIfTruncated]
  )

  const textContainerRef = React.useCallback(
    (container: HTMLDivElement) => {
      if (resizeObserver) {
        if (container) {
          resizeObserver.observe(container)
        } else {
          resizeObserver.disconnect()
        }
      } else {
        if (container) {
          const isTruncatedIe11 = container.scrollWidth > container.offsetWidth
          setHideToolTip(!isTruncatedIe11)
        }
      }
    },
    [resizeObserver]
  )

  return (
    <Tooltip
      trigger={["hover"]}
      overlay={text}
      placement={Tooltip.Placement.Top}
      width={Tooltip.Width.LimitedWide}
      disabled={hideToolTip}
    >
      <div
        ref={textContainerRef}
        style={{ WebkitLineClamp: maxLines }}
        className={classNames(styles.truncatedText, className)}
      >
        {text}
      </div>
    </Tooltip>
  )
}

export default TruncatedText
