import { CSVUploadForm } from "applications/Cms/components/CSVUploadForm"
import Modal from "components/Modal"
import * as api from "../api"
import { reload } from "utilities/navigation"
import { CanopyButton } from "@parachutehealth/canopy-button"
import React from "react"
import { useState, useRef } from "react"

const DEFAULT_FILE_FIELD = "file"

interface SupplierInsuranceSettingsImportDialogFormModalProps {
  fileFieldName: string
}

const SupplierInsuranceSettingsImportFormDialogModal = ({
  fileFieldName,
}: SupplierInsuranceSettingsImportDialogFormModalProps) => {
  const fileField = fileFieldName || DEFAULT_FILE_FIELD

  const onSubmit = async (file: File) => {
    try {
      await api.createUpload(file)
      closeModal()
      reload()
    } catch (e) {
      // Error handled server-side. Need catch so UI doesn't fail
    }
  }

  const [open, setOpen] = useState(false)
  const openModal = () => setOpen(true)
  const closeModal = () => setOpen(false)
  const activatorRef = useRef<HTMLButtonElement>(null)

  return (
    <>
      <CanopyButton
        size="medium"
        variant="primary"
        className="canopy-my-8x canopy-mie-2x"
        ref={activatorRef}
        onClick={openModal}
      >
        Import
      </CanopyButton>
      <Modal show={open} size="md">
        <Modal.Header>
          <h2 className="modal-title">Upload CSV</h2>
          <button
            aria-label="Close"
            className="close"
            type="button"
            style={{ marginTop: "-1rem", lineHeight: 1.4286 }}
            onClick={() => setOpen(false)}
          >
            ×
          </button>
        </Modal.Header>
        <Modal.Body>
          <CSVUploadForm
            fileFieldName={fileField}
            submitCb={onSubmit}
            closeCb={() => setOpen(false)}
            requiredCsvColumns={"Accepted,External System ID,Plan Family,Type,Parachute ID".split(
              ","
            )}
          />
        </Modal.Body>
      </Modal>
    </>
  )
}
export default SupplierInsuranceSettingsImportFormDialogModal
