import { employerPrefix, EmployerType } from "utilities/url"

export const makeUrlProvider = (
  employerType: EmployerType,
  employerId: string
) => {
  const urlPrefix = employerPrefix(employerType, employerId)
  return {
    createDmeOrderUrl: `${urlPrefix}/orders.json`,
    patientValidateUrl: `${urlPrefix}/orders/patient_validate.json`,
    suppliersUrl: `${urlPrefix}/suppliers`,
  }
}
