// @ts-strict-ignore
import React, { useState } from "react"
import classNames from "classnames"
import Popover from "components/Popover"
import {
  DmeOrderEvent,
  SupplierDashboardDmeOrder,
} from "applications/SupplierDashboard/sharedTypes"
import { timeAgoInWords } from "utilities/date"
import UpdatesList from "./UpdatesList"
import { SupplierOrganizationDashboardDmeOrder } from "../../sharedTypes"

type Props = {
  dmeOrder: SupplierDashboardDmeOrder | SupplierOrganizationDashboardDmeOrder
  supplierId?: string
}

const UpdatesEditor = ({ dmeOrder, supplierId }: Props) => {
  const [orderEvents, setOrderEvents] = useState<DmeOrderEvent[]>([])
  const unresolvedEventCount = () =>
    orderEvents.length
      ? orderEvents.filter((orderEvent) => !orderEvent.resolved).length
      : dmeOrder.unresolvedEventCount
  const hasUnresolved = () => unresolvedEventCount() > 0
  const [open, setOpen] = useState(false)
  const [lastActivityAt, setLastActivityAt] = useState(dmeOrder.lastActivityAt)
  const toggle = () => setOpen((open) => !open)
  const hide = () => setOpen(false)

  if (dmeOrder.transferredOrderLocked) {
    return null
  }

  return (
    <div data-analytics-context="UpdatesEditor">
      <Popover
        open={open}
        onHide={hide}
        onClick={toggle}
        label={
          <a title="Show popover" data-analytics="open-updates-popover">
            <span
              className={classNames("unresolved_count", {
                "color-accent": hasUnresolved(),
              })}
            >
              {lastActivityAt && (
                <span className="nowrap">{timeAgoInWords(lastActivityAt)}</span>
              )}
              {hasUnresolved() && (
                <>
                  {lastActivityAt && <br />}
                  <span className="nowrap">
                    New Activity ({unresolvedEventCount()})
                  </span>
                </>
              )}
            </span>
          </a>
        }
      >
        <UpdatesList
          dmeOrder={dmeOrder}
          supplierId={supplierId}
          orderEvents={orderEvents}
          setOrderEvents={setOrderEvents}
          setLastActivityAt={setLastActivityAt}
        />
      </Popover>
    </div>
  )
}

export default UpdatesEditor
