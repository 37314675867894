import { getName } from "./string"
import { stringify } from "query-string"

export function getQuery(value = "") {
  const { firstName, lastName } = getName(value)
  if (firstName && lastName) {
    return `?${stringify({ first_name: firstName, last_name: lastName })}`
  }
  return ""
}
