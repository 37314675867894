import { employerPrefix } from "utilities/url"

export const unsignedPdfUrl = (
  doctorId: string,
  signingSignatureRequestId: string
) =>
  `${employerPrefix(
    "Doctor",
    doctorId
  )}/signing_signature_requests/${signingSignatureRequestId}/unsigned_pdf.pdf`
