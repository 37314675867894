import React from "react"
import DmeOrderPatientCard, { PatientSearchResult } from "./DmeOrderPatientCard"
import { ToastProvider } from "../../components/Toaster"
import { Context, CsrInboxDocument } from "sharedTypes"
import { FeatureFlagInput } from "../../sharedTypes"
import { FeatureFlagProvider } from "components/FeatureFlagContext"

type Props = {
  featureFlags: FeatureFlagInput
  patientSearchResults: PatientSearchResult[]
  supplierId: string
  csrInboxStateId: string
  context: Context
  document: CsrInboxDocument
}

const DmeOrderPatientCards = (props: Props) => {
  return (
    <ToastProvider>
      <FeatureFlagProvider flags={props.featureFlags}>
        {props.patientSearchResults.map((result) => {
          return (
            <DmeOrderPatientCard
              key={result.patient.id}
              supplierId={props.supplierId}
              csrInboxStateId={props.csrInboxStateId}
              employer={props.context.currentEmployer}
              document={props.document}
              {...result}
            />
          )
        })}
      </FeatureFlagProvider>
    </ToastProvider>
  )
}

export default DmeOrderPatientCards
