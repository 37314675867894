import React from "react"
import Modal from "components/Modal"
import PatientInfoForm from "./PatientInfoForm"
import { Address, DmeOrder } from "sharedTypes"

type Props = {
  show: boolean
  billingAddress: Address
  dmeOrder: DmeOrder
  onClose(): void
  update(values): Promise<void>
}

const PatientInfoModal: React.FC<Props> = ({
  show,
  billingAddress,
  dmeOrder,
  onClose,
  update,
}) => {
  return (
    <Modal size="md" show={show} cancel={onClose} title="Patient Info">
      <Modal.Body>
        <PatientInfoForm
          update={update}
          initialState={dmeOrder.clinicalFacility.state}
          initialPhoneNumber={dmeOrder.billingPhoneNumber}
          initialEmail={dmeOrder.patientSnapshot.email}
          initialPreferredLanguage={dmeOrder.patientSnapshot.preferredLanguage}
          billingAddress={billingAddress}
          onClose={onClose}
          liaison={dmeOrder.clinicalFacility.liaison}
        />
      </Modal.Body>
    </Modal>
  )
}

export default PatientInfoModal
