import React from "react"
import ExpandableTruncatedText from "components/ExpandableTruncatedText"

function SelfSignAttestation() {
  return (
    <ExpandableTruncatedText fullText="I certify that I am the treating clinician identified on this document. I reviewed the written order, and the information reflects the patient’s condition and treatment plan. Any statement on my letterhead attached hereto, has been reviewed and signed by me. I certify that the medical necessity information above contained herein is true, accurate and complete, to the best of my knowledge, and is consistent with the information contained in my clinical records for the Patient and/or in the Patient’s permanent medical record. I certify that the patient/caregiver is capable and has successfully completed training or will be trained on the proper use of the products prescribed on this written order. I understand that any falsification, omission, or concealment of material fact in that section with respect to any information contained herein may subject me to civil or criminal liability." />
  )
}

export default SelfSignAttestation
