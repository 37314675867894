import { put } from "../../../../../services/api"
import { updatePatientAddressUrl } from "../../../urls"
import { PatientAddress } from "./index"
import { ResourceUpdateResponse } from "../api"

export const updatePatientAddress = (
  supplierOrganizationId: string,
  csrInboxStateId: string,
  patientAddress: Partial<PatientAddress>
): Promise<ResourceUpdateResponse> => {
  return put(updatePatientAddressUrl(supplierOrganizationId, csrInboxStateId), {
    patientAddress,
  })
    .then(() => ({ success: true }))
    .catch((e) => ({ errors: e.response?.data?.errors || {} }))
}
