// @ts-strict-ignore
import React, { useEffect, useState } from "react"
import InternalFacilitySelectForm from "./components/InternalFacilitySelectForm"
import withInitialData from "components/withInitialData"
import { makeApi } from "./api"
import {
  ContextEmployer,
  FacilityOption,
  SupplierLegalEntity,
} from "sharedTypes"
import { Employee } from "./sharedTypes"
import { employerTypeFromContext } from "utilities/url"

type InitialData = {
  requiresLegalEntity: boolean
  showDoctorSelect: boolean
}

type Props = {
  employer?: ContextEmployer
  closeModal(): void
  savedFacilityGid?: string
  defaultDoctor?: {
    doctorId: string
    firstName: string
    lastName: string
    npi: string
    clinicalOrganizations: string[]
  }
  documentFacility?: { id: string; name: string }
  initialData: InitialData
  modalTitle: string
  showModal: boolean
  submit(
    clinicalFacilityId: string,
    doctorId: string,
    legalEntityId: string,
    externalSystemBranchId: string,
    salesRepId: string,
    legalEntityAssignmentMethod: string
  ): Promise<void>
  submitButtonText: string
}

function FacilitySelectForm(props: Props) {
  const {
    employer,
    closeModal,
    defaultDoctor,
    documentFacility,
    initialData,
    modalTitle,
    showModal,
    submit,
    submitButtonText,
    savedFacilityGid,
  } = props
  const { requiresLegalEntity, showDoctorSelect } = initialData
  const [isSubmitting, setSubmitting] = useState(false)
  const [doctorId, setDoctorId] = useState(defaultDoctor?.doctorId || null)
  const [selectedFacilityGid, setClinicalFacilityGid] = useState(null)
  const [recommendedFacilities, setRecommendedFacilities] = useState(
    [] as FacilityOption[]
  )
  const [
    requiresExternalSystemBranch,
    setRequiresExternalSystemBranch,
  ] = useState(null)
  const [defaultLegalEntity, setDefaultLegalEntity] = useState(
    {} as SupplierLegalEntity
  )
  const [defaultSalesRep, setDefaultSalesRep] = useState({} as Employee)
  const [legalEntityId, setLegalEntityId] = useState(null)
  const [
    legalEntityAssignmentMethod,
    setLegalEntityAssignmentMethod,
  ] = useState(null)
  const [externalSystemBranchId, setExternalSystemBranchId] = useState(null)
  const [salesRepId, setSalesRepId] = useState(null)
  const [showFacilityError, setShowFacilityError] = useState(false)
  const [
    showFulfillmentAgreementFields,
    setShowFulfillmentAgreementFields,
  ] = useState(false)
  const api = makeApi(
    employerTypeFromContext(employer?.employerType),
    employer?.employerId
  )

  useEffect(() => {
    if (showModal && (documentFacility?.id || defaultDoctor?.doctorId)) {
      queryDefaultValues({
        clinicalFacilityId: documentFacility?.id,
        doctorId: defaultDoctor?.doctorId,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showModal])

  const handleSubmit = () => {
    setSubmitting(true)
    return submit(
      selectedFacilityGid,
      doctorId,
      legalEntityId,
      externalSystemBranchId,
      salesRepId,
      legalEntityAssignmentMethod
    )
      .then(() => {
        setSubmitting(false)
        setShowFacilityError(false)
      })
      .catch(({ errors }) => {
        if (!!errors?.clinicalFacility) {
          setShowFacilityError(true)
        }
        setSubmitting(false)
      })
  }

  const selectFacility = (gid) => {
    setShowFacilityError(false)
    setClinicalFacilityGid(gid)
    queryDefaultValues({ clinicalFacilityId: gid, doctorId })
  }

  const selectDoctor = (id) => {
    setDoctorId(id)
    queryDefaultValues({
      clinicalFacilityId: selectedFacilityGid,
      doctorId: id,
    })
  }

  const queryDefaultValues = ({ clinicalFacilityId, doctorId }) => {
    if (clinicalFacilityId || doctorId) {
      setShowFulfillmentAgreementFields(false)
      api
        .getDefaultValues({ clinicalFacilityId, doctorId })
        .then((response) => {
          setRecommendedFacilities(response?.recommendedFacilities?.results)
          setDefaultLegalEntity(
            response?.supplierLegalEntity || ({} as SupplierLegalEntity)
          )
          setDefaultSalesRep(response?.externalSalesUser || ({} as Employee))
          setLegalEntityId(response?.supplierLegalEntity?.id || null)
          setSalesRepId(response?.externalSalesUser?.userId || null)
          setRequiresExternalSystemBranch(
            response?.requiresExternalSystemBranch
          )
          setLegalEntityAssignmentMethod(response?.legalEntityAssignmentMethod)
          setShowFulfillmentAgreementFields(true)
        })
    } else {
      clearDefaultValues()
    }
  }

  const selectLegalEntity = (legalEntityId) => {
    setLegalEntityId(legalEntityId)
    setLegalEntityAssignmentMethod("user_selected")
  }

  const selectExternalSystemBranch = (externalSystemBranchId) => {
    setExternalSystemBranchId(externalSystemBranchId)
  }

  const selectSalesRep = (salesRepId) => {
    setSalesRepId(salesRepId)
  }

  const handleCloseModal = () => {
    closeModal()
    setDoctorId(null)
    setClinicalFacilityGid(null)
    clearDefaultValues()
  }

  const clearDefaultValues = () => {
    setRecommendedFacilities([])
    setRequiresExternalSystemBranch(null)
    setDefaultLegalEntity({} as SupplierLegalEntity)
    setDefaultSalesRep({} as Employee)
    setLegalEntityId(null)
    setExternalSystemBranchId(null)
    setSalesRepId(null)
    setShowFulfillmentAgreementFields(false)
  }

  const hasMissingFields =
    (!doctorId && !selectedFacilityGid) ||
    (requiresExternalSystemBranch && !externalSystemBranchId) ||
    (requiresLegalEntity && !legalEntityId)

  return (
    <InternalFacilitySelectForm
      fetchDoctors={api.fetchDoctors}
      fetchFacilities={api.fetchFacilities}
      fetchLegalEntities={api.fetchLegalEntities}
      fetchExternalSystemBranches={api.fetchExternalSystemBranches}
      fetchEmployees={api.fetchEmployees}
      showDoctorSelect={showDoctorSelect}
      submit={handleSubmit}
      submitButtonText={submitButtonText}
      isSubmitting={isSubmitting}
      closeModal={handleCloseModal}
      showModal={showModal}
      modalTitle={modalTitle}
      hasMissingFields={hasMissingFields}
      hasInvalidFacility={
        savedFacilityGid && selectedFacilityGid === savedFacilityGid
      }
      showFacilityError={showFacilityError}
      recommendedFacilities={recommendedFacilities}
      requiresExternalSystemBranch={requiresExternalSystemBranch}
      defaultDoctor={defaultDoctor}
      selectFacility={selectFacility}
      selectDoctor={selectDoctor}
      selectLegalEntity={selectLegalEntity}
      selectExternalSystemBranch={selectExternalSystemBranch}
      selectSalesRep={selectSalesRep}
      showFulfillmentAgreementFields={showFulfillmentAgreementFields}
      defaultLegalEntity={defaultLegalEntity}
      defaultSalesRep={defaultSalesRep}
      requiresLegalEntity={requiresLegalEntity}
    />
  )
}

const fetchInitialData = ({ employer }: Omit<Props, "initialData">) => {
  const api = makeApi(
    employerTypeFromContext(employer?.employerType),
    employer?.employerId
  )
  return api.getInitialValues().then((data: InitialData) => data)
}

export default withInitialData(fetchInitialData)(FacilitySelectForm)
