// @team @expanse

import React from "react"
import { CanopyDialogModal } from "@parachutehealth/canopy-dialog-modal"
import { CanopyButton } from "@parachutehealth/canopy-button"
import { reload } from "utilities/navigation"

type Props = {
  open: boolean
  setOpen: (open: boolean) => void
  errorMessage: string
}

const ReorderErrorModal = (props: Props) => {
  const onClose = () => {
    props.setOpen(false)
    reload()
  }

  return (
    <CanopyDialogModal
      header="Unable to create reorder"
      onClose={() => onClose()}
      open={props.open}
      primaryFooterButton={<CanopyButton onClick={onClose}>Close</CanopyButton>}
      size="medium"
    >
      {props.errorMessage}
    </CanopyDialogModal>
  )
}

export default ReorderErrorModal
