import $ from "jquery"
import { get, headers, post } from "./ajax"
import { navigate } from "utilities/navigation"
import { alert } from "utilities/alert"
import { poll } from "utilities/poll"
import { AxiosResponse } from "axios"

function enableLink($link) {
  $link.removeAttr("disabled")
  $link.removeClass("is-downloading").addClass("is-inactive")
}

function disableLink($link) {
  $link.attr("disabled", true)
  $link.removeClass("is-inactive").addClass("is-downloading")
}

function onSuccess($link, data) {
  navigate(data.document_download_path)
  enableLink($link)
}

function onFail($link) {
  alert("Something went wrong, please try again")
  enableLink($link)
}

function documentPoll($link, url: string) {
  if (!$link.closest(document.documentElement).length) {
    return
  }
  poll(
    () =>
      get(url, {
        headers: {
          "Content-Type": "application/json",
          "X-Requested-With": "XMLHttpRequest",
          ...headers(),
        },
        data: {},
      }),
    {
      delay: 1000,
      success(data) {
        onSuccess($link, data)
      },
      failure() {
        onFail($link)
      },
      error() {
        onFail($link)
      },
    }
  )
}

$(document).on("click", ".js-document-download", function (event) {
  event.preventDefault()
  const $link = $(this)
  const url = $link.attr("href")
  if ($link.attr("disabled")) {
    return false
  }
  disableLink($link)
  post(url, {
    headers: headers(),
  })
    .then(function ({ data }: AxiosResponse) {
      const castData = data as { path: string }
      documentPoll($link, castData.path)
    })
    .catch(function () {
      onFail($link)
    })
})
