import React from "react"
import { asForm, EmailField, PhoneField } from "components/form"

type Props = {
  emailDisabled: boolean
  mobileNumberDisabled: boolean
  isValid: boolean
}

const ContactInfoForm = ({
  isValid,
  emailDisabled,
  mobileNumberDisabled,
}: Props) => {
  const anyDigitalMethods = emailDisabled || mobileNumberDisabled
  return (
    <>
      <EmailField
        label="Clinician Email Address"
        name="email"
        disabled={emailDisabled}
      />
      <PhoneField
        label="Clinician Cell Number"
        name="mobileNumber"
        disabled={mobileNumberDisabled}
      />
      <div className="text-right">
        <button type="submit" className="btn btn-primary" disabled={!isValid}>
          {anyDigitalMethods ? "Save to Network" : "Send Invite"}
        </button>
      </div>
    </>
  )
}

export default asForm(ContactInfoForm)
