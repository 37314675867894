import { fullName } from "utilities/person"

export const doctorNameWithCredential = (doctor) => {
  if (!doctor) {
    return
  }

  if (!doctor.credential) {
    return fullName(doctor)
  }

  return `${fullName(doctor)}, ${doctor.credential}`
}
