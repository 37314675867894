// @ts-strict-ignore
export const ZIP_VALIDATION_MESSAGE = "Enter a valid ZIP Code"

const ZIP_REGEXP = /^[0-9]{5}(?:-[0-9]{4})?$/
const FIVE_DIGIT_ZIP_REGEX = /^(\d{5})$/
export function isZipValid(zip?: string): boolean {
  return ZIP_REGEXP.test(zip)
}

export function isFiveDigitZip(zip?: string): boolean {
  return FIVE_DIGIT_ZIP_REGEX.test(zip)
}
