import React from "react"
import * as styles from "./index.module.scss"

interface Props {
  query?: string
}

const SortDescription: React.FC<Props> = ({ query }) => {
  const sortingType = query ? "Relevancy" : "Order Volume"

  return (
    <div className={styles.sortingBy}>
      <span>
        {"Sorting by: "}
        <strong>{sortingType}</strong>
      </span>
    </div>
  )
}

export default SortDescription
