import React, { ReactElement } from "react"
import ExternalQuestionnaireModalButton from "../ExternalQuestionnaireModalButton/ExternalQuestionnaireModalButton"
import * as styles from "./MedicalNecessitySummaryRow.module.scss"
import { QuestionAndAnswers } from "../../../../../../../../../../sharedTypes"

type Props = {
  title: string
  questionsAndAnswers?: QuestionAndAnswers[]
  statusPill: ReactElement
}
function MedicalNecessitySummaryRow({
  title,
  questionsAndAnswers,
  statusPill,
}: Props) {
  return (
    <span className={`${styles.rowWrapper}`}>
      <span className="flex-1">{title}</span>
      {questionsAndAnswers && (
        <span className={styles.floatRight}>
          <ExternalQuestionnaireModalButton
            title={title}
            questionsAndAnswers={questionsAndAnswers}
          />
        </span>
      )}
      {statusPill}
    </span>
  )
}

export default MedicalNecessitySummaryRow
