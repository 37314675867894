import React from "react"
import FacilitySelectForm from "applications/FacilitySelectForm"
import { UpdateClinicalFacilityAttributes } from "../sharedTypes"
import { Doctor } from "sharedTypes"

interface Props {
  closeModal: () => void
  savedFacilityGid: string
  updateClinicalFacility: (
    attributes: UpdateClinicalFacilityAttributes
  ) => Promise<void>
  defaultDoctor?: Doctor
}

const UpdateClinicalFacilityModal = ({
  closeModal,
  savedFacilityGid,
  updateClinicalFacility,
  defaultDoctor,
}: Props) => {
  const submit = (
    clinicalFacilityId: string,
    doctorId: string,
    selectedLegalEntityId: string,
    selectedExternalSystemBranchId: string,
    selectedSalesRepId: string
  ) => {
    return updateClinicalFacility({
      newClinicalFacilityId: clinicalFacilityId,
      newDoctorId: doctorId,
      supplierLegalEntityId: selectedLegalEntityId,
      externalSystemBranchId: selectedExternalSystemBranchId,
      externalSalesRepId: selectedSalesRepId,
    })
  }

  return (
    <FacilitySelectForm
      savedFacilityGid={savedFacilityGid}
      showModal
      closeModal={closeModal}
      modalTitle="Update Facility"
      submit={submit}
      submitButtonText="Update Facility"
      defaultDoctor={defaultDoctor}
    />
  )
}
export default UpdateClinicalFacilityModal
