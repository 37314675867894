import React from "react"
import Alert from "components/Alert"

interface Props {
  userEmail: string
}

function FollowTheLinkBanner({ userEmail }: Props) {
  return (
    <Alert leftIcon status="info">
      Follow the link in the email sent to {userEmail} to finish signing up.
    </Alert>
  )
}

export default FollowTheLinkBanner
