export function loginUrl() {
  return "/users/log_in"
}

export function updateUserUrl(clinicalFacilityId: string, userId: string) {
  return `/f/${clinicalFacilityId}/sign_up/users/${userId}`
}

export function updatePasswordUrl(clinicalFacilityId: string, userId: string) {
  return `/f/${clinicalFacilityId}/sign_up/passwords/${userId}.json`
}

export function resendInvitationUrl(
  clinicalFacilityId: string,
  userId: string
) {
  return `/f/${clinicalFacilityId}/sign_up/users/${userId}/resend_invitation`
}
