import React from "react"
import { Form } from "components/form"
import Select from "components/form/Select"
import Button from "components/form/Button"
import { EmrInstance } from "../sharedTypes"

interface Props {
  emrInstances: EmrInstance[]
  onSubmit(params: { emrInstanceId: string }): Promise<void>
}

const EpicLoginForm = ({ emrInstances, onSubmit }: Props) => {
  const initialValues = {
    emrInstanceId: "",
  }

  return (
    <Form onSubmit={onSubmit} initialValues={initialValues}>
      {({ isSubmitting, values }) => (
        <>
          <Select
            options={emrInstances.map(({ name, externalId }) => ({
              value: externalId,
              label: name,
            }))}
            name="emrInstanceId"
            placeholder="Select organization"
            isSearchable
            isClearable
          />
          <Button
            type="submit"
            className="btn-brand btn-block"
            disabled={isSubmitting || !values.emrInstanceId}
          >
            Continue
          </Button>
        </>
      )}
    </Form>
  )
}

export default EpicLoginForm
