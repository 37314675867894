import { Form, TextField, Button } from "../../../components/form"
import { Field } from "formik"
import TemplateFormField from "../TemplateFormField"
import React from "react"
import {
  useFetchSupplierCommentTemplate,
  useCreateSupplierCommentTemplate,
  useUpdateSupplierCommentTemplate,
} from "../queries"
import { Nullable } from "sharedTypes"

const MessageTemplateForm = ({
  action,
  supplierId,
  supplierName,
  templateId,
  hideForm,
}: {
  action: string
  supplierId: string
  supplierName: string
  templateId: Nullable<string>
  hideForm: () => void
}) => {
  const {
    data: currentSupplierCommentTemplate,
    isInitialLoading,
  } = useFetchSupplierCommentTemplate(supplierId, templateId, !!templateId)

  const {
    mutateAsync: createSupplierCommentTemplate,
  } = useCreateSupplierCommentTemplate(supplierId)

  const {
    mutateAsync: updateSupplierCommentTemplate,
  } = useUpdateSupplierCommentTemplate(supplierId)

  const onSubmit = (params, { setErrors }) => {
    if (action === "Add") {
      createSupplierCommentTemplate(params)
        .then((_data) => {
          hideForm()
        })
        .catch(({ errors }) => setErrors(errors))
    } else {
      updateSupplierCommentTemplate({ templateId, ...params })
        .then((_data) => {
          hideForm()
        })
        .catch(({ errors }) => setErrors(errors))
    }
  }

  const validateMessage = (value: string) => !value

  const validateName = (value: string) => {
    if (!value) {
      return "Required"
    } else if (value.length > 30) {
      return "Must be 30 characters or less"
    }
  }

  if (isInitialLoading) return null

  return (
    <>
      <h1 className="canopy-my-8x">{action} Template</h1>
      <div className="canopy-mbe-12x">
        Once added, {supplierName} users can select the template from within the
        Global Comment section on the Activity Feed. Users can view the
        templated message and edit as needed prior to sending it out.
      </div>
      <Form
        initialValues={{
          name: currentSupplierCommentTemplate?.name || "",
          template: currentSupplierCommentTemplate?.template || "",
        }}
        onSubmit={onSubmit}
      >
        {({ errors, isSubmitting, values }) => (
          <>
            <TextField
              label="Template Name"
              name="name"
              placeholder="Enter template name..."
              subtext="Not visible to the facility. 30 characters max."
              validate={validateName}
            />
            <Field name="template" validate={validateMessage}>
              {({ field, form }) => (
                <TemplateFormField
                  field={field}
                  form={form}
                  values={values}
                  label="Message"
                  placeholder="Enter template message..."
                  interpolate={false}
                />
              )}
            </Field>
            <Button
              type="submit"
              className="btn-primary"
              disabled={!!errors.name || errors.template || isSubmitting}
            >
              Save
            </Button>
          </>
        )}
      </Form>
    </>
  )
}

export default MessageTemplateForm
