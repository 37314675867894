import React, { ReactNode } from "react"
import { ExternalLink } from "sharedTypes"

const renderLink = (link: ExternalLink, index: number): ReactNode => {
  return (
    <span key={`${link.text}-${index}`}>
      {index > 0 && ", "}
      {link.url ? (
        <a href={link.url} target="_blank" rel="nofollow noreferrer">
          {link.text}
        </a>
      ) : (
        link.text
      )}
    </span>
  )
}

type Props = {
  links?: ExternalLink[]
}

function ExternalLinks({ links }: Props) {
  if (!links || links.length === 0) {
    return null
  }
  return <span>{links.map(renderLink)}</span>
}

export default ExternalLinks
