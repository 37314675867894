import { del, get, post, put } from "services/api"
import {
  patientPortalMessageTemplatesUrl,
  patientPortalMessageTemplateUrl,
  supplierCommentTemplatesUrl,
  supplierCommentTemplateUrl,
} from "./urls"
import { Nullable } from "sharedTypes"

export const getPatientMessageTemplates = () =>
  get(`${patientPortalMessageTemplatesUrl()}.json`).then(({ data }) => data)

export const getSupplierCommentTemplates = () =>
  get(supplierCommentTemplatesUrl()).then(({ data }) => data)

export const fetchSupplierCommentTemplate = (templateId: Nullable<string>) =>
  get(supplierCommentTemplateUrl(templateId)).then(({ data }) => data)

export const createSupplierCommentTemplate = (params: {
  name: string
  template: string
}) => post(supplierCommentTemplatesUrl(), params).then(({ data }) => data)

export const updateSuppliercommentTemplate = (params: {
  templateId: string
  name: string
  template: string
}) =>
  put(supplierCommentTemplateUrl(params.templateId), params).then(
    ({ data }) => data
  )

export const deleteSupplierCommentTemplate = (templateId: string) =>
  del(supplierCommentTemplateUrl(templateId)).then(({ data }) => data)

export const createPatientPortalMessageTemplate = (messageTemplate) =>
  post(`${patientPortalMessageTemplatesUrl()}.json`, { messageTemplate })
    .then(({ data }) => data)
    .catch(({ response }) => Promise.reject(response.data))

export const updatePatientPortalMessageTemplate = (messageTemplateId) => (
  messageTemplate
) =>
  put(`${patientPortalMessageTemplateUrl(messageTemplateId)}.json`, {
    messageTemplate,
  })
    .then(({ data }) => data)
    .catch(({ response }) => Promise.reject(response.data))
