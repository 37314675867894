// @ts-strict-ignore
import React, { useState } from "react"
import { Form } from "components/form"
import {
  AddSupplierFormFields,
  Supplier,
  SupplierFormFields,
} from "./sharedTypes"
import FormButtons from "./FormButtons"
import SalesRepFields, { initialSalesRepFieldValues } from "./SalesRepFields"
import ServiceAreaWarning from "./ServiceAreaWarning"
import SupplierSelect from "./SupplierSelect"
import { stateAbbreviationToName } from "utilities/address"

interface Props {
  facilityState: string
  onCancel: () => void
  onRequestSupplier: () => void
  onSubmit: (values: SupplierFormFields) => void
  supplierSearch: (term: string) => Promise<Supplier[]>
}

const AddSupplierForm: React.FC<Props> = ({
  facilityState,
  onSubmit,
  onCancel,
  onRequestSupplier,
  supplierSearch,
}: Props) => {
  const [selectedSupplier, setSelectedSupplier] = useState<Supplier | null>(
    null
  )
  const initialValues: AddSupplierFormFields = {
    ...initialSalesRepFieldValues,
    supplierId: "",
  }
  const stateName: string = stateAbbreviationToName(facilityState) || ""

  const validate = ({ supplierId }: { supplierId: string }) => {
    const errors: { supplierId?: string } = {}
    if (!supplierId) {
      errors.supplierId = "Select a supplier"
    }
    if (selectedSupplier?.connected) {
      errors.supplierId = "You are already connected to this supplier"
    }
    return errors
  }

  const showServiceAreaWarning = (): boolean =>
    selectedSupplier && !selectedSupplier.inServiceArea

  return (
    <>
      <p>
        Can't find the supplier you're looking for?&nbsp;
        <a className="link" onClick={onRequestSupplier}>
          Request an outside supplier
        </a>
      </p>
      <Form
        initialValues={initialValues}
        validate={validate}
        onSubmit={onSubmit}
      >
        {({ isSubmitting, isValid }) => (
          <>
            <SupplierSelect
              onChange={(_: string, supplier?: Supplier) =>
                setSelectedSupplier(supplier)
              }
              stateName={stateName}
              supplierSearch={supplierSearch}
              unwrapped={showServiceAreaWarning()}
            />
            {showServiceAreaWarning() && (
              <ServiceAreaWarning stateName={stateName} />
            )}
            <SalesRepFields />
            <FormButtons
              onCancel={onCancel}
              isSubmitting={isSubmitting}
              isValid={isValid}
              submitText="Add Supplier"
            />
          </>
        )}
      </Form>
    </>
  )
}

export default AddSupplierForm
