import React from "react"
import classNames from "classnames"
import * as styles from "./index.module.scss"
import { CanopyButton } from "@parachutehealth/canopy-button"
import { CanopyIconNames } from "@parachutehealth/canopy-icons/build/canopyIconGlyphNames"
import { CanopyIcon } from "@parachutehealth/canopy-icon"

export type NoticeProps = {
  children: React.ReactNode
  type: "info" | "danger" | "warning" | "success" | "secondary"
  shadow?: boolean
  role?: string
  icon?: CanopyIconNames | false
  link?: { text: string } & React.HTMLProps<HTMLAnchorElement>
  onClose?: () => void
} & React.HTMLProps<HTMLDivElement>

const DEFAULT_ICON: Record<
  NoticeProps["type"],
  CanopyIconNames
> = Object.freeze({
  info: "circle-info",
  danger: "circle-exclamation",
  warning: "triangle-exclamation",
  success: "circle-check",
  secondary: "flag",
})

export const Notice = (props: NoticeProps): JSX.Element => {
  const {
    children,
    type,
    link,
    shadow = false,
    role = "alert",
    icon,
    onClose,
    ...divProps
  } = props

  const computedStyles: Record<string, boolean> = {
    [`${styles[type]}`]: true,
    [`${styles.shadow}`]: shadow,
  }

  const Icon = () => {
    // icons can be disabled entirely by passing a literal "false"
    if (icon === false) {
      return <></>
    }

    const determinedIcon = icon || DEFAULT_ICON[type]

    return (
      <div className={classNames(styles.noticeIcon)}>
        <CanopyIcon aria-hidden="true" size="medium" name={determinedIcon} />
      </div>
    )
  }

  const Body = (): JSX.Element => {
    return (
      <div className={styles.noticeBody}>
        <div className="canopy-typography-body-medium">{children}</div>
      </div>
    )
  }

  const Actions = () => {
    if (!onClose && !link) {
      return <></>
    }

    const Link = (): JSX.Element => {
      if (!link) {
        return <></>
      }

      const { className, text, ...otherProps } = link
      return (
        <a {...otherProps} className={classNames(styles.noticeLink, className)}>
          {text}
        </a>
      )
    }

    const Divider = (): JSX.Element => {
      return (
        <>
          {link && onClose && (
            <span className={styles.divider} role="presentation">
              &nbsp;
            </span>
          )}
        </>
      )
    }

    const Button = (): JSX.Element => {
      return (
        <>
          {onClose && (
            <CanopyButton
              className={styles.closeButton}
              onClick={onClose}
              size="small"
              variant="tertiary"
              iconStart="xmark"
              accessibleText="Close notice"
              title="Close notice"
            />
          )}
        </>
      )
    }

    return (
      <div className={styles.noticeActions}>
        <Link />
        <Divider />
        <Button />
      </div>
    )
  }

  return (
    <div
      role={role}
      {...divProps}
      className={classNames(styles.notice, computedStyles)}
    >
      <Icon />
      <Body />
      <Actions />
    </div>
  )
}
