import { Link } from "react-router-dom"
import * as styles from "./index.module.scss"
import classNames from "classnames"
import React, { ReactEventHandler } from "react"
import uuid from "uuid/v4"
import { CanopyIcon } from "@parachutehealth/canopy-icon"
import { Menu, MenuItem } from "@material-ui/core"

export type BreadcrumbItem = {
  /**
   * The visual name of the breadcrumb, either as a link or as static text
   */
  label: string
  /**
   * The intended URL of the link (for native browser navigation)
   */
  href?: string
  /**
   * The intended URL of the link for React Router DOM links.
   * This is only intended for use in components that manage their own routing!
   * If you want traditional navigation, use the `href` property instead!
   */
  to?: string
  /**
   * An optional title attribute to attach to the link.
   */
  title?: string
  /**
   * Additional operations to run when the breadcrumb is clicked.
   */
  onClick?: (e: ReactEventHandler<HTMLAnchorElement>) => void
  /**
   * Optional child elements.  If provided, the breadcrumb will ignore any URLs passed to it
   * and instead function as a dropdown menu.
   */
  submenuItems?: BreadcrumbSubItem[]
}

type BreadcrumbSubItem = Omit<BreadcrumbItem, "submenuItems">

export type BreadcrumbsProps = {
  /**
   * If true, no root icon will be shown even when there is only one breadcrumb.
   */
  disableIcon?: boolean
  children: BreadcrumbItem[]
} & React.HTMLProps<HTMLElement>

const determineComponent = (
  item: BreadcrumbItem | BreadcrumbSubItem
): typeof Link | "a" | "span" => {
  if (item.to) {
    // "to" is a special case that implies React-Router-Dom Link elements
    return Link
  } else if (item.href) {
    return "a"
  } else {
    // if no URL is provided, assume that we're on a terminal breadcrumb
    // and display as a link only
    return "span"
  }
}

/**
 * This component acts as a centralized way to invoke breadcrumbs within the CMS.
 * It supports both traditional and react-router-dom navigation (on a per-item basis).
 * If there is only one item (e.g., a "Back" or "All [Foo]" for simple workflows) it will include
 * an icon unless explicitly disabled via props.
 *
 * It is also possible for any individual breadcrumb entry to be a dropdown (by passing `submenuItems`), if your design requires it.
 * Note that this feature is experimental and subject to change.
 *
 * This component expects, but will not enforce, the convention that the final item in a list of breadcrumbs
 * is not itself a link (though it can be).  It _does_ expect that the terminal entry represents the current page,
 * and indicates as much to screen-readers via the `aria-current` attribute.
 */
const Breadcrumbs: React.FC<BreadcrumbsProps> = ({
  children,
  disableIcon = false,
  ...other
}): React.JSX.Element => {
  const Divider = () => (
    <li
      role="presentation"
      className={classNames(styles.divider)}
      aria-hidden="true"
    >
      /
    </li>
  )

  const maybeIcon = (): React.JSX.Element => {
    if (disableIcon === true || children.length > 1) return <></>

    return (
      <CanopyIcon
        className={classNames(styles.icon)}
        name="angle-left"
        size="small"
      />
    )
  }

  const itemToLink = (
    item: BreadcrumbItem,
    index: number
  ): React.JSX.Element => {
    const Component = determineComponent(item)
    const { label, ...rest } = item

    const isLast = children.length === index + 1
    const isFirst = index === 0
    const ariaCurrent = isLast && !isFirst ? "page" : undefined

    return (
      <>
        {/* TS complains about `to` being possibly undefined for Link, but we check for its presence elsewhere */}
        {/* @ts-ignore */}
        <Component {...rest} aria-current={ariaCurrent}>
          {isFirst && maybeIcon()}
          {label}
        </Component>
      </>
    )
  }

  const negotiateItemRendering = (
    item: BreadcrumbItem,
    index: number
  ): React.JSX.Element => {
    if (item.submenuItems) {
      return <Dropdown item={item} />
    } else {
      return itemToLink(item, index)
    }
  }

  const itemToMarkup = (
    item: BreadcrumbItem,
    index: number
  ): React.JSX.Element => {
    const isLast = children.length === index + 1

    return (
      <React.Fragment key={`breadcrumb-${index}`}>
        <li>{negotiateItemRendering(item, index)}</li>

        {!isLast && <Divider />}
      </React.Fragment>
    )
  }

  const childrenToMarkup = () => {
    return (
      <ol className={classNames(styles.breadcrumbList)}>
        {children.map(itemToMarkup)}
      </ol>
    )
  }

  return (
    <nav className="canopy-mb-8x" aria-label="Breadcrumb" {...other}>
      {childrenToMarkup()}
    </nav>
  )
}

/**
 * An internal-only component, used to render dropdowns from within a Breadcrumb trail.
 */
const Dropdown: React.FC<{ item: BreadcrumbItem }> = ({
  item,
}): React.JSX.Element => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const handleClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const itemToMenuItem = (item: BreadcrumbSubItem): React.JSX.Element => {
    return (
      <MenuItem
        // Typescript doesn't like the polymorphic component and the "to" attribute, so we're ignoring for now
        // @ts-ignore
        component={determineComponent(item)}
        disabled={determineComponent(item) === "span"}
        label={item.label}
        key={item.label}
        href={item.href}
        to={item.to}
      >
        {item.label}
      </MenuItem>
    )
  }

  const id = uuid()
  const generatedId = `${id}-anchor`
  const menuOpen = (): boolean => Boolean(anchorEl)

  // will attach to the button so we can programmatically get its width
  const buttonRef = React.useRef<HTMLAnchorElement>(null)

  return (
    <>
      <a
        role="button"
        href="#" // required for consistent styling
        ref={buttonRef}
        onClick={handleClick}
        aria-controls={`${id}-menu`}
        aria-haspopup="true"
        id={generatedId}
      >
        {item.label}

        <CanopyIcon
          className={classNames("canopy-mis-2x", styles.caret)}
          name="chevron-down"
          size="xSmall"
          fill="canopyColorTextDisabled"
        />
      </a>
      <Menu
        data-testid={`test-${id}`}
        id={`${id}-menu`}
        className={classNames(styles.menu)}
        MenuListProps={{
          // make the menu _at least_ as wide as the button itself
          style: { minWidth: buttonRef?.current?.offsetWidth || "150px" },
          "aria-labelledby": generatedId,
        }}
        anchorEl={anchorEl}
        keepMounted
        open={menuOpen()}
        onClose={handleClose}
        onClick={handleClose}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        {item.submenuItems?.map(itemToMenuItem)}
      </Menu>
    </>
  )
}

export default Breadcrumbs
