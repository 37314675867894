import React, { useState } from "react"
import { createCatalogAttribute } from "applications/Cms/api/attributes"
import { Form } from "components/form"
import { StaticFieldDefinition } from "../../../../../../types/form/sharedTypes"
import { CanopyButton } from "@parachutehealth/canopy-button"
import { usePolicies } from "../../../../../../contexts/PoliciesContext"
import { CanopyDialogModal } from "@parachutehealth/canopy-dialog-modal"
import FormSegment from "../../../../../../components/Formik/FormSegment"
import { generateInitialValues } from "../../../../../../utilities/form"
import { CatalogCustomAttribute } from "../../../../../../types/sharedTypes"

export type Props = {
  open?: boolean
  onSuccess?: (attribute: CatalogCustomAttribute) => void
}

const staticFields: StaticFieldDefinition[] = [
  {
    label: "Custom Attribute Name",
    name: "name",
    required: true,
    type: "text",
  },
  { label: "Custom Attribute Caption", name: "selectionCaption", type: "text" },
]

const AddAttributeForm: React.FC<Props> = (props: Props): JSX.Element => {
  const { open, onSuccess } = props
  const [dialogOpen, setDialogOpen] = useState<boolean>(!!open)

  const { hasPermission } = usePolicies()

  const handleClickOpen = () => {
    setDialogOpen(true)
  }

  const handleClose = () => {
    setDialogOpen(false)
  }

  const handleSubmit = (params, { setSubmitting, setErrors }) => {
    createCatalogAttribute(params.selectionCaption, params.name).then(
      (response) => {
        if (!response.errors) {
          handleClose()
          if (onSuccess) onSuccess(response.attribute)
        } else {
          setSubmitting(false)
          setErrors(response.errors)
        }
      }
    )
  }

  // we might check this in the parent component two, but accessing the context is cheap and easy, so
  // so this component can make sure that it's never shown when it shouldn't be
  if (!hasPermission("Catalog::CustomAttribute", "create")) {
    return <></>
  }

  return (
    <div>
      <Form
        initialValues={generateInitialValues(staticFields, {})}
        onSubmit={handleSubmit}
      >
        {({ isSubmitting, handleSubmit }) => (
          <>
            <CanopyButton
              size="small"
              onClick={handleClickOpen}
              variant="primary"
            >
              Add Attribute
            </CanopyButton>
            <CanopyDialogModal
              header="Create a new Custom Attribute"
              onClose={handleClose}
              open={dialogOpen}
              size="medium"
              primaryFooterButton={
                <CanopyButton
                  variant="primary"
                  onClick={handleSubmit}
                  type="submit"
                  loading={isSubmitting}
                >
                  Save
                </CanopyButton>
              }
              secondaryFooterButton={
                <CanopyButton
                  disabled={isSubmitting}
                  variant="tertiary"
                  onClick={handleClose}
                >
                  Cancel
                </CanopyButton>
              }
            >
              <FormSegment>{staticFields}</FormSegment>
            </CanopyDialogModal>
          </>
        )}
      </Form>
    </div>
  )
}

export default AddAttributeForm
