import React, { useState } from "react"
import Modal from "components/Modal"
import { FailureReason, Upload } from "applications/DocumentationOrders/api"
import Icon from "components/Icon"
import UploadErrors from "applications/Uploads/UploadsTable/UploadsSummaryModal/UploadErrors"

interface Props {
  record: Upload
  resultsUrl: string
}

function UploadsSummaryModal({
  record,
  resultsUrl,
}: Props): React.ReactElement {
  const [showModal, setShowModal] = useState(false)

  const openModal = () => {
    setShowModal(true)
  }

  const closeModal = () => {
    setShowModal(false)
  }

  return (
    <>
      {/* Our standard Button component is not yet built out to support buttons styled
    as links. Furthermore, this button component is rendered alongside an Icon
    component and within a TableCell component. Given this context, it was
    easier to use a plain button element with some pre-defined classes
 */}
      <button className="btn-link color-brand" onClick={openModal}>
        View Results
      </button>
      <Modal
        title="Upload Summary"
        show={showModal}
        cancel={closeModal}
        size="md"
      >
        <Modal.Body>
          <div className="canopy-pis-8x">
            <div>
              {record.numberOfSuccessfulRows &&
              record.numberOfSuccessfulRows > 0 ? (
                <div>
                  <Icon
                    type="check"
                    className="color-check-success canopy-p-4x"
                  />
                  <p className="d-inline-block canopy-mbe-0">
                    {record.numberOfSuccessfulRows} Requests Created
                  </p>
                </div>
              ) : null}
            </div>
            <div>
              {record.numberOfRowsWithErrors &&
              record.numberOfRowsWithErrors > 0 ? (
                <UploadErrors
                  numberOfRowsWithErrors={record.numberOfRowsWithErrors}
                  failedUploadResults={record.failedUploadResults}
                />
              ) : null}
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="text-left">
          {record.numberOfRowsWithErrors &&
          record.numberOfRowsWithErrors > 0 &&
          record.failedUploadResults &&
          record.failedUploadResults.filter(
            (result) => result.failureReason !== FailureReason.DUPLICATE
          ).length > 0 ? (
            <div style={{ marginRight: "10px", display: "inline" }}>
              <a
                className="btn btn-brand"
                type="button"
                href={resultsUrl + "?only_error_rows=true"}
              >
                Download Failed Rows
              </a>
            </div>
          ) : null}
          <a className="btn btn-ghost" type="button" href={resultsUrl}>
            Download Report
          </a>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default UploadsSummaryModal
