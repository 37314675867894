import React, { useState } from "react"
import { DocumentationOrder } from "../fetchOrders"
import Modal from "../../../components/Modal"
import { cancelDocumenationOrder } from "./api"
import Tooltip from "components/Tooltip"
import * as styles from "./index.module.scss"

const OrderCancellationContext = React.createContext({
  // eslint-disable-next-line unused-imports/no-unused-vars
  onOrderCancelled: (id: string) => {},
})

export const OrderCancellationProvider = ({
  onOrderCancelled,
  children,
}: {
  onOrderCancelled: (string) => void
  children: React.ReactNode
}) => {
  return (
    <OrderCancellationContext.Provider value={{ onOrderCancelled }}>
      {children}
    </OrderCancellationContext.Provider>
  )
}

const CancelButton = ({ order }: { order: DocumentationOrder }) => {
  const [showModal, setShowModal] = useState(false)
  const { onOrderCancelled } = React.useContext(OrderCancellationContext)

  const cancelable = !order.signatureResolution

  const openModal = (e) => {
    e.preventDefault()
    if (!cancelable) return
    setShowModal(true)
  }

  const cancelDocumentationOrder = async () => {
    await cancelDocumenationOrder(order.id)
    setShowModal(false)
    onOrderCancelled(order.id)
  }

  const tooltipText =
    order.signatureResolution?.type &&
    `A doctor ${order.signatureResolution.type} this order and it can no longer be canceled`

  return (
    <>
      <a
        href=""
        onClick={openModal}
        className={cancelable ? styles.active : styles.disabled}
      >
        <Tooltip
          trigger={["click", "hover"]}
          overlay={tooltipText}
          placement={Tooltip.Placement.Left}
          width={Tooltip.Width.Wide}
          disabled={cancelable}
        >
          Cancel
        </Tooltip>
      </a>
      <Modal
        show={showModal}
        cancel={() => setShowModal(false)}
        verticalCenter
        title="Cancel Signature Request?"
        size="md"
      >
        <Modal.Body>
          The signature request will be removed from your and the doctor's
          signature dashboard and cannot be viewed or signed by the doctor.
        </Modal.Body>
        <Modal.Footer>
          <a
            className="btn btn-link bold color-dark-gray"
            onClick={() => setShowModal(false)}
          >
            Go Back
          </a>
          <a
            className="btn btn-danger"
            type="button"
            onClick={cancelDocumentationOrder}
          >
            Cancel Signature Request
          </a>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default CancelButton
