import axios from "axios"
import { meta } from "utilities/meta"

export function get(url, options = {}) {
  return axios({
    method: "get",
    url,
    ...options,
  })
}

export function post(url, options = {}) {
  return axios({
    method: "post",
    url,
    ...options,
  })
}

export function put(url, options = {}) {
  return axios({
    method: "put",
    url,
    ...options,
  })
}

export function patch() {
  throw new Error("PATCH is not a supported HTTP method")
}

export function headers() {
  return {
    "X-CSRF-Token": meta("csrf-token"),
  }
}
