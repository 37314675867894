import React, { useState } from "react"
import emailImage from "email_icon.png"
import smsImage from "sms_icon.png"
import classNames from "classnames"
import { DigitalIdentification } from "../sharedTypes"

type Props = {
  digitalIdentification: DigitalIdentification
  confirm(identityVerificationId: string, token: string): Promise<void>
  reject(identityVerificationId: string): Promise<void>
}

const DoctorDigitalIdentificationForm = ({
  digitalIdentification,
  confirm,
  reject,
}: Props) => {
  const {
    doctorCredential,
    doctorName,
    doctorNpi,
    identityType,
  } = digitalIdentification
  const [iconUrl, header] =
    identityType === "email"
      ? [emailImage, "Confirm my email"]
      : [smsImage, "Confirm my SMS"]
  const [submitting, setSubmitting] = useState(false)
  const handleConfirm = () => {
    setSubmitting(true)
    confirm(
      digitalIdentification.identityVerificationId,
      digitalIdentification.token
    )
  }

  const handleReject = () => {
    setSubmitting(true)
    reject(digitalIdentification.identityVerificationId)
  }

  return (
    <div className="container canopy-mbs-2x">
      <div className="row">
        <div className="col-md-6 offset-md-3">
          <div className="text-center canopy-mbe-8x">
            <img src={iconUrl} className="image with-header" />
            <h1 className="canopy-mis-2x d-inline-block"> {header}</h1>
          </div>
          <p className="canopy-my-12x">
            By clicking below, I am certifying that I am {doctorName},{" "}
            {doctorCredential} NPI {doctorNpi}.
          </p>
          <button
            className="btn btn-brand btn-block"
            disabled={submitting}
            onClick={handleConfirm}
          >
            I am {doctorName}
          </button>
          <div className="text-center canopy-mbs-8x">
            <a
              onClick={handleReject}
              className={classNames("link", {
                disabled: submitting,
              })}
              data-confirm="Are you sure?"
            >
              I am not {doctorName}
            </a>
          </div>
          <p className="text-center canopy-mbs-8x font-notice">
            Unauthorized access of this information can result in state or
            federal prosecution.
          </p>
        </div>
      </div>
    </div>
  )
}

export default DoctorDigitalIdentificationForm
