import React from "react"
import { format as formatDate } from "../../../../utilities/date"

export type TimestampProps = {
  /**
   * The underlying time, which may be either a real Date object or an ISO time string
   */
  time: Date | string
  /**
   * The display format (i.e., what's shown to the user) for the datetime.
   */
  format?: string
}

/**
 * A simple wrapper around the HTML <time> element.
 * This allows passing in either a real ECMAscript Date object
 * or strings in ISO format.
 */
const Timestamp: React.FC<TimestampProps> = ({
  time,
  format = "MM/DD/YYYY [at] h:mmA",
}) => {
  let timestampString: string
  if (typeof time === "object" && time["toISOString"]) {
    timestampString = time.toISOString()
  } else {
    timestampString = time?.toString()
  }

  // n.b. the <time> element has an implicit "time" role but old Jest doesn't see it unless it's added explicitly
  return (
    <time role="time" dateTime={timestampString}>
      {formatDate(timestampString, format)}
    </time>
  )
}

export default Timestamp
