import { gql, TypedDocumentNode } from "@apollo/client"
import {
  SupplierInsuranceContractUpdateMutation,
  SupplierInsuranceContractUpdateMutationVariables,
} from "../../../graphql/__generated__/graphql"

export const SupplierInsuranceContractUpdate: TypedDocumentNode<
  SupplierInsuranceContractUpdateMutation,
  SupplierInsuranceContractUpdateMutationVariables
> = gql`
  mutation SupplierInsuranceContractUpdate(
    $insuranceProductId: ID!
    $insuranceContractId: ID
  ) {
    supplierInsuranceContractUpdate(
      input: {
        insuranceProductId: $insuranceProductId
        insuranceContractId: $insuranceContractId
      }
    ) {
      action
    }
  }
`
