// @ts-strict-ignore
import React from "react"
import AssigneePopover from "./Popover"
import { Assignee } from "sharedTypes"

type Props = {
  id: string
  assignee: Assignee
  canAssign?: boolean
  updateAssignee(id: string, { assigneeId }): Promise<Assignee>
  deleteAssignee(id: string): Promise<Assignee>
  fetchAssignees?: (term: string) => Promise<Assignee[]>
}

const AssignedToEditor = ({
  id,
  assignee,
  canAssign,
  updateAssignee,
  deleteAssignee,
  fetchAssignees,
}: Props) => {
  const setAssignee = ({ assigneeId }): Promise<Assignee> => {
    if (assigneeId) {
      return updateAssignee(id, { assigneeId })
    } else {
      return deleteAssignee(id).then(() => {
        return null
      })
    }
  }

  if (!canAssign) {
    return <>{assignee?.name || "Unassigned"}</>
  }

  return (
    <AssigneePopover
      assignee={assignee}
      setAssignee={setAssignee}
      fetchAssignees={fetchAssignees}
    />
  )
}

export default AssignedToEditor
