import { del, post, put } from "services/api"
import {
  createPinnedSupplierUrl,
  destroyPinnedSupplierUrl,
  fulfillmentAgreementUrl,
} from "./urls"
import { FulfillmentAgreement } from "./sharedTypes"

export const updateFulfillmentAgreement: (params: {
  active?: boolean
  id: string
}) => Promise<FulfillmentAgreement> = ({ id, ...params }) =>
  put(fulfillmentAgreementUrl(id), {
    fulfillmentAgreement: params,
  })
    .then(({ data }) => data)
    .catch(({ response }) => Promise.reject(response.data))

export const createPinnedSupplier = (supplierId: string) =>
  post(createPinnedSupplierUrl(), { supplierId })
    .then(({ data }) => data)
    .catch(({ response }) => Promise.reject(response.data))
export const destroyPinnedSupplier = (supplierId: string) =>
  del(destroyPinnedSupplierUrl(supplierId))
    .then(({ data }) => data)
    .catch(({ response }) => Promise.reject(response.data))
