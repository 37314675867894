import React from "react"
import * as styles from "./index.module.scss"
import { DocumentationOrderNotificationHistory } from "./api"

type Props = {
  orderNotificationHistories: DocumentationOrderNotificationHistory[]
}

const NotificationHistory = ({ orderNotificationHistories }: Props) => {
  return (
    <>
      <div className={styles.notificationHistoryTable}>
        {orderNotificationHistories.map((history) => {
          return (
            <div key={history.sentAt} className={styles.notificationHistoryRow}>
              <div className={styles.notificationHistoryDate}>
                {history.sentAt}
              </div>
              <div className={styles.notificationHistoryMethod}>
                {`${history.type} ${history.channel} ${history.destination}`}
              </div>
            </div>
          )
        })}
      </div>
    </>
  )
}

export default NotificationHistory
