import React, { ReactNode } from "react"
import DraggableTableRow from "applications/Cms/components/DraggableTableRow"
import { TableCell, TableRow } from "@material-ui/core"
import { SvgIconComponent } from "@material-ui/icons"

type Props<T> = {
  row: T
  index: number
  children: ReactNode
  rearrangeable?: boolean
  icon: SvgIconComponent
  onMove: (fromIndex: number, toIndex: number) => void
  onDrop: () => void
}

const RowContainer: React.FC<Props<any>> = (props: Props<any>): JSX.Element => {
  const { row, index, children, rearrangeable, icon, onMove, onDrop } = props
  if (rearrangeable) {
    return (
      <DraggableTableRow
        key={row.id}
        id={row.id.toString()}
        index={index}
        onMove={onMove}
        onDrop={onDrop}
        icon={icon}
      >
        {children}
      </DraggableTableRow>
    )
  } else {
    return (
      <TableRow key={row.id} id={row.id.toString()}>
        <TableCell>
          {/* account for the drag handle cell of draggable rows */}
        </TableCell>
        {children}
      </TableRow>
    )
  }
}

export default RowContainer
