import { Route, RouterChildContext, Switch, useHistory } from "react-router-dom"
import React, { useContext } from "react"
import { NoticeContext, withNoticeProvider } from "../../contexts/NoticeContext"
import { withBrowserRouter } from "../../../../routers/BrowserRouter"
import { withMaterialTheme } from "../../../../themes/theme"
import AttributesPage from "./components/AttributesPage"
import AttributeDetailsPage from "./components/AttributeDetailsPage"
import { Policies, PoliciesProvider } from "../../contexts/PoliciesContext"

type Props = {
  policies: Policies
}

const AttributesWorkflow: React.FC<Props> = (props: Props) => {
  const history: RouterChildContext["router"]["history"] = useHistory()
  const { clearNotice } = useContext(NoticeContext)

  React.useEffect(() => {
    clearNotice()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history.location.pathname])

  return (
    <PoliciesProvider policies={props.policies}>
      <Switch>
        <Route path="/cms/catalog/attributes" exact>
          <AttributesPage />
        </Route>
        <Route path="/cms/catalog/attributes/:attributeId" exact>
          <AttributeDetailsPage />
        </Route>
      </Switch>
    </PoliciesProvider>
  )
}

export default withBrowserRouter(
  withNoticeProvider(withMaterialTheme(AttributesWorkflow)),
  {}
)
