import React from "react"
import { Patient } from "../../../sharedTypes"

interface Props {
  patient: Patient
}

const PatientData = ({ patient }: Props) => {
  const { firstName, lastName, sex, dateOfBirth } = patient
  return (
    <div>
      <div className="canopy-typography-heading-large">New order for</div>
      <span className="canopy-typography-heading-large">
        {firstName} {lastName}
      </span>{" "}
      {sex} &#x2022; {dateOfBirth}
    </div>
  )
}

export default PatientData
