import compact from "lodash/compact"
import { RejectFormValues, SendBackFormValues } from "./sharedTypes"

type RecipientError = {
  destination: string
}

type Errors = {
  recipients?: (RecipientError | undefined)[]
  reasons?: string
}

export const validateSendBack = (
  values: RejectFormValues | SendBackFormValues
) => {
  const errors: Errors = {}

  if (compact(values.reasons || []).length === 0) {
    errors.reasons = "is required"
  }

  const recipientsErrors = (values.recipients || []).map((r) => {
    if (r.destination) {
      return
    }
    return { destination: "is required" }
  })

  if (compact(recipientsErrors).length > 0) {
    errors.recipients = recipientsErrors
  }

  return errors
}
