import { employerPrefix, userPrefix } from "utilities/url"

export function newOrderUrl() {
  return `${employerPrefix()}/orders/new`
}

export function clinicalFacilityDashboardResultsUrl() {
  return `${employerPrefix()}/dashboard/results.json`
}

export function clinicalFacilityFocusTileResultsUrl(focusTile?: string) {
  return `${employerPrefix()}/dashboard/results.json/?focus_tile=${focusTile}`
}

export function searchFollowersUrl() {
  return `${employerPrefix()}/dashboard/followers.json`
}

export function searchSuppliersUrl() {
  return `${employerPrefix()}/dashboard/suppliers.json`
}

export function searchDoctorsUrl() {
  return `${employerPrefix()}/dashboard/doctors.json`
}

export function updateUiPreferencesUrl() {
  return `${userPrefix()}/ui_preferences.json`
}

export function createAdditionalContactResponsesUrl() {
  return `${employerPrefix()}/additional_contact_responses`
}

export function incomingOrdersCountUrl() {
  return `${employerPrefix()}/dashboard/incoming_orders.json`
}

export function allFacilitiesIncomingOrdersCountUrl() {
  return `${employerPrefix()}/dashboard/all_facilities_incoming_orders.json`
}

export function sentBackForReviewCountUrl() {
  return `${employerPrefix()}/dashboard/sent_back_for_review.json`
}

export function ordersWithUnreadEventsCountUrl() {
  return `${employerPrefix()}/dashboard/orders_with_unread_events.json`
}
