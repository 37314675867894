import React, { useEffect, useState } from "react"
import cx from "classnames"
import ConsignmentClosetsFilter from "../ConsignmentClosetsFilter"
import * as styles from "./index.module.scss"
import Checkbox from "../Checkbox"
import Switch from "components/input/Switch"
import Link from "components/Link"
import { fulfillmentAgreementsUrl } from "../../../../urls"
import { CanopyIcon } from "@parachutehealth/canopy-icon"
import {
  ApplicationError,
  CatalogBrowseTab,
  ConsignmentCloset,
  Nullable,
} from "../../../../../../sharedTypes"
import CategorySelector from "../CategorySelector"
import { loadSearchSettings } from "../../../SupplierList/api"
import { handleError } from "../../../../../../utilities/error"
import { CategoryWithSubcategories } from "../../sharedTypes"
import ServiceAreaState from "./ServiceAreaState"

type Props = {
  availableCategories: CategoryWithSubcategories[]
  canFilterByFormulary: boolean
  canFilterByYourOrganizationsSuppliers: boolean
  categories: CategoryWithSubcategories[]
  combinedCatalogSearch: boolean
  consignmentClosetOnly: boolean
  consignmentClosets: ConsignmentCloset[]
  currentCatalogBrowseTab: CatalogBrowseTab
  defaultConsignmentClosetId: string
  formularyOnly: boolean
  manageFulfillmentAgreements: boolean
  selectCategory: (number: number, isSubcategory?: boolean) => void
  selectState(state: string): void
  selectDefaultConsignmentCloset: (id: string) => Promise<void>
  selectedCategory: Nullable<number>
  serviceAreaState: string
  subcategoryId?: Nullable<number>
  toggleConsignmentClosetFilter: () => void
  toggleFormularyFilter: () => void
  toggleYourOrganizationsSuppliersFilter: () => void
  usesEnterpriseFeatures: boolean
  yourOrganizationsSuppliersOnly: boolean
  isMobile: boolean
}

const Filters = ({
  availableCategories,
  canFilterByFormulary,
  canFilterByYourOrganizationsSuppliers,
  categories,
  consignmentClosetOnly,
  consignmentClosets,
  combinedCatalogSearch,
  currentCatalogBrowseTab,
  defaultConsignmentClosetId,
  formularyOnly,
  isMobile,
  manageFulfillmentAgreements,
  selectCategory,
  selectDefaultConsignmentCloset,
  selectState,
  selectedCategory,
  serviceAreaState,
  subcategoryId,
  toggleConsignmentClosetFilter,
  toggleFormularyFilter,
  toggleYourOrganizationsSuppliersFilter,
  usesEnterpriseFeatures,
  yourOrganizationsSuppliersOnly,
}: Props) => {
  const [defaultServiceAreaState, setDefaultServiceAreaState] = useState<
    string
  >("")

  const addDividerPriorToCategoriesSection =
    consignmentClosets.length > 0 || canFilterByFormulary
  const categoryFilterClasses = () => {
    const filterClasses = [styles.categoryFilter, "canopy-px-4x"]
    if (currentCatalogBrowseTab === CatalogBrowseTab.Supplier) {
      if (!combinedCatalogSearch && !canFilterByFormulary) {
        filterClasses.push(styles.searchBySupplier)
      } else {
        if (combinedCatalogSearch) {
          filterClasses.push(styles.combinedSearch)
        }
        if (canFilterByFormulary) {
          filterClasses.push(styles.consignmentAndFormularyFilter)
        }
      }
    } else if (addDividerPriorToCategoriesSection) {
      filterClasses.push(
        styles.searchByProduct,
        styles.consignmentAndFormularyFilter
      )
    }
    return filterClasses
  }

  useEffect(() => {
    const getDefaultServiceAreaState: () => void = async () => {
      try {
        const response = await loadSearchSettings()
        if (response) {
          setDefaultServiceAreaState(response.defaultServiceAreaStates[0])
        }
      } catch (error) {
        handleError(error as ApplicationError)
      }
    }
    if (currentCatalogBrowseTab === CatalogBrowseTab.Product) {
      getDefaultServiceAreaState()
    }
  }, [currentCatalogBrowseTab])

  const filters = (
    <>
      <ConsignmentClosetsFilter
        checked={consignmentClosetOnly}
        toggle={toggleConsignmentClosetFilter}
        defaultClosetId={defaultConsignmentClosetId}
        closets={consignmentClosets}
        selectDefaultCloset={selectDefaultConsignmentCloset}
      />
      {canFilterByFormulary && (
        <>
          <h3
            className={cx(
              styles.filterHeading,
              "canopy-mx-4x",
              "canopy-mbs-8x"
            )}
          >
            Formulary
          </h3>
          <Checkbox
            checked={formularyOnly}
            enabled={true}
            toggle={toggleFormularyFilter}
            name="Formulary"
          />
        </>
      )}
      {addDividerPriorToCategoriesSection && (
        <hr className="canopy-mbe-8x canopy-mbs-8x"></hr>
      )}
      {canFilterByYourOrganizationsSuppliers && (
        <div className={styles.yourOrganizationsSuppliersFilter}>
          <h3 className={styles.filterHeading}>Suppliers</h3>
          <div className={styles.yourOrganizationsSuppliersToggleRow}>
            <Switch
              id="yourOrganizationsSuppliers"
              className="yourOrganizationsSuppliers"
              onChange={toggleYourOrganizationsSuppliersFilter}
              height={24}
              width={48}
              dark
              checked={yourOrganizationsSuppliersOnly}
              checkedIcon={
                <span className={cx(styles.switchLabel, styles.on)}>On</span>
              }
              uncheckedIcon={
                <span className={cx(styles.switchLabel, styles.off)}>Off</span>
              }
            />
            <label
              htmlFor="yourOrganizationsSuppliers"
              className={styles.yourOrganizationsSuppliersLabel}
            >
              Your Organization's Suppliers Only
            </label>
          </div>
          {manageFulfillmentAgreements && (
            <div className="canopy-mbe-8x">
              <Link href={fulfillmentAgreementsUrl()} target="_blank">
                <CanopyIcon className="canopy-mie-2x" name="gear" />
                Manage Suppliers
              </Link>
            </div>
          )}
          <hr className="canopy-mbs-0 canopy-mbe-4x"></hr>
        </div>
      )}
      {currentCatalogBrowseTab === CatalogBrowseTab.Product && (
        <ServiceAreaState
          setServiceAreaState={selectState}
          defaultState={defaultServiceAreaState}
          serviceAreaState={serviceAreaState}
          isMobile={isMobile}
        />
      )}

      <CategorySelector
        availableCategories={availableCategories}
        categories={categories}
        selectedValue={selectedCategory}
        selectedSubcategory={subcategoryId}
        select={selectCategory}
        usesEnterpriseFeatures={usesEnterpriseFeatures}
      />
    </>
  )

  if (isMobile) {
    return filters
  }

  return <div className={cx(categoryFilterClasses())}>{filters}</div>
}

export default Filters
