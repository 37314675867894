import { gql } from "@apollo/client"
import { PageInfoFieldsFragment } from "../../../../../graphql/fragments/fragments"
import {
  GetPayorDmeOrdersQuery,
  GetPayorDmeOrdersQueryVariables,
} from "../../../../../graphql/__generated__/graphql"
import { TypedDocumentNode } from "@graphql-typed-document-node/core"

export const GetPayorDmeOrders: TypedDocumentNode<
  GetPayorDmeOrdersQuery,
  GetPayorDmeOrdersQueryVariables
> = gql`
  ${PageInfoFieldsFragment}
  query GetPayorDmeOrders(
    $memberId: String!
    $after: String
    $before: String
    $first: Int
    $last: Int
  ) {
    payorDmeOrders(
      memberId: $memberId
      after: $after
      before: $before
      first: $first
      last: $last
    ) {
      totalCount
      pageInfo {
        ...PageInfoFields
      }
      nodes {
        id
        orderStatus
        signatureStatus
        missingDocuments
        clinicalFacility {
          name
        }
        creator {
          firstName
          lastName
        }
        patient {
          id
          firstName
          lastName
          dateOfBirth
        }
        deliveryFulfillments {
          id
          requestedDeliveryDate
          deliveryStatus
          deliveryAddress {
            line1
            line2
            city
            state
            zip
          }
        }
        insurancePolicies {
          id
          memberNumber
          verifiedMemberId
          rank
        }
        lineItemGroups {
          id
          packageName
        }
        supplierLegalEntity {
          id
          name
          line1
          line2
          city
          state
          zip
          phoneNumber
        }
        orderDate
        orderingDoctor {
          name
        }
        originatorType
      }
    }
  }
`
