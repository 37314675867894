import React from "react"
import { FormData } from "./components/FulfillmentForm"
import InternalFulfillmentEditor from "./components/InternalFulfillmentEditor"
import * as api from "./api"
import { formatDeliveryStatuses } from "utilities/deliveryStatus"
import StatusWithUpdateModal from "components/StatusWithUpdateModal"

type Props = {
  dmeOrderId: string
  initialStatusText: string
  employerPrefix?: string
}

const FulfillmentEditor = ({
  dmeOrderId,
  initialStatusText,
  employerPrefix,
}: Props) => {
  const updateFulfillment = (fulfillmentId: string, values: FormData) => {
    return api.updateFulfillment(
      dmeOrderId,
      fulfillmentId,
      values,
      employerPrefix
    )
  }

  return (
    <StatusWithUpdateModal
      dmeOrderId={dmeOrderId}
      initialStatus={initialStatusText}
      defaultStatus="Processing"
      editable
      onUpdate={updateFulfillment}
      formatStatus={(dmeOrder) => formatDeliveryStatuses(dmeOrder.fulfillments)}
      title="Fulfillment Status"
      employerPrefix={employerPrefix}
      renderChild={(dmeOrder, onUpdate) => {
        return (
          <InternalFulfillmentEditor
            dmeOrder={dmeOrder}
            updateFulfillment={onUpdate}
          />
        )
      }}
    />
  )
}
export default FulfillmentEditor
