import React from "react"
import { highlight } from "utilities/select"
import { ClinicalFacility } from "../sharedTypes"
import Suggestion, {
  SuggestionIndicator,
} from "components/form/Select/Suggestion"

type Props = {
  inNetwork: boolean
  facility: ClinicalFacility
  query: string
}

const ClinicalFacilitySuggestion = ({ facility, query, inNetwork }: Props) => {
  return (
    <Suggestion className="d-flex" key={facility.externalId}>
      <SuggestionIndicator selected={inNetwork} />
      <div style={{ width: "100%" }}>
        {highlight(`${facility.name} - ${facility.npi}`, query)}
        {facility.address && (
          <div className="float-right color-dark-gray">{facility.address}</div>
        )}
        {inNetwork && (
          <>
            <br />
            <small>Already added to network</small>
          </>
        )}
      </div>
    </Suggestion>
  )
}

export default ClinicalFacilitySuggestion
