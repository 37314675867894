import React, { useState, useEffect } from "react"
import { CanopyDialogModal } from "@parachutehealth/canopy-dialog-modal"
import { CanopyButton } from "@parachutehealth/canopy-button"
import { CanopyForm } from "@parachutehealth/canopy-form"
import { FieldArray, Formik } from "formik"
import Link from "components/Link"
import { canopySpace12X } from "@parachutehealth/canopy-tokens-space"
import { FaxFieldRow } from "./FaxFieldRow/FaxFieldRow"
import { createAdditionalContactResponses } from "../../api"
import { isPhoneValid } from "utilities/phone"
import { EventCategory, trackEvent } from "utilities/tracking"

type Props = {
  facilityName: string
}

const AdditionalFaxModal: React.FC<Props> = ({ facilityName }) => {
  const [open, setOpen] = useState(true)
  const onClose = () => {
    setOpen(false)
    createAdditionalContactResponses({ contacts: [{ number: "", name: "" }] })
    trackEvent(EventCategory.FacilityFaxInput, "exit_no_data")
  }

  const submitForm = (values): void => {
    createAdditionalContactResponses(values).then(() => {
      setOpen(false)
      trackEvent(EventCategory.FacilityFaxInput, "successful_submission")
    })
  }

  useEffect(() => {
    open && trackEvent(EventCategory.FacilityFaxInput, "prompt_displayed")
  }, [open])

  const validate = (values) => {
    const errors = {}

    for (let i = 0; i < values.contacts.length; i++) {
      const current_contact = values.contacts[i]

      if (
        current_contact.number.length === 0 &&
        current_contact.name.length === 0 &&
        i !== 0
      ) {
        continue
      }
      if (
        current_contact.number.length === 0 &&
        current_contact.name.length !== 0
      ) {
        errors[`contacts.${i}.number`] = "fax number is required"
        trackEvent(EventCategory.FacilityFaxInput, "validation_error")
      } else {
        if (!isPhoneValid(current_contact.number)) {
          errors[`contacts.${i}.number`] = "fax number format is invalid"
          trackEvent(EventCategory.FacilityFaxInput, "validation_error")
        }
      }
    }
    return errors
  }
  return (
    <Formik
      onSubmit={(values) => submitForm(values)}
      initialValues={{ contacts: [{ name: "", number: "" }] }}
      validate={validate}
      validateOnChange={false}
      validateOnBlur={false}
    >
      {({ handleSubmit, isSubmitting, errors, submitCount }) => {
        return (
          <CanopyDialogModal
            header="Help suppliers reach you on Parachute"
            onClose={onClose}
            open={open}
            primaryFooterButton={
              <CanopyButton
                type="submit"
                loading={isSubmitting}
                form="fax-prompt-form"
              >
                Submit
              </CanopyButton>
            }
            size="medium"
          >
            <p style={{ marginBottom: canopySpace12X }}>
              Add the fax number that suppliers use to send orders and documents
              to {facilityName} so they can find you on Parachute.
            </p>
            <CanopyForm onSubmit={handleSubmit} id="fax-prompt-form">
              <FieldArray
                name="contacts"
                render={({ form, remove, push }) => {
                  return (
                    <div>
                      {form.values.contacts.map((_r, index) => (
                        <FaxFieldRow
                          rowIndex={index}
                          removeFaxField={() => remove(index)}
                          removable={index > 0}
                          key={index}
                          rowErrors={errors}
                          submitCount={submitCount}
                        />
                      ))}
                      <Link
                        onClick={() => {
                          push({ number: "", name: "" })
                        }}
                      >
                        + Add another fax number
                      </Link>
                    </div>
                  )
                }}
              />
            </CanopyForm>
          </CanopyDialogModal>
        )
      }}
    </Formik>
  )
}

export default AdditionalFaxModal
