import { useState, useCallback } from "react"
import { syncIcd10Codes } from "../api"
import { Diagnosis, Icd10Code } from "sharedTypes"
import { handleError } from "utilities/error"
import throttledPromise from "utilities/throttledPromise"

const THROTTLE_IN_MS = 1000

export interface DiagnosisHook {
  diagnosis: Diagnosis
  setDiagnosis(diagnosis: Diagnosis): void
  addIcd10Code(icd10Code: Icd10Code): void
  removeIcd10Code(icd10Code: Icd10Code): void
}

export const useDiagnosis = (
  initialDiagnosis: Diagnosis,
  refreshDmeOrder: () => Promise<void>
): DiagnosisHook => {
  const [diagnosis, setDiagnosis] = useState<Diagnosis>(initialDiagnosis)

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const sync = useCallback(
    (function () {
      const throttledFunction = throttledPromise((icd10Codes) => {
        return syncIcd10Codes(icd10Codes)
          .then((diagnosis) => {
            if (!throttledFunction.next) {
              setDiagnosis(diagnosis)
              return refreshDmeOrder()
            }
          })
          .catch(handleError)
      }, THROTTLE_IN_MS)
      return throttledFunction
    })(),
    []
  )

  const atDiagnosisLimit = diagnosis.icd10Codes.length >= diagnosis.limit

  const removeIcd10Code = (icd10Code: Icd10Code): void => {
    const icd10Codes = diagnosis.icd10Codes.filter(
      ({ code }) => code !== icd10Code.code
    )
    setDiagnosis({ ...diagnosis, icd10Codes })
    sync(icd10Codes)
  }

  const addIcd10Code = (icd10Code: Icd10Code): void => {
    if (atDiagnosisLimit) {
      return
    }
    const icd10Codes = [...diagnosis.icd10Codes, icd10Code]
    setDiagnosis({ ...diagnosis, icd10Codes })
    sync(icd10Codes)
  }
  return {
    diagnosis,
    removeIcd10Code,
    addIcd10Code,
    setDiagnosis,
  }
}
