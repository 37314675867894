import { CategoryWithSubcategories, PackageSku } from "../../sharedTypes"
import {
  CatalogBrowseTab,
  CatalogSearchTab,
  CatalogSearchType,
  ConsignmentCloset,
  DmeOrder,
  Nullable,
} from "sharedTypes"
import React from "react"
import HybridSearch from "../HybridSearch"
import SingleSearch from "../SingleSearch"

interface Props {
  availableCategories: CategoryWithSubcategories[]
  canFilterByFormulary: boolean
  canFilterByYourOrganizationsSuppliers: boolean
  catalogSearchType: CatalogSearchType
  categories: CategoryWithSubcategories[]
  categoryId: number
  combinedCatalogSearch: boolean
  consignmentClosetOnly: boolean
  consignmentClosets: ConsignmentCloset[]
  currentCatalogBrowseTab: CatalogBrowseTab
  defaultConsignmentClosetId: string
  defaultServiceAreaState: string
  dmeOrder: DmeOrder
  filterQuery: string
  formularyOnly: boolean
  formularyPriceEnabled: boolean
  searchPackagesWithSku(query: string): Promise<{ data: PackageSku[] }>
  selectedCategory: Nullable<number>
  selectedTab: CatalogSearchTab
  selectDefaultConsignmentCloset(id: string): Promise<void>
  selectPackageWithSku(s: PackageSku): void
  selectCategory(n: number, isSubcategory?: boolean): void
  selectState: (state: string) => void
  serviceAreaState: string
  setSelectedTab(tab: CatalogSearchTab): void
  subcategoryId: number
  supplierIdParam: string
  toggleConsignmentClosetFilter(): void
  toggleFormularyFilter(): void
  toggleYourOrganizationsSuppliersFilter(): void
  updateFilterQuery(query: string): void
  yourOrganizationsSuppliersOnly: boolean
}

const ProductSearch: React.FC<Props> = (props) => {
  const { combinedCatalogSearch } = props

  if (combinedCatalogSearch) {
    return <HybridSearch {...props} />
  } else {
    return <SingleSearch {...props} />
  }
}

export default ProductSearch
