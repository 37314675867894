import React from "react"
import { RouteParams, RoutePattern } from "./routes"
import { Route } from "react-router-dom"
import { withBrowserRouter } from "routers/BrowserRouter"
import SupplierOrganizationDashboard from "applications/SupplierOrganizationDashboard"

const DashboardTabs: React.FC<React.ComponentProps<
  typeof SupplierOrganizationDashboard
>> = (props) => (
  <Route
    path={RoutePattern}
    render={({ match }) => {
      const activeTab = match.params[RouteParams.Tab] || "dashboard"
      return (
        <SupplierOrganizationDashboard
          {...{ activeTab: activeTab, tabView: true, ...props }}
        />
      )
    }}
  />
)

export default withBrowserRouter(DashboardTabs, {})
