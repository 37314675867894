import React from "react"
import { Tooltip } from "@material-ui/core"
import { CanopyTextInputField } from "@parachutehealth/canopy-text-input-field"

export type SearchFieldProps = {
  onSearch: (searchText: string) => void
  dirtyTooltip?: boolean
  defaultValue?: string
  disabled?: boolean
  label?: string
  placeholder?: string
  hiddenLabel?: boolean
  autoFocus?: boolean
}

const SearchField: React.FC<SearchFieldProps> = (props: SearchFieldProps) => {
  const {
    dirtyTooltip,
    onSearch,
    defaultValue = "",
    label = "Search",
    ...rest
  } = props
  const [value, setValue] = React.useState<string>(defaultValue)
  const [dirty, setDirty] = React.useState<boolean>(false)

  const handleSearch = () => {
    onSearch(value)
    setDirty(false)
  }

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>): void => {
    if (event.key === "Enter") {
      handleSearch()
      event.preventDefault()
    }
  }

  const handleChange = (event): void => {
    const newValue: string = event.target.value
    setValue(newValue)
    if (dirtyTooltip) {
      negotiateDirtyBit(newValue)
    }
  }

  const negotiateDirtyBit = (newValue: string) => {
    setDirty(newValue !== defaultValue)
  }

  return (
    <Tooltip open={dirty} arrow title="Press Enter to search">
      <CanopyTextInputField
        type="search"
        data-testid="search-keywords-filter"
        label={label}
        size="small"
        contentEnd="magnifying-glass"
        onKeyDown={handleKeyDown}
        onChange={handleChange}
        defaultValue={defaultValue}
        {...rest}
      />
    </Tooltip>
  )
}

export default SearchField
