import { Event } from "sharedTypes"
import {
  isComment,
  isInternalComment,
  isGlobalComment,
  isSupplierComment,
} from "utilities/event"

const allEvents = (event: Event) => event

export enum Label {
  AllActivity = "All Activity",
  AllComments = "All Comments",
  GlobalComments = "Global Comments",
  InternalComments = "Internal Comments",
  SupplierComments = "Supplier Comments",
}

export interface FilterOption {
  id?: number | string
  checked?: boolean
  label: Label
}

export const DEFAULT_FILTER_OPTION: FilterOption = {
  label: Label.AllActivity,
}

export const FILTERS_BY_LABEL = {
  [Label.AllActivity]: allEvents,
  [Label.AllComments]: isComment,
  [Label.GlobalComments]: isGlobalComment,
  [Label.InternalComments]: isInternalComment,
  [Label.SupplierComments]: isSupplierComment,
}
export const SUPPLIER_FILTERS = Object.keys(FILTERS_BY_LABEL)
export const FACILITY_FILTERS = [Label.AllActivity, Label.AllComments]
