import React from "react"
import { DoctorLoginCode } from "../../sharedTypes"
import { navigate } from "utilities/navigation"
import { handleError } from "utilities/error"
import { thankyouUrl } from "../../urls"
import DoctorIdentificationForm from "./DoctorIdentificationForm"

type Props = {
  accessCode: string
  securityCode: string
  doctorLoginCode: DoctorLoginCode
  login(props): Promise<void>
  reject(identityVerificationId: string): Promise<void>
}

const DoctorLogin = (props: Props) => {
  const { doctorLoginCode, securityCode } = props

  const handleDoctorLogin = () => {
    const { accessCode, login } = props
    return login({ accessCode, securityCode })
      .then(() => {
        navigate("/")
      })
      .catch(handleError)
  }

  const reject = () => {
    const identityVerificationId = doctorLoginCode.pendingIdentityVerificationId
    return props
      .reject(identityVerificationId)
      .then(() => navigate(thankyouUrl(identityVerificationId)))
      .catch(handleError)
  }

  if (securityCode && doctorLoginCode.verified) {
    handleDoctorLogin()
    return <></> // early return avoids showing id verification form to verified docs 🙂
  }

  return (
    <DoctorIdentificationForm
      onSubmit={handleDoctorLogin}
      reject={reject}
      doctorLoginCode={doctorLoginCode}
    />
  )
}

export default DoctorLogin
