import Alert from "components/Alert"
import React from "react"
import { Doctor } from "../../sharedTypes"
import ChangeInUserPreferencesLink from "./ChangeInUserPreferencesLink"

const OptInSuccessAlert = ({ doctor }: { doctor: Doctor }) => (
  <Alert status="success">
    <span>
      <strong>Thanks for opting in to digital notifications!</strong>
      {` You will now receive any digital signature requests related to
                Dr. ${doctor.name} directly to your email. `}
      <ChangeInUserPreferencesLink doctor={doctor} />
    </span>
  </Alert>
)

export default OptInSuccessAlert
