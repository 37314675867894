import React from "react"
import classNames from "classnames"

interface Props {
  status: "success" | "warning" | "danger"
  children: React.ReactNode
  className?: string
}

function Bulletin({ status, children, className }: Props) {
  return (
    <div
      className={classNames(
        "bulletin",
        status && `bulletin-${status}`,
        className
      )}
    >
      {children}
    </div>
  )
}

export default Bulletin
