import {
  build as buildEndpoint,
  FacilityNameData,
} from "services/patientPortal"
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query"

export const useFacilityNamesQuery = (facilityId: string) => {
  return useQuery({
    queryKey: ["facilityNames", facilityId],
    queryFn: () =>
      buildEndpoint()
        .fetchFacilityNames(facilityId)
        .then(({ data }) => data),
  })
}

export const useUpdateFacilityNames = (facilityId: string) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: ({ facilityFullName, facilityShortName }: FacilityNameData) => {
      return buildEndpoint().updateFacilityNames(
        facilityId,
        facilityFullName,
        facilityShortName
      )
    },
    onSuccess: ({ data }) => {
      queryClient.setQueryData(["facilityNames", facilityId], data)
    },
  })
}
