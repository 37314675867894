import React, { useState } from "react"

type Props = {
  requiresConfirmation: boolean
  confirmPrefilledAnswers(): Promise<void>
}

const ConfirmButton: React.SFC<Props> = ({
  requiresConfirmation,
  confirmPrefilledAnswers,
}) => {
  const [confirming, setConfirming] = useState(false)

  if (!requiresConfirmation) {
    return null
  }

  const confirm = () => {
    setConfirming(true)
    confirmPrefilledAnswers().then(() => {
      setConfirming(false)
    })
  }

  return (
    <button
      type="button"
      onClick={confirm}
      className="btn btn-brand"
      disabled={confirming}
    >
      {confirming ? "Confirming" : "Confirm"}
    </button>
  )
}

export default ConfirmButton
