import { get, post, put } from "services/api"
import {
  getUserEmailUrl,
  sendPushbackInviteUrl,
  updateUserEmailUrl,
} from "applications/Workflow/urls"

export const getUserEmail = () => get(getUserEmailUrl())

export function updateEmail(email: string) {
  return put(updateUserEmailUrl(), { email })
    .then(({ data }) => data)
    .catch(({ response }) => Promise.reject(response.data))
}

export function sendPushbackInvitation() {
  return post(sendPushbackInviteUrl())
}
