import { put, post, del } from "services/api"
import {
  deliveryUrl,
  updateDeliveryUrl,
  updateDeliveryAddressUrl,
  createDeliveryUrl,
  updateLineItemGroupDeliveryAddressUrl,
  updateEmergencyContactUrl,
  deleteEmergencyContactUrl,
  patientContactUrl,
} from "applications/Workflow/urls"

export function updateDelivery(deliveryId, params) {
  return put(deliveryUrl(deliveryId), params)
}

export function updateDeliveryAddress(deliveryId, params) {
  return put(updateDeliveryAddressUrl(deliveryId), params)
}

export function createDelivery(params) {
  return post(createDeliveryUrl(), params)
}

export function updateLineItemGroupDeliveryAddress(lineItemGroupId, params) {
  return put(updateLineItemGroupDeliveryAddressUrl(lineItemGroupId), params)
}

export function updateEmergencyContact(params) {
  return put(updateEmergencyContactUrl(), params).then(({ data }) => data)
}

export function deleteEmergencyContact({ id }) {
  return del(deleteEmergencyContactUrl(id)).then(({ data }) => data)
}

export function updatePatientInfo(values) {
  return put(patientContactUrl(), values).then(({ data }) => data)
}

export function updateDeliveryData(params) {
  return put(updateDeliveryUrl(), params).then(({ data }) => data)
}
