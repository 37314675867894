import React from "react"
import { MaterialThemeProvider } from "themes/theme"
import InternalGlobalPatientSelect from "./components/InternalGlobalPatientSelect"

const GlobalPatientSelect = () => {
  return (
    <MaterialThemeProvider>
      <InternalGlobalPatientSelect />
    </MaterialThemeProvider>
  )
}

export default GlobalPatientSelect
