import React from "react"

interface Props {
  pinned: boolean
  onClick: () => void
}
const PinnedManagementCheckbox: React.FC<Props> = ({ pinned, onClick }) => {
  return <input type="checkbox" checked={pinned} onChange={onClick} />
}

export default PinnedManagementCheckbox
