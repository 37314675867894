import React from "react"
import { DmeOrderWarning, EmployerType, Employer } from "sharedTypes"

type Props = {
  warnings: DmeOrderWarning[]
  employer: Employer
}

function Attestations({ warnings, employer }: Props) {
  if (
    employer.employerType === EmployerType.ClinicalFacility &&
    warnings.find(({ key }) => key === "proof_of_delivery")
  ) {
    return (
      <p className="font-xs">
        I acknowledge my responsibility to provide a signed Proof of Delivery
        after dispensing.
      </p>
    )
  }

  return <></>
}

export default Attestations
