// @team @destroythefax
import React from "react"
import InternalClinicalFacilityDashboardSubpage from "../ClinicalFacilityDashboard/components/InternalClinicalFacilityDashboardSubpage"
import { ClinicalFacility, FeatureFlagInput } from "sharedTypes"
import {
  getClinicalFacilityDashboardNewMessagesFocusTileResults,
  updateSidebarExpandedPreference,
} from "../ClinicalFacilityDashboard/api"
import { FeatureFlagProvider } from "components/FeatureFlagContext"
import {
  SidebarListDoctor,
  SidebarListFacility,
} from "components/ClinicalDashboard/sharedTypes"

type Props = {
  clinicalFacility: ClinicalFacility
  limit: number
  unreadEventCounts: object
  featureFlags: FeatureFlagInput
  sidebarInformation: {
    incomingOrdersEnabled: boolean
    isReviewer: boolean
    lastAccessedFacilityEmployerId?: string
    doctors?: SidebarListDoctor[]
    facilities?: SidebarListFacility[]
    lastAccessedDoctorEmployerId?: string
  }
  sidebarExpanded: boolean
}

const NewMessagesSubpage = ({
  clinicalFacility,
  limit,
  unreadEventCounts,
  featureFlags,
  sidebarInformation,
  sidebarExpanded,
}: Props) => {
  return (
    <FeatureFlagProvider flags={featureFlags}>
      <InternalClinicalFacilityDashboardSubpage
        title="New messages"
        subtitle="Orders you follow that have new messages."
        clinicalFacility={clinicalFacility}
        limit={limit}
        unreadEventCounts={unreadEventCounts}
        getClinicalFacilityDashboardResults={
          getClinicalFacilityDashboardNewMessagesFocusTileResults
        }
        sidebarInformation={sidebarInformation}
        sidebarExpanded={sidebarExpanded}
        updateSidebarExpandedPreference={updateSidebarExpandedPreference}
      />
    </FeatureFlagProvider>
  )
}

export default NewMessagesSubpage
