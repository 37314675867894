import React, { useContext } from "react"
import { ToastContext } from "components/Toaster"
import { sendEmailNotificationsOptInEmail } from "../../api"
import Alert from "components/Alert"
import Link from "components/Link"
import { Doctor } from "../../sharedTypes"

interface Props {
  reviewer: { id: string; email: string; name: string }
  selectedDoctor: Doctor
}
const OptInNotificationsEmailAlert = (props: Props) => {
  const { persistentAlert } = useContext(ToastContext)
  const resendOptInEmail = () => {
    return sendEmailNotificationsOptInEmail(
      props.reviewer.id,
      props.selectedDoctor.id
    )
      .then(() => {
        persistentAlert({
          message: `Email successfully sent to ${props.reviewer.email}.`,
          severity: "success",
        })
      })
      .catch(() =>
        persistentAlert({
          message: `Encountered an error. Please use the Help Center button to contact support.`,
          severity: "error",
        })
      )
  }
  return (
    <Alert status="info">
      <span>
        {`Check your email to opt-in to digital notifications for signature requests and stop receiving faxes for Dr. ${props.selectedDoctor.name}. `}
      </span>
      <Link onClick={resendOptInEmail}>Resend email.</Link>
    </Alert>
  )
}

export default OptInNotificationsEmailAlert
