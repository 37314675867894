// @team @catalog-crew

export const consignmentClosetsUrl = (): string => {
  return "/cms/catalog/consignment_closets"
}

export const consignmentClosetsSupplierFilterUrl = (supplierId): string => {
  return `/cms/catalog/consignment_closets?supplier_id=${supplierId}`
}

export const consignmentClosetCreateUrl = (): string => {
  return `${consignmentClosetsUrl()}/new`
}

export const consignmentClosetDuplicateUrl = (
  consignmentClosetId: string
): string => {
  return `${consignmentClosetsUrl()}/${consignmentClosetId}/duplicate`
}

export const consignmentClosetDetailUrl = (
  consignmentClosetId: string
): string => {
  return `/cms/catalog/consignment_closets/${consignmentClosetId}`
}

export const consignmentClosetDuplicateSPCFormUrl = (
  consignmentClosetId: string,
  supplierPackageConfigurationId: string
): string => {
  return `/cms/catalog/consignment_closets/${consignmentClosetId}/supplier_package_configurations/${supplierPackageConfigurationId}/duplicate`
}

export const consignmentClosetExportUrl = (
  consignmentClosetId: string
): string => {
  return `/cms/catalog/consignment_closets/${consignmentClosetId}/export`
}
