// @ts-strict-ignore
import React from "react"
import Modal from "components/Modal"
import Button from "components/form/Button"
import { Form, TextField } from "components/form"

interface Props {
  show?: boolean
  cancel(): void
  submit({ viewName }): Promise<void>
}

interface Values {
  viewName: string
}

function SaveModal({ show, cancel, submit }: Props) {
  const onSubmit = ({ viewName }, { setErrors }) => {
    return submit({ viewName }).catch(({ errors }) => {
      setErrors(errors)
    })
  }
  return (
    <Modal title="Save View" size="md" show={show} cancel={cancel}>
      <Form initialValues={{ viewName: "" }} onSubmit={onSubmit}>
        {({
          values,
          isSubmitting,
        }: {
          values: Values
          isSubmitting: boolean
        }) => (
          <>
            <Modal.Body>
              <TextField
                name="viewName"
                label="What would you like this view to be called?"
                placeholder="Enter View Name"
                disabled={isSubmitting}
              />
            </Modal.Body>
            <Modal.Footer>
              <Button
                className="btn-link"
                disabled={isSubmitting}
                onClick={cancel}
              >
                Cancel
              </Button>
              <Button
                className="btn-brand"
                type="submit"
                disabled={!values.viewName || isSubmitting}
              >
                Save
              </Button>
            </Modal.Footer>
          </>
        )}
      </Form>
    </Modal>
  )
}

export default SaveModal
