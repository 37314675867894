import React from "react"
import { Doctor } from "sharedTypes"
import { fullName } from "utilities/person"
import Modal from "components/Modal"
import EmailForm from "./EmailForm"
import { isEmailValid, EMAIL_VALIDATION_MESSAGE } from "utilities/email"

type Props = {
  doctor: Doctor
  updateDoctor(doctor: Doctor, params: { email: string }): Promise<void>
  show: boolean
  close(): void
}

const EmailModal = ({ doctor, updateDoctor, show, close }: Props) => {
  const update = (values, { setErrors }) => {
    updateDoctor(doctor, values)
      .then(close)
      .catch((errors) => setErrors(errors))
  }

  const validate = ({ email }) => {
    if (!isEmailValid(email)) {
      return { email: EMAIL_VALIDATION_MESSAGE }
    }
  }

  return (
    <Modal
      show={show}
      cancel={close}
      title={`Add Email Address for ${fullName(doctor)}`}
      size="md"
    >
      <Modal.Body>
        <EmailForm
          initialValues={{ email: "" }}
          onSubmit={update}
          validate={validate}
        />
      </Modal.Body>
    </Modal>
  )
}

export default EmailModal
