import React, { useState, useCallback } from "react"
import { DocumentationOrder } from "../fetchOrders"
import {
  DocumentationOrderNotificationHistory,
  documentationOrderNotificationHistory,
} from "./api"
import NotificationHistory from "./NotificationHistory"
import Popover from "components/Popover"
import Link from "components/Link"

const NotificationHistoryPopover = ({
  order,
}: {
  order: DocumentationOrder
}) => {
  const [notificationHistory, setNotificationHistory] = useState<
    DocumentationOrderNotificationHistory[]
  >([])
  const [open, setOpen] = useState(false)
  const toggle = () => setOpen((open) => !open)
  const hide = () => setOpen(false)

  const getHistory = useCallback(async () => {
    const res = await documentationOrderNotificationHistory(order.id)
    setNotificationHistory(res)
  }, [order.id])

  const onClick = () => {
    toggle()
    getHistory()
  }

  return (
    <>
      {order.lastNotification && (
        <Popover
          open={open}
          onHide={hide}
          onClick={onClick}
          label={<Link>{order.lastNotification}</Link>}
        >
          <NotificationHistory
            orderNotificationHistories={notificationHistory}
          />
        </Popover>
      )}
    </>
  )
}

export default NotificationHistoryPopover
