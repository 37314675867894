// @ts-strict-ignore
import React, { useState } from "react"
import { Route, Redirect } from "react-router-dom"
import {
  Supplier,
  ReferralFields,
  SalesRep,
  SupplierLegalEntity,
  Facility,
  InvitationFields,
  FacilityCreateFormFields,
  Branch,
} from "../sharedTypes"
import ReferralForm from "./ReferralForm"
import FacilitySelectForm from "./FacilitySelectForm"
import FacilityCreateForm from "./FacilityCreateForm"
import InvitationSuccess from "./InvitationSuccess"
import { withHashRouter } from "routers/HashRouter"
import { RouteComponentProps } from "react-router"
import { handleError } from "utilities/error"

type Props = {
  suppliers: Supplier[]
  createInvitation(supplierId: string, fields: InvitationFields): Promise<void>
  getSalesReps(supplierId: string, term: string): Promise<SalesRep[]>
  getBranches(supplierId: string, term: string): Promise<Branch[]>
  getSupplierLegalEntities(
    supplierId: string,
    term: string
  ): Promise<SupplierLegalEntity[]>
  getFacilities(supplierId: string, term: string): Promise<Facility[]>
} & RouteComponentProps

enum Routes {
  Root = "/",
  FacilitySelect = "/facility-select",
  FacilityCreate = "/facility-create",
  InvitationSuccess = "/invitation-success",
}

const InternalSupplierFacilitySignUp = ({
  suppliers,
  createInvitation,
  getSalesReps,
  getSupplierLegalEntities,
  getBranches,
  getFacilities,
  history,
}: Props) => {
  const [supplierId, setSupplierId] = useState<string>(undefined)
  const [facility, setFacility] = useState<Facility>(undefined)
  const [referralFields, setReferralFields] = useState<ReferralFields>(
    undefined
  )

  const restartInvitation = () => {
    setSupplierId(undefined)
    setFacility(undefined)
    setReferralFields(undefined)
    history.push({ pathname: Routes.Root })
  }

  const submitFacilitySelectForm = (): Promise<void> => {
    if (facility.facilityId) {
      return createInvitation(supplierId, {
        ...referralFields,
        facilityId: facility.facilityId,
      })
        .then(() => {
          history.push({ pathname: Routes.InvitationSuccess })
        })
        .catch(handleError)
    }
    return Promise.resolve().then(() => {
      history.push({
        pathname: Routes.FacilityCreate,
      })
    })
  }

  const sendInviteWithoutFacility = (): Promise<void> => {
    return createInvitation(supplierId, { ...referralFields }).then(() => {
      history.push({ pathname: Routes.InvitationSuccess })
    })
  }

  const submitFacilityCreateForm = (
    supplierId: string,
    facilityFields: FacilityCreateFormFields
  ): Promise<void> => {
    return createInvitation(supplierId, {
      ...referralFields,
      facility: facilityFields,
    }).then(() => {
      history.push({ pathname: Routes.InvitationSuccess })
    })
  }

  return (
    <>
      <Route exact path={Routes.Root}>
        <ReferralForm
          suppliers={suppliers}
          onSupplierIdChange={setSupplierId}
          onSubmit={(values) => {
            setReferralFields(values)
            history.push({
              pathname: Routes.FacilitySelect,
            })
            return Promise.resolve()
          }}
          getSalesReps={(term: string) => getSalesReps(supplierId, term)}
          getSupplierLegalEntities={(term: string) =>
            getSupplierLegalEntities(supplierId, term)
          }
          getBranches={(term: string) => getBranches(supplierId, term)}
        />
      </Route>
      <Route exact path={Routes.FacilitySelect}>
        {supplierId ? (
          <FacilitySelectForm
            onFacilityChange={setFacility}
            onSubmit={submitFacilitySelectForm}
            getFacilities={(term: string) => getFacilities(supplierId, term)}
            skipFacilitySelect={sendInviteWithoutFacility}
          />
        ) : (
          <Redirect to={Routes.Root} />
        )}
      </Route>
      <Route exact path={Routes.FacilityCreate}>
        {supplierId && facility ? (
          <FacilityCreateForm
            onSubmit={(values: FacilityCreateFormFields) =>
              submitFacilityCreateForm(supplierId, values)
            }
            onBackButtonClick={() =>
              history.push({ pathname: Routes.FacilitySelect })
            }
            facility={facility}
          />
        ) : (
          <Redirect to={Routes.Root} />
        )}
      </Route>
      <Route exact path={Routes.InvitationSuccess}>
        {referralFields?.email ? (
          <InvitationSuccess
            email={referralFields?.email}
            onClick={restartInvitation}
          />
        ) : (
          <Redirect to={Routes.Root} />
        )}
      </Route>
    </>
  )
}
export default withHashRouter(InternalSupplierFacilitySignUp)
