// @ts-strict-ignore
import { createConsumer } from "./cable"
import { camelizeKeys, decamelizeKeys } from "humps"
import { Employer } from "sharedTypes"
import ActionCable from "actioncable"
import { classify } from "utilities/string"

export default class WebSocketClient {
  consumer: ActionCable.Cable
  employer: Employer

  constructor(employer: Employer, accessToken?: string) {
    const webSocketUrl = window.location.origin.replace(/^http/, "ws")
    const url = accessToken
      ? `${webSocketUrl}/cable?access_token=${encodeURIComponent(accessToken)}`
      : `${webSocketUrl}/cable`
    this.consumer = createConsumer(url)
    this.employer = employer
  }

  subscribe({
    channel,
    connected,
    disconnected,
    received,
  }: {
    channel: { channel: string; id: string }
    connected?(): void
    disconnected?(): void
    received?(data): void
  }): ActionCable.Channel {
    const channelOptions = {
      ...channel,
      employerId: this.employer.employerId,
      employerType: classify(this.employer.employerType),
    }

    // TODO: find a better decamelizer that converts a `/` back to `::`
    // updatedKeys are needed to properly convert insurance/payor to Insurance::Payor
    const updatedKeys = decamelizeKeys(channelOptions)
    if (updatedKeys["employer_type"] === "InsurancePayor") {
      updatedKeys["employer_type"] = "Insurance::Payor"
    }

    return this.consumer.subscriptions.create(updatedKeys, {
      connected,
      disconnected,
      received: (data) => {
        received(camelizeKeys(data))
      },
    })
  }
}
