import { ServerSideDataGridParams } from "../../../hooks/useServerSideDataGrid"
import { get } from "../../../../../services/api"

export type SuppliersDataGridRecord = {
  id: number
  externalId: string
  name: string
  test: boolean
  packageCount: number
  skuCount: number
  unconfigurablePackageCount: number
  consignmentClosetCount: number
  brightree: boolean
}

export type SuppliersDataGridResponse = {
  records: SuppliersDataGridRecord[]
  totalCount: number
}

export const getCatalogSupplierData = async (
  params?: ServerSideDataGridParams
): Promise<SuppliersDataGridResponse> => {
  return await get("/cms/catalog/suppliers.json", params).then(
    (response) => response.data
  )
}
