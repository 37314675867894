import React from "react"
import LoginForm from "./LoginForm"
import { navigate } from "utilities/navigation"
import SignUpLink from "./SignUpLink"
import { Flash } from "../sharedTypes"

interface Props {
  epicOauth: boolean
  flash: Flash | undefined
  login: (
    loginParams: {
      login: string
      password: string
    },
    redirectTo: string
  ) => Promise<{
    redirectTo?: string
  }>
  redirectTo: string
  samlDomains: string[]
  signUpUrl: string
}

const InternalLogin: React.FC<Props> = ({
  epicOauth,
  flash,
  login,
  redirectTo: redirectToFromInitialProps,
  samlDomains,
  signUpUrl,
}) => {
  const [redirectTo, setRedirectTo] = React.useState(redirectToFromInitialProps)

  const handleLogin: (params: {
    login: string
    password: string
  }) => Promise<void> = async (params) => {
    const response = await login(params, redirectTo)
    const redirectUrl = response.redirectTo || redirectToFromInitialProps
    setRedirectTo(redirectUrl)

    navigate(redirectUrl)
  }

  return (
    <>
      <LoginForm
        epicOauth={epicOauth}
        flash={flash}
        login={handleLogin}
        samlDomains={samlDomains}
      />
      <SignUpLink signUpUrl={signUpUrl} />
    </>
  )
}

export default InternalLogin
