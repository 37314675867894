export type Doctor = {
  id: string
  name: string
  npi: string
  pendingOrdersCount?: number
  signOnScreenEnabled: boolean
  reviewerAgreementIsSigned: boolean
  showNotificationsOptInRequestInfoBanner: boolean
  hasActionableSignatureRequests?: boolean
}

export type SidebarListDoctor = Pick<
  Doctor,
  "id" | "name" | "hasActionableSignatureRequests"
>

export enum Tab {
  SIGN = "sign",
  REVIEW = "review",
  DONE = "done",
  FACILITY_SIGN_UP = "facility_sign_up",
}
