import React from "react"
import ReviewCard from "../../ReviewCard"
import CartSectionContent from "../../../SectionContent/CartSectionContent"
import { toList } from "utilities/array"
import { PRODUCTS_STEP } from "applications/Workflow/utilities/steps"
import { warningSeverity } from "../../../../utilities/validation"
import {
  DmeOrder,
  DmeOrderWarning,
  Employer,
  LineItemGroup,
  RxDetailsOutput,
} from "sharedTypes"
import { countItemsInCart } from "../../../../../../utilities/cart"

function EmptyCartParagraph({ text }: { text: string }) {
  return <p className="color-mid-gray">{text}</p>
}

function getSummary(lineItemGroups, emptyCartText) {
  if (lineItemGroups.length === 0) {
    return <EmptyCartParagraph text={emptyCartText} />
  }
  return toList(
    lineItemGroups.map((lineItem) => lineItem.packageName),
    3
  )
}

type Props = {
  dmeOrder: DmeOrder
  lineItemGroups: LineItemGroup[]
  currentEmployer: Employer
  expanded: boolean
  warnings: DmeOrderWarning[]
  rxDetailsOutputs: RxDetailsOutput[]
  canUpdateCustomAttributeConfiguration: boolean
  canUpdateSupplierLegalEntity: boolean
  title?: string
  emptyCartText?: string
  showFulfilledBy?: boolean
}

function CartReviewCard({
  dmeOrder,
  lineItemGroups,
  currentEmployer,
  expanded,
  warnings,
  rxDetailsOutputs,
  canUpdateCustomAttributeConfiguration,
  canUpdateSupplierLegalEntity,
  title = "In this order",
  emptyCartText = "Your cart is currently empty",
  showFulfilledBy = true,
}: Props) {
  const summary = getSummary(lineItemGroups, emptyCartText)
  const status = warningSeverity(PRODUCTS_STEP, warnings)
  const titleWithCount = `${title} (${countItemsInCart(lineItemGroups)})`

  return (
    <ReviewCard
      status={status}
      expanded={expanded}
      title={titleWithCount}
      summary={summary}
    >
      {lineItemGroups.length === 0 && (
        <EmptyCartParagraph text={emptyCartText} />
      )}
      <CartSectionContent
        dmeOrder={dmeOrder}
        lineItemGroups={lineItemGroups}
        currentEmployer={currentEmployer}
        rxDetailsOutputs={rxDetailsOutputs}
        warnings={warnings}
        canUpdateCustomAttributeConfiguration={
          canUpdateCustomAttributeConfiguration
        }
        canUpdateSupplierLegalEntity={canUpdateSupplierLegalEntity}
        showFulfilledBy={showFulfilledBy}
      />
    </ReviewCard>
  )
}

export default CartReviewCard
