import React from "react"
import InternalAccountCreation, {
  MethodSelection,
} from "./components/InternalAccountCreation"
import * as api from "./api"
import { Supplier } from "../../shared/sharedTypes"

interface Props {
  supplier?: Supplier
}

const AccountCreation = ({ supplier }: Props) => {
  const createUser = (
    userParams,
    supplierId: string,
    methodSelection: MethodSelection,
    salesRepEmail: string
  ) => {
    return api.createUser(
      userParams,
      supplierId,
      methodSelection,
      salesRepEmail
    )
  }
  const getSuppliers = (term: string) => {
    return api.getSuppliers(term)
  }
  return (
    <InternalAccountCreation
      createUser={createUser}
      getSuppliers={getSuppliers}
      supplier={supplier}
    />
  )
}

export default AccountCreation
