// @ts-strict-ignore
import React from "react"
import TextField from "./TextField"
import {
  isPhoneValid,
  normalizePhone,
  PHONE_VALIDATION_MESSAGE,
} from "utilities/phone"
import { PHONE_AND_FAX_PLACEHOLDER } from "utilities/placeholder"
import { PHONE_NUMBER_MASK } from "components/MaskedInput"

type Props = {
  className?: string
  id?: string
  name: string
  label?: string
  disabled?: boolean
  onChange?(event: React.FormEvent): void
  unwrapped?: boolean
  validateField?: boolean
  optional?: boolean
}

function PhoneField({
  className,
  id,
  name,
  label,
  onChange,
  disabled,
  unwrapped,
  validateField,
  optional,
}: Props) {
  const validate = (value) => {
    if (!isPhoneValid(value, optional)) {
      return PHONE_VALIDATION_MESSAGE
    }
  }

  return (
    <TextField
      className={className}
      id={id}
      name={name}
      label={label}
      mask={disabled ? null : PHONE_NUMBER_MASK}
      placeholder={PHONE_AND_FAX_PLACEHOLDER}
      disabled={disabled}
      numeric
      onChange={onChange}
      onPaste={(event) => {
        event.target.value = normalizePhone(event.target.value)
      }}
      validate={validateField ? validate : undefined}
      unwrapped={unwrapped}
    />
  )
}

export default PhoneField
