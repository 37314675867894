import React, { useRef } from "react"
import { withApollo } from "../../../../hocs/withApollo"
import DmeOrdersTable from "./components/DmeOrdersTable/DmeOrdersTable"
import { CanopyTextInputField } from "@parachutehealth/canopy-text-input-field"
import { CanopyButton } from "@parachutehealth/canopy-button"
import { Box, Grid } from "@material-ui/core"
import { useLazyQuery } from "@apollo/client"
import { GetPayorDmeOrders } from "./graphql/payorDmeOrdersQuery"
import { DmeOrderConnection } from "../../../../graphql/__generated__/graphql"
import { FeatureFlagProvider } from "components/FeatureFlagContext"
import { FeatureFlagInput } from "sharedTypes"

type MemberServicesDashboardProps = {
  featureFlags: FeatureFlagInput
}

const MemberServicesDashboard = ({
  featureFlags,
}: MemberServicesDashboardProps) => {
  const [
    getPayorDmeOrders,
    { data, loading, called, fetchMore },
  ] = useLazyQuery(GetPayorDmeOrders)

  const dmeOrders = data?.payorDmeOrders as DmeOrderConnection

  const search = useRef("")

  const handleSearch = async (memberId: string) => {
    await getPayorDmeOrders({ variables: { memberId: memberId } })
  }

  return (
    <FeatureFlagProvider flags={featureFlags}>
      <div className="canopy-m-10x">
        <h1 className="canopy-typography-heading-2xlarge canopy-mbs-12x canopy-mbe-0">
          Member Search
        </h1>
        <p className="canopy-mbe-20x">
          Search for members to find their recent orders
        </p>
        <Box mb={3} className="canopy-mbe-20x">
          <Grid container spacing={2} alignItems="flex-end">
            <Grid item className="canopy-pie-0">
              <CanopyTextInputField
                label="Member ID"
                type="search"
                data-testid="search-patient-id"
                required
                onChange={(e) => {
                  search.current = e.target.value
                }}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    void handleSearch(search.current)
                  }
                }}
              />
            </Grid>
            <Grid item>
              <CanopyButton
                variant="primary"
                onClick={() => void handleSearch(search.current)}
              >
                Search
              </CanopyButton>
            </Grid>
          </Grid>
        </Box>

        {called && (
          <DmeOrdersTable
            dmeOrdersData={dmeOrders}
            loading={loading}
            fetchMore={fetchMore}
          />
        )}
      </div>
    </FeatureFlagProvider>
  )
}

export default withApollo(MemberServicesDashboard)
