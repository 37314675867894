// @ts-strict-ignore
import React from "react"
import Icon from "components/Icon"
import { faxCoverSheetUrl } from "applications/Workflow/urls"
import classNames from "classnames"

interface Props {
  disabled?: boolean
  documentationRequirementIds?: string[]
  otherTitle?: string
  text?: string
  className?: string
}

const DownloadFaxCoverSheetLink = ({
  className,
  text,
  disabled,
  documentationRequirementIds,
  otherTitle,
}: Props) => {
  return (
    <a
      className={classNames(className, { disabled })}
      href={
        disabled
          ? undefined
          : faxCoverSheetUrl(documentationRequirementIds, otherTitle)
      }
    >
      <Icon type="download" className="canopy-mie-4x" />
      {text}
    </a>
  )
}

DownloadFaxCoverSheetLink.defaultProps = {
  disabled: false,
  documentationRequirementIds: [],
  className: "link",
  text: "Download Cover Sheet",
}

export default DownloadFaxCoverSheetLink
