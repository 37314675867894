import React from "react"
import { FacilityOption } from "sharedTypes"
import { formatAddress } from "utilities/address"
import { pluralize } from "utilities/string"
import compact from "lodash/compact"
import Suggestion from "components/form/Select/Suggestion"

type Props = {
  facility: FacilityOption
  isHighlighted?: boolean
}

const FacilitySuggestion: React.FC<Props> = ({
  facility,
  isHighlighted = false,
}) => {
  const userLine =
    facility.source === "fulfillment_agreement"
      ? `${facility.userCount} ${pluralize("user", facility.userCount)}`
      : "Not on Parachute"
  const facilityId = facility.supplierSystemId
    ? `#${facility.supplierSystemId}`
    : null

  return (
    <Suggestion isHighlighted={isHighlighted}>
      <div className="row">
        <div className="col-md-8">
          <div className="bold">
            {compact([facility.name, facilityId]).join(" - ")}
          </div>
        </div>
        <div className="col-md-4 text-right">{userLine}</div>
      </div>
      <div className="row">
        <div className="col-md-12">{formatAddress(facility)}</div>
      </div>
      {facility.otherName && (
        <div className="row">
          <div className="col-md-12 canopy-mt-4x">
            <small>Other name: {facility.otherName}</small>
          </div>
        </div>
      )}
    </Suggestion>
  )
}

export default FacilitySuggestion
