import React, { createRef } from "react"
import { Form } from "components/form"
import { getFilesValidationMessage } from "utilities/fileValidation"
import FileHandler from "utilities/FileHandler"
import * as styles from "applications/Uploads/upload-form.module.scss"
import { EventCategory, trackEvent } from "utilities/tracking"

interface Props {
  afterUpload: () => void
  upload: (file) => Promise<any>
  requiredCsvColumns: string[]
  possibleCsvColumns: string[]
  eventing?: {
    category: EventCategory
    eventType: string
  }
}

const FileInputName = "file_for_csv_upload"
const MAX_BYTES = 3_000_000
const MAX_LINES = 10_000
const onFileChange = ({ event, setFieldValue }) => {
  const files: File[] = Array.from(event.target.files)
  setFieldValue(FileInputName, files[0])
}

const SelectedFile = ({ file, unselect }) => (
  <div className="d-flex canopy-mbe-8x canopy-mx-2x">
    <div>
      <i className="far fa-file-alt canopy-mie-8x" />
    </div>
    <span className={styles.filename}>{file.name}</span>
    <div
      role="button"
      className="canopy-mis-auto canopy-p-8x m-n3"
      onClick={unselect}
    >
      <span className="icon fas fa-times canopy-mis-auto" />
    </div>
  </div>
)

const UploadForm = ({
  upload,
  afterUpload,
  requiredCsvColumns,
  possibleCsvColumns,
  eventing,
}: Props) => {
  const fileInputRef = createRef<HTMLInputElement>()
  const unselectFile = (setFieldValue) => () => {
    setFieldValue(FileInputName, null)
    // @ts-ignore
    fileInputRef.current.value = null
  }

  const validate = async (values) => {
    if (!values[FileInputName]) {
      return { [FileInputName]: ["must select a file"] }
    }

    const file = values[FileInputName] as File

    const fileHandler = new FileHandler(file)

    if (fileHandler.size() === 0) {
      return { [FileInputName]: ["file must not be empty"] }
    }

    if (fileHandler.size() > MAX_BYTES) {
      return {
        [FileInputName]: [
          `File must be smaller than ${MAX_BYTES / 1000000} MB`,
        ],
      }
    }

    const lineCount = await fileHandler.lineCount()

    if (lineCount > MAX_LINES) {
      return {
        [FileInputName]: [
          `File must have no more than ${new Intl.NumberFormat("en-US").format(
            MAX_LINES
          )} lines`,
        ],
      }
    }

    const message = getFilesValidationMessage([file])
    if (message) {
      return { [FileInputName]: [message] }
    }

    const firstLine = await fileHandler.firstLine()
    const headers = firstLine.split(",").map((header) => header.trim())
    const missingColumns = checkMissingRequiredColumns(headers)
    const unrecognizedColumns = checkUnrecognizedColumns(headers)
    if (missingColumns.length || unrecognizedColumns.length) {
      const fileErrors: string[] = []
      if (missingColumns.length > 0) {
        fileErrors.push("missing columns: " + missingColumns.join(", "))
      }

      if (unrecognizedColumns.length > 0) {
        fileErrors.push(
          "unrecognized columns: " + unrecognizedColumns.join(", ")
        )
      }
      if (fileErrors.length > 0) {
        eventing &&
          trackEvent(
            eventing.category,
            `${eventing.eventType}-validation-error-${fileErrors}`
          )
      }
      return { [FileInputName]: fileErrors }
    }
  }

  const checkMissingRequiredColumns = (headers: string[]): string[] => {
    return requiredCsvColumns.filter((column) => !headers.includes(column))
  }

  const checkUnrecognizedColumns = (headers: string[]): string[] => {
    return headers.filter((header) => !possibleCsvColumns.includes(header))
  }

  const onSubmit = (
    { [FileInputName]: file },
    { setSubmitting, setErrors }
  ) => {
    setSubmitting(true)
    return upload(file)
      .then(() => {
        setSubmitting(false)
        afterUpload()
      })
      .catch((e) => {
        setErrors({ [FileInputName]: [e.response.data["errors"]] })
        setSubmitting(false)
      })
  }

  return (
    <Form
      onSubmit={onSubmit}
      validate={validate}
      initialValues={{ file: null }}
    >
      {({ isSubmitting, setFieldValue, values, errors }) => {
        const hasErrors =
          values[FileInputName] && Object.keys(errors).length > 0

        return (
          <>
            <div
              className={`well bg-lightest-gray canopy-p-12x canopy-mbe-8x ${
                hasErrors ? styles.invalidField : "well-borderless"
              }`}
            >
              <input
                className="d-none"
                type="file"
                name={FileInputName}
                id={FileInputName}
                ref={fileInputRef}
                accept=".csv,text/csv,text/plain"
                disabled={isSubmitting}
                onChange={(event) => onFileChange({ event, setFieldValue })}
              />
              {!values[FileInputName] ? (
                <div className="text-center">
                  <label
                    className="file-label btn btn-sm btn-brand-o"
                    htmlFor={FileInputName}
                  >
                    Select CSV to upload
                  </label>
                </div>
              ) : (
                <SelectedFile
                  file={values[FileInputName]}
                  unselect={unselectFile(setFieldValue)}
                />
              )}
            </div>
            {hasErrors && (
              <div role="alert" aria-live="polite" className={styles.error}>
                {errors[FileInputName] &&
                  errors[FileInputName].map((message) => (
                    <p key={message}>{message}</p>
                  ))}
              </div>
            )}
            <hr className="bg-lightest-gray" />
            <div className="text-right">
              <button
                className="btn btn-sm btn-brand"
                disabled={isSubmitting || hasErrors}
                onClick={() => {
                  eventing &&
                    trackEvent(EventCategory.Resupply, eventing.eventType)
                }}
                type="submit"
              >
                Upload Document
              </button>
            </div>
          </>
        )
      }}
    </Form>
  )
}

export default UploadForm
