// @team @facilitators
import React, { useEffect, useState } from "react"
import { Form, RadioButtons, Checkboxes } from "components/form"
import Overlay from "components/Overlay"
import { arrayEquals } from "utilities/array"
import {
  PackageConfiguration,
  CustomAttributeWithOptions,
} from "../sharedTypes"
import { ApplicationError, OfferingType, Supplier } from "sharedTypes"
import { FormikValues } from "formik"
import DeliveryMethodSelector from "./DeliveryMethodSelector"
import { createPackageConfigurationErrorNotification } from "applications/Workflow/api"
import { handleError } from "utilities/error"
import {
  canopyColorPrimitivesGray86,
  canopyColorTextSecondary,
} from "@parachutehealth/canopy-tokens-color"
import SelectedSupplierSummary from "./SupplierSelector/SelectedSupplierSummary"
import debounce from "awesome-debounce-promise"
import isEqual from "lodash.isequal"
import { useFeatureFlags } from "../../../../../components/FeatureFlagContext"

const nameFor = ({ id }) => `attribute${id}`

const productAttributeNameFor = (productId, attributeId): string =>
  `product${productId}attribute${attributeId}`

const notFulfillableWarning = (id) => {
  try {
    createPackageConfigurationErrorNotification(id)
  } catch (error) {
    handleError(error as ApplicationError)
  }
  return (
    <div className="well well-danger well-expand canopy-mbs-8x">
      Package cannot be configured. Parachute Health has been notified, and
      we're working on resolving the issue. Check back again soon!
    </div>
  )
}

type ProductConfigurationValues = {
  selectedOptionalProductIds: string[] | string
  supplierId: string
  deliveryMethod: string
  productCustomAttributeOptions?: string[][]
  catalogCustomAttributeOptionIds?: string[]
}

type Props = {
  isConfigurationIncomplete: boolean
  showAccessories: boolean
  packageConfiguration: PackageConfiguration
  preferredSuppliers: Supplier[]
  onContinue(
    params?: FormikValues
  ): Promise<PackageConfiguration> | Promise<void>
  save(params: FormikValues): Promise<PackageConfiguration> | Promise<void>
  showSupplierSelection(): void
}

const saveValues = (
  values: ProductConfigurationValues,
  save: (params: FormikValues) => Promise<PackageConfiguration | void>,
  setLoading: (loading: boolean) => void,
  launchLoadingSpinner: boolean
) => {
  if (launchLoadingSpinner) {
    setLoading(true)
  }
  return save(values)
}

const debouncedSave = debounce(saveValues, 1500)

const Configuration: React.FC<Props> = ({
  isConfigurationIncomplete,
  showAccessories,
  packageConfiguration,
  preferredSuppliers,
  onContinue,
  save,
  showSupplierSelection,
}: Props) => {
  const allProductCustomAttributes: () => CustomAttributeWithOptions[] = () => {
    const {
      requiredProductCustomAttributes,
      optionalProductCustomAttributes,
    } = packageConfiguration
    return requiredProductCustomAttributes.concat(
      optionalProductCustomAttributes
    ) as CustomAttributeWithOptions[]
  }

  const getInitialValues = (packageConfiguration) => {
    const {
      catalogCustomAttributeOptionIds,
      productCustomAttributeOptions,
      selectedOptionalProductIds,
      packageRelationshipsEnabled,
    } = packageConfiguration

    let initialValues = {
      selectedOptionalProductIds,
      supplierId: packageConfiguration.supplier?.externalId,
      deliveryMethod: packageConfiguration.deliveryMethod,
    }

    if (packageRelationshipsEnabled) {
      const pcaoNamesToValues: Record<
        string,
        string
      > = productCustomAttributeOptions.reduce(
        (acc, pcao) => ({
          ...acc,
          [productAttributeNameFor(
            pcao["catalogProductId"],
            pcao["catalogCustomAttributeId"]
          )]: JSON.stringify({
            catalog_custom_attribute_option_id:
              pcao["catalogCustomAttributeOptionId"],
            catalog_product_id: pcao["catalogProductId"],
            catalog_custom_attribute_id: pcao["catalogCustomAttributeId"],
            dme_order_package_configuration_id:
              pcao["dmeOrderPackageConfigurationId"],
          }),
        }),
        {}
      )
      initialValues = {
        ...initialValues,
        ...pcaoNamesToValues,
      }

      return initialValues
    } else {
      initialValues = {
        ...initialValues,
        ...allProductCustomAttributes().reduce(
          (acc, attribute: CustomAttributeWithOptions) => ({
            ...acc,
            [nameFor(attribute)]: catalogCustomAttributeOptionIds.find((id) =>
              attribute.options.some((o) => o.id === id)
            ),
          }),
          {}
        ),
      }
      return initialValues
    }
  }

  const [loading, setLoading] = useState(false)
  const [submittingValues, setSubmittingValues] = useState(false)
  const [continueClicked, setContinueClicked] = useState(false)
  const [previousValues, setPreviousValues] = useState<{
    [key: string]: string
  } | null>(getInitialValues(packageConfiguration))
  const { isFeatureEnabled } = useFeatureFlags()

  useEffect(() => {
    async function handleContinueClicked() {
      await onContinue()
      setContinueClicked(false)
      setLoading(false)
    }

    if (continueClicked && !submittingValues) {
      handleContinueClicked()
    }
  }, [continueClicked, onContinue, submittingValues])

  const submitValues: (
    values: { [key: string]: string },
    setErrors: (errors: { [key: string]: string }) => void,
    resetForm: () => void
  ) => void = async (values, setErrors, resetForm) => {
    const valuesToSubmit = getValuesToSubmit(values)
    const shouldLaunchLoadingSpinner = requiredOptionsChanged(
      values,
      previousValues
    )
    setPreviousValues(values)

    try {
      setSubmittingValues(true)
      await debouncedSave(
        valuesToSubmit,
        save,
        setLoading,
        shouldLaunchLoadingSpinner
      )

      setSubmittingValues(false)
      setLoading(false)
      resetForm()
    } catch (error) {
      resetForm()
      const e = error as {
        [p: string]: string
      }
      const { supplierId, catalogCustomAttributeOptionIds } = e
      if (supplierId || catalogCustomAttributeOptionIds) {
        setErrors(e)
      } else {
        setErrors({ base: "Something went wrong!" })
      }
    }
  }

  const requiredOptionsChanged: (
    values: { [key: string]: string },
    previousValues: { [key: string]: string } | null
  ) => boolean = (values, previousValues) => {
    const valuesChanged = !isEqual(previousValues, values)
    const optionalValuesChanged = previousValues
      ? !arrayEquals(
          previousValues.selectedOptionalProductIds,
          values.selectedOptionalProductIds
        )
      : false
    return valuesChanged && !optionalValuesChanged
  }

  const getValuesToSubmit: (values: {
    [key: string]: string
  }) => ProductConfigurationValues = (values: { [key: string]: string }) => {
    let catalogCustomAttributeOptionIds: string[]
    let productCustomAttributeOptions: string[][]
    const { packageRelationshipsEnabled } = packageConfiguration
    const {
      deliveryMethod,
      selectedOptionalProductIds,
      supplierId,
      ...selectedOptions
    } = values

    if (packageRelationshipsEnabled) {
      const selectedValues = Object.values(selectedOptions)
      productCustomAttributeOptions = selectedValues.map((value: string) =>
        JSON.parse(value)
      )

      return {
        productCustomAttributeOptions,
        selectedOptionalProductIds,
        deliveryMethod,
        supplierId,
      }
    } else {
      catalogCustomAttributeOptionIds = allProductCustomAttributes()
        .map((attr) => selectedOptions[nameFor(attr)])
        .filter((a) => a)

      return {
        catalogCustomAttributeOptionIds,
        selectedOptionalProductIds,
        deliveryMethod,
        supplierId,
      }
    }
  }

  const selectedOptionalProductIdsChanged: (values: {
    [key: string]: string
  }) => boolean = (values) => {
    return previousValues
      ? !arrayEquals(
          previousValues.selectedOptionalProductIds,
          values.selectedOptionalProductIds
        )
      : false
  }

  const onSubmit: (
    values: { [key: string]: string },
    { setErrors, resetForm }
  ) => void = async (values, { setErrors, resetForm }) => {
    const optionalProductIdsChanged = selectedOptionalProductIdsChanged(values)
    if (!optionalProductIdsChanged) {
      setLoading(true)
    }

    await submitValues(values, setErrors, resetForm)
  }

  const handleContinue: () => void = () => {
    setLoading(true)
    setContinueClicked(true)
  }

  const getAccessoriesLabel: (offeringType: OfferingType) => string = (
    offeringType
  ) => {
    switch (offeringType) {
      case OfferingType.Service:
        return "Other Services"
      case OfferingType.O2Recert:
        return isFeatureEnabled("renameRecertificationToRenewal")
          ? "Equipment to renew"
          : "Equipment to recertify"
      default:
        return "Accessories"
    }
  }

  const displayCustomAttributes = (product) => {
    return (
      <div
        style={{
          borderBottom: `2px solid ${canopyColorPrimitivesGray86}`,
        }}
        className="canopy-pt-16x canopy-pb-4x"
        key={product.id}
        data-testid={product.id}
      >
        <div
          className="canopy-typography-heading-xlarge canopy-pb-12x"
          style={{
            color: canopyColorTextSecondary,
          }}
        >
          {product.name}
        </div>
        {product.customAttributes !== null &&
          product.customAttributes.map(
            (customAttribute: CustomAttributeWithOptions) => (
              <RadioButtons
                labelClassName="canopy-typography-heading-small"
                label={customAttribute.name}
                name={productAttributeNameFor(product.id, customAttribute.id)}
                key={customAttribute.id}
                options={customAttribute.options.map((o) => ({
                  label: o.description,
                  value: JSON.stringify({
                    catalog_custom_attribute_option_id: o.id,
                    catalog_product_id: product.id,
                    catalog_custom_attribute_id: customAttribute.id,
                    dme_order_package_configuration_id: packageConfiguration.id,
                  }), // this json stringify is so that the name for the option selection is generated with a unique name (a stringified value object)
                }))}
              />
            )
          )}
      </div>
    )
  }

  const {
    availableDeliveryMethods,
    requiredProductCustomAttributes,
    optionalProductCustomAttributes,
    optionalProducts,
    fulfillable,
    offeringType,
    consignmentCloset,
  } = packageConfiguration

  return (
    <Overlay active={loading} showSpinner>
      <div className="row">
        <div className="col-md-12">
          <Form
            onSubmit={onSubmit}
            initialValues={getInitialValues(packageConfiguration)}
            submitOnChange
          >
            <SelectedSupplierSummary
              selectedSupplier={packageConfiguration.supplier}
              availableSuppliers={packageConfiguration.availableSuppliers}
              preferredSuppliers={preferredSuppliers}
              showSupplierSelect={showSupplierSelection}
            />
            <DeliveryMethodSelector
              availableDeliveryMethods={availableDeliveryMethods}
              consignmentCloset={consignmentCloset}
            />
            {packageConfiguration.packageRelationshipsEnabled &&
              requiredProductCustomAttributes.map(displayCustomAttributes)}

            {packageConfiguration.packageRelationshipsEnabled &&
              showAccessories &&
              optionalProducts.length > 0 && (
                <div
                  style={{
                    borderBottom: `2px solid ${canopyColorPrimitivesGray86}`,
                  }}
                  className="canopy-pt-16x canopy-pb-8x"
                >
                  <Checkboxes
                    name="selectedOptionalProductIds"
                    labelClassName="canopy-typography-heading-xlarge canopy-pb-8x"
                    labelStyle={{
                      color: canopyColorTextSecondary,
                    }}
                    label={getAccessoriesLabel(offeringType)}
                    options={optionalProducts.map((pp) => ({
                      value: pp.productId,
                      label: pp.productName,
                    }))}
                  />
                </div>
              )}

            {packageConfiguration.packageRelationshipsEnabled &&
              optionalProductCustomAttributes.map(displayCustomAttributes)}

            {!packageConfiguration.packageRelationshipsEnabled &&
              requiredProductCustomAttributes.map((customAttribute) => (
                <RadioButtons
                  label={customAttribute.name}
                  name={nameFor(customAttribute)}
                  key={customAttribute.id}
                  options={customAttribute.options.map((o) => ({
                    label: o.description,
                    value: o.id,
                  }))}
                />
              ))}
            {!packageConfiguration.packageRelationshipsEnabled &&
              showAccessories &&
              optionalProducts.length > 0 && (
                <Checkboxes
                  name="selectedOptionalProductIds"
                  label={getAccessoriesLabel(offeringType)}
                  options={optionalProducts.map((pp) => ({
                    value: pp.productId,
                    label: pp.productName,
                  }))}
                />
              )}
            {!packageConfiguration.packageRelationshipsEnabled &&
              optionalProductCustomAttributes.map((customAttribute) => (
                <RadioButtons
                  label={customAttribute.name}
                  name={nameFor(customAttribute)}
                  key={customAttribute.id}
                  deselectable={true}
                  options={customAttribute.options.map((o) => ({
                    label: o.description,
                    value: o.id,
                  }))}
                />
              ))}
            <div className="form-actions text-right">
              {isConfigurationIncomplete || (
                <button
                  type="button"
                  onClick={handleContinue}
                  className="btn btn-brand float-right"
                >
                  Continue
                </button>
              )}
            </div>
            {fulfillable || notFulfillableWarning(packageConfiguration.id)}
          </Form>
        </div>
      </div>
    </Overlay>
  )
}

export default Configuration
