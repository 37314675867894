export const downloadALink = ({
  link,
  filename,
}: {
  link: string
  filename: string
}) => {
  const domLink = document.createElement("a")
  domLink.download = filename
  domLink.href = link
  domLink.click()
  domLink.remove()
}
