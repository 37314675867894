export const facilitiesSearchUrl = () =>
  "/facility_sign_up/facilities/search.json"

export const facilityDetailsUrl = () => `/facility_sign_up/facilities.json`

export const facilitiesUpdateUrl = () => "/facility_sign_up/facilities.json"

export const facilitiesCreateUrl = () => "/facility_sign_up/facilities.json"

export const facilityDashboardUrl = (externalId: string) => `/u/f/${externalId}`
