// @ts-strict-ignore
import React from "react"
import Modal from "components/Modal"
import AddressForm from "./AddressForm"
import { Address } from "sharedTypes"
import { FormikValues } from "formik"
import { formatAddressType } from "utilities/address"
import { isPhoneValid, PHONE_VALIDATION_MESSAGE } from "utilities/phone"

type Props = {
  show: boolean
  cancel(): void
  saveAddress(values: FormikValues): Promise<void>
  address: Address
  defaultState: string
}

function getModalTitle(address) {
  if (address) {
    if (address.addressType) {
      return `Edit ${formatAddressType(address)} address`
    }
    return "Edit address"
  }
  return "Add a new address"
}

function AddressModal({
  show,
  cancel,
  address,
  defaultState,
  saveAddress,
}: Props) {
  const onSubmit = (values, { setErrors, setSubmitting }) => {
    saveAddress(values)
      .then(() => {
        setSubmitting(false)
      })
      .catch((errors) => {
        setErrors(errors)
        setSubmitting(false)
      })
  }
  const initialValues = {
    line1: "",
    line2: "",
    city: "",
    state: defaultState || "AL",
    zip: "",
    phoneNumber: "",
    ...address,
  }
  const validate = ({ phoneNumber }) => {
    if (phoneNumber && !isPhoneValid(phoneNumber)) {
      return { phoneNumber: PHONE_VALIDATION_MESSAGE }
    }
    return {}
  }

  return (
    <Modal size="md" title={getModalTitle(address)} show={show} cancel={cancel}>
      <Modal.Body>
        <AddressForm
          initialValues={initialValues}
          validate={validate}
          onSubmit={onSubmit}
        />
      </Modal.Body>
    </Modal>
  )
}

export default AddressModal
