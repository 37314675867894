import React, { useCallback, useEffect, useRef, useState } from "react"
import { ClinicalFacility, Doctor, Follower } from "sharedTypes"
import { Filters as FiltersType } from "../../sharedTypes"
import { Supplier } from "components/ClinicalFilters/sharedTypes"
import { useDebounce } from "hooks/useDebounce"
import Filters from "../Filters"
import DashboardHeader from "../DashboardHeader"
import DashboardColumn from "../DashboardColumn"
import Icon from "components/Icon"
import StartFirstOrderCard from "../StartFirstOrderCard"
import { navigate } from "utilities/navigation"
import { EventCategory, trackEvent } from "utilities/tracking"
import { newOrderUrl } from "../../urls"
import WelcomeModal from "../WelcomeModal"
import LeftSidebar from "components/ClinicalDashboard/LeftSidebar"
import RemovedLastReviewerEmploymentAlert from "../Alerts/RemovedLastReviewerEmploymentAlert"
import { useFeatureFlags } from "components/FeatureFlagContext"
import {
  SidebarListDoctor,
  SidebarListFacility,
} from "components/ClinicalDashboard/sharedTypes"
import { useEffectThatWontRunOnMount } from "hooks/useEffectThatWontRunOnMount"
import { AxiosPromise } from "axios"
import AdditionalFaxModal from "../AdditionalFaxModal/AdditionalFaxModal"
import FocusTiles from "applications/ClinicalFacilityDashboard/components/FocusTiles/FocusTiles"
import AdditionalFaxModalV2 from "../AdditionalFaxModalV2/AdditionalFaxModalV2"
import {
  getClinicalFacilityDashboardNewMessagesFocusTileResults,
  getClinicalFacilityDashboardCreatedBySupplierFocusTileResults,
  getClinicalFacilityDashboardSentBackForReviewFocusTileResults,
} from "../../api"
import { CanopyButton } from "@parachutehealth/canopy-button"
import { CanopyIcon } from "@parachutehealth/canopy-icon"

type Props = {
  clinicalFacility: ClinicalFacility
  limit: number
  unreadEventCounts: object
  getClinicalFacilityDashboardResults(values?): Promise<any>
  followers: Follower[]
  suppliers: Supplier[]
  doctors: { doctorId: string; firstName: string; lastName: string }[]
  searchFollowers(term: string): Promise<Follower[]>
  searchSuppliers(term: string): Promise<Supplier[]>
  searchDoctors(term: string): Promise<Doctor[]>
  initialValues: FiltersType
  showStartFirstOrderCard?: boolean
  showWelcomeModal: boolean
  showAdditionalFaxPrompt: boolean
  showAdditionalFaxPromptV2: boolean
  alerts: {
    removedLastReviewerEmploymentDoctor?: string
  }
  sidebarInformation: {
    incomingOrdersEnabled: boolean
    isReviewer: boolean
    lastAccessedFacilityEmployerId?: string
    doctors?: SidebarListDoctor[]
    facilities?: SidebarListFacility[]
    lastAccessedDoctorEmployerId?: string
  }
  sidebarExpanded: boolean
  updateSidebarExpandedPreference: (sidebarExpanded: boolean) => AxiosPromise
  currentUserEligibleForReviewerSignUp?: boolean
}

const InternalClinicalFacilityDashboard: React.FC<Props> = ({
  clinicalFacility,
  limit,
  followers,
  suppliers,
  doctors,
  getClinicalFacilityDashboardResults,
  searchFollowers,
  searchSuppliers,
  searchDoctors,
  initialValues,
  showStartFirstOrderCard,
  showWelcomeModal,
  showAdditionalFaxPrompt,
  showAdditionalFaxPromptV2,
  alerts,
  sidebarInformation,
  sidebarExpanded,
  updateSidebarExpandedPreference,
  currentUserEligibleForReviewerSignUp,
}: Props) => {
  const [loading, setLoading] = useState(true)
  const [inProgressOrders, setInProgressOrders] = useState([])
  const [submittedOrders, setSubmittedOrders] = useState([])
  const [doneOrders, setDoneOrders] = useState([])
  const [unreadEventCounts, setUnreadEventCounts] = useState({})
  const [selectedFocusTile, setSelectedFocusTile] = useState<
    "newMessages" | "incomingOrders" | "sentBackForReview" | null
  >(null)
  const [hideFilters, setHideFilters] = useState(false)
  const [initialOriginator, setInitialOriginator] = useState<string>(() =>
    initialValues.originator ? initialValues.originator : ""
  )
  const [initialFollowerIds, setInitialFollowerIds] = useState<string[]>(
    followers.map((follower) => follower.id)
  )
  const [initialSupplierIds, setInitialSupplierIds] = useState<string[]>(() =>
    suppliers.map((supplier) => supplier.externalId)
  )
  const [initialDeliveryDateStart, setInitialDeliveryDateStart] = useState(
    initialValues.deliveryDateStart
  )
  const [initialDeliveryDateEnd, setInitialDeliveryDateEnd] = useState(
    initialValues.deliveryDateEnd
  )
  const [initialSort, setInitialSort] = useState<string>(() =>
    initialValues.sort ? initialValues.sort : ""
  )
  const [initialQuery, setInitialQuery] = useState("")
  const [initialDoctorIds, setInitialDoctorIds] = useState<string[]>(() =>
    doctors.map((doctor) => doctor.doctorId)
  )
  const { isFeatureEnabled } = useFeatureFlags()
  const incomingOrdersEnabledSetting = isFeatureEnabled(
    "incomingOrdersEnabledSetting"
  )

  const newPillIncomingOrders = isFeatureEnabled("newPillIncomingOrders")

  const [initialLeftSidebarExpanded, setInitialLeftSidebarExpanded] = useState<
    boolean
  >(sidebarExpanded)

  const debouncedGetClinicalFacilityDashboardResults = useDebounce(
    getClinicalFacilityDashboardResults
  )
  const latestRequestId = useRef<number>(0)

  const fetchData = useCallback(() => {
    const currentRequestId = ++latestRequestId.current
    const values = {
      query: initialQuery,
      originator: initialOriginator,
      followers: initialFollowerIds,
      supplier_ids: initialSupplierIds,
      doctor_ids: initialDoctorIds,
      deliveryDateStart: initialDeliveryDateStart,
      deliveryDateEnd: initialDeliveryDateEnd,
      sort: initialSort,
    }
    setLoading(true)
    debouncedGetClinicalFacilityDashboardResults(values)
      .then((results) => {
        if (currentRequestId === latestRequestId.current) {
          setInProgressOrders(results.inProgressOrders)
          setSubmittedOrders(results.submittedOrders)
          setDoneOrders(results.doneOrders)
          setUnreadEventCounts(results.unreadEventCounts)
          setLoading(false)
        }
      })
      .catch(() => {
        if (currentRequestId === latestRequestId.current) {
          setLoading(false)
        }
      })
  }, [
    debouncedGetClinicalFacilityDashboardResults,
    initialQuery,
    initialOriginator,
    initialFollowerIds,
    initialSupplierIds,
    initialDoctorIds,
    initialDeliveryDateStart,
    initialDeliveryDateEnd,
    initialSort,
  ])

  useEffect(() => {
    fetchData()
  }, [fetchData])

  const noOrdersWithSuperfilter =
    !loading &&
    selectedFocusTile &&
    !inProgressOrders.length &&
    !submittedOrders.length &&
    !doneOrders.length

  const emptyStateMessage =
    selectedFocusTile === "newMessages"
      ? "No orders at your facility have new messages."
      : selectedFocusTile === "incomingOrders"
      ? "There are no incoming orders at your facility."
      : selectedFocusTile === "sentBackForReview"
      ? "No orders at your facility have been sent back for review."
      : "" // This should never happen

  const handleSuperfilterClick = (
    selectedTile: "newMessages" | "incomingOrders" | "sentBackForReview",
    setSelectedTile: React.Dispatch<
      React.SetStateAction<
        "newMessages" | "incomingOrders" | "sentBackForReview" | null
      >
    >,
    fetchSubpageResults: () => Promise<any>
  ) => {
    setLoading(true)
    const currentRequestId = ++latestRequestId.current
    if (selectedFocusTile === selectedTile) {
      setSelectedTile(null)
      setHideFilters(false)
      fetchData()
    } else {
      setSelectedTile(selectedTile)
      setHideFilters(true)
      fetchSubpageResults().then(
        ({
          inProgressOrders,
          unreadEventCounts,
          submittedOrders,
          doneOrders,
        }) => {
          if (currentRequestId === latestRequestId.current) {
            setInProgressOrders(inProgressOrders)
            setUnreadEventCounts(unreadEventCounts)
            setSubmittedOrders(submittedOrders)
            setDoneOrders(doneOrders)
            setLoading(false)
          }
        }
      )
    }
  }

  const handleNewMessagesSuperfilterClick = () => {
    handleSuperfilterClick(
      "newMessages",
      setSelectedFocusTile,
      getClinicalFacilityDashboardNewMessagesFocusTileResults
    )
  }

  const handleIncomingOrdersSuperfilterClick = () => {
    handleSuperfilterClick(
      "incomingOrders",
      setSelectedFocusTile,
      getClinicalFacilityDashboardCreatedBySupplierFocusTileResults
    )
  }

  const handleSentBackForReviewSuperfilterClick = () => {
    handleSuperfilterClick(
      "sentBackForReview",
      setSelectedFocusTile,
      getClinicalFacilityDashboardSentBackForReviewFocusTileResults
    )
  }

  const EVENT_CATEGORY = EventCategory.Activation
  const EVENT_NAME = "click-activation-start-first-order-button"

  const onStartFirstOrderClick = () => {
    trackEvent(EVENT_CATEGORY, EVENT_NAME).then(() => {
      navigate(newOrderUrl())
    })
  }

  const showSignatureRequestsDashboardNewPill = currentUserEligibleForReviewerSignUp

  function onToggleExpand() {
    setInitialLeftSidebarExpanded(!initialLeftSidebarExpanded)
  }

  useEffectThatWontRunOnMount(() => {
    updateSidebarExpandedPreference(initialLeftSidebarExpanded)
  }, [initialLeftSidebarExpanded])

  return (
    <div className="position-relative">
      <LeftSidebar
        active={LeftSidebar.Options.Orders}
        sidebarInformation={sidebarInformation}
        showSignatureRequestsDashboardNewPill={
          showSignatureRequestsDashboardNewPill
        }
        featureFlags={{
          incomingOrdersEnabledSetting: !!incomingOrdersEnabledSetting,
          newPillIncomingOrders: !!newPillIncomingOrders,
        }}
        onToggleExpand={onToggleExpand}
        expandLeftSidebar={initialLeftSidebarExpanded}
      />
      <div className="row mt-n3">
        <div className="col-12 canopy-mbs-12x">
          {alerts.removedLastReviewerEmploymentDoctor && (
            <RemovedLastReviewerEmploymentAlert
              doctorName={alerts.removedLastReviewerEmploymentDoctor}
              showSignupLink={currentUserEligibleForReviewerSignUp}
            />
          )}
          <p
            className="canopy-typography-heading-xlarge"
            aria-label="facility name"
          >
            {clinicalFacility.name}
          </p>
          <FocusTiles
            clinicalFacilityId={clinicalFacility.externalId}
            handleNewMessagesSuperfilterClick={
              handleNewMessagesSuperfilterClick
            }
            handleIncomingOrdersSuperfilterClick={
              handleIncomingOrdersSuperfilterClick
            }
            handleSentBackForReviewSuperfilterClick={
              handleSentBackForReviewSuperfilterClick
            }
            selectedFocusTile={selectedFocusTile}
            incomingOrdersEnabled={sidebarInformation.incomingOrdersEnabled}
          />
          {!hideFilters && (
            <Filters
              loading={loading}
              initialQuery={initialQuery}
              setInitialQuery={setInitialQuery}
              followers={followers}
              suppliers={suppliers}
              doctors={doctors}
              initialOriginator={initialOriginator}
              setInitialOriginator={setInitialOriginator}
              initialFollowerIds={initialFollowerIds}
              setInitialFollowerIds={setInitialFollowerIds}
              initialDeliveryDateStart={initialDeliveryDateStart}
              setInitialDeliveryDateStart={setInitialDeliveryDateStart}
              initialDeliveryDateEnd={initialDeliveryDateEnd}
              setInitialDeliveryDateEnd={setInitialDeliveryDateEnd}
              initialSupplierIds={initialSupplierIds}
              setInitialSupplierIds={setInitialSupplierIds}
              initialDoctorIds={initialDoctorIds}
              setInitialDoctorIds={setInitialDoctorIds}
              initialSort={initialSort}
              setInitialSort={setInitialSort}
              searchFollowers={searchFollowers}
              searchSuppliers={searchSuppliers}
              searchDoctors={searchDoctors}
              onClear={() => {
                setInitialOriginator("")
                setInitialSupplierIds([])
                setInitialFollowerIds([])
                setInitialDeliveryDateStart("")
                setInitialDeliveryDateEnd("")
                setInitialQuery("")
                setInitialDoctorIds([])
              }}
            />
          )}
          {loading ? (
            <div className="text-center">
              <Icon type="spinner" spin />
            </div>
          ) : noOrdersWithSuperfilter ? (
            <div className="js-order-columns">
              <div className="row">
                <div className="col-lg-4 col-12">
                  <DashboardHeader
                    title="Orders in progress"
                    limit={limit}
                    count={inProgressOrders.length}
                  />
                </div>
                <div className="col-lg-4 col-12">
                  <DashboardHeader
                    title="Sent to supplier"
                    limit={limit}
                    count={submittedOrders.length}
                  />
                </div>
                <div className="col-lg-4 col-12">
                  <DashboardHeader
                    title="Accepted by supplier"
                    limit={limit}
                    count={doneOrders.length}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <div
                    className="d-flex flex-column align-items-center justify-content-center"
                    style={{ marginTop: "76px" }}
                  >
                    <CanopyIcon
                      className="canopy-mbe-16x"
                      fill="canopyColorBrandSecondary"
                      name="sparkles"
                      size="large"
                    />
                    <h4 className="canopy-typography-heading-2xlarge canopy-mbe-8x">
                      Your work here is done!
                    </h4>
                    <p className="canopy-typography-body-medium canopy-mbe-8x">
                      {emptyStateMessage}
                    </p>
                    <CanopyButton
                      onClick={() => {
                        setSelectedFocusTile(null)
                        fetchData()
                      }}
                      variant="secondary"
                    >
                      View all orders
                    </CanopyButton>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="js-order-columns">
              <div className="row">
                <div className="col-lg-4 col-12">
                  <DashboardHeader
                    title="Orders in progress"
                    limit={limit}
                    count={inProgressOrders.length}
                  />
                  {showStartFirstOrderCard && (
                    <StartFirstOrderCard onClick={onStartFirstOrderClick} />
                  )}
                  <DashboardColumn
                    dmeOrders={inProgressOrders}
                    clinicalFacility={clinicalFacility}
                    unreadEventCounts={unreadEventCounts}
                    limit={limit}
                    showProgress
                  />
                </div>
                <div className="col-lg-4 col-12">
                  <DashboardHeader
                    title="Sent to supplier"
                    limit={limit}
                    count={submittedOrders.length}
                  />
                  <DashboardColumn
                    dmeOrders={submittedOrders}
                    clinicalFacility={clinicalFacility}
                    unreadEventCounts={unreadEventCounts}
                    limit={limit}
                    showSwatch
                    showMissingDocument
                  />
                </div>
                <div className="col-lg-4 col-12">
                  <DashboardHeader
                    title="Accepted by supplier"
                    limit={limit}
                    count={doneOrders.length}
                  />
                  <DashboardColumn
                    dmeOrders={doneOrders}
                    clinicalFacility={clinicalFacility}
                    unreadEventCounts={unreadEventCounts}
                    limit={limit}
                    showSwatch
                  />
                </div>
              </div>
            </div>
          )}
          {showWelcomeModal && <WelcomeModal />}
          {showAdditionalFaxPrompt && (
            <AdditionalFaxModal facilityName={clinicalFacility.name} />
          )}
          {!showAdditionalFaxPrompt && showAdditionalFaxPromptV2 && (
            <AdditionalFaxModalV2 facilityName={clinicalFacility.name} />
          )}
        </div>
      </div>
    </div>
  )
}

InternalClinicalFacilityDashboard.defaultProps = {
  initialValues: {},
}

export default InternalClinicalFacilityDashboard
