import React from "react"
import { LineItemGroup } from "sharedTypes"

type Props = {
  lineItemGroups: LineItemGroup[]
}

const Cart = (props: Props) => {
  const { lineItemGroups } = props
  return (
    <div>
      <span className="bold color-black">Products</span>
      <ul>
        {lineItemGroups.map((lineItemGroup) =>
          lineItemGroup.lineItems.map((lineItem) => (
            <li className="ml-n3" key={lineItem.id}>
              <span className="bold color-black">
                {lineItem.skuDescription}
              </span>{" "}
              {lineItem.hcpcs && `- ${lineItem.hcpcs}`} -{" "}
              {`Quantity: ${lineItem.quantity}`}
            </li>
          ))
        )}
      </ul>
    </div>
  )
}

export default Cart
