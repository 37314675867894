import React from "react"
import Icon, { IconType } from "components/Icon"
import classNames from "classnames"
import { dash } from "utilities/string"
import { useStyles } from "themes/theme"

interface Props {
  currentTab?: { id: string }
  tabs: Tab[]
  onChange(tab): void
  children: React.ReactNode
}

interface Tab {
  icon: IconType
  label: string
  id: string
}
const tabStyles = (theme) => ({
  activeButton: {
    borderColor: theme.colors.gray.light + "!important",
  },
})
const Tab = (tab: Tab & { active: boolean; onClick }) => {
  const classes = useStyles(tabStyles)
  return (
    <button
      className={classNames(
        "btn btn-sm btn-gray font-subparagraph canopy-px-4x flex-1 canopy-mis-2x canopy-mie-2x",
        { [classes.activeButton]: tab.active },
        { active: tab.active }
      )}
      onClick={tab.onClick}
      data-analytics={`${dash(tab.label)}-tab-clicked`}
      key={tab.label}
    >
      <Icon className="canopy-mie-4x" type={tab.icon} />
      {tab.label}
    </button>
  )
}
function Tabs({ tabs, currentTab, onChange, children }: Props) {
  return (
    <>
      <div className="canopy-mbe-8x d-flex flex-wrap justify-content-space-between">
        {tabs.map((tab, idx) => (
          <Tab
            {...tab}
            active={currentTab?.id === tab.id}
            onClick={() => onChange(tab)}
            key={idx}
          />
        ))}
      </div>
      {children}
    </>
  )
}

export default Tabs
