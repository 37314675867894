import React from "react"
import { highlight } from "utilities/select"

export const formatSearchResults = (source: string, query: string) => {
  if (!query) {
    return <span>{source}</span>
  }

  const separator = " - "
  const separatorIndex = source.indexOf(separator)

  if (separatorIndex === -1) {
    return <span>{source}</span>
  }

  const packageName = source.substring(0, separatorIndex)
  const packageConfig = source.substring(separatorIndex + separator.length)

  const nodes: React.ReactNode[] = []

  nodes.push(
    <span key="left">{highlight(packageName, query).props.children}</span>
  )
  nodes.push(<span key="separator">{separator}</span>)

  if (packageConfig) {
    nodes.push(<span key="right">{highlight(packageConfig, query)}</span>)
  }

  return <span>{nodes}</span>
}
