// @ts-strict-ignore
import React, { useState } from "react"
import Thumbnail from "components/Thumbnail"
import { PackageWithCartData } from "../../sharedTypes"
import * as styles from "./index.module.scss"
import cx from "classnames"
import { CatalogBrowseTab, DmeOrder } from "sharedTypes"
import SupplierCount from "../SupplierCount/SupplierCount"

type Props = {
  currentCatalogBrowseTab: CatalogBrowseTab
  dmeOrder: DmeOrder
  onSelect(pkg: PackageWithCartData): Promise<void>
  pkg: PackageWithCartData
  yourOrganizationsSuppliersOnly: boolean
}

const enterAndSpaceHandler = (event, onClick) => {
  if (event.key === "Enter" || event.key === " ") {
    event.preventDefault()
    onClick()
  }
}

const GridItem = ({
  currentCatalogBrowseTab,
  dmeOrder,
  pkg,
  onSelect,
  yourOrganizationsSuppliersOnly,
}: Props) => {
  const {
    imageUrl,
    name,
    numberInCart,
    associatedSupplierCount,
    otherSupplierCount,
  } = pkg
  const [isSelecting, setSelecting] = useState(false)
  const flexibleSupplierAccess = dmeOrder.clinicalFacility.oneTimeOrderEnabled
  const shouldShowOtherSuppliers =
    currentCatalogBrowseTab === CatalogBrowseTab.Product &&
    flexibleSupplierAccess &&
    !dmeOrder.supplier
  const tileClasses =
    shouldShowOtherSuppliers && !yourOrganizationsSuppliersOnly
      ? cx("tile", styles.searchByProductTile, styles.tallTile)
      : cx("tile", styles.searchByProductTile)

  const onClick = () => {
    if (isSelecting) {
      return
    }

    setSelecting(true)
    onSelect(pkg)
      .then(() => setSelecting(false))
      .catch(() => setSelecting(false))
  }

  return (
    <div
      onClick={onClick}
      className={cx("col-lg-4", "col-md-6", "col-6")}
      tabIndex={0}
      role="button"
      onKeyDown={(event) => enterAndSpaceHandler(event, onClick)}
    >
      <div className={tileClasses}>
        <Thumbnail src={imageUrl} centered />
        <h5>{name}</h5>
        {numberInCart > 0 && (
          <span className="pill">{numberInCart} in cart</span>
        )}
        <div className={styles.supplierLabels}>
          {shouldShowOtherSuppliers && (
            <SupplierCount
              count={associatedSupplierCount}
              supplierType="Your Organization's Suppliers"
              shouldRenderPlaceholder={
                shouldShowOtherSuppliers && associatedSupplierCount === 0
              }
            />
          )}
          {shouldShowOtherSuppliers && !yourOrganizationsSuppliersOnly && (
            <SupplierCount
              count={otherSupplierCount}
              supplierType="Other Suppliers"
              shouldRenderPlaceholder={otherSupplierCount === 0}
            />
          )}
        </div>
        <div className="overlay">
          <div className="circle-overlay">
            {numberInCart > 0 ? "Edit" : "Configure"}
          </div>
        </div>
      </div>
    </div>
  )
}

export default GridItem
