import React from "react"
import { DmeOrder } from "sharedTypes"
import FulfillmentForm, { FormData } from "./FulfillmentForm"

type Props = {
  dmeOrder: DmeOrder
  updateFulfillment(id: string, values: FormData): Promise<void>
}

export default (props: Props) => {
  const { dmeOrder, updateFulfillment } = props

  const deliveryAddressFor = ({ deliveryAddressId }) => {
    return dmeOrder.addresses.find((a) => a.id === deliveryAddressId) || null
  }

  return (
    <table className="table canopy-mbe-0">
      <thead>
        <tr>
          <th>Address</th>
          <th>Fulfillment Status</th>
          <th>Requested Date</th>
          <th>Expected Date</th>
          <th>Delivered Date</th>
        </tr>
      </thead>
      <tbody>
        {dmeOrder.fulfillments.map((fulfillment) => (
          <FulfillmentForm
            key={fulfillment.id}
            fulfillment={fulfillment}
            deliveryAddress={deliveryAddressFor(fulfillment)}
            updateFulfillment={(values) =>
              updateFulfillment(fulfillment.id, values)
            }
          />
        ))}
      </tbody>
    </table>
  )
}
