import React, { FC, useContext, useState } from "react"
import { ToastContext } from "components/Toaster"
import FormButton from "components/form/Button"

export class Success {
  type: "Success"
  constructor() {
    this.type = "Success"
  }
}
export class Failure {
  type: "Failure"
  message: string
  constructor(message: string) {
    this.type = "Failure"
    this.message = message
  }
}

export type Result = Success | Failure

function isSuccess(result: Result): result is Success {
  return result.type === "Success"
}

export type AsyncButtonState = "default" | "submitting" | "success" | "error"
export const AsyncButton: FC<{
  onClick: () => Promise<Result>
  onSuccess?: () => void
  Button?: React.FC<{
    disabled: boolean
    onClick: () => Promise<void>
  }>
  initialState?: AsyncButtonState
}> = ({
  onClick: propsOnClick,
  onSuccess = () => {},
  Button = FormButton,
  children,
  initialState = "default",
}) => {
  const { persistentAlert } = useContext(ToastContext)
  const [state, setState] = useState<AsyncButtonState>(initialState)
  const onClick = async () => {
    setState("submitting")
    const result = await propsOnClick()

    if (isSuccess(result)) {
      setState("success")
      onSuccess()
    } else {
      setState("error")
      persistentAlert({ message: result.message, severity: "error" })
    }
  }

  return (
    <Button
      disabled={state === "submitting" || state === "success"}
      onClick={onClick}
    >
      {children}
    </Button>
  )
}
