import React from "react"
import InternalCsrInboxStateAiDetails from "applications/CsrInboxStateAiDetails/components/InternalCsrInboxStateAiDetails"

type Props = {
  smartDocumentIntakeEnabled: boolean
  documentation: {
    title?: string
    summary?: string
    qualificationStatus?: "qualified" | "undetermined"
  }
}
const CsrInboxStateAiDetails = ({
  smartDocumentIntakeEnabled,
  documentation,
}: Props) => {
  if (!smartDocumentIntakeEnabled) {
    return null
  }

  return <InternalCsrInboxStateAiDetails {...documentation} />
}

export default CsrInboxStateAiDetails
