import React from "react"
import classNames from "classnames"
import * as styles from "./table.module.scss"
import MaterialTableCell from "@material-ui/core/TableCell"

interface Props<T> {
  record: T
  attr: string
  render?: (record: T) => any
  selected: boolean
  truncate?: boolean
}

const TableCell = <
  T extends { [k: string]: string | boolean | number | undefined }
>({
  record,
  attr,
  render,
  selected,
  truncate = true,
}: Props<T>) => {
  const tableCellContent = render ? render(record) : record[attr]?.toString()

  return (
    <MaterialTableCell className={classNames(styles.td, { selected })}>
      <div className={classNames({ [styles.truncate]: truncate })}>
        {tableCellContent}
      </div>
    </MaterialTableCell>
  )
}

export default TableCell
