import React from "react"
import TextField from "./TextField"
import { TextFieldType } from "components/form/sharedTypes"

interface Props {
  subtext?: string
  autoFocus?: boolean
  autoComplete?: string
  confirmation?: boolean
  unwrapped?: boolean
}

function PasswordField({
  subtext,
  autoFocus,
  autoComplete,
  confirmation,
  unwrapped,
}: Props) {
  return (
    <TextField
      label={confirmation ? "Confirm New Password" : "New Password"}
      name={confirmation ? "passwordConfirmation" : "password"}
      subtext={subtext}
      type={TextFieldType.Password}
      autoFocus={autoFocus}
      autoComplete={autoComplete}
      unwrapped={unwrapped}
    />
  )
}

export default PasswordField
