import React, { useState } from "react"
import Alert from "components/Alert"
import GoDigitalModal from "./GoDigitalModal"
import { Doctor } from "../sharedTypes"

type Props = {
  updateProfile(values): Promise<any>
  doctor: Doctor
}

const GoDigitalLink = ({ updateProfile, doctor }: Props) => {
  const [isModalVisible, setModalVisible] = useState(false)
  return (
    <div className="row">
      <div className="col-md-6 col-12">
        <Alert leftIcon status="info" onClick={() => setModalVisible(true)}>
          Did you know that you can save time by getting signature requests sent
          directly to your email or smartphone? <a>Sign Up</a>
        </Alert>
        {isModalVisible && (
          <GoDigitalModal
            doctor={doctor}
            updateProfile={updateProfile}
            onClose={() => setModalVisible(false)}
          />
        )}
      </div>
    </div>
  )
}

export default GoDigitalLink
