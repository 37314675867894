import React, { useState } from "react"
import { FulfillmentAgreement, SupplierFilter } from "../../sharedTypes"
import FulfillmentAgreementsTable from "../FulfillmentAgreementsTable"
import { useToaster } from "components/Toaster"
import { EventCategory, trackEvent } from "utilities/tracking"
import AddSupplierModal from "../AddSupplierModal"
import Header from "../Header"

import {
  createPinnedSupplier,
  destroyPinnedSupplier,
  updateFulfillmentAgreement,
} from "../../api"
import SuppliersTabs from "../SuppliersTabs"
import SortDescription from "../SortDescription"
import { handleError } from "utilities/error"
import { CreateRequestResponse } from "../AddSupplierModal/sharedTypes"
import { getAddSupplierMessage } from "../AddSupplierModal/utilities/message"

interface Props {
  facilityState: string
  fulfillmentAgreements: FulfillmentAgreement[]
  pinnedSupplierIds: string[]
}

const InternalManageSuppliers: React.FC<Props> = ({
  facilityState,
  fulfillmentAgreements: fulfillmentagreementsFromProps,
  pinnedSupplierIds,
}) => {
  const { persistentAlert } = useToaster()
  const [showModal, toggleModal] = useState(false)
  const [fulfillmentAgreements, setFulfillmentAgreements] = useState(
    fulfillmentagreementsFromProps
  )
  const [supplierFilter, setSupplierFilter] = useState<SupplierFilter>(
    SupplierFilter.Active
  )
  const openModal = () => toggleModal(true)
  const closeModal = () => toggleModal(false)

  const afterAddSupplier = ({
    fulfillmentAgreement,
  }: CreateRequestResponse) => {
    if (fulfillmentAgreement?.supplier?.name) {
      setFulfillmentAgreements([...fulfillmentAgreements, fulfillmentAgreement])
    }
    const message = getAddSupplierMessage({ fulfillmentAgreement })
    closeModal()
    persistentAlert({
      message,
      severity: "info",
      position: { vertical: "top", horizontal: "right" },
    })
    return Promise.resolve()
  }

  const updateFulfillmentAgreements = (params: {
    id: string
    active: boolean
  }): Promise<void> => {
    updateFulfillmentAgreement(params)
      .then((fulfillmentAgreement) => {
        const updatedFulfillmentAgreements = [...fulfillmentAgreements]
        const updatedIndex = updatedFulfillmentAgreements.findIndex(
          ({ id }) => id === fulfillmentAgreement.id
        )

        updatedFulfillmentAgreements[updatedIndex] = fulfillmentAgreement
        setFulfillmentAgreements(updatedFulfillmentAgreements)
      })
      .catch(handleError)
    return Promise.resolve()
  }

  const onAddSupplierClick = () => {
    trackEvent(EventCategory.AnySupplier, "manage-suppliers-cta")
    openModal()
  }

  const sortingBy =
    supplierFilter === SupplierFilter.Active
      ? "Supplier Name"
      : "Recent Orders (last 30 days)"

  return (
    <>
      <Header onAddSupplierClick={onAddSupplierClick} />
      <SuppliersTabs
        supplierFilter={supplierFilter}
        setSupplierFilter={setSupplierFilter}
      />
      <SortDescription sortingBy={sortingBy} />
      <FulfillmentAgreementsTable
        fulfillmentAgreements={fulfillmentAgreements}
        createPinnedSupplier={createPinnedSupplier}
        destroyPinnedSupplier={destroyPinnedSupplier}
        updateFulfillmentAgreements={updateFulfillmentAgreements}
        pinnedSupplierIds={pinnedSupplierIds}
        supplierFilter={supplierFilter}
      />
      <AddSupplierModal
        onCancel={closeModal}
        showModal={showModal}
        facilityState={facilityState}
        afterSubmit={afterAddSupplier}
      />
    </>
  )
}

export default InternalManageSuppliers
