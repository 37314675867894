import $ from "jquery"
import { setCurrentUrlWithQuery } from "utilities/url"
import uuid from "uuid/v4"

function getQuery(fields, unsavedAttributes) {
  if (Array.isArray(unsavedAttributes)) {
    unsavedAttributes.forEach((attribute) => {
      fields = fields.filter((item) => item.name !== attribute)
    })
  }
  return fields.map((item) => `${item.name}=${item.value}`).join("&")
}

export function submitRemoteForm(selector) {
  const $form = $(selector)
  const fields = $form.serializeArray()
  const unsavedAttributes = $form.data("unsaved-attributes")
  const query = getQuery(fields, unsavedAttributes)
  setCurrentUrlWithQuery(query)
  const id = uuid()
  $form.attr("request_id", id)
  $form.attr("action", location.pathname + `?request_id=${id}`)
  if ($form.data("remote")) {
    $form.trigger("submit.rails")
  } else {
    $form.submit()
  }
}
