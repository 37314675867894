// @ts-strict-ignore
import React from "react"
import { DmeOrder } from "sharedTypes"
import HowItWorks from "./HowItWorks"

type Props = {
  dmeOrder: DmeOrder
}

function OnScreenSignature({ dmeOrder }: Props) {
  const { doctor } = dmeOrder
  return (
    <HowItWorks type={HowItWorks.Type.SignOnScreen}>
      <p>
        This signature method allows {doctor.firstName} {doctor.lastName} to
        sign immediately by clicking “Sign and Send” on the review page.
      </p>
    </HowItWorks>
  )
}

export default OnScreenSignature
