import React from "react"
import Link from "./Link"

type Props = {
  loading: boolean
  onClick(): Promise<void>
  count: number
  totalCount: number
  clearFilters?(): void
}

const LoadMoreLink = ({
  loading,
  count,
  totalCount,
  onClick,
  clearFilters,
}: Props) => {
  if (loading) return null

  return (
    <div className="text-center">
      <div className="font-subparagraph color-dark-gray">
        Displaying {count} of {totalCount}
      </div>
      {count === 0 && clearFilters && (
        <div>
          <Link onClick={clearFilters} className="link link-sm nowrap">
            <small>Clear All Filters</small>
          </Link>
        </div>
      )}
      {count < totalCount && (
        <div>
          <Link className="link" onClick={onClick}>
            Load More Results
          </Link>
        </div>
      )}
    </div>
  )
}

export default LoadMoreLink
