import CmsDrawer from "../../../../components/CmsDrawer"
import React, { useContext } from "react"
import Form from "../../../../../../components/form/Form"
import { CatalogCustomAttribute } from "../../../../types/sharedTypes"
import { StaticFieldDefinition } from "../../../../types/form/sharedTypes"
import { generateInitialValues } from "../../../../utilities/form"
import FormSegment from "../../../../components/Formik/FormSegment"
import { CanopyButton } from "@parachutehealth/canopy-button"
import { updateCatalogAttribute } from "../../../../api/attributes"
import { NoticeContext } from "../../../../contexts/NoticeContext"
import { booleanRadioGroup } from "../../../../components/Formik/DynamicFormField"

type Props = {
  customAttribute: CatalogCustomAttribute
  open?: boolean
  onSave?: (attribute: CatalogCustomAttribute) => void
  onClose: () => void
}
const EditAttributeDrawer: React.FC<Props> = ({
  customAttribute,
  open = false,
  onClose,
  onSave,
}: Props): React.JSX.Element => {
  const { showNotice } = useContext(NoticeContext)
  const staticFields: StaticFieldDefinition[] = [
    { label: "Name", name: "name", type: "text", required: true },
    {
      label: "Caption",
      name: "selectionCaption",
      type: "text",
      optional: true,
    },
    {
      label: "Enable attribute auto-selection on optional products",
      name: "attributeAutoSelectionEnabled",
      type: "radio",
      options: booleanRadioGroup(),
      description:
        "When marked Yes, the attribute auto-selection will be enabled on the front end. This will only apply if this attribute is used on a product that is not required in a package. For required products, no auto-selection will occur.",
    },
  ]
  const initialValues = generateInitialValues(staticFields, customAttribute)

  const handleSubmit = async (
    params: Record<string, any>,
    { setErrors }: any
  ) => {
    const response = await updateCatalogAttribute(
      customAttribute.externalId,
      params
    )
    if (response.errors) {
      setErrors(response.errors)
    } else {
      onClose()
      showNotice(
        `Successfully updated ${response.customAttribute.name}`,
        "success"
      )
      if (onSave) onSave(response.customAttribute)
    }
  }

  return (
    <CmsDrawer open={open} title="Edit Attribute" onClose={onClose}>
      <Form initialValues={initialValues} onSubmit={handleSubmit}>
        {({ isSubmitting }) => (
          <>
            <FormSegment>{staticFields}</FormSegment>
            <div>
              <CanopyButton
                size="small"
                variant="primary"
                type="submit"
                loading={isSubmitting}
              >
                Save
              </CanopyButton>
              <CanopyButton
                className="canopy-mis-4x"
                size="small"
                variant="tertiary"
                disabled={isSubmitting}
                onClick={onClose}
              >
                Cancel
              </CanopyButton>
            </div>
          </>
        )}
      </Form>
    </CmsDrawer>
  )
}

export default EditAttributeDrawer
