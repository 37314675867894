import React from "react"
import DataRow from "components/DataRow"
import { RxDetailsOutput } from "sharedTypes"

interface Props {
  rxDetailsOutputs: RxDetailsOutput[]
}

const PrescriptionDetails = ({ rxDetailsOutputs }: Props) => {
  const list = (
    <ul className="list-dashed">
      {rxDetailsOutputs.map((output) => (
        <li key={output.label}>
          {output.label} - {output.value}
        </li>
      ))}
    </ul>
  )

  return (
    <DataRow
      className="font-xs canopy-mbe-0"
      label="Prescription Details"
      value={list}
    />
  )
}

export default PrescriptionDetails
