// @ts-strict-ignore
import React from "react"
import { Field } from "formik"
import SignaturePad from "components/SignaturePad"
import { getFieldError } from "../utilities"
import ErrorText from "../ErrorText"

type Props = {
  name: string
}

const SignaturePadField: React.FC<Props> = ({ name }) => {
  return (
    <Field name={name}>
      {({ form }) => {
        const { errors, touched } = form
        const error = getFieldError(errors, touched, name)
        return (
          <SignaturePad
            height={200}
            onChange={(base64DataUrl) => {
              form.setFieldValue(name, base64DataUrl || "")
            }}
            fieldError={error ? <ErrorText error={error} /> : null}
          />
        )
      }}
    </Field>
  )
}

export default SignaturePadField
