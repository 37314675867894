import React from "react"
import { Form } from "components/form"
import DatePickerField from "components/form/DatePickerField"
import { isDateValid } from "utilities/date"

type Props = {
  orderDate: string
  updateOrderDate(date: string): Promise<void>
}

export default function OrderDateForm({ orderDate, updateOrderDate }: Props) {
  return (
    <Form
      onSubmit={({ orderDate }) => updateOrderDate(orderDate)}
      initialValues={{ orderDate }}
      validate={({ orderDate }) =>
        isDateValid(orderDate) ? {} : { orderDate: "Invalid date" }
      }
      submit
      submitOnChange
    >
      <DatePickerField label="Order Date" name="orderDate" width="sm" />
    </Form>
  )
}
