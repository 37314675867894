import React, { useContext } from "react"
import { blurOnEnter } from "utilities/browser/event"
import Icon from "components/Icon"
import * as styles from "./index.module.scss"
import WorkflowPageContext from "context/WorkflowPage"
import { theme } from "themes/theme"

type Props = {
  updateFilterQuery(query: string): void
  filterQuery: string
}

const PackageFilter: React.FunctionComponent<Props> = ({
  updateFilterQuery,
  filterQuery,
}) => {
  const { isSidebarOpen } = useContext(WorkflowPageContext)
  const isMobile = window.innerWidth < theme.breakpoints.values.lg
  const inputClasses = () => {
    return `package-search form-control ${isMobile ? "d-block" : "d-lg-block"}`
  }
  const inputPlaceholder = () => {
    const shortPlaceholder = "Enter product name or ID..."
    return isMobile || isSidebarOpen
      ? shortPlaceholder
      : `${shortPlaceholder} (e.g., oxygen concentrator or E1390)`
  }

  return (
    <>
      <span
        className={styles.searchContainer}
        data-user-target-name="Search by Product search field"
      >
        <input
          className={inputClasses()}
          value={filterQuery}
          onChange={(event) => updateFilterQuery(event.target.value)}
          placeholder={inputPlaceholder()}
          onKeyUp={blurOnEnter}
        />
        <Icon className="form-control-feedback color-mid-gray" type="search" />
      </span>
    </>
  )
}
export default PackageFilter
