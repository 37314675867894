import React from "react"
import { confirm } from "./api"
import { reject } from "../AccessCodeLogin/api"
import { DigitalIdentification } from "./sharedTypes"
import { navigate } from "utilities/navigation"
import { handleError } from "utilities/error"
import DoctorDigitalIdentificationForm from "./components/DoctorDigitalIdentificationForm"
import { thankyouUrl } from "../AccessCodeLogin/urls"

type Props = {
  digitalIdentification: DigitalIdentification
}

const handleReject = (identityVerificationId) => {
  return reject(identityVerificationId)
    .then(() => navigate(thankyouUrl(identityVerificationId)))
    .catch(handleError)
}

const handleConfirm = (identityVerificationId, token) => {
  return confirm(identityVerificationId, token)
    .then((response) => navigate(response.redirectUrl))
    .catch((response) => handleError(response, response.error))
}

const DoctorSelfVerification = ({ digitalIdentification }: Props) => {
  return (
    <DoctorDigitalIdentificationForm
      digitalIdentification={digitalIdentification}
      reject={handleReject}
      confirm={handleConfirm}
    />
  )
}

export default DoctorSelfVerification
