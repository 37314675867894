import React from "react"
import {
  LineItemGroup,
  Employer,
  EmployerType,
  DmeOrder,
  RxDetailsOutput,
} from "sharedTypes"
import DataRow from "components/DataRow"
import LineItemDescription from "components/LineItemDescription"
import PrescriptionDetails from "./PrescriptionDetails"

type Props = {
  dmeOrder: DmeOrder
  lineItemGroup: LineItemGroup
  currentEmployer: Employer
  rxDetailsOutputs: RxDetailsOutput[]
  showFulfilledBy: boolean
}

const DocumentLineItemGroup = ({
  lineItemGroup,
  currentEmployer,
  dmeOrder,
  rxDetailsOutputs,
  showFulfilledBy,
}: Props) => {
  const lineItemGroupOutputs = rxDetailsOutputs.filter(
    (output) => output.lineItemGroupId === lineItemGroup.id
  )

  return (
    <div key={lineItemGroup.id} className="well well-shadow">
      <div className="image with-description">
        <img src={lineItemGroup.imageUrl || undefined} />
        <div className="content">
          <p className="bold font-xs">{lineItemGroup.packageName}</p>
          <ul className="canopy-pis-8x font-xs">
            {lineItemGroup.lineItems.map((lineItem) => (
              <li key={lineItem.id} className="canopy-mbe-2x">
                <LineItemDescription lineItem={lineItem} />
              </li>
            ))}
            <hr className="canopy-mbs-8x canopy-mbe-0" />
          </ul>
          {!!lineItemGroupOutputs.length && (
            <PrescriptionDetails
              rxDetailsOutputs={lineItemGroupOutputs}
              lineItemGroup={lineItemGroup}
              orderStatus={dmeOrder.orderStatus}
              employerType={currentEmployer.employerType}
              transferredOrderLocked={dmeOrder.transferredOrderLocked}
            />
          )}
          {currentEmployer.employerType === EmployerType.ClinicalFacility && (
            <DataRow
              className="font-xs canopy-mbe-0"
              label="Supplier"
              value={lineItemGroup.packageSupplier.name}
            />
          )}
          {currentEmployer.employerType === EmployerType.Supplier &&
            showFulfilledBy && (
              <DataRow
                className="font-xs canopy-mbe-0"
                label="Fulfilled by"
                value={dmeOrder.supplierLegalEntity.name}
              />
            )}
        </div>
      </div>
    </div>
  )
}

export default DocumentLineItemGroup
