import React from "react"
import * as styles from "./patient-card-header.module.scss"
import DriveFileRenameOutlined from "../../components/SupplierOrganizationDashboard/DriveFileRenameOutlinedIcon"

type Props = {
  firstName: string
  lastName: string
  dateOfBirth: string
  sex: string
}

const PatientCardHeader = (props: Props) => {
  const { firstName, lastName, dateOfBirth, sex } = props
  return (
    <div className={styles.headerContainer}>
      <div className={styles.headerTitle}>
        <DriveFileRenameOutlined
          fontSize="large"
          className={styles.signatureIcon}
        />
        <h3>Signature Requests</h3>
      </div>
      <div className={styles.headerPatientInfo}>
        <strong>
          {firstName} {lastName}
        </strong>
        , {dateOfBirth}, {sex}
      </div>
    </div>
  )
}

export default PatientCardHeader
