// @ts-strict-ignore
import $ from "jquery"
import { navigate } from "utilities/navigation"

function onLinkClick() {
  const href = $(this).attr("href")
  navigate(href)
}

$(document).on("click", ".js-link", onLinkClick)
