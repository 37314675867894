import React, { useContext } from "react"

const ClinicalFacilityContext = React.createContext<{
  clinicalFacilityId: string
}>({
  clinicalFacilityId: "",
})

export const ClinicalFacilityProvider = (props: {
  clinicalFacilityId: string
  children: any
}) => {
  return (
    <ClinicalFacilityContext.Provider
      value={{
        clinicalFacilityId: props.clinicalFacilityId,
      }}
    >
      {props.children}
    </ClinicalFacilityContext.Provider>
  )
}

export const useClinicalFacilityContext = (): {
  clinicalFacilityId: string
} => useContext(ClinicalFacilityContext)
