import { CanopyButton } from "@parachutehealth/canopy-button"
import classNames from "classnames"
import * as styles from "./index.module.scss"
import React, { MouseEventHandler } from "react"

interface Props {
  onClick: MouseEventHandler
}

export const NewOrderButton: React.FC<Props> = ({ onClick }) => (
  <>
    <CanopyButton
      accessibleText="New order"
      className={classNames("d-none d-md-flex", styles.button)}
      data-analytics="supplier_org__new_order_button"
      iconStart="plus"
      onClick={onClick}
      size="small"
      variant="primary"
    >
      New order
    </CanopyButton>
    <CanopyButton
      className={classNames("d-flex d-md-none", styles.button)}
      data-analytics="supplier_org__new_order_button"
      iconStart="plus"
      onClick={onClick}
      size="small"
      variant="primary"
    />
  </>
)

export default NewOrderButton
