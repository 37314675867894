import React, { CSSProperties } from "react"
import classNames from "classnames"
import Icon from "components/Icon"

type Props = {
  src: string | null
  className?: string
  centered?: boolean
  size?: "small" | null
  notice?: boolean
}

const Thumbnail: React.FunctionComponent<Props> = ({
  className,
  src,
  size,
  centered,
  notice,
}) => {
  const css = { backgroundImage: `url(${src})` }
  const iconCss: CSSProperties = { right: 5, bottom: 5, position: "absolute" }

  return (
    <div
      className={classNames("image thumbnail", className, size, { centered })}
      style={css}
    >
      {notice && (
        <Icon
          type="info-circle"
          className="color-info"
          size="xs"
          style={iconCss}
        />
      )}
    </div>
  )
}

export default Thumbnail
