import toPlural from "pluralize"
import { camelCase, startCase } from "lodash"

const ACRONYMS: { [key: string]: string } = {
  sms: "SMS",
  "eFaxed to Supplier": "eFaxed to supplier",
}

export function sentenceCase(string: string): string {
  if (!string) return ""
  return (
    ACRONYMS[string] ||
    string.charAt(0).toUpperCase() + string.slice(1).toLowerCase()
  )
}

export function capitalize(string?: string): string {
  if (!string) {
    return ""
  }

  return ACRONYMS[string] || string.charAt(0).toUpperCase() + string.slice(1)
}

export function titlecase(string?: string): string {
  if (!string) {
    return ""
  }

  return startCase(string.toLowerCase())
}

export function truncate(string: string, limit: number): string {
  if (string.length <= limit) {
    return string
  }

  return `${string.substr(0, limit)}...`
}

export function lowercase(string: string): string {
  if (!string) {
    return ""
  }

  return string.toLowerCase()
}

export function underscore(string?: string): string {
  if (!string) {
    return ""
  }
  return string
    .replace(/([a-z\d])([A-Z]+)/g, "$1_$2")
    .replace(/[-\s]+/g, "_")
    .toLowerCase()
}

export function dash(string?: string): string {
  if (!string) {
    return ""
  }
  return string
    .replace(/([a-z\d])([A-Z]+)/g, "$1-$2")
    .replace(/[_\s]+/g, "-")
    .toLowerCase()
}

export function pluralize(word: string, count: number): string {
  if (!word) {
    return ""
  }
  if (/\s?SKU$/.exec(word)) {
    return count === 1 ? word : `${word}s`
  }
  return toPlural(word, count)
}

export function titleize(word: string): string {
  return capitalize(word).replace(/_/g, " ")
}

export function quote(string?: string): string {
  if (!string) {
    return ""
  }

  return `“${string}”`
}

export function classify(string: string): string {
  return capitalize(camelCase(string))
}

export function slugify(string: string): string {
  return string
    .toString()
    .toLowerCase()
    .replace(/\s+/g, "_") // Replace spaces with -
    .replace(/&/g, "_and_") // Replace & with 'and'
    .replace(/[^\w\-]+/g, "") // Remove all non-word characters
    .replace(/\_\_+/g, "_") // Replace multiple - with single -
    .replace(/^-+/, "") // Trim - from start of text
    .replace(/-+$/, "") // Trim - from end of text
}

export function splitLines(string?: string): string[] {
  if (!string) {
    return []
  }
  return string.split(/[\r\n]+/g)
}
export function replaceUnderscores(string?: string): string {
  if (!string) {
    return ""
  }
  return string.replace(/_/g, " ")
}
