import { post } from "services/api"
import { invitationOptInPath } from "./urls"

export type ReviewerParams = {
  reviewer: {
    email: string
    firstName: string
    lastName: string
  }
}

export type DoctorParams = {
  doctor: {
    id: string
  }
}

export type OptInParams = ReviewerParams & DoctorParams

export function invitationOptIn(
  params: OptInParams
): Promise<{ path: string }> {
  return post(invitationOptInPath, params)
    .then(({ data }) => data)
    .catch(({ response }) => Promise.reject(response.data))
}
