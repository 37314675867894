// @team @destroythefax

import React from "react"
import { trackClick } from "applications/Workflow/containers/Review/components/Warnings/analytics"
import {
  incomingOrdersUrl,
  facilityDashboardSubpageUrl,
} from "applications/Workflow/urls"
import NonProdHeader from "applications/Workflow/containers/Navigation/components/TopNavigation/TopLeftNavButton/NonProdHeader/NonProdHeader"
import { CanopyButton } from "@parachutehealth/canopy-button"

interface Props {
  nonprodHeaderDetails?: string
  facilityWorkflowBackPath: string
}

const dictionary = {
  incoming_orders: "Incoming orders",
  created_by_supplier: "Incoming orders",
  sent_back_for_review: "Sent back for review",
  new_messages: "New messages",
}

const FacilityWorkflowBackLink: React.FC<Props> = ({
  nonprodHeaderDetails,
  facilityWorkflowBackPath,
}) => {
  const url =
    facilityWorkflowBackPath === "incoming_orders"
      ? incomingOrdersUrl
      : facilityDashboardSubpageUrl(facilityWorkflowBackPath)
  const stringifiedBacklink = dictionary[facilityWorkflowBackPath]

  return (
    <>
      <CanopyButton
        as="a"
        size="small"
        variant="tertiary"
        iconStart="arrow-left"
        href={url}
        onClick={() => trackClick(stringifiedBacklink)}
      >
        {stringifiedBacklink}
      </CanopyButton>
      <NonProdHeader nonprodHeaderDetails={nonprodHeaderDetails} />
    </>
  )
}

export default FacilityWorkflowBackLink
