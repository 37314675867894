// @ts-strict-ignore
import $ from "jquery"
import debounce from "debounce"
// @ts-ignore
const popoverAllowList = $.fn.tooltip.Constructor.Default.whiteList

popoverAllowList.table = []
popoverAllowList.thead = []
popoverAllowList.tbody = []
popoverAllowList.th = []
popoverAllowList.tr = []
popoverAllowList.td = []

function addBootstrapHandlers(context) {
  $(context).find('[data-toggle="tooltip"]').tooltip({ container: "body" })
  $(context).find('[data-toggle="popover"]').popover()
}

function removeBootstrapHandlers(context) {
  $(context).find('[data-toggle="tooltip"]').tooltip("dispose")
  $(context).find('[data-toggle="popover"]').popover("dispose")
}

function lockRadioInputs() {
  if ($(".js-radio-unlock input:checked").length === 0) {
    $(".js-radio-lock").prop("disabled", true)
  }
}

function onScroll() {
  const $node = $('[data-toggle="popover"]')
  if ($node.length > 0 && typeof $node.popover === "function") {
    $node.popover("hide")
  }
}

function addUIHandlers(context) {
  addBootstrapHandlers(context)
}

function removeUIHandlers(context) {
  removeBootstrapHandlers(context)
}

function onBootstrapModalShown() {
  addUIHandlers(this)
}

function onBootstrapModalHidden() {
  removeUIHandlers(this)
}

$(window)
  .on("scroll", debounce(onScroll, 500))
  .on("load", function () {
    addUIHandlers(document)
    lockRadioInputs()
  })

$(document)
  .on("show.bs.modal", ".modal", onBootstrapModalShown)
  .on("hidden.bs.modal", ".modal", onBootstrapModalHidden)
  .on("unload", function () {
    removeUIHandlers(document)
  })
  .on("ajaxComplete", function () {
    addUIHandlers(document)
    lockRadioInputs()
  })
