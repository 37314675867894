import React, { useState } from "react"

const Checkbox = ({ id, checked: initialChecked, label, onChange }) => {
  const [checked, setChecked] = useState(initialChecked)

  const handleChange = () => {
    const newChecked = !checked
    setChecked(newChecked)
    onChange(newChecked, label)
  }

  return (
    <div className="checkbox">
      <label>
        <input
          id={id}
          type="checkbox"
          value={label}
          checked={checked}
          onChange={handleChange}
        />
        {label}
      </label>
    </div>
  )
}

export default Checkbox
