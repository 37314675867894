import { get, post } from "services/api"
import * as urls from "applications/Workflow/urls"
import { EmploymentMention } from "sharedTypes"
import { SupplierCommentTemplate } from "./types"
import { useQuery } from "@tanstack/react-query"

enum CommentType {
  Internal = "internal",
  Supplier = "supplier",
  Global = "global",
}

export function getActivityData() {
  return get(urls.sidebarActivityUrl())
}

export function getEmploymentMentions(
  term: string,
  commentType: CommentType
): Promise<EmploymentMention[]> {
  return get(urls.employmentMentionsUrl(), {
    term,
    commentType: commentType,
  }).then(({ data }) => data)
}

export function createComment(data) {
  return post(urls.commentsUrl(), data)
}

export function createInternalComment(data) {
  return post(urls.internalCommentsUrl(), data)
}

export function createSupplierComment(data) {
  return post(urls.supplierCommentsUrl(), data)
}

export function getActiveUsers() {
  return get(urls.activeUsersUrl())
}

export const fetchSupplierCommentTemplates = (): Promise<
  SupplierCommentTemplate[]
> => get(urls.supplierCommentTemplatesUrl()).then(({ data }) => data)

export const useSupplierCommentTemplatesQuery = (supplierId: string) => {
  return useQuery({
    queryKey: ["supplierCommentTemplates", { supplierId }],
    queryFn: () => fetchSupplierCommentTemplates(),
  })
}
