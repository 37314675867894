import React, { useState } from "react"
import WelcomeMessage from "welcome-message.svg"
import { CanopyButton } from "@parachutehealth/canopy-button"
import AutomatedWelcomeMessageForm from "../AutomatedWelcomeMessageForm"
import { styleTemplateVariables } from "../helpers"
import { useSupplierSettingsQuery } from "../queries"
import { useUpdateAutomatedWelcomeMessage } from "../queries"

interface Props {
  supplierId: string
}

const AutomatedWelcomeMessageHero = ({
  showForm,
  description,
}: {
  showForm: () => void
  description: string
}) => {
  return (
    <div
      style={{ maxWidth: "60%", textAlign: "center" }}
      className="canopy-mx-auto"
    >
      <img
        src={WelcomeMessage}
        alt="Hands with text bubble saying welcome"
        className="canopy-m-auto"
        style={{ width: "144px", height: "144px" }}
      />
      <h1 className="canopy-m-auto">Send patients a welcome message</h1>

      <p className="canopy-mt-4x">{description}</p>
      <CanopyButton onClick={showForm}>Add Welcome Message</CanopyButton>
    </div>
  )
}

const AutomatedWelcomeMessage = ({ supplierId }: Props) => {
  const [showForm, setShowForm] = useState(false)

  const { data: supplierSettings } = useSupplierSettingsQuery(supplierId)

  const {
    mutateAsync: updateAutomatedWelcomeMessage,
  } = useUpdateAutomatedWelcomeMessage(supplierId)

  const initialAction = supplierSettings?.automatedWelcomeMessage
    ? "Edit"
    : "Add"

  const [action, setAction] = useState(initialAction)

  if (!supplierSettings) return null

  const description =
    "Send personalized, automated welcome messages to patients and set clear expectations. You could include (a) the steps in your process, (b) how they might reach out to you, or (c) what to do if they have questions."

  const deleteWelcomeMessage = () => updateAutomatedWelcomeMessage("")

  if (showForm) {
    return (
      <AutomatedWelcomeMessageForm
        action={action}
        supplierId={supplierId}
        supplierSettings={supplierSettings}
        hideForm={() => setShowForm(false)}
      />
    )
  }

  if (!showForm && supplierSettings.automatedWelcomeMessage) {
    return (
      <div>
        <div className="d-flex">
          <div>
            <p>{description}</p>
          </div>
        </div>

        <table style={{ tableLayout: "fixed" }} className="table table-hover">
          <thead>
            <tr>
              <th style={{ width: "20%" }}>Name</th>
              <th style={{ width: "68%" }}>Message</th>
              <th style={{ width: "12%" }}></th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <b>Welcome Message</b>
              </td>
              <td className="text-truncate">
                {styleTemplateVariables(
                  supplierSettings.automatedWelcomeMessage
                )}
              </td>
              <td>
                <button
                  onClick={() => {
                    setAction("Edit")
                    setShowForm(true)
                  }}
                  className="link"
                >
                  Edit
                </button>
                |
                <button onClick={deleteWelcomeMessage} className="link">
                  Delete
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    )
  }

  return (
    <AutomatedWelcomeMessageHero
      showForm={() => setShowForm(true)}
      description={description}
    />
  )
}

export default AutomatedWelcomeMessage
