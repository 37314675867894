import React from "react"
import { ChartNote } from "sharedTypes"
import Button from "components/form/Button"
import { EventCategory, trackEvent } from "utilities/tracking"
import { getTrackingStatus } from "./utilities"
import { Link } from "react-router-dom"

type Props = {
  chartNotes: ChartNote[]
  bounceToSection?: {
    shouldBounce: boolean
    triggerBounce: () => void
  }
  pathToFirstIncompleteDocumentation: string
}
const EVENT_PREFIX = "documentation-requirement-build"

const GenerateButton = ({
  chartNotes,
  bounceToSection,
  pathToFirstIncompleteDocumentation,
}: Props) => {
  const { shouldBounce, triggerBounce } = bounceToSection || {
    triggerBounce: () => {},
    shouldBounce: false,
  }

  const trackEventSuffix = getTrackingStatus(
    chartNotes.map((chartNote) => chartNote.status)
  )

  const clickTrackEvent = () => {
    trackEventSuffix &&
      trackEvent(
        EventCategory.Activation,
        `${EVENT_PREFIX}-${trackEventSuffix}`
      )
  }

  if (shouldBounce) {
    return (
      <Button
        className="btn btn-block btn-brand"
        onClick={() => triggerBounce()}
      >
        Build documents with Parachute
      </Button>
    )
  }
  return (
    <Link
      className="btn btn-block btn-brand"
      onClick={() => clickTrackEvent()}
      to={pathToFirstIncompleteDocumentation}
    >
      Build documents with Parachute
    </Link>
  )
}

export default GenerateButton
