import React, { useContext } from "react"
import * as styles from "./index.module.scss"
import { productsPath } from "../../../routes"
import { Link } from "react-router-dom"
import WorkflowPage from "context/WorkflowPage"
import classNames from "classnames"
import { EFaxSupplier, Supplier } from "../sharedTypes"

interface Props {
  compact?: boolean
  supplier: Supplier | EFaxSupplier
}

const SupplierCard: React.FC<Props> = ({ children, compact, supplier }) => {
  const { isSidebarOpen } = useContext(WorkflowPage) || { isSidebarOpen: false }

  const gridColumnContainerStyles = () => {
    if (!isSidebarOpen) {
      return "col-xl-4 col-md-6"
    }

    return "col-12 col-xl-6"
  }

  return (
    <div className={gridColumnContainerStyles()}>
      <Link
        to={productsPath(supplier.externalId)}
        className={classNames(styles.card, compact && styles.compactCard)}
      >
        {children}
      </Link>
    </div>
  )
}

export default SupplierCard
