export function toInches(feet, inches) {
  feet = Number(feet) || 0
  inches = Number(inches) || 0
  return feet > 0 ? feet * 12 + inches : inches
}

export function formatHeight(useFeetForHeight, heightInches) {
  if (useFeetForHeight) {
    return `${Math.floor(heightInches / 12)} ft ${heightInches % 12} in`
  }
  return `${heightInches} in`
}
