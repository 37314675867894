// extracted by mini-css-extract-plugin
var _1 = "app-javascript-applications-Cms-pages-SeedsWorkflow-components-SeedDetailsPage-index-module__allSeeds--KyCl2";
var _2 = "app-javascript-applications-Cms-pages-SeedsWorkflow-components-SeedDetailsPage-index-module__archivable--gVapB";
var _3 = "app-javascript-applications-Cms-pages-SeedsWorkflow-components-SeedDetailsPage-index-module__archiveIcon--qv6p9";
var _4 = "app-javascript-applications-Cms-pages-SeedsWorkflow-components-SeedDetailsPage-index-module__auditIcon--nwbdv";
var _5 = "app-javascript-applications-Cms-pages-SeedsWorkflow-components-SeedDetailsPage-index-module__backBtn--OBABA";
var _6 = "app-javascript-applications-Cms-pages-SeedsWorkflow-components-SeedDetailsPage-index-module__headerContainer--tt54u";
var _7 = "app-javascript-applications-Cms-pages-SeedsWorkflow-components-SeedDetailsPage-index-module__internalNotesContainer--auWCs";
var _8 = "app-javascript-applications-Cms-pages-SeedsWorkflow-components-SeedDetailsPage-index-module__leftHeader--eFZyC";
var _9 = "app-javascript-applications-Cms-pages-SeedsWorkflow-components-SeedDetailsPage-index-module__link--iq1kQ";
var _a = "app-javascript-applications-Cms-pages-SeedsWorkflow-components-SeedDetailsPage-index-module__mainContent--UILS4";
var _b = "app-javascript-applications-Cms-pages-SeedsWorkflow-components-SeedDetailsPage-index-module__menuItem--jUH7r";
var _c = "app-javascript-applications-Cms-pages-SeedsWorkflow-components-SeedDetailsPage-index-module__menuText--jUI1K";
var _d = "app-javascript-applications-Cms-pages-SeedsWorkflow-components-SeedDetailsPage-index-module__rightHeader--yfZyG";
export { _1 as "allSeeds", _2 as "archivable", _3 as "archiveIcon", _4 as "auditIcon", _5 as "backBtn", _6 as "headerContainer", _7 as "internalNotesContainer", _8 as "leftHeader", _9 as "link", _a as "mainContent", _b as "menuItem", _c as "menuText", _d as "rightHeader" }
