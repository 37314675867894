import { QuickListProductVariation } from "./sharedTypes"
import { lowercase } from "utilities/string"

const queryMatch = (
  productVariation: QuickListProductVariation,
  query: string
): boolean => {
  if (!query) return true
  return lowercase(productVariation.description).includes(lowercase(query))
}

export const filterProductVariations = (
  productVariations: QuickListProductVariation[],
  query: string
): QuickListProductVariation[] => {
  return productVariations.filter((pv: QuickListProductVariation) =>
    queryMatch(pv, query)
  )
}

export const trimmedProductVariationName = (
  productVariationName: string,
  packageName: string
): string => {
  try {
    const re = RegExp(`^${packageName} *,? *`)
    const result = productVariationName.replace(re, "")
    if (result) {
      return result
    }

    return productVariationName
  } catch (e) {
    return productVariationName
  }
}

export const sortValue = (v1, v2): number => {
  if (v1 > v2) {
    return 1
  }
  if (v1 < v2) {
    return -1
  }
  return 0
}

interface ItemWithExternalId {
  externalId: string
}
export const findItemWithExternalId = (
  collection: ItemWithExternalId[],
  id: string
): ItemWithExternalId | {} => {
  return collection.find(({ externalId }) => externalId === id) || {}
}
