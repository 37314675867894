// @ts-strict-ignore
import React from "react"

interface ApiContextInterface {
  updateAssignee: (
    id: string,
    params: { assigneeId: string }
  ) => Promise<{ name: string; id: string }>
  deleteAssignee: (id: string) => Promise<null>
  getAssignees: (term: string) => Promise<null>
  getAuthorizations: (filters: {}) => Promise<{
    counts: {}
    filters: {}
    records: []
  }>
  getMembers: (
    term: string
  ) => Promise<{ results: { memberId: string; text: string }[] }>
  getCarrierAuthorizationNumbers: (
    term: string
  ) => Promise<{ results: { authId: string; text: string }[] }>
  updateStatus: (
    id: string,
    params: { status: string }
  ) => Promise<{ status: string }>
  authorizationUrl: (id: string) => string
}

export const ApiContext = React.createContext<ApiContextInterface>(undefined)
