import $ from "jquery"
import { handleRemoteFormChange } from "./form"

$(document)
  .on("click", ".js-radio.js-submit label", function (event) {
    const $input = $(this).prev("input")
    if ($input.is(":checked")) {
      event.preventDefault()
      $input.prop("checked", null)
    }
    handleRemoteFormChange()
  })
  .on("click", ".js-radio-unlock", function () {
    $(".js-radio-lock").attr("disabled", null)
  })
