import React from "react"
import InternalForgotPassword from "./components/InternalForgotPassword"
import { sendPasswordResetInstructions } from "./api"

function ForgotPassword() {
  return (
    <InternalForgotPassword
      sendPasswordResetInstructions={sendPasswordResetInstructions}
    />
  )
}

export default ForgotPassword
