import React, { FC } from "react"
import { AsyncButton, Failure, Result, Success } from "components/AsyncButton"
import { approveOrders } from "../../DoctorDashboard/api"
import { redirectToDoctorsDashboard } from "./redirectToDoctorsDashboard"
import { EventCategory, trackEvent } from "utilities/tracking"

export const APPROVE_ORDER_EVENT_NAME = "doctor_dash_approve_single_order"

export const ApproveOrderButton: FC<{
  signatureRequestId: string
  doctorId: string
}> = (props) => {
  const onClick = (): Promise<Result> => {
    trackEvent(EventCategory.Resupply, APPROVE_ORDER_EVENT_NAME)
    return approveOrders([], [props.signatureRequestId], props.doctorId)
      .then(() => new Success())
      .catch(
        () =>
          new Failure(
            'Sorry, there was a problem on our end. Try again, or use the "Having trouble?" in the bottom right corner'
          )
      )
  }
  return (
    <AsyncButton
      onClick={onClick}
      onSuccess={() => redirectToDoctorsDashboard(props.doctorId)}
    >
      Approve Order
    </AsyncButton>
  )
}
