import React from "react"
import SelectFilter from "components/SelectFilter"

interface Props {
  doctors: { doctorId: string; firstName: string; lastName: string }[]
  fetchClinicians(term: string): Promise<{ label: string; value: string }[]>
  onChange(values: { doctorIds: string[] }): void
  initialDoctorIds: string[]
}

function ClinicianSelect({
  doctors,
  fetchClinicians,
  onChange,
  initialDoctorIds,
}: Props) {
  const options = doctors.map((doctor) => {
    return {
      label: `${doctor.firstName} ${doctor.lastName}`,
      value: doctor.doctorId,
    }
  })

  return (
    <SelectFilter
      name="doctorIds"
      label="Clinicians"
      initialValues={initialDoctorIds}
      options={options}
      fetchOptions={fetchClinicians}
      onChange={onChange}
      minLength={0}
      enableReinitialize
    />
  )
}

export default ClinicianSelect
