import React from "react"

export const styleTemplateVariables = (template: string) => {
  const regex = /({Patient First Name}|{Patient Full Name}|{Supplier Name})/g

  const parts = template.split(regex)

  return (
    <>
      {parts.map((part, index) => (
        <React.Fragment key={index}>
          {part.match(regex) ? (
            <span style={{ backgroundColor: "#eee6fa" }}>
              {part.replace(/[{}]/g, "")}
            </span>
          ) : (
            part
          )}
        </React.Fragment>
      ))}
    </>
  )
}
