import React, { useContext } from "react"
import ContentArea from "../../../../components/ContentArea"
import { Form } from "../../../../../../components/form"
import { FormikProps } from "formik"
import { CanopyTextInputField } from "@parachutehealth/canopy-text-input-field"
import { stringifyErrors } from "../../../../utilities/form"
import { CanopyCheckboxInput } from "@parachutehealth/canopy-checkbox-input"
import { CanopyFormFieldGroup } from "@parachutehealth/canopy-form-field-group"
import { CanopyButton } from "@parachutehealth/canopy-button"
import {
  consignmentClosetDetailUrl,
  consignmentClosetsUrl,
} from "../../../../urls/consignmentClosets"
import { RouterChildContext, useHistory } from "react-router-dom"
import FetchingCombobox from "../../../../components/FetchingCombobox"
import {
  createConsignmentCloset,
  getConsignmentClosetEligibleSuppliers,
  getFacilitiesForSupplier,
} from "../../../../api/consignmentClosets"
import { NameAndId } from "../../../../types/sharedTypes"
import { isEmpty } from "lodash"
import { NoticeContext } from "../../../../contexts/NoticeContext"
import { isNullOrUndefined } from "../../../../../../utilities/isNullOrUndefined"

const NewConsignmentClosetForm: React.FC = (): React.JSX.Element => {
  const { showNotice } = useContext(NoticeContext)

  const initialValues: Record<string, any> = {
    name: "",
    floor: "",
    active: "true",
    supplierId: "",
    clinicalFacilityId: "",
  }

  const history: RouterChildContext["router"]["history"] = useHistory()

  const selectedSupplierId = React.useRef<number | undefined>(undefined)

  const onCancel = (): void => {
    history.push(consignmentClosetsUrl())
  }

  const handleSubmit = async (
    params: Record<string, any>,
    { setErrors }: any
  ) => {
    await createConsignmentCloset(params)
      .then((response) => {
        if (response.consignmentCloset) {
          history.push(
            consignmentClosetDetailUrl(response.consignmentCloset.externalId)
          )
        } else {
          history.push(consignmentClosetsUrl())
        }

        showNotice(
          response.message ||
            `You've successfully created the closet ${response.consignmentCloset.name}`,
          "success",
          [],
          true
        )
      })
      .catch((error) => {
        showNotice(
          error.response?.data?.message || "Something went wrong",
          "error"
        )
        setErrors(error.response?.data?.errors)
      })
  }

  const fetchSuppliers = async (searchString: string): Promise<NameAndId[]> => {
    if (isEmpty(searchString?.trim())) return []

    return await getConsignmentClosetEligibleSuppliers({
      searchKeywords: searchString,
    })
  }

  const fetchClinicalFacilities = async (
    supplierId: number,
    searchString: string
  ): Promise<NameAndId[]> => {
    if (isEmpty(searchString.trim())) return []

    return await getFacilitiesForSupplier(supplierId, {
      searchKeywords: searchString,
    })
  }

  return (
    <>
      <h2 className="canopy-typography-heading-xlarge">Create Closet</h2>
      <ContentArea variant="medium">
        <Form
          enableReinitialize
          initialValues={initialValues}
          onSubmit={handleSubmit}
        >
          {(formik: FormikProps<any>) => {
            return (
              <>
                <FetchingCombobox<NameAndId>
                  className="canopy-mbe-8x"
                  label="Supplier"
                  description="Only suppliers with a fulfillment agreement with a clinical facility can have closets"
                  required
                  id="supplierId"
                  onSelection={(newValue: NameAndId[]) => {
                    formik.setFieldValue("supplierId", newValue.at(0)?.id)
                    selectedSupplierId.current = newValue.at(0)?.id
                  }}
                  fetchItems={fetchSuppliers}
                  getOptionLabel={(item: NameAndId) => item.name}
                  // rails sends back the error under "supplier" and not "supplierId"
                  feedbackMessage={stringifyErrors(formik.errors, "supplier")}
                />

                <FetchingCombobox<NameAndId>
                  className="canopy-mbe-8x"
                  label="Clinical Facility"
                  disabled={isNullOrUndefined(selectedSupplierId.current)} // the fetch list is dependent upon this value
                  required
                  id="clinicalFacilityId"
                  onSelection={(newValue: NameAndId[]) =>
                    formik.setFieldValue(
                      "clinicalFacilityId",
                      newValue.at(0)?.id
                    )
                  }
                  fetchItems={(search: string) =>
                    fetchClinicalFacilities(selectedSupplierId.current!, search)
                  }
                  getOptionLabel={(item: NameAndId) => item.name}
                  // rails sends back the error under "clinical_facility" and not "clinical_facility_id"
                  feedbackMessage={stringifyErrors(
                    formik.errors,
                    "clinicalFacility"
                  )}
                />

                <CanopyTextInputField
                  className="canopy-mbe-8x"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  name="name"
                  label="Closet name"
                  value={formik.values["name"]}
                  required
                  feedbackMessage={stringifyErrors(formik.errors, "name")}
                />

                <CanopyTextInputField
                  className="canopy-mbe-8x"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  name="floor"
                  label="Floor number"
                  value={formik.values["floor"]}
                  feedbackMessage={stringifyErrors(formik.errors, "floor")}
                />

                <CanopyFormFieldGroup
                  className="canopy-mbe-8x"
                  label="Active"
                  hiddenLabel
                >
                  {() => (
                    <>
                      <CanopyCheckboxInput
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        className="canopy-my-4x"
                        label="Active"
                        name="active"
                        value="true"
                        checked
                      />
                    </>
                  )}
                </CanopyFormFieldGroup>

                <CanopyButton
                  size="small"
                  variant="primary"
                  type="submit"
                  loading={formik.isSubmitting}
                >
                  Save
                </CanopyButton>
                <CanopyButton
                  className="canopy-mis-4x"
                  size="small"
                  variant="tertiary"
                  disabled={formik.isSubmitting}
                  onClick={onCancel}
                >
                  Cancel
                </CanopyButton>
              </>
            )
          }}
        </Form>
      </ContentArea>
    </>
  )
}

export default NewConsignmentClosetForm
