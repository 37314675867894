// @ts-strict-ignore
import $ from "jquery"
import { isEmpty } from "utilities/validation"
import { isDateValid } from "utilities/date"
import { submitRemoteForm } from "utilities/rails"
import { setCurrentUrlWithQuery } from "utilities/url"

function showLoader(node) {
  const $node = $(node)
  const cols = $node.find("thead th").length
  if (cols) {
    $node
      .find(".js-tbody")
      .html(
        '<tr class="loader"><td colspan="' +
          cols +
          '"><span class="fas fa-spinner fa-spin"></span></td></tr>'
      )
    $node.find(".js-pagination").empty()
  } else {
    $node.html(
      '<div class="loader"><div class="fas fa-spinner fa-spin"></div></div>'
    )
  }
}

export function isRemoteFormValid(form) {
  const fields = form.find("[data-validation], .flatpickr-input")
  const results = $.map(fields, function (field) {
    if (
      field.getAttribute("data-validation") === "date" ||
      field.placeholder === "MM/DD/YYYY"
    ) {
      return isEmpty(field.value) || isDateValid(field.value)
    }
    return true
  })

  return results.indexOf(false) === -1
}

export function handleRemoteFormChange(): void {
  const form = $(".js-form")
  if (!isRemoteFormValid(form)) {
    return
  }

  showLoader(".js-table-container, .js-dashboard")
  setTimeout(function () {
    submitRemoteForm(".js-form")
  }, 50)
}

window.handleRemoteFormChange = handleRemoteFormChange

function checkRadios() {
  $(".form-group.radio_buttons .radio input:checked").each(function () {
    $(this).closest(".radio").addClass("radio-selected")
  })
}

function checkForm() {
  const $node = $(".js-form")
  if ($node.length === 0) {
    return null
  }
  const query = $node.serialize()
  if (!query) {
    return null
  }
  setCurrentUrlWithQuery(query)
}

function checkLoadRemoteFormChange() {
  const $node = $(".js-load-remote-form-change")
  if ($node.length === 0) {
    return null
  }
  handleRemoteFormChange()
}

function handleAutosubmit(form) {
  const $form = $(form)
  if ($form.data("js-autosubmit-pending")) {
    return false
  }
  $form.data("js-autosubmit-pending", true)
  if ($form.data("remote")) {
    $form.trigger("submit.rails")
  } else {
    $form.submit()
  }
}

function onAjaxSuccess(event) {
  $(event.target).data("js-autosubmit-pending", false)
}

function onAjaxError(event) {
  $(event.target).data("js-autosubmit-pending", false)
}

function onFormSubmit() {
  $(this)
    .find('input[type="submit"]:not(.nonprocessing)')
    .each(function () {
      const submit = $(this)
      submit.prop("disabled", true)
      submit.val("Processing...")
    })
}

$(window).on("load", function () {
  checkRadios()
  checkForm()
  checkLoadRemoteFormChange()
})

$(document)
  .on("ajaxComplete", function () {
    checkRadios()
  })
  .on("click", ".form-group.radio_buttons .radio", function () {
    $(this).parent(".form-group").find(".radio").removeClass("radio-selected")
    $(this)
      .find("input")
      .prop("checked", true)
      .addClass("radio-selected")
      .trigger("change")
    $(this).addClass("radio-selected")
  })
  .on("change", ".js-autosubmit", function () {
    handleAutosubmit(this.form)
  })
  .on("keypress", ".js-autosubmit", function (event) {
    if (event.which === 13) {
      event.preventDefault()
      handleAutosubmit(this.form)
    }
  })
  .on("submit", ".simple_form", onFormSubmit)
  .on("ajax:send", ".simple_form", onFormSubmit)
  .on("ajax:success", ".simple_form", onAjaxSuccess)
  .on("ajax:error", ".simple_form", onAjaxError)
  .on("keypress", ".js-form", function (event) {
    if (event.which === 13) {
      event.preventDefault()
      handleRemoteFormChange()
    }
  })
