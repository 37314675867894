// extracted by mini-css-extract-plugin
var _1 = "app-javascript-applications-Cms-pages-ConsignmentClosetsWorkflow-components-ConsignmentClosetDetailsPage-index-module__actions--zGh_c";
var _2 = "app-javascript-applications-Cms-pages-ConsignmentClosetsWorkflow-components-ConsignmentClosetDetailsPage-index-module__allClosets--kwy4m";
var _3 = "app-javascript-applications-Cms-pages-ConsignmentClosetsWorkflow-components-ConsignmentClosetDetailsPage-index-module__backBtn--oX9kH";
var _4 = "app-javascript-applications-Cms-pages-ConsignmentClosetsWorkflow-components-ConsignmentClosetDetailsPage-index-module__duplicateButton--cu1fa";
var _5 = "app-javascript-applications-Cms-pages-ConsignmentClosetsWorkflow-components-ConsignmentClosetDetailsPage-index-module__header--QrKmO";
var _6 = "app-javascript-applications-Cms-pages-ConsignmentClosetsWorkflow-components-ConsignmentClosetDetailsPage-index-module__link--pAAG4";
var _7 = "app-javascript-applications-Cms-pages-ConsignmentClosetsWorkflow-components-ConsignmentClosetDetailsPage-index-module__packageSelect--xcZ7q";
var _8 = "app-javascript-applications-Cms-pages-ConsignmentClosetsWorkflow-components-ConsignmentClosetDetailsPage-index-module__subheading--we0hB";
export { _1 as "actions", _2 as "allClosets", _3 as "backBtn", _4 as "duplicateButton", _5 as "header", _6 as "link", _7 as "packageSelect", _8 as "subheading" }
