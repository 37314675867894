import React, { useState } from "react"
import { handleError } from "utilities/error"
import VerificationForm from "./VerificationForm"
import DoctorLogin from "./DoctorLogin"
import InvitationLogin from "./InvitationLogin"
import ResendPage from "./ResendPage"
import {
  DmeOrderInvitation,
  DoctorLoginCode,
  TemporaryAccessCode,
  UserProvider,
  UserProviderType,
} from "../sharedTypes"
import { basePath as signatureRequestsAccessBasePath } from "../../SigningSignatureRequestsAccess/urls"
import { navigate } from "utilities/navigation"
import { SupplierInformation } from "sharedTypes"
import Alert from "components/Alert"

enum Step {
  Verify,
  Resend,
  DoctorLogin,
  InvitationLogin,
  Canceled,
}

type Props = {
  accessCode: string
  requestCanceled: boolean
  supplierInformation?: SupplierInformation
  reviewerInformation?: {
    email: string
    firstName: string
    lastName: string
  }
  verifyCodes(
    accessCode: string,
    securityCode: string
  ): Promise<TemporaryAccessCode>
  resend(accessCode: string, securityCode: string): Promise<void>
  login(props): Promise<void>
  reject(identityVerificationId: string): Promise<void>
}

type Codes = {
  accessCode: string
  securityCode: string
}

const InternalAccessCodeLogin = ({
  accessCode,
  resend,
  verifyCodes,
  login,
  reject,
  requestCanceled,
  supplierInformation,
  reviewerInformation,
}: Props): JSX.Element => {
  const [codes, setCodes] = useState<Codes>({
    accessCode: "",
    securityCode: "",
  })
  const [step, setStep] = useState(
    requestCanceled ? Step.Canceled : Step.Verify
  )
  const [userProvider, setUserProvider] = useState<UserProvider>()
  const [channel, setChannel] = useState<string>()

  const verify = ({ accessCode, securityCode }: Codes): Promise<void> => {
    return verifyCodes(accessCode, securityCode).then((temporaryAccessCode) => {
      if (temporaryAccessCode.validForAuthentication) {
        setUserProvider(temporaryAccessCode.userProvider)
        setCodes({ accessCode, securityCode })
        switch (temporaryAccessCode.userProviderType) {
          case UserProviderType.DoctorLoginCode:
            setStep(Step.DoctorLogin)
            break
          case UserProviderType.SignableLoginCode:
            navigate(
              buildRedirectLocation(
                signatureRequestsAccessBasePath,
                reviewerInformation
              )
            )
            break
          case UserProviderType.DmeOrderInvitation:
            setStep(Step.InvitationLogin)
        }
      } else if (temporaryAccessCode.validForResend) {
        setCodes({ accessCode, securityCode })
        setChannel(temporaryAccessCode.channel)
        setStep(Step.Resend)
      }
    })
  }

  const buildRedirectLocation = (
    basePath: string,
    reviewerParams:
      | { email: string; firstName: string; lastName: string }
      | undefined
  ) => {
    let path = basePath
    if (reviewerParams?.email) {
      path += "?" + new URLSearchParams(location.search)
    }
    return path
  }

  const requestResend = () => {
    resend(codes.accessCode, codes.securityCode).catch(handleError)
  }

  const page = () => {
    switch (step) {
      case Step.Canceled:
        return (
          <Alert status="danger">
            The request associated with this access code is no longer valid.
          </Alert>
        )
      case Step.Verify:
        return (
          <VerificationForm
            initialAccessCode={accessCode}
            verify={verify}
            supplierInformation={supplierInformation}
          />
        )
      case Step.Resend:
        return (
          <ResendPage requestResend={requestResend} channel={channel ?? ""} />
        )
      case Step.DoctorLogin:
        return (
          <DoctorLogin
            accessCode={codes.accessCode}
            login={login}
            reject={reject}
            doctorLoginCode={userProvider as DoctorLoginCode}
            securityCode={codes.securityCode}
          />
        )
      case Step.InvitationLogin:
        return (
          <InvitationLogin
            accessCode={codes.accessCode}
            login={login}
            invitation={userProvider as DmeOrderInvitation}
            securityCode={codes.securityCode}
          />
        )
    }
  }

  return (
    <div className="onboarding-container">
      <div className="onboarding-contents">{page()}</div>
    </div>
  )
}

export default InternalAccessCodeLogin
