import { alert } from "utilities/alert"
import * as Sentry from "@sentry/react"
import { ApplicationError } from "sharedTypes"

const ERROR_TEXT = "Something went wrong. Please try again."

export function reportError(error: ApplicationError) {
  Sentry.captureException(error)
}

export function handleError(
  error?: ApplicationError,
  message: string = ERROR_TEXT
) {
  error = error || new Error(message)
  if (process.env.NODE_ENV === "development") {
    // tslint:disable
    console.error(error)
    // tslint:enable
  }

  reportError(error)
  alert(message)
}

export function sendError(message: string) {
  const error = new Error(message)
  if (process.env.NODE_ENV === "development") {
    // tslint:disable
    console.error(error)
    // tslint:enable
  }
  reportError(error)
}
