export enum PillStatus {
  Success = "success",
  Danger = "danger",
  Default = "",
}

export function getPillStatus({
  valid,
  touched,
}: {
  valid?: boolean
  touched?: boolean
}): PillStatus {
  if (valid) {
    return PillStatus.Success
  } else if (!touched) {
    return PillStatus.Default
  }
  return PillStatus.Danger
}
