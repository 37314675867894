import { get } from "services/api"
import { dmeOrderStatusUrl } from "./urls"
import { poll } from "utilities/poll"

export function pollDmeOrderStatus(token): Promise<{ dmeOrderPath: string }> {
  return new Promise((resolve, reject) => {
    poll(() => get(dmeOrderStatusUrl(token)), {
      delay: 1000,
      success: resolve,
      failure: reject,
      error: reject,
    })
  })
}
