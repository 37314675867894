export const fullName = (obj: { firstName: string; lastName: string }) =>
  obj ? `${obj.firstName} ${obj.lastName}` : ""

// should be in sync with /app/helpers/application_helper.rb
export function initials(name: string) {
  if (!name) {
    return ""
  }
  return name
    .split(/\s+/g)
    .map((phrase) => phrase[0].toUpperCase())
    .filter((letter) => /[a-zA-Z]/.exec(letter))
    .reduce((letters, letter) => {
      if (letters.length < 3) {
        letters += letter
      }
      return letters
    }, "")
}
