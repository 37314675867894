import { employerPrefix } from "utilities/url"

export const emailCumulativeReportUrl = () => {
  return `${employerPrefix()}/financials/cumulative_invoices.json`
}

export const cumulativeReportUrl = () => {
  return `${employerPrefix()}/financials/cumulative_invoices/new`
}

export const searchFacilitiesUrl = () => {
  return `${employerPrefix()}/clinical_facilities/search.json`
}

export const searchSuppliersUrl = () => {
  return `${employerPrefix()}/suppliers/search.json`
}
