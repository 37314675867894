import React from "react"
import { Patient } from "../../sharedTypes"
import { OptionItem } from "@parachutehealth/canopy-combobox-field"
import { format } from "utilities/date"
import * as styles from "./patient_suggestion.module.scss"

type Props = {
  patient: OptionItem & Patient
  query: string
}

const PatientSuggestion: React.FC<Props> = ({ patient, query }) => {
  const sex = patient.sex ? ` (${patient.sex.toUpperCase()[0]})` : ""
  const nameAndSex = `${patient.firstName} ${patient.lastName}${sex}`
  const dob = `DOB: ${format(patient.dateOfBirth)}`
  const mrn = `MRN: ${patient.mrn}`

  const highlightQuery = (text: string): JSX.Element => {
    if (text.toLowerCase().includes(query.toLowerCase())) {
      const startIndex = text.toLowerCase().indexOf(query.toLowerCase())
      const endIndex = startIndex + query.length
      return (
        <span aria-label={text}>
          {text.slice(0, startIndex)}
          <span className={styles.highlightedSuggestionText}>
            {text.slice(startIndex, endIndex)}
          </span>
          {text.slice(endIndex)}
        </span>
      )
    } else {
      return <>{text}</>
    }
  }

  return (
    <div>
      <span
        className="canopy-typography-font-weight-bold"
        role="note"
        aria-label="Patient Name and Sex"
      >
        <strong>{highlightQuery(nameAndSex)}</strong>
      </span>
      <br />
      <span role="note" aria-label="Patient Date of Birth">
        {dob}
      </span>
      <br />
      {patient.mrn && (
        <span role="note" aria-label="Patient MRN">
          {highlightQuery(mrn)}
        </span>
      )}
    </div>
  )
}

export default PatientSuggestion
