import React from "react"
import classNames from "classnames"
import * as styles from "./index.module.scss"
import Icon from "components/Icon"

type Props = {
  selected: boolean
}

const SuggestionIndicator = ({ selected }: Props) => (
  <div className={classNames(styles.icon, { [styles.selected]: selected })}>
    {selected && <Icon type="check-circle" />}
  </div>
)

export default SuggestionIndicator
