import React from "react"
import resolveOrder from "resolve-order.png"
import MarketingContent from "./MarketingContent"

const ResolveOrder: React.FC = () => (
  <MarketingContent
    title="Easily resolve orders"
    description="Fix order addendums through real-time, digital communication with your suppliers."
    imageSrc={resolveOrder}
    alt="Activity Feed between supplier and facility user"
  />
)

export default ResolveOrder
