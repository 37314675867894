// @ts-strict-ignore
import alertFavicon from "logo-alert.png"
import memoize from "lodash.memoize"

type Favicon = "default" | "alert"

const selector = "link[rel*='icon'][data-favicon='true']"

const getDefaultFavicon = memoize(() => {
  return document.querySelector(selector).getAttribute("href")
})

export const setFavicon = (type: Favicon) => {
  const defaultFavicon = getDefaultFavicon()
  const href = type === "alert" ? alertFavicon : defaultFavicon
  document.querySelector(selector).setAttribute("href", href)
}
