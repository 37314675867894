import { useEffect, useRef } from "react"

export const useEffectThatWontRunOnMount: typeof useEffect = (effect, deps) => {
  const mounted = useRef(false)
  useEffect(() => {
    if (mounted.current) {
      effect()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps)
  useEffect(() => {
    mounted.current = true
  }, [])
}
