import React, { useContext } from "react"
import classNames from "classnames"
import * as styles from "./index.module.scss"
import WorkflowPageContext from "context/WorkflowPage"

interface Props {
  oneTimeOrderEnabled: boolean
}

function Header({ oneTimeOrderEnabled }: Props) {
  const { isSidebarOpen } = useContext(WorkflowPageContext)
  const subheaderText = oneTimeOrderEnabled
    ? "Order from your organization's suppliers, or from any supplier on the Parachute Platform."
    : "Order from your organization's suppliers."

  return (
    <>
      <div
        className={classNames(styles.container, {
          [styles.isSidebarOpen]: isSidebarOpen,
        })}
      >
        <div className={styles.header}>
          <h1 className={styles.title}>Select Supplier</h1>
        </div>
      </div>
      <p>{subheaderText}</p>
    </>
  )
}

export default Header
