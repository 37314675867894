import React, { Dispatch, SetStateAction } from "react"
import SelectFilter from "components/SelectFilter"
import { EventCategory, trackEvent } from "utilities/tracking"

interface Props {
  selectedCategories: string[]
  categories: string[]
  setSelectedCategories: Dispatch<SetStateAction<string[]>>
}

const CategoriesFilter = ({
  selectedCategories,
  categories,
  setSelectedCategories,
}: Props) => {
  const categoryOptions = categories.map((categoryName) => ({
    label: categoryName,
    value: categoryName,
  }))
  const eventCategory = EventCategory.AnySupplier
  const onChange = async ({ categories }: { categories: string[] }) => {
    trackEvent(eventCategory, "selected-categories-changed")
    await setSelectedCategories(categories)
  }
  const onToggle = (value: boolean) => {
    if (value) {
      trackEvent(eventCategory, "selected-categories-clicked")
    }
  }
  return (
    <SelectFilter
      initialValues={selectedCategories}
      label="Categories"
      name="categories"
      onChange={onChange}
      onToggle={onToggle}
      options={categoryOptions}
      enableReinitialize
    />
  )
}

export default CategoriesFilter
