// @ts-strict-ignore
import React from "react"
import InternalClinicalFacilityDashboard from "./components/InternalClinicalFacilityDashboard"
import { ClinicalFacility, FeatureFlagInput, Follower } from "sharedTypes"
import {
  getClinicalFacilityDashboardResults,
  searchDoctors,
  searchFollowers,
  searchSuppliers,
  updateSidebarExpandedPreference,
} from "./api"
import { Filters } from "./sharedTypes"
import { Supplier } from "components/ClinicalFilters/sharedTypes"
import { FeatureFlagProvider } from "components/FeatureFlagContext"
import {
  SidebarListDoctor,
  SidebarListFacility,
} from "components/ClinicalDashboard/sharedTypes"

type Props = {
  clinicalFacility: ClinicalFacility
  limit: number
  unreadEventCounts: object
  initialValues: Filters
  followers: Follower[]
  suppliers: Supplier[]
  doctors: { doctorId: string; firstName: string; lastName: string }[]
  showStartFirstOrderCard?: boolean
  showWelcomeModal?: boolean
  showAdditionalFaxPrompt: boolean
  showAdditionalFaxPromptV2: boolean
  featureFlags: FeatureFlagInput
  alerts: {
    removedLastReviewerEmploymentDoctor?: string
  }
  sidebarInformation: {
    incomingOrdersEnabled: boolean
    isReviewer: boolean
    lastAccessedFacilityEmployerId?: string
    doctors?: SidebarListDoctor[]
    facilities?: SidebarListFacility[]
    lastAccessedDoctorEmployerId?: string
  }
  sidebarExpanded: boolean
  currentUserEligibleForReviewerSignUp?: boolean
}

function ClinicalFacilityDashboard({
  clinicalFacility,
  limit,
  unreadEventCounts,
  initialValues,
  followers,
  suppliers,
  doctors,
  showStartFirstOrderCard,
  showWelcomeModal,
  showAdditionalFaxPrompt,
  showAdditionalFaxPromptV2,
  featureFlags,
  alerts,
  sidebarInformation,
  sidebarExpanded,
  currentUserEligibleForReviewerSignUp,
}: Props) {
  return (
    <FeatureFlagProvider flags={featureFlags}>
      <InternalClinicalFacilityDashboard
        clinicalFacility={clinicalFacility}
        limit={limit}
        unreadEventCounts={unreadEventCounts}
        showStartFirstOrderCard={showStartFirstOrderCard}
        getClinicalFacilityDashboardResults={
          getClinicalFacilityDashboardResults
        }
        initialValues={initialValues}
        followers={followers}
        suppliers={suppliers}
        doctors={doctors}
        searchFollowers={searchFollowers}
        searchSuppliers={searchSuppliers}
        searchDoctors={searchDoctors}
        showWelcomeModal={showWelcomeModal}
        showAdditionalFaxPrompt={showAdditionalFaxPrompt}
        showAdditionalFaxPromptV2={showAdditionalFaxPromptV2}
        sidebarInformation={sidebarInformation}
        sidebarExpanded={sidebarExpanded}
        updateSidebarExpandedPreference={updateSidebarExpandedPreference}
        alerts={alerts}
        currentUserEligibleForReviewerSignUp={
          currentUserEligibleForReviewerSignUp
        }
      />
    </FeatureFlagProvider>
  )
}

ClinicalFacilityDashboard.defaultProps = {
  followers: [],
  suppliers: [],
  doctors: [],
}

export default ClinicalFacilityDashboard
