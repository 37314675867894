// @ts-strict-ignore
import React, { ReactElement } from "react"
import SalesRepPopover from "./Popover"
import { SalesRep } from "../../sharedTypes"

type Props = {
  id: string
  salesRep: SalesRep
  canUpdateSalesRep?: boolean
  updateSalesRep(id: string, { externalId }): Promise<SalesRep>
  getSalesReps(term: string): Promise<SalesRep[]>
}

const SalesRepEditor = ({
  id,
  salesRep,
  canUpdateSalesRep,
  updateSalesRep,
  getSalesReps,
}: Props): ReactElement => {
  const setSalesRep = ({ salesRepId }): Promise<SalesRep> => {
    if (salesRepId) {
      return updateSalesRep(id, { externalId: salesRepId })
    }
  }

  if (!canUpdateSalesRep) {
    return <>{salesRep?.name || "Unassigned"}</>
  }

  return (
    <SalesRepPopover
      salesRep={salesRep}
      setSalesRep={setSalesRep}
      getSalesReps={getSalesReps}
    />
  )
}

export default SalesRepEditor
