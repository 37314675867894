import { employerPrefix } from "utilities/url"

export const salesRepsUrl = (supplierId: string) => {
  return `${employerPrefix("Supplier", supplierId)}/facility_sign_up/sales_reps`
}

export const facilitiesUrl = (supplierId: string) => {
  return `${employerPrefix("Supplier", supplierId)}/facility_sign_up/facilities`
}

export const invitationUrl = (supplierId: string) => {
  return `${employerPrefix(
    "Supplier",
    supplierId
  )}/facility_sign_up/invitations`
}

export const supplierLegalEntitiesUrl = (supplierId: string) => {
  return `${employerPrefix("Supplier", supplierId)}/supplier_legal_entities`
}

export const branchesUrl = (supplierId: string) => {
  return `${employerPrefix("Supplier", supplierId)}/external_system_branches`
}
