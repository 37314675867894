import React from "react"

function Exhibit() {
  return (
    <>
      <h4>
        <u>EXHIBIT A</u>
      </h4>
      <p>Please provide a list of Facilities in the Network, where relevant.</p>
      <h4>
        <u>
          EXHIBIT B
          <br />
          BUSINESS ASSOCIATE AGREEMENT
        </u>
      </h4>
      This Business Associate Agreement (“Agreement”) is made as of the
      Effective Date by and between the Facility (“Covered Entity”) and
      <strong>PARACHUTE HEALTH, LLC</strong>(“Business Associate”).
      <h4>BACKGROUND</h4>
      <p>
        Covered Entity and Business Associate wish to enter into this Agreement
        for purposes of complying with the Health Insurance Portability and
        Accountability Act of 1996 (“HIPAA”), the Health Information Technology
        for Economic and Clinical Health Act (the “HITECH Act”), and the
        regulations promulgated thereunder; 45 C.F.R. Parts 160 and Part 164,
        Subparts A, C, D and E (collectively the “HIPAA Standards”). The
        provisions of this Agreement apply with respect to all Protected Health
        Information (“PHI”), as defined in 45 CFR § 160.103, created, received,
        maintained or transmitted by Business Associate in its representation of
        Covered Entity.
      </p>
      <p>
        WHEREAS, Covered Entity is a covered entity as such term is defined
        under HIPAA and as such is required to comply with the requirements
        thereof regarding the confidentiality and privacy of PHI; and
      </p>
      <p>
        WHEREAS, Business Associate has entered or may enter into an agreement
        or agreements with Covered Entity (“Service Agreement”), pursuant to
        which Business Associate will render services to, for, or on behalf of
        Covered Entity; and
      </p>
      <p>
        WHEREAS, by providing services pursuant to the Service Agreement,
        Business Associate shall become a business associate of Covered Entity,
        as such term is defined under HIPAA, and will therefore have obligations
        regarding the confidentiality and privacy of PHI that Business Associate
        creates for, or receives from or on behalf of, Covered Entity.
      </p>
      <h4>Terms</h4>In consideration of the mutual covenants contained herein,
      Business Associate and Covered Entity agree as follows:
      <ol>
        <li>
          <p>
            <u>Definitions.</u> For the purposes of this Agreement, capitalized
            terms shall have the meanings ascribed to them herein. All
            capitalized terms used but not otherwise defined herein will have
            the meaning ascribed to them by HIPAA
          </p>
        </li>
        <li>
          <u>Obligations of Business Associate.</u>
          <ol type="a">
            <li>
              <p>
                <u>Confidentiality.</u>
              </p>
            </li>
            <ol type="i">
              <li>
                <p>
                  Business Associate shall: (a) use or disclose PHI only in
                  connection with fulfilling its duties and obligations under
                  this Agreement and the Service Agreement; (b) shall not use or
                  disclose PHI other than as permitted or required by this
                  Agreement or required by law; and (c) shall not use or
                  disclose PHI in any manner that violates applicable federal
                  and state laws or would violate such laws if used or disclosed
                  in such manner by Covered Entity
                </p>
              </li>
              <li>
                <p>
                  Subject to the restrictions set forth in the previous
                  paragraph and throughout this Agreement, Business Associate
                  may use the information received from Covered Entity if
                  necessary for: (a) the proper management and administration of
                  Business Associate; (b) to carry out the legal
                  responsibilities of Business Associate; (c) to De-Identify PHI
                  as permitted by 45 C.F.R. § 164.502(d); and (d) for the
                  provision of Data Aggregation services to Covered Entity under
                  the terms of the Service Agreement, and as permitted by
                  45 C.F.R. § 164.504(e)(2)(i)(B).
                </p>
              </li>
              <li>
                <p>
                  Subject to the restrictions set forth in Section 2(a)(i) and
                  throughout this Agreement, Business Associate may disclose PHI
                  for the proper management and administration of Business
                  Associate, provided that:
                </p>
                <ol>
                  <li>
                    <p>Disclosures are required by law; or</p>
                  </li>
                  <li>
                    <p>
                      Business Associate obtains reasonable assurances from the
                      person or entity to whom the information is disclosed that
                      it will remain confidential and used or further disclosed
                      only as Required by Law or for the purpose for which it
                      was disclosed to the person or entity, and the person or
                      entity notifies the Business Associate of any instances of
                      which it is aware in which the confidentiality of the
                      information has been breached.
                    </p>
                  </li>
                </ol>
              </li>
              <li>
                <p>
                  To the extent the Business Associate is to carry out one or
                  more of Covered Entity’s obligations under Subpart E of 45 CFR
                  Part 164, Business Associate will comply with the requirements
                  of 45 CFR Part 164, Subpart E that apply to Covered Entity in
                  the performance of such obligations.
                </p>
              </li>
              <li>
                <p>
                  Business Associate will make a reasonable effort to limit the
                  use or disclosure of PHI to the minimum necessary to
                  accomplish the intended purpose of the use, disclosure, or
                  request, except where the minimum necessary standard, as
                  described in 45 CFR § 164.502(b), does not apply
                </p>
              </li>
            </ol>
            <li>
              <p>
                <u>Safeguards.</u> Business Associate will implement
                administrative, physical, and technical safeguards that
                reasonably and appropriately protect the confidentiality,
                integrity, and availability of the electronic PHI that it
                creates, receives, maintains, or transmits on behalf of Covered
                Entity as required by the HIPAA Standards, and to prevent use or
                disclosure of PHI other than as provided for by this Agreement.
                Business Associate will comply with Subpart C of 45 CFR Part 164
                with respect to electronic PHI.
              </p>
            </li>
            <li>
              <p>
                <u>Availability of Books and Records.</u> Business Associate
                will make books and records relating to the use and disclosure
                of PHI available to the Secretary of Health and Human Services
                (“Secretary”) or the Secretary’s designee, in a reasonable time
                and manner designated by the Secretary, for purposes of the
                Secretary determining Covered Entity’s compliance with the HIPAA
                Standards.
              </p>
            </li>
            <li>
              <p>
                <u>Individuals’ Rights to their PHI.</u>
              </p>
              <ol type="i">
                <li>
                  <p>
                    At Covered Entity’s request, Business Associate will make
                    available PHI in Business Associate’s possession to enable
                    Covered Entity to respond to a request by an individual for
                    access to PHI in accordance with 45 CFR § 164.524.
                  </p>
                </li>
                <li>
                  <p>
                    At Covered Entity’s request, Business Associate will make
                    available PHI in Business Associate’s possession for
                    amendment, and will incorporate any amendments to PHI, in
                    accordance with 45 CFR § 164.526.
                  </p>
                </li>
                <li>
                  <p>
                    Breachusiness Associate will maintain and will provide to
                    Covered Entity on request such documentation of disclosures
                    of PHI as would be required for Covered Entity to respond to
                    a request by an individual for an accounting of disclosures
                    of PHI in accordance with 45 CFR § 164.528. Upon receipt of
                    a request for an accounting directly from an individual,
                    Business Associate will forward such request to the Covered
                    Entity to allow the Covered Entity to provide the individual
                    an accounting of disclosures containing the information
                    described in 45 CFR § 164.528.
                  </p>
                </li>
              </ol>
            </li>
            <li>
              <p>
                <u>Disclosure to Third Parties.</u> Business Associate will
                ensure that any agent, including a subcontractor, that receives
                PHI from Business Associate, or creates, receives, maintains, or
                transmits PHI on behalf of Business Associate, agrees to
                substantially the same restrictions, conditions and requirements
                that apply to Business Associate with respect to such PHI.
              </p>
            </li>
            <li>
              <p>
                <u>Reporting Obligations:</u>
              </p>
              <ol type="i">
                <li>
                  Business Associate will notify Covered Entity of any Breach of
                  unsecured PHI, as defined in 45 CFR § 164.402 and required by
                  45 CFR § 164.410, without unreasonable delay and in no case
                  later than 10 calendar days after Business Associate discovers
                  the Breach. Such report shall include, to the extent known:
                  (a) the identification of each individual whose PHI has been,
                  or is reasonably believed to have been, accessed, acquired, or
                  disclosed during the Breach; (b) the date of the Breach, if
                  known, and the date of discovery of the Breach; (c) the scope
                  of the Breach; and (d) the Business Associate’s response to
                  the Breach. In the event of a Breach, the Business Associate
                  shall, in consultation with Covered Entity, mitigate, to the
                  extent reasonably practicable, any harmful effect of such
                  Breach known to Business Associate.
                </li>
              </ol>
            </li>
            <li>
              <p>
                In the event of a use or disclosure of PHI that is improper
                under this Agreement but does not constitute a Breach, Business
                Associate promptly shall report such use or disclosure to
                Covered Entity.
              </p>
            </li>
            <li>
              <p>
                In the event of any Security Incident, Business Associate shall
                report such Security Incident in writing to Covered Entity
                within ten (10) business days of the date on which Business
                Associate becomes aware of such Security Incident; provided,
                however, the Parties acknowledge and agree that this Section
                2(d)(iii) constitutes notice by Business Associate to Covered
                Entity of the ongoing existence and occurrence of attempted but
                Unsuccessful Security Incidents (as defined below).
                “Unsuccessful Security Incidents” shall include, but not be
                limited to, pings and other broadcast attacks on Business
                Associate’s firewall, port scans, unsuccessful log-on attempts,
                denials of service and any combination of the above, so long as
                no such incident results in unauthorized access, use or
                disclosure of PHI.
              </p>
            </li>
          </ol>
        </li>
        <li>
          <p>
            <u>Obligations of Covered Entity.</u>
          </p>
          <ol type="a">
            <li>
              <p>
                <u>Permissible Requests.</u>
              </p>
              <ol type="i">
                <li>
                  <p>
                    Covered Entity shall not request Business Associate to use
                    or disclose PHI in any manner that would violate applicable
                    federal and state laws if such use or disclosure were made
                    by Covered Entity.
                  </p>
                </li>
                <li>
                  <p>
                    Covered Entity may request Business Associate to disclose
                    PHI directly to another party only for the purposes allowed
                    by HIPAA and the HITECH Act.
                  </p>
                </li>
              </ol>
            </li>
            <li>
              <p>
                <u>Notifications.</u>
              </p>
              <ol type="i">
                <li>
                  <p>
                    Covered Entity will notify Business Associate of limitations
                    to its notice of privacy practices where such limitations
                    affects Business Associate’s use or disclosure of PHI.
                  </p>
                </li>
                <li>
                  <p>
                    Covered Entity will notify Business Associate of changes to
                    the permission by an individual to use or disclose his/her
                    PHI where such change affects Business Associate’s use or
                    disclosure of PHI.
                  </p>
                </li>
                <li>
                  <p>
                    Covered Entity will notify Business Associate of any
                    restrictions to the use or disclosure of PHI to which
                    Covered Entity has agreed to abide in accordance with 45 CFR
                    § 164.522.
                  </p>
                </li>
              </ol>
            </li>
          </ol>
        </li>
        <li>
          <p>
            <u>Term and Termination.</u>
          </p>
          <ol type="a">
            <li>
              <p>
                <u>General Term and Termination.</u> This Agreement will be
                effective as of the Effective Date, and shall terminate when all
                PHI is destroyed, or, if it is infeasible to destroy the PHI,
                protections are extended to such PHI, in accordance with the
                termination provisions of this Section 4.
              </p>
            </li>
            <li>
              <u>Material Breach.</u>
              <ol type="i">
                <li>
                  <p>
                    If either party has knowledge of a material breach of this
                    Agreement by the other party, the non-breaching party shall
                    provide an opportunity for the breaching party to cure the
                    breach or end the violation. Where said breach is not cured
                    within ten (10) business days of the breaching party’s
                    receipt of notice from the non-breaching party of said
                    breach, the non-breaching party shall, if feasible,
                    terminate this Agreement and the portion(s) of the Service
                    Agreement affected by the breach.
                  </p>
                </li>
                <li>
                  <p>
                    Where either party has knowledge of a material breach by the
                    other party and cure is not possible, the non-breaching
                    party shall, if feasible, terminate this Agreement and the
                    portion(s) of the Service Agreement affected by the breach.
                  </p>
                </li>
              </ol>
            </li>
            <li>
              <p>
                <u>Return or Destruction of PHI.</u> Upon termination of this
                Agreement, for any reason, Business Associate shall
              </p>
              <ol type="i">
                <li>
                  <p>
                    If feasible as determined by Business Associate, return or
                    destroy all PHI received from, or created or received by
                    Business Associate for or on behalf of Covered Entity that
                    Business Associate or any of its subcontractors and agents
                    still maintain in any form, and Business Associate shall
                    retain no copies of such information; or
                  </p>
                </li>
                <li>
                  <p>
                    If Business Associate determines that such return or
                    destruction is not feasible, extend the protections of this
                    Agreement to such information and limit further uses and
                    disclosures to those purposes that make the return or
                    destruction of the PHI infeasible, in which case Business
                    Associate’s obligations under this Section shall survive the
                    termination of this Agreement.
                  </p>
                </li>
              </ol>
            </li>
          </ol>
        </li>
        <li>
          <p>
            <u>Miscellaneous.</u>
          </p>
          <ol type="a">
            <li>
              <p>
                <u>Assignment.</u> This Agreement may not be assigned by either
                party without the prior notice to the other party. Subject to
                the foregoing, this Agreement will be binding upon and will
                inure to the benefit of the parties and their respective
                successors and assigns.
              </p>
            </li>
            <li>
              <p>
                <u>Amendment</u> If any of the regulations promulgated under
                HIPAA or the HITECH Act are amended or interpreted in a manner
                that renders this Agreement inconsistent therewith, the parties
                shall amend this Agreement to the extent necessary to comply
                with such amendments or interpretations. This Agreement may be
                amended only by written consent of the parties.
              </p>
            </li>
            <li>
              <p>
                <u>Third Parties</u> Nothing in this Agreement shall confer upon
                any person other than the Parties and their respective
                successors or assigns, any rights, remedies, obligations, or
                liabilities whatsoever. There are no third-party beneficiaries
                of this Agreement.
              </p>
            </li>
            <li>
              <p>
                <u>Entire Agreement</u> This Agreement constitutes the entire
                agreement between the parties concerning its subject matter, and
                supersedes all prior and contemporaneous agreements and
                understandings, express or implied, oral or written.
              </p>
            </li>
            <li>
              <p>
                <u>Governing Law</u> This Agreement will be deemed to have been
                made in New York and will be governed by and construed in
                accordance with New York law. The section headings in this
                Agreement are for convenience only and will not affect its
                interpretation.
              </p>
            </li>
            <li>
              <p>
                <u>Conflicting Terms</u> In the event that any terms of this
                Agreement conflict with any terms of the Service Agreement, the
                terms of this Agreement shall govern and control.
              </p>
            </li>
            <li>
              <p>
                <u>Notice</u> Any notice or other communication by either party
                to the other will be in writing and will be deemed to have been
                given when hand delivered, sent by nationally-recognized
                overnight delivery service, or mailed, postage prepaid,
                registered or certified mail, addressed as follows:
              </p>
            </li>
          </ol>
          <p>
            If to Covered Entity: Address next to the signature line for such
            Covered Entity
          </p>
          <p>If to Business Associate:</p>
          <address>
            Parachute Health, LLC
            <br />
            100 Overlook Center, 2nd Floor
            <br />
            Princeton, NJ 08540
            <br />
            Attn: David Gelbard
          </address>
          <p>
            or to such other address as either party may designate by notice
            pursuant to this section.
          </p>
        </li>
      </ol>
    </>
  )
}

export default Exhibit
