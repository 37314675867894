import React from "react"
import SelectFilter from "components/SelectFilter"

export const PatientLastAppointmentFilter = (props: {
  patientLastAppointment: string[] | undefined
  onChange: (params: { patientLastAppointment: string[] }) => void
}) => {
  return (
    <SelectFilter
      onChange={(values) => {
        props.onChange(values)
      }}
      options={[
        { label: "Within 6 months", value: "less_than_6_months" },
        { label: "Within 6 to 12 months", value: "six_to_12_months" },
        { label: "Over a year", value: "more_than_one_year" },
        { label: "Upcoming appointment", value: "upcoming" },
      ]}
      name="patientLastAppointment"
      label="Patient Last Appointment"
      singular={true}
      initialValues={props.patientLastAppointment}
    />
  )
}
