// extracted by mini-css-extract-plugin
var _1 = "app-javascript-applications-Workflow-containers-Product-CatalogBrowseTabs-index-module__container--s2LMj";
var _2 = "app-javascript-applications-Workflow-containers-Product-CatalogBrowseTabs-index-module__selected-tab--cBc8F";
var _3 = "app-javascript-applications-Workflow-containers-Product-CatalogBrowseTabs-index-module__selected-tab-container--Od5G2";
var _4 = "app-javascript-applications-Workflow-containers-Product-CatalogBrowseTabs-index-module__tab--OLuMS";
var _5 = "app-javascript-applications-Workflow-containers-Product-CatalogBrowseTabs-index-module__tab-container--ysB_0";
var _6 = "app-javascript-applications-Workflow-containers-Product-CatalogBrowseTabs-index-module__tab-list--QsvKM";
var _7 = "app-javascript-applications-Workflow-containers-Product-CatalogBrowseTabs-index-module__tab-text--wk2q2";
var _8 = "app-javascript-applications-Workflow-containers-Product-CatalogBrowseTabs-index-module__tab-text-with-sidebar-open--I3g1r";
export { _1 as "container", _2 as "selectedTab", _3 as "selectedTabContainer", _4 as "tab", _5 as "tabContainer", _6 as "tabList", _7 as "tabText", _8 as "tabTextWithSidebarOpen" }
