import React from "react"
import Icon from "components/Icon"
import classNames from "classnames"

type Props = {
  className?: string
  checked?: boolean
}

function NavigationIcon({ className, checked }: Props) {
  return (
    <Icon
      type={checked ? "check-circle" : "circle"}
      className={classNames(className, {
        "color-secondary": checked,
        "color-mid-gray": !checked,
      })}
      style={{ fontWeight: checked ? 700 : 500 }}
    />
  )
}

export default NavigationIcon
