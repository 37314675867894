export function getAddSupplierMessage({
  fulfillmentAgreement,
}: {
  fulfillmentAgreement?: { supplier: { name: string } }
}) {
  if (fulfillmentAgreement?.supplier?.name) {
    return `Supplier successfully added! ${fulfillmentAgreement.supplier.name} is now available in your supplier menu.`
  }
  return "Request submitted! You'll receive an email when the supplier has been added."
}
