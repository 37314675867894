import FacilitySignup from "../../FacilitySignup"
import { Spacer } from "../../Spacer"
import { theme } from "themes/theme"
import { Grid, Paper } from "@material-ui/core"
import { NoSignatureRequests } from "../../NoSignatureRequests"
import React, { useMemo } from "react"
import { Doctor } from "../../../sharedTypes"
import { useFlashContext } from "../../../hooks/useFlashContext"
import { heightCalculation } from "../../../utilities/heightCalculation"
import { useStyles } from "themes/theme"

interface Props {
  doctor: Pick<Doctor, "id" | "name" | "npi" | "signOnScreenEnabled">
  currentUserEmail: string
  currentUserEligibleForFacilitySignup: boolean
  redirectToFacilitySignupPage: boolean
  showSignTabCta: boolean
}

const noSignatureRequestsToReviewStyles = (flash) => ({
  paper: {
    height: `${heightCalculation(flash)}`,
  },
})

const NoSignatureRequestsToReview = ({
  doctor,
  currentUserEmail,
  currentUserEligibleForFacilitySignup,
  redirectToFacilitySignupPage,
  showSignTabCta,
}: Props) => {
  const allRequestsReviewedText = `No pending requests for ${doctor.name}`
  const shouldShowFacilitySignup = () => {
    const shouldUserBeDirectedToSignTab =
      showSignTabCta && doctor.signOnScreenEnabled

    return (
      currentUserEligibleForFacilitySignup && !shouldUserBeDirectedToSignTab
    )
  }

  const flash = useFlashContext()
  const muiStyles = useMemo(() => noSignatureRequestsToReviewStyles(flash), [
    flash,
  ])
  const muiClasses = useStyles(muiStyles)

  if (shouldShowFacilitySignup()) {
    return (
      <FacilitySignup
        currentUserEmail={currentUserEmail}
        headingTitle={allRequestsReviewedText}
        redirectToFacilitySignupPage={redirectToFacilitySignupPage}
      />
    )
  } else {
    return (
      <>
        <Spacer width={theme.spacing(1)} />
        <Grid item xs>
          <Paper className={muiClasses.paper} elevation={0}>
            <NoSignatureRequests
              showSignTabCta={showSignTabCta}
              message={allRequestsReviewedText}
              signOnScreenEnabled={doctor.signOnScreenEnabled}
            />
          </Paper>
        </Grid>
      </>
    )
  }
}

export default NoSignatureRequestsToReview
