import React from "react"
import { CarrierAuthorization } from "sharedTypes"
import DataRow from "components/DataRow"

type Props = {
  carrierAuthorization: CarrierAuthorization
}

const ProviderSectionContent = ({ carrierAuthorization }: Props) => {
  const {
    requesterName,
    requesterNpi,
    facilityName,
    facilityNpi,
    supplierName,
    supplierNpi,
  } = carrierAuthorization

  return (
    <div>
      <DataRow label="Clinician" value={requesterName} />
      <DataRow label="Clinician NPI" value={requesterNpi} />
      <DataRow label="Facility" value={facilityName} />
      <DataRow label="Facility NPI" value={facilityNpi} />
      <DataRow label="Supplier" value={supplierName} />
      <DataRow label="Supplier NPI" value={supplierNpi} />
    </div>
  )
}

export default ProviderSectionContent
