import React, { useContext } from "react"
import { CatalogCustomAttributeOption } from "../../../../types/sharedTypes"
import { NoticeContext } from "../../../../contexts/NoticeContext"
import { StaticFieldDefinition } from "../../../../types/form/sharedTypes"
import { booleanRadioGroup } from "../../../../components/Formik/DynamicFormField"
import { generateInitialValues } from "../../../../utilities/form"
import Form from "../../../../../../components/form/Form"
import FormSegment from "../../../../components/Formik/FormSegment"
import { CanopyButton } from "@parachutehealth/canopy-button"
import CmsDrawer from "../../../../components/CmsDrawer"
import { createAttributeOption } from "../../../../api/attributeOptions"

type Props = {
  customAttributeId: string
  open?: boolean
  onClose: () => void
  onSave?: (option: CatalogCustomAttributeOption) => void
}

const AddAttributeValueDrawer: React.FC<Props> = ({
  customAttributeId,
  open = false,
  onClose,
  onSave,
}: Props): React.JSX.Element => {
  const { showNotice } = useContext(NoticeContext)

  const staticFields: StaticFieldDefinition[] = [
    { label: "Description", name: "description", type: "text", required: true },
    {
      label: "Patient Preference",
      name: "patientPreference",
      type: "radio",
      initialValue: ["false"],
      options: booleanRadioGroup(),
    },
  ]

  const handleSubmit = async (
    params: Record<string, any>,
    { setErrors }: any
  ) => {
    const response = await createAttributeOption(customAttributeId, params)
    if (response.errors) {
      setErrors(response.errors)
    } else {
      onClose()
      showNotice(
        `Successfully added "${response.customAttributeOption.description}"`,
        "success"
      )
      if (onSave) onSave(response.customAttributeOption)
    }
  }

  const initialValues = generateInitialValues(staticFields, {})

  return (
    <CmsDrawer open={open} title="Add Attribute Value" onClose={onClose}>
      <Form initialValues={initialValues} onSubmit={handleSubmit}>
        {({ isSubmitting }) => (
          <>
            <FormSegment>{staticFields}</FormSegment>
            <div>
              <CanopyButton
                size="small"
                variant="primary"
                type="submit"
                loading={isSubmitting}
              >
                Save
              </CanopyButton>
              <CanopyButton
                className="canopy-mis-4x"
                size="small"
                variant="tertiary"
                disabled={isSubmitting}
                onClick={onClose}
              >
                Cancel
              </CanopyButton>
            </div>
          </>
        )}
      </Form>
    </CmsDrawer>
  )
}

export default AddAttributeValueDrawer
