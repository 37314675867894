import React from "react"
import { DoctorSignatureRequest } from "../../api"
import { TableColumnDefinition } from "components/Table"
import { DonePath } from "../../routes"
import Tooltip from "components/Tooltip"
import { Link } from "react-router-dom"
import classNames from "classnames"
import isTouchDevice from "is-touch-device"
import Icon from "components/Icon"

type DoctorSignatureRequestRecord = DoctorSignatureRequest & { key: string }

const makeTableColumnDefinitions = (
  setSelectedSignatureRequest,
  doctorId
): TableColumnDefinition<DoctorSignatureRequestRecord>[] => {
  return [
    {
      title: "Order",
      attr: "id",
      render: (signatureRequest: DoctorSignatureRequestRecord) => {
        const selectSignatureRequest = () => {
          setSelectedSignatureRequest(signatureRequest)
        }
        const tooltipContent = (
          <>
            Parachute Order: <strong>{signatureRequest.id}</strong>
          </>
        )
        return (
          <Tooltip
            trigger={["hover"]}
            overlay={tooltipContent}
            disabled={isTouchDevice()}
            placement={Tooltip.Placement.Right}
            width={Tooltip.Width.LimitedWide}
            triggerElementClassName="d-inline"
          >
            <Link
              className={classNames("link color-brand")}
              to={`${DonePath(doctorId)}/${signatureRequest.gid}`}
              onClick={selectSignatureRequest}
            >
              View
            </Link>
          </Tooltip>
        )
      },
    },
    {
      title: "Patient Name",
      attr: "patientName",
      render: (signatureRequest: DoctorSignatureRequestRecord) =>
        signatureRequest.patient.name,
    },
    {
      title: "Patient Date of Birth",
      attr: "patientDOB",
      render: (signatureRequest: DoctorSignatureRequestRecord) =>
        signatureRequest.patient.dateOfBirth,
    },
    {
      title: "Creation Date",
      attr: "creationDate",
      render: (signatureRequest: DoctorSignatureRequestRecord) =>
        signatureRequest.createdAt,
    },
    {
      title: "Supplier",
      attr: "supplierName",
    },
    {
      title: "Status",
      attr: "status",
    },
    {
      title: "Reason",
      attr: "denialReason",
      render: (signatureRequest: DoctorSignatureRequestRecord) => {
        const denialDescriptionText = signatureRequest.denialReason?.description

        const denialDescriptonTooltip = (
          <Tooltip
            trigger={["hover"]}
            overlay={denialDescriptionText}
            placement={Tooltip.Placement.Bottom}
            width={Tooltip.Width.LimitedWide}
            triggerElementClassName="d-inline"
          >
            <Icon
              ariaLabel="denial reason"
              prefix="far"
              type="comment"
              className="color-primary"
            />
          </Tooltip>
        )
        return (
          <span>
            {signatureRequest.denialReason?.reason}
            &nbsp;&nbsp;
            {signatureRequest.denialReason?.description && !isTouchDevice()
              ? denialDescriptonTooltip
              : null}
          </span>
        )
      },
    },
  ]
}

export default makeTableColumnDefinitions
