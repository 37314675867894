import { CanopyButton } from "@parachutehealth/canopy-button"
import React from "react"

interface Props {
  href?: string
  onClick: () => void
}

const NewOrderButton: React.FC<Props> = ({ href, onClick }) => (
  // When as={undefined}, CanopyButton renders as <button> in the DOM.
  // This is required to comply with TypeScript checks in the CanopyButton's polymorphic typing.
  <>
    <CanopyButton
      accessibleText="New order"
      className="d-flex d-md-none"
      as={href ? "a" : undefined}
      href={href}
      onClick={onClick}
      iconStart="plus"
      size="small"
      variant="primary"
    />
    <CanopyButton
      className="d-none d-md-flex"
      as={href ? "a" : undefined}
      href={href}
      onClick={onClick}
      iconStart="plus"
      size="small"
      variant="primary"
    >
      New order
    </CanopyButton>
  </>
)

export default NewOrderButton
