import { ApiResponse } from "applications/Cms/api/utilities"
import { patch } from "services/api"

export const discardDocument = ({
  supplierOrganizationId,
  csrInboxStateId,
}: {
  supplierOrganizationId: string
  csrInboxStateId: string
}): Promise<ApiResponse<any>> => {
  const pathToCsrInboxState = `/u/r/${supplierOrganizationId}/inbox_documents/${csrInboxStateId}`
  return patch(`${pathToCsrInboxState}/discard`)
}
