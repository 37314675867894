import React from "react"
import { ErrorType } from "../sharedTypes"

interface Props {
  error: string
  type: ErrorType
}

const ErrorText = ({ error, type }: Props) => {
  switch (type) {
    case ErrorType.Invalid:
      return <></>
    default:
      return <>{error}</>
  }
}

const CaptureAlert = ({ error, type }: Props) => {
  return (
    <div className="color-danger font-xs text-left canopy-mbs-2x">
      <ErrorText error={error} type={type} />
    </div>
  )
}

export default CaptureAlert
