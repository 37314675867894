import React, { useState } from "react"
import { Form, TextArea } from "components/form"
import Button from "components/form/Button"
import Alert from "components/Alert"
import { Nullable } from "sharedTypes"
import { isCtrlEnter } from "utilities/browser/event"
import { SupplierChatTemplate } from "../types"
import ChatTemplates from "./ChatTemplates"
import autosize from "autosize"

interface Props {
  onSubmit(values: Comment, { resetForm }): void
  chatId: string
  chatTemplates: SupplierChatTemplate[]
  patientConsentsToSms: Nullable<boolean>
  patientOptedOutAt: Nullable<Date>
}

interface AlertOptions {
  alertText: string
  alertIcon: string
}

const alertContent = (
  consentsToSms: Nullable<boolean>,
  optedOutAt: Nullable<Date>
): AlertOptions => {
  if (optedOutAt) {
    return {
      alertText:
        "Patient has disabled text message notifications. They will not receive notifications about new messages from you.",
      alertIcon: "fa-bell-slash",
    }
  }

  switch (consentsToSms) {
    case null:
      return {
        alertText: "Patient will receive a text message within 24 hours.",
        alertIcon: "fa-mobile-alt",
      }
    case false:
      return {
        alertText:
          "Patient has disabled text message notifications. They will not receive notifications about new messages from you.",
        alertIcon: "fa-bell-slash",
      }
    default:
      return {
        alertText: "Patient will receive a text message notification.",
        alertIcon: "fa-mobile-alt",
      }
  }
}

const MessageForm: React.FC<Props> = ({
  onSubmit,
  chatId,
  chatTemplates,
  patientConsentsToSms,
  patientOptedOutAt,
}) => {
  const [textareaEl, setTextareaEl] = useState<Element>()
  const { alertText, alertIcon } = alertContent(
    patientConsentsToSms,
    patientOptedOutAt
  )

  return (
    <Form initialValues={{ content: "", chatId: chatId }} onSubmit={onSubmit}>
      {({ isSubmitting, submitForm, values, setFieldValue }) => (
        <>
          <ChatTemplates
            name="portal-chat-templates"
            templates={chatTemplates}
            onClick={(template) => {
              setFieldValue("portal-chat-templates", template.id)
              setFieldValue("content", template.template).then(() => {
                autosize.update(textareaEl)
              })
            }}
          />
          <TextArea
            name="content"
            placeholder="Send a message to the patient"
            autoFocus
            unwrapped
            alert={
              <Alert leftIcon={alertIcon} status="warning">
                {alertText} Do not post credit card info.
              </Alert>
            }
            onKeyDown={(event) => isCtrlEnter(event) && submitForm()}
            innerRef={(node) => setTextareaEl(node)}
          />
          <input type="hidden" name="chatId" value={chatId} />
          <div className="clearfix">
            <Button
              type="submit"
              className="btn-brand canopy-px-8x float-right"
              disabled={isSubmitting || !values.content}
            >
              Send
            </Button>
          </div>
          <p className="text-right canopy-mbs-4x color-dark-gray font-notice">
            or press 'Ctrl+Enter' to post
          </p>
        </>
      )}
    </Form>
  )
}

export default MessageForm
