import React from "react"
import classNames from "classnames"
import Link from "components/Link"
import { EventCategory, trackEvent } from "utilities/tracking"
import * as styles from "./SignUpPromo.module.scss"
import { learnMoreUrl } from "../urls"
import wave from "wave.png"

const SignUpPromo = () => {
  return (
    <div className={classNames(styles.infoContainer, "col-lg-6 col-12")}>
      <h2 className="canopy-typography-heading-large">
        Upgrade your workflow:
      </h2>
      <h1 className="canopy-typography-heading-xlarge">
        Say goodbye to faxes, and hello to digital renewals{" "}
        <img src={wave} width="26px" alt="hand wave" />
      </h1>
      <div className={styles.infoDetailContainer}>
        <div className={styles.infoDetailHeader}>
          <span className="canopy-typography-heading-medium">
            Focus on patients, not paperwork!
          </span>
        </div>
        <ul>
          <li>Once daily Resupply requests by email</li>
          <li>
            Easily prepare orders for clinicians to Sign On-Screen, or deny with
            a note to supplier
          </li>
        </ul>
      </div>
      <div className={styles.infoDetailLearnMoreLink}>
        Want to learn more?&nbsp;
        <Link
          href={learnMoreUrl}
          onClick={() =>
            trackEvent(EventCategory.ReviewerSidebarSelfSignUp, "learn_more")
          }
        >
          Visit our website
        </Link>
      </div>
    </div>
  )
}

export default SignUpPromo
