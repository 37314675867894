// @ts-strict-ignore
import React from "react"
import { CarrierAuthorization } from "sharedTypes"
import DataRow from "components/DataRow"
import { format } from "utilities/date"
import { capitalize, pluralize } from "utilities/string"

type Props = {
  carrierAuthorization: CarrierAuthorization
}

const LineItemAuthorizationSectionContent = ({
  carrierAuthorization,
}: Props) => {
  const { carrierLineItemAuthorizations } = carrierAuthorization

  const renderCarrierLineItemAuthorization = (carrierLineItemAuthorization) => {
    const {
      id,
      hcpcs,
      lineItemAuthorizationNumber,
      quantity,
      startDate,
      endDate,
      withdrawn,
    } = carrierLineItemAuthorization
    return (
      <React.Fragment key={id}>
        <hr />
        <DataRow bold label="HCPCS" value={hcpcs} />
        <DataRow
          label="Total Quantity"
          value={`${quantity} ${pluralize("unit", quantity)} over period`}
        />
        <DataRow
          label="Period"
          value={`${format(startDate)} - ${
            endDate ? format(endDate) : "present"
          }`}
        />
        <DataRow
          label="Line-item Auth. #"
          value={`${lineItemAuthorizationNumber}${
            withdrawn ? " - Withdrawn" : ""
          }`}
          status={withdrawn ? "error" : null}
        />
      </React.Fragment>
    )
  }

  return (
    <div>
      <DataRow
        bold
        label="Authorization #"
        value={`${carrierAuthorization.parentAuthorizationNumber}${
          carrierAuthorization.terminated
            ? " - " + capitalize(carrierAuthorization.authStatus)
            : ""
        }`}
        status={carrierAuthorization.terminated ? "error" : null}
      />
      {!!carrierAuthorization.authorizationNotes && (
        <div>
          <em>{`"${carrierAuthorization.authorizationNotes}"`}</em>
        </div>
      )}
      {carrierLineItemAuthorizations.map((c) =>
        renderCarrierLineItemAuthorization(c)
      )}
    </div>
  )
}

export default LineItemAuthorizationSectionContent
