import React, { useContext } from "react"
import GlobalContext from "context/Global"
import DownloadLink from "components/DownloadLink"
import { DocumentationRowProps } from "./documentation-row-props"

type DownloadUploadDetailsProps = Pick<
  DocumentationRowProps,
  "dmeOrderId" | "documentationRequirement"
>

export const DownloadUploadDetails = (props: DownloadUploadDetailsProps) => {
  const { requiresTwoStepDownload } = useContext(GlobalContext)
  const documentationRequirement = props.documentationRequirement
  const documentKey = documentationRequirement.definitionIdentifierName
  return (
    <>
      <div className="color-dark-gray font-subparagraph">
        Download and fill out this form.
      </div>

      <div className="color-dark-gray font-subparagraph canopy-mbe-2x">
        Submit the completed form via upload or fax.
      </div>

      <DownloadLink
        orderId={props.dmeOrderId}
        dmeOrderDocumentationRequirementId={documentationRequirement.externalId}
        documentKey={documentKey!}
        label=""
        className="btn-link color-primary"
        requiresTwoStepDownload={requiresTwoStepDownload}
      />
    </>
  )
}
