import { gql, TypedDocumentNode } from "@apollo/client"
import {
  PayorUserUpdateMutation,
  PayorUserUpdateMutationVariables,
} from "../../../../graphql/__generated__/graphql"

export const PayorUserUpdate: TypedDocumentNode<
  PayorUserUpdateMutation,
  PayorUserUpdateMutationVariables
> = gql`
  mutation PayorUserUpdate(
    $userId: ID
    $firstName: String
    $lastName: String
    $email: String
    $timeZone: String
    $role: EmploymentRolesEnum
  ) {
    userUpdate(
      input: {
        userId: $userId
        firstName: $firstName
        lastName: $lastName
        email: $email
        timeZone: $timeZone
        role: $role
      }
    ) {
      user {
        id
        firstName
        lastName
        email
        timeZone
      }
    }
  }
`
