import React from "react"
import { CanopyDialogModal } from "@parachutehealth/canopy-dialog-modal"
import { CanopyButton } from "@parachutehealth/canopy-button"
import { CanopyTextInputField } from "@parachutehealth/canopy-text-input-field"
import { Formik } from "formik"
import { shareRequest } from "./api"
import { useToaster } from "../../../components/Toaster"
import { DocumentationOrder } from "../fetchOrders"
import { makeStyles } from "@material-ui/core"
import { canopyColorPrimitivesGray74 } from "@parachutehealth/canopy-tokens-color"
import {
  canopySpace16X,
  canopySpace4X,
} from "@parachutehealth/canopy-tokens-space"
import { format } from "../../../utilities/date"
import { trackEvent as trackAnalyticsEvent } from "utilities/analytics"

export const ShareModal = ({
  showModal,
  close,
  documentationOrder,
}: {
  showModal: boolean
  close: () => void
  documentationOrder: DocumentationOrder
}): JSX.Element => {
  const { persistentAlert } = useToaster()

  function formattedDiagnoses(icd10Codes: string[][] | undefined) {
    if (icd10Codes === undefined || icd10Codes.length === 0) {
      return "No diagnoses listed"
    } else {
      // temporarily return only first diagnosis. This will be expanded in a future ticket
      return icd10Codes[0].join(`, `)
    }
  }

  const useStyles = makeStyles(() => ({
    orderDetails: {
      boxShadow: `0px 3px 5px 0px ${canopyColorPrimitivesGray74}`,
      borderRadius: canopySpace4X,
      padding: canopySpace4X,
      margin: canopySpace16X,
    },
  }))

  const classes = useStyles()

  const handleClose = () => {
    void trackAnalyticsEvent(["ShareRequestLink", "share-request-modal-closed"])
    close()
  }

  return (
    <Formik
      initialValues={{
        firstName: "",
        lastName: "",
        email: "",
      }}
      onSubmit={(values, actions) => {
        shareRequest(documentationOrder.id, values).then(() => {
          persistentAlert({
            message:
              "Well done! The signature request was successfully shared.",
            severity: "success",
          })
          actions.setSubmitting(false)
          void trackAnalyticsEvent([
            "ShareRequestLink",
            "share-request-submit-clicked",
          ])
          close()
        })
      }}
      validate={(values) => {
        const errors: {
          firstName?: string
          lastName?: string
          email?: string
        } = {}
        if (!values.firstName) {
          errors.firstName = "Please provide a first name"
        }
        if (!values.lastName) {
          errors.lastName = "Please provide a last name"
        }
        if (!values.email) {
          errors.email = "Please provide an email"
        }

        return errors
      }}
    >
      {({ values, isValid, dirty, handleChange, handleBlur, handleSubmit }) => {
        return (
          <CanopyDialogModal
            open={showModal}
            size="medium"
            header="Share Request Link"
            onClose={handleClose}
            primaryFooterButton={
              <CanopyButton
                type="submit"
                variant="primary"
                form="modal-form-id"
                disabled={!(isValid && dirty)}
              >
                Send Email
              </CanopyButton>
            }
            secondaryFooterButton={
              <CanopyButton
                onClick={handleClose}
                variant="secondary"
                className="canopy-mie-8x"
              >
                Close
              </CanopyButton>
            }
          >
            <p>
              Please verify the information below with the clinician or
              reviewer.
            </p>
            <div className={classes.orderDetails}>
              <b>{documentationOrder.patient.name}</b>
              <br />
              DOB: <b>{documentationOrder.patient.dateOfBirth}</b>
              <br />
              Diagnoses:{" "}
              <b>
                {formattedDiagnoses(
                  documentationOrder.documentationData.icd10CodesWithDescription
                )}
              </b>
              <br />
              Order date:{" "}
              <b>{format(documentationOrder.documentationData.orderDate)}</b>
              <br />
              <br />
              Signing clinician: <b>{documentationOrder.doctor.name}</b>
              <br />
              Last notification date:{" "}
              <b>{documentationOrder.lastNotification}</b>
              <br />
              Notification method:{" "}
              <b>{documentationOrder.lastNotificationMethod}</b>
              <br />
            </div>
            <p>
              Please add the clinician&apos;s or reviewer&apos;s email to send
              them the signature request digitally.
            </p>
            <form id="modal-form-id" onSubmit={handleSubmit}>
              <CanopyTextInputField
                className="canopy-pbe-8x"
                label="Email"
                type="email"
                required
                id="email"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.email}
              ></CanopyTextInputField>
              <CanopyTextInputField
                className="col-6 d-inline-block canopy-pis-0"
                label="First Name"
                type="text"
                required
                id="firstName"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.firstName}
              ></CanopyTextInputField>
              <CanopyTextInputField
                className="col-6 d-inline-block canopy-pie-0"
                label="Last Name"
                type="text"
                id="lastName"
                required
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.lastName}
              ></CanopyTextInputField>
            </form>
          </CanopyDialogModal>
        )
      }}
    </Formik>
  )
}
