import { verifyUrl, loginUrl, resendUrl, rejectUrl } from "./urls"
import { put, post } from "services/api"

export function verify(accessCode: string, securityCode: string) {
  return put(verifyUrl(), { accessCode, securityCode })
    .then(({ data }) => data)
    .catch(({ response }) => Promise.reject(response.data))
}

export function login(params: {
  accessCode: string
  securityCode: string
  firstName: string
  lastName: string
  email?: string
}) {
  return post(loginUrl(), params)
    .then(({ data }) => data)
    .catch(({ response }) => Promise.reject(response.data))
}

export function resend(accessCode: string, securityCode: string) {
  return post(resendUrl(), { accessCode, securityCode })
    .then(({ data }) => data)
    .catch(({ response }) => Promise.reject(response.data))
}

export function reject(identityVerificationId: string) {
  return put(rejectUrl(identityVerificationId))
    .then(({ data }) => data)
    .catch(({ response }) => Promise.reject(response.data))
}
