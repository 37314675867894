import React from "react"
import { CarrierAuthorization } from "sharedTypes"
import DataRow from "components/DataRow"

type Props = {
  carrierAuthorization: CarrierAuthorization
}

const DiagnosesSectionContent = ({ carrierAuthorization }: Props) => {
  return (
    <div>
      <DataRow
        label="Diagnoses"
        value={
          carrierAuthorization.diagnoses &&
          carrierAuthorization.diagnoses.join(", ")
        }
      />
    </div>
  )
}

export default DiagnosesSectionContent
