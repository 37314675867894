import React from "react"
import DatePickerInput, {
  DatePickerWidth,
} from "components/input/DatePickerInput"
import fieldWrapper from "../fieldWrapper"
import { format, DATE_FORMAT } from "utilities/date"

interface Props {
  field: { onChange(event): void }
  form: { setFieldTouched(name, touched); setFieldValue(name, value) }
  name: string
  label?: string
  disabled?: boolean
  autoFocus?: boolean
  className?: string
  unwrapped?: boolean
  width: DatePickerWidth
}

const InnerField = ({
  form,
  field,
  name,
  disabled,
  autoFocus,
  className,
  width,
}: Props) => {
  return (
    <DatePickerInput
      {...field}
      id={name}
      disabled={disabled}
      autoFocus={autoFocus}
      className={className}
      width={width}
      onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
        form.setFieldTouched(name, true)
        form.setFieldValue(name, format(event.target.value, DATE_FORMAT))
        field.onChange(event)
      }}
    />
  )
}

InnerField.defaultProps = {
  width: "auto",
}

export default fieldWrapper(InnerField)
