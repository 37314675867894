// @team @facilitators
// @ts-strict-ignore
import { DmeOrderWarningSeverity } from "sharedTypes"

export const hasError = (warnings) => {
  return warnings.find(
    (warning) => warning.severity === DmeOrderWarningSeverity.Error
  )
}

export const hasWarning = (warnings) => {
  return warnings.find(
    (warning) => warning.severity === DmeOrderWarningSeverity.Warning
  )
}
