import React from "react"
import { numberToCurrency } from "utilities/price"
import { Nullable } from "sharedTypes"

interface Props {
  unitPrice: Nullable<number>
  quantity: number
}

const FormularySubtotal: React.FC<Props> = ({ quantity, unitPrice }) => {
  if (!unitPrice && unitPrice !== 0) return null

  const formattedSubtotal = numberToCurrency(unitPrice * quantity)

  return (
    <div className="d-flex justify-content-end">
      <span className="color-dark-gray">Subtotal:&nbsp;&nbsp;</span>
      <span className="font-weight-bolder">{formattedSubtotal}</span>
    </div>
  )
}

export default FormularySubtotal
