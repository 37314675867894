import React, { useState, useRef } from "react"
import * as styles from "./index.module.scss"
import { Event } from "sharedTypes"
import TabToggleButton from "./TabToggleButton"
import { NavOrder, SidebarTab } from "../../../sharedTypes"
import {
  Assignment,
  Forum,
  SwapHorizontalCircle,
  ShoppingCart,
} from "@material-ui/icons"
import KnowledgeBaseButton from "../KnowledgeBaseButton"
import { useWebSockets } from "hooks/useWebSockets"
import { isComment } from "utilities/event"
import { countItemsInCart } from "applications/Workflow/utilities/cart"
import { useEffectRunOnEveryRender } from "hooks"

interface Props {
  currentTab: SidebarTab
  launchKnowledgeBase: () => void
  setSidebarTab: (tab: SidebarTab) => void
  showTransferTab: boolean
  dmeOrder: NavOrder
}
const TopRightInOrderNavContainer: React.FC<Props> = ({
  currentTab,
  launchKnowledgeBase,
  setSidebarTab,
  showTransferTab,
  dmeOrder,
}) => {
  const currentTabRef = useRef(currentTab)
  useEffectRunOnEveryRender(() => {
    currentTabRef.current = currentTab
  })
  const [showActivityBadge, setShowActivityBadge] = useState(false)
  const changeTab = (tab: SidebarTab) => {
    if (tab === SidebarTab.Activity) {
      setShowActivityBadge(false)
    }

    if (tab === currentTab) {
      setSidebarTab(SidebarTab.Closed)
      return
    }

    setSidebarTab(tab)
  }
  const cartQuantity = countItemsInCart(dmeOrder.lineItemGroups)

  useWebSockets(
    { channel: "EventsChannel", id: dmeOrder.id },
    {
      received(event: Event) {
        if (isComment(event) && currentTabRef.current !== SidebarTab.Activity) {
          setShowActivityBadge(true)
        }
      },
    }
  )

  return (
    <div className={styles.container}>
      <TabToggleButton
        currentTab={currentTab}
        setTab={changeTab}
        tab={SidebarTab.Activity}
        icon={<Forum />}
        label="Activity Feed"
        badge={showActivityBadge}
      />
      <TabToggleButton
        currentTab={currentTab}
        setTab={changeTab}
        tab={SidebarTab.Documents}
        icon={<Assignment />}
        label="Documents"
      />
      <TabToggleButton
        currentTab={currentTab}
        setTab={changeTab}
        tab={SidebarTab.Cart}
        icon={<ShoppingCart />}
        label={`Cart (${cartQuantity})`}
        collapsedLabel={`(${cartQuantity})`}
      />
      {showTransferTab && (
        <TabToggleButton
          currentTab={currentTab}
          setTab={changeTab}
          tab={SidebarTab.SupplierTransfer}
          icon={<SwapHorizontalCircle />}
          label="Transfer"
        />
      )}
      <KnowledgeBaseButton launchKnowledgeBase={launchKnowledgeBase} />
    </div>
  )
}

export default TopRightInOrderNavContainer
