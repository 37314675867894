import { employerPrefix } from "utilities/url"

export const makeUrlProvider = (employerType, employerId) => {
  const urlPrefix = employerPrefix(employerType, employerId)
  return {
    createDmeOrderUrl: `${urlPrefix}/orders.json`,
    showDmeOrderUrl: (id: string) => `${urlPrefix}/orders/${id}`,
    reorderDmeOrderUrl: (id: string) =>
      `${urlPrefix}/orders/${id}/reorder.json`,
    showAsyncDmeOrderUrl: (token: string) =>
      `${urlPrefix}/creating_order/${token}`,
    updateHospiceUrl: (patientId: string) =>
      `${urlPrefix}/patients/${patientId}.json`,
    facilitiesSearchUrl: `${urlPrefix}/facilities_search.json`,
    sendEmrResetPasswordEmailUrl: `/emr/reset_passwords`,
  }
}
