import React from "react"
import classNames from "classnames"
import * as styles from "./SidebarSubOption.module.scss"
import Link from "components/Link"
import Badge from "components/Badge"

export interface SidebarSuboption {
  text: string
  href?: string
  active?: boolean
  badgeEnabled?: boolean
  onClick?: (e?: React.MouseEvent<HTMLAnchorElement>) => void
}

const SidebarSubOption = ({
  text,
  href,
  active,
  badgeEnabled,
  onClick,
}: SidebarSuboption) => {
  if (badgeEnabled) {
    const dotStyles: Partial<CSSStyleDeclaration> = {
      transform: "translate(0.65rem, -0.065rem)",
    }
    return (
      <Link
        href={href}
        onClick={onClick}
        className={classNames(
          styles.subOption,
          "d-flex align-items-center canopy-mis-4x canopy-pis-4x",
          {
            [styles.active]: active,
          }
        )}
      >
        <Badge dotStyles={dotStyles}>{text}</Badge>
      </Link>
    )
  }
  return (
    <Link
      onClick={onClick}
      href={href}
      className={classNames(
        styles.subOption,
        "d-flex align-items-center canopy-mis-4x canopy-pis-4x",
        {
          [styles.active]: active,
        }
      )}
    >
      <div>{text}</div>
    </Link>
  )
}

export default SidebarSubOption
