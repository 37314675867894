import Button from "components/form/Button"
import React from "react"
import { navigate } from "utilities/navigation"
import * as urls from "../../../FacilitySignUp/AccountCompletion/urls"
import { EventCategory, trackEvent } from "utilities/tracking"

interface Props {
  doctorId: string
}

function ContinueSigningUpButton({ doctorId }: Props) {
  const onButtonClick = () => {
    void trackEvent(
      EventCategory.ResupplySignUp,
      "promo_continue_submitted_resupply"
    )
    navigate(
      urls.joinFacilityUrl({
        reviewer_dashboard_doctor_id: doctorId,
      })
    )
  }

  return (
    <Button className="btn btn-brand canopy-mbe-12x" onClick={onButtonClick}>
      Continue signing up
    </Button>
  )
}

export default ContinueSigningUpButton
