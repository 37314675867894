import React from "react"
import { Select } from "components/form"
import {
  convertValueToOption,
  filterOptionsByQuery,
} from "components/form/Select"
import Suggestion from "components/form/Select/Suggestion"

function RelationshipSelect() {
  const relationshipOptions = [
    "Spouse",
    "Parent",
    "Child",
    "Grandparent",
    "Friend",
  ]

  const options = relationshipOptions.map(convertValueToOption)

  return (
    <Select
      name="relationship"
      label="Relationship"
      placeholder="Select relationship"
      fetchOptions={filterOptionsByQuery(options)}
      openMenuOnFocus
      minLength={0}
      debounce={false}
      isSearchable
      isClearable
      renderOtherOption={(option, { query }) =>
        query.length > 0 && <Suggestion>+ Add “{query}”</Suggestion>
      }
    />
  )
}

export default RelationshipSelect
