import React, { useState } from "react"
import { ConsignmentCloset } from "sharedTypes"
import Modal from "components/Modal"
import Checkbox from "../Checkbox"
import { Form, RadioButtons } from "components/form"
import Button from "components/form/Button"
import { handleError } from "utilities/error"
import { closetLabel } from "../utils"
import * as styles from "./index.module.scss"

type Props = {
  checked: boolean
  toggle(): void
  defaultClosetId: string
  closets: ConsignmentCloset[]
  selectDefaultCloset(id: string): Promise<void>
}

const ConsignmentClosetsFilter = ({
  defaultClosetId,
  checked,
  toggle,
  closets,
  selectDefaultCloset,
}: Props) => {
  const [showModal, setShowModal] = useState(false)
  const [submitting, setSubmitting] = useState(false)

  if (closets.length === 0) {
    return null
  }

  if (closets.length === 1) {
    return (
      <>
        <h3 className={styles.productFilterTitle}>Consignment</h3>
        <Checkbox
          checked={checked}
          toggle={toggle}
          enabled
          name={closetLabel(closets[0])}
          nowrap
        />
      </>
    )
  }

  const defaultCloset =
    defaultClosetId &&
    closets.find(({ externalId }) => externalId === defaultClosetId)
  const closetOptions = closets.map((closet) => ({
    label: closetLabel(closet),
    value: closet.externalId,
  }))

  const closeModal = () => setShowModal(false)
  const onSubmit = ({ consignmentClosetId }) => {
    setSubmitting(true)
    selectDefaultCloset(consignmentClosetId)
      .then(() => {
        closeModal()
        setSubmitting(false)
      })
      .catch((error) => {
        setSubmitting(false)
        handleError(error)
      })
  }
  return (
    <>
      <h3 className={styles.productFilterTitle}>Consignment</h3>
      {defaultCloset ? (
        <div className="canopy-mbe-8x">
          <Checkbox
            checked={checked}
            toggle={toggle}
            enabled
            name={closetLabel(defaultCloset)}
            nowrap
          />
          <a className="link" onClick={() => setShowModal(true)}>
            <small>Change Closet</small>
          </a>
        </div>
      ) : (
        <button
          className="btn btn-primary-o btn-block canopy-mbe-8x"
          type="button"
          onClick={() => setShowModal(true)}
        >
          Manage Closets
        </button>
      )}
      <Modal
        title="Manage Closets"
        show={showModal}
        cancel={closeModal}
        size="md"
      >
        <Form
          onSubmit={onSubmit}
          initialValues={{ consignmentClosetId: defaultClosetId }}
        >
          <Modal.Body>
            <h4>Consignment Closet Default</h4>
            <p className="canopy-mbe-8x">
              Select the closet you use most frequently
            </p>
            <RadioButtons
              name="consignmentClosetId"
              options={closetOptions}
              block
            />
          </Modal.Body>
          <Modal.Footer>
            <div className="text-right">
              <button
                type="button"
                className="btn btn-brand-o canopy-mie-4x"
                onClick={closeModal}
              >
                Cancel
              </button>
              <Button type="submit" className="btn-brand" loading={submitting}>
                Save
              </Button>
            </div>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  )
}

export default ConsignmentClosetsFilter
