import Link from "components/Link"
import { notificationPreferencesPath } from "../../urls"
import React from "react"
import { Doctor } from "../../sharedTypes"

const ChangeInUserPreferencesLink = ({ doctor }: { doctor: Doctor }) => (
  <Link href={notificationPreferencesPath(doctor.id)}>
    Change in User Preferences.
  </Link>
)

export default ChangeInUserPreferencesLink
