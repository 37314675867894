import React from "react"
import Alert from "components/Alert"

const NoRequestedWarning = () => {
  return (
    <Alert leftIcon status="info">
      <strong> No documents requested. </strong> The supplier has not requested
      any documentation.
    </Alert>
  )
}

export default NoRequestedWarning
