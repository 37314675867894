import React from "react"
import { Select } from "components/form"
import { GroupedOptions, Option } from "components/form/Select"
import { Supplier } from "./sharedTypes"
import SupplierSuggestion from "./SupplierSuggestion"
import NoResults from "components/form/Select/Suggestion/NoResults"

interface Props {
  onChange(_: string, supplier?: Supplier): void
  stateName: string
  supplierSearch: (query: string) => Promise<Supplier[]>
  unwrapped: boolean
}

const SupplierSelect: React.FC<Props> = ({
  onChange,
  stateName,
  supplierSearch,
  unwrapped,
}) => {
  const suppliersToOptions = (suppliers: Supplier[]): Option[] =>
    suppliers.map(({ name, externalId, inServiceArea, connected }) => ({
      label: name,
      value: externalId,
      inServiceArea: inServiceArea,
      connected: connected,
    }))

  const fetchOptions = (term: string): Promise<Option[] | GroupedOptions[]> =>
    supplierSearch(term).then((suppliers: Supplier[]) => [
      {
        label: `SERVICES ${stateName.toUpperCase()}`,
        options: suppliersToOptions(
          suppliers.filter(({ inServiceArea }) => inServiceArea)
        ),
      },
      {
        label: `MAY NOT SERVICE ${stateName.toUpperCase()}`,
        options: suppliersToOptions(
          suppliers.filter(({ inServiceArea }) => !inServiceArea)
        ),
      },
    ])

  const MIN_LENGTH = 2

  return (
    <Select
      fetchOptions={fetchOptions}
      isClearable
      isSearchable
      label="Supplier"
      name="supplierId"
      minLength={MIN_LENGTH}
      onChange={onChange}
      options={[]}
      placeholder="Search suppliers on Parachute"
      unwrapped={unwrapped}
      renderOption={(supplier, { query, isHighlighted }) => (
        <SupplierSuggestion
          supplier={supplier}
          query={query}
          isHighlighted={isHighlighted}
        />
      )}
      renderEmpty={(query) =>
        query.length >= MIN_LENGTH && <NoResults query={query} />
      }
    />
  )
}

export default SupplierSelect
