import { InsurancePlanType, PaymentMethod } from "sharedTypes"

const FORMATTED_PAYMENT_METHODS = {
  [PaymentMethod.Insurance]: "Bill Insurance",
  [PaymentMethod.Pps]: "PPS",
  [PaymentMethod.PrivatePay]: "Private Pay",
  [PaymentMethod.Authorization]: "Pre-Authorization",
  [PaymentMethod.Hospice]: "Hospice",
}

export const formatPaymentMethod = (paymentMethod: PaymentMethod) =>
  FORMATTED_PAYMENT_METHODS[paymentMethod]
export const isDefaultPaymentMethod = (paymentMethod: PaymentMethod) =>
  paymentMethod === PaymentMethod.Insurance
export const paymentMethods = () => Object.values(PaymentMethod)
export const insurancePlanTypes = () => Object.values(InsurancePlanType)
