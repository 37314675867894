import React from "react"

type Props = {
  children: React.ReactNode
}

const LabelSubtext = ({ children }: Props) => (
  <p className="font-subparagraph regular color-dark-gray canopy-mbe-2x">
    {children}
  </p>
)

export default LabelSubtext
