import React, { Dispatch, SetStateAction } from "react"
import { Grid, Paper } from "@material-ui/core"
import SignatureRequestsDrawer from "./SignatureRequestsDrawer"
import SignatureRequestCard from "./SignatureRequestCard"
import { Spacer } from "../../Spacer"
import { theme } from "themes/theme"
import SignatureRequestDetailView from "../../SignatureRequestDetailView"
import { Doctor } from "../../../sharedTypes"
import { DoctorSignatureRequest } from "../../../api"
import * as styles from "../index.module.scss"
import SeverityIcon from "components/SeverityIcon"
import { Severity } from "sharedTypes"
import classNames from "classnames"

interface Props {
  doctor: Pick<Doctor, "id" | "name" | "npi" | "signOnScreenEnabled">
  selectedSignatureRequestGid: string
  setSelectedSignatureRequestGid: Dispatch<
    SetStateAction<DoctorSignatureRequest["gid"]>
  >
  onSignatureRequestReviewedOrDenied?: () => void
  signatureRequests: DoctorSignatureRequest[]
  notificationBadgeStatusChecker: () => void
  patientSearchQuery?: string
  setPatientSearchQuery: (string) => void
  loadingSignatureRequests: boolean
}

const SignatureRequestsToReview = ({
  doctor,
  selectedSignatureRequestGid,
  setSelectedSignatureRequestGid,
  onSignatureRequestReviewedOrDenied,
  signatureRequests,
  notificationBadgeStatusChecker,
  patientSearchQuery,
  setPatientSearchQuery,
  loadingSignatureRequests,
}: Props) => {
  const noSearchResults = !signatureRequests.length && !loadingSignatureRequests
  return (
    <>
      <Grid item data-testid="signature-request-select-column">
        <SignatureRequestsDrawer
          selectedId={selectedSignatureRequestGid}
          setSelectedSignatureRequestGid={setSelectedSignatureRequestGid}
          signatureRequests={signatureRequests.map((s) => ({
            id: s.gid,
            displayText: <SignatureRequestCard signatureRequest={s} />,
          }))}
          noSearchResults={noSearchResults}
          patientSearchQuery={patientSearchQuery}
          setPatientSearchQuery={setPatientSearchQuery}
        />
      </Grid>
      <Spacer width={theme.spacing(1)} />
      {noSearchResults ? (
        <Grid item xs>
          <Paper className={classNames(styles.paper, styles.noResults)}>
            <SeverityIcon className={styles.icon} type={Severity.Warning} />
            <span className={styles.message}>No Results.</span>
            <h2>Try searching for the patient under another clinician.</h2>
          </Paper>
        </Grid>
      ) : (
        <Grid item xs data-testid="signature-request-details">
          <SignatureRequestDetailView
            onReviewedOrDenied={onSignatureRequestReviewedOrDenied}
            doctorId={doctor.id}
            signatureRequest={signatureRequests.find(
              (s) => s.gid === selectedSignatureRequestGid
            )}
            notificationBadgeStatusChecker={notificationBadgeStatusChecker}
          />
        </Grid>
      )}
    </>
  )
}

export default SignatureRequestsToReview
