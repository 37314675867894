// @ts-strict-ignore
import React from "react"
import { DmeOrder, NetworkCoverage, Supplier } from "sharedTypes"
import { SupplierContactDetails } from "../../sharedTypes"
import SupplierHeader from "./SupplierHeader"
import AllProductsAndServicesHeader from "./AllProductsAndServicesHeader"
import SelectSupplierLink from "../SelectSupplierLink"

type Props = {
  dmeOrder: DmeOrder
  networkCoverage: NetworkCoverage | undefined
  supplier: Supplier | undefined
  supplierContactDetails: SupplierContactDetails
}

const Header: React.FC<Props> = (props) => {
  const { dmeOrder } = props

  const supplierSpecificHeaderDetails = () => {
    if (props.supplier) return <SupplierHeader {...props} />
    return <AllProductsAndServicesHeader />
  }

  return (
    <>
      <SelectSupplierLink dmeOrder={dmeOrder} />
      {supplierSpecificHeaderDetails()}
    </>
  )
}

export default Header
