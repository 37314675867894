import React from "react"
import { DmeOrder } from "sharedTypes"
import InternalSupplierList from "./InternalSupplierList"

type Props = {
  dmeOrder: DmeOrder
}

const SupplierList = ({ dmeOrder }: Props) => {
  const { manageFulfillmentAgreements } = dmeOrder.permissions

  return (
    <InternalSupplierList
      manageFulfillmentAgreements={manageFulfillmentAgreements}
      usesEnterpriseFeatures={dmeOrder.clinicalFacility.usesEnterpriseFeatures}
    />
  )
}

export default SupplierList
