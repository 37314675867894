import React from "react"
import classNames from "classnames"
import { Link } from "react-router-dom"
import { productsPath } from "../routes"

type Props = {
  path: string
  className?: string
  disabled?: boolean
  isButtonOutlined?: boolean
  block?: boolean
}

const ContinueButton = ({
  className,
  path,
  disabled,
  isButtonOutlined,
  block,
}: Props) => {
  const onClick = (event) => {
    event.stopPropagation()
  }
  const classes =
    className ||
    classNames("btn btn-default btn-full-xs continue", {
      "btn-brand-o": isButtonOutlined,
      "btn-brand": !isButtonOutlined,
      "btn-block": block,
    })
  if (disabled) {
    return (
      <button type="button" className={classes} disabled>
        Continue
      </button>
    )
  }

  return (
    <Link
      className={classes}
      onClick={onClick}
      to={{
        pathname: path,
        state: { autoOpenCartTab: path.startsWith(productsPath()) },
      }}
      data-analytics-context="Workflow Navigation"
      data-analytics="continue-button-clicked"
    >
      Continue
    </Link>
  )
}

export default ContinueButton
