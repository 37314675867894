import React from "react"
import Dropdown from "components/Dropdown"

interface Props {
  initialSort: string
  setInitialSort(sort: string): void
}
function SortBySelect({ initialSort, setInitialSort }: Props) {
  return (
    <Dropdown
      menuClassName="dropdown-right"
      label={`Sort by: ${initialSort}`}
      options={[
        "Most recent activity",
        "Earliest delivery date",
        "Latest delivery date",
        "Most recent orders",
        "Oldest orders",
      ].map((text) => ({ label: text, checked: initialSort === text }))}
      onClick={(option) => {
        setInitialSort(option.label)
      }}
    />
  )
}

export default SortBySelect
