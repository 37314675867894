import React from "react"
import InfoTooltip from "../../../../components/InfoTooltip"

type Props = {
  message: string
}

/**
 * This is a simple presentational component; we indicate in multiple locations
 * when a seed contains a reference to an archive global catalog item. To enforce
 * consistency and centralize styling, each such instance will use this same
 * compontent (with a slightly different message).
 */
const ArchivedLabel: React.FC<Props> = ({
  message,
}: Props): React.JSX.Element => {
  return (
    <InfoTooltip
      title={message}
      icon="triangle-exclamation"
      className="color-danger canopy-mis-6x"
    />
  )
}

export default ArchivedLabel
