import * as React from "react"
import { Dispatch, SetStateAction } from "react"
import { EventCategory, trackEvent } from "utilities/tracking"
import {
  CanopyComboboxField,
  OptionItem,
} from "@parachutehealth/canopy-combobox-field"

type Props = {
  onInputChange: (newValue: string) => Promise<void>
  selectedSalesRep: OptionItem
  setSelectedSalesRep: Dispatch<SetStateAction<OptionItem>>
  userOptions: OptionItem[]
  userOptionsLoading: boolean
}

const trackSalesRepChange = () => {
  void trackEvent(
    EventCategory.ReassignSalesRep,
    "reassign_sales_rep_selection_clicked"
  )
}

const ReassignSalesRepsForm: React.FC<Props> = ({
  onInputChange,
  selectedSalesRep,
  setSelectedSalesRep,
  userOptions,
  userOptionsLoading,
}: Props) => {
  const handleChange = (newValue) => {
    setSelectedSalesRep(newValue)
  }

  return (
    <CanopyComboboxField
      className="form-group"
      label="Select a sales rep"
      placeholder="Select sales rep"
      loading={userOptionsLoading}
      options={userOptions}
      onInputChange={onInputChange}
      onFocus={trackSalesRepChange}
      onChange={handleChange}
      noOptionsAvailableMessage="Search by name or email"
      noOptionsFoundMessage="No users found"
      value={selectedSalesRep}
    />
  )
}

export default ReassignSalesRepsForm
