// @ts-strict-ignore
import React from "react"
import { Link } from "react-router-dom"
import { DmeOrder, SignatureType } from "sharedTypes"
import { doctorNameWithCredential } from "utilities/doctor"
import { formatPhone } from "utilities/phone"
import { capitalize } from "utilities/string"
import { reviewSignaturePath } from "applications/Workflow/routes"
import DataRow from "components/DataRow"
import SendSignatureReminderButton from "../SendSignatureReminderButton"
import { Permissions, SignatureRequest } from "../../sharedTypes"
import { lastRequestedAt } from "../../utilities/signature"

type Props = {
  dmeOrder: DmeOrder
  permissions: Permissions
  sendSignatureRequest(params: {}): Promise<void>
  signatureRequests: SignatureRequest[]
}

const SignatureSectionContent = (props: Props) => {
  const {
    dmeOrder,
    permissions,
    sendSignatureRequest,
    signatureRequests,
  } = props
  const { doctor } = dmeOrder

  const signatureChannelDoctorAttributeMapping = {
    email: "maskedEmail",
    fax: "faxNumber",
    sms: "maskedMobileNumber",
  }

  const doctorAttributeFor = (channel) =>
    signatureChannelDoctorAttributeMapping[channel]

  const getActiveChannels = (signatureChannels) =>
    Object.keys(signatureChannels).filter((c) => signatureChannels[c])

  const requestMethodValues = () => {
    switch (dmeOrder.signatureType) {
      case SignatureType.Manual:
        return ["Print and Attach"]
      case SignatureType.Digital:
        if (doctor) {
          const activeChannels = getActiveChannels(dmeOrder.signatureChannels)
          if (activeChannels.length) {
            return activeChannels.map((channel) => {
              let value = capitalize(channel)
              if (channel !== "epic") {
                value += ` - ${
                  doctor[doctorAttributeFor(channel)] || "Awaiting verification"
                }`
              }
              return value
            })
          }
        }
        break
      case SignatureType.OnScreen:
        return ["Sign On-Screen"]
    }
    return ["-"]
  }

  const renderRequestMethods = () => {
    return requestMethodValues().map((value, index) => {
      return (
        <DataRow
          label={index === 0 ? "Request Methods" : null}
          value={value}
          key={value}
        />
      )
    })
  }

  return (
    <>
      <DataRow
        label="Clinician"
        value={
          doctor && `${doctorNameWithCredential(doctor)} (NPI: ${doctor.npi})`
        }
      />
      {renderRequestMethods()}
      {doctor && (
        <DataRow label="Office Phone" value={formatPhone(doctor.phoneNumber)} />
      )}
      {permissions.updateSignature && (
        <DataRow
          label=""
          value={
            <Link className="link" to={reviewSignaturePath()}>
              Update Clinician Information
            </Link>
          }
        />
      )}

      {permissions.sendSignatureReminder && sendSignatureRequest && (
        <>
          <DataRow
            label="Last Request"
            value={lastRequestedAt(signatureRequests)}
          />
          <DataRow
            label=""
            value={
              <SendSignatureReminderButton
                className="btn btn-brand-o"
                sendSignatureRequest={sendSignatureRequest}
                disabled={dmeOrder.transferredOrderLocked}
              />
            }
          />
        </>
      )}
    </>
  )
}

export default SignatureSectionContent
