// extracted by mini-css-extract-plugin
var _1 = "app-javascript-applications-Workflow-containers-SupplierList-SupplierCard-ParachuteSupplierCard-index-module__bold-efax-disclaimer--NcNVU";
var _2 = "app-javascript-applications-Workflow-containers-SupplierList-SupplierCard-ParachuteSupplierCard-index-module__caution-icon--VDHw5";
var _3 = "app-javascript-applications-Workflow-containers-SupplierList-SupplierCard-ParachuteSupplierCard-index-module__disclaimer-container--SE9kz";
var _4 = "app-javascript-applications-Workflow-containers-SupplierList-SupplierCard-ParachuteSupplierCard-index-module__efax-container--xMl14";
var _5 = "app-javascript-applications-Workflow-containers-SupplierList-SupplierCard-ParachuteSupplierCard-index-module__efax-disclaimer--dxBJI";
var _6 = "app-javascript-applications-Workflow-containers-SupplierList-SupplierCard-ParachuteSupplierCard-index-module__header--EOPVe";
var _7 = "app-javascript-applications-Workflow-containers-SupplierList-SupplierCard-ParachuteSupplierCard-index-module__pinned-icon--EZ0nQ";
var _8 = "app-javascript-applications-Workflow-containers-SupplierList-SupplierCard-ParachuteSupplierCard-index-module__promoted-copy--Qzxgj";
var _9 = "app-javascript-applications-Workflow-containers-SupplierList-SupplierCard-ParachuteSupplierCard-index-module__promoted-insight--hQcLG";
export { _1 as "boldEfaxDisclaimer", _2 as "cautionIcon", _3 as "disclaimerContainer", _4 as "efaxContainer", _5 as "efaxDisclaimer", _6 as "header", _7 as "pinnedIcon", _8 as "promotedCopy", _9 as "promotedInsight" }
