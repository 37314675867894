import React from "react"
import {
  getDeliveryDates,
  DeliveryDateType,
  DeliveryDate,
} from "utilities/deliveryDate"
import { format } from "utilities/date"
import * as styles from "./index.module.scss"

function DeliveryDates({ fulfillments, createdAt }) {
  const deliveryDates = getDeliveryDates({ fulfillments, createdAt })

  const renderDate = (value, text?) => {
    return (
      <span key={value + text} className={styles.trailingComma}>
        {format(value)}{" "}
        {text && <span className="color-dark-gray">({text})</span>}
      </span>
    )
  }

  const renderDeliveryDate = ({ type, value }: DeliveryDate) => {
    switch (type) {
      case DeliveryDateType.Actual:
        return renderDate(value, "Delivered")
      case DeliveryDateType.Expected:
        return renderDate(value, "Expected")
      case DeliveryDateType.Requested:
        return renderDate(value, "Requested")
      case DeliveryDateType.CreatedAt:
        return renderDate(value, "Created")
      default:
        return renderDate(value)
    }
  }

  return <>{deliveryDates.map(renderDeliveryDate)}</>
}

export default DeliveryDates
