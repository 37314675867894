// @ts-strict-ignore
import React, { useState } from "react"
import ToggleFilter from "components/ToggleFilter"
import { SignatureRequestQueryParameters, signatureStatus } from "../../../api"

export const ResolutionStatusToggleFilter: React.FC<{
  updateSearchParameters(
    updatedParameters: Partial<SignatureRequestQueryParameters>
  ): void
  initialState:
    | signatureStatus.DONE
    | signatureStatus.DENIED
    | signatureStatus.SIGNED
}> = ({ updateSearchParameters, initialState }) => {
  const [statusFilter, setStatusFilter] = useState<boolean | null>(
    {
      [signatureStatus.SIGNED]: true,
      [signatureStatus.DENIED]: false,
    }[initialState]
  )
  const onChange = async (values) => {
    if (values.status === true) {
      updateSearchParameters({
        status: signatureStatus.SIGNED,
      })
    } else if (values.status === false) {
      updateSearchParameters({
        status: signatureStatus.DENIED,
      })
    } else {
      updateSearchParameters({
        status: signatureStatus.DONE,
      })
    }
    setStatusFilter(values.status)
  }
  return (
    <ToggleFilter
      name="status"
      options={[
        { label: "Signed", value: true },
        { label: "Denied", value: false },
      ]}
      initialValue={statusFilter}
      onChange={onChange}
    />
  )
}
