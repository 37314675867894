import { post } from "services/api"
import { updateSupplierServiceAreaStatesUrl } from "./urls"

export const updateSupplierServiceAreas = (
  supplierId: string,
  serviceAreaStates: string[]
) =>
  post(updateSupplierServiceAreaStatesUrl(supplierId), { serviceAreaStates })
    .then(({ data }) => data)
    .catch(({ response }) => Promise.reject(response.data))
