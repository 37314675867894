// @ts-strict-ignore
import { get, post, put } from "services/api"
import * as urls from "./urls"
import {
  FacilitySearchResult,
  FacilityFormParams,
  FacilityUpdateParams,
} from "./sharedTypes"

export const searchFacilities = (
  term: string
): Promise<FacilitySearchResult[]> =>
  get(urls.facilitiesSearchUrl(), { term })
    .then(({ data }) => data.results)
    .catch(({ response }) => Promise.reject(response.data))

export const fetchFacilityDetails = (externalId?: string, npi?: string) =>
  get(urls.facilityDetailsUrl(), { externalId, npi })
    .then(({ data }) => data)
    .catch(({ response }) => Promise.reject(response.data))

export const updateFacility = (params: FacilityUpdateParams) =>
  put(urls.facilitiesUpdateUrl(), { ...params })
    .then(({ data }) => data)
    .catch(({ response }) => Promise.reject(response.data))

export const createFacility = (params: FacilityFormParams) => {
  const { signedAgreement, ...remainingParams } = params
  return post(urls.facilitiesCreateUrl(), {
    clinicalFacility: { ...remainingParams },
    signedAgreement,
  })
    .then(({ data }) => data)
    .catch(({ response }) => Promise.reject(response.data))
}
