import React from "react"
import { asForm, PhoneField } from "components/form"

type Props = {
  isValid: boolean
}

const MobileNumberForm = ({ isValid }: Props) => {
  return (
    <>
      <PhoneField label="Mobile Number" name="mobileNumber" />
      <div className="text-right">
        <button type="submit" className="btn btn-primary" disabled={!isValid}>
          Send SMS Verification
        </button>
      </div>
    </>
  )
}

export default asForm(MobileNumberForm)
