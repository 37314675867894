import React from "react"
import { Form, TextField, Button } from "components/form"
import {
  createPatientPortalMessageTemplate,
  updatePatientPortalMessageTemplate,
} from "../api"
import { messageTemplatesUrl } from "../urls"
import { navigate } from "utilities/navigation"
import { Field } from "formik"
import TemplateFormField from "../TemplateFormField"

interface Props {
  messageTemplate: { id: string; name: string; template: string }
}

const PatientPortalMessageTemplateForm: React.FC<Props> = ({
  messageTemplate,
}) => {
  const createOrUpdate = messageTemplate.id
    ? updatePatientPortalMessageTemplate(messageTemplate.id)
    : createPatientPortalMessageTemplate

  const onSubmit = (values, { setErrors }) => {
    createOrUpdate(values)
      .then(() => navigate(messageTemplatesUrl()))
      .catch(({ errors }) => setErrors(errors))
  }

  const validateName = (value: string) => {
    if (!value) {
      return "Required"
    } else if (value.length > 30) {
      return "Must be 30 characters or less"
    }
  }

  const validateMessage = (value: string) => !value

  return (
    <Form
      initialValues={{
        name: messageTemplate.name || "",
        template: messageTemplate.template || "",
      }}
      onSubmit={onSubmit}
    >
      {({ errors, isSubmitting, values }) => (
        <>
          <TextField
            label="Template Name"
            name="name"
            placeholder="Enter template name"
            validate={validateName}
          />
          <Field name="template" validate={validateMessage}>
            {({ field, form }) => (
              <TemplateFormField
                field={field}
                form={form}
                values={values}
                label="Message"
              />
            )}
          </Field>
          <Button
            type="submit"
            className="btn-primary"
            disabled={!!errors.name || errors.template || isSubmitting}
          >
            Save
          </Button>
        </>
      )}
    </Form>
  )
}

export default PatientPortalMessageTemplateForm
