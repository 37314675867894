import React from "react"
import * as style from "./index.module.scss"
import { ArrowBack } from "@material-ui/icons"

const BackArrowIcon = () =>
  React.cloneElement(<ArrowBack />, {
    className: style.icon,
  })

export default BackArrowIcon
