import React, { useEffect } from "react"
import Browsing from "../Browsing/Browsing"
import SupplierList from "../SupplierList"
import { DmeOrder, CatalogBrowseTab } from "sharedTypes"
import { match } from "react-router-dom"

interface SearchBySupplierTabProps {
  dmeOrder: DmeOrder
  defaultSearchByProduct: boolean
  yourOrganizationsSuppliersOnly: boolean
  toggleYourOrganizationsSuppliersOnly: () => void
  setSelectedServiceAreaStateFilter: (state: string) => void
  currentTab: CatalogBrowseTab
  match: match<any>
  setShowSubheader: (hide: boolean) => void
}

const SearchBySupplierTab = ({
  dmeOrder,
  match,
  defaultSearchByProduct,
  yourOrganizationsSuppliersOnly,
  toggleYourOrganizationsSuppliersOnly,
  setSelectedServiceAreaStateFilter,
  currentTab,
  setShowSubheader,
}: SearchBySupplierTabProps) => {
  const dmeOrderSupplier = dmeOrder.supplier
  const matchSupplierId = (match): string | undefined => {
    if (dmeOrderSupplier) {
      return dmeOrderSupplier.externalId
    }
    if (match.params.supplierId) {
      return match.params.supplierId
    }
  }
  const supplierId = matchSupplierId(match)

  const browsingACatalog = supplierId || defaultSearchByProduct
  const isListSuppliersRoute = supplierId === "list"

  const showSupplierList = isListSuppliersRoute || !browsingACatalog

  useEffect(() => {
    setShowSubheader(showSupplierList)
  }, [setShowSubheader, showSupplierList])

  if (showSupplierList) {
    return <SupplierList dmeOrder={dmeOrder} />
  }

  return (
    <Browsing
      dmeOrder={dmeOrder}
      supplierId={supplierId}
      currentCatalogBrowseTab={currentTab}
      yourOrganizationsSuppliersOnly={yourOrganizationsSuppliersOnly}
      toggleYourOrganizationsSuppliersOnly={
        toggleYourOrganizationsSuppliersOnly
      }
      setSelectedServiceAreaStateFilter={setSelectedServiceAreaStateFilter}
    />
  )
}

export default SearchBySupplierTab
