import React, { ReactElement } from "react"
import { Box, Button, Fade, Menu, MenuItem, MenuProps } from "@material-ui/core"
import { CanopyIcon } from "@parachutehealth/canopy-icon"
import "./index.module.scss"

export type Row = Record<string, any>

export type Action = {
  label: string | ReactElement
  onClick?: (row: Row) => void
}

export type RowMenuProps = Omit<MenuProps, "open"> & {
  row: Row
  actions: Action[]
  style?: any
}

export const RowMenu: React.FC<RowMenuProps> = (props: RowMenuProps) => {
  const { row, actions, ...menuProps } = props

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)

  const handleLinkClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault()
    setAnchorEl(event.currentTarget)
  }

  const handleMenuItemClick = (event: React.MouseEvent<HTMLLIElement>) => {
    event.preventDefault()
    setAnchorEl(null)
  }

  const handleMenuClose = () => {
    setAnchorEl(null)
  }

  return (
    <div>
      <Button
        href="#"
        role="button"
        onClick={handleLinkClick}
        endIcon={<CanopyIcon name="caret-down" />}
        variant="text"
        disableRipple
        style={props.style}
      >
        Actions
      </Button>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        TransitionComponent={Fade}
        TransitionProps={{ timeout: 100 }}
        {...menuProps}
      >
        {actions.map((action, index) => (
          <Box key={index} minWidth={260}>
            <MenuItem
              onClick={(event: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
                handleMenuItemClick(event)
                if (action.onClick) {
                  action.onClick(row)
                }
              }}
            >
              {action.label}
            </MenuItem>
          </Box>
        ))}
      </Menu>
    </div>
  )
}

export default RowMenu
