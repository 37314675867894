import React from "react"
import Modal from "components/Modal"
import { requestOutsideSupplier } from "../AddSupplierModal"
import RequestSupplierForm from "../AddSupplierModal/RequestSupplierForm"
import {
  CreateRequestResponse,
  RequestSupplierFormFields,
} from "../AddSupplierModal/sharedTypes"
import { createRequest } from "../AddSupplierModal/api"
import { getAddSupplierMessage } from "../AddSupplierModal/utilities/message"
import { handleError } from "utilities/error"

interface Props {
  showModal: boolean
  onCancel: () => void
  afterSubmit: (message: string) => Promise<void>
}

const RequestSupplierModal = ({ showModal, onCancel, afterSubmit }: Props) => {
  const onSubmit = (values: RequestSupplierFormFields) =>
    createRequest(values)
      .then(({ fulfillmentAgreement }: CreateRequestResponse) => {
        return afterSubmit(getAddSupplierMessage({ fulfillmentAgreement }))
      })
      .catch(handleError)

  return (
    <Modal
      show={showModal}
      cancel={onCancel}
      title={requestOutsideSupplier}
      size="md"
    >
      <Modal.Body>
        <RequestSupplierForm onCancel={onCancel} onSubmit={onSubmit} />
      </Modal.Body>
    </Modal>
  )
}

export default RequestSupplierModal
