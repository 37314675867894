import { CanopyDialogModal } from "@parachutehealth/canopy-dialog-modal"
import { CanopyButton } from "@parachutehealth/canopy-button"
import React from "react"
import { User } from "../../../../../graphql/__generated__/graphql"

interface Props {
  loading: boolean
  onCancel: () => void
  onRemove: () => void
  user: User
}

export const RemoveUserDialogModal = ({
  loading,
  onCancel,
  onRemove,
  user,
}: Props) => {
  return (
    <CanopyDialogModal
      header={`Remove ${user.firstName} ${user.lastName}?`}
      onClose={onCancel}
      open
      primaryFooterButton={
        <CanopyButton onClick={onRemove} loading={loading}>
          Yes, remove user
        </CanopyButton>
      }
      secondaryFooterButton={
        <CanopyButton variant="secondary" onClick={onCancel}>
          Cancel
        </CanopyButton>
      }
    >
      {user.firstName} will be removed from your organization and no longer able
      to log in to Parachute.
    </CanopyDialogModal>
  )
}
