import React, { useEffect, useRef } from "react"
import { ErrorType } from "../sharedTypes"
import { newConfirmationUrl } from "../urls"
import { CanopyNotice } from "@parachutehealth/canopy-notice"

interface Props {
  error: string
  type: ErrorType
}

const LoginAlert: React.FC<Props> = ({ error, type }) => {
  // After pressing submit, the customer should be taken back up to the error message
  const noticeRef = useRef<HTMLDivElement>(null)
  useEffect(() => {
    noticeRef.current?.focus()
  }, [])

  switch (type) {
    case ErrorType.Invalid:
      return (
        <CanopyNotice
          message="Double-check email address or password and try again. If the issue persists, chat with support"
          ref={noticeRef}
          tabIndex={-1}
          title="Invalid email or password"
          variant="error"
        />
      )
    case ErrorType.Unconfirmed:
      return (
        <CanopyNotice
          actions={[
            {
              href: newConfirmationUrl(),
              text: "Click here to confirm your email address.",
            },
          ]}
          ref={noticeRef}
          tabIndex={-1}
          title="Email confirmation is required to log in."
          variant="error"
        />
      )
    default:
      return (
        <CanopyNotice
          ref={noticeRef}
          tabIndex={-1}
          title={error}
          variant="error"
        />
      )
  }
}

export default LoginAlert
