import { attempt } from "utilities/function"

const sessionStorage = {
  getItem(key: string) {
    return attempt(() => window.sessionStorage.getItem(key))
  },
  removeItem(key: string) {
    return attempt(() => window.sessionStorage.removeItem(key))
  },
  clear() {
    return attempt(() => window.sessionStorage.clear())
  },
  setItem(key: string, value) {
    return attempt(() => window.sessionStorage.setItem(key, value))
  },
}

export default sessionStorage
