import { Document } from "components/DocumentsBrowser/sharedTypes"

export const updateDocumentFileRotation = (
  documentState: Document,
  activeImageIndex: number,
  rotationDegrees: number
) => {
  const oldDocument = documentState
  const newDocument = {
    ...oldDocument,
    files: oldDocument.files.map((file, index) => {
      if (index === activeImageIndex) {
        return {
          ...file,
          rotation: (file.rotation + rotationDegrees) % 360,
        }
      }
      return file
    }),
  }
  return newDocument
}
