import { get, post } from "services/api"
import {
  supplierRejectUrl,
  supplierSendBackDataUrl,
  supplierSendBackUrl,
  supplierSendBackFollowablesUrl,
  supplierSendBackRecipientsUrl,
  trackRecommendationResultsUrl,
  currentOrderId,
  facilityInfoUrl,
} from "applications/Workflow/urls"
import {
  RejectFormValues,
  SendBackFormValues,
  SendBackRecipient,
} from "./sharedTypes"
import { GroupedSendBackRecipients } from "applications/Workflow/containers/Review/components/SupplierSendBack/SupplierSendBackForm"

export const getSupplierSendBackData = () => get(supplierSendBackDataUrl())
export const supplierSendBack = (
  values: SendBackFormValues,
  recommendationValues: string[]
) => {
  return post(supplierSendBackUrl(), values)
    .then(function () {
      post(trackRecommendationResultsUrl(), {
        dmeOrderId: currentOrderId(),
        recipients: values.recipients,
        recommendationEmployments: recommendationValues,
      })
    })
    .catch(({ response }) => Promise.reject(response.data))
}
export const supplierReject = (
  values: RejectFormValues,
  recommendationValues: string[]
) =>
  post(supplierRejectUrl(), values)
    .then(function () {
      post(trackRecommendationResultsUrl(), {
        dmeOrderId: currentOrderId(),
        recipients: values.recipients,
        recommendationEmployments: recommendationValues,
      })
    })
    .catch(({ response }) => Promise.reject(response.data))
export const searchSupplierFollowables = (term) => {
  return get(supplierSendBackFollowablesUrl(), { term })
    .then(({ data }) => data)
    .catch(({ response }) => Promise.reject(response.data))
}

type RecommendationsAndRecipients = {
  recommendations: SendBackRecipient[]
  recipients: SendBackRecipient[]
}

export const searchFacilityRecipients = (
  term: string,
  clinicalFacilityId?: string
): Promise<SendBackRecipient[] | GroupedSendBackRecipients[]> => {
  let params
  if (clinicalFacilityId) {
    params = { term, updatedClinicalFacilityId: clinicalFacilityId }
  } else {
    params = { term }
  }
  return get(supplierSendBackRecipientsUrl(), params)
    .then(
      ({
        data,
      }: {
        data: SendBackRecipient[] | RecommendationsAndRecipients
      }) => {
        if (data["recommendations"]) {
          return [
            {
              label: "Recommended by Parachute",
              options: data["recommendations"] as SendBackRecipient[],
            },
            {
              label: "Other User(s)",
              options: data["recipients"] as SendBackRecipient[],
            },
          ] as GroupedSendBackRecipients[]
        } else {
          return data as SendBackRecipient[]
        }
      }
    )
    .catch(({ response }) => Promise.reject(response.data))
}

export const getFacilityInfo = (facilityId: string) => {
  return get(facilityInfoUrl(), { facilityId })
    .then(({ data }) => data)
    .catch(({ response }) => Promise.reject(response.data))
}
