import { EmployerType } from "sharedTypes"

interface Employer {
  employerType: EmployerType
}
interface Context {
  currentEmployer: Employer
}
export const inOrderNavReplacement = ({ currentEmployer }: Context): boolean =>
  currentEmployer.employerType === EmployerType.ClinicalFacility
