// @ts-strict-ignore
import React, { useContext } from "react"
import { QuickListPackage, QuickListProductVariation } from "../sharedTypes"
import Checkboxes from "components/form/Checkboxes"
import { sortValue, trimmedProductVariationName } from "../utilities"
import GlobalContext from "context/Global"
import { numberToCurrency } from "utilities/price"

type Props = {
  filteredProductVariations: QuickListProductVariation[]
  pkg: QuickListPackage
  formularyPriceEnabled: boolean
}

const MISSING_PRICE_COPY = "Price unknown"

const PackageProductVariationOptions = (props: Props) => {
  const { filteredProductVariations, formularyPriceEnabled, pkg } = props
  const { featureFlags } = useContext(GlobalContext)

  const packageProductVariations = filteredProductVariations
    .filter((pv) => pv.packageIds[0] === pkg.externalId)
    .sort((pv1, pv2) => sortValue(pv1.description, pv2.description))

  const showPrice = formularyPriceEnabled && featureFlags.formularyPricing
  const callout = (productVariation: QuickListProductVariation) => {
    if (!showPrice) {
      return
    }

    const priceText = productVariation.price
      ? numberToCurrency(productVariation.price)
      : MISSING_PRICE_COPY

    return (
      <div className="float-right">
        <strong>{priceText}</strong>
      </div>
    )
  }

  const options = packageProductVariations.map((productVariation) => ({
    label: trimmedProductVariationName(productVariation.description, pkg.name),
    value: productVariation.externalId,
    callout: callout(productVariation),
  }))

  return (
    <Checkboxes
      id="productVariationIds"
      name="productVariationIds"
      options={options}
    />
  )
}

export default PackageProductVariationOptions
