import React from "react"
import Icon from "components/Icon"
import { formatAddress } from "utilities/address"
import { Address } from "sharedTypes"

type Props = {
  address: Address
  editAddress(address: Address): void
  label?: string
  children?: React.ReactNode
}

function AddressSection({ address, label, editAddress, children }: Props) {
  return (
    <div className="canopy-mis-8x canopy-pis-2x">
      {label && <p className="canopy-mbe-0 canopy-mbs-4x bold">{label}</p>}
      <p className="canopy-mbe-4x color-dark-gray">
        {formatAddress(address)}
        <a
          className="link link-primary canopy-mis-2x nowrap"
          onClick={() => editAddress(address)}
        >
          Edit
          <Icon type="pencil-alt" className="canopy-mis-2x" />
        </a>
      </p>
      {children}
    </div>
  )
}

export default AddressSection
