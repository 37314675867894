import React, { useContext, useEffect, useState } from "react"
import InternalCart from "./components/InternalCart"
import { DmeOrder } from "sharedTypes"
import { updateLineItemGroup, removeLineItemGroup } from "./api"
import { getInitialCartData } from "applications/Workflow/api"
import { Notifications } from "./components/sharedTypes"
import GlobalContext from "context/Global"

interface Props {
  dmeOrder: DmeOrder
  refreshDmeOrder(): Promise<void>
}
const Cart: React.FC<Props> = ({ dmeOrder, refreshDmeOrder }) => {
  const [notifications, setNotifications] = useState<Notifications>([])
  useEffect(() => {
    getInitialCartData().then((response) => {
      setNotifications(response.data.notifications)
    })
  }, [dmeOrder])
  const { featureFlags } = useContext(GlobalContext)

  const formularyPriceEnabled = !!(
    dmeOrder.clinicalFacility.formularyPriceEnabled &&
    featureFlags.formularyPricing
  )

  return (
    <InternalCart
      notifications={notifications}
      removeLineItemGroup={(lineItemGroup) =>
        removeLineItemGroup(lineItemGroup).then(refreshDmeOrder)
      }
      updateLineItemGroup={(lineItemGroup, params) =>
        updateLineItemGroup(lineItemGroup, params).then(refreshDmeOrder)
      }
      lineItemGroups={dmeOrder.lineItemGroups}
      canUpdate={dmeOrder.permissions.update}
      formularyPriceEnabled={formularyPriceEnabled}
    />
  )
}

export default Cart
