import { Doctor } from "sharedTypes"

export type DmeOrderInvitation = {
  dmeOrderId: string
  clinicalFacilityId: string
  clinicalFacilityName: string
  patientName: string
  packageNames: string[]
  supplierName: string
  supplierLogoUrl?: string
  instructions: string
  invitingUserName: string
  firstName: string
  lastName: string
  promptEmail: boolean
}

export type DoctorLoginCode = {
  verified: boolean
  doctor: Doctor
  supplierName: string
  supplierLogoUrl?: string
  pendingIdentityVerificationId: string
}

export type SignableLoginCode = {
  doctor: Doctor
}

export enum UserProviderType {
  DmeOrderInvitation = "DmeOrderInvitation",
  DoctorLoginCode = "DoctorLoginCode",
  SignableLoginCode = "Signing::SignableAccessCode",
}

export type UserProvider =
  | DmeOrderInvitation
  | DoctorLoginCode
  | SignableLoginCode

export type TemporaryAccessCode = {
  validForAuthentication: boolean
  validForResend: boolean
  channel: string
  userProviderType: UserProviderType
  userProvider: UserProvider
}
