import React from "react"
import { CarrierAuthorizationDmeOrder } from "sharedTypes"
import SingleButtonForm from "components/form/SingleButtonForm"
import { dmeOrderUrl, dmeOrderReorderUrl } from "../urls"
import DeliveryDates from "components/DeliveryDates"

type Props = {
  dmeOrders: CarrierAuthorizationDmeOrder[]
}

const LinkedOrdersSectionContent = ({ dmeOrders }: Props) => {
  function renderDmeOrder(dmeOrder, index) {
    const { fulfillments, createdAt } = dmeOrder
    return (
      <div className="row" key={dmeOrder.id}>
        <div className="col-md-4">
          <a href={dmeOrderUrl(dmeOrder.id)} className="color-primary bold">
            {`#${index + 1} - `}
            <DeliveryDates fulfillments={fulfillments} createdAt={createdAt} />
          </a>
        </div>
        <div className="col-md-4 uppercase">{dmeOrder.status}</div>
        {dmeOrder.reorderable && dmeOrder.permissions.reorder && (
          <div className="col-md-3">
            <SingleButtonForm
              action={dmeOrderReorderUrl(dmeOrder.id)}
              className="button btn-link color-primary bold"
              buttonText="Reorder"
            />
          </div>
        )}
      </div>
    )
  }

  return <div>{dmeOrders.map(renderDmeOrder)}</div>
}

export default LinkedOrdersSectionContent
