import React, { useState } from "react"
import classNames from "classnames"
import * as styles from "./index.module.scss"
import PatientPortalMessageTemplates from "./PatientPortalMessageTemplates"
import ChatDisabledHero from "./ChatDisabledHero"
import AutomatedWelcomeMessage from "./AutomatedWelcomeMessage"
import CommentTemplates from "./CommentTemplates"
import { QueryClientProvider } from "@tanstack/react-query"
import { useSupplierSettingsQuery } from "./queries"
import { useStableQueryClient } from "utilities/queryClient"

interface Props {
  supplierId: string
  supplierName: string
  supportEmail: string
  patientPortalEnabled: boolean
}

enum Tabs {
  MessageTemplates = "messageTemplates",
  AutomatedWelcomeMessage = "automatedWelcomeMessage",
  CommentTemplates = "commentTemplates",
}

const PatientPortalMessages = ({
  supplierName,
  supportEmail,
  supplierId,
  patientPortalEnabled,
}: Props) => {
  const initialTab = patientPortalEnabled
    ? Tabs.MessageTemplates
    : Tabs.CommentTemplates

  const [activeTab, setActiveTab] = useState<Tabs>(initialTab)

  const { data: supplierSettings } = useSupplierSettingsQuery(supplierId)

  return (
    <div>
      <div className={styles.container}>
        <ul role="tablist" className={styles.tabList}>
          {patientPortalEnabled && (
            <li role="presentation" className={styles.tabContainer}>
              <div
                role="tab"
                className={classNames(styles.tab, {
                  [styles.tabActive]: activeTab === Tabs.MessageTemplates,
                })}
                aria-selected={activeTab === Tabs.MessageTemplates}
                onClick={() => setActiveTab(Tabs.MessageTemplates)}
              >
                Patient Message Templates ({supplierName})
              </div>
            </li>
          )}
          {patientPortalEnabled && (
            <li role="presentation" className={styles.tabContainer}>
              <div
                role="tab"
                className={classNames(styles.tab, {
                  [styles.tabActive]:
                    activeTab === Tabs.AutomatedWelcomeMessage,
                })}
                aria-selected={activeTab === Tabs.AutomatedWelcomeMessage}
                onClick={() => setActiveTab(Tabs.AutomatedWelcomeMessage)}
              >
                Automated Welcome Message
              </div>
            </li>
          )}
          <li role="presentation" className={styles.tabContainer}>
            <div
              role="tab"
              className={classNames(styles.tab, {
                [styles.tabActive]: activeTab === Tabs.CommentTemplates,
              })}
              aria-selected={activeTab === Tabs.CommentTemplates}
              onClick={() => setActiveTab(Tabs.CommentTemplates)}
            >
              Comment Templates
            </div>
          </li>
        </ul>
      </div>

      {activeTab === Tabs.MessageTemplates &&
        patientPortalEnabled &&
        supplierSettings?.portalMessaging && (
          <PatientPortalMessageTemplates
            supplierName={supplierName}
            supplierId={supplierId}
          />
        )}

      {activeTab === Tabs.MessageTemplates &&
        patientPortalEnabled &&
        supplierSettings?.portalMessaging === false && (
          <ChatDisabledHero
            supplierName={supplierName}
            supportEmail={supportEmail}
          />
        )}

      {activeTab === Tabs.AutomatedWelcomeMessage && patientPortalEnabled && (
        <AutomatedWelcomeMessage supplierId={supplierId} />
      )}

      {activeTab === Tabs.CommentTemplates && (
        <CommentTemplates supplierId={supplierId} supplierName={supplierName} />
      )}
    </div>
  )
}

const PatientPortalMessagesWithQueryClientProvider = (props: Props) => {
  const queryClient = useStableQueryClient()

  return (
    <QueryClientProvider client={queryClient}>
      <PatientPortalMessages {...props} />
    </QueryClientProvider>
  )
}

export default PatientPortalMessagesWithQueryClientProvider
