import { DmeOrderWarningSeverity } from "sharedTypes"
import { warningsByStep } from "applications/Workflow/utilities/validation"

const sortByStepIndex = (allWarningKeys, a, b) => {
  const indexA = allWarningKeys.indexOf(a.key)
  const indexB = allWarningKeys.indexOf(b.key)

  if (indexA === indexB) {
    return 0
  }
  if (indexA >= 0 && indexB >= 0) {
    return indexA - indexB
  }
  if (indexA < 0) {
    return 1
  }
  return -1
}

const sortBySeverity = (a, b) => {
  if (a?.key?.toString() === "prevent_supplier_edit") {
    return -1
  }
  if (a.severity === b.severity) {
    return 0
  }
  if (a.severity === DmeOrderWarningSeverity.Error) {
    return -1
  }
  if (
    a.severity === DmeOrderWarningSeverity.Warning &&
    b.severity === DmeOrderWarningSeverity.Info
  ) {
    return -1
  }
  return 1
}

const sortWarnings = (allWarningKeys) => (a, b) => {
  if (a.severity === b.severity) {
    return sortByStepIndex(allWarningKeys, a, b)
  }
  return sortBySeverity(a, b)
}

export const orderedWarningsWithStep = (context, warnings) => {
  const stepWarnings = warningsByStep(context)
  const allWarningKeys = stepWarnings.map(({ key }) => key)

  return warnings
    .sort(sortWarnings(allWarningKeys))
    .filter(
      (warning, index, self) =>
        index === self.findIndex(({ key }) => key === warning.key)
    )
    .map((warning) => {
      const warningConfig = stepWarnings.find((w) => w.key === warning.key)
      return {
        ...warning,
        step: warningConfig && warningConfig.step,
      }
    })
}

export function warningSeverity(identifier, warnings) {
  const warning = warnings
    .sort((a, b) => sortBySeverity(a, b))
    .find(({ key, step }) => key === identifier || step === identifier)

  if (warning) {
    return warning.severity
  }
}
