import React from "react"
import Icon, { IconType } from "components/Icon"

type Props = {
  className?: string
  type?: IconType
}
const SpinIcon: React.FunctionComponent<Props> = ({ className, type }) => {
  return (
    <Icon
      type={type || "circle-notch"}
      spin
      className={className}
      ariaLabel="loading indicator"
    />
  )
}

export default SpinIcon
