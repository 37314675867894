import { AxiosPromise } from "axios"

interface PollOptions {
  success(data): void
  failure(data): void
  error(): void
  retries?: number
  maxRetries?: number
  delay?: number
}

export function poll(promise, options: PollOptions) {
  const { success, failure, error } = options
  const retries = options.retries || 1
  const maxRetries = options.maxRetries || 60
  const delay = options.delay || 1000
  const delayTime = delay * retries
  promise()
    .then(({ data }) => {
      if (data.status === "success") {
        success(data)
      } else if (data.status === "pending" && retries < maxRetries) {
        setTimeout(() => {
          options.retries = retries + 1
          poll(promise, options)
        }, delayTime)
      } else {
        failure(data)
      }
    })
    .catch(error)
}

export function pollPromise(
  promiseFn: () => AxiosPromise<any>,
  options?
): Promise<any> {
  return new Promise((resolve, reject) => {
    const pollOptions = {
      success: resolve,
      failure: reject,
      error: reject,
      ...options,
    }
    poll(promiseFn, pollOptions)
  })
}
