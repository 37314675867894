import React, { useState } from "react"
import Modal from "components/Modal"
import UploadForm from "../../../Uploads/UploadForm"
import { ToastProvider, useToaster } from "components/Toaster"
import Link from "components/Link"
import { downloadCsvTemplateUrl } from "./routes"
import * as api from "./api"

type Props = {
  supplierOrganizationId: string
  requiredHeaders: string[]
  possibleHeaders: string[]
}

const UploadButton = (props: Props) => {
  const { supplierOrganizationId, requiredHeaders, possibleHeaders } = props

  const [showModal, setShowModal] = useState(false)

  const openModal = (event) => {
    event.preventDefault()
    setShowModal(true)
  }

  const closeModal = () => {
    setShowModal(false)
  }

  const { persistentAlert } = useToaster()

  return (
    <>
      <button
        className="file-label btn btn-brand pdf-modal"
        onClick={openModal}
      >
        Upload CSV
      </button>
      <Modal show={showModal} cancel={closeModal} title="Upload CSV" size="md">
        <Modal.Body>
          <>
            <p>
              The uploaded CSV must adhere to the template format to be accepted
              and processed.
            </p>
            <p>
              Download:{" "}
              <Link
                href={downloadCsvTemplateUrl(supplierOrganizationId)}
                className="color-brand"
              >
                CSV Template
              </Link>
            </p>
            <UploadForm
              upload={(file) => {
                return api.createUpload(props.supplierOrganizationId, file)
              }}
              afterUpload={() => {
                closeModal()
                persistentAlert({
                  message: "Upload submitted!",
                  severity: "success",
                })
              }}
              requiredCsvColumns={requiredHeaders}
              possibleCsvColumns={possibleHeaders}
            />
          </>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default (props) => (
  <ToastProvider>
    <UploadButton {...props} />
  </ToastProvider>
)
