import { employerPrefix } from "utilities/url"
import { EmployerType } from "utilities/url"

export const searchPatientsUrl = (
  employerType: EmployerType | undefined,
  employerId: string | undefined
) => `${employerPrefix(employerType, employerId)}/patients.json`
export const patientUrl = (
  employerType: EmployerType | undefined,
  employerId: string | undefined,
  patientId: string
) => `${employerPrefix(employerType, employerId)}/patients/${patientId}`

export const newOrderUrl = () => `${employerPrefix()}/orders/new`
