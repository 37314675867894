import { Box } from "@material-ui/core"
import {
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarFilterButton,
} from "@mui/x-data-grid-pro"
import * as React from "react"
import { Archive, Edit, FileCopyOutlined } from "@material-ui/icons"
import DataGridToolbarButton from "../DataGridToolbarButton"
import * as styles from "./index.module.scss"
import { Permissioned } from "../../types/sharedTypes"

type Props = {
  selectedRows?: Permissioned[]
  filter?: boolean
  archiveOnClick?: () => void
  duplicateOnClick?: () => void
  editOnClick?: () => void
}

const DataGridToolbar = (props: Props): JSX.Element => {
  const {
    selectedRows = [],
    filter,
    archiveOnClick,
    duplicateOnClick,
    editOnClick,
  } = props

  const canEditSelected = () => {
    return selectedRows.every((row) => row.permissions?.edit)
  }

  const canDestroySelected = () => {
    return selectedRows.every((row) => row.permissions?.destroy)
  }

  const emptyRows = () => {
    return selectedRows.length === 0
  }

  const singleRow = () => {
    return selectedRows.length === 1
  }

  return (
    <Box mb={1} className={styles.datagridToolbar}>
      <GridToolbarContainer>
        {!emptyRows() && (
          <span className={styles.selectedRowsCount}>
            {`${selectedRows.length} Row${
              selectedRows.length > 1 ? "s" : ""
            } selected`}
          </span>
        )}
        {emptyRows() && (
          <>
            <GridToolbarColumnsButton disableRipple />
            {filter && <GridToolbarFilterButton />}
          </>
        )}
        {singleRow() && canEditSelected() && editOnClick && (
          <DataGridToolbarButton
            name="edit"
            label="Edit"
            icon={Edit}
            onClick={editOnClick}
          />
        )}
        {singleRow() && duplicateOnClick && (
          <DataGridToolbarButton
            name="duplicate"
            label="Duplicate"
            icon={FileCopyOutlined}
            onClick={duplicateOnClick}
          />
        )}
        {selectedRows.length >= 1 && canDestroySelected() && archiveOnClick && (
          <DataGridToolbarButton
            name="archive"
            label="Archive"
            icon={Archive}
            onClick={archiveOnClick}
          />
        )}
      </GridToolbarContainer>
    </Box>
  )
}

DataGridToolbar.defaultProps = {
  selectedRowsCount: 0,
}

export default DataGridToolbar
