import React from "react"
import { Follower } from "sharedTypes"
import SelectFilter from "components/SelectFilter"

interface Props {
  followers: Follower[]
  fetchFollowers(term: string): Promise<{ label: string; value: string }[]>
  onChange(values: { followerIds: string[] }): void
  initialFollowerIds: string[]
}

function FollowerSelect({
  followers,
  initialFollowerIds,
  fetchFollowers,
  onChange,
}: Props) {
  const options = followers.map((follower) => {
    return { label: follower.name, value: follower.id }
  })

  return (
    <SelectFilter
      name="followerIds"
      label="Followers"
      initialValues={initialFollowerIds}
      options={options}
      fetchOptions={fetchFollowers}
      onChange={onChange}
      minLength={0}
      enableReinitialize
    />
  )
}

export default FollowerSelect
