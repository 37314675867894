import { Address, InsurancePolicy, Nullable } from "sharedTypes"

export interface DmeOrder {
  insurancePolicies: InsurancePolicy[]
  addresses: Address[]
  billingAddressId: Nullable<number>
  clinicalFacility: {
    state: string
    zip?: Nullable<string>
  }
}

export const stateForBillingRouting = (dmeOrder: DmeOrder): string =>
  billingAddressFor(dmeOrder)?.state || dmeOrder.clinicalFacility.state

export const zipForBillingRouting = (dmeOrder: DmeOrder): Nullable<string> =>
  billingAddressFor(dmeOrder)?.zip || dmeOrder.clinicalFacility.zip || ""

export const primaryPolicyFor = (
  dmeOrder: DmeOrder
): Nullable<InsurancePolicy> =>
  dmeOrder.insurancePolicies.find((p) => p.rank === "primary") ?? null

const billingAddressFor = (dmeOrder: DmeOrder): Nullable<Address> =>
  dmeOrder.addresses.find((a) => a.id === dmeOrder.billingAddressId) ?? null
