import React from "react"
import InternalManageSuppliers from "./components/InternalManageSuppliers"
import { ToastProvider } from "components/Toaster"
import { FulfillmentAgreement } from "./sharedTypes"

interface Props {
  facilityState: string
  fulfillmentAgreements: FulfillmentAgreement[]
  pinnedSupplierIds: string[]
}

const ManageSuppliers: React.FC<Props> = ({
  facilityState,
  fulfillmentAgreements,
  pinnedSupplierIds,
}) => (
  <ToastProvider>
    <InternalManageSuppliers
      facilityState={facilityState}
      fulfillmentAgreements={fulfillmentAgreements}
      pinnedSupplierIds={pinnedSupplierIds}
    />
  </ToastProvider>
)

export default ManageSuppliers
