export enum ChartNoteHelperType {
  Prefill = "prefill",
  Common = "common",
  Manual = "manual",
}

export type ChartNote = {
  name: string
  id: string
}
