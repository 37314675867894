import React from "react"
import { CircularProgress } from "@material-ui/core"
import * as styles from "./index.module.scss"

const IndeterminateLoader = ({
  label,
  size = "md",
}: {
  label?: string
  size?: "md" | "xl"
}) => {
  const loaderSizeInPixels = { md: 20, xl: 70 }[size]
  return (
    <>
      <CircularProgress
        size={loaderSizeInPixels}
        className={styles.muiCircularProgressIndeterminate}
      />
      {label && (
        <small className="d-inline-block canopy-mis-4x color-black">
          {label}
        </small>
      )}
    </>
  )
}

export default IndeterminateLoader
