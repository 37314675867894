import { Card, CardContent, CardHeader } from "@material-ui/core"
import { FluidLabelAndValue } from "./FluidLabelAndValue"
import React from "react"

export const PatientCard = ({
  patient,
}: {
  patient: { name: string; dateOfBirth: string }
}) => (
  <Card>
    <CardHeader title="Patient" />
    <CardContent>
      <FluidLabelAndValue label="Name" value={patient.name} />
      <FluidLabelAndValue label="Date of Birth" value={patient.dateOfBirth} />
    </CardContent>
  </Card>
)
