import React from "react"
import { SurveyQuestion } from "sharedTypes"
import { pluralize } from "utilities/string"

interface Props {
  question: SurveyQuestion
  answer: string | null
}

function TextLengthCheck({ question, answer }: Props) {
  const min = question.minimumLength
  const max = question.maximumLength
  const answerLength = (answer || "").trim().length

  const warningText = () => {
    if (min && min > answerLength) {
      const count = min - answerLength
      return `${count} more ${pluralize("character", count)} required`
    } else if (max && max < answerLength) {
      return (
        <>
          <i className="fas fa-info-circle" />
          &nbsp;Maximum number of characters reached
        </>
      )
    }
  }

  if (!min && !max) {
    return null
  }

  return (
    <div className="d-flex justify-content-space-between small">
      <span>{warningText() || "\u00A0"}</span>
      {max && (
        <span>
          ({answerLength}/{max})
        </span>
      )}
    </div>
  )
}

export default TextLengthCheck
