import { Supplier } from "components/cms/sharedTypes"
import React, { useRef } from "react"
import SupplierInsuranceSettingsTable from "./components/DataGrid/SupplierInsuranceSettingsTable"
import { withApollo } from "../../hocs/withApollo"
import { useQuery } from "@apollo/client"
import { GetInsuranceProducts } from "./graphql/insuranceProductsQuery"
import { Box, Grid } from "@material-ui/core"
import { CanopyTextInputField } from "@parachutehealth/canopy-text-input-field"
import { CanopyButton } from "@parachutehealth/canopy-button"
import SupplierInsuranceSettingsImportFormDialogModal from "./components/SupplierInsuranceSettingsImportDialogFormModal"
import { employerPrefix } from "../../utilities/url"
import { MaterialThemeProvider } from "../../themes/theme"
import { InsuranceProductConnection } from "../../graphql/__generated__/graphql"
import ParachuteMuiDataGridContainer from "../../components/ParachuteMuiDataGrid/ParachuteMuiDataGridContainer"

export type Props = {
  supplier: Supplier
}

const SupplierInsuranceSettings = ({ supplier }: Props): JSX.Element => {
  const { data, loading, fetchMore, refetch } = useQuery(GetInsuranceProducts)
  const insuranceProducts = data?.insuranceProducts as InsuranceProductConnection
  const search = useRef("")

  const handleSearch = async (searchString: string) => {
    await refetch({
      searchString: searchString,
    })
  }

  return (
    <>
      <h1 className="canopy-typography-heading-2xlarge canopy-mbe-8x">
        Plan Family Mappings ({supplier.name})
      </h1>
      <ParachuteMuiDataGridContainer>
        <MaterialThemeProvider>
          <Box mb={3} className="canopy-mbe-2x">
            <Grid container spacing={2}>
              <Grid lg={2} item>
                <CanopyTextInputField
                  className="canopy-my-8x"
                  type="search"
                  label="Search"
                  id="search"
                  hiddenLabel
                  autocorrect="off"
                  placeholder="Search"
                  size="medium"
                  onChange={(e) => {
                    search.current = e.target.value
                  }}
                />
              </Grid>
              <Grid lg={1} item>
                <CanopyButton
                  variant="secondary"
                  className="canopy-my-8x"
                  onClick={() => void handleSearch(search.current)}
                >
                  Search
                </CanopyButton>
              </Grid>
              <Grid lg={9} item>
                <Box display="flex" justifyContent="flex-end">
                  <SupplierInsuranceSettingsImportFormDialogModal fileFieldName="supplier-insurance-settings" />
                  <CanopyButton
                    as="a"
                    className="canopy-my-8x"
                    size="medium"
                    variant="primary"
                    href={`${employerPrefix()}/insurance/products.csv`}
                    target="_blank"
                  >
                    Export
                  </CanopyButton>
                </Box>
              </Grid>
            </Grid>
          </Box>
          <SupplierInsuranceSettingsTable
            insuranceData={insuranceProducts}
            loading={loading}
            refetchData={refetch}
            paginate={fetchMore}
          />
        </MaterialThemeProvider>
      </ParachuteMuiDataGridContainer>
    </>
  )
}

export default withApollo(SupplierInsuranceSettings)
