import React from "react"
import Alert from "components/Alert"

type Props = {
  onClose(): void
}

const ErrorBanner = ({ onClose }: Props) => {
  return (
    <Alert leftIcon status="danger" rightIcon="fa-times" onClick={onClose}>
      <span>
        <strong>Error!</strong> Please reload the page and try again.
      </span>
    </Alert>
  )
}

export default ErrorBanner
