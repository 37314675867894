import { employerPrefix } from "utilities/url"

const basePath = (orgId) =>
  employerPrefix("SupplierOrganization", orgId) + "/documentation_orders"

export const RouteParams = {
  SupplierOrganizationId: "supplierOrganizationId",
  Tab: "tab",
}
export const RoutePattern = `${basePath(
  `:${RouteParams.SupplierOrganizationId}`
)}/:${RouteParams.Tab}?`

export const signatureRequestPath = (orgId: string) =>
  `${basePath(orgId)}/dashboard`
export const csvUploadPath = (orgId: string) => `${basePath(orgId)}/uploads`
export const downloadCsvTemplatePath = (orgId: string) =>
  `${basePath(orgId)}/uploads/prescription_template`
export const createUploadUrl = (orgId: string) =>
  `${basePath(orgId)}/uploads.json`
export const cancelDocumenationOrderUrl = (documentationOrderId: string) =>
  `/web_api/documentation_orders/${documentationOrderId}/cancel`
export const shareRequestLinkUrl = (documentationOrderId: string) =>
  `/web_api/documentation_orders/${documentationOrderId}/signable_temporary_access_codes`
