import React, { useContext } from "react"
import HowItWorks from "./HowItWorks"
import Tooltip from "components/Tooltip"
import DownloadLink from "components/DownloadLink"
import GlobalContext from "context/Global"
import { DmeOrder } from "sharedTypes"

type Props = {
  dmeOrder: DmeOrder
  allowFaxPacketDownload: boolean
}

function PrintSignature({ dmeOrder, allowFaxPacketDownload }: Props) {
  const { requiresTwoStepDownload } = useContext(GlobalContext)

  return (
    <div>
      <form>
        <>
          <HowItWorks type={HowItWorks.Type.Download}>
            <p>
              If faxing in your documents, be sure to attach a cover sheet in
              front.
            </p>
            {allowFaxPacketDownload ? (
              <p>
                <DownloadLink
                  orderId={dmeOrder.id}
                  documentKey="fax_packet"
                  label="order documents with cover sheet"
                  className="link"
                  requiresTwoStepDownload={requiresTwoStepDownload}
                />
              </p>
            ) : (
              <p>
                <Tooltip
                  triggerElementClassName="d-inline-block"
                  trigger={["click", "hover"]}
                  overlay="Please complete all previous sections to enable download"
                  placement={Tooltip.Placement.Bottom}
                >
                  <a className="link disabled">
                    <i className="fas fa-cloud-download-alt canopy-mie-4x" />
                    Download order documents with cover sheet
                  </a>
                </Tooltip>
              </p>
            )}
          </HowItWorks>
        </>
      </form>
    </div>
  )
}

export default PrintSignature
