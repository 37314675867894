export interface FulfillmentAgreement {
  active: boolean
  id: string
  newAgreement: boolean
  recentOrdersCount: number
  supplier: {
    billingAddress: string
    description: string
    externalId: string
    name: string
    npi: string
  }
}

export enum SupplierFilter {
  Active = "active",
  History = "history",
}
