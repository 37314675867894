// @team @expanse
import { Address } from "sharedTypes"
import { formatPhone } from "utilities/phone"

/**
 * Provided an address, formats phone numbers for use in the order review contacts section
 * @param address
 * @returns Phone numbers and additional copy in case an alternate number is provided
 */
export const formatPhoneNumbers = (address: Address) => {
  const primaryPhoneNumber = `${formatPhone(address.phoneNumber)}${
    address.alternatePhoneNumber ? " (Primary)" : ""
  }`
  const alternatePhoneNumber = address.alternatePhoneNumber
    ? `${formatPhone(address.alternatePhoneNumber)} (Alternate)`
    : null

  return {
    primaryPhoneNumber,
    alternatePhoneNumber,
  }
}

/**
 * @param address
 * @param patientSnapshot
 * @returns Patients preferred language if we think this address belongs to a patient
 */
export const getPreferredLanguage = (address: Address, patientSnapshot) => {
  if (
    address.phoneNumber &&
    isPatientAddress(address) &&
    patientSnapshot.preferredLanguage
  ) {
    return `${patientSnapshot.preferredLanguage} preferred`
  }
}

/**
 *
 * @param address
 * @param patientSnapshot
 * @returns Patients email if we think this address belongs to a patient
 */
export const getPatientEmail = (address: Address, patientSnapshot) =>
  isPatientAddress(address) ? patientSnapshot.email : null

function isPatientAddress(address: Address) {
  return address.addressType === "billing"
}
