// @ts-strict-ignore
import React, { forwardRef } from "react"
import classNames from "classnames"
import { getClipboardData } from "../utilities/clipboard"

type Props = {
  value: string
  hasError?: boolean
  id?: string
  name?: string
  type?: "text" | "email"
  label?: string
  placeholder?: string
  maxLength?: number
  center?: boolean
  onChange?(e: React.ChangeEvent<HTMLInputElement>): void
  onKeyDown?(e: React.KeyboardEvent<HTMLInputElement>): void
  autoFocus?: boolean
  onPaste?: React.ChangeEventHandler<HTMLInputElement>
}

const CHAR_WIDTH = 40

const BigInput = forwardRef<HTMLInputElement, Props>((props: Props, ref) => {
  const {
    value,
    hasError,
    id,
    name,
    type,
    label,
    placeholder,
    maxLength,
    center,
    onChange,
    onKeyDown,
    onPaste,
    autoFocus,
  } = props

  const handlePaste = (event) => {
    if (onPaste) {
      event.preventDefault()
      event.target.value = getClipboardData(event)
      onPaste(event)
    }
  }

  return (
    <div className={classNames("big-input", { "has-error": hasError })}>
      <input
        value={value}
        id={id}
        name={name}
        type={type || "text"}
        placeholder={placeholder}
        ref={ref}
        onChange={onChange}
        onKeyDown={onKeyDown}
        maxLength={maxLength}
        autoFocus={autoFocus}
        className={classNames({ "text-center": center })}
        onPaste={handlePaste}
        style={{ maxWidth: maxLength ? `${maxLength * CHAR_WIDTH}px` : null }}
      />
      {label && <label htmlFor={id}>{label}</label>}
    </div>
  )
})

export default BigInput
