import React, { useState } from "react"
import { navigate } from "utilities/navigation"
import ForgotPasswordEmailSent from "./ForgotPasswordEmailSent"
import { signInUrl } from "../urls"
import ForgotPasswordForm from "./ForgotPasswordForm"

interface Props {
  sendPasswordResetInstructions({ email }): Promise<{ redirectTo?: string }>
}

function InternalForgotPassword({ sendPasswordResetInstructions }: Props) {
  const [successful, setSuccessful] = useState(false)

  const onSubmit = ({ email }) => {
    return sendPasswordResetInstructions({ email }).then((data) => {
      const { redirectTo } = data
      if (redirectTo) {
        navigate(redirectTo)
      } else {
        setSuccessful(true)
      }
    })
  }

  if (successful) {
    return (
      <ForgotPasswordEmailSent
        onClick={() => {
          navigate(signInUrl())
        }}
      />
    )
  }

  return <ForgotPasswordForm onSubmit={onSubmit} />
}

export default InternalForgotPassword
