import React, { useEffect, useState } from "react"
import * as styles from "./index.module.scss"
import { viewSDKClient } from "./viewSDKClient"
import ErrorBoundary from "components/ErrorBoundary"
import { trackEvent, EventCategory } from "utilities/tracking"

type Props = {
  documentUrl: string
  saveDocument: (data: any) => Promise<any>
}

export const PdfViewer = ({ documentUrl, saveDocument }: Props) => {
  const onError = () =>
    trackEvent(EventCategory.CustomerSupport, "PdfViewerError", documentUrl)

  return (
    <ErrorBoundary
      errorMessage="Cannot load PDF. Please continue order or Contact Support for assistance."
      onError={onError}
    >
      <InternalPdfViewer
        documentUrl={documentUrl}
        saveDocument={saveDocument}
      />
    </ErrorBoundary>
  )
}

const InternalPdfViewer = ({ documentUrl, saveDocument }: Props) => {
  const PDF_VIEWER_DIV_ID = "adobe-pdf-viewer"
  const [error, setError] = useState<Error | null>(null)
  if (error) {
    throw error
  }

  useEffect(() => {
    const client = new viewSDKClient()

    client
      .ready()
      .then(() => {
        return client.previewFile({
          divId: PDF_VIEWER_DIV_ID,
          url: documentUrl,
          saveDocument,
        })
      })
      .catch((e) => setError(e))
  }, [documentUrl, saveDocument])

  return (
    <div className={styles.wrapper}>
      <div id={PDF_VIEWER_DIV_ID} />
    </div>
  )
}
