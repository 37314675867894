// @ts-strict-ignore
import React, { ReactElement, useEffect } from "react"
import * as styles from "./index.module.scss"
import TopLeftNavLink from "./TopLeftNavButton"
import { NavOrder, SidebarTab } from "../../sharedTypes"
import classNames from "classnames"
import TopRightInOrderNavContainer from "./TopRightInOrderNavContainer"
import { launchKnowledgeBase } from "./api"
import { trackView } from "./analytics"

type Props = {
  currentTab?: SidebarTab
  setSidebarTab(tab: SidebarTab): void
  showTransferTab: boolean
  dmeOrder: NavOrder
}

export default function TopNavigation({
  currentTab,
  setSidebarTab,
  showTransferTab,
  dmeOrder,
}: Props): ReactElement {
  useEffect(() => {
    trackView()
  }, [])

  return (
    <div className={classNames("navbar-fixed-top fixed-top", styles.nav)}>
      <TopLeftNavLink />
      <TopRightInOrderNavContainer
        currentTab={currentTab}
        launchKnowledgeBase={launchKnowledgeBase}
        setSidebarTab={setSidebarTab}
        showTransferTab={showTransferTab}
        dmeOrder={dmeOrder}
      />
    </div>
  )
}
