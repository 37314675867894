import React, { useState } from "react"
import ChatHand from "chat-hand.svg"
import MessageTemplateForm from "../MessageTemplateForm"
import { CanopyButton } from "@parachutehealth/canopy-button"
import { styleTemplateVariables } from "../helpers"
import {
  useSupplierCommentTemplatesQuery,
  useDeleteSupplierCommentTemplate,
} from "../queries"

interface Props {
  supplierId: string
  supplierName: string
}

const CommentTemplateHero = ({
  showAddForm,
  description,
}: {
  showAddForm: () => void
  description: string
}) => {
  return (
    <div
      style={{ maxWidth: "60%", textAlign: "center" }}
      className="canopy-mx-auto"
    >
      <img
        src={ChatHand}
        alt="Hand with chat bubble"
        className="canopy-m-auto"
        style={{ width: "144px", height: "144px" }}
      />
      <h1 className="canopy-m-auto">Speed up communication with a Facility</h1>

      <p className="canopy-mt-4x">{description}</p>
      <CanopyButton onClick={showAddForm}>Add comment template</CanopyButton>
    </div>
  )
}

const CommentTemplates = ({ supplierId, supplierName }: Props) => {
  const [showForm, setShowForm] = useState(false)

  const {
    data: supplierCommentTemplates,
    isFetching,
  } = useSupplierCommentTemplatesQuery(supplierId)

  const {
    mutateAsync: deleteSupplierCommentTemplate,
  } = useDeleteSupplierCommentTemplate(supplierId)

  const [action, setAction] = useState("Add")
  const [currentTemplateId, setCurrentTemplateId] = useState(null)

  const showAddForm = () => {
    setAction("Add")
    setCurrentTemplateId(null)
    setShowForm(true)
  }

  if (isFetching) return null

  const description =
    "Add templated messages for communicating with clinical facilities. Save time while making common requests, providing additional details, or sending the timely updates your referrals appreciate. Your staff will thank you too!"

  if (showForm) {
    return (
      <MessageTemplateForm
        action={action}
        supplierId={supplierId}
        supplierName={supplierName}
        templateId={currentTemplateId}
        hideForm={() => setShowForm(false)}
      />
    )
  }

  if (!showForm && supplierCommentTemplates.length > 0) {
    return (
      <div>
        <div className="d-flex">
          <div>
            <p>
              {supplierName} can select a template from within the Global
              Comment section at the Activity Feed.
              <br />
              Users can view the templated message prior to sending and edit as
              needed.
            </p>
          </div>
          <button
            className="canopy-ml-auto btn btn-primary"
            onClick={showAddForm}
          >
            + Add Template
          </button>
        </div>

        <table style={{ tableLayout: "fixed" }} className="table table-hover">
          <thead>
            <tr>
              <th style={{ width: "20%" }}>Name</th>
              <th style={{ width: "68%" }}>Message</th>
              <th style={{ width: "12%" }}></th>
            </tr>
          </thead>
          <tbody>
            {supplierCommentTemplates.map((messageTemplate) => (
              <tr key={messageTemplate.id}>
                <td>{messageTemplate.name}</td>
                <td className="text-truncate">
                  {styleTemplateVariables(messageTemplate.template)}
                </td>
                <td>
                  <button
                    onClick={() => {
                      setAction("Edit")
                      setCurrentTemplateId(messageTemplate.id)
                      setShowForm(true)
                    }}
                    className="link"
                  >
                    Edit
                  </button>
                  |
                  <button
                    onClick={() =>
                      deleteSupplierCommentTemplate(messageTemplate.id)
                    }
                    className="link"
                  >
                    Delete
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    )
  }

  return (
    <CommentTemplateHero showAddForm={showAddForm} description={description} />
  )
}

export default CommentTemplates
