import { Grid, Typography } from "@material-ui/core"
import React from "react"
import * as styles from "./fluid-label-and-value.module.scss"

export const FluidLabelAndValue = ({
  label,
  value,
}: {
  label: string
  value: string
}) => {
  return (
    <Grid container className={styles.container}>
      <Grid item xs={12} sm={6}>
        <Typography className={styles.label}>{label}</Typography>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Typography>{value}</Typography>
      </Grid>
    </Grid>
  )
}
