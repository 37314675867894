import React from "react"
import ChatHand from "chat-hand.svg"
import { CanopyButton } from "@parachutehealth/canopy-button"

const ChatDisabledHero = ({
  supplierName,
  supportEmail,
}: {
  supplierName: string
  supportEmail: string
}) => {
  return (
    <div
      style={{ maxWidth: "60%", textAlign: "center" }}
      className="canopy-mx-auto"
    >
      <img
        src={ChatHand}
        alt="Hand with chat bubble"
        style={{ width: "144px", height: "144px", margin: "auto" }}
      />
      <h1 style={{ margin: "auto" }}>Chat with patients</h1>

      <p className="canopy-mt-4x">
        {supplierName} users could use templates from within the Patient
        Messaging pane on an order to reach out to patients in real time!
      </p>
      <p className="canopy-mx-auto">
        <span style={{ textAlign: "center" }} className="d-block">
          Interested in Patient Chat?
        </span>
        <span style={{ textAlign: "center" }} className="d-block">
          Reach out to support below to get set up.
        </span>
      </p>

      <a href={`mailto:${supportEmail}`} className="canopy-mx-auto">
        <CanopyButton>Contact Parachute Support</CanopyButton>
      </a>
    </div>
  )
}

export default ChatDisabledHero
