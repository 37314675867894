import React from "react"
import Alert from "components/Alert"

interface Props {
  missingPrice: boolean
}

const PricingDisclaimers: React.FC<Props> = ({ missingPrice }) => {
  return (
    <>
      {missingPrice ? (
        <div className="canopy-mie-4x canopy-mbe-12x">
          <Alert status="warning">
            <strong>Heads-up:</strong> An item in your cart does not have price
            information, so the cart total may be incomplete.
          </Alert>
        </div>
      ) : null}
      <span className="font-weight-bold">A note on prices: </span>
      <span className="font-subparagraph">
        Prices are for comparison purposes only and may not reflect actual
        billed prices. Pricing information is subject to change at any time.
      </span>
    </>
  )
}

export default PricingDisclaimers
