import $ from "jquery"

function onFeeTypeChange(value) {
  $("#hospice_dme_fee_price").prop("readonly", value === "capitated")
}

$(window).on("load", function () {
  onFeeTypeChange($("#hospice_dme_fee_fee_type").val())
})

$(document).on("change", "#hospice_dme_fee_fee_type", function (event) {
  onFeeTypeChange(event.target.value)
})
