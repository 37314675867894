import React, { useEffect } from "react"
import { DoctorLoginCode } from "../../sharedTypes"
import { doctorBasicInfo, trackInvitationEvent } from "../../utilities"
import Cobranding from "components/Cobranding"
import Legalese from "applications/AccessCodeLogin/components/Legalese"

type Props = {
  doctorLoginCode: DoctorLoginCode
  onSubmit(): Promise<void>
  reject(): Promise<void>
}

const DoctorIdentificationForm = ({
  doctorLoginCode,
  onSubmit,
  reject,
}: Props) => {
  useEffect(() => {
    trackInvitationEvent("onSubmit-form-viewed")
  }, [])
  const doctor = doctorLoginCode.doctor

  return (
    <>
      <Cobranding
        supplierName={doctorLoginCode.supplierName}
        supplierLogoUrl={doctorLoginCode.supplierLogoUrl}
      />
      <div className="onboarding-intro">
        <h1>First, confirm your identity:</h1>
        <h2 className="canopy-my-24x">{doctorBasicInfo(doctor)}</h2>
        <button
          type="submit"
          onClick={onSubmit}
          className="btn btn-brand btn-block"
        >
          I am {`${doctor.firstName} ${doctor.lastName}`}
        </button>
        <button onClick={reject} className="btn btn-secondary btn-block">
          No, I am not this person
        </button>
      </div>
      <Legalese entityName={doctorBasicInfo(doctor)} />
    </>
  )
}
export default DoctorIdentificationForm
