import React, { useState, useContext } from "react"
import withInitialData from "components/withInitialData"
import LeftSidebar, {
  Option,
} from "components/SupplierOrganizationDashboard/LeftSidebar"
import * as api from "./api"
import { FilterOptions } from "./components/DashboardFilters/sharedTypes"
import { defaultFilterSettings } from "./filterConfigs"
import { defaultColumnSettings } from "./components/tableColumnDefinitions"
import { DashboardView } from "./sharedTypes"
import { ViewContextProvider } from "./view/viewContext"
import SupplierOrganizationContext from "./SupplierOrganizationContext"
import classNames from "classnames"
import { ViewSelectorAndSaver } from "./view/ViewSelectorAndSaver"
import DashboardFilters from "./components/DashboardFilters"
import SettingsEditor from "./components/SettingsEditor"
import { ManageViewButton } from "./components/ManageViewButton"
import { ClearFiltersButton } from "./components/ClearFiltersButton"
import { OrdersTable } from "./components/OrdersTable"
import { dashboardPath, uploadsPath } from "applications/DashboardTabs/routes"
import { Header as TabbedHeader } from "applications/DocumentationOrders/Header"
import Header from "./components/Header"
import UploadsTabPanel from "applications/Uploads/UploadsTabPanel"
import { downloadCsvTemplateUrl } from "./components/UploadButton/routes"
import { dmeOrderUploadResultsUrl } from "../Uploads/urls"
import { createUpload } from "./components/UploadButton/api"

interface Props {
  initialData: {
    currentView: DashboardView
    filterOptions: FilterOptions
  }
  supplierOrganizationId: string
  supplierOrganizationName: string
  csvUpload?: {
    enabled: boolean
    requiredHeaders: string[]
    possibleHeaders: string[]
  }
  activeTab?: "dashboard" | "uploads"
  tabView?: boolean
}

const SupplierOrganizationDashboard = ({ csvUpload, activeTab, tabView }) => {
  const { supplierOrganizationName, supplierOrganizationId } = useContext(
    SupplierOrganizationContext
  )
  const [showSidebar, setSidebarVisibility] = useState<boolean>(false)
  const closeSidebar = () => setSidebarVisibility(false)
  const toggleSidebar = (): void =>
    setSidebarVisibility((showSidebar: boolean) => !showSidebar)

  return (
    <div className="position-relative">
      <LeftSidebar active={Option.Orders} />
      <div className="row mt-n3">
        <div
          className={classNames("canopy-mbs-12x", {
            "col-lg-9 d-none d-lg-block": showSidebar,
            "col-12": !showSidebar,
          })}
        >
          {tabView! ? (
            <TabbedHeader
              activeTab={activeTab}
              supplierOrganizationId={supplierOrganizationId}
              showUploadsTab={csvUpload?.enabled || false}
              dashboardPath={dashboardPath(supplierOrganizationId)}
              uploadsPath={uploadsPath(supplierOrganizationId)}
              dashboardName={`Orders (${supplierOrganizationName})`}
            />
          ) : (
            <Header
              name={supplierOrganizationName}
              csvUpload={{ ...csvUpload, supplierOrganizationId }}
              tabView={tabView}
            />
          )}
          {(activeTab === "dashboard" || !tabView) && (
            <>
              <ViewSelectorAndSaver />
              <DashboardFilters />
              <ManageViewButton
                showSidebar={showSidebar}
                toggleSidebar={toggleSidebar}
              />
              <ClearFiltersButton />
              <OrdersTable />
            </>
          )}
          {activeTab === "uploads" && (
            <UploadsTabPanel
              supplierOrganizationId={supplierOrganizationId}
              possibleCsvColumns={csvUpload.possibleHeaders}
              requiredCsvColumns={csvUpload.requiredHeaders}
              uploadsIndex={api.fetchUploads}
              templatePath={downloadCsvTemplateUrl(supplierOrganizationId)}
              resultsUrlBuilder={dmeOrderUploadResultsUrl}
              uploadsCreate={createUpload}
            />
          )}
        </div>
        {showSidebar && (
          <div className="col-lg-3 dashboard-sidebar">
            <SettingsEditor closeSidebar={closeSidebar} />
          </div>
        )}
      </div>
    </div>
  )
}
function InitialDashboardWithContexts(props: Props) {
  const {
    supplierOrganizationId,
    supplierOrganizationName,
    initialData,
    csvUpload,
  } = props
  return (
    <SupplierOrganizationContext.Provider
      value={{
        supplierOrganizationId: supplierOrganizationId,
        supplierOrganizationName: supplierOrganizationName,
      }}
    >
      <ViewContextProvider
        initialState={{
          ...initialData,
          hasUnsavedChanges: false,
          dirtyFilters: {},
        }}
      >
        <SupplierOrganizationDashboard
          csvUpload={csvUpload}
          activeTab={props.activeTab}
          tabView={props.tabView}
        />
      </ViewContextProvider>
    </SupplierOrganizationContext.Provider>
  )
}

const fetchInitialData = ({ supplierOrganizationId }) =>
  api.getInitialValues(supplierOrganizationId).then(({ data }) => {
    data.currentView.filterSettings = {
      ...defaultFilterSettings,
      ...data.currentView.filterSettings,
    }
    data.currentView.columnSettings = {
      ...defaultColumnSettings,
      ...data.currentView.columnSettings,
    }
    return data
  })
export default withInitialData(fetchInitialData)(InitialDashboardWithContexts)
