import React from "react"
import DateInput from "components/input/DateInput"
import fieldWrapper from "../fieldWrapper"
import {
  DATE_FORMAT,
  DATE_VALIDATION_MESSAGE,
  format,
  isDateValid,
} from "utilities/date"

type Props = {
  className?: string
  id?: string
  name: string
  label?: string
  disabled?: boolean
  unwrapped?: boolean
  validateField?: boolean
}

function InnerField({ field, form, className, id, name, disabled, unwrapped }) {
  return (
    <DateInput
      {...field}
      className={className}
      id={id || name}
      disabled={disabled}
      unwrapped={unwrapped}
      onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
        form.setFieldTouched(name, true)
        form.setFieldValue(name, format(event.target.value, DATE_FORMAT))
        field.onChange(event)
      }}
    />
  )
}

const DateField: React.FunctionComponent<Props> = fieldWrapper(InnerField, {
  // @ts-ignore
  validate(value) {
    if (!isDateValid(value)) {
      return DATE_VALIDATION_MESSAGE
    }
  },
})
export default DateField
