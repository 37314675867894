import React from "react"
import Modal from "react-modal"
import classNames from "classnames"
import ModalHeader from "./ModalHeader"
import ModalBody from "./ModalBody"
import ModalFooter from "./ModalFooter"
import ModalNonFixedContainer from "./ModalNonFixedContainer"

const STATIC_BACKDROP = "static"

export type ModalSize = "xl" | "lg" | "md" | "sm"

type Props = {
  title?: string
  show: boolean
  size?: ModalSize
  backdrop?: string | boolean
  cancel?(): void | Promise<void>
  closeable?: boolean
  onEntered?(): void
  onExited?(): void
  children?: React.ReactNode
  verticalCenter?: boolean
}
function Component({
  title,
  show,
  size,
  backdrop,
  cancel,
  closeable,
  children,
  onEntered,
  onExited,
  verticalCenter,
}: Props) {
  if (!closeable) {
    backdrop = STATIC_BACKDROP
  }
  const onClose = () => {
    cancel?.()
    onExited?.()
  }
  return (
    <Modal
      overlayClassName="modal modal-backdrop fade in"
      bodyOpenClassName="modal-open"
      isOpen={show}
      onAfterOpen={onEntered}
      className={classNames("modal-dialog", size && `modal-${size}`, {
        "vertical-center": verticalCenter,
      })}
      onRequestClose={onClose}
      shouldCloseOnOverlayClick={backdrop !== STATIC_BACKDROP}
      shouldCloseOnEsc
      ariaHideApp={false}
    >
      <div className="modal-content">
        {title && (
          <ModalHeader>
            {closeable && (
              <button
                className="close icon clickable"
                type="button"
                aria-label="Close"
                onClick={onClose}
              >
                ×
              </button>
            )}
            {title && <h2 className="modal-title">{title}</h2>}
          </ModalHeader>
        )}
        {children}
      </div>
    </Modal>
  )
}

Component.defaultProps = {
  cancel: () => {},
  closeable: true,
  size: "lg",
}

Component.Header = ModalHeader
Component.Body = ModalBody
Component.Footer = ModalFooter
Component.NonFixedContainer = ModalNonFixedContainer

export default Component
