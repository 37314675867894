const MAX_INCHES = 100
const MAX_FEET = Math.floor(MAX_INCHES / 12)
const MAX_FEET_INCHES = MAX_INCHES % 12

export const IS_REQUIRED_ERROR = "is required"
export const INCHES_OUT_OF_BOUND_ERROR = "must be 0 - 11"
export const FEET_MAX_ERROR = `must be less than or equal to ${MAX_FEET} feet ${MAX_FEET_INCHES} inches`
export const INCHES_MAX_ERROR = `must be less than or equal ${MAX_INCHES} inches`
export const INCHES_MIN_ERROR = "must be greater than or equal to 12 inches"

type ValidationErrors = {
  heightFeet?: string
  heightInches?: string
  weightLbs?: string
  numberOfWounds?: string
}

function validateFeetInches(heightFeet, heightInches) {
  const errors: ValidationErrors = {}
  if (heightInches >= 12 || heightInches < 0) {
    errors.heightInches = INCHES_OUT_OF_BOUND_ERROR
  }
  if (!heightFeet) {
    errors.heightFeet = IS_REQUIRED_ERROR
  } else if (
    heightFeet > MAX_FEET ||
    (heightFeet === MAX_FEET && heightInches > MAX_FEET_INCHES)
  ) {
    errors.heightFeet = FEET_MAX_ERROR
  }
  return errors
}

function validateInches(heightInches) {
  if (!heightInches) {
    return IS_REQUIRED_ERROR
  } else if (heightInches > MAX_INCHES) {
    return INCHES_MAX_ERROR
  } else if (heightInches < 12) {
    return INCHES_MIN_ERROR
  }
}

export const validate = ({
  requiresHeight,
  requiresWeight,
  requiresWoundCount,
  useFeetForHeight,
}: {
  requiresHeight?: boolean
  requiresWeight?: boolean
  requiresWoundCount?: boolean
  useFeetForHeight?: boolean
}) => {
  return ({
    heightFeet,
    heightInches,
    weightLbs,
    numberOfWounds,
  }: {
    heightFeet?: string | number
    heightInches?: string | number
    weightLbs?: string | number
    numberOfWounds?: string | number
  }) => {
    let errors: ValidationErrors = {}
    if (requiresHeight) {
      if (useFeetForHeight) {
        errors = { ...errors, ...validateFeetInches(heightFeet, heightInches) }
      } else {
        const error = validateInches(heightInches)
        if (error) {
          errors.heightInches = error
        }
      }
    }
    if (requiresWeight) {
      if (!weightLbs) {
        errors.weightLbs = IS_REQUIRED_ERROR
      }
    }
    if (requiresWoundCount) {
      if (!numberOfWounds) {
        errors.numberOfWounds = IS_REQUIRED_ERROR
      }
    }
    return errors
  }
}
