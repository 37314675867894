import React, { useState } from "react"
import * as styles from "./../../index.module.scss"
import { useCsrInboxState } from "applications/SupplierOrganizationInboxDocument/csrInboxStateContext"
import DocumentsBrowser from "components/DocumentsBrowser"
import { Document } from "components/DocumentsBrowser/sharedTypes"
import { transformImage } from "./api"
import { updateDocumentFileRotation } from "./utilities"
import { handleError } from "utilities/error"

interface Props {
  document: Document
}

export const DocumentBrowserFrame = ({ document }: Props) => {
  const [documentState, setDocumentState] = useState(document)
  const csrInboxState = useCsrInboxState()

  const handleRotate = async (
    _documentId: string,
    activeImageIndex: number,
    rotationDegrees: number
  ) => {
    const file = documentState.files[activeImageIndex]
    transformImage(csrInboxState.supplierOrganization.id, csrInboxState.id, {
      documentFileTransform: {
        fileName: file.name,
        rotationDegrees,
      },
    })
      .then(() => {
        setDocumentState(
          updateDocumentFileRotation(
            documentState,
            activeImageIndex,
            rotationDegrees
          )
        )
      })
      .catch(handleError)
  }

  return (
    <div className={styles.documentBrowserFrame}>
      {document.downloadUrl ? (
        <DocumentsBrowser
          newUi
          documents={[documentState]}
          hideDocumentsNav
          className={styles.documentBrowserInjectedStyle}
          handleImageRotated={handleRotate}
        />
      ) : (
        <div>Document could not be loaded at this time.</div>
      )}
    </div>
  )
}
