import { ChartNoteStatus } from "sharedTypes"

export const getTrackingStatus = (statuses: ChartNoteStatus[]): string => {
  if (statuses.length === 0) {
    return ""
  }

  const counts = {}
  statuses.forEach((status) => {
    counts[status] += 1
  })

  const mixed = Object.keys(counts).length >= 2

  if (mixed) {
    return "mixed"
  }

  const singleStatus = statuses[0].replace(" ", "").toLowerCase()

  return singleStatus
}
