import React from "react"
import CaptureForm from "./components/CaptureForm"
import * as api from "./api"
import { navigate } from "utilities/navigation"

interface Props {
  submitPath: string
}

function EmrEmailCapture({ submitPath }: Props) {
  const handleCapture = (params) => {
    return api.emrEmailCapture(submitPath, params).then((data) => {
      navigate(data.redirectPath)
    })
  }

  return (
    <div>
      <CaptureForm emailCapture={handleCapture} />
    </div>
  )
}

export default EmrEmailCapture
