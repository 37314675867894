// @team @catalog-crew

export function seedsUrl(): string {
  return "/cms/catalog/seeds"
}

export function seedCreateUrl(): string {
  return `/cms/catalog/seeds/new`
}

export function seedDetailUrl(seedId: string): string {
  return `/cms/catalog/seeds/${seedId}`
}

export function seedEditUrl(seedId: string): string {
  return `/cms/catalog/seeds/${seedId}/edit`
}
