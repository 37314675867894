// @ts-strict-ignore
import React, { useState } from "react"
import { Checkbox, Form } from "components/form"
import Icon from "components/Icon"
import ContactFormModal from "./ContactFormModal"
import { isEmailValid } from "utilities/email"
import { isPhoneValid } from "utilities/phone"
import { DmeOrder } from "sharedTypes"
import { DoctorContactAttributes } from "../sharedTypes"

type Props = {
  count?: number
  maskedData?: string
  dmeOrder: DmeOrder
  createDoctorContact?(attributes: DoctorContactAttributes): Promise<any>
  channel: string
  updateSignatureChannel(channel: string, isSelected: boolean): Promise<void>
  verifiedChannels: string[]
}

const ContactSignature = ({
  count,
  maskedData,
  dmeOrder,
  createDoctorContact,
  channel,
  updateSignatureChannel,
  verifiedChannels,
}: Props) => {
  const [channelChecked, setChannelChecked] = useState(
    dmeOrder.signatureChannels[channel]
  )
  const [isModalOpen, showModal] = useState(false)

  const channelMapping = {
    email: "email",
    fax: "faxNumber",
    sms: "mobileNumber",
  }
  const getIconType = (channel) => {
    switch (channel) {
      case "email":
        return "envelope"
      case "fax":
        return "fax"
      case "sms":
        return "phone"
      case "epic":
        return "file"
      case "cerner":
        return "file"
    }
  }
  const renderIcon = () => {
    return <Icon type={getIconType(channel)} className="canopy-mis-4x" />
  }
  const renderSuffix = () => {
    const view = renderIcon()
    if (!verifiedChannels.includes(channel) && count === 0) {
      return (
        <span className="font-xs canopy-mbe-0 canopy-mbs-2x float-right">
          Awaiting verification{view}
        </span>
      )
    }
    return (
      <span className="font-xs canopy-mbe-0 canopy-mbs-2x float-right">
        {`${count} ${count === 1 ? "order" : "orders"} signed`}
        {view}
      </span>
    )
  }

  const renderPreferred = () => {
    if (dmeOrder.preferredChannels.includes(channel)) {
      return (
        <span className="pill pill-solid pill-primary d-inline-flex flex-row align-items-center canopy-mis-4x canopy-mbe-0 canopy-pis-4x canopy-typography-body-small">
          <span className="font-notice">Preferred</span>
        </span>
      )
    } else {
      return null
    }
  }
  const channelLabel = {
    epic: (
      <>
        {maskedData}
        {renderSuffix()}
        {renderPreferred()}
      </>
    ),
    cerner: (
      <>
        {maskedData}
        {renderSuffix()}
        {renderPreferred()}
      </>
    ),
    email: (
      <>
        {maskedData}
        {renderSuffix()}
        {renderPreferred()}
      </>
    ),
    fax: (
      <>
        {`Fax: ${maskedData}`}
        {renderSuffix()}
        {renderPreferred()}
      </>
    ),
    sms: (
      <>
        {`SMS: ${maskedData}`}
        {renderSuffix()}
        {renderPreferred()}
      </>
    ),
  }
  const addChannelTitle = {
    email: "+ Add Clinician Email",
    fax: "+ Add Clinician Fax Number",
    sms: "+ Add Clinician Cell Phone Number",
  }
  const attribute = channelMapping[channel]

  const createContact = (values, { setErrors }) => {
    return createDoctorContact(values)
      .then(() => {
        setChannelChecked(true)
        showModal(false)
      })
      .catch((errors) => {
        setErrors(errors)
      })
  }

  const validate = (attributes: DoctorContactAttributes) => {
    const value = attributes[attribute]
    if (attribute === "email" && !isEmailValid(value)) {
      return { [attribute]: false }
    }
    if (
      (attribute === "mobileNumber" || attribute === "faxNumber") &&
      !isPhoneValid(value)
    ) {
      return { [attribute]: false }
    }
  }

  const update = (isChecked) => {
    updateSignatureChannel(channel, isChecked)
    setChannelChecked(isChecked)
  }

  const inputId = `${channel}ChannelId`
  const newContactFormDisabled = !createDoctorContact
  const hasRequiredData = !!maskedData
  const isMissingContactInformation = !maskedData && !newContactFormDisabled

  return (
    <>
      <Form
        initialValues={{ [inputId]: channelChecked }}
        onSubmit={(values) => {
          update(values[inputId])
        }}
        submitOnChange
      >
        {hasRequiredData && (
          <Checkbox label={channelLabel[channel]} name={inputId} unwrapped />
        )}
        {isMissingContactInformation && (
          <button
            className="checkbox-link block"
            onClick={() => showModal(true)}
          >
            {addChannelTitle[channel]}{" "}
            <div className="float-right">{renderIcon()}</div>
          </button>
        )}
      </Form>
      <ContactFormModal
        show={isModalOpen}
        title={addChannelTitle[channel]}
        cancel={() => showModal(false)}
        id={inputId}
        initialValues={{ [attribute]: "" }}
        name={attribute}
        onSubmit={createContact}
        validate={validate}
      />
    </>
  )
}

export default ContactSignature
