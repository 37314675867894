// @ts-strict-ignore
import React from "react"
import { ErrorMessage, Field } from "formik"
import SuperText from "components/SuperText"
import SubText from "components/SubText"
import classNames from "classnames"

const errorMsgFor = (name) => (
  <ErrorMessage
    name={name}
    render={(msg) => <div className="color-danger">{msg}</div>}
  />
)

const ADDON_POSITION = { INSIDE: "inside", OUTSIDE: "outside" }

export type Option = {
  label: string
  value: number | string
  addOnPosition?: string
  addOn?(params: object): React.ReactNode
  callout?: React.ReactNode
}

type Props = {
  name: string
  label?: string
  labelClassName?: string
  labelStyle?: object
  supertext?: string | React.ReactElement
  subtext?: string | React.ReactElement
  id?: string
  disabled?: boolean
  onChange?(event: React.FormEvent): void
  options: Option[]
  render?({
    label,
    name,
    error,
    renderCheckbox,
    renderField,
    options,
  }): React.ReactElement
  unframed?: boolean
  outdented?: boolean
  preventChange?: boolean
}

function Checkboxes({
  id,
  name,
  label,
  supertext,
  subtext,
  options,
  onChange,
  disabled,
  render,
  unframed,
  outdented,
  preventChange,
  labelClassName,
  labelStyle,
}: Props) {
  const renderCheckboxes = ({ field, form }) =>
    options.map((checkbox, index) =>
      renderCheckbox(checkbox, { field, form }, index)
    )
  const renderCheckbox = (checkbox, { field, form }, index) => {
    const selectedValues = field.value || []
    const inputId = `${id || name}-${checkbox.value}`
    const addOnPosition = checkbox.addOnPosition || ADDON_POSITION.OUTSIDE
    const addOn = checkbox.addOn && checkbox.addOn({ field, form })
    return (
      <React.Fragment key={checkbox.value}>
        <div
          className={classNames("checkbox primary block", {
            "canopy-mbs-0": index === 0 && supertext,
            "canopy-mbe-0": index === options.length - 1 && subtext,
            unframed: unframed,
            outdented: outdented,
          })}
        >
          <input
            type="checkbox"
            id={inputId}
            value={checkbox.value}
            checked={selectedValues.includes(checkbox.value)}
            onChange={(event) => {
              if (preventChange) {
                form.setFieldValue(name, selectedValues)
                return
              }
              onChange && onChange(event)
              let newValue

              if (event.target.checked) {
                newValue = [...selectedValues, checkbox.value]
              } else {
                newValue = selectedValues.filter((v) => v !== checkbox.value)
              }

              form.setFieldValue(name, newValue)
            }}
            disabled={disabled}
          />
          <label className="col-form-label" htmlFor={inputId}>
            {checkbox.callout}
            {checkbox.label}
            {addOnPosition === ADDON_POSITION.INSIDE && addOn}
          </label>
          {addOnPosition === ADDON_POSITION.OUTSIDE && addOn}
        </div>
      </React.Fragment>
    )
  }

  const renderField = (render) => {
    return <Field name={name}>{render}</Field>
  }

  const error = errorMsgFor(name)

  if (render) {
    return render({
      label,
      name,
      renderCheckbox,
      renderField,
      error,
      options,
    })
  }

  return (
    <div className="form-group">
      {label && (
        <label className={classNames(labelClassName)} style={labelStyle}>
          {label}
        </label>
      )}
      <SuperText content={supertext} />
      <div>{renderField((props) => renderCheckboxes(props))}</div>
      <SubText content={subtext} />
      {error}
    </div>
  )
}

export default Checkboxes
