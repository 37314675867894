// @ts-strict-ignore
import React, { useState } from "react"
import ReadOnlyQuestionList from "./ReadOnlyQuestionList"
import { handleError } from "utilities/error"
import { ChartNote, ChartNoteQuestion } from "sharedTypes"
import Modal from "components/Modal"

type Props = {
  chartNote: ChartNote
  fetchChartNoteQuestions(chartNote: ChartNote): Promise<ChartNoteQuestion[]>
}
const ReadOnlyChartNote = ({ chartNote, fetchChartNoteQuestions }: Props) => {
  const [expanded, setExpanded] = useState(false)
  const [loading, setLoading] = useState(false)
  const [questions, setQuestions] = useState(null)

  const toggle = () => {
    if (expanded) {
      setExpanded(false)
      return
    } else if (!expanded && questions) {
      setExpanded(true)
      return
    } else if (loading) {
      return
    }

    setLoading(true)

    return fetchChartNoteQuestions(chartNote)
      .then((questions) => {
        setQuestions(questions)
        setLoading(false)
        setExpanded(true)
      })
      .catch(handleError)
  }
  return (
    <>
      <a className="link" onClick={toggle}>
        View {chartNote.name} Documentation
      </a>
      {questions && expanded && (
        <Modal
          show
          cancel={toggle}
          title={`${chartNote.name} Documentation`}
          size="md"
        >
          <Modal.Body>
            <ReadOnlyQuestionList questions={questions} />
          </Modal.Body>
          <Modal.Footer>
            <button
              type="button"
              className="btn btn-primary-o"
              onClick={toggle}
            >
              Close
            </button>
          </Modal.Footer>
        </Modal>
      )}
    </>
  )
}

export default ReadOnlyChartNote
