import React from "react"
import { GridRowData } from "@mui/x-data-grid-pro"
import { isTest } from "../../../../utilities/environment"
import { supplierInsuranceSettingsColumnDefinitions } from "./tableColumnDefinitions"
import { InsuranceProductConnection } from "../../../../graphql/__generated__/graphql"
import { useGraphqDataGrid } from "../../../../hooks/useGraphqlDataGrid/useGraphqlDataGrid"
import ParachuteMuiDataGridPro from "../../../../components/ParachuteMuiDataGrid/ParachuteMuiDataGrid"
import { makeStyles } from "@material-ui/core/styles"

interface Props {
  insuranceData: InsuranceProductConnection | undefined
  loading: boolean
  paginate: any
  refetchData: any
}

const useStyles = makeStyles({
  root: {
    "& .MuiDataGrid-renderingZone": {
      maxHeight: "none !important",
    },
    "& .MuiDataGrid-cell": {
      lineHeight: "unset !important",
      maxHeight: "none !important",
      whiteSpace: "normal",
    },
    "& .MuiDataGrid-row": {
      maxHeight: "none !important",
    },
  },
})

const SupplierInsuranceSettingsTable: React.FC<Props> = ({
  insuranceData,
  loading,
  paginate,
  refetchData,
}) => {
  const { nodes, pageInfo, totalCount } = insuranceData || {}
  const insuranceProducts: Readonly<GridRowData[]> = nodes
    ? (nodes as GridRowData[])
    : []

  const { gridOptions, handlePagination, handleSorting } = useGraphqDataGrid(
    refetchData,
    paginate,
    pageInfo
  )
  const classes = useStyles()

  return (
    <ParachuteMuiDataGridPro
      className={classes.root}
      columns={supplierInsuranceSettingsColumnDefinitions()}
      rows={insuranceProducts}
      loading={loading}
      getRowId={(row) => row.externalId}
      pagination={true}
      rowCount={totalCount}
      pageSize={50}
      page={gridOptions.page}
      paginationMode="server"
      sortingMode="server"
      sortModel={gridOptions.sort}
      onSortModelChange={async (sortModel) => await handleSorting(sortModel)}
      autoHeight
      rowHeight={100}
      hideFooterSelectedRowCount={true}
      rowsPerPageOptions={[50]}
      onPageChange={async (page) => await handlePagination(page)}
      disableVirtualization={isTest()}
    />
  )
}

export default SupplierInsuranceSettingsTable
