import React from "react"
import InputText from "./InputText"

type Props = {
  content: string | React.ReactElement
}

const SuperText = ({ content }: Props) => {
  return <InputText className="canopy-mbe-4x" content={content} />
}

export default SuperText
