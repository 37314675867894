import { get } from "services/api"
import { employerPrefix } from "utilities/url"
import { makeUrlProvider } from "./urls"

export const makeApi = (supplierId) => {
  const urls = makeUrlProvider(supplierId)
  return {
    searchDocumentSources: (term) => {
      return get(urls.searchDocumentSourcesUrl, { term }).then(
        ({ data }) => data
      )
    },
    searchReceivingFaxNumbers: (term) => {
      return get(urls.searchReceivingFaxNumbersUrl, { term }).then(
        ({ data }) => data
      )
    },
    getFilteredDocuments: (filters) => {
      return get(urls.csrInboxDocumentsUrl, filters).then(({ data }) => data)
    },
    searchClinicalFacilities: (term) => {
      return get(urls.searchClinicalFacilitiesUrl, { term }).then(
        ({ data }) => data
      )
    },
    getAssignees: (term) => {
      return get(urls.getAssigneesUrl, { term }).then(({ data }) => data)
    },
  }
}

export const getCsrInboxStateInternalStatusesUrl = (id, orgType, orgId) => {
  return `${employerPrefix(
    orgType,
    orgId
  )}/dashboard/csr_inbox_states/${id}/internal_status.json`
}

export const fetchInitialDashboardData = ({ supplierId }) => {
  const urls = makeUrlProvider(supplierId)
  return get(urls.initialDashboardDataUrl)
    .then(({ data }) => data)
    .catch(({ response }) => Promise.reject(response.data))
}
