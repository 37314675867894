import { employerPrefix } from "utilities/url"

export const makeUrlProvider = (employerType, employerId) => {
  const urlPrefix = employerPrefix(employerType, employerId)
  return {
    doctorsSearchUrl: `/u/doctors_search.json`,
    getExternalSystemBranchesUrl: `${urlPrefix}/external_system_branches.json`,
    facilitiesSearchUrl: `${urlPrefix}/facilities_search.json`,
    employeesSearchUrl: `${urlPrefix}/employees.json`,
    initialValuesUrl: `${urlPrefix}/facilities_search/initial_values.json`,
    defaultValuesUrl: `${urlPrefix}/facilities_search/default_values.json`,
    legalEntitiesSearchUrl: `${urlPrefix}/supplier_legal_entities.json`,
    recommendedFacilitiesUrl: `${urlPrefix}/facilities_search/recommended_facilities.json`,
  }
}
