import React from "react"
import { Form, Select } from "components/form"
import { states } from "utilities/address"

interface Props {
  onCancel?: () => void
  onSubmit(values: { serviceAreaStates: string[] }): Promise<void>
  initialServiceAreaStates: string[]
}
const StatesSelectForm: React.FC<Props> = ({
  onSubmit,
  onCancel,
  initialServiceAreaStates,
}) => {
  const options = states.map((state) => ({ label: state[1], value: state[1] }))

  const cancelButton = (
    <button className="btn btn-secondary" onClick={onCancel} type="button">
      Cancel
    </button>
  )
  return (
    <Form
      initialValues={{ serviceAreaStates: initialServiceAreaStates }}
      onSubmit={onSubmit}
    >
      <Select
        closeMenuOnSelect={false}
        isMulti
        isSearchable
        label="Service areas"
        name="serviceAreaStates"
        options={options}
      />
      <div className="canopy-mbs-4x">
        <button className="btn btn-brand canopy-mie-4x" type="submit">
          Save
        </button>
        {onCancel && cancelButton}
      </div>
    </Form>
  )
}

export default StatesSelectForm
