// @ts-strict-ignore
export enum PasswordError {
  TooShort = "too_short",
  MissingNumber = "missing_number",
  MissingLowercaseLetter = "missing_lowercase_letter",
  MissingCapitalLetter = "missing_uppercase_letter",
}

export const validatePassword = (password): PasswordError[] => {
  const errors = []
  if (password.length < 8) {
    errors.push(PasswordError.TooShort)
  }
  if (!/\d/.test(password)) {
    errors.push(PasswordError.MissingNumber)
  }
  if (!/[a-z]/.test(password)) {
    errors.push(PasswordError.MissingLowercaseLetter)
  }
  if (!/[A-Z]/.test(password)) {
    errors.push(PasswordError.MissingCapitalLetter)
  }
  return errors
}

export const isPasswordValid = (password) => {
  return validatePassword(password).length === 0
}

export const validate = ({ password, passwordConfirmation }) => {
  const errors: {
    password?: boolean
    passwordConfirmation?: string
  } = {}

  if (!password || !isPasswordValid(password)) {
    errors.password = true
  }
  if (!passwordConfirmation) {
    errors.passwordConfirmation = "Enter password confirmation"
  }
  if (password !== passwordConfirmation) {
    errors.passwordConfirmation = "Password confirmation must match password"
  }
  return errors
}
