import React from "react"
import classNames from "classnames"

interface Props {
  children: React.ReactNode
  size: string
  verticalCenter: boolean
}

function ModalNonFixedContainer({ children, size, verticalCenter }: Props) {
  return (
    <div
      className={classNames("modal-dialog", size && `modal-${size}`, {
        "vertical-center": verticalCenter,
      })}
    >
      <div className="modal-content">{children}</div>
    </div>
  )
}

export default ModalNonFixedContainer
