import { underscore } from "utilities/string"
import { EmployerType } from "sharedTypes"
import { Event, EventAction } from "sharedTypes"

export enum EventClassName {
  Parachute = "parachute",
  Referral = "referral",
  Supplier = "supplier",
  Patient = "patient",
  Event = "event",
  Automated = "automated",
  SupplierAutomatedMessage = "supplier-automated-message",
}

export const MANUAL_EVENTS = [
  EventAction.Comment,
  EventAction.InternalComment,
  EventAction.SupplierComment,
  EventAction.SupplierSendBackInstructions,
  EventAction.SignatureDenied,
]

const PATIENT_PORTAL_EVENTS = [
  EventAction.PatientMessage,
  EventAction.PatientPortalAutomated,
  EventAction.SupplierAutomatedMessage,
]

export function eventClassName({
  action,
  userEmployerType,
  superAdmin,
}: Event): EventClassName {
  const isManualEvent = MANUAL_EVENTS.includes(action)
  const isPortalEvent = PATIENT_PORTAL_EVENTS.includes(action)
  if (!isManualEvent && !isPortalEvent) {
    return EventClassName.Event
  }
  if (superAdmin) {
    return EventClassName.Parachute
  }
  const type = underscore(userEmployerType)
  if (action === EventAction.SupplierAutomatedMessage) {
    return EventClassName.SupplierAutomatedMessage
  }
  if (action === EventAction.PatientPortalAutomated) {
    return EventClassName.Automated
  }
  switch (type) {
    case EmployerType.ClinicalFacility:
      return EventClassName.Referral
    case EmployerType.Supplier:
      return EventClassName.Supplier
    case EmployerType.None:
      return EventClassName.Patient
    case EmployerType.Doctor:
      return EventClassName.Referral
    default:
      return EventClassName.Referral
  }
}
