import { updateUserUrl, updatePasswordUrl, resendInvitationUrl } from "./urls"
import { put, post } from "services/api"
import { Employment, User } from "../shared/sharedTypes"

export const updateUser = (
  clinicalFacilityId: string,
  userId: string,
  invitationToken: string,
  params
): Promise<{ employment: Employment; user: User }> => {
  const { firstName, lastName, title } = params
  return put(updateUserUrl(clinicalFacilityId, userId), {
    invitationToken,
    employment: { title },
    user: { firstName, lastName },
  })
    .then(({ data }) => data)
    .catch(({ response }) => Promise.reject(response.data))
}

export const updatePassword = (
  clinicalFacilityId: string,
  userId: string,
  invitationToken: string,
  params
): Promise<{ redirectUrl: string }> => {
  return put(updatePasswordUrl(clinicalFacilityId, userId), {
    user: { ...params, invitationToken },
  })
    .then(({ data }) => data)
    .catch(({ response }) => Promise.reject(response.data))
}

export const resendInvitation = (
  clinicalFacilityId: string,
  userId: string,
  invitationToken: string
): Promise<void> => {
  return post(resendInvitationUrl(clinicalFacilityId, userId), {
    invitationToken,
  })
    .then(({ data }) => data)
    .catch(({ response }) => Promise.reject(response.data))
}
