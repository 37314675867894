import { Supplier } from "../../../../types/sharedTypes"
import React from "react"
import { CanopyButton } from "@parachutehealth/canopy-button"
import { CanopyDialogModal } from "@parachutehealth/canopy-dialog-modal"
import { meta } from "../../../../../../utilities/meta"

type Props = {
  open: boolean
  supplier: Supplier
  onClose: () => void
}
/**
 * This is an ugly legacy replacement for the simple form on the legacy page;
 * it uses a standard form submit and no fancy React tech.
 */
const SupplierCatalogUploadModal = ({
  open,
  supplier,
  onClose,
}: Props): React.JSX.Element => {
  const negotiateFile = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFile(event?.target?.files?.item(0) || null)
  }
  const [file, setFile] = React.useState<File | null>(null)
  const [submitting, setSubmitting] = React.useState<boolean>(false)
  const formRef = React.createRef<HTMLFormElement>()
  const fileInputRef = React.useRef<HTMLInputElement>(null)
  const submitButton = (
    <CanopyButton
      loading={submitting}
      disabled={!file}
      onClick={() => {
        setSubmitting(true)
        formRef.current?.submit()
      }}
    >
      Submit
    </CanopyButton>
  )
  const cancelButton = (
    <CanopyButton disabled={submitting} variant="tertiary" onClick={onClose}>
      Cancel
    </CanopyButton>
  )
  return (
    <CanopyDialogModal
      header="Upload Supplier Catalog"
      primaryFooterButton={submitButton}
      secondaryFooterButton={cancelButton}
      onClose={onClose}
      size="medium"
      open={open}
    >
      <form
        ref={formRef}
        action={`/cms/supplier_product_variation_imports?supplier_id=${supplier.id}`}
        encType="multipart/form-data"
        method="post"
      >
        <input
          type="hidden"
          name="authenticity_token"
          value={meta("csrf-token")}
        />
        <input
          accept="text/csv"
          className="d-none"
          name="supplier_catalog_merge[file]"
          ref={fileInputRef}
          data-testid="import-wizard-file-button"
          id="import-wizard-file-button"
          onChange={(e) => negotiateFile(e)}
          type="file"
        />
        <label htmlFor="import-wizard-file-button">
          {/* must be a span or else it won't trigger the hidden input */}
          <CanopyButton size="small" variant="secondary" as="span">
            Choose CSV
          </CanopyButton>
        </label>
        <p className="canopy-mis-0">{file?.name}</p>
      </form>
    </CanopyDialogModal>
  )
}

export default SupplierCatalogUploadModal
