import React from "react"
import classNames from "classnames"

export type Status =
  | "success"
  | "info"
  | "accent"
  | "warning"
  | "danger"
  | "default"
  | "error"
  | "textured"

type Props = {
  className?: string
  leftIcon?: boolean | string
  rightIcon?: boolean | string
  onClick?(): void
  onLeftIconClick?(): void
  onRightIconClick?(): void
  bordered?: boolean
  status: Status
}

const statusMap = {
  error: "alert-danger",
}

function renderIcon(icon, className, onClick) {
  const clickable = !!onClick
  if (typeof icon === "string") {
    return clickable ? (
      <div
        data-testid="alert-icon"
        className={`fas ${icon} ${className}`}
        role="button"
        onClick={onClick}
      />
    ) : (
      <div data-testid="alert-icon" className={`fas ${icon} ${className}`} />
    )
  } else if (icon) {
    return clickable ? (
      <div
        data-testid="alert-icon"
        className={`alert-icon ${className}`}
        role="button"
        onClick={onClick}
      />
    ) : (
      <div data-testid="alert-icon" className={`alert-icon ${className}`} />
    )
  }
  return null
}

const Alert: React.FC<Props> = ({
  className,
  status,
  leftIcon,
  rightIcon,
  onClick,
  onLeftIconClick,
  onRightIconClick,
  children,
  bordered,
}) => {
  const clickable = !!onClick
  const statusClassName = statusMap[status] || `alert-${status}`
  const borderless = !clickable && !bordered
  return (
    <div
      onClick={onClick}
      className={classNames(
        className,
        "alert alert-rounded canopy-mbe-4x",
        statusClassName,
        { pointer: clickable, "alert-borderless": borderless },
        leftIcon && "has-left-icon",
        rightIcon && "has-right-icon"
      )}
    >
      {renderIcon(leftIcon, "left", onLeftIconClick)}
      {renderIcon(rightIcon, "right", onRightIconClick)}
      {children}
    </div>
  )
}

export default Alert
