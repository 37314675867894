import React, { ReactElement } from "react"
import * as styles from "./index.module.scss"
import DocumentationRequirementIcon from "components/DocumentationRequirementIcon"
import ExternalQuestionnaireStatusPill from "applications/Workflow/containers/Review/components/ExternalQuestionnaireStatusPill"

interface Props {
  status?: ReactElement
  details?: ReactElement
  title: string
  isDocumentParachuteGenerated: boolean
  showDetails: boolean
  recommendation?: string
}

const ClinicalInformationRow = (props: Props) => {
  const {
    status,
    details,
    title,
    isDocumentParachuteGenerated,
    recommendation,
  } = props

  {
    return (
      <>
        <div className={`position-relative ${styles.container}`}>
          <DocumentationRequirementIcon
            isDocumentParachuteGenerated={isDocumentParachuteGenerated}
          />
          <div className="documentation-requirement-content">
            <div className="requirement-wrapper">
              <div className="flex-1">{title}</div>
              {status}
              {recommendation && (
                <ExternalQuestionnaireStatusPill
                  satisfyStatus={recommendation}
                />
              )}
            </div>
            {props.showDetails && details}
          </div>
        </div>
      </>
    )
  }
}
export default ClinicalInformationRow
