import { employerPrefix } from "utilities/url"

export function defaultDoctorUrl(id) {
  return `${employerPrefix()}/manage_clinicians/${id}.json`
}

export function defaultDoctorCreateUrl() {
  return `${employerPrefix()}/manage_clinicians.json`
}

export function defaultDoctorSearchUrl() {
  return `${employerPrefix()}/manage_clinicians/search.json`
}
