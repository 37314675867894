import React from "react"
import Suggestion from "components/form/Select/Suggestion"
import { trackEvent } from "../../../utilities/analytics"

const NewPatientSuggestion = () => {
  return (
    <Suggestion
      className="d-inline-block font-xs"
      onClick={() => trackEvent("click-patient-search-cta")}
    >
      Can't find the patient you're looking for?{" "}
      <a href="#" className="link">
        Click here to create a new patient
      </a>
    </Suggestion>
  )
}

export default NewPatientSuggestion
