// @team @paypals
import { ApolloProvider } from "@apollo/client"
import { apolloClient } from "../services/graphql/apolloClient"
import React from "react"

export const withApollo = (Component) => {
  return (props: any) => {
    return (
      <ApolloProvider client={apolloClient}>
        <Component {...props} />
      </ApolloProvider>
    )
  }
}
