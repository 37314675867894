import React from "react"
import { Icd10Code } from "sharedTypes"

type Props = {
  icd10Codes: Icd10Code[]
}

const Diagnoses = (props: Props) => {
  const { icd10Codes } = props
  return (
    <div>
      <span className="bold color-black">Diagnoses</span>
      <ul>
        {icd10Codes.map((icd10Code) => (
          <li className="ml-n3" key={icd10Code.code}>
            <span className="bold color-black">{icd10Code.codeLabel}</span> -{" "}
            {icd10Code.description}
          </li>
        ))}
      </ul>
    </div>
  )
}

export default Diagnoses
