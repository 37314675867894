import React, { useState } from "react"
import { DmeOrder } from "sharedTypes"
import * as api from "./api"
import Icon from "components/Icon"
import Modal, { ModalSize } from "components/Modal"
import Overlay from "components/Overlay"
import { handleError } from "utilities/error"

type Props = {
  dmeOrderId: string
  initialStatus: string
  defaultStatus: string
  editable: boolean
  onUpdate(...args): Promise<any>
  formatStatus(dmeOrder: DmeOrder): string
  renderChild(dmeOrder, onUpdate): React.ReactNode
  title: string
  modalSize?: ModalSize
  employerPrefix?: string
}

const StatusWithUpdateModal: React.FC<Props> = ({
  dmeOrderId,
  initialStatus,
  defaultStatus,
  editable,
  onUpdate,
  formatStatus,
  renderChild,
  title,
  modalSize,
  employerPrefix,
}) => {
  const [isOpen, setIsOpen] = useState(false)
  const [dmeOrder, setDmeOrder] = useState<DmeOrder | null>(null)
  const [statusText, setStatusText] = useState(initialStatus)

  const updateOrder = (newDmeOrder) => {
    setDmeOrder(newDmeOrder)
    setStatusText(formatStatus(newDmeOrder))
  }

  const openEditor = () => {
    setIsOpen(true)
    api
      .getDmeOrder(dmeOrderId, employerPrefix)
      .then(updateOrder)
      .catch(handleError)
  }

  const closeEditor = () => {
    setIsOpen(false)
    setDmeOrder((previousDmeOrder) => {
      // @ts-ignore
      setStatusText(formatStatus(previousDmeOrder))
      return null
    })
  }

  const handleUpdate = (...params) => {
    return onUpdate(...params).then(updateOrder)
  }

  const renderEditorToggle = () => {
    if (!editable) {
      return <div>{statusText}</div>
    }
    return (
      <a onClick={openEditor} className="link link-teal position-relative">
        <div>
          {statusText || defaultStatus}{" "}
          <Icon
            className="canopy-mis-2x position-absolute"
            type="chevron-down"
          />
        </div>
      </a>
    )
  }

  return (
    <>
      {renderEditorToggle()}
      <Modal title={title} show={isOpen} cancel={closeEditor} size={modalSize}>
        <Modal.Body>
          {!dmeOrder ? (
            <Overlay className="canopy-mbs-48x" active showSpinner />
          ) : (
            renderChild(dmeOrder, handleUpdate)
          )}
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-primary"
            type="button"
            onClick={closeEditor}
          >
            Done
          </button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default StatusWithUpdateModal
