// @ts-strict-ignore
import React from "react"

interface ApiContextInterface {
  searchDocumentSources: (
    term: string
  ) => Promise<{ results: { source: string; sourceType: string }[] }>
  searchReceivingFaxNumbers: (term: string) => Promise<{ results: string[] }>
  getFilteredDocuments: (
    filters: []
  ) => Promise<{
    csr_inbox_documents: {}[]
    counts: {}
    internal_statuses: {}[]
  }>
  searchClinicalFacilities: (
    term: string
  ) => Promise<{ results: { id: string; text: string }[] }>
  getAssignees: (term: string) => Promise<{ id: string; name: string }[]>
}

export const ApiContext = React.createContext<ApiContextInterface>(undefined)
