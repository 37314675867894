// @team @facilitators
import React, { useRef, ChangeEventHandler } from "react"
import classNames from "classnames"
import TextInput from "components/TextInput"
import { DATE_MASK } from "components/MaskedInput"
import Icon from "components/Icon"
import { DATE_FORMAT, format, isDateValid } from "utilities/date"
import { IS_DATE_INPUT_SUPPORTED } from "utilities/detection"
import isTouchDevice from "is-touch-device"
import Flatpickr from "react-flatpickr"
import flatpickr from "flatpickr"
import { english } from "flatpickr/dist/l10n/default.js"
import { isTabOrEnter } from "utilities/browser/event"
import * as styles from "./index.module.scss"
import {
  DATEPICKER_DATE_FORMAT,
  DatePickerProps,
  formatDate,
  formatForDateInput,
  onPaste,
  WEEKDAYS,
} from "."

const DatePickerInput: React.FC<DatePickerProps> = ({
  name,
  id,
  value,
  disabled,
  className,
  onChange,
  hasDateInput = IS_DATE_INPUT_SUPPORTED && isTouchDevice(),
  minDate,
  maxDate,
  disabledDates = [],
  autoFocus,
  handleNavigationKey,
  width = "auto",
  inline,
}) => {
  const datepicker = useRef<flatpickr.Instance | null>(null)
  const hiddenInput = useRef<HTMLInputElement>(null)

  const onDateInputChange = (event) => {
    const date = event.target.value
    if (hiddenInput.current) {
      hiddenInput.current.value = formatDate(date)
    }
    hiddenInput.current?.dispatchEvent(new Event("blur", { bubbles: true }))
    hiddenInput.current?.dispatchEvent(new Event("change", { bubbles: true }))
    if (date && typeof onChange === "function") {
      onChange(({
        target: { value: format(date), name, id },
      } as unknown) as React.FormEvent)
    }
  }

  const dateInput = (
    <>
      <input
        type="date"
        disabled={disabled}
        autoFocus={autoFocus}
        className={classNames("form-control", className)}
        min={formatForDateInput(minDate)}
        max={formatForDateInput(maxDate)}
        value={formatForDateInput(value)}
        onChange={onDateInputChange}
        aria-disabled={disabled}
      />
      <input
        type="hidden"
        ref={hiddenInput}
        readOnly
        value={formatDate(value)}
        id={id}
        name={name}
      />
    </>
  )

  const flatPickerOnChange = (dates) => {
    const date = dates[0]
    if (date && typeof onChange === "function") {
      onChange(({
        target: { value: format(date), name, id },
      } as unknown) as React.FormEvent)
    }
  }

  const flatPickerInput = (
    <Flatpickr
      value={value}
      onCreate={(flatpickr) => {
        datepicker.current = flatpickr
      }}
      onDestroy={() => {
        datepicker.current?.destroy()
        datepicker.current = null
      }}
      onClose={() => {
        /*Note: It was necessary to include an onClose function here because without one, the library automatically blurs on close and overrides our custom focusing.
                                                      https://github.com/haoxins/react-flatpickr/blob/3a581d2b2e9f358e388ec7ff8596ba1a0442a99d/lib/index.js#L98-L104*/
      }}
      options={{
        inline,
        minDate,
        maxDate,
        disable: disabledDates.map(formatForDateInput),
        onChange: flatPickerOnChange,
        allowInput: true,
        clickOpens: false,
        dateFormat: DATEPICKER_DATE_FORMAT,
        locale: {
          ...english,
          weekdays: {
            shorthand: WEEKDAYS,
          },
        },
      }}
      render={(props, ref) => {
        return (
          <>
            <TextInput
              name={name}
              id={id}
              disabled={disabled}
              // TODO: reconcile prop types.
              onChange={onChange as ChangeEventHandler<HTMLInputElement>}
              className={classNames(
                "form-control",
                { [styles.formControlSm]: width === "sm" },
                className
              )}
              autoComplete="off"
              value={value}
              mask={DATE_MASK}
              onPaste={onPaste}
              autoFocus={autoFocus}
              placeholder={DATE_FORMAT}
              ref={ref}
              onKeyDown={(event) => {
                isTabOrEnter(event) &&
                  handleNavigationKey &&
                  handleNavigationKey()
              }}
            />
            {!inline && (
              <span
                className={classNames(
                  "datepicker input-group-append input-group-text",
                  styles.append,
                  {
                    pointer: !hasDateInput,
                  }
                )}
                onClick={() => datepicker.current?.toggle()}
              >
                <Icon prefix="far" type="calendar-alt" />
              </span>
            )}
          </>
        )
      }}
    />
  )

  return (
    <div
      className={classNames(styles.container, inline && styles.inline, {
        "input-group": !hasDateInput,
        [styles.inputGroupSm]: width === "sm",
      })}
    >
      {hasDateInput ? dateInput : flatPickerInput}
    </div>
  )
}

// a bit of a hack, but the old component relied on shouldComponentUpdate to avoid re-rendering every time a character
// was typed in the TextInput, so manually changing the date via the text input is broken without this. I think this is related
// to the issue described here: https://parachutehealth.atlassian.net/browse/WF-23718?focusedCommentId=118575
const shouldUpdate = (oldProps, nextProps) => {
  return !(
    oldProps.disabled !== nextProps.disabled ||
    nextProps.value === "" ||
    !!isDateValid(nextProps.value)
  )
}

export const DatePicker = React.memo(DatePickerInput, shouldUpdate)
