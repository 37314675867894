// extracted by mini-css-extract-plugin
var _1 = "app-javascript-applications-ClinicalFacilityDashboard-components-FocusTiles-FocusTiles-module__container--iJ7aA";
var _2 = "app-javascript-applications-ClinicalFacilityDashboard-components-FocusTiles-FocusTiles-module__count--wYh7c";
var _3 = "app-javascript-applications-ClinicalFacilityDashboard-components-FocusTiles-FocusTiles-module__inner-container--klPUb";
var _4 = "app-javascript-applications-ClinicalFacilityDashboard-components-FocusTiles-FocusTiles-module__outer-container--q31DN";
var _5 = "app-javascript-applications-ClinicalFacilityDashboard-components-FocusTiles-FocusTiles-module__selected--W0CKS";
var _6 = "app-javascript-applications-ClinicalFacilityDashboard-components-FocusTiles-FocusTiles-module__spinner--bjmpL";
var _7 = "app-javascript-applications-ClinicalFacilityDashboard-components-FocusTiles-FocusTiles-module__subtitle--KLZD_";
var _8 = "app-javascript-applications-ClinicalFacilityDashboard-components-FocusTiles-FocusTiles-module__title--TTkEn";
var _9 = "app-javascript-applications-ClinicalFacilityDashboard-components-FocusTiles-FocusTiles-module__zero-count--Azmcz";
export { _1 as "container", _2 as "count", _3 as "innerContainer", _4 as "outerContainer", _5 as "selected", _6 as "spinner", _7 as "subtitle", _8 as "title", _9 as "zeroCount" }
