import React, { useState } from "react"
import { Form, EmailField } from "components/form"
import { isEmailValid } from "utilities/email"
import ErrorText from "components/form/ErrorText"

type Props = {
  initialEmail: string
  onSubmit(email: string): Promise<void>
  submitText?: string
  setSubmittedEmail(string): void
}

const EmailForm = ({
  initialEmail,
  onSubmit,
  submitText,
  setSubmittedEmail,
}: Props) => {
  const [baseError, setBaseError] = useState()
  const validate = ({ email }) => {
    if (!isEmailValid(email)) {
      return { email: "Email is invalid" }
    }
  }

  const submit = ({ email }, { setSubmitting, setErrors }) => {
    setSubmitting(true)
    onSubmit(email)
      .then(() => {
        setSubmitting(false)
        setSubmittedEmail(email)
      })
      .catch(({ errors }) => {
        const { base, ...otherErrors } = errors
        setSubmitting(false)
        setBaseError(base)
        setErrors(otherErrors)
      })
  }

  const initialValues = { email: initialEmail || "" }
  return (
    <Form initialValues={initialValues} onSubmit={submit} validate={validate}>
      {({ isValid, isSubmitting }) => (
        <>
          <EmailField name="email" placeholder="Enter your email address" />
          {baseError && <ErrorText error={baseError} />}
          <button
            type="submit"
            className="btn btn-brand btn-block"
            disabled={!isValid || isSubmitting}
          >
            {submitText}
          </button>
        </>
      )}
    </Form>
  )
}

EmailForm.defaultProps = {
  submitText: "Sign Up",
}

export default EmailForm
