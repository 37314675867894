import { gql, TypedDocumentNode } from "@apollo/client"
import {
  GetInsuranceProductsQuery,
  GetInsuranceProductsQueryVariables,
} from "../../../graphql/__generated__/graphql"
import { PageInfoFieldsFragment } from "../../../graphql/fragments/fragments"

export const GetInsuranceProducts: TypedDocumentNode<
  GetInsuranceProductsQuery,
  GetInsuranceProductsQueryVariables
> = gql`
  ${PageInfoFieldsFragment}
  query GetInsuranceProducts(
    $sort: String
    $sortDirection: SortDirectionEnum
    $searchString: String
    $before: String
    $after: String
    $first: Int
    $last: Int
  ) {
    insuranceProducts(
      sort: $sort
      sortDirection: $sortDirection
      searchString: $searchString
      before: $before
      after: $after
      first: $first
      last: $last
    ) {
      totalCount
      pageInfo {
        ...PageInfoFields
      }
      nodes {
        id
        name
        externalId
        planType
        insuranceContract {
          id
          supplierId
          insuranceProductId
        }
        externalInsuranceMapping {
          externalSystemId
        }
      }
    }
  }
`
