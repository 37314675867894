import React from "react"
import Link from "components/Link"

const LearnMore = () => {
  return (
    <Link
      href="https://ph-public-user-documentation.s3.amazonaws.com/Patient+Order+Tracker.pdf"
      target="_blank"
      className="color-brand"
    >
      How it works
    </Link>
  )
}

export default LearnMore
