import React from "react"
import { signOut } from "../../../api"
import { Button } from "components/form"
import classNames from "classnames"
import * as styles from "./index.module.scss"

interface Props {
  text: string
}
const SignOutButton = ({ text }: Props) => {
  return (
    <Button
      ariaLabel={text}
      onClick={signOut}
      className={classNames(
        "btn-ghost",
        styles.signOutAsGuest,
        styles.btnBlockSm
      )}
    >
      {text}
    </Button>
  )
}

export default SignOutButton
