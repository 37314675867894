import React from "react"
import { Form, RadioButtons } from "components/form"
import { ConfigData } from "../sharedTypes"

const nameFor = ({ id }) => `attribute${id}`

type Props = {
  configData: ConfigData
  select(customAttributeConfigurationId: number): void
  filter(customAttributeOptionIds: number[]): Promise<void>
}

const InternalPatientPreferenceConfiguration = ({
  configData,
  select,
  filter,
}: Props) => {
  const {
    customAttributeOptionIds,
    customAttributesToAvailableOptions,
    matchingCustomAttributeConfigurationId,
  } = configData

  const initialValues = customAttributesToAvailableOptions.reduce(
    (acc, attribute) => ({
      ...acc,
      [nameFor(attribute)]: customAttributeOptionIds.find((id) =>
        attribute.options.some((o) => o.id === id)
      ),
    }),
    {}
  )

  const onSubmit = (values, { resetForm }) => {
    const customAttributeOptionIds = customAttributesToAvailableOptions
      .map((attr) => values[nameFor(attr)])
      .filter((a) => a)
    filter(customAttributeOptionIds).then(resetForm)
  }

  const selectConfiguration = () => {
    select(matchingCustomAttributeConfigurationId)
  }

  return (
    <div className="row">
      <div className="col-md-12">
        <Form onSubmit={onSubmit} initialValues={initialValues} submitOnChange>
          {customAttributesToAvailableOptions.map((customAttribute) => (
            <RadioButtons
              label={customAttribute.name}
              name={nameFor(customAttribute)}
              key={customAttribute.id}
              options={customAttribute.options.map((o) => ({
                label: o.description,
                value: o.id,
              }))}
            />
          ))}
        </Form>
        <div className="clearfix">
          <button
            type="button"
            onClick={selectConfiguration}
            className="btn btn-primary float-right"
            disabled={!matchingCustomAttributeConfigurationId}
          >
            Continue
          </button>
        </div>
      </div>
    </div>
  )
}

export default InternalPatientPreferenceConfiguration
