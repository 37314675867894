import { OfferingType } from "sharedTypes"

export const fulfillmentHeader = (offeringType) =>
  offeringType === OfferingType.Service
    ? "Service Coordination"
    : "Order Logistics"
export const addressLabel = (offeringType) =>
  offeringType === OfferingType.Service ? "Service Address" : "Address"
export const itemsLabel = (offeringType) =>
  offeringType === OfferingType.Service ? "Services" : "Products"
export const deliveryDateLabel = (offeringType) =>
  offeringType === OfferingType.Service
    ? "Requested Date of Evaluation"
    : "Requested Delivery Date"
export const deliveryNoteLabel = (offeringType) =>
  offeringType === OfferingType.Service
    ? "Service Note (Optional)"
    : "Delivery Note (Optional)"
export const deliveryNotePlaceholder = (offeringType) =>
  offeringType === OfferingType.Service
    ? "Add contact information and notes for coordinating service..."
    : "Add contact information and notes for coordinating delivery..."
export const deliveryAddressLabel = (offeringType) =>
  offeringType === OfferingType.Service ? "Service Address" : "Delivery Address"
export const deliverPrefix = (offeringType) =>
  offeringType === OfferingType.Service ? "Service at" : "Deliver to"
