import React from "react"
import { asForm, TextField, PhoneField, EmailField } from "components/form"
import RelationshipSelect from "./RelationshipSelect"
import PreferredLanguageSelect from "./PreferredLanguageSelect"

type Props = {
  isSubmitting: boolean
  onRemove(): void
}

const OtherContactForm = ({ isSubmitting, onRemove }: Props) => {
  return (
    <>
      <div className="row">
        <div className="col-lg-6">
          <TextField
            label="First Name"
            name="firstName"
            placeholder="Enter First Name"
          />
        </div>
        <div className="col-lg-6">
          <TextField
            label="Last Name"
            name="lastName"
            placeholder="Enter Last Name"
          />
        </div>
      </div>
      <RelationshipSelect />
      <div className="row">
        <div className="col-lg-6">
          <PhoneField label="Phone Number" name="phoneNumber" />
        </div>
        <div className="col-lg-6">
          <PreferredLanguageSelect />
        </div>
      </div>
      <div className="row">
        <div className="col-lg-6">
          <EmailField label="Email" name="email" />
        </div>
      </div>
      <div className="form-actions text-right">
        <a className="color-danger canopy-mie-8x" onClick={onRemove}>
          Remove
        </a>
        <button type="submit" className="btn btn-brand" disabled={isSubmitting}>
          Save
        </button>
      </div>
    </>
  )
}

export default asForm(OtherContactForm)
