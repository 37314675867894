import { get } from "../../../services/api"
import { Filters } from "../fetchOrders"

export type InitialData = {
  filters: Filters
  patientActivityEnabled: boolean
}
export const fetchInitialFilters = async (
  supplierOrganizationId: string
): Promise<InitialData> => {
  const url = "/web_api/documentation_orders/initial_dashboard_data"
  const res = await get(url, { supplierOrganizationId })
  return res.data
}
