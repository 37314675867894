import debounceFn from "awesome-debounce-promise"
import { get } from "services/api"
import * as urls from "./urls"

export const fetchSuppliers = debounceFn((term, dmeOrderId) => {
  return get(urls.supplierTransferSearchUrl(), { term, dmeOrderId })
    .then(({ data }) => {
      return data.results
    })
    .catch(({ response }) => Promise.reject(response.data))
}, 200)
