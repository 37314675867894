import { Grid } from "@material-ui/core"
import { useStyles } from "themes/theme"
import React from "react"

const styles = (props: { width: number }) => ({
  spacer: { width: props.width },
})
export const Spacer = (props: { width: number }) => {
  const classes = useStyles(styles({ width: props.width }))
  return <Grid item className={classes.spacer} />
}
