import { put } from "services/api"
import {
  dmeOrderQuestionAnswerUrl,
  confirmPrefilledAnswersUrl,
  startChartNoteUrl,
} from "applications/Workflow/urls"
import {
  SurveyQuestion,
  SurveyAnswerValue,
  SurveyAnswerType,
} from "sharedTypes"

export function answerQuestion(
  dmeOrderFaceToFaceId: string,
  question: SurveyQuestion,
  answerType: SurveyAnswerType,
  answerValue: SurveyAnswerValue
) {
  return put(dmeOrderQuestionAnswerUrl(question.questionId), {
    answerType,
    answerValue,
    dmeOrderFaceToFaceId,
    surveyId: question.surveyId,
  })
}

export function confirmPrefilledAnswers(faceToFaceId: string) {
  return put(confirmPrefilledAnswersUrl(faceToFaceId))
}

export const startChartNote = (faceToFaceId: string) => {
  return put(startChartNoteUrl(faceToFaceId)).then(({ data }) => data)
}
