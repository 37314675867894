import { del, post, put } from "services/api"
import {
  sendVerificationEmailPath,
  updateContactInformationPath,
  notificationPreferencesPath,
} from "./urls"

export function updateContactInformation(params: {
  firstName: string
  lastName: string
  timeZone: string
}): Promise<{}> {
  return put(updateContactInformationPath, { user: params }).then(
    ({ data }) => data
  )
}

export function updateSignatureRequestPreferences({
  preferences,
}: {
  preferences: {
    employmentId: string
    email?: boolean
    emr?: boolean
  }[]
}): Promise<{}> {
  return put(notificationPreferencesPath, {
    employments: { preferences },
  }).then(({ data }) => data)
}

export function removeReviewerEmployment(
  employmentId: string
): Promise<{ data: { redirectUrl: string } }> {
  return del(notificationPreferencesPath, { employmentId })
}

export function sendEmailVerificationEmail(
  reviewerId: string
): Promise<{ data: { email: string } }> {
  return post(sendVerificationEmailPath, { userId: reviewerId })
}
