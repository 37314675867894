// @ts-strict-ignore
import React, { useState } from "react"

import AddDoctorModal from "./AddDoctorModal"
import DoctorSuggestion from "./DoctorSuggestion"
import { Doctor } from "sharedTypes"
import Select from "components/form/Select"
import { Form } from "components/form"

type Props = {
  existingDoctors: Doctor[]
  searchDoctors(value: string): Promise<Doctor[]>
  selectDoctor(doctor: Doctor, params): Promise<void>
}

const DoctorSearch = ({
  existingDoctors,
  searchDoctors,
  selectDoctor,
}: Props) => {
  const [doctorToAdd, setDoctorToAdd] = useState(null)

  const existingDoctorIds = existingDoctors.map(({ doctorId }) => doctorId)
  const select = (doctorId, suggestion) => {
    if (existingDoctorIds.includes(doctorId)) {
      return
    }
    setDoctorToAdd(suggestion)
  }

  const doctorLabel = (doctor) =>
    `${doctor.firstName} ${doctor.lastName} - ${doctor.npi}`

  return (
    <div className="well well-expand">
      <Form>
        <Select
          id="affiliated-doctor-search"
          name="affiliatedDoctorSearch"
          placeholder="Type clinician's name or NPI"
          label="Search Clinicians"
          fetchOptions={(value) =>
            searchDoctors(value).then((doctors) =>
              doctors.map((doctor) => ({
                value: doctor.doctorId,
                label: doctorLabel(doctor),
                ...doctor,
              }))
            )
          }
          isSearchable
          onChange={select}
          closeMenuOnSelect
          minLength={3}
          clearOnSelect
          renderOption={(doctor, { query }) => (
            <DoctorSuggestion
              doctor={doctor}
              query={query}
              inNetwork={existingDoctorIds.includes(doctor.doctorId)}
            />
          )}
        />
      </Form>
      <AddDoctorModal
        doctor={doctorToAdd}
        addDoctor={selectDoctor}
        close={() => setDoctorToAdd(null)}
        show={!!doctorToAdd}
      />
    </div>
  )
}

export default DoctorSearch
