import React, { useState, useEffect } from "react"
import Icon from "components/Icon"
import { trackInvitationEvent } from "../utilities"

type Props = {
  requestResend(): void
  channel: string
}

const ResendPage = ({ requestResend, channel }: Props) => {
  const [requested, setRequested] = useState(false)

  const request = () => {
    requestResend()
    setRequested(true)
  }

  useEffect(() => {
    trackInvitationEvent("resend-page-viewed")
  }, [])

  return (
    <>
      <div className="onboarding-intro">
        <Icon type="lock" className="font-xl" />
        <p className="color-danger bold">
          Oh no! This link has expired for security purposes.
        </p>
        {requested ? (
          <p>A new {channel} invitation for this order has been sent.</p>
        ) : (
          <>
            <p>
              To resend a link and security code via {channel}, click the button
              below.
            </p>
            <button type="button" className="btn btn-brand" onClick={request}>
              Re-send Access Info
            </button>
          </>
        )}
      </div>
    </>
  )
}

export default ResendPage
