import React from "react"
import DynamicFormField from "../DynamicFormField"
import {
  FieldSet,
  StaticFieldDefinition,
} from "../../../types/form/sharedTypes"

export type FormSegmentProps = {
  bottomMarginClass?: string
  children: FieldSet[]
}

/**
 * A component to visually group a list of field definitions for a Formik-based form.
 * It can be entirely one-dimensional (elements output in a vertical list), but also allows
 * for one level of grouping that will be evenly spread out across one "row" of the form.
 *
 */
const FormSegment = ({
  children,
  bottomMarginClass = "canopy-mbe-8x",
}: FormSegmentProps): React.JSX.Element => {
  const renderField = (
    definition: StaticFieldDefinition,
    classes?: string
  ): React.JSX.Element => {
    return (
      <div
        key={definition.name}
        className={`${bottomMarginClass} ${classes || ""}`}
      >
        <DynamicFormField definition={definition} />
      </div>
    )
  }

  const renderFieldSet = (fieldSet: FieldSet, index: number): JSX.Element => {
    if (Array.isArray(fieldSet)) {
      return (
        <div
          key={`field-group-${index}`}
          className="d-flex flex-row justify-content-between"
        >
          {fieldSet.map((fs, index) => {
            const className = `${
              fieldSet.length - index > 1 ? "canopy-mie-8x" : ""
            } flex-grow-1`

            return renderField(fs, className)
          })}
        </div>
      )
    } else {
      return renderField(fieldSet)
    }
  }

  return (
    <>
      {children.map((question, index) => {
        return renderFieldSet(question, index)
      })}
    </>
  )
}

export default FormSegment
