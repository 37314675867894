// @ts-strict-ignore
import React, { ChangeEvent, useEffect, useState } from "react"
import InsuranceMemberNumberInput from "./InsuranceMemberNumberInput"
import InsuranceCarrierSelect from "./InsuranceCarrierSelect"
import { InsuranceErrorType } from "sharedTypes"
import {
  CarrierAutoUpdateResponse,
  CarrierSearchSuggestion,
  InsuranceRank,
  InsuranceVerificationInterfaceStatus,
  Prediction,
  UpdateParams,
} from "../sharedTypes"
import { handleError } from "utilities/error"
import Link from "components/Link"
import * as styles from "./insurance-selection.module.scss"
import { EventCategory, trackEvent } from "utilities/tracking"
import { fieldSeparator } from "./../index.module.scss"

type Props = {
  title: string
  rank: InsuranceRank
  initialMemberNumber?: string
  initialCarrierName?: string
  initialCarrierId?: string
  initialStatus: InsuranceVerificationInterfaceStatus
  onPolicyChange?({ carrierName, memberNumber }): void
  carrierSearch(carrierName: string): Promise<CarrierSearchSuggestion[]>
  predict(memberNumber: string, rank: InsuranceRank): Promise<Prediction[]>
  update(params: UpdateParams): Promise<void>
  verifyInsurance(rank: InsuranceRank): Promise<boolean>
  onInsuranceRemove(rank: InsuranceRank): void
  removeRankPolicy(rank: InsuranceRank): void
  autoUpdateCarrier(rank: InsuranceRank): Promise<CarrierAutoUpdateResponse>
}

const InsuranceSelection = (props: Props) => {
  const {
    title,
    rank,
    initialMemberNumber,
    initialCarrierName,
    initialCarrierId,
    initialStatus,
    onPolicyChange,
    carrierSearch,
    predict,
    update,
    verifyInsurance,
    onInsuranceRemove,
    removeRankPolicy,
    autoUpdateCarrier,
  } = props

  const [carrier, setCarrier] = useState<CarrierSearchSuggestion>({
    name: initialCarrierName || "",
    externalId: initialCarrierId || "",
  })
  const [memberNumber, setMemberNumber] = useState(initialMemberNumber || "")
  const [status, setStatus] = useState(initialStatus)
  const [showCarrierInput, setShowCarrierInput] = useState(!!memberNumber)
  const [userSelectedCarrier, setUserSelectedCarrier] = useState(false)

  const errorFromReviewPage = (
    errorType: InsuranceErrorType,
    queryParams: URLSearchParams,
    value: string
  ) => {
    return (
      rank === queryParams.get("policyRank") &&
      errorType === queryParams.get("errorType") &&
      !value
    )
  }
  const queryParams = new URLSearchParams(location.search)
  const carrierSelectErrorFromReviewPage = errorFromReviewPage(
    "carrier",
    queryParams,
    carrier.name
  )

  const memberInputErrorFromReviewPage = errorFromReviewPage(
    "policy",
    queryParams,
    memberNumber
  )

  const [focusCarrierInput, setFocusCarrierInput] = useState(
    carrierSelectErrorFromReviewPage
  )

  useEffect(() => {
    let canPoll = true
    const verifyAndUpdateInsurance = async () => {
      try {
        const active = await verifyInsurance(rank)
        if (!canPoll) return

        if (active) {
          const response = await autoUpdateCarrier(rank)
          if (response.updated) {
            setCarrier(response.carrier)
            setStatus(InsuranceVerificationInterfaceStatus.VerifiedAndUpdated)
            trackEvent(
              EventCategory.PaymentsPage,
              "plan-family-name-updated",
              response.carrier.externalId
            )
          } else {
            setStatus(InsuranceVerificationInterfaceStatus.Verified)
          }
        } else {
          setStatus(
            active
              ? InsuranceVerificationInterfaceStatus.Verified
              : InsuranceVerificationInterfaceStatus.Unverified
          )
        }
      } catch {
        setStatus(InsuranceVerificationInterfaceStatus.Error)
      }
    }

    if (canPoll && status === InsuranceVerificationInterfaceStatus.Verifying) {
      void verifyAndUpdateInsurance()
    }

    return () => {
      canPoll = false
    }
  }, [rank, status, verifyInsurance, autoUpdateCarrier])

  useEffect(() => {
    const carrierName = carrier.name
    let canRunOnPolicyChange = true
    if (canRunOnPolicyChange) {
      onPolicyChange && onPolicyChange({ carrierName, memberNumber })
    }
    return () => {
      canRunOnPolicyChange = false
    }
  }, [onPolicyChange, carrier, memberNumber])

  const updatePolicy = ({ memberNumber, insuranceProductId, carrierName }) => {
    update({ rank, memberNumber, insuranceProductId, carrierName })
      .then(() => setStatus(InsuranceVerificationInterfaceStatus.Verifying))
      .catch(() => setStatus(InsuranceVerificationInterfaceStatus.Error))
  }

  const handleMemberIdChange = (event: ChangeEvent<HTMLInputElement>) => {
    const newMemberNumber = event.target.value
    setMemberNumber(newMemberNumber)
    setStatus(InsuranceVerificationInterfaceStatus.Dirty)
  }

  const handleMemberIdSave = () => {
    if (!userSelectedCarrier) {
      setCarrier({ name: "", externalId: "" })
      setShowCarrierInput(false)
    }

    if (userSelectedCarrier && memberNumber) {
      setShowCarrierInput(true)
      const carrierName = carrier.name
      const insuranceProductId = carrier.externalId
      updatePolicy({ carrierName, memberNumber, insuranceProductId })
    } else if (memberNumber) {
      setShowCarrierInput(true)
      predictInsuranceAndUpdatePolicy(memberNumber)
    } else {
      removeRankPolicy(rank)
      setShowCarrierInput(false)
    }
  }

  const predictInsuranceAndUpdatePolicy = (memberNumber) => {
    const PROBABILITY_THRESHOLD = 0.8
    setStatus(InsuranceVerificationInterfaceStatus.Predicting)

    predict(memberNumber, rank)
      .then((predictions) => {
        const prediction =
          predictions[0]?.probability >= PROBABILITY_THRESHOLD
            ? predictions[0]
            : null
        if (prediction?.payerName) {
          const {
            payerName: carrierName,
            payerId: insuranceProductId,
          } = prediction
          setCarrier({ name: carrierName, externalId: insuranceProductId })
          updatePolicy({ carrierName, memberNumber, insuranceProductId })
        } else {
          setStatus(InsuranceVerificationInterfaceStatus.Dirty)
          setFocusCarrierInput(true)
          update({
            rank,
            memberNumber,
            insuranceProductId: null,
            carrierName: null,
          })
            .then(() => {})
            .catch(handleError)
        }
      })
      .catch(() => {
        setStatus(InsuranceVerificationInterfaceStatus.Dirty)
        setFocusCarrierInput(true)
      })
  }

  const fetchCarriers = (carrierName) => {
    return carrierSearch(carrierName).then(
      (suggestions: CarrierSearchSuggestion[]) =>
        suggestions.map(({ externalId, name }) => ({
          value: externalId,
          label: name,
        }))
    )
  }

  const carrierSelect = (suggestion: CarrierSearchSuggestion) => {
    if (memberNumber) {
      setStatus(InsuranceVerificationInterfaceStatus.Dirty)
      const carrierName = suggestion.name
      const insuranceProductId = suggestion.externalId
      setCarrier({ name: carrierName, externalId: insuranceProductId })
      updatePolicy({ carrierName, memberNumber, insuranceProductId })
      setUserSelectedCarrier(true)
    }
  }

  const isPrimary = rank === "primary"

  const isFormDisabled = [
    InsuranceVerificationInterfaceStatus.Verifying,
    InsuranceVerificationInterfaceStatus.Predicting,
  ].includes(status)

  const onRemoveCarrier = () => {
    if (memberNumber) {
      update({
        rank,
        memberNumber,
        insuranceProductId: null,
        carrierName: null,
      }).catch(handleError)
    } else {
      setShowCarrierInput(false)
      removeRankPolicy(rank)
    }
    setStatus(InsuranceVerificationInterfaceStatus.Blank)
    setUserSelectedCarrier(false)
    setCarrier({
      name: "",
      externalId: "",
    })
    trackEvent(EventCategory.PaymentsPage, `clear-${rank}-carrier`)
  }

  const handleMemberIdClear = () => {
    setMemberNumber("")
    if (!userSelectedCarrier) {
      setCarrier({ name: "", externalId: "" })
      setShowCarrierInput(false)
      setShowCarrierInput(false)
      removeRankPolicy(rank)
    }
    trackEvent(EventCategory.PaymentsPage, `clear-${rank}-memberID`)
  }

  const renderRemoveInsurance = (rank: InsuranceRank) => (
    <div className={styles.removeLinkContainer}>
      <Link
        className="link"
        data-testid={`remove ${rank} insurance`}
        onClick={() => {
          onInsuranceRemove(rank)
        }}
      >
        Remove
      </Link>
    </div>
  )

  return (
    <div data-testid={`InsuranceSelection-${rank}`}>
      <div className="d-relative">
        <h3> {`${title} Insurance`} </h3>
        {!isPrimary && renderRemoveInsurance(rank)}
      </div>
      <div className={fieldSeparator}>
        <InsuranceMemberNumberInput
          name={`${rank}-insurance-member-number`}
          title="Member ID"
          value={memberNumber}
          onMemberIdChange={handleMemberIdChange}
          onSave={handleMemberIdSave}
          onClear={handleMemberIdClear}
          disabled={isFormDisabled}
          errorFromReviewPage={memberInputErrorFromReviewPage}
        />
      </div>
      {showCarrierInput && (
        <div className={fieldSeparator}>
          <InsuranceCarrierSelect
            rank={rank}
            title="Carrier"
            carrier={carrier}
            onBlur={() => {
              setStatus(InsuranceVerificationInterfaceStatus.Blank)
            }}
            onRemove={onRemoveCarrier}
            fetch={fetchCarriers}
            onCarrierSelect={carrierSelect}
            status={status}
            focus={focusCarrierInput}
            errorFromReviewPage={carrierSelectErrorFromReviewPage}
          />
        </div>
      )}
    </div>
  )
}

export default InsuranceSelection
