import React from "react"
import classNames from "classnames"
import NavigationIcon from "../NavigationIcon"
import { Link } from "react-router-dom"
import { Page } from "sharedTypes"
import * as styles from "./index.module.scss"
import { productsPath } from "applications/Workflow/routes"

type Props = {
  pages: Page[]
  removeBottomBorder?: boolean
}

function Pages({ pages, removeBottomBorder }: Props) {
  if (!pages.length) {
    return <div />
  }

  const linkStyles = removeBottomBorder
    ? [styles.link, "navigation-link"]
    : [styles.link, styles.withBottomBorder, "navigation-link"]
  const stepIconStyles = removeBottomBorder
    ? "canopy-mis-4x canopy-mie-4x"
    : "canopy-mie-4x"

  return (
    <ul className={styles.pages} data-testid="pages">
      {pages.map((page) => (
        <li
          className={classNames(linkStyles)}
          key={page.id}
          data-analytics-context="Workflow Navigation"
          data-analytics="nav-menu-clicked"
        >
          <Link
            to={{
              pathname: `/${page.id}`,
              state: {
                autoOpenCartTab: `/${page.id}`.startsWith(productsPath()),
              },
            }}
            className={classNames("truncate", { [styles.active]: page.active })}
            data-active={page.active}
          >
            <NavigationIcon className={stepIconStyles} checked={page.checked} />
            {page.title}
          </Link>
        </li>
      ))}
    </ul>
  )
}

export default Pages
