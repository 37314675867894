import React from "react"
import { highlight } from "utilities/select"
import { Doctor } from "sharedTypes"
import Suggestion, {
  SuggestionIndicator,
} from "components/form/Select/Suggestion"

type Props = {
  inNetwork: boolean
  doctor: Doctor
  query: string
  isHighlighted?: boolean
}

const DoctorSuggestion = ({
  doctor,
  query,
  inNetwork,
  isHighlighted,
}: Props) => {
  const address = [doctor.city, doctor.state].filter(Boolean).join(", ")
  return (
    <Suggestion
      className="d-flex"
      key={doctor.doctorId}
      isHighlighted={isHighlighted}
    >
      <SuggestionIndicator selected={inNetwork} />
      <div style={{ width: "100%" }}>
        {highlight(
          `${doctor.firstName} ${doctor.lastName} - ${doctor.npi}`,
          query
        )}
        {address && (
          <div className="float-right color-dark-gray">{address}</div>
        )}
        {!doctor.pecosCertified && (
          <>
            <br />
            <small>Cannot sign for Medicare orders</small>
          </>
        )}
        {inNetwork && (
          <>
            <br />
            <small>Already added to network</small>
          </>
        )}
      </div>
    </Suggestion>
  )
}

export default DoctorSuggestion
