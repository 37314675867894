import { updateInsurance, UpdateInsuranceParams } from "../api"
import { InsuranceRank } from "../../../../sharedTypes"
import {
  useIsMutating,
  useMutation,
  UseMutationResult,
} from "@tanstack/react-query"
import { useCsrInboxState } from "../../../../csrInboxStateContext"
import { handleError } from "utilities/error"

const mutationKey = (rank: InsuranceRank) => ["updateInsurance", rank]

export const useInsuranceMutation = (
  fieldName: keyof UpdateInsuranceParams,
  rank: InsuranceRank
): [UseMutationResult<void, unknown, string, unknown>, number] => {
  const csrInboxState = useCsrInboxState()
  const rankMutation: UseMutationResult<
    void,
    unknown,
    string,
    unknown
  > = useMutation({
    mutationKey: mutationKey(rank),
    mutationFn: async (fieldValue: string) => {
      return updateInsurance({
        supplierOrganizationId: csrInboxState.supplierOrganization.id,
        csrInboxStateId: csrInboxState.id,
        rank,
        params: { [fieldName]: fieldValue },
      }).catch(handleError)
    },
  })
  const rankMutationCount: number = useIsMutating({
    mutationKey: mutationKey(rank),
  })
  return [rankMutation, rankMutationCount]
}
