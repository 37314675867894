import { get } from "services/api"
import { searchSettingsUrl, searchSuppliersUrl } from "./urls"
import {
  SearchSettings,
  SuppliersRequest,
  SuppliersResponse,
} from "./sharedTypes"

export const searchSuppliers = (
  suppliersRequest: SuppliersRequest
): Promise<SuppliersResponse> =>
  get(searchSuppliersUrl(), suppliersRequest).then(({ data }) => data)

export const loadSearchSettings = (): Promise<SearchSettings> =>
  get(searchSettingsUrl()).then(({ data }) => data)
