import React from "react"
import classNames from "classnames"

type Position = "top"

type Props = {
  active: boolean
  showSpinner: boolean
  className?: string
  children?: React.ReactNode
  position?: Position
}
const Overlay = ({
  active,
  showSpinner,
  children,
  className,
  position,
}: Props) => {
  const status = active ? "active" : "inactive"
  return (
    <div
      data-testid={`overlay-${status}`}
      className={classNames(
        "overlay-container",
        `overlay-${status}`,
        className,
        { standalone: !children }
      )}
    >
      <div className={classNames({ "overlay-children": active })}>
        {children}
      </div>
      {showSpinner && (
        <div className={classNames("spinner-overlay", { active })}>
          <div
            className={classNames("spinner-icon", {
              "spinner-icon-top": position === "top",
            })}
          >
            <div className="fas fa-spinner fa-spin color-primary" />
          </div>
        </div>
      )}
    </div>
  )
}

export default Overlay
