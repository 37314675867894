import { employerPrefix } from "utilities/url"

export const messageTemplatesUrl = () => `${employerPrefix()}/message_templates`

export const patientPortalMessageTemplatesUrl = () =>
  `${employerPrefix()}/patient_portal_message_templates`

export const patientPortalMessageTemplateUrl = (id) =>
  `${employerPrefix()}/patient_portal_message_templates/${id}`

export const supplierCommentTemplatesUrl = () =>
  `${employerPrefix()}/supplier_comment_templates.json`

export const supplierCommentTemplateUrl = (id) =>
  `${employerPrefix()}/supplier_comment_templates/${id}.json`
