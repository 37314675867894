import { gql, TypedDocumentNode } from "@apollo/client"
import {
  GetIncomingOrdersClinicalFacilitiesQuery,
  GetIncomingOrdersClinicalFacilitiesQueryVariables,
  GetIncomingOrdersDoctorsQuery,
  GetIncomingOrdersDoctorsQueryVariables,
  GetIncomingOrdersFollowersQuery,
  GetIncomingOrdersFollowersQueryVariables,
  GetIncomingOrdersProductSubcategoriesQuery,
  GetIncomingOrdersProductSubcategoriesQueryVariables,
  GetIncomingOrdersQuery,
  GetIncomingOrdersQueryVariables,
  GetIncomingOrdersSuppliersQuery,
  GetIncomingOrdersSuppliersQueryVariables,
} from "graphql/__generated__/graphql"
import { PageInfoFieldsFragment } from "graphql/fragments/fragments"

export const GetIncomingOrders: TypedDocumentNode<
  GetIncomingOrdersQuery,
  GetIncomingOrdersQueryVariables
> = gql`
  ${PageInfoFieldsFragment}
  query GetIncomingOrders(
    $before: String
    $after: String
    $first: Int
    $last: Int
    $sort: IncomingOrdersSortOptionEnum
    $sortDirection: SortDirectionEnum
    $clinicalFacilityIds: [ID]
    $productSubcategoryIds: [ID]
    $followerIds: [ID]
    $doctorIds: [ID]
    $supplierIds: [ID]
    $patientSearchTerm: String
    $deliveryDateStartAt: String
    $deliveryDateEndAt: String
  ) {
    incomingOrders(
      before: $before
      after: $after
      first: $first
      last: $last
      sort: $sort
      sortDirection: $sortDirection
      clinicalFacilityIds: $clinicalFacilityIds
      productSubcategoryIds: $productSubcategoryIds
      followerIds: $followerIds
      doctorIds: $doctorIds
      supplierIds: $supplierIds
      patientSearchTerm: $patientSearchTerm
      deliveryDateStartAt: $deliveryDateStartAt
      deliveryDateEndAt: $deliveryDateEndAt
    ) {
      totalCount
      pageSize
      pageInfo {
        ...PageInfoFields
      }
      nodes {
        id
        clinicalFacility {
          id
          name
        }
        orderingDoctor {
          name
        }
        patient {
          dateOfBirth
          fullName
        }
        deliveryDate
        supplier {
          name
        }
        productSubcategories {
          name
        }
        submissionWarnings {
          key
        }
      }
      unfilteredCount
    }
  }
`

export const GetClinicalFacilities: TypedDocumentNode<
  GetIncomingOrdersClinicalFacilitiesQuery,
  GetIncomingOrdersClinicalFacilitiesQueryVariables
> = gql`
  query GetIncomingOrdersClinicalFacilities($searchTerm: String) {
    incomingOrders {
      clinicalFacilities(searchTerm: $searchTerm) {
        id
        name
      }
    }
  }
`

export const GetIncomingOrdersFollowers: TypedDocumentNode<
  GetIncomingOrdersFollowersQuery,
  GetIncomingOrdersFollowersQueryVariables
> = gql`
  query GetIncomingOrdersFollowers($searchTerm: String) {
    incomingOrders {
      followers(searchTerm: $searchTerm) {
        id
        name
      }
    }
  }
`

export const GetDoctors: TypedDocumentNode<
  GetIncomingOrdersDoctorsQuery,
  GetIncomingOrdersDoctorsQueryVariables
> = gql`
  query GetIncomingOrdersDoctors($searchTerm: String) {
    incomingOrders {
      doctors(searchTerm: $searchTerm) {
        id
        name
      }
    }
  }
`

export const GetSuppliers: TypedDocumentNode<
  GetIncomingOrdersSuppliersQuery,
  GetIncomingOrdersSuppliersQueryVariables
> = gql`
  query GetIncomingOrdersSuppliers($searchTerm: String) {
    incomingOrders {
      suppliers(searchTerm: $searchTerm) {
        id
        name
      }
    }
  }
`

export const GetProductSubcategories: TypedDocumentNode<
  GetIncomingOrdersProductSubcategoriesQuery,
  GetIncomingOrdersProductSubcategoriesQueryVariables
> = gql`
  query GetIncomingOrdersProductSubcategories($searchTerm: String) {
    incomingOrders {
      productSubcategories(searchTerm: $searchTerm) {
        id
        name
      }
    }
  }
`
