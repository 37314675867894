import React, { useEffect, useState } from "react"
import * as styles from "../tab-panels.module.scss"
import {
  COMPLETE_STATE,
  IN_PROGRESS_STATE,
  useAsyncDownload,
} from "utilities/asyncDownload"
import { post } from "services/api"
import Icon from "components/Icon"
import Button from "components/form/Button"
import IndeterminateLoader from "../../../../components/IndeterminateLoader"

export const DownloadReportButton = (props: {
  supplierOrganizationId: string
  searchText?: string
  createdAtStartAt?: string
  createdAtEndAt?: string
  clinicians?: string[]
  suppliers?: string[]
  signatureStatuses?: string[]
  lastNotificationMethod?: string[]
  patientActivity?: string[]
}) => {
  let filter_results = false
  const { fetchDownload, downloadState } = useAsyncDownload({
    createDownload: () =>
      post(
        `/u/r/${props.supplierOrganizationId}/documentation_orders/async_downloads.json`,
        {
          filter: filter_results
            ? {
                searchText: props.searchText,
                createdAtStartAt: props.createdAtStartAt,
                createdAtEndAt: props.createdAtEndAt,
                clinicians: props.clinicians,
                suppliers: props.suppliers,
                signatureStatuses: props.signatureStatuses,
                lastNotificationMethod: props.lastNotificationMethod,
                patientActivity: props.patientActivity,
              }
            : null,
        }
      ).then((d) => d.data),
    key: "download_documentation_orders_csv",
  })

  const [content, setContent] = useState(
    downloadState === "inProgress" ? "Preparing Download" : "Download CSV"
  )
  const [open, setOpen] = useState(false)
  const [disabled, setDisabled] = useState(false)
  const toggleOpen = () => {
    setOpen(!open)
  }

  const handleDownloadAll = async () => {
    setOpen(false)
    filter_results = false
    await fetchDownload()
  }

  const handleDownloadFiltered = async () => {
    setOpen(false)
    filter_results = true
    await fetchDownload()
  }

  useEffect(() => {
    setContent(
      {
        notStarted: "Download CSV",
        inProgress: "Preparing Download",
        complete: "CSV Downloaded",
        failed: "Encountered Error",
      }[downloadState]
    )
    setDisabled(
      downloadState === IN_PROGRESS_STATE || downloadState === COMPLETE_STATE
    )
  }, [downloadState])

  return (
    <div className="dropdown float-right">
      <Button
        className={` btn-ghost ${styles.action}`}
        onClick={toggleOpen}
        disabled={disabled}
      >
        {downloadState === IN_PROGRESS_STATE ? (
          <IndeterminateLoader />
        ) : (
          <Icon
            style={{
              display: "flex",
              alignItems: "center",
              height: "100%",
            }}
            type="download"
          />
        )}
        <span style={{ paddingRight: "8px" }} />
        {content}
      </Button>
      {open && (
        <ul className="dropdown-menu d-block w-100-p">
          <li
            className="dropdown-item canopy-pis-8x canopy-py-2x"
            onClick={() => handleDownloadAll()}
          >
            Download All
          </li>
          <li
            className="dropdown-item canopy-pis-8x canopy-py-2x"
            onClick={handleDownloadFiltered}
          >
            Download Filtered
          </li>
        </ul>
      )}
    </div>
  )
}
