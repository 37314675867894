export function updatePasswordUrl(userId) {
  return `/facility_sign_up/passwords/${userId}.json`
}

export function updateUserUrl(userId) {
  return `/facility_sign_up/supplier_info_center/account_completion/users/${userId}.json`
}

export function resendInvitationUrl(userId: string) {
  return `/facility_sign_up/supplier_info_center/account_completion/invitations/${userId}/resend_invitation.json`
}

export function joinFacilityUrl() {
  return "/facility_sign_up/facilities/edit"
}

export function loginUrl() {
  return "/users/log_in"
}
