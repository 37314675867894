import { get, post } from "services/api"
import { createRequestUrl, searchSuppliersUrl } from "./urls"
import {
  Supplier,
  SupplierFormFields,
  CreateRequestResponse,
} from "./sharedTypes"

export const supplierSearch = (term: string): Promise<Supplier[]> =>
  get(searchSuppliersUrl(), { term }).then(({ data }) => data)

export const createRequest = (
  values: SupplierFormFields
): Promise<CreateRequestResponse> =>
  post(createRequestUrl(), { supplierRequest: values }).then(({ data }) => data)
