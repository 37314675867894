import React from "react"
import InternalDoctorDashboard from "./components/InternalDoctorDashboard"
import { Doctor, DoctorDashboardDmeOrder } from "./sharedTypes"
import { EmrInstance, FeatureFlagInput } from "sharedTypes"
import {
  addFacility,
  approveOrders,
  removeFacility,
  searchFacilities,
} from "./api"
import { SigningSignatureRequest } from "./components/OrdersList/SignatureRequestCard"
import { ThemeProvider } from "@material-ui/core/styles"
import { materialTheme } from "../../themes/theme"
import { FeatureFlagProvider } from "components/FeatureFlagContext"

export type ReviewerDetails = { name: string; email: string }

type Props = {
  orders: DoctorDashboardDmeOrder[]
  currentDoctor: Doctor
  showPrompt: boolean
  pendingVerifications: string[]
  emrInstance?: EmrInstance
  signingSignatureRequests: SigningSignatureRequest[]
  featureFlags: FeatureFlagInput
  reviewers: ReviewerDetails[]
}

const DoctorDashboard = ({
  currentDoctor,
  orders,
  showPrompt,
  pendingVerifications,
  emrInstance,
  signingSignatureRequests,
  featureFlags,
  reviewers,
}: Props) => {
  return (
    <ThemeProvider theme={materialTheme}>
      <FeatureFlagProvider flags={featureFlags}>
        <InternalDoctorDashboard
          initialShowPrompt={showPrompt}
          pendingVerifications={pendingVerifications}
          initialOrders={orders}
          approveOrders={approveOrders}
          addFacility={addFacility}
          removeFacility={removeFacility}
          currentDoctor={currentDoctor}
          searchFacilities={searchFacilities}
          emrInstance={emrInstance}
          signingSignatureRequests={signingSignatureRequests || []}
          initialReviewers={reviewers}
        />
      </FeatureFlagProvider>
    </ThemeProvider>
  )
}

export default DoctorDashboard
