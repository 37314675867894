const DEFAULT_TIMEOUT = 500

interface CallbackWrapper {
  (...args): Promise<void>
  next: boolean
}

const throttledPromise = (callback, timeout = DEFAULT_TIMEOUT) => {
  let callbackPromise
  let latestCallbackArgs

  const callbackWrapper = function (...args) {
    if (callbackPromise) {
      latestCallbackArgs = args
      callbackWrapper.next = true
      return
    }

    callbackWrapper.next = false
    callbackPromise = callback(...args)
    const timeoutPromise = new Promise((resolve, _) =>
      setTimeout(resolve, timeout)
    )

    const callWithNextArgs = () => {
      callbackPromise = null
      if (latestCallbackArgs) {
        callbackWrapper(...latestCallbackArgs)
        latestCallbackArgs = null
      }
    }

    return Promise.all([callbackPromise, timeoutPromise])
      .then(callWithNextArgs)
      .catch(callWithNextArgs)
  } as CallbackWrapper

  return callbackWrapper
}

export default throttledPromise
