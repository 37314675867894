import React from "react"
import { numberToCurrency } from "utilities/price"
import { Nullable } from "sharedTypes"

interface Props {
  unitPrice: Nullable<number>
}

const FormularyUnitPrice: React.FC<Props> = ({ unitPrice }) => {
  const formattedUnitPrice =
    unitPrice || unitPrice === 0 ? numberToCurrency(unitPrice) : "unknown"

  return (
    <div className="row canopy-mbe-4x">
      <div className="col">
        <span className="color-dark-gray">Price per unit:&nbsp;</span>
        <span className="color-black">{formattedUnitPrice}</span>
      </div>
    </div>
  )
}

export default FormularyUnitPrice
