import { employerPrefix } from "utilities/url"
export const convertToFullUserUrl = (userId) =>
  `${employerPrefix()}/users/${userId}/convert_to_full_user`

export const removeUserFromFacilityUrl = (userId) =>
  `${employerPrefix()}/users/${userId}/`

export const sendWelcomeEmailUrl = (userId) =>
  `${employerPrefix()}/users/${userId}/send_welcome_email`

export const sendResetPasswordUrl = (userId) =>
  `${employerPrefix()}/users/${userId}/send_reset_password_email`
