import { Route, Switch, useHistory } from "react-router-dom"
import React, { useContext } from "react"
import { NoticeContext, withNoticeProvider } from "../../contexts/NoticeContext"
import { withBrowserRouter } from "../../../../routers/BrowserRouter"
import { withMaterialTheme } from "../../../../themes/theme"
import SuppliersPage from "./SuppliersPage"
import SupplierDetailsPage from "./SupplierDetailsPage"
import {
  Policies,
  PoliciesProvider,
} from "applications/Cms/contexts/PoliciesContext"

type Props = {
  policies: Policies
}

const SuppliersWorkflow: React.FC<Props> = (
  props: Props
): React.JSX.Element => {
  const history = useHistory()
  const { clearNotice } = useContext(NoticeContext)

  React.useEffect(() => {
    clearNotice()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history.location.pathname])

  return (
    <PoliciesProvider policies={props.policies}>
      <Switch>
        <Route path="/cms/catalog/suppliers" exact>
          <SuppliersPage />
        </Route>
        <Route path="/cms/catalog/suppliers/:supplierId" exact>
          <SupplierDetailsPage />
        </Route>
      </Switch>
    </PoliciesProvider>
  )
}

export default withBrowserRouter(
  withNoticeProvider(withMaterialTheme(SuppliersWorkflow)),
  {}
)
