import React from "react"
import createOrder from "create-order.png"
import MarketingContent from "./MarketingContent"

const CreateOrder: React.FC = () => (
  <MarketingContent
    title="Create new orders"
    description="Step-by-step ordering in as fast as 3 min from suppliers of your choice with no re-faxing."
    imageSrc={createOrder}
    alt="Product Catalog and steps to complete an order"
  />
)

export default CreateOrder
