import Sha256 from "sha.js/sha256"

function getDomain(email) {
  return email.split("@")[1]
}

export const isSamlDomain: (
  email: string,
  samlHashedDomains: string[]
) => boolean = (email, samlHashedDomains) => {
  const domain = getDomain(email)
  if (!domain) {
    return false
  }
  const hash = new Sha256().update(domain.toLowerCase()).digest("hex")
  return samlHashedDomains.indexOf(hash) > -1
}
