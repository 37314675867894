import React, { ReactElement } from "react"
import { CanopyNotice } from "@parachutehealth/canopy-notice"
import { put } from "../../services/api"
import { userActivationsUrl } from "../ClinicalFacilityDashboard/components/WelcomeModal/urls"

type Props = {
  scheduleLiveDemoDismissed: Boolean
}

export function markScheduleLiveDemoDismissed() {
  return put(userActivationsUrl(), { scheduleLiveDemoDismissed: true })
}

const ScheduleLiveDemoPrompt = ({
  scheduleLiveDemoDismissed,
}: Props): ReactElement | null => {
  if (scheduleLiveDemoDismissed) {
    return null
  }
  return (
    <CanopyNotice
      actions={[
        {
          href: "https://www.parachutehealth.com/demo",
          text: "Schedule a demo",
          target: "_blank",
        },
      ]}
      closable
      message="Meet with our team for 20 mins or less to walk through an example order and learn some tips and tricks."
      title="Want tips and tricks for placing orders?"
      variant="discovery"
      onClose={async () => {
        await markScheduleLiveDemoDismissed()
      }}
    />
  )
}

export default ScheduleLiveDemoPrompt
