import React from "react"
import { CarrierMembership } from "sharedTypes"
import { capitalize } from "utilities/string"
import { fullName } from "utilities/person"
import DataRow from "components/DataRow"
import { formatAddress } from "utilities/address"
import { formatPhone } from "utilities/phone"

type Props = {
  carrierMembership: CarrierMembership
}

const MemberSectionContent = ({ carrierMembership }: Props) => {
  return (
    <div>
      <DataRow label="Name" value={fullName(carrierMembership)} />
      <DataRow label="DOB" value={carrierMembership.dateOfBirth} />
      <DataRow label="Sex" value={capitalize(carrierMembership.sex)} />
      <DataRow
        label="Home Phone"
        value={formatPhone(carrierMembership.phoneNumber)}
      />
      <DataRow label="Address" value={formatAddress(carrierMembership)} />
    </div>
  )
}

export default MemberSectionContent
