import { SupplierChatTemplate } from "../types"

type Variables = {
  supplierName: string
  patientFirstName: string
  patientLastName: string
}

const patterns: [string, (v: Variables) => string][] = [
  ["Supplier Name", ({ supplierName }) => supplierName],
  ["Patient First Name", ({ patientFirstName }) => patientFirstName],
  [
    "Patient Full Name",
    ({ patientFirstName, patientLastName }) =>
      `${patientFirstName} ${patientLastName}`,
  ],
]

const interpolate = (template: string, variables: Variables) =>
  patterns.reduce(
    (acc, [pattern, toValue]) =>
      acc.replace(`{${pattern}}`, toValue(variables)),
    template
  )

export const interpolateTemplates = (
  supplierChatTemplates: SupplierChatTemplate[],
  variables: Variables
) =>
  supplierChatTemplates.map((supplierChatTemplate) => ({
    ...supplierChatTemplate,
    template: interpolate(supplierChatTemplate.template, variables),
  }))
