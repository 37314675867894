import React, { useState } from "react"
import ManageTerritoriesForm from "applications/ReassignSalesReps/ManageTerritories/ManageTerritoriesForm"
import * as api from "applications/ReassignSalesReps/api"
import { AxiosError } from "axios"
import { reportError } from "utilities/error"
import { ApplicationError } from "sharedTypes"
import { navigate } from "utilities/navigation"
import ZipCodeConflictModal from "../ZipCodeConflictModal"
import { SelectOption } from "@parachutehealth/canopy-combobox-field"

type Territory = {
  territoryId: string
  territoryName: string
  salesRep: string
  territoryZips: string
  initialSalesRep?: SelectOption
}

type Props = {
  supplierId: string
  territory?: Territory
  path: string
  salesRepSearchPath: string
}

type ConflictProps = {
  zipOverlaps: []
  overlappingZipCodesCount: number
  currentRegionName: string
  regionId: string
  supplierId: string
  regionType: string
  path: string
}

const SalesRepTerritoryForm: React.FC<Props> = ({
  territory,
  path,
  salesRepSearchPath,
}) => {
  const [conflictProps, setConflictProps] = useState<ConflictProps | null>(null)
  const handleSubmit = async (values, { setErrors }): Promise<void> => {
    setConflictProps(null)
    try {
      const response = territory
        ? await api.updateTerritory(path, values)
        : await api.createTerritory(path, values)
      if (response.data.conflictProps) {
        setConflictProps({
          ...response.data.conflictProps,
          path: response.data.path,
        })
      } else {
        navigate(response.data.path)
      }
    } catch (e) {
      if (e instanceof AxiosError && e.response) {
        setErrors(e.response.data.errors)
      }
      reportError(e as ApplicationError)
    }
  }
  return (
    <>
      {conflictProps && <ZipCodeConflictModal {...conflictProps} />}
      <ManageTerritoriesForm
        onSubmit={handleSubmit}
        initialValues={{
          territories: territory?.territoryZips || "",
          territoryName: territory?.territoryName || "",
          selectedSalesRep: territory?.salesRep || "",
          initialSalesRep: territory?.initialSalesRep,
        }}
        salesRepSearchPath={salesRepSearchPath}
      />
    </>
  )
}

export default SalesRepTerritoryForm
