import React from "react"
import { Employment } from "sharedTypes"

import RadioInput from "./RadioInput"
import { Column, Header, Row } from "./sharedTypes"

export interface Props {
  employments: Pick<Employment, "employerName" | "role" | "salesRep">[]
}

enum Fields {
  Name = "name",
  NoAccess = "noAccess",
  ViewOnly = "viewOnly",
  Standard = "standard",
  Admin = "admin",
  SalesRep = "salesRep",
}

function EmploymentsTable({ employments }: Props) {
  const headers: Header[] = [
    { key: Fields.Name, label: "Name" },
    { key: Fields.NoAccess, label: "No Access" },
    { key: Fields.ViewOnly, label: "View Only" },
    { key: Fields.Standard, label: "Standard" },
    { key: Fields.Admin, label: "Admin" },
  ]

  const rows: Row[] = [
    {
      [Fields.Name]: "All Suppliers",
      [Fields.NoAccess]: {
        key: Fields.NoAccess,
        name: "select_all",
        value: "no_access",
        type: "radio",
      },
      [Fields.ViewOnly]: {
        key: Fields.ViewOnly,
        name: "select_all",
        value: "view_only",
        type: "radio",
      },
      [Fields.Standard]: {
        key: Fields.Standard,
        name: "select_all",
        value: "standard",
        type: "radio",
      },
      [Fields.Admin]: {
        key: Fields.Admin,
        name: "select_all",
        value: "admin",
        type: "radio",
      },
      [Fields.SalesRep]: {
        key: Fields.SalesRep,
        name: "select_all",
        value: "sales_rep",
        type: "checkbox",
      },
    },
    ...employments.map((employment, index) => {
      const roleRadio = (key, value) => ({
        key,
        name: `employments[${index}].role`,
        value,
        type: "radio",
      })
      return {
        [Fields.Name]: employment.employerName,
        [Fields.NoAccess]: roleRadio(Fields.NoAccess, "no_access"),
        [Fields.ViewOnly]: roleRadio(Fields.ViewOnly, "view_only"),
        [Fields.Standard]: roleRadio(Fields.Standard, "standard"),
        [Fields.Admin]: roleRadio(Fields.Admin, "admin"),
        [Fields.SalesRep]: {
          key: Fields.SalesRep,
          name: `employments[${index}].salesRep`,
          value: "sales_rep",
          type: "checkbox",
        },
      }
    }),
  ]

  return (
    <div className="form-group">
      <table className="table table-striped">
        <thead>
          <tr>
            {headers.map((header) => (
              <th key={header.key}>{header.label}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {rows.map((row) => {
            return (
              <tr key={row.name}>
                {headers.map((header) => {
                  const column = row[header.key] as Column
                  if (typeof column === "string") {
                    return <td key={column}>{column}</td>
                  }
                  return (
                    <td key={column.value}>
                      {column.type === "radio" && (
                        <RadioInput column={column} rows={rows} />
                      )}
                    </td>
                  )
                })}
              </tr>
            )
          })}
        </tbody>
      </table>
    </div>
  )
}

export default EmploymentsTable
