import { gql, TypedDocumentNode } from "@apollo/client"
import {
  GetUsersPatientsQuery,
  GetUsersPatientsQueryVariables,
} from "graphql/__generated__/graphql"

export const GetUsersPatients: TypedDocumentNode<
  GetUsersPatientsQuery,
  GetUsersPatientsQueryVariables
> = gql`
  query GetUsersPatients($query: FacetedQuery) {
    patients(query: $query) {
      nodes {
        id
        masterPatientId
        firstName
        lastName
        dateOfBirth
        sex
        mrn
        source {
          ... on ClinicalFacility {
            id
            name
          }
          ... on PatientSource {
            clinicalFacilitiesCurrentUserEmployersIntersection {
              id
              name
            }
          }
        }
      }
    }
  }
`
