import React from "react"
import EpicLoginForm from "./EpicLoginForm"
import { EmrInstance } from "../sharedTypes"

interface Props {
  emrInstances: EmrInstance[]
  createSession(params: { emrInstanceId: string }): Promise<void>
}

const InternalEpicLogin = ({ emrInstances, createSession }: Props) => (
  <>
    <h1>Select Your Organization</h1>
    <p className="text-center text-xs-left canopy-mbe-24x">
      You will be redirected to your organization's single sign-on page to log
      in with Epic
    </p>
    <EpicLoginForm emrInstances={emrInstances} onSubmit={createSession} />
  </>
)

export default InternalEpicLogin
