import { titleize } from "utilities/string"
import { Assignee, Doctor } from "sharedTypes"
import {
  getAssignees,
  getCatalogSubcategories,
  getClinicalFacilities,
  getClinicians,
  getFollowables,
  getCatalogPackages,
  getInternalStatuses,
  getLegalEntities,
  getSalesReps,
} from "./components/DashboardFilters/api"
import {
  ClinicalFacilitySearchResult,
  DateRangeConfig,
  FilterConfig,
  FilterType,
  FulfillmentStatus,
  OrderStage,
  OrderType,
  Package,
  PayerType,
  PackageCategory,
  SalesRep,
  SelectConfig,
  SignatureStatus,
  Followable,
  ToggleConfig,
  ExternalSystemBranch,
  LegalEntitySearchResult,
} from "./components/DashboardFilters/sharedTypes"

const toggleConfigs: ToggleConfig[] = [
  {
    name: "resolved",
    options: [
      { label: "New Activity", name: "unresolved", value: false },
      { label: "Cleared", name: "resolved", value: true },
    ],
  },
  {
    name: "patientMessageResolved",
    options: [
      {
        label: "New Patient Message",
        name: "patientMessageUnresolved",
        value: false,
      },
      { label: "Cleared", name: "patientMessageResolved", value: true },
    ],
  },
  {
    name: "assigned",
    options: [
      { label: "Assigned", name: "assigned", value: true },
      { label: "Unassigned", name: "unassigned", value: false },
    ],
  },
  {
    name: "completed",
    options: [
      { label: "Ready", name: "ready", value: true },
      { label: "Pending", name: "pending", value: false },
    ],
  },
  {
    name: "accepted",
    options: [
      { label: "Unaccepted", name: "unaccepted", value: false },
      { label: "Accepted", name: "accepted", value: true },
    ],
  },
  {
    name: "supplierOriginated",
    options: [
      { label: "Supplier Made", name: "supplierMade", value: true },
      { label: "Facility Made", name: "facilityMade", value: false },
    ],
  },
  {
    name: "deliveryMethod",
    options: [
      { label: "Delivery", name: "delivery", value: true },
      { label: "Consignment", name: "consignment", value: false },
    ],
  },
  {
    name: "liaison",
    options: [
      { label: "Liaison", name: "liaison", value: true },
      { label: "Non-liaison", name: "nonLiaison", value: false },
    ],
  },
  {
    name: "o2Recert",
    options: [
      { label: "O2 Recerts", name: "o2Recert", value: true },
      { label: "Non-Recerts", name: "nonO2Recert", value: false },
    ],
  },
  {
    name: "invite",
    options: [
      { label: "Invites", name: "invite", value: true },
      { label: "Non-Invites", name: "nonInvite", value: false },
    ],
  },
  {
    name: "salesRepAssigned",
    options: [
      { label: "Sales Rep Assigned", name: "salesRepAssigned", value: true },
      {
        label: "Sales Rep Unassigned",
        name: "salesRepUnassigned",
        value: false,
      },
    ],
  },
].map((filter) => ({ ...filter, filterType: FilterType.Toggle }))

const selectConfigs: SelectConfig<unknown>[] = [
  {
    name: "clinicalFacilities",
    label: "Facility",
    buildOption: ({ name, id }: ClinicalFacilitySearchResult) => ({
      label: name,
      value: id.toString(),
    }),
    fetchOptions: getClinicalFacilities,
  },
  {
    name: "suppliers",
    label: "Supplier",
    buildOption: ({ name, id }) => ({
      label: titleize(name),
      value: id,
    }),
  },
  {
    name: "packageCategories",
    label: "Categories",
    buildOption: ({ name, id }: PackageCategory) => ({
      label: titleize(name),
      value: id,
    }),
  },
  {
    name: "packageSubcategories",
    label: "Subcategories",
    buildOption: ({ name, id }: PackageCategory) => ({
      label: titleize(name),
      value: id,
    }),
    fetchOptions: getCatalogSubcategories,
  },
  {
    name: "packages",
    label: "Packages",
    buildOption: ({ name, id }: Package) => ({
      label: titleize(name),
      value: id,
    }),
    fetchOptions: getCatalogPackages,
  },
  {
    name: "signatureStatuses",
    label: "Signature Statuses",
    buildOption: (name: SignatureStatus) => ({
      label: titleize(name),
      value: name,
    }),
  },
  {
    name: "orderStages",
    label: "Order Stages",
    buildOption: (name: OrderStage) => ({
      label: titleize(name),
      value: name,
    }),
  },
  {
    name: "orderTypes",
    label: "Order Types",
    buildOption: (name: OrderType) => ({
      label: titleize(name),
      value: name,
    }),
  },
  {
    name: "payerTypes",
    label: "Payer Types",
    buildOption: (name: PayerType) => ({
      label: titleize(name),
      value: name,
    }),
  },
  {
    name: "legalEntities",
    label: "Legal Entities",
    buildOption: ({ name, id }: LegalEntitySearchResult) => ({
      label: name,
      value: id.toString(),
    }),
    fetchOptions: getLegalEntities,
  },
  {
    name: "deliveryStatuses",
    label: "Fulfillment Status",
    buildOption: (name: FulfillmentStatus) => ({
      label: titleize(name),
      value: name,
    }),
  },
  {
    name: "internalStatuses",
    label: "Internal Statuses",
    buildOption: ({ id, name }) => ({ label: name, value: id }),
    fetchOptions: getInternalStatuses,
  },
  {
    name: "assignees",
    label: "Assignee",
    buildOption: ({ id, name }: Assignee) => ({
      label: name,
      value: id.toString(),
    }),
    fetchOptions: getAssignees,
  },
  {
    name: "followUpOwners",
    label: "Follow-up Owner",
    buildOption: ({ id, name }: Followable) => ({
      label: name,
      value: id.toString(),
    }),
    fetchOptions: getFollowables,
  },
  {
    name: "vendorFollowers",
    label: "Supplier Follower",
    buildOption: ({ id, name }: Followable) => ({
      label: name,
      value: id.toString(),
    }),
    fetchOptions: (term) => getAssignees(term, { useGlobalExternalIds: true }),
  },
  {
    name: "externalSystemBranches",
    label: "External System Branch",
    buildOption: ({ id, name }: ExternalSystemBranch) => ({
      label: name,
      value: id,
    }),
  },
  {
    name: "clinicians",
    label: "Clinician",
    buildOption: (record: Doctor) => ({
      label: `${record.firstName} ${record.lastName} ${record.npi} ${record.state}`,
      value: record.doctorId,
    }),
    fetchOptions: getClinicians,
  },
  {
    name: "salesReps",
    label: "Sales Rep",
    buildOption: ({ id, name }: SalesRep) => ({
      label: name,
      value: id.toString(),
    }),
    fetchOptions: getSalesReps,
  },
].map((filter) => ({ ...filter, filterType: FilterType.Select }))

const dateRangeConfigs: DateRangeConfig[] = [
  { name: "received", title: "Date Received" },
  { name: "signed", title: "Date Signed" },
  { name: "discharge", title: "Discharge Date" },
  { name: "requestedDelivery", title: "Req. Delivery" },
  { name: "expiration", title: "Due Date" },
].map((filter) => ({ ...filter, filterType: FilterType.DateRange }))

const filterConfigs: FilterConfig[] = [
  ...selectConfigs,
  ...toggleConfigs,
  ...dateRangeConfigs,
]

export default filterConfigs

export const defaultFilterSettings = filterConfigs
  .map((fc) => `${fc.name}Filter`)
  .reduce((acc, curr) => {
    acc[curr] = true
    return acc
  }, {})
