// extracted by mini-css-extract-plugin
var _1 = "app-javascript-components-DocumentsBrowser-index-module__actions--BN6Vk";
var _2 = "app-javascript-components-DocumentsBrowser-index-module__documentControls--XANnL";
var _3 = "app-javascript-components-DocumentsBrowser-index-module__documentNav--GQyKW";
var _4 = "app-javascript-components-DocumentsBrowser-index-module__documentsBrowser--jL3z4";
var _5 = "app-javascript-components-DocumentsBrowser-index-module__end--GR7lK";
var _6 = "app-javascript-components-DocumentsBrowser-index-module__hover--RDxrM";
var _7 = "app-javascript-components-DocumentsBrowser-index-module__image--Hs4qb";
var _8 = "app-javascript-components-DocumentsBrowser-index-module__overlay--PY7Fl";
var _9 = "app-javascript-components-DocumentsBrowser-index-module__paginationLabel--h7ob4";
var _a = "app-javascript-components-DocumentsBrowser-index-module__start--f7yu3";
var _b = "app-javascript-components-DocumentsBrowser-index-module__swiper--Pr9xd";
export { _1 as "actions", _2 as "documentControls", _3 as "documentNav", _4 as "documentsBrowser", _5 as "end", _6 as "hover", _7 as "image", _8 as "overlay", _9 as "paginationLabel", _a as "start", _b as "swiper" }
