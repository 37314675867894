import React, { useCallback, useState, FC } from "react"
import Popover from "components/Popover"
import { CanopyButton } from "@parachutehealth/canopy-button"
import SupplierDetails from "./SupplierDetails"
import { DmeOrder } from "sharedTypes"

interface ContactInfoProps {
  dmeOrder: DmeOrder
}

const ContactInfo: FC<ContactInfoProps> = ({ dmeOrder }) => {
  const [open, setOpen] = useState(false)

  const togglePopover = useCallback(() => {
    setOpen(!open)
  }, [open, setOpen])

  const { lineItemGroups } = dmeOrder
  if (lineItemGroups.length === 0) {
    return null
  }

  return (
    <Popover
      open={open}
      onClick={togglePopover}
      label={
        <CanopyButton
          variant="secondary"
          size="small"
          className="canopy-mbe-4x"
        >
          Contact Info
        </CanopyButton>
      }
    >
      {open && <SupplierDetails />}
    </Popover>
  )
}

export default ContactInfo
