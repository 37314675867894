import React, { useContext } from "react"
import classNames from "classnames"
import { DocumentationRequirement } from "sharedTypes"
import { getFilesValidationMessage } from "utilities/fileValidation"
import ManualRequirementsSection from "./ManualRequirementsSection"
import GenerateRequirementsSection from "./GenerateRequirementsSection"
import uniqBy from "lodash.uniqby"
import Overlay from "components/Overlay"
import { Form } from "components/form"
import Modal from "components/Modal"
import WorkflowPage from "context/WorkflowPage"

import { pluralize } from "utilities/string"
import { toSentence } from "utilities/array"
type Props = {
  uploadDocuments(
    documentationRequirementIds: string[],
    files: File[],
    signed: boolean,
    otherAttachmentTitle: string
  ): Promise<void>
  manualRequirements: DocumentationRequirement[]
  generateRequirements: DocumentationRequirement[]
}

const UploadTab = ({
  uploadDocuments,
  manualRequirements,
  generateRequirements,
}: Props) => {
  const { pollForDocuments } = useContext(WorkflowPage)

  const onSubmit = (
    { files, documentationRequirementIds, signed, otherAnswer },
    { setSubmitting }
  ) => {
    setSubmitting(true)
    const ids = documentationRequirementIds.filter(
      (id) => ![ManualRequirementsSection.OTHER].includes(id)
    )
    const otherAttachmentTitle = documentationRequirementIds.includes(
      ManualRequirementsSection.OTHER
    )
      ? otherAnswer
      : null
    uploadDocuments(ids, files, signed, otherAttachmentTitle)
      .then(() => pollForDocuments())
      .catch(() => setSubmitting(false))
  }

  function getFilesTypeValidationMessage(files: File[]): string {
    if (files.length) {
      const incorrectFileTypeNames = files
        .filter((file) => file.type !== "application/pdf")
        .map((file) => file.name)

      if (incorrectFileTypeNames.length > 0) {
        return `File ${pluralize(
          "type",
          incorrectFileTypeNames.length
        )} of ${toSentence(incorrectFileTypeNames)} ${
          incorrectFileTypeNames.length > 1 ? "are" : "is"
        } the incorrect format. Only application/pdf is allowed`
      }
    }
    return ""
  }

  const onFileChange = ({ event, selectedFiles, setFieldValue }) => {
    const files: File[] = Array.from(event.target.files)

    const size_message = getFilesValidationMessage(files)
    if (size_message) {
      return alert(size_message)
    }

    const format_message = getFilesTypeValidationMessage(files)
    if (format_message) {
      return alert(format_message)
    }
    setFieldValue("files", uniqBy([...selectedFiles, ...files], "name"))
  }

  const validate = (requireDocumentationRequirements) => ({
    documentationRequirementIds,
    signed,
    files,
    otherAnswer,
  }) => {
    if (
      requireDocumentationRequirements &&
      documentationRequirementIds.length === 0 &&
      !signed
    ) {
      return {
        documentationRequirementIds:
          "must select at least one documentation requirement",
      }
    }
    if (
      documentationRequirementIds.includes(ManualRequirementsSection.OTHER) &&
      !otherAnswer
    ) {
      return { otherAnswer: "Document name required" }
    }
    if (files.length === 0) {
      return { files: "must select a file" }
    }
  }

  const renderUploads = (files, setFieldValue) => {
    const removeFile = (fileName) => {
      setFieldValue(
        "files",
        files.filter((file) => file.name !== fileName)
      )
    }
    return (
      <>
        {files.map((file) => (
          <div className="entry" key={file.name}>
            <span className="far fa-file-alt" />
            <span className="title" />
            <span className="description">{file.name}</span>
            <span
              className="icon fas fa-trash"
              onClick={() => removeFile(file.name)}
            />
          </div>
        ))}
      </>
    )
  }

  const renderFileInput = (files, setFieldValue, isSubmitting) => {
    return (
      <input
        type="file"
        name="files"
        id="files"
        accept="application/pdf"
        disabled={isSubmitting}
        multiple
        onChange={(event) =>
          onFileChange({ event, selectedFiles: files, setFieldValue })
        }
      />
    )
  }

  const modalBodyWithSteps = (values, setFieldValue, isSubmitting) => {
    return (
      <>
        <div className="row canopy-mbe-12x">
          <div className="col-12">
            <strong className="canopy-mie-8x">Step 1:</strong>
            <strong>Identify what you are uploading</strong>
          </div>
        </div>
        <ManualRequirementsSection requirements={manualRequirements} />
        <GenerateRequirementsSection
          signed={values.signed}
          requirements={generateRequirements}
        />
        <div className="row canopy-mbe-8x">
          <div className="col-12">
            <strong className="canopy-mie-8x">Step 2: </strong>
            <div className="w-250 d-inline-block v-align-middle">
              <label
                className={classNames("file-label btn btn-block btn-brand-o")}
              >
                <i className="fa fa-upload canopy-mie-4x" />
                {values.files && values.files.length > 0
                  ? "Add files to upload"
                  : "Upload files"}
                {renderFileInput(values.files, setFieldValue, isSubmitting)}
              </label>
            </div>
          </div>
        </div>
        <div className="row canopy-mbe-12x canopy-mx-2x">
          {renderUploads(values.files, setFieldValue)}
        </div>
      </>
    )
  }

  const modalBodyWithoutSteps = (values, setFieldValue, isSubmitting) => {
    return (
      <>
        <div className="row canopy-mbe-8x">
          <div className="col-4 canopy-mbs-4x">
            <strong>Upload your files:</strong>
          </div>
          <div className="col-5">
            <label
              className={classNames("file-label btn btn-block btn-brand-o")}
            >
              <i className="fa fa-upload canopy-mie-4x" />
              {values.files && values.files.length > 0
                ? "Add files to upload"
                : "Upload files"}
              {renderFileInput(values.files, setFieldValue, isSubmitting)}
            </label>
          </div>
        </div>
        <div className="row canopy-mbe-12x canopy-mx-2x">
          {renderUploads(values.files, setFieldValue)}
        </div>
      </>
    )
  }

  const showSteps =
    manualRequirements.length > 0 || generateRequirements.length > 0

  return (
    <Form
      onSubmit={onSubmit}
      validate={validate(showSteps)}
      initialValues={{
        documentationRequirementIds: [],
        files: [],
        signed: false,
      }}
    >
      {({ isSubmitting, setFieldValue, isValid, values }) => (
        <Overlay
          showSpinner
          active={isSubmitting}
          className="overlay-flex-height"
        >
          <Modal.Body>
            {showSteps
              ? modalBodyWithSteps(values, setFieldValue, isSubmitting)
              : modalBodyWithoutSteps(values, setFieldValue, isSubmitting)}
          </Modal.Body>
          <Modal.Footer>
            <button
              className="btn btn-brand"
              disabled={isSubmitting || !isValid}
              type="submit"
            >
              Done
            </button>
          </Modal.Footer>
        </Overlay>
      )}
    </Form>
  )
}

export default UploadTab
