import { Record } from "components/SelectFilter"

export interface DateRangeConfig {
  name: string
  title: string
  filterType: FilterType.DateRange
}

export interface SupplierOrganizationDashboardSupplier {
  name: string
  id: string
}

export type OrderStage =
  | "facility_in_progress"
  | "supplier_review"
  | "accepted"
  | "canceled"

export type OrderType =
  | "Hospice"
  | "Manual Documentation"
  | "PPS"
  | "Private Pay"
  | "Pre-Authorization"
  | "Quick"
  | "Re-Order"
  | "O2 Recert"

export type PayerType =
  | "medicare"
  | "medicaid"
  | "medicare_medicaid"
  | "commercial"
  | "workers_comp"
  | "chip"

export type Package = {
  id: string
  name: string
}

export type PackageCategory = {
  id: string
  name: string
}

export type SignatureStatus =
  | "not_needed"
  | "completed"
  | "pending"
  | "needed"
  | "unknown"

export interface FilterOptions {
  suppliers: SupplierOrganizationDashboardSupplier[]
  externalSystemBranches: ExternalSystemBranch[]
}

export interface FilterValues {
  query?: string
  suppliers?: string[]
  name?: string
  options?: string[]
  assigned?: boolean
  resolved?: boolean
  accepted?: boolean
  clinicalFacilities?: []
}

export enum FilterType {
  Select = "select",
  Toggle = "toggle",
  DateRange = "date_range",
}

export interface SelectConfig<T> {
  name: string
  label: string
  fetchOptions?(term: string): Promise<T[]>
  buildOption(T): Record
  filterType: FilterType.Select
}

export type FilterConfig =
  | SelectConfig<unknown>
  | ToggleConfig
  | DateRangeConfig

export interface ToggleOption {
  name: string
  label: string
  value: boolean
}

export interface ToggleConfig {
  name: string
  options: ToggleOption[]
  filterType: FilterType.Toggle
}

export interface ClinicalFacilitySearchResult {
  name: string
  id: number
}
export interface LegalEntitySearchResult {
  name: string
  id: number
}

export type FulfillmentStatus = string

export type Followable = {
  id: string
  name: string
}

export type SalesRep = {
  id: number
  name: string
  externalId: string
}

export type ExternalSystemBranch = {
  id: string
  name: string
  defaultSupplierIds: string[]
}

export type InternalStatus = {
  id: string
  name: string
}
