// @ts-strict-ignore
import { EmrInstanceTypes } from "sharedTypes"

const allowableEmrInstanceType = (instanceType) =>
  [EmrInstanceTypes.PointClickCare].includes(instanceType)

export const initUpscope = (upscopeApiKey) => {
  if (!upscopeApiKey) return
  if (
    window.parachute?.emrInstanceType &&
    !allowableEmrInstanceType(window.parachute.emrInstanceType)
  )
    return

  const i = function () {
    // eslint-disable-next-line prefer-rest-params
    i.c(arguments)
  }
  i.q = []
  i.c = (args) => {
    i.q.push(args)
  }

  const getUpscope = () => {
    const script = document.createElement("script")
    script.type = "text/javascript"
    script.async = true
    script.src = `${window.upscopeUrl}/upscope.js`
    const firstScript = document.getElementsByTagName("script")[0]
    firstScript.parentNode.insertBefore(script, firstScript)
  }

  if (typeof window.Upscope !== "function") {
    window.Upscope = i
    getUpscope()

    window.Upscope("init", {
      showUpscopeLink: false,
      showTerminateButton: true,
      trackConsole: false,
      allowRemoteConsole: false,
      allowRemoteScroll: true,
      allowRemoteClick: false,
      allowRemoteType: false,
      injectLookupCodeButton: false,
      enableLookupCodeOnKey: false,
      requireAuthorizationForSession: true,
      resetConnectionOnLogOut: true,
      showAgentRequestButton: "never",
      collectHistory: false,
      apiKey: upscopeApiKey,
      neverDebug: false,
      debug: false,
    })
    window.Upscope("updateConnection", {
      uniqueId: window.parachute.currentUserId,
      identities: [window.parachute.currentUserName],
    })
  }
}
