import React from "react"
import { FacilityFormParams, FacilitySearchResult } from "../../sharedTypes"
import {
  Form,
  TextField,
  PhoneField,
  ZipField,
  NpiField,
  StateSelect,
} from "components/form"
import FacilityTypeSelect from "components/FacilityTypeSelect"
import Button from "components/form/Button"
import SignedAgreementCheckbox from "../SignedAgreementCheckbox"
import BackButton from "./BackButton"
import { isPhoneValid } from "utilities/phone"
import { customizeErrors } from "utilities/validation"

type Props = {
  titleText: string
  subtitleText?: string
  submitText: string
  onSubmit(params: FacilityFormParams): Promise<void>
  onBackButtonClick(): void
  selectedFacility?: FacilitySearchResult
  trackOnSubmit(): void
}

const FacilityForm = ({
  titleText,
  subtitleText,
  submitText,
  onSubmit,
  onBackButtonClick,
  selectedFacility,
  trackOnSubmit,
}: Props) => {
  const CUSTOM_MESSAGES = {
    name: {
      "has already been taken":
        "A facility with this name is already on Parachute Health. Enter a different facility name or go back to facility search to join this facility.",
    },
  }

  const submitForm = (values: FacilityFormParams, { setErrors }) =>
    onSubmit(values)
      .then(() => {
        trackOnSubmit()
      })
      .catch(({ errors }) => {
        setErrors(customizeErrors(errors, CUSTOM_MESSAGES))
      })

  const validate = ({
    name,
    line1,
    state,
    phoneNumber,
    city,
    signedAgreement,
  }) => {
    const errors: {
      name?: string
      facilityType?: string
      line1?: string
      state?: string
      phoneNumber?: string
      city?: string
      signedAgreement?: string
    } = {}

    if (!name) {
      errors.name = "Enter healthcare facility name"
    }
    if (!line1) {
      errors.line1 = "Enter address"
    }
    if (!state) {
      errors.state = "Select state"
    }
    if (!isPhoneValid(phoneNumber)) {
      errors.phoneNumber = "Enter a valid 10-digit phone number"
    }
    if (!city) {
      errors.city = "Enter City"
    }
    if (!signedAgreement) {
      errors.signedAgreement =
        "You must agree to the Facility Terms of Use to create a facility"
    }
    return errors
  }

  return (
    <>
      <BackButton onClick={onBackButtonClick} />
      <div className="canopy-mbe-24x">
        <h1>{titleText}</h1>
        {subtitleText && (
          <p className="text-center text-xs-left canopy-mbe-0">
            {subtitleText}
          </p>
        )}
      </div>
      <Form
        initialValues={{
          name: "",
          npi: "",
          phoneNumber: "",
          facilityType: "",
          line1: "",
          line2: "",
          city: "",
          state: "",
          zip: "",
          signedAgreement: false,
          ...selectedFacility,
        }}
        onSubmit={submitForm}
        validate={validate}
      >
        {({ isSubmitting }) => (
          <>
            <TextField
              label="Healthcare facility name"
              name="name"
              placeholder="Ex. East Medical Center - Emergency"
            />
            <NpiField
              label="NPI (Optional)"
              name="npi"
              optional
              validateField
            />
            <PhoneField label="Facility phone number" name="phoneNumber" />
            <TextField label="Address line 1" name="line1" />
            <TextField label="Address line 2 (Optional)" name="line2" />
            <div className="row gutter-10">
              <div className="col-md-6">
                <TextField label="City" name="city" />
              </div>
              <div className="col-md-6">
                <StateSelect />
              </div>
            </div>
            <ZipField validateField />
            <FacilityTypeSelect />
            <SignedAgreementCheckbox />
            <Button
              className="btn-brand btn-block"
              type="submit"
              disabled={isSubmitting}
            >
              {submitText}
            </Button>
          </>
        )}
      </Form>
    </>
  )
}

export default FacilityForm
