// @ts-strict-ignore
import React, { useState, useEffect } from "react"
import autosize from "autosize"
import classNames from "classnames"

interface Props {
  autoFocus?: boolean
  className?: string
  disabled?: boolean
  alert?: React.ReactNode
  id?: string
  innerRef?(node: Element): void
  name?: string
  rows?: number
  value?: string
  onFocus?: React.FormEventHandler
  onBlur?: React.FormEventHandler
  onChange?: React.ChangeEventHandler<HTMLTextAreaElement>
  onKeyDown?: React.KeyboardEventHandler<HTMLTextAreaElement>
  placeholder?: string
}

const TextArea = React.forwardRef<HTMLTextAreaElement, Props>(
  (
    {
      alert,
      autoFocus,
      className,
      disabled,
      id,
      innerRef,
      name,
      onChange,
      onKeyDown,
      placeholder,
      rows,
      value,
      ...props
    },
    ref
  ) => {
    const [isFocused, setFocus] = useState(false)
    const [internalRef, setInternalRef] = useState<HTMLTextAreaElement>()
    const setForwardRef = ref as (instance: HTMLTextAreaElement) => void

    useEffect(() => {
      if (internalRef) {
        autosize(internalRef)
        return () => {
          autosize.destroy(internalRef)
        }
      }
    }, [internalRef])

    const onFocus = (event) => {
      setFocus(true)
      props.onFocus?.(event)
    }

    const onBlur = (event) => {
      setFocus(false)
      props.onBlur?.(event)
    }

    React.useEffect(() => {
      if (internalRef) {
        internalRef.addEventListener("focus", onFocus)
        internalRef.addEventListener("blur", onBlur)
        return () => {
          internalRef.removeEventListener("focus", onFocus)
          internalRef.removeEventListener("blur", onBlur)
        }
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [internalRef])

    return (
      <div
        className={classNames("textarea-container has-autosize", {
          "has-alert": alert,
          "has-focus": isFocused,
        })}
      >
        <textarea
          ref={(element) => {
            setForwardRef && setForwardRef(element)
            setInternalRef(element)
            if (innerRef) {
              innerRef(internalRef)
            }
          }}
          id={id}
          autoFocus={autoFocus}
          className={className}
          disabled={disabled}
          name={name}
          onFocus={onFocus}
          onBlur={onBlur}
          onKeyDown={onKeyDown}
          onChange={onChange}
          placeholder={placeholder}
          rows={rows}
          value={value}
        />
        {alert}
      </div>
    )
  }
)

export default TextArea
