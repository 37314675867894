import React from "react"
import ClinicalInformationRow from "applications/Workflow/containers/ClinicalFacilityClinical/components/DocumentationRequirements/ClinicalInformationRow"
import ExternalQuestionnaireModalButton from "../ExternalQuestionnaireModalButton/ExternalQuestionnaireModalButton"
import { QuestionAndAnswers } from "../../../../../../../../../../sharedTypes"

interface Props {
  title: string
  questionsAndAnswers: QuestionAndAnswers[] | undefined
  recommendation?: string //Temporarily making this optional until we start rendering the actual questionnaires
}

const MedicalNecessityRow = (props: Props) => {
  return (
    <ClinicalInformationRow
      title={props.title}
      showDetails={false}
      isDocumentParachuteGenerated={true}
      status={
        <ExternalQuestionnaireModalButton
          title={props.title}
          questionsAndAnswers={props.questionsAndAnswers}
        />
      }
      recommendation={props.recommendation}
    />
  )
}

export default MedicalNecessityRow
