import React from "react"
import { CanopyDialogModal } from "@parachutehealth/canopy-dialog-modal"
import { CanopyButton } from "@parachutehealth/canopy-button"

type Props = {
  navigateToUpdateFacilityPage: () => void
  navigateToSupplierSendBackPage: () => void
  onClose: () => void
}

const SupplierSendBackFacilityWarning = ({
  navigateToUpdateFacilityPage,
  navigateToSupplierSendBackPage,
  onClose,
}: Props) => {
  return (
    <CanopyDialogModal
      header="Select facility before pushing order"
      onClose={onClose}
      open
      size="large"
      primaryFooterButton={
        <CanopyButton onClick={navigateToUpdateFacilityPage} variant="primary">
          Select Facility
        </CanopyButton>
      }
      secondaryFooterButton={
        <CanopyButton
          onClick={navigateToSupplierSendBackPage}
          variant="secondary"
        >
          Push Without Facility
        </CanopyButton>
      }
    >
      Please select a facility before pushing the order to ensure it gets sent
      to the right place.
    </CanopyDialogModal>
  )
}

export default SupplierSendBackFacilityWarning
