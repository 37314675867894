import React, { useState } from "react"

type Props = {
  isPrimary: boolean
  isBrand: boolean
  createDmeOrder(): Promise<void>
  label: string
}

function CreateOrderButton(props: Props) {
  const { isPrimary, isBrand, createDmeOrder, label } = props

  const buttonClass = `${isBrand ? "btn-brand" : "btn-primary"}${
    isPrimary ? "" : "-o"
  }`

  const [disabled, setDisabled] = useState(false)

  const onClick = (event) => {
    event.stopPropagation()
    setDisabled(true)
    createDmeOrder()
  }

  return (
    <button
      type="submit"
      className={`btn btn-sm btn-xs-block ${buttonClass}`}
      onClick={onClick}
      disabled={disabled}
    >
      {label}
    </button>
  )
}

CreateOrderButton.defaultProps = {
  label: "Create New Order",
}

export default CreateOrderButton
