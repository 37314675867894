// @ts-strict-ignore
import Tooltip from "../../Tooltip"
import React, { useMemo } from "react"
import { ParachuteTheme, useStyles } from "themes/theme"
import { EmployerType, EventAction } from "../../../sharedTypes"
import { underscore } from "../../../utilities/string"
import { EventClassName } from "../utilities/classNames"

type StyleProps = { displayType: string }
const mentionBackgroundStyles = (props: StyleProps, theme: ParachuteTheme) =>
  ({
    neutral: theme.colors.gray.lighter,
    internal: "#eef2f3",
    referral: "#eed6fa",
    supplier: "#daf0f9",
    parachute: "#ddf7f5",
  }[props.displayType])
const mentionColorStyles = (props: StyleProps, theme: ParachuteTheme) =>
  props.displayType === "neutral"
    ? theme.colors.black.default
    : theme.colors.info.default
const styles = (props: StyleProps) => (theme) => ({
  mention: {
    background: mentionBackgroundStyles(props, theme),
    "border-radius": "4px",
    color: mentionColorStyles(props, theme),
    padding: "2px 4px",
  },
  mentionText: {
    opacity: 0.8,
    "&:hover": {
      opacity: 1,
    },
  },
})

export type MentionProps = {
  email: string
  name: string
  disableTooltip?: boolean
  colorCoded?: boolean
  eventInformation?: {
    superAdmin: boolean
    userEmployerType: EmployerType
    action: EventAction
  }
}
type EventType = "internal" | "referral" | "supplier" | "parachute" | "neutral"

export const mentionDisplayType = ({
  action,
  colorCoded,
  superAdmin,
  userEmployerType,
}: {
  action: EventAction
  colorCoded: boolean
  superAdmin: boolean
  userEmployerType: EmployerType
}): EventType => {
  if (!colorCoded) return "neutral"
  if (action === EventAction.InternalComment) return "internal"

  if (superAdmin) {
    return EventClassName.Parachute
  }
  const employerType = underscore(userEmployerType)
  switch (employerType) {
    case EmployerType.ClinicalFacility:
      return EventClassName.Referral
    case EmployerType.Supplier:
      return EventClassName.Supplier
    case EmployerType.Doctor:
      return EventClassName.Referral
    default:
      return EventClassName.Referral
  }
}
export const MENTION_TEST_ID = "message-mention"
export const Mention = ({
  email,
  name,
  disableTooltip = false,
  colorCoded = false,
  eventInformation,
}: MentionProps) => {
  const displayType = mentionDisplayType({ ...eventInformation, colorCoded })
  const stylesFunction = useMemo(() => styles({ displayType }), [displayType])
  const classes = useStyles(stylesFunction)
  const nameElement = (
    <span className={classes.mention} data-testid={MENTION_TEST_ID}>
      <span className={classes.mentionText}>@{name}</span>
    </span>
  )
  const nameWithTooltip = (
    <Tooltip
      triggerElementClassName="d-inline-block"
      overlay={email}
      trigger={["hover", "click"]}
      width={Tooltip.Width.Unlimited}
    >
      {nameElement}
    </Tooltip>
  )
  return disableTooltip ? nameElement : nameWithTooltip
}
