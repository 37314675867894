import React from "react"
import { searchIcd10Codes } from "./api"
import InternalIcd10Codes from "./InternalIcd10Codes"
import { DiagnosisHook } from "./hooks/useDiagnosis"

type Props = {
  className: string
  diagnosisHook: DiagnosisHook
  label: string
  header: string
  subheader: string | null
  showIcd10Error?: boolean
}

export default function Icd10Codes(props: Props) {
  const {
    className,
    diagnosisHook,
    label,
    header,
    subheader,
    showIcd10Error,
  } = props
  const { addIcd10Code, diagnosis, removeIcd10Code } = diagnosisHook
  return (
    <InternalIcd10Codes
      header={header}
      subheader={subheader}
      add={addIcd10Code}
      className={className}
      remove={removeIcd10Code}
      diagnosis={diagnosis}
      label={label}
      fetch={searchIcd10Codes}
      showIcd10Error={showIcd10Error}
    />
  )
}
