import React, { ReactElement } from "react"
import classNames from "classnames"
import * as styles from "./index.module.scss"
import Icon from "./Icon"

type Props = {
  active?: boolean
  badge?: boolean
  ariaLabel?: string
  icon: ReactElement
  label?: string
  collapsedLabel?: string
  onClick(): void
}

const ToggleButton = ({
  ariaLabel,
  icon,
  active,
  badge,
  onClick,
  label,
  collapsedLabel,
}: Props): ReactElement => {
  return (
    <button
      aria-label={ariaLabel}
      className={classNames(styles.toggle, {
        [styles.active]: active,
      })}
      onClick={onClick}
    >
      {icon && <Icon icon={icon} active={active} badge={badge} />}
      {label && <div className={classNames(styles.label)}>{label}</div>}
      {collapsedLabel && (
        <div className={classNames(styles.collapsedLabel)}>
          {collapsedLabel}
        </div>
      )}
    </button>
  )
}

export default ToggleButton
