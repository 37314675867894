import { post } from "services/api"
import { createVerificationEmailUrl } from "./urls"

export const createVerificationEmail = (
  doctorId: string,
  userId: string
): Promise<void> => {
  return post(createVerificationEmailUrl, { doctorId, userId }).then(
    ({ data }) => data
  )
}
