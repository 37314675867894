import { post, put } from "services/api"
import { createUserUrl, updateUserUrl } from "./urls"

export const updateUser = (userId, params) =>
  put(updateUserUrl(userId), params)
    .then(({ data }) => data)
    .catch(({ response }) => Promise.reject(response.data))

export const createUser = (params) =>
  post(createUserUrl(), params)
    .then(({ data }) => data)
    .catch(({ response }) => Promise.reject(response.data))
