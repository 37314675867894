import React, { useState } from "react"
import { RouteComponentProps } from "react-router"
import { User, Employment } from "../shared/sharedTypes"
import InternalFacilitySignUp from "./components/InternalFacilitySignUp"
import * as api from "./api"
import { navigate } from "utilities/navigation"

import { trackCreatePassword } from "../shared/tracking"

type Props = {
  clinicalFacilityId: string
  invitationToken: string
  tokenExpired: boolean
  user: User
  employment: Employment
  titles: string[]
} & RouteComponentProps

function FacilitySignUp(props: Props) {
  const { clinicalFacilityId, invitationToken, titles, tokenExpired } = props
  const [employment, setEmployment] = useState(props.employment)
  const [user, setUser] = useState(props.user)

  const updateUser = (params) => {
    return api
      .updateUser(clinicalFacilityId, user.externalId, invitationToken, params)
      .then(({ employment, user }) => {
        setEmployment(employment)
        setUser(user)
        return Promise.resolve()
      })
  }

  const updatePassword = (params) => {
    return api
      .updatePassword(
        clinicalFacilityId,
        user.externalId,
        invitationToken,
        params
      )
      .then(({ redirectUrl }) => {
        trackCreatePassword(user)
        navigate(redirectUrl)
        return Promise.resolve()
      })
  }

  const resendInvitation = () => {
    return api.resendInvitation(
      clinicalFacilityId,
      user.externalId,
      invitationToken
    )
  }

  return (
    <InternalFacilitySignUp
      user={user}
      employment={employment}
      updateUser={updateUser}
      updatePassword={updatePassword}
      resendInvitation={resendInvitation}
      titles={titles}
      tokenExpired={tokenExpired}
    />
  )
}

export default FacilitySignUp
