import React from "react"
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core"
import { CatalogSeed } from "../../../../types/sharedTypes"
import { pluralize } from "../../../../../../utilities/string"
import CmsDrawer from "../../../../components/CmsDrawer"
import ArchivedLabel from "../ArchivedTooltip"
import StyledTable from "../../../../components/StyledTable"

export type SeedProductVariationsDrawerProps = {
  catalogSeed: CatalogSeed
  open: boolean
  onClose(): void
}

const SeedProductVariationsDrawer = ({
  open,
  onClose,
  catalogSeed,
}: SeedProductVariationsDrawerProps) => {
  return (
    <CmsDrawer
      title={`${catalogSeed.name} Seed: SKUs`}
      open={open}
      onClose={onClose}
    >
      <div className="d-flex justify-content-space-between canopy-mbs-4x canopy-mbe-8x">
        <div>
          <div className="canopy-typography-heading-medium canopy-mbe-4x">
            Seed SKUs
          </div>
          <Typography>
            This seed contains{"  "}
            <span className="font-weight-bold">
              {`${catalogSeed.productVariations!.length} ${pluralize(
                "SKU",
                catalogSeed.productVariations!.length
              )}`}
            </span>
          </Typography>
        </div>
      </div>
      {catalogSeed.productVariations && (
        <StyledTable>
          <Table data-testid="seed-skus-table" size="small">
            <TableHead>
              <TableRow>
                <TableCell component="th" scope="col">
                  SKU
                </TableCell>
                <TableCell component="th" scope="col">
                  SKU ID
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {catalogSeed.productVariations.map((pv) => (
                <TableRow key={pv.id}>
                  <TableCell>
                    <a href={pv.url}>{pv.description}</a>
                    {pv.archivedAt && (
                      <ArchivedLabel message="This Global SKU is archived and will not be added during seed import" />
                    )}
                  </TableCell>
                  <TableCell>{pv.externalId}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </StyledTable>
      )}
    </CmsDrawer>
  )
}
export default SeedProductVariationsDrawer
