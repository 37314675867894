import React, { useContext, useState } from "react"
import Followers from "./Followers"
import { DmeOrder, EmployerType, FollowerEmployerType } from "sharedTypes"
import { UpdateFollowersParams } from "../sharedTypes"
import { CanopyDialogModal } from "@parachutehealth/canopy-dialog-modal"
import { CanopyButton } from "@parachutehealth/canopy-button"
import GlobalContext from "context/Global"
import { handleError } from "utilities/error"
import { Form } from "components/form"

type Props = {
  show: boolean
  closeModal(): void
  editable: boolean
  updateFollowers(params: UpdateFollowersParams): Promise<void>
  dmeOrder: DmeOrder
}

function FollowersModal({
  show,
  closeModal,
  editable,
  dmeOrder,
  updateFollowers,
}: Props) {
  const [loading, setLoading] = useState(false)
  const { currentEmployer } = useContext(GlobalContext)
  const isFacility =
    currentEmployer.employerType === EmployerType.ClinicalFacility
  const allFollowers = dmeOrder.followers
  const supplierFollowers = allFollowers.filter(
    (follower) => follower.employerType === FollowerEmployerType.Supplier
  )

  const isAvailableToRemove = (record) =>
    !(record.employerType === FollowerEmployerType.Supplier && isFacility)
  const followers = dmeOrder.followers.filter(isAvailableToRemove)
  const followerIds = followers.map((follower) => follower.id)

  const onSubmit = (values) => {
    if (!editable) closeModal()
    const valueFollowerIds = values.followerIds
    setLoading(true)
    const readonlyFollowerIds = isFacility
      ? supplierFollowers.map((follower) => follower.id)
      : []
    updateFollowers({
      followerIds: valueFollowerIds.concat(readonlyFollowerIds),
    })
      .then(() => {
        setLoading(false)
        closeModal()
      })
      .catch((e) => {
        setLoading(false)
        handleError(e)
      })
  }

  return (
    <Form onSubmit={onSubmit} initialValues={{ followerIds }}>
      {({ handleSubmit, values }) => {
        return (
          <CanopyDialogModal
            header={editable ? "View and edit followers" : "Followers"}
            open={show}
            onClose={closeModal}
            primaryFooterButton={
              <CanopyButton
                onClick={() => handleSubmit(values)}
                loading={loading}
              >
                Done
              </CanopyButton>
            }
            size={editable ? "medium" : "small"}
          >
            <Followers
              editable={editable}
              dmeOrder={dmeOrder}
              loading={loading}
            />
          </CanopyDialogModal>
        )
      }}
    </Form>
  )
}

export default FollowersModal
