import React, { useState, useMemo } from "react"
import {
  CatalogBrowseTab,
  ConsignmentCloset,
  Nullable,
} from "../../../../../../sharedTypes"
import { CanopyDialogModal } from "@parachutehealth/canopy-dialog-modal"
import { CanopyButton } from "@parachutehealth/canopy-button"
import Filters from "./Filters"
import Button from "components/form/Button"
import { CategoryWithSubcategories } from "../../sharedTypes"

type Props = {
  availableCategories: CategoryWithSubcategories[]
  canFilterByFormulary: boolean
  canFilterByYourOrganizationsSuppliers: boolean
  categories: CategoryWithSubcategories[]
  categoryId: Nullable<number>
  combinedCatalogSearch: boolean
  consignmentClosetOnly: boolean
  consignmentClosets: ConsignmentCloset[]
  currentCatalogBrowseTab: CatalogBrowseTab
  defaultConsignmentClosetId: string
  defaultServiceAreaState: string
  formularyOnly: boolean
  manageFulfillmentAgreements: boolean
  selectCategory: (number: number, isSubcategory?: boolean) => void
  selectDefaultConsignmentCloset: (id: string) => Promise<void>
  selectState: (state: string) => void
  selectedCategory: Nullable<number>
  serviceAreaState: string
  subcategoryId: Nullable<number>
  toggleConsignmentClosetFilter: () => void
  toggleFormularyFilter: () => void
  toggleYourOrganizationsSuppliersFilter: () => void
  usesEnterpriseFeatures: boolean
  yourOrganizationsSuppliersOnly: boolean
}

const MobileFilters = ({
  availableCategories,
  canFilterByFormulary,
  canFilterByYourOrganizationsSuppliers,
  categories,
  categoryId,
  combinedCatalogSearch,
  consignmentClosetOnly,
  consignmentClosets,
  currentCatalogBrowseTab,
  defaultConsignmentClosetId,
  defaultServiceAreaState,
  formularyOnly,
  manageFulfillmentAgreements,
  selectCategory,
  selectDefaultConsignmentCloset,
  selectState,
  selectedCategory,
  serviceAreaState,
  subcategoryId,
  toggleConsignmentClosetFilter,
  toggleFormularyFilter,
  toggleYourOrganizationsSuppliersFilter,
  usesEnterpriseFeatures,
  yourOrganizationsSuppliersOnly,
}: Props) => {
  const [showModal, setShowModal] = useState<boolean>(false)

  const filterCount = useMemo(() => {
    const filters = [
      consignmentClosetOnly,
      canFilterByFormulary && formularyOnly,
      canFilterByYourOrganizationsSuppliers && yourOrganizationsSuppliersOnly,
      categoryId,
      subcategoryId,
      serviceAreaState !== defaultServiceAreaState,
    ]
    return filters.filter(Boolean).length
  }, [
    canFilterByFormulary,
    canFilterByYourOrganizationsSuppliers,
    categoryId,
    consignmentClosetOnly,
    defaultServiceAreaState,
    formularyOnly,
    serviceAreaState,
    subcategoryId,
    yourOrganizationsSuppliersOnly,
  ])

  return (
    <>
      <Button
        className="btn btn-brand-o btn-block"
        onClick={() => setShowModal(true)}
      >
        {filterCount > 0 ? `Filters (${filterCount})` : "Filters"}
      </Button>
      <CanopyDialogModal
        header="Filters"
        open={showModal}
        onClose={() => setShowModal(false)}
        primaryFooterButton={
          <CanopyButton
            className="btn btn-brand btn-block"
            onClick={() => setShowModal(false)}
          >
            Done
          </CanopyButton>
        }
      >
        <Filters
          availableCategories={availableCategories}
          canFilterByFormulary={canFilterByFormulary}
          canFilterByYourOrganizationsSuppliers={
            canFilterByYourOrganizationsSuppliers
          }
          categories={categories}
          combinedCatalogSearch={combinedCatalogSearch}
          consignmentClosetOnly={consignmentClosetOnly}
          consignmentClosets={consignmentClosets}
          currentCatalogBrowseTab={currentCatalogBrowseTab}
          defaultConsignmentClosetId={defaultConsignmentClosetId}
          formularyOnly={formularyOnly}
          isMobile={true}
          manageFulfillmentAgreements={manageFulfillmentAgreements}
          selectCategory={selectCategory}
          selectState={selectState}
          selectedCategory={selectedCategory}
          selectDefaultConsignmentCloset={selectDefaultConsignmentCloset}
          serviceAreaState={serviceAreaState}
          subcategoryId={subcategoryId}
          toggleConsignmentClosetFilter={toggleConsignmentClosetFilter}
          toggleYourOrganizationsSuppliersFilter={
            toggleYourOrganizationsSuppliersFilter
          }
          toggleFormularyFilter={toggleFormularyFilter}
          usesEnterpriseFeatures={usesEnterpriseFeatures}
          yourOrganizationsSuppliersOnly={yourOrganizationsSuppliersOnly}
        />
      </CanopyDialogModal>
    </>
  )
}

export default MobileFilters
