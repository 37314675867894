// @ts-strict-ignore
import React from "react"
import {
  DmeOrder,
  LineItemGroup,
  Employer,
  EmployerType,
  RxDetailsOutput,
  DmeOrderWarning,
} from "sharedTypes"
import DataRow from "components/DataRow"
import { formatPaymentMethod, isDefaultPaymentMethod } from "utilities/payment"
import { addressForFulfillment, formatAddressType } from "utilities/address"
import { capitalize } from "utilities/string"
import LineItemDescription from "components/LineItemDescription"

type Props = {
  dmeOrder: DmeOrder
  lineItemGroup: LineItemGroup
  warnings: DmeOrderWarning[]
  currentEmployer: Employer
  rxDetailsOutputs: RxDetailsOutput[]
  showFulfilledBy: boolean
}

const ServiceLineItemGroup = ({
  dmeOrder,
  lineItemGroup,
  currentEmployer,
  rxDetailsOutputs,
  showFulfilledBy,
}: Props) => {
  const fulfillment = dmeOrder.fulfillments.find(
    (fulfillment) => fulfillment.id === lineItemGroup.dmeOrderFulfillmentId
  )
  const lineItemGroupOutputs = rxDetailsOutputs.filter(
    (output) => output.lineItemGroupId === lineItemGroup.id
  )
  const address = addressForFulfillment(dmeOrder.addresses, fulfillment)

  const formatAddress = (address) => {
    return (
      <>
        {address.addressType && (
          <span className="bold color-black">
            {capitalize(formatAddressType(address))}
            <br />
          </span>
        )}
        {[address.line1, address.line2, address.room, address.city]
          .filter((l) => !!l)
          .map((l) => (
            <React.Fragment key={l}>
              {l}
              <br />
            </React.Fragment>
          ))}
      </>
    )
  }

  return (
    <div key={lineItemGroup.id} className="well well-shadow">
      <div className="image with-description">
        <img src={lineItemGroup.imageUrl} />
        <div className="content">
          <p className="bold font-xs">{lineItemGroup.packageName}</p>
          <ul className="canopy-pis-8x font-xs">
            {lineItemGroup.lineItems.map((lineItem) => (
              <li key={lineItem.id} className="canopy-mbe-2x">
                <LineItemDescription lineItem={lineItem} />
              </li>
            ))}
            <hr className="canopy-mbs-8x canopy-mbe-0" />
          </ul>
          {!!lineItemGroupOutputs.length && (
            <DataRow
              className="font-xs canopy-mbe-0"
              label="Service Details"
              value={lineItemGroupOutputs
                .map((output) => `${output.label}: ${output.value}`)
                .join(", ")}
            />
          )}
          <DataRow
            className="font-xs canopy-mbe-0"
            label="Service Address"
            value={formatAddress(address)}
          />
          {currentEmployer.employerType === EmployerType.ClinicalFacility && (
            <DataRow
              className="font-xs canopy-mbe-0"
              label="Provided By"
              value={lineItemGroup.packageSupplier.name}
            />
          )}
          {currentEmployer.employerType === EmployerType.Supplier &&
            showFulfilledBy && (
              <DataRow
                className="font-xs canopy-mbe-0"
                label="Fulfilled by"
                value={dmeOrder.supplierLegalEntity.name}
              />
            )}
          <DataRow
            className="font-xs canopy-mbe-0"
            label="Evaluation Date"
            value={fulfillment.deliveryDate}
          />
          {!isDefaultPaymentMethod(lineItemGroup.paymentMethod) && (
            <DataRow
              className="font-xs canopy-mbe-0"
              label="Payment Method"
              value={formatPaymentMethod(lineItemGroup.paymentMethod)}
            />
          )}
        </div>
      </div>
    </div>
  )
}

export default ServiceLineItemGroup
