import { useEffect } from "react"
import { get } from "services/api"
import { supplierChatTemplateUrl } from "./urls"
import { SupplierChatTemplate } from "./types"
import { useQuery, useQueryClient } from "@tanstack/react-query"
import {
  build as buildEndpoint,
  ChatWithMessages,
  Message,
} from "services/patientPortal"
import { Socket } from "phoenix"
import { camelizeKeys } from "humps"

export const fetchSupplierChatTemplates = (
  order_id: string
): Promise<SupplierChatTemplate[]> =>
  get(supplierChatTemplateUrl(order_id)).then(({ data }) => data)

export const useSupplierChatTemplatesQuery = (orderId: string) => {
  return useQuery({
    queryKey: ["supplierChatTemplates", { orderId }],
    queryFn: () => fetchSupplierChatTemplates(orderId),
  })
}
export const useChatQuery = (chatId: string) => {
  return useQuery({
    queryKey: ["patientPortalChat", { chatId }],
    queryFn: () =>
      buildEndpoint()
        .fetchChat(chatId)
        .then(({ data }) => data),
  })
}
export const useChatSubscription = (
  chatId: string,
  token: string | undefined
) => {
  const queryClient = useQueryClient()
  useEffect(() => {
    if (!token) {
      return
    }
    const socket = new Socket(`${window.patientPortalWsUrl}/ws/parachute`, {
      params: { token: token },
    })
    socket.connect()
    const channel = socket.channel(`chat:${chatId}`, {})
    channel.join()
    channel.on("new_message", (msg) => {
      queryClient.setQueryData<ChatWithMessages>(
        ["patientPortalChat", { chatId }],
        (data) =>
          data && {
            ...data,
            messages: [camelizeKeys(msg), ...data.messages] as Message[],
          }
      )
      channel.push("read_message", {
        external_id: msg.external_id,
        type: msg.type,
      })
    })
    channel.on("updated_messages", ({ updated_messages }) => {
      queryClient.setQueryData<ChatWithMessages>(
        ["patientPortalChat", { chatId }],
        (data) =>
          data && {
            ...data,
            messages: data.messages.map((message) => {
              const updatedMessage = updated_messages.find(
                (updatedMessage) =>
                  message.externalId === updatedMessage.external_id
              )
              if (updatedMessage) {
                return camelizeKeys(updatedMessage)
              } else {
                return message
              }
            }),
          }
      )
    })
    return () => {
      channel.leave()
      socket.disconnect()
    }
  }, [chatId, token, queryClient])
}
