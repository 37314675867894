import React from "react"
import withInitialData from "components/withInitialData"
import InternalClinical from "./components/InternalClinical"
import * as api from "./api"
import { useDiagnosis, DiagnosisHook } from "../Icd10Codes/hooks/useDiagnosis"
import { getDmeOrderDiagnosis } from "../Icd10Codes/api"
import { DmeOrder, Diagnosis } from "sharedTypes"

type Props = {
  dmeOrder: DmeOrder
  initialData: Diagnosis
  nextPagePath?: string
  refreshDmeOrder(): Promise<void>
  setDmeOrder(dmeOrder: DmeOrder): Promise<void>
  isPageChecked?: boolean
}

const Clinical = (props: Props) => {
  const {
    dmeOrder,
    initialData,
    nextPagePath,
    isPageChecked,
    refreshDmeOrder,
    setDmeOrder,
  } = props
  const diagnosisHook: DiagnosisHook = useDiagnosis(
    initialData,
    refreshDmeOrder
  )

  const updateDocumentationRequirementsRequested = (requestedIds: string[]) => {
    return api
      .updateDocumentationRequirementsRequested(requestedIds)
      .then((response) => {
        return getDmeOrderDiagnosis().then(({ data }) => {
          diagnosisHook.setDiagnosis(data)
          setDmeOrder(response.data)
        })
      })
  }

  const updateOrderDate = (orderDate: string) => {
    return api
      .updateOrderDate(orderDate)
      .then((response) => setDmeOrder(response.data))
  }

  return (
    <InternalClinical
      updateOrderDate={updateOrderDate}
      updateDocumentationRequirementsRequested={
        updateDocumentationRequirementsRequested
      }
      diagnosisHook={diagnosisHook}
      dmeOrder={dmeOrder}
      fetchChartNoteQuestions={api.fetchChartNoteQuestions}
      nextPagePath={nextPagePath}
      isPageChecked={isPageChecked}
      setDmeOrder={setDmeOrder}
    />
  )
}

const fetchInitialData = () => getDmeOrderDiagnosis().then(({ data }) => data)
export default withInitialData(fetchInitialData)(Clinical)
