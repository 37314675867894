import cookiesStorage from "./storage/cookies"

export function isStorageAvailable(storage) {
  const test = "test"
  try {
    storage.setItem(test, test)
    const isAvailable = storage.getItem(test) === test
    storage.removeItem(test)
    return isAvailable
  } catch (e) {
    return false
  }
}

export function isCookiesStorageAvailable() {
  return isStorageAvailable(cookiesStorage)
}

export function isAndroid() {
  return /(android)/i.test(navigator.userAgent)
}

function isInputTypeSupported(type) {
  const input = document.createElement("input")
  input.setAttribute("type", type)
  return input.type !== "text"
}

export const IS_DATE_INPUT_SUPPORTED = isInputTypeSupported("date")
