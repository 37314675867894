// @ts-strict-ignore
import React, { useState } from "react"

type Props = {
  fullText: string
  limit?: number
  closeText?: string
  openText?: string
  classes?: { link?: string }
}

const MINIMUM_LIMIT = 120
const APPENDED_TEXT_LENGTH = 13

function ExpandableTruncatedText({
  fullText,
  limit,
  closeText = "Read Less",
  openText = "Read More",
  classes = {},
}: Props) {
  const [open, setOpen] = useState(false)
  if (limit < MINIMUM_LIMIT) {
    limit = MINIMUM_LIMIT
  }
  if (fullText.length < limit) {
    return <p className="font-xs">{fullText}</p>
  }
  const onClick = (event) => {
    event.stopPropagation()
    event.preventDefault()
    setOpen((previousState) => !previousState)
  }
  if (open) {
    return (
      <p className="font-xs">
        {`${fullText} `}
        <a className={classes.link ? classes.link : "link"} onClick={onClick}>
          {closeText}
        </a>
      </p>
    )
  }
  return (
    <p className="font-xs">
      {`${fullText.substr(0, limit - APPENDED_TEXT_LENGTH)}... `}{" "}
      <a className={classes.link ? classes.link : "link"} onClick={onClick}>
        {openText}
      </a>
    </p>
  )
}

ExpandableTruncatedText.defaultProps = {
  limit: MINIMUM_LIMIT,
}

export default ExpandableTruncatedText
