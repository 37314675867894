// @ts-strict-ignore
import React from "react"
import { Field, useFormikContext } from "formik"
import NumberField from "components/form/NumberField"
import asQuestion from "./asQuestion"
import { SurveyQuestion, SurveyAnswerType } from "sharedTypes"
import { Values } from "../sharedTypes"
import { Step, DefaultStep } from "sharedTypes"
import Checkbox from "../form/Checkbox"
import { isNumber } from "lodash"
import { hasTruthyProperty } from "../../../../../utilities/object"

type Props = {
  inputId: string
  question: {
    min?: number
    minExclusive?: number
    max?: number
    maxExclusive?: number
    step?: Step
    unit?: string
    naEnabled?: boolean
    naLabel?: string
  }
  isSubmitting: boolean
}

interface NumericQuestionAnswerValues {
  answerValue: number | string
  na?: boolean
}

const QuestionInputNumber = React.forwardRef<HTMLInputElement, Props>(
  ({ inputId, question, isSubmitting }, ref) => {
    const { values } = useFormikContext<NumericQuestionAnswerValues>()
    const { max, maxExclusive, min, minExclusive, step } = question

    const answerValueIsNumber = isNumber(values.answerValue)
    return (
      <>
        <NumberField
          name="answerValue"
          id={inputId}
          max={answerValueIsNumber ? max : null}
          min={answerValueIsNumber ? min : null}
          maxExclusive={answerValueIsNumber ? maxExclusive : null}
          minExclusive={answerValueIsNumber ? minExclusive : null}
          step={step || DefaultStep.Any}
          suffix={question.unit}
          disabled={isSubmitting || !!values.na}
          submitOnNavigationKey
          ref={ref}
        />
        {question.naEnabled && (
          <Field name="na">
            {({ form, field }) => (
              <Checkbox
                {...field}
                id={`na-${inputId}`}
                label={question.naLabel}
                checked={!!form.values.na}
                onChange={(checked) => {
                  form
                    .setFieldValue("answerValue", "")
                    .then(() => form.setFieldTouched("answerValue", true))
                  form.setFieldValue("na", checked)
                }}
              />
            )}
          </Field>
        )}
      </>
    )
  }
)

export default asQuestion(QuestionInputNumber, {
  mapValuesToQuestion: (values: Values) => ({
    answerType: values.na ? SurveyAnswerType.Na : SurveyAnswerType.Value,
    answerValue: values.na ? "" : values.answerValue,
  }),
  mapQuestionToValues: (question: SurveyQuestion) => {
    if (question.answerType === SurveyAnswerType.Na) {
      return { answerValue: "", na: true }
    } else {
      return {
        answerValue:
          typeof question.answerValue === "string"
            ? Number(question.answerValue)
            : "",
      }
    }
  },
  validate: (values: Values, question: SurveyQuestion) => {
    if (
      typeof values.answerValue === "number" ||
      values.na ||
      (question.naEnabled && values.answerValue === "")
    ) {
      return {}
    }
    return { answerValue: "valid number is required" }
  },
  isButtonVisible: ({ values, isValid, dirty, errors }) => {
    return (
      (values.na || values.answerValue !== "") &&
      isValid &&
      dirty &&
      !hasTruthyProperty(errors)
    )
  },
})
