import React, { useState } from "react"
import { DocumentationOrder, isDeniedResolution } from "../fetchOrders"
import Popover from "../../../components/Popover"
import ExpandableTruncatedText from "../../../components/ExpandableTruncatedText"
import IconButton from "@material-ui/core/IconButton"
import ChatIcon from "@material-ui/icons/Chat"
import * as styles from "./index.module.scss"

export const DenialReason = ({ order }: { order: DocumentationOrder }) => {
  const [open, setOpen] = useState(false)
  if (!isDeniedResolution(order.signatureResolution)) return null

  return (
    <>
      {order.signatureResolution.denialReason}
      {order.signatureResolution.description && (
        <Popover
          open={open}
          label={
            <IconButton
              aria-label="denial description"
              color={open ? "primary" : "inherit"}
            >
              <ChatIcon />
            </IconButton>
          }
          onClick={() => setOpen((open) => !open)}
          onHide={() => setOpen(false)}
        >
          <ExpandableTruncatedText
            fullText={order.signatureResolution.description}
            limit={300}
            openText="See All"
            closeText="See Less"
            classes={{ link: styles.link }}
          />
        </Popover>
      )}
    </>
  )
}
