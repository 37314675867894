import React from "react"
import { CarrierAuthorization } from "sharedTypes"
import DataRow from "components/DataRow"
import { format } from "utilities/date"
import { capitalize } from "utilities/string"

type Props = {
  carrierAuthorization: CarrierAuthorization
}

const CoverageSectionContent = ({ carrierAuthorization }: Props) => {
  const { carrierMembership } = carrierAuthorization
  const {
    carrierName,
    memberNumber,
    enrollmentChangedAt,
    rank,
    enrollmentStatus,
  } = carrierMembership

  const renderOtherInsurance = (carrierMembership) => {
    const {
      otherInsuranceCarrierName,
      otherInsuranceType,
      otherInsuranceMemberNumber,
      otherInsuranceRank,
    } = carrierMembership

    if (
      !otherInsuranceCarrierName &&
      !otherInsuranceType &&
      !otherInsuranceMemberNumber &&
      !otherInsuranceRank
    ) {
      return
    }

    return (
      <>
        <hr />
        <DataRow
          label="Other Insurance"
          value={otherInsuranceCarrierName || otherInsuranceType}
        />
        <DataRow label="Member ID" value={otherInsuranceMemberNumber} />
        <DataRow label="Rank" value={capitalize(otherInsuranceRank)} />
      </>
    )
  }

  return (
    <div>
      <DataRow label="Authorization Insurance" value={carrierName} />
      <DataRow label="Member ID" value={memberNumber} />
      <DataRow label="Rank" value={capitalize(rank)} />
      <DataRow
        label="Enrollment"
        value={`${capitalize(enrollmentStatus)} as of ${format(
          enrollmentChangedAt
        )}`}
      />
      {renderOtherInsurance(carrierMembership)}
    </div>
  )
}

export default CoverageSectionContent
