import React from "react"
import { highlight } from "utilities/select"
import { Doctor } from "sharedTypes"
import Suggestion from "components/form/Select/Suggestion"
import { capitalize, titlecase } from "utilities/string"

type Props = {
  doctor: Doctor
  query: string
}

const DoctorSuggestion = ({ doctor, query }: Props) => {
  const address = [titlecase(doctor.city), capitalize(doctor.state)]
    .filter(Boolean)
    .join(", ")
  return (
    <Suggestion className="d-flex" key={doctor.doctorId}>
      <div className="w-100-p" aria-label="Doctor Suggestion">
        {highlight(
          `${titlecase(doctor.firstName)} ${titlecase(doctor.lastName)} - ${
            doctor.npi
          }`,
          query
        )}
        {address && (
          <div className="float-right color-dark-gray">{address}</div>
        )}
      </div>
    </Suggestion>
  )
}

export default DoctorSuggestion
