// @ts-strict-ignore
type Options = {
  decelerate?: boolean
  threshold?: number
  y?: boolean
  x?: boolean
  slowdown?: number
  maxvelocity?: number
  onScroll?(node: HTMLElement): void
}

type Listener = {
  node: HTMLElement
  eventName: string
  callback(): void
}

class Swiper {
  options: Options
  node: HTMLElement
  document: HTMLElement
  listeners: Listener[]
  xpos: null | number
  ypos: null | number
  prevxpos: null | number
  prevypos: null | number
  mousedown: boolean
  timeout: number
  movedAt: null | Date
  target: null | HTMLElement
  velocity: number
  velocityY: number
  threshold: null | number
  moving: boolean

  constructor(node, options = {}) {
    this.options = {
      decelerate: true,
      threshold: 0,
      y: true,
      x: true,
      slowdown: 0.9,
      maxvelocity: 80,
      ...options,
    }
    this.node = node
    this.document = document.documentElement
    this.listeners = []
    this.xpos = null
    this.ypos = null
    this.prevxpos = null
    this.prevypos = null
    this.mousedown = false
    this.timeout = 1000 / 60
    this.movedAt = null
    this.target = null
    this.velocity = 0
    this.velocityY = 0
    this.threshold = null
    this.moving = false

    this.addListeners()
  }

  destroy() {
    this.removeListeners()
  }

  start(clientX, clientY) {
    this.mousedown = true
    this.velocity = this.prevxpos = 0
    this.velocityY = this.prevypos = 0
    this.xpos = clientX
    this.ypos = clientY
  }

  stop() {
    this.velocity = 0
    this.velocityY = 0
    this.options.decelerate = true
  }

  onTouchStart(event) {
    const touch = this.getTouch(event)
    this.threshold = this.options.threshold
    this.start(touch.clientX, touch.clientY)
    event.stopPropagation()
  }

  onTouchMove(event) {
    if (this.mousedown) {
      const touch = this.getTouch(event)
      this.inputmove(touch.clientX, touch.clientY)
      event.preventDefault && event.preventDefault()
    }
  }

  onInputDown(event) {
    this.threshold = this.options.threshold
    this.start(event.clientX, event.clientY)
    this.target = event.target
    if (event.target.nodeName === "IMG") {
      event.preventDefault && event.preventDefault()
    }
    event.stopPropagation && event.stopPropagation()
  }

  onInputEnd(event) {
    this.end()
    this.target = null
    event.preventDefault && event.preventDefault()
  }

  onInputMove(event) {
    if (this.mousedown) {
      this.inputmove(event.clientX, event.clientY)
      event.preventDefault && event.preventDefault()
    }
  }

  onScroll(event) {
    event.preventDefault && event.preventDefault()
    this.options.onScroll && this.options.onScroll(this.node)
  }

  onInputClick(event) {
    if (Math.abs(this.velocity) > 0 || Math.abs(this.velocityY) > 0) {
      event.preventDefault && event.preventDefault()
      event.stopPropagation && event.stopPropagation()
      return false
    }
  }

  onDragStart(event) {
    event.preventDefault && event.preventDefault()
    event.stopPropagation && event.stopPropagation()
    return false
  }

  onSelectStart(event) {
    event.preventDefault && event.preventDefault()
    event.stopPropagation && event.stopPropagation()
    return false
  }

  inputmove(clientX, clientY) {
    if (
      !this.movedAt ||
      new Date() > new Date(this.movedAt.getTime() + this.timeout)
    ) {
      this.movedAt = new Date()

      if (this.mousedown && (this.xpos || this.ypos)) {
        const movedX = clientX - this.xpos
        const movedY = clientY - this.ypos
        if (this.threshold > 0) {
          const moved = Math.sqrt(movedX * movedX + movedY * movedY)
          if (this.threshold > moved) {
            return
          } else {
            this.threshold = 0
          }
        }
        if (this.target) {
          this.target.blur()
          this.target = null
          this.node.focus()
        }

        this.options.decelerate = false
        this.velocity = this.velocityY = 0

        const { scrollLeft, scrollTop } = this.node

        this.scrollLeft(this.options.x ? scrollLeft - movedX : scrollLeft)
        this.scrollTop(this.options.y ? scrollTop - movedY : scrollTop)

        this.prevxpos = this.xpos
        this.prevypos = this.ypos
        this.xpos = clientX
        this.ypos = clientY

        this.setVelocities()
      }
    }
  }

  move() {
    if (this.options.x && this.node.scrollWidth > 0) {
      this.scrollLeft(this.node.scrollLeft + this.velocity)
      if (Math.abs(this.velocity) > 0) {
        this.velocity = this.options.decelerate
          ? this.decelerateVelocity(this.velocity, this.options.slowdown)
          : this.velocity
      }
    } else {
      this.velocity = 0
    }

    if (this.options.y && this.node.scrollHeight > 0) {
      this.scrollTop(this.node.scrollTop + this.velocityY)
      if (Math.abs(this.velocityY) > 0) {
        this.velocityY = this.options.decelerate
          ? this.decelerateVelocity(this.velocityY, this.options.slowdown)
          : this.velocityY
      }
    } else {
      this.velocityY = 0
    }

    if (Math.abs(this.velocity) > 0 || Math.abs(this.velocityY) > 0) {
      if (!this.moving) {
        this.moving = true
        window.requestAnimationFrame(() => {
          this.moving = false
          this.move()
        })
      }
    } else {
      this.stop()
    }
  }

  decelerateVelocity(velocity, slowdown) {
    return Math.floor(Math.abs(velocity)) === 0 ? 0 : velocity * slowdown
  }

  setVelocities() {
    this.velocity = this.capVelocity(
      this.prevxpos - this.xpos,
      this.options.maxvelocity
    )
    this.velocityY = this.capVelocity(
      this.prevypos - this.ypos,
      this.options.maxvelocity
    )
  }

  end() {
    if (this.xpos && this.prevxpos && this.options.decelerate === false) {
      this.options.decelerate = true
      this.setVelocities()
      this.xpos = null
      this.prevxpos = null
      this.mousedown = false
      this.move()
    }
  }

  resetMouse() {
    this.xpos = null
    this.ypos = null
    this.mousedown = false
  }

  capVelocity(velocity, max) {
    if (velocity > 0 && velocity > max) {
      return max
    } else if (velocity < 0 - max) {
      return 0 - max
    }
    return velocity
  }

  scrollLeft(left) {
    this.node.scrollLeft = left
  }

  scrollTop(top) {
    this.node.scrollTop = top
  }

  addListener(node, eventName, callback) {
    node.addEventListener(eventName, callback, false)
    this.listeners.push({ node, eventName, callback })
  }

  hasTouch() {
    return "ontouchend" in document
  }

  getTouch(e) {
    return ((e.originalEvent &&
      (e.originalEvent.touches || e.originalEvent.changedTouches)) ||
      e.changedTouches)[0]
  }

  addListeners() {
    if (this.hasTouch()) {
      this.addListener(this.node, "touchstart", this.onTouchStart.bind(this))
      this.addListener(this.node, "touchend", this.onInputEnd.bind(this))
      this.addListener(this.node, "touchmove", this.onTouchMove.bind(this))
    }

    this.addListener(this.node, "mousedown", this.onInputDown.bind(this))
    this.addListener(this.node, "mouseup", this.onInputEnd.bind(this))
    this.addListener(this.node, "mousemove", this.onInputMove.bind(this))

    this.addListener(this.node, "click", this.onInputClick.bind(this))
    this.addListener(this.node, "scroll", this.onScroll.bind(this))
    this.addListener(this.node, "selectstart", this.onSelectStart.bind(this))
    this.addListener(this.node, "dragstart", this.onDragStart.bind(this))

    this.addListener(this.document, "mouseup", this.resetMouse.bind(this))
    this.addListener(this.document, "click", this.resetMouse.bind(this))
  }

  removeListeners() {
    this.listeners.forEach(({ node, eventName, callback }) => {
      node.removeEventListener(eventName, callback, false)
    })
  }
}

export default Swiper
