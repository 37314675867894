// @ts-strict-ignore
import React, { useState } from "react"
import classNames from "classnames"
import OtherContactCard from "./OtherContactCard"
import OtherContactModal from "./OtherContactModal"
import { DmeOrder, EmergencyContact } from "sharedTypes"

type Props = {
  dmeOrder: DmeOrder
  updateOtherContact(otherContact: EmergencyContact): Promise<void>
  deleteOtherContact(otherContact: EmergencyContact): Promise<void>
}

export default ({
  dmeOrder,
  updateOtherContact,
  deleteOtherContact,
}: Props) => {
  const [currentOtherContact, setCurrentOtherContact] = useState(null)

  const editOtherContact = (otherContact) => {
    setCurrentOtherContact(otherContact)
  }

  const addOtherContact = () => {
    if (currentOtherContact) {
      return
    }
    setCurrentOtherContact({})
  }

  return (
    <>
      {dmeOrder.emergencyContacts.map((otherContact) => (
        <OtherContactCard
          key={otherContact.id}
          otherContact={otherContact}
          editOtherContact={editOtherContact}
        />
      ))}
      <div className="canopy-mbe-12x">
        <a
          onClick={addOtherContact}
          className={classNames("link", { disabled: !!currentOtherContact })}
        >
          + Add another contact for this order (Optional)
        </a>
      </div>
      {currentOtherContact && (
        <OtherContactModal
          otherContact={currentOtherContact}
          updateOtherContact={updateOtherContact}
          deleteOtherContact={deleteOtherContact}
          cancel={() => {
            setCurrentOtherContact(null)
          }}
        />
      )}
    </>
  )
}
