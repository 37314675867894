import { put } from "services/api"
import { updateOneTimeOrderEnabledUrl } from "./urls"

export const updateOneTimeOrderEnabled = ({
  clinicalFacilityId,
  oneTimeOrderEnabled,
}: {
  clinicalFacilityId: string
  oneTimeOrderEnabled: boolean
}) => {
  return put(updateOneTimeOrderEnabledUrl(clinicalFacilityId), {
    oneTimeOrderEnabled,
  })
    .then(({ data }) => data)
    .catch(({ response }) => Promise.reject(response.data))
}

export const updateYourOrganizationsSuppliersOnlyDefault = ({
  clinicalFacilityId,
  yourOrganizationsSuppliersOnlyDefault,
}: {
  clinicalFacilityId: string
  yourOrganizationsSuppliersOnlyDefault: boolean
}) => {
  return put(updateOneTimeOrderEnabledUrl(clinicalFacilityId), {
    yourOrganizationsSuppliersOnlyDefault,
  })
    .then(({ data }) => data)
    .catch(({ response }) => Promise.reject(response.data))
}
