import React from "react"
import { Severity } from "../sharedTypes"
import SeverityIcon from "./SeverityIcon"

type Props = {
  missingDocument: boolean
}

export default function MissingDocument({ missingDocument }: Props) {
  if (!missingDocument) {
    return null
  }
  return (
    <div className="pill pill-warning">
      <SeverityIcon className="canopy-mie-2x" type={Severity.Warning} />
      Missing documents
    </div>
  )
}
