import { del, get, post, put } from "services/api"
import * as urls from "./urls"
import { ClinicalFacility, Doctor } from "./sharedTypes"

export const approveOrders = (
  orderIds,
  signingSignatureRequestIds,
  doctorId: string
) => {
  return post(urls.approvalsUrl(doctorId), {
    approvals: { orderIds, signingSignatureRequestIds },
  }).then(({ data }) => data)
}

export const removeFacility = (clinicalFacilityId): Promise<Doctor> => {
  return del(urls.affiliationsUrl(), { clinicalFacilityId }).then(
    ({ data }) => data
  )
}

export const addFacility = (clinicalFacilityId): Promise<Doctor> => {
  return post(urls.affiliationsUrl(), { clinicalFacilityId }).then(
    ({ data }) => data
  )
}

export function searchFacilities(term: string): Promise<ClinicalFacility[]> {
  return get(urls.facilitiesUrl(), { search: term })
    .then((response) => response.data)
    .catch(({ response }) => Promise.reject(response.data.errors))
}

export function updateProfile(values) {
  return put(urls.profileUrl(), { doctor: values })
    .then((response) => response.data)
    .catch(({ response }) => Promise.reject(response.data.errors))
}

export function resendIdentityVerifications(identity) {
  return post(urls.resendIdentityVerificationsUrl(), { identity })
    .then((response) => response.data)
    .catch(({ response }) => Promise.reject(response.data.errors))
}
