import Cookies from "js-cookie"

export const getGridSortModelFromCookie = (key: string) => {
  return Cookies.getJSON(key)
}
export const setGridSortModelInCookie = (
  key: string,
  value: string,
  expirationDays: number
) => {
  return Cookies.set(key, value, { expires: expirationDays })
}
