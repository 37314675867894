import React from "react"
import Link from "components/Link"

export const DownloadCsvTemplateButton = (props: { templatePath: string }) => (
  <p>
    Download:{" "}
    <Link href={props.templatePath} className="color-brand">
      CSV Template
    </Link>
  </p>
)
