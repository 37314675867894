// @team @destroythefax

import React, { Dispatch, ReactElement, SetStateAction } from "react"
import OrderActionsDropdownButton from "applications/Workflow/containers/Navigation/components/OrderActionsDropdown/OrderActionsDropdownButton"
import { handleError } from "utilities/error"

type Props = {
  unfollowDmeOrder(): Promise<void>
  setOrderActionsDropdownOpen: Dispatch<SetStateAction<boolean>>
}

const UnfollowButton = ({
  setOrderActionsDropdownOpen,
  unfollowDmeOrder,
}: Props): ReactElement => {
  const unfollowOrderHandler = () => {
    unfollowDmeOrder()
      .then(() => {
        setOrderActionsDropdownOpen(false)
      })
      .catch((error) => {
        handleError(error)
      })
  }

  return (
    <OrderActionsDropdownButton
      iconName="user"
      title="Unfollow&nbsp;order"
      subtitle="Stop receiving updates on this order"
      onClick={unfollowOrderHandler}
    />
  )
}

export default UnfollowButton
