import React from "react"
import { employerHomeUrl } from "applications/Workflow/urls"
import { trackClick } from "../../analytics"
import NonProdHeader from "applications/Workflow/containers/Navigation/components/TopNavigation/TopLeftNavButton/NonProdHeader/NonProdHeader"
import { CanopyButton } from "@parachutehealth/canopy-button"

interface Props {
  label: string
  nonprodHeaderDetails?: string
}

const HomeLink: React.FC<Props> = ({ label, nonprodHeaderDetails }) => {
  return (
    <>
      <CanopyButton
        as="a"
        href={employerHomeUrl()}
        iconStart="arrow-left"
        onClick={() => trackClick(label)}
        size="small"
        variant="tertiary"
      >
        {label}
      </CanopyButton>
      <NonProdHeader nonprodHeaderDetails={nonprodHeaderDetails} />
    </>
  )
}

export default HomeLink
