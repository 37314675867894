import React from "react"
import { Form } from "components/form"
import DatePickerField from "components/form/DatePickerField"
import { isDateValid } from "utilities/date"
import { DmeOrder } from "sharedTypes"

type Props = {
  dischargeDate: string
  updateDischargeDate(date: string): Promise<{ data: DmeOrder }>
  setDmeOrder(dmeOrder: DmeOrder): Promise<void>
}
export default function InternalDischargeDateForm(props: Props) {
  const { dischargeDate, updateDischargeDate, setDmeOrder } = props
  const onSubmit = (values, { setErrors }) => {
    updateDischargeDate(values.dischargeDate)
      .then((response) => setDmeOrder(response.data))
      .catch((error) => {
        if (error && error.response) {
          setErrors(error.response.data.errors)
        } else {
          setErrors({ base: ["something went wrong, please try again"] })
        }
      })
  }

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={{ dischargeDate }}
      validate={({ dischargeDate }) =>
        isDateValid(dischargeDate) ? {} : { dischargeDate: "Invalid date" }
      }
      submitOnChange
    >
      <DatePickerField label="Discharge Date" name="dischargeDate" width="sm" />
    </Form>
  )
}
