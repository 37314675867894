import { get, put, del, post } from "services/api"
import * as urls from "applications/Workflow/urls"
import {
  CarrierAutoUpdateResponse,
  InsuranceCheckResponse,
  InsuranceRank,
  UpdateParams,
} from "./sharedTypes"
import { poll as ajaxPoll } from "utilities/poll"
import { InsurancePolicy } from "sharedTypes"

export const update = ({
  rank,
  memberNumber,
  insuranceProductId,
  carrierName,
}: UpdateParams) => {
  return put(urls.updateInsurancePolicyUrl(), {
    dmeOrderInsurancePolicy: {
      memberNumber,
      carrierName,
      rank,
      insuranceCarrierId: insuranceProductId,
    },
  })
}

export const removePolicy = (policy: InsurancePolicy) => {
  return del(urls.deleteInsurancePolicyUrl(policy.id))
}
export const poll = (rank: InsuranceRank): Promise<boolean> => {
  return new Promise((resolve, reject) => {
    ajaxPoll(() => get(urls.insuranceCheckUrl()), {
      delay: 2000,
      success: ({ policyStatuses }: InsuranceCheckResponse) => {
        resolve(policyStatuses[rank].active)
      },
      failure: reject,
      error: reject,
    })
  })
}
export const carrierSearch = (carrierName: string) => {
  return get(`${urls.searchInsuranceUrl()}?search=${carrierName}`)
}

export const predict = (memberNumber: string, rank: InsuranceRank) => {
  return get(
    `${urls.insuranceCarrierPredictionsUrl()}?member_number=${memberNumber}&rank=${rank}`
  )
}

export const updateAuthorizationNumber = (params) => {
  return put(urls.dmeOrderCarrierAuthorizationsUrl(), params)
}

export const getCarrierAuthorizations = (query) => {
  return get(`${urls.workflowCarrierAuthorizationsUrl()}?query=${query}`)
}

export const autoUpdateCarrier = (
  rank: InsuranceRank
): Promise<CarrierAutoUpdateResponse> => {
  return post(urls.insuranceAutoUpdateCarrierUrl(), { rank }).then(
    ({ data }) => data
  )
}
