// @ts-strict-ignore
import React, { useCallback, useMemo } from "react"
import CategorySelectorMobile from "./CategorySelectorMobile"
import { Props } from "./sharedTypes"
import CategorySelectorDesktop from "./CategorySelectorDesktop"
import { Category, CategoryWithSubcategories } from "../../sharedTypes"

const CategorySelector: React.FunctionComponent<Props> = (props) => {
  const { availableCategories, categories } = props

  const findCategory = (
    categories: CategoryWithSubcategories[] | Category[],
    categoryId: number
  ) => categories.find((otherCategory) => otherCategory.id === categoryId)

  const isCategoryDisabled = useCallback(
    (categoryId: number) => !findCategory(availableCategories, categoryId),
    [availableCategories]
  )

  const isSubcategoryDisabled = useCallback(
    (parentCategoryId: number, categoryId: number) => {
      const parentCategory = findCategory(
        availableCategories,
        parentCategoryId
      ) as CategoryWithSubcategories
      return (
        !parentCategory ||
        !findCategory(parentCategory.subcategories, categoryId)
      )
    },
    [availableCategories]
  )

  const categoryToOption = useCallback(
    ({ name, id, subcategories }) => ({
      label: name,
      value: id,
      disabled: isCategoryDisabled(id),
      suboptions:
        subcategories.length <= 1
          ? []
          : subcategories.map((subcategory) => ({
              label: subcategory.name,
              value: subcategory.id,
              disabled: isSubcategoryDisabled(id, subcategory.id),
            })),
    }),
    [isCategoryDisabled, isSubcategoryDisabled]
  )

  const categoryOptions = useMemo(() => {
    const defaultCategory = {
      label: "All Categories",
      value: null,
      disabled: false,
    }
    return [defaultCategory].concat(categories.map(categoryToOption))
  }, [categories, categoryToOption])

  return (
    <div>
      <CategorySelectorDesktop {...props} options={categoryOptions} />
      <CategorySelectorMobile
        {...props}
        header="Category"
        options={categoryOptions}
      />
    </div>
  )
}

export default CategorySelector
