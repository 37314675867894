import { get, post } from "../../../../services/api"
import { employerPrefix } from "utilities/url"
import { DoctorSignatureRequest, signatureStatus } from "../../api"

export const getPendingSignatureRequests = async (
  doctorId: string
): Promise<DoctorSignatureRequest[]> => {
  const url = `${employerPrefix(
    "Doctor",
    doctorId
  )}/signature_requests.json?status=${signatureStatus.REVIEWED}`
  return get(url).then(({ data }) => {
    data.results.forEach((s: DoctorSignatureRequest) => (s.doctorId = doctorId))
    return data.results
  })
}

type ReviewedRequestActionables = {
  orderIds: string[]
  signingSignatureRequestIds: string[]
}

export type Approvals = ReviewedRequestActionables

export type RemoveReviews = ReviewedRequestActionables

export const bulkSignSignatureRequests = (
  doctorId: string,
  selectedSignatureRequestIds: Approvals
) => {
  const url = `${employerPrefix("Doctor", doctorId)}/approvals.json`
  return post(url, {
    approvals: selectedSignatureRequestIds,
    signatureType: "onscreen",
  })
}

export const bulkRemoveSignatureRequestReviews = (
  doctorId: string,
  selectedSignatureRequestIds: Approvals
) => {
  const url = `${employerPrefix("Doctor", doctorId)}/remove_reviews.json`
  return post(url, {
    remove_reviews: selectedSignatureRequestIds,
  })
}
