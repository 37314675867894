import React, { useState } from "react"
import Modal from "components/Modal"
import Overlay from "components/Overlay"
import withInitialData from "components/withInitialData"
import {
  filterLineItemGroupCustomAttributeConfigurations,
  selectLineItemGroupCustomAttributeConfiguration,
} from "./api"
import { ConfigData } from "./sharedTypes"
import InternalPatientPreferenceConfiguration from "./components/InternalPatientPreferenceConfiguration"

type Props = {
  initialData: ConfigData
  lineItemGroupId: string
  dismiss(): void
}

const PatientPreferenceConfiguration = ({
  initialData,
  lineItemGroupId,
  dismiss,
}: Props) => {
  const [configData, setConfigData] = useState(initialData)
  const [loading, setLoading] = useState(false)

  const filter = (customAttributeOptionIds) => {
    setLoading(true)
    return filterLineItemGroupCustomAttributeConfigurations(
      lineItemGroupId,
      customAttributeOptionIds
    ).then(({ data }) => {
      setConfigData(data)
      setLoading(false)
    })
  }

  const select = (matchingCustomAttributeConfigurationId) => {
    setLoading(true)
    selectLineItemGroupCustomAttributeConfiguration(
      lineItemGroupId,
      matchingCustomAttributeConfigurationId
    ).then(dismiss)
  }

  return (
    <Modal title="Product Configuration" show cancel={dismiss}>
      <Overlay active={loading} showSpinner>
        <Modal.Body>
          <InternalPatientPreferenceConfiguration
            configData={configData}
            select={select}
            filter={filter}
          />
        </Modal.Body>
      </Overlay>
    </Modal>
  )
}

const fetchInitialData = ({ lineItemGroupId }) =>
  filterLineItemGroupCustomAttributeConfigurations(lineItemGroupId, []).then(
    ({ data }) => data
  )
export default withInitialData(fetchInitialData)(PatientPreferenceConfiguration)
