import { Nullable } from "sharedTypes"

import { isNullOrUndefined } from "../../../utilities/isNullOrUndefined"

interface LineItemGroup {
  lineItems: {
    formularyPrice?: Nullable<string>
  }[]
}
export const formularyUnitPrice = (
  lineItemGroup: LineItemGroup
): Nullable<number> => {
  if (lineItemGroup.lineItems.length !== 1) return null

  const price = lineItemGroup.lineItems[0].formularyPrice
  if (price === undefined || price === null) return null

  return parseFloat(price)
}

function someLineItemsMissingPrice(lineItemGroup: LineItemGroup) {
  const prices = lineItemGroup.lineItems.map(
    (lineItem) => lineItem.formularyPrice
  )
  return prices.some((price) => isNullOrUndefined(price))
}

export const hasMissingPrices = (lineItemGroups: LineItemGroup[]): boolean => {
  const groupsMissingPrices = lineItemGroups.filter((lineItemGroup) => {
    if (lineItemGroup.lineItems.length <= 1) {
      return !formularyUnitPrice(lineItemGroup)
    } else {
      return someLineItemsMissingPrice(lineItemGroup)
    }
  })
  return groupsMissingPrices.length !== 0
}
