import React from "react"
import { Form, NumberField } from "components/form"
import * as styles from "./CartLineItemGroup.module.scss"

interface Props {
  quantity: number
  update(params: { quantity: number }): Promise<void>
}

const QuantityForm: React.FC<Props> = ({ quantity, update }) => {
  const initialValues = { quantity: quantity }
  return (
    <Form
      enableReinitialize
      onSubmit={(values, { setErrors }) =>
        update(values).catch((errors) => setErrors(errors))
      }
      initialValues={initialValues}
    >
      {({ isSubmitting }) => (
        <NumberField
          name="quantity"
          min={1}
          max={10000}
          submitOnBlur
          disabled={isSubmitting}
          className={styles.quantity}
        />
      )}
    </Form>
  )
}

export default QuantityForm
