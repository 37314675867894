import React from "react"
import { DATE_FORMAT } from "utilities/date"
import classNames from "classnames"
import { blurOnEnter } from "utilities/browser/event"
import MaskedInput, { DATE_MASK } from "components/MaskedInput"

type Props = {
  name?: string
  id?: string
  value?: string
  disabled?: boolean
  className?: string
  autoFocus?: boolean
  onChange?(event: React.FormEvent): void
}

const DateInput = ({
  name,
  id,
  value,
  disabled,
  className,
  autoFocus,
  onChange,
}: Props) => {
  return (
    <MaskedInput
      name={name}
      id={id}
      value={value}
      disabled={disabled}
      className={classNames("form-control", className)}
      onChange={onChange}
      mask={DATE_MASK}
      placeholder={DATE_FORMAT}
      onKeyUp={blurOnEnter}
      autoFocus={autoFocus}
      inputMode="numeric"
    />
  )
}

export default DateInput
