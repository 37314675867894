import { employerPrefix as employerPrefixFromUrl } from "utilities/url"

export function dmeOrderUrl(dmeOrderId, employerPrefix?) {
  if (!employerPrefix) employerPrefix = employerPrefixFromUrl()
  return `${employerPrefix}/orders/${dmeOrderId}/dme_order.json`
}

export function updateFulfillmentUrl(dmeOrderId, id, employerPrefix?) {
  if (!employerPrefix) employerPrefix = employerPrefixFromUrl()
  return `${employerPrefix}/dashboard/dme_orders/${dmeOrderId}/delivery_statuses/${id}.json`
}
