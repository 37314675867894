// @ts-strict-ignore
import React, { useCallback, useContext, useState } from "react"
import { useDebounce } from "hooks/useDebounce"
import { ActionType, ViewContext } from "../view/viewContext"
import { handleError } from "../../../utilities/error"
import * as api from "../api"
import { OrderSearchParams } from "../api"
import tableColumnDefinitions, {
  filterTableColumnDefinitions,
} from "./tableColumnDefinitions"
import TableOverviewPresentation from "../../../components/TableOverviewPresentation"
import Table from "./Table"
import BackToTop from "../../../components/BackToTop"
import { InfiniteLoadTable } from "../../../components/Table/InfiniteLoadTable"
import SupplierOrganizationContext from "../SupplierOrganizationContext"

const sortingByText = (sortValue) => {
  if (sortValue) {
    const tableColumn = tableColumnDefinitions.find(
      (tc) => tc.value === sortValue
    )
    return tableColumn.title
  }
}
export const OrdersTable = () => {
  const [currentCount, setCurrentCount] = useState(0)
  const [loading, setLoading] = useState(true)
  const { state: viewContext, dispatch } = useContext(ViewContext)
  const currentView = viewContext.currentView
  const { supplierOrganizationId } = useContext(SupplierOrganizationContext)

  const getOrders = (orderSearchParams: OrderSearchParams, page: number) =>
    api
      .getOrders(supplierOrganizationId, orderSearchParams, page)
      .then((response) => {
        setCurrentCount(response.currentCount)
        setLoading(false)
        return {
          records: response.orders.map((o) => ({ ...o, key: o.id })),
          totalCount: response.currentCount,
          hasNextPage: response.hasNextPage,
        }
      })
      .catch((error) => {
        setLoading(false)
        handleError(error)
      })

  const debouncedGetOrders = useDebounce(getOrders)
  const loadOrders = useCallback(
    (newPage) => debouncedGetOrders(currentView, newPage),
    [currentView, debouncedGetOrders]
  )

  const onSortChange = async (sortInfo) =>
    dispatch({
      type: ActionType.UPDATE_SORT,
      data: {
        sortColumn: sortInfo.sort,
        sortDirection: sortInfo.direction,
      },
    })

  const tableColumns = filterTableColumnDefinitions(currentView.columnSettings)
  return (
    <>
      <TableOverviewPresentation
        results={`${loading ? "x" : currentCount} orders`}
        sortingBy={sortingByText(currentView.sortColumn)}
      />
      <InfiniteLoadTable
        loadPage={loadOrders}
        Table={Table}
        handleLoadingIndicators={false}
        tableProps={{
          loading: loading,
          onSortChange: onSortChange,
          sortColumn: currentView.sortColumn,
          sortDirection: currentView.sortDirection,
          tableColumns: tableColumns,
        }}
      />
      <BackToTop />
    </>
  )
}
