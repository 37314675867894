export const MAX_FILE_SIZE_IN_MB = 10
export const ONE_MB_IN_BYTES = 1048576
export const MAX_FILE_SIZE_IN_BYTES = MAX_FILE_SIZE_IN_MB * ONE_MB_IN_BYTES

import { pluralize } from "utilities/string"
import { toSentence } from "utilities/array"

export function isFileSizeAboveLimit(size: number): boolean {
  return size > MAX_FILE_SIZE_IN_BYTES
}

export function getFilesValidationMessage(files: File[]): string {
  if (files.length) {
    const tooLargeFileNames = files
      .filter((file) => isFileSizeAboveLimit(file.size))
      .map((file) => file.name)
    if (tooLargeFileNames.length > 0) {
      return `File ${pluralize(
        "size",
        tooLargeFileNames.length
      )} of ${toSentence(tooLargeFileNames)} ${
        tooLargeFileNames.length > 1 ? "are" : "is"
      } too large. Can not be greater than ${MAX_FILE_SIZE_IN_MB}MB`
    }
  }
  return ""
}
