// @ts-strict-ignore
import React, { useEffect, useState } from "react"
import { Form, RadioButtons } from "components/form"
import ContinueButton from "applications/Workflow/components/ContinueButton"
import ClinicianSelect from "./ClinicianSelect"
import SignatureChannelSelector from "./SignatureChannelSelector"
import { DmeOrder, Doctor, SignatureType } from "sharedTypes"
import {
  CernerSignatureChannel,
  DoctorContactAttributes,
  EpicSignatureChannel,
  SignableRequirement,
} from "../sharedTypes"
import cx from "classnames"

enum SelfSignRadioStatus {
  SelfSign,
  Other,
  Neither,
}

type Props = {
  allowFaxPacketDownload: boolean
  dmeOrder: DmeOrder
  nextPagePath?: string
  isPageChecked?: boolean
  selectDoctor(id: string): Promise<any>
  doctorSearch({ value }): Promise<Doctor[]>
  createDoctorContact(attributes: DoctorContactAttributes): Promise<any>
  updateSignatureType(signatureType: SignatureType): Promise<any>
  updateSignatureChannel(channel: string, isSelected: boolean): Promise<any>
  showSignatureChannelSelector: boolean
  suggestedDoctors: Doctor[]
  epicSignatureChannel: EpicSignatureChannel
  cernerSignatureChannel: CernerSignatureChannel
  selfSignDoctorId?: string
  signableRequirements: SignableRequirement[]
  verifiedChannels: string[]
  signOnScreenEnabled: boolean
}

const InternalSignature: React.FC<Props> = ({
  dmeOrder,
  selectDoctor,
  epicSignatureChannel,
  cernerSignatureChannel,
  updateSignatureChannel,
  updateSignatureType,
  createDoctorContact,
  allowFaxPacketDownload,
  signableRequirements,
  verifiedChannels,
  doctorSearch,
  nextPagePath,
  isPageChecked,
  selfSignDoctorId,
  showSignatureChannelSelector,
  suggestedDoctors,
  signOnScreenEnabled,
}: Props) => {
  const [signatureType, setSignatureType] = useState(dmeOrder.signatureType)
  const [isChangingSignatureType, setIsChangingSignatureType] = useState(false)
  const [selfSignDirty, setSelfSignDirty] = useState(false)

  useEffect(() => {
    setSignatureType(dmeOrder.signatureType)
  }, [dmeOrder])

  const callSelectDoctor = (doctorId: string): Promise<void> => {
    setIsChangingSignatureType(true)
    return selectDoctor(doctorId)
      .then(() => {
        setIsChangingSignatureType(false)
      })
      .catch(() => {
        setIsChangingSignatureType(false)
      })
  }

  const isEpicEnabledForDoctor = () => {
    return !!epicSignatureChannel.enabledForOrderingDoctor
  }

  const isCernerEnabledForDoctor = () => {
    return !!cernerSignatureChannel.enabledForOrderingDoctor
  }

  const hasSignatureMethod = () => {
    const channels = dmeOrder.signatureChannels
    if (isChangingSignatureType) {
      return false
    }

    switch (signatureType) {
      case SignatureType.Digital:
        return (
          channels.email ||
          channels.sms ||
          channels.fax ||
          channels.epic ||
          channels.cerner
        )
      case SignatureType.Manual:
      case SignatureType.OnScreen:
        return true
    }
  }

  const callUpdateSignatureType = (signatureTypeArg, channels = {}) => {
    setIsChangingSignatureType(true)
    updateSignatureType(signatureTypeArg).then(() => {
      Object.entries(channels).forEach(([key, value]) =>
        updateSignatureChannel(key, !!value)
      )
      setIsChangingSignatureType(false)
    })
    setSignatureType(signatureTypeArg)
  }

  const renderSignatureRequestMethods = () => {
    return (
      <SignatureChannelSelector
        allowFaxPacketDownload={allowFaxPacketDownload}
        createDoctorContact={createDoctorContact}
        isChangingSignatureType={isChangingSignatureType}
        signatureType={signatureType}
        isEpicEnabledForDoctor={isEpicEnabledForDoctor()}
        isCernerEnabledForDoctor={isCernerEnabledForDoctor()}
        dmeOrder={dmeOrder}
        updateSignatureChannel={updateSignatureChannel}
        setSignatureType={callUpdateSignatureType}
        verifiedChannels={verifiedChannels}
        signableRequirements={signableRequirements}
        signOnScreenEnabled={signOnScreenEnabled}
      />
    )
  }

  const selfSignRadioStatus = () => {
    if (dmeOrder.doctor && dmeOrder.doctor.doctorId === selfSignDoctorId) {
      return SelfSignRadioStatus.SelfSign
    }
    if (dmeOrder.doctor || selfSignDirty || !selfSignDoctorId) {
      return SelfSignRadioStatus.Other
    }
    return SelfSignRadioStatus.Neither
  }

  const renderSelfSignForm = () => {
    const selfSignInitialValues = {
      selfSign: selfSignRadioStatus(),
    }
    const updateSelfSign = ({ selfSign }) => {
      if (selfSign === SelfSignRadioStatus.SelfSign) {
        callSelectDoctor(selfSignDoctorId).then(() => {
          setSelfSignDirty(true)
        })
      } else {
        callSelectDoctor(null).then(() => {
          setSelfSignDirty(true)
        })
      }
    }
    return (
      <Form
        enableReinitialize
        onSubmit={updateSelfSign}
        initialValues={selfSignInitialValues}
        submitOnChange
      >
        <RadioButtons
          name="selfSign"
          options={[
            { label: "I will sign", value: SelfSignRadioStatus.SelfSign },
            {
              label: "Another clinician will sign",
              value: SelfSignRadioStatus.Other,
            },
          ]}
        />
      </Form>
    )
  }

  const doctorSelector = () => {
    const { doctor } = dmeOrder
    const showDoctorSelector =
      !selfSignDoctorId || selfSignRadioStatus() === SelfSignRadioStatus.Other
    const isContinueButtonVisible =
      (selfSignRadioStatus() === SelfSignRadioStatus.SelfSign ||
        (doctor && hasSignatureMethod()) ||
        !showSignatureChannelSelector) &&
      nextPagePath
    return (
      <>
        <div className={cx("well", "wide-fixed-row")}>
          {selfSignDoctorId && renderSelfSignForm()}

          {showDoctorSelector && (
            <ClinicianSelect
              dmeOrder={dmeOrder}
              doctorSearch={doctorSearch}
              selectDoctor={callSelectDoctor}
              suggestedDoctors={suggestedDoctors}
            />
          )}

          {showDoctorSelector &&
            doctor &&
            showSignatureChannelSelector &&
            renderSignatureRequestMethods()}
        </div>
        {isContinueButtonVisible && (
          <div className={cx("wide-fixed-row", "text-right")}>
            <ContinueButton
              isButtonOutlined={!isPageChecked}
              path={nextPagePath}
            />
          </div>
        )}
      </>
    )
  }

  return doctorSelector()
}

export default InternalSignature
