import * as React from "react"
import { Tab, Tabs } from "components/Tabs"
import { LibraryAddCheck, SwapHorizontalCircle } from "@material-ui/icons"
import classNames from "classnames"
import { EventCategory, trackEvent } from "utilities/tracking"

type Props = {
  setSelectedTab(tab: SelectedTab): void
  selectedTab: SelectedTab
}

export enum SelectedTab {
  Select = "Select",
  Transfer = "Transfer",
}

export const isOnTab = (
  selectedTab: SelectedTab,
  desiredTab: SelectedTab
): boolean => selectedTab === desiredTab

const ReassignSalesRepsTabs: React.FC<Props> = ({
  setSelectedTab,
  selectedTab,
}: Props) => {
  return (
    <Tabs className={classNames("canopy-mbe-8x canopy-mbs-8x bg-transparent")}>
      <Tab
        onClick={() => setSelectedTab(SelectedTab.Select)}
        selected={isOnTab(selectedTab, SelectedTab.Select)}
      >
        <LibraryAddCheck style={{ verticalAlign: "middle" }} />
        <span className={classNames("canopy-p-8x v-align-middle")}>Select</span>
      </Tab>
      <Tab
        selected={isOnTab(selectedTab, SelectedTab.Transfer)}
        onClick={() => {
          void trackEvent(
            EventCategory.TransferSalesRep,
            "transfer_sales_rep_clicked"
          )
          setSelectedTab(SelectedTab.Transfer)
        }}
      >
        <SwapHorizontalCircle style={{ verticalAlign: "middle" }} />
        <span className={classNames("canopy-p-8x v-align-middle")}>
          Transfer
        </span>
      </Tab>
    </Tabs>
  )
}

export default ReassignSalesRepsTabs
