import React, { useContext } from "react"
import InternalDashboardFilters from "./InternalDashboardFilters"
import filterConfigs from "../../filterConfigs"
import { ActionType, ViewContext } from "../../view/viewContext"

const DashboardFilters = () => {
  const { state: viewState, dispatch } = useContext(ViewContext)
  const updateFilterValues = async (updatedFilterValues) =>
    dispatch({
      type: ActionType.UPDATE_FILTER_VALUES,
      data: updatedFilterValues,
    })

  const { currentView, filterOptions, dirtyFilters } = viewState
  const onQueryChange = (value: string) => {
    dispatch({
      type: ActionType.UPDATE_FILTER_VALUES,
      data: { query: value },
    })
  }

  return (
    <InternalDashboardFilters
      filterConfigs={filterConfigs}
      currentView={currentView}
      filterOptions={filterOptions}
      onQueryChange={onQueryChange}
      onChange={updateFilterValues}
      dirtyFilters={dirtyFilters}
    />
  )
}

export default DashboardFilters
