import React from "react"
import Card from "./Card"
import { DmeOrder } from "sharedTypes"
import DataRow from "components/DataRow"
import { fullName } from "utilities/person"
import { formatHeight } from "utilities/conversion"
import { capitalize } from "utilities/string"

type Props = {
  dmeOrder: DmeOrder
}

const PatientCard = (props: Props) => {
  const { dmeOrder } = props
  const {
    clinicalFacility,
    patient,
    patientSnapshot,
    useFeetForHeight,
  } = dmeOrder
  const { heightInches, weightLbs } = patientSnapshot
  const height = heightInches && formatHeight(useFeetForHeight, heightInches)
  const weight = weightLbs && `${weightLbs} lbs`
  const sex = capitalize(
    patient.sex === "unknown" ? patient.sex : patient.sex[0]
  )

  return (
    <Card title="Patient" summary={fullName(patient)}>
      <DataRow label="Name" value={`${fullName(patient)} (${sex})`} />
      <DataRow label="Date of Birth" value={patient.dateOfBirth} />
      <DataRow
        label="Height / Weight"
        value={[height, weight].filter(Boolean).join(", ")}
      />
      <DataRow label="Facility">{clinicalFacility.name}</DataRow>
      {dmeOrder.discharge && (
        <DataRow label="Discharge Date" value={dmeOrder.dischargeDate} />
      )}
    </Card>
  )
}

export default PatientCard
