import React from "react"
import { highlight } from "utilities/select"
import { timestampInWords } from "utilities/date"
import { OptionItem } from "@parachutehealth/canopy-combobox-field"
import * as styles from "../index.module.scss"

type Props = {
  option: OptionItem & { updatedAt: string }
  searchQuery: string
}

const CARRIER_AUTHORIZATION_TIME_FORMAT = "M/D/YYYY ∙ h:mm A"

function AuthorizationSuggestion({ option, searchQuery }: Props) {
  const updatedAt = timestampInWords(
    option.updatedAt,
    CARRIER_AUTHORIZATION_TIME_FORMAT
  )

  return (
    <div className={styles.authorizationSuggestion} key={option.key}>
      {highlight(option.label || "", searchQuery)}
      <span className={styles.updatedAt}>{updatedAt}</span>
    </div>
  )
}

export default AuthorizationSuggestion
