const EVENTS = (window.EVENTS = window.EVENTS || {})

export default {
  on(event, callback) {
    if (!EVENTS[event]) {
      EVENTS[event] = []
    }
    EVENTS[event].push(callback)
  },
  off(event, callback?) {
    if (EVENTS[event] && callback) {
      const index = EVENTS[event].indexOf(callback)
      if (index >= 0) {
        EVENTS[event].splice(index, 1)
      }
    } else {
      EVENTS[event] = []
    }
  },
  emit(event, ...params) {
    if (EVENTS[event]) {
      EVENTS[event].forEach((element) => {
        element.apply(this, params)
      })
    }
  },
}
