// @ts-strict-ignore
import { del, get, post, put } from "services/api"
import * as urls from "./urls"
import { SendBackRecipient } from "../Workflow/containers/Review/components/SupplierSendBack/sharedTypes"
import {
  DashboardViewConfiguration,
  SupplierOrganizationDashboardBaseDmeOrder,
} from "./sharedTypes"
import { FilterValues } from "./components/DashboardFilters/sharedTypes"
import { SortDirection } from "../../sharedTypes"

export const getInitialValues = (supplierOrganizationId: string) =>
  get(urls.initialValuesUrl(supplierOrganizationId))

export interface OrderSearchParams {
  filterValues: FilterValues
  sortColumn?: string
  sortDirection?: SortDirection
}

export const getOrders = (
  supplierOrganizationId: string,
  params: OrderSearchParams,
  page: number
) => {
  const { filterValues, sortColumn, sortDirection } = params
  return get(urls.ordersUrl(supplierOrganizationId), {
    ...filterValues,
    sortColumn,
    sortDirection,
    page,
  }).then(({ data }) => data)
}
export const updateAssignee = (supplierId: string) => (dmeOrderId, params) => {
  return post(
    `/u/s/${supplierId}/dashboard/dme_orders/${dmeOrderId}/assignees.json`,
    { dmeOrder: params }
  ).then(({ data }) => data)
}

export const deleteAssignee = (supplierId: string) => (dmeOrderId) => {
  return del(
    `/u/s/${supplierId}/dashboard/dme_orders/${dmeOrderId}/assignees.json`
  ).then(({ data }) => data)
}
export const fetchAssignees = (supplierId: string) => (term) => {
  return get(`/u/s/${supplierId}/assignees.json`, { term }).then(
    ({ data }) => data
  )
}

export const updateResolution = (
  supplierId,
  dmeOrderId,
  dmeOrdeEventId,
  resolved
) => {
  return post(
    urls.eventResolutionsUrl(supplierId, dmeOrderId, dmeOrdeEventId),
    { resolved }
  ).then(({ data }) => data)
}

export const getOrderEvents = (supplierId, dmeOrderId) => {
  return get(urls.orderEventsUrl(supplierId, dmeOrderId)).then(
    ({ data }) => data
  )
}

export const searchSupplierFollowables = (
  dmeOrder: SupplierOrganizationDashboardBaseDmeOrder,
  term: string
): Promise<SendBackRecipient[]> => {
  return get(urls.followUpOwnerUrl(dmeOrder), { term }).then(({ data }) => data)
}

export const updateFollowUpOwner = (
  dmeOrder: SupplierOrganizationDashboardBaseDmeOrder,
  followUpOwner: string
) => {
  return put(urls.assignFollowUpOwnerUrl(dmeOrder), { followUpOwner }).then(
    ({ data }) => data
  )
}

type UpdateDashboardViewParams = Omit<
  DashboardViewConfiguration,
  "filterValues"
>
export const updateDashboardView = (
  supplierOrganizationId: string,
  id: string,
  dashboardView: UpdateDashboardViewParams
) => {
  return put(urls.dashboardViewUrl(supplierOrganizationId, id), {
    dashboardView,
  }).then(({ data }) => data)
}

export const fetchUploads = async ({ page }: { page: number }) => {
  return get(urls.uploadsUrl(), { page }).then(({ data }) => data)
}
