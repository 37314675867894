import { CanopyIcon } from "@parachutehealth/canopy-icon"
import React, { useState } from "react"
import { Form } from "../../../../components/form"
import FileHandler from "../../../../utilities/FileHandler"
import { VALIDATION_ERRORS } from "./errorStrings"
import { getFilesValidationMessage } from "../../../../utilities/fileValidation"
import { FormikValues } from "formik"
import { CanopyButton } from "@parachutehealth/canopy-button"

interface CSVUploadFormProps {
  submitCb(value: any): void
  closeCb(): void
  fileFieldName?: string
  requiredCsvColumns: string[]
}

interface SelectedFileProps {
  onUnselect(setFieldValue): void
  file: File | null
  fileField: string
}

const DEFAULT_FILE_FIELD = "file"

const SelectedFile = ({ file, fileField, onUnselect }: SelectedFileProps) => {
  if (!file) {
    return (
      <div className="text-center">
        <label
          className="file-label btn btn-sm btn-brand-o"
          htmlFor={`${fileField}-file`}
        >
          Select CSV to upload
        </label>
      </div>
    )
  }
  return (
    <div className="d-flex canopy-mx-2x">
      <div>
        <i className="far fa-file-alt canopy-mie-8x" />
      </div>
      <span>{file.name}</span>
      <div
        role="button"
        className="canopy-mis-auto canopy-p-8x m-n3"
        onClick={onUnselect}
      >
        <CanopyIcon name="xmark" size="medium" data-testid="close-btn" />
      </div>
    </div>
  )
}

export const CSVUploadForm = ({
  submitCb,
  closeCb,
  requiredCsvColumns,
  fileFieldName,
}: CSVUploadFormProps) => {
  const [submitting, setSubmitting] = useState<boolean>(false)
  const fileField = fileFieldName || DEFAULT_FILE_FIELD

  const checkMissingOrUnorderedColumns = (headers: string[]): string[] => {
    const missingCols: string[] = []
    const unrecognizedColumns: string[] = []
    const errors: string[] = []

    requiredCsvColumns.forEach((col, index) => {
      if (!headers.includes(col)) {
        missingCols.push(col)
      } else if (headers[index] !== col) {
        errors.push(`Column "${col}" should be in position ${index + 1}`)
      }
    })

    headers.forEach((header) => {
      if (!requiredCsvColumns.includes(header)) {
        unrecognizedColumns.push(header)
      }
    })
    if (unrecognizedColumns.length) {
      errors.unshift(
        `Unrecognized column(s): ${unrecognizedColumns.join(", ")}`
      )
    }
    if (missingCols.length) {
      errors.unshift(`Missing column(s): ${missingCols.join(", ")}`)
    }

    return errors
  }

  const validate = async (values) => {
    if (!values[fileField]) {
      return { [fileField]: ["must select a file"] }
    }

    const file = values[fileField] as File
    const fileHandler = new FileHandler(file)

    if (fileHandler.size() === 0) {
      return {
        [fileField]: [VALIDATION_ERRORS.EMPTY_FILE],
      }
    }
    const message = getFilesValidationMessage([file])
    if (message) {
      return { [fileField]: [message] }
    }

    const fileLines = await fileHandler.getLines()
    if (fileLines && (fileLines.length < 2 || fileLines[1].length === 0)) {
      return {
        [fileField]: [VALIDATION_ERRORS.EMPTY_FILE],
      }
    }

    const fileHeaderLine = await fileHandler.firstLine()
    const headers = fileHeaderLine.split(",").map((str) => str.trim())
    const headerErrors = checkMissingOrUnorderedColumns(headers)
    const fileErrors: string[] = []

    if (headerErrors.length) {
      fileErrors.push(...headerErrors)
    }
    return fileErrors.length ? { [fileField]: fileErrors } : {}
  }

  const onSubmit = async (values: FormikValues) => {
    setSubmitting(true)
    try {
      await submitCb(values[fileField])
    } catch (e) {
      // Error handled server-side. Need catch so UI doesn't fail
    }
  }

  return (
    <>
      <Form
        onSubmit={onSubmit}
        validate={validate}
        initialValues={{ [fileField]: null }}
        className="canopy-mbs-4x"
      >
        {({ setFieldValue, values, errors }) => {
          const hasErrors = values[fileField] && Object.keys(errors).length > 0
          return (
            <>
              <div className="well canopy-p-12x canopy-mbe-8x bg-lightest-gray">
                <input
                  className="d-none"
                  type="file"
                  name={fileField}
                  id={`${fileField}-file`}
                  accept=".csv"
                  disabled={submitting}
                  onChange={(e) =>
                    setFieldValue(
                      fileField,
                      e?.target?.files ? e?.target?.files[0] : null
                    )
                  }
                />
                <SelectedFile
                  onUnselect={() => setFieldValue(fileField, null)}
                  file={values[fileField]}
                  fileField={fileField}
                />
              </div>
              <hr />
              {hasErrors && (
                <div role="alert" aria-live="polite" className="color-danger">
                  {errors[fileField] &&
                    errors[fileField].map((message) => (
                      <p key={message}>{message} </p>
                    ))}
                </div>
              )}
              <div className="text-right">
                <CanopyButton
                  variant="secondary"
                  className="canopy-mie-4x"
                  onClick={closeCb}
                >
                  Cancel
                </CanopyButton>
                <CanopyButton
                  variant="primary"
                  type="submit"
                  disabled={!values[fileField] || submitting || hasErrors}
                >
                  Upload
                </CanopyButton>
              </div>
            </>
          )
        }}
      </Form>
    </>
  )
}
