import React from "react"
import { Label } from "../utilities/filters"

type Props = {
  viewFilter: string
}

const EmptyMessage = ({ viewFilter }: Props) => {
  const header =
    {
      [Label.InternalComments]: "There are no internal comments on this order.",
      [Label.GlobalComments]: "There are no global comments on this order.",
    }[viewFilter] || "There are no comments on this order."

  return (
    <div className="text-center">
      <h3>{header}</h3>
    </div>
  )
}

export default EmptyMessage
