// @ts-strict-ignore
import React from "react"
import Select from "components/form/Select"
import { states } from "utilities/address"

interface Props {
  label?: string
  name?: string
}

function StateSelect({ label, name }: Props) {
  const stateOptions = states.map((state) => ({
    label: state[0],
    value: state[1],
  }))
  return <Select label={label} name={name} options={stateOptions} />
}

StateSelect.defaultProps = {
  label: "State",
  name: "state",
}

export default StateSelect
