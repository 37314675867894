// @ts-strict-ignore
import React from "react"
import Breadcrumb, { BreadcrumbItem } from "components/Breadcrumb"
import UserForm from "../UserForm"
import { User, Employment } from "sharedTypes"
import { fullName } from "utilities/person"
import { usersUrl, userUrl } from "../urls"

interface Props {
  defaultTimeZone: string
  user: User
  employments: Employment[]
  createUser(params): Promise<void>
  updateUser(params): Promise<void>
}

interface InternalUserFormProps {
  header: string
  defaultTimeZone: string
  user: User
  employments: Employment[]
  emailDisabled?: boolean
  onSubmit(params): Promise<void>
  breadcrumbItems: BreadcrumbItem[]
}

function InternalUserForm({
  header,
  breadcrumbItems,
  user,
  defaultTimeZone,
  emailDisabled,
  employments,
  onSubmit,
}: InternalUserFormProps) {
  return (
    <>
      <h1>{header}</h1>
      <Breadcrumb items={breadcrumbItems} />
      <UserForm
        user={user}
        defaultTimeZone={defaultTimeZone}
        emailDisabled={emailDisabled}
        employments={employments}
        onSubmit={onSubmit}
      />
    </>
  )
}

function InternalSupplierOrganizationUserForm({
  user,
  defaultTimeZone,
  employments,
  createUser,
  updateUser,
}: Props) {
  const isNew = !user.userId
  if (isNew) {
    return (
      <InternalUserForm
        header="Add User"
        breadcrumbItems={[{ text: "Users", href: usersUrl() }, { text: "Add" }]}
        user={user}
        defaultTimeZone={defaultTimeZone}
        emailDisabled={false}
        employments={employments}
        onSubmit={createUser}
      />
    )
  }

  return (
    <InternalUserForm
      header="Edit User"
      breadcrumbItems={[
        { text: "Users", href: usersUrl() },
        { text: fullName(user), href: userUrl(user.userId) },
        { text: "Edit" },
      ]}
      user={user}
      defaultTimeZone={defaultTimeZone}
      emailDisabled
      employments={employments}
      onSubmit={updateUser}
    />
  )
}

export default InternalSupplierOrganizationUserForm
