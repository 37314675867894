// @team @facilitators
// @ts-strict-ignore
import React, { useContext, useEffect, useState } from "react"
import { ServiceTier, Supplier } from "sharedTypes"
import { PackageConfiguration } from "../../sharedTypes"
import { BasicSupplierHelpUrl } from "applications/Workflow/urls/external"
import { EventCategory, trackEvent } from "utilities/tracking"
import { fetchSuppliers } from "./api"
import PreferredSupplierSelector from "./PreferredSupplierSelector"
import YourOrganizationsSuppliers from "./YourOrganizationsSuppliers"
import OtherSuppliersOnParachute from "./OtherSuppliersOnParachute"
import { PreferredSupplierContext } from "../../hooks/usePreferredSupplier"

type Props = {
  packageConfiguration: PackageConfiguration
  redirectToPreferredSupplier: boolean
  yourOrganizationsSuppliersOnly: boolean
  selectedServiceAreaState: string
  flexibleSupplierAccess: boolean
  loading: boolean
  setLoading: (loading: boolean) => void
  manageFulfillmentAgreements: boolean
  showServiceTier: boolean
}

const SearchByProductSupplierSelector: React.FC<Props> = ({
  packageConfiguration,
  yourOrganizationsSuppliersOnly,
  selectedServiceAreaState,
  flexibleSupplierAccess,
  loading,
  setLoading,
  manageFulfillmentAgreements,
  showServiceTier,
}) => {
  const { preferredSuppliers } = useContext(PreferredSupplierContext)

  const { availableSuppliers, supplier } = packageConfiguration
  const selectedSupplier =
    supplier &&
    availableSuppliers.find((s) => s.externalId === supplier.externalId)

  const [yourOrganizationsSuppliers, setYourOrganizationsSuppliers] = useState<
    Supplier[]
  >([])
  const [otherSuppliersOnParachute, setOtherSuppliersOnParachute] = useState<
    Supplier[]
  >([])

  const preferredSupplierIds = preferredSuppliers.map(
    (ps) => ps.supplier.externalId
  )

  const hasPreferredSuppliers = preferredSupplierIds.length > 0

  const preferredSupplierSelected = preferredSupplierIds.includes(
    selectedSupplier?.externalId
  )

  const otherSupplierOnParachuteSelected = otherSuppliersOnParachute
    .map((s) => s.externalId)
    .includes(selectedSupplier?.externalId)

  useEffect(() => {
    if (preferredSupplierSelected) {
      trackEvent(EventCategory.PreferredSupplier, "autoselect")
    }
  }, [preferredSupplierSelected])

  useEffect(() => {
    if (!preferredSupplierSelected) {
      setLoading(true)
      fetchSuppliers(
        packageConfiguration.packageId,
        yourOrganizationsSuppliersOnly,
        selectedServiceAreaState
      )
        .then((data) => {
          setYourOrganizationsSuppliers(data.yourOrganizationsSuppliers)
          setOtherSuppliersOnParachute(data.otherSuppliers)
        })
        .finally(() => setLoading(false))
    }
  }, [
    packageConfiguration,
    preferredSupplierSelected,
    yourOrganizationsSuppliersOnly,
    selectedServiceAreaState,
    setLoading,
  ])

  const onSupplierSelect = () => {
    void trackEvent(
      EventCategory.ProductConfiguration,
      "supplier-radio-clicked"
    )
  }

  const hideOtherSuppliersSection =
    yourOrganizationsSuppliersOnly ||
    !flexibleSupplierAccess ||
    otherSuppliersOnParachute.length < 1

  return (
    <>
      {hasPreferredSuppliers && (
        <PreferredSupplierSelector
          availableSuppliers={availableSuppliers}
          preferredSuppliers={preferredSuppliers}
          onSupplierSelect={onSupplierSelect}
        />
      )}

      {!hasPreferredSuppliers && !loading && (
        <>
          <YourOrganizationsSuppliers
            manageFulfillmentAgreements={manageFulfillmentAgreements}
            onSupplierSelect={onSupplierSelect}
            yourOrganizationsSuppliers={yourOrganizationsSuppliers}
            showServiceTier={showServiceTier}
            preferredSupplierIds={preferredSupplierIds}
            hasPreferredSuppliers={hasPreferredSuppliers}
          />
          {hideOtherSuppliersSection ? null : (
            <OtherSuppliersOnParachute
              onSupplierSelect={onSupplierSelect}
              otherSuppliersOnParachute={otherSuppliersOnParachute}
              initiallyShowOtherSuppliers={
                yourOrganizationsSuppliers.length === 0 ||
                otherSupplierOnParachuteSelected
              }
            />
          )}
        </>
      )}

      {selectedSupplier && selectedSupplier.serviceTier === ServiceTier.Basic && (
        <div className="well well-warning well-expand">
          {selectedSupplier.name} does not have messaging and status updates
          enabled on Parachute.&nbsp;
          <a href={BasicSupplierHelpUrl} target="_blank" rel="noreferrer">
            Learn More
          </a>
        </div>
      )}
    </>
  )
}

export default SearchByProductSupplierSelector
