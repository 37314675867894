import React from "react"
import { User } from "sharedTypes"
import { CanopyButton } from "@parachutehealth/canopy-button"
import classNames from "classnames"
import * as styles from "./InternalActions.module.scss"
import { sendWelcomeEmail, sendResetPasswordEmail } from "../api"
import { navigate } from "utilities/navigation"

export type Props = {
  user: User & { availableForSignUp: boolean }
  invitePreviouslySent: boolean
}

const SendEmailButtons: React.FC<Props> = ({ user, invitePreviouslySent }) => {
  const inviteButtonText = () => {
    if (invitePreviouslySent) {
      return "Resend Email Invite"
    } else {
      return "Send Email Invite"
    }
  }

  return (
    <div>
      {user.availableForSignUp ? (
        <CanopyButton
          fullWidth
          type="button"
          onClick={() => {
            sendWelcomeEmail(user.userId).then(({ redirectUrl }) => {
              navigate(redirectUrl)
            })
          }}
          className={classNames(styles.secondaryEmailButton)}
        >
          {inviteButtonText()}
        </CanopyButton>
      ) : (
        <CanopyButton
          fullWidth
          type="button"
          onClick={() => {
            sendResetPasswordEmail(user.userId).then(({ redirectUrl }) => {
              navigate(redirectUrl)
            })
          }}
          className={classNames(styles.secondaryEmailButton)}
        >
          Send Reset Password Email
        </CanopyButton>
      )}
    </div>
  )
}

export default SendEmailButtons
