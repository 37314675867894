import React, { useRef, useEffect } from "react"
import { Popover as ReactPopover, PositionTransform } from "react-tiny-popover"
import classNames from "classnames"

type Props = {
  label: React.ReactNode
  children: React.ReactNode
  open?: boolean
  onHide?(): void
  onClick?(event): void
  size?: "large" | "date-selector"
  className?: string
  contentLocation?: PositionTransform
  allowOverflow?: boolean
}

export { PositionTransform as ContentLocation }

const Popover: React.FC<Props> = ({
  className,
  label,
  children,
  contentLocation,
  open,
  onHide,
  onClick,
  size,
  allowOverflow,
}) => {
  const popover = useRef<HTMLDivElement>(null)
  const toggle = useRef<HTMLElement>(null)

  useEffect(() => {
    const onWindowEvent = (event) => {
      if (
        open &&
        !popover.current?.contains(event.target) &&
        !toggle.current?.contains(event.target)
      ) {
        if (onHide) onHide()
      }
    }

    window.addEventListener("mousedown", onWindowEvent)
    window.addEventListener("touchstart", onWindowEvent)
    return () => {
      window.removeEventListener("mousedown", onWindowEvent)
      window.removeEventListener("touchstart", onWindowEvent)
    }
  }, [open, onHide])

  const defaultContentLocation = ({ childRect, popoverRect }) => {
    const PAGE_PADDING = 15
    let left = childRect.width / 2 - popoverRect.width / 2
    if (childRect.left + window.scrollX < Math.abs(left)) {
      left += childRect.left * 2 + window.scrollX * 2 + PAGE_PADDING
    }
    if (childRect.right + Math.abs(left) > window.innerWidth) {
      left -= Math.abs(left) + PAGE_PADDING
    }

    return {
      top: childRect.height,
      left: left || 0,
    }
  }

  const onKeyPress = (event) => {
    if (event.key === " ") {
      event.preventDefault()
      if (onClick) onClick(event)
    }
  }

  return (
    <div
      className={classNames(className, "popover-container", {
        "is-closed": !open,
      })}
    >
      <ReactPopover
        isOpen={Boolean(open)}
        positions={["bottom"]}
        align="center"
        containerClassName="react-tiny-popover-container"
        content={({ childRect, popoverRect }) => {
          const targetElement = childRect as DOMRect
          const popoverElement = popoverRect as DOMRect
          const ARROW_WIDTH = 5
          // The below calculation centers the arrow in most cases
          const left =
            targetElement.left -
            popoverElement.left +
            targetElement.width / 2 -
            ARROW_WIDTH
          return (
            <div
              role="dialog"
              ref={popover}
              className={classNames(
                "popover fade in bottom d-block",
                size && `popover-${size}`
              )}
            >
              <div className="arrow" style={{ left: left || 0 }} />
              <div
                className={`popover-content ${
                  allowOverflow ? "popover-visible-overflow" : ""
                }`}
              >
                {open && children}
              </div>
            </div>
          )
        }}
        parentElement={toggle?.current || document.body}
        transform={contentLocation || defaultContentLocation}
        ref={toggle}
      >
        <div
          className="d-inline-block"
          onClick={onClick}
          onKeyDown={onKeyPress}
        >
          {label}
        </div>
      </ReactPopover>
    </div>
  )
}

export default Popover
