import { Follower, FollowersGroup } from "sharedTypes"

export const convertFollowerToOption = (record: Follower) => ({
  label: record.name,
  value: record.id,
})
export const convertToGroupOption = (group: FollowersGroup) => ({
  label: group.name,
  options: group.followers.map(convertFollowerToOption),
})
