import classNames from "classnames"
import Modal from "components/Modal"
import React, { useState } from "react"
import {
  DocumentationRequirement,
  DocumentationRequirementSatisfyMethod,
  DocumentationRequirementSatisfyStatus,
} from "sharedTypes"
import FaxTab from "./FaxTab"
import UploadTab from "./UploadTab"

interface Props {
  showModal: boolean
  documentationRequirements: DocumentationRequirement[]
  uploadDocuments(
    documentationRequirementIds: string[],
    files: File[],
    signed: boolean,
    otherAttachmentTitle: string
  ): Promise<void>
  updateDocumentationRequirementsSatisfyStatus(values: {
    manualSatisfyStatus: DocumentationRequirementSatisfyStatus
    documentationRequirementIds: string[]
  }): Promise<void>
  dmeOrderId: string
  hasSigningClinician: boolean
  supplierFaxNumber: string
  closeModal(): void
  manuallySatisfyGeneratedDocs: boolean
}

enum Tab {
  Upload = "upload",
  Fax = "fax",
}

const AttachManualDocumentationModal = ({
  showModal,
  documentationRequirements,
  supplierFaxNumber,
  hasSigningClinician,
  closeModal,
  manuallySatisfyGeneratedDocs,
  ...props
}: Props) => {
  const [currentTab, setCurrentTab] = useState(Tab.Upload)
  const uploadDocuments = (
    documentationRequirementIds,
    files,
    signed,
    otherAttachmentTitle
  ) => {
    return props
      .uploadDocuments(
        documentationRequirementIds,
        files,
        signed,
        otherAttachmentTitle
      )
      .then(dismiss)
  }
  const updateDocumentationRequirementsSatisfyStatus = ({
    manualSatisfyStatus,
    documentationRequirementIds,
  }) => {
    return props
      .updateDocumentationRequirementsSatisfyStatus({
        manualSatisfyStatus,
        documentationRequirementIds: documentationRequirementIds.filter(
          (id) => id !== ""
        ),
      })
      .then(dismiss)
  }

  const dismiss = () => {
    setCurrentTab(Tab.Upload)
    closeModal()
  }

  const manualRequirements = documentationRequirements.filter(
    (requirement) =>
      requirement.satisfyMethod ===
        DocumentationRequirementSatisfyMethod.Manual ||
      (manuallySatisfyGeneratedDocs && requirement.chartNotesRequired)
  )
  const generateRequirements = documentationRequirements.filter(
    (requirement) =>
      requirement.satisfyMethod ===
      DocumentationRequirementSatisfyMethod.Generate
  )

  const showGenerateRequirements =
    hasSigningClinician &&
    generateRequirements &&
    generateRequirements.length > 0 &&
    generateRequirements.every(
      (dr) =>
        dr.satisfyStatus === DocumentationRequirementSatisfyStatus.Complete
    )

  const renderTab = () => {
    switch (currentTab) {
      case Tab.Upload:
        return (
          <UploadTab
            uploadDocuments={uploadDocuments}
            manualRequirements={manualRequirements}
            generateRequirements={
              showGenerateRequirements ? generateRequirements : []
            }
          />
        )
      case Tab.Fax:
        return (
          <FaxTab
            updateDocumentationRequirementsSatisfyStatus={
              updateDocumentationRequirementsSatisfyStatus
            }
            manualRequirements={manualRequirements}
            generateRequirements={
              showGenerateRequirements ? generateRequirements : []
            }
            closeModal={dismiss}
            supplierFaxNumber={supplierFaxNumber}
          />
        )
    }
  }

  return (
    <>
      <Modal show={showModal} size="md" closeable cancel={dismiss}>
        <Modal.Header className="has-tabs">
          <button className="close" type="button" onClick={dismiss}>
            ×
          </button>
          <h2 className="modal-title">Attach Documents</h2>

          <div className="tabs">
            <div
              className={classNames("tab", {
                selected: currentTab === Tab.Upload,
              })}
              onClick={() => setCurrentTab(Tab.Upload)}
            >
              via Upload
            </div>
            {supplierFaxNumber && (
              <div
                className={classNames("tab", {
                  selected: currentTab === Tab.Fax,
                })}
                onClick={() => setCurrentTab(Tab.Fax)}
              >
                via Fax
              </div>
            )}
          </div>
        </Modal.Header>
        {renderTab()}
      </Modal>
    </>
  )
}

export default AttachManualDocumentationModal
