import React from "react"
import { Field } from "formik"
import classNames from "classnames"
import { getFieldError } from "./utilities"

const fieldWrapper = (InnerField, options = { validate: null }) => (props) => {
  const { label, name, validateField, unwrapped } = props
  const { validate } = options
  return (
    <Field
      name={name}
      validate={validateField && validate ? validate : undefined}
    >
      {({ field, form }) => {
        const { errors, touched } = form
        const error = getFieldError(errors, touched, name)
        return (
          <div
            className={classNames({
              "form-group": !unwrapped,
              "has-error": !!error,
            })}
          >
            {label && <label htmlFor={name}>{label}</label>}
            <InnerField field={field} form={form} {...props} />
            {error && (
              <div className="color-danger is-under-input">{error}</div>
            )}
          </div>
        )
      }}
    </Field>
  )
}

export default fieldWrapper
