import React from "react"
import { Container } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles({
  maxWidthLg: {
    maxWidth: "none",
  },
})

const ParachuteMuiDataGridContainer: React.FC = ({ children }) => {
  const classes = useStyles()

  return (
    <Container
      classes={{ maxWidthLg: classes.maxWidthLg }}
      className="bg-white canopy-pbe-6x border rounded"
    >
      <>{children}</>
    </Container>
  )
}

export default ParachuteMuiDataGridContainer
