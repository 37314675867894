// @ts-strict-ignore)
import * as Sentry from "@sentry/react"
import { EmrInstanceTypes } from "../sharedTypes"

interface SentryWindow {
  sentryConfig: {
    dsn: string
    domainSuffix?: string
    relay_enabled?: boolean
  }
  parachute?: {
    emrInstanceType?: EmrInstanceTypes
    currentUserEmail?: string
    currentUserId?: string
    employerType?: string
    employerId?: string
    currentOrderId?: string
  }
}

export default class SentryConfig {
  static start({
    sentryConfig,
    parachute,
    clientsideInstrumentation,
  }: SentryWindow & Window) {
    if (!parachute) {
      return
    }

    if (!!parachute.emrInstanceType && !sentryConfig.relay_enabled) {
      return
    }

    const sentryScope = () => {
      if (!parachute.currentUserId) {
        return
      }

      return {
        user: {
          id: parachute.currentUserId,
          email: parachute.currentUserEmail,
        },
        tags: {
          emrInstanceType: parachute.emrInstanceType,
          employerType: parachute.employerType,
          employerId: parachute.employerId,
          currentOrderId: parachute.currentOrderId,
        },
      }
    }

    Sentry.init({
      dsn: sentryConfig.dsn,
      environment: clientsideInstrumentation.releaseStage,
      release: clientsideInstrumentation.commitSHAWithPrefix,
      integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.replayIntegration(),
      ],
      replaysSessionSampleRate: 0.0,
      replaysOnErrorSampleRate: 1.0,
      initialScope: sentryScope(),
    })

    if (clientsideInstrumentation.releaseStage === "previews") {
      Sentry.setTag("previews", sentryConfig.domainSuffix)
    }
  }
}
