import { Event } from "sharedTypes"
import { formatPhoneWithExtension } from "utilities/phone"

export function getTooltip(event: Event, isUserOnline: boolean): string {
  const { userEmail, userPhoneNumber, userPhoneExtension } = event

  if (!userEmail && !userPhoneNumber) {
    return ""
  }

  const phone = formatPhoneWithExtension(userPhoneNumber, userPhoneExtension)
  return [phone, userEmail, `(${isUserOnline ? "Online" : "Offline"})`]
    .filter(Boolean)
    .join(" ")
}
