// @ts-strict-ignore
import Icon from "components/Icon"
import React, { forwardRef, useEffect, useState } from "react"
import ReactSelect, { components } from "react-select"
import AsyncSelect from "react-select/async"
import AsyncCreatableSelect from "react-select/async-creatable"
import { useDebounce } from "hooks/useDebounce"
import { Field } from "formik"
import classNames from "classnames"
import flatten from "lodash/flatten"
import omit from "lodash.omit"
import { unique } from "utilities/array"
import { getFieldError } from "components/form/utilities"
import ErrorText from "components/form/ErrorText"
import Suggestion from "./Suggestion"
import SubText from "components/SubText"
import * as styles from "./index.module.scss"

export type Option = {
  label: string
  value: string | number
  isOther?: boolean
}

export type GroupedOptions = {
  label: string | React.ReactNode
  options: Option[]
}

export const convertValueToOption = (value: string): Option => ({
  value: value,
  label: value,
})

export const omitOptionProps = (option) => omit(option, ["label", "value"])

export const filterOptionsByQuery = (options: Option[]) => (
  query: string
): Promise<Option[]> => {
  const filteredOptions = options.filter(({ label }) =>
    label.toLowerCase().includes(query.toLowerCase())
  )
  return new Promise((resolve) => resolve(filteredOptions))
}

type Props = {
  id?: string
  name: string
  label?: React.ReactNode
  subtext?: string | React.ReactElement
  unwrapped?: boolean
  options?: (Option | GroupedOptions)[]
  renderOtherOption?(option, RenderOptionProps): React.ReactNode
  renderMenu?(props): React.ReactNode
  renderEmpty?(query: string): React.ReactNode
  renderOption?(option, RenderOptionProps): React.ReactNode
  renderSelection?(option): React.ReactNode
  hideSelections?: boolean
  onChange?(newValue?: string | string[], options?): void
  onFocus?(): void
  onBlur?(): void
  debounceOptions?: {
    leading?: boolean
    timeInMilliseconds?: number
    memoDependencies?: any[]
  }
  isClearable?: boolean
  isSearchable?: boolean
  isMulti?: boolean
  isDisabled?: boolean
  isRequired?: boolean
  menuIsOpen?: boolean
  minLength?: number
  placeholder?: string
  autoFocus?: boolean
  openMenuOnFocus?: boolean
  fetchOptions?(query: string): Promise<(Option | GroupedOptions)[]>
  hasDropdownIndicator?: boolean
  hasNoOptionsMessage?: boolean
  hasLoadingMessage?: boolean
  closeMenuOnSelect?: boolean
  clearOnSelect?: boolean
  setValueOnSelect?(option): boolean
  hideSelectedOptions?: boolean
  blurInputOnChange?: boolean
  debounce?: boolean
  small?: boolean
  expandToFit?: boolean
  menuPosition?: "absolute" | "fixed"
  onInputChange?(term: string): void
  isLoading?: boolean
  filterOption?: boolean
  onCreate?(input: string): Promise<Option>
  validate?(value: string): void
  maxMenuHeight?: number
  disabledIndicator?: React.ReactNode
  onKeyDown?(event: Event): void
}

const flattenOptions = (options) => {
  return flatten(options.map((option) => option.options || option))
}

const findOptionByValue = (options, value) => {
  const flattenedOptions = flattenOptions(options)
  return flattenedOptions.find(
    (option: Option) => normalizeValue(option.value) === normalizeValue(value)
  )
}

const normalizeValue = (value) => {
  if (typeof value === "number") {
    return value.toString()
  }
  return value
}

const selectOptions = (options, values) => {
  return values
    .map((value) => findOptionByValue(options, value))
    .filter(Boolean)
}

const Select = forwardRef<HTMLInputElement, Props>(
  (props: Props, ref: React.RefObject<HTMLInputElement>) => {
    const {
      id,
      name,
      label,
      subtext,
      options: initialOptions,
      renderOtherOption,
      onChange,
      onInputChange,
      isLoading,
      filterOption,
      onFocus,
      onBlur,
      renderOption: customRenderOption,
      renderEmpty,
      renderSelection,
      hideSelections,
      renderMenu,
      unwrapped,
      minLength,
      isClearable,
      isSearchable,
      isMulti,
      isDisabled,
      isRequired,
      menuIsOpen,
      hasDropdownIndicator,
      hasNoOptionsMessage,
      hasLoadingMessage,
      autoFocus,
      placeholder,
      fetchOptions,
      debounce,
      closeMenuOnSelect,
      clearOnSelect,
      setValueOnSelect,
      openMenuOnFocus,
      menuPosition,
      hideSelectedOptions,
      blurInputOnChange,
      small,
      expandToFit,
      validate,
      maxMenuHeight,
      disabledIndicator,
      onKeyDown,
    } = props
    const debouncedFetchOptions =
      fetchOptions &&
      // eslint-disable-next-line react-hooks/rules-of-hooks
      useDebounce(
        fetchOptions,
        props.debounceOptions?.timeInMilliseconds,
        props.debounceOptions?.leading,
        props.debounceOptions?.memoDependencies
      )
    const [options, setOptions] = useState(initialOptions)
    const [searchOptions, setSearchOptions] = useState(initialOptions)
    ref = ref || React.createRef()
    const blurInput = () => ref && ref.current && ref.current.blur()

    useEffect(() => {
      setOptions(initialOptions)
      setSearchOptions((currentOptions: (Option | GroupedOptions)[]) =>
        unique([...currentOptions, ...initialOptions])
      )
    }, [initialOptions])

    const ariaLabel = typeof label === "string" ? label : placeholder

    const renderSubText = (subtext) => {
      return <SubText content={subtext} />
    }

    return (
      <Field id={id || name} name={name} validate={validate}>
        {({ field, form }) => {
          const getValue = () => {
            if (clearOnSelect) {
              return ""
            }

            if (isMulti) {
              return options
                ? selectOptions(searchOptions, field.value || [])
                : []
            } else {
              const defaultValue =
                renderOtherOption && field.value
                  ? convertValueToOption(field.value)
                  : undefined
              return options
                ? selectOptions(
                    searchOptions,
                    [field.value].filter(Boolean)
                  )[0] || defaultValue
                : ""
            }
          }
          const value = getValue() || ""
          const selectProps = {
            ...field,
            className: classNames("react-select__container", {
              small: small,
              autoexpand: expandToFit,
            }),
            classNamePrefix: "react-select",
            id: id || name,
            "aria-label": ariaLabel,
            name,
            placeholder,
            autoFocus,
            openMenuOnFocus,
            value,
            defaultMenuIsOpen: menuIsOpen,
            menuIsOpen,
            onInputChange,
            isLoading,
            filterOption,
            onKeyDown,
            onChange: async (input) => {
              if (isMulti) {
                const options = input || []
                const newValues = options.map((option) => option.value)
                onChange && onChange(newValues, options)
                await form.setFieldValue(field.name, newValues, true)
                form.setFieldTouched(field.name, true)
              } else {
                const shouldSetValue =
                  setValueOnSelect && input ? setValueOnSelect(input) : true
                if (shouldSetValue) {
                  const newValue = input ? input.value : null
                  onChange && onChange(newValue, input)
                  await form.setFieldValue(field.name, newValue, true)
                  form.setFieldTouched(field.name, true)
                } else {
                  blurInput()
                }
              }
              blurInputOnChange && blurInput()
            },
            onFocus,
            onBlur: () => {
              form.setFieldTouched(field.name, true)
              onBlur && onBlur()
            },
            minLength,
            isClearable,
            isSearchable,
            isMulti,
            isDisabled,
            options,
            hideSelectedOptions,
            closeMenuOnSelect,
            menuPosition,
            maxMenuHeight,
          }
          const renderers = {} as {
            Option?(option): React.ReactNode
            SingleValue?(option): React.ReactNode
            MultiValue?(option): React.ReactNode
            Menu?(props): React.ReactNode
            DropdownIndicator?(props): React.ReactNode
            NoOptionsMessage?(props): React.ReactNode
            LoadingMessage?(props): React.ReactNode
          }

          const defaultRenderOption = (option, { isSelected }) => {
            return (
              <Suggestion
                className={classNames({
                  "react-select__option--is-selected": isSelected,
                })}
              >
                {option.label}
              </Suggestion>
            )
          }

          renderers.DropdownIndicator = (props) => {
            if (!hasDropdownIndicator) {
              return <></>
            }

            if (isDisabled && disabledIndicator) {
              return (
                <components.DropdownIndicator {...props}>
                  {disabledIndicator}
                </components.DropdownIndicator>
              )
            } else if (isSearchable) {
              return field.value && isClearable ? (
                <></>
              ) : (
                <components.DropdownIndicator {...props}>
                  <Icon type="search" />
                </components.DropdownIndicator>
              )
            } else {
              return <components.DropdownIndicator {...props} />
            }
          }
          if (!hasNoOptionsMessage) {
            renderers.NoOptionsMessage = () => <></>
          }
          if (renderEmpty) {
            renderers.NoOptionsMessage = (props) => (
              <div className="react-select__option">
                {renderEmpty(props.selectProps.inputValue)}
              </div>
            )
          }
          if (!hasLoadingMessage) {
            renderers.LoadingMessage = () => <></>
          }

          const renderOption = customRenderOption || defaultRenderOption
          renderers.Option = (props) => {
            const isOther = props.data.isOther
            const renderFn = isOther ? renderOtherOption : renderOption
            return (
              <components.Option {...props}>
                {renderFn(props.data, {
                  isSelected: props.isSelected,
                  isFocused: props.isFocused,
                  query: props.selectProps.inputValue,
                })}
              </components.Option>
            )
          }

          if (renderSelection) {
            renderers.SingleValue = (props) => (
              <components.SingleValue {...props}>
                <div>{renderSelection(props.data)}</div>
              </components.SingleValue>
            )
            renderers.MultiValue = (props) => (
              <components.MultiValue {...props}>
                {renderSelection(props.data)}
              </components.MultiValue>
            )
          }
          if (hideSelections) {
            renderers.SingleValue = () => <></>
            renderers.MultiValue = () => <></>
          }
          if (renderMenu) {
            renderers.Menu = renderMenu
          }
          selectProps.components = renderers

          const renderSelect = () => {
            if (fetchOptions) {
              const onAsyncInputChange = (term, { action }) => {
                if (!term && action === "input-change") {
                  setOptions([])
                }
              }

              const loadOptions = (term) => {
                if (term.length >= selectProps.minLength) {
                  return (debounce
                    ? debouncedFetchOptions(term)
                    : fetchOptions(term)
                  )
                    .then((newOptions) => {
                      return renderOtherOption
                        ? newOptions.concat([
                            { label: term, value: term, isOther: true },
                          ])
                        : newOptions
                    })
                    .then((newOptions) => {
                      setOptions(newOptions)
                      setSearchOptions((currentOptions) =>
                        unique([...currentOptions, ...newOptions])
                      )
                      return newOptions
                    })
                } else {
                  return Promise.resolve()
                }
              }

              const defaultOptions =
                (minLength === 0 && openMenuOnFocus) ||
                options.length === 0 ||
                options

              const asyncSelectProps = {
                ...selectProps,
                defaultOptions, // if defaultOptions is `true`, react-select fires loadOptions('') on component load; see https://react-select.com/async#defaultoptions
                loadOptions,
                filterOptions: false,
                onSelectResetsInput: false,
                onBlurResetsInput: false,
                onInputChange: onAsyncInputChange,
                onMenuClose: () => setOptions(initialOptions),
              }

              if (props.onCreate) {
                asyncSelectProps.onCreateOption = (input) => {
                  props.onCreate(input).then((option) => {
                    setOptions([...options, option])
                    setSearchOptions([...searchOptions, option])
                    form.setFieldValue(
                      field.name,
                      [...field.value, option.value],
                      true
                    )
                  })
                }
              }
              const SelectComponent = props.onCreate
                ? AsyncCreatableSelect
                : AsyncSelect
              return <SelectComponent ref={ref} {...asyncSelectProps} />
            }
            return <ReactSelect ref={ref} {...selectProps} />
          }

          const { errors, touched } = form
          const error = getFieldError(errors, touched, name)
          const renderError = (error) => <ErrorText error={error} />

          const renderLabel = (isRequired, label, id, name) => {
            if (isRequired) {
              return (
                label && (
                  <label
                    className={`col-form-label ${styles.required}`}
                    htmlFor={id || name}
                  >
                    {label}
                  </label>
                )
              )
            } else {
              return (
                label && (
                  <label className="col-form-label" htmlFor={id || name}>
                    {label}
                  </label>
                )
              )
            }
          }
          return (
            <div
              className={classNames({
                "form-group": !unwrapped,
                "has-error": error,
              })}
            >
              {renderLabel(isRequired, label, id, name)}
              {renderSelect()}
              {renderError(error)}
              {renderSubText(subtext)}
            </div>
          )
        }}
      </Field>
    )
  }
)

Select.defaultProps = {
  options: [],
  isClearable: false,
  isSearchable: false,
  isMulti: false,
  hasDropdownIndicator: true,
  hasNoOptionsMessage: false,
  hasLoadingMessage: false,
  closeMenuOnSelect: true,
  debounce: true,
  minLength: 0,
  menuPosition: "absolute",
  hideSelectedOptions: true,
  maxMenuHeight: 350,
}

export default Select
