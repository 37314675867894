import React, { useEffect, useState } from "react"
import { preload } from "utilities/image"

type Props = {
  className?: string
  alt?: string
  notice?: string
  src: string
}

function Image({ className, src, alt }: Props) {
  const [ready, setReady] = useState(false)

  useEffect(() => {
    let mounted = true
    preload(src)
      .then(() => {
        if (mounted) {
          setReady(true)
        }
      })
      .catch(() => {
        if (mounted) {
          setReady(false)
        }
      })
    return () => {
      mounted = false
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (!ready) {
    return <></>
  }
  return <img className={className} src={src} alt={alt} />
}

export default Image
