import React, { useCallback, useEffect } from "react"
import { PackageSku, SuggestionOption } from "../sharedTypes"
import { Form, Select } from "components/form"
import Suggestion from "components/form/Select/Suggestion"
import { omitOptionProps, Option } from "components/form/Select"
import { convertToOption, meetsMinLength } from "./utils"
import SearchResult from "./SearchResult"
import { handleError } from "../../../../../utilities/error"
import { ApplicationError } from "../../../../../sharedTypes"

type Props = {
  formularyPriceEnabled: boolean
  hideIcon?: boolean
  placeholder?: string
  searchPackagesWithSku(query: string): Promise<{ data: PackageSku[] }>
  selectPackageWithSku(s: PackageSku): void
  isInHybridSearch?: boolean
}

const SkuQuickAdd: React.FunctionComponent<Props> = ({
  hideIcon,
  formularyPriceEnabled,
  placeholder = "Search",
  searchPackagesWithSku,
  selectPackageWithSku,
  isInHybridSearch = false,
}) => {
  const minLength = 3
  const [options, setOptions] = React.useState<Option[]>([])
  const [query, setQuery] = React.useState<string>("")

  const searchable = useCallback(
    (value: string): Promise<{ data: PackageSku[] }> => {
      if (meetsMinLength(value, minLength)) {
        return searchPackagesWithSku(value)
      } else {
        return new Promise((resolve) => resolve({ data: [] }))
      }
    },
    [searchPackagesWithSku]
  )

  useEffect(() => {
    const updateOptions = async () => {
      try {
        const response = await searchable(query)
        setOptions(response.data.map(convertToOption))
      } catch (e) {
        handleError(e as ApplicationError)
      }
    }
    updateOptions()
  }, [query, searchable])

  return (
    <div className="canopy-mbe-12x">
      <Form>
        <Select
          unwrapped={true}
          hasDropdownIndicator={!hideIcon}
          name="package-search-select"
          minLength={minLength}
          placeholder={placeholder}
          onInputChange={(value) => setQuery(value)}
          onChange={(_value, option) =>
            selectPackageWithSku(omitOptionProps(option))
          }
          options={options}
          isSearchable
          renderOption={(
            suggestion: SuggestionOption,
            { query, isFocused }: { query: string; isFocused: boolean }
          ) => {
            return (
              <SearchResult
                suggestion={suggestion}
                isHighlighted={isFocused}
                query={query}
                formularyPriceEnabled={formularyPriceEnabled}
                isInHybridSearch={isInHybridSearch}
              />
            )
          }}
          renderEmpty={(value) =>
            value && meetsMinLength(value, minLength) ? (
              <Suggestion>No matching products.</Suggestion>
            ) : (
              <Suggestion>
                Type a word with at least {minLength} characters to search
              </Suggestion>
            )
          }
          clearOnSelect
          blurInputOnChange
        />
      </Form>
    </div>
  )
}
export default SkuQuickAdd
