// @ts-strict-ignore
import React, { createRef, useEffect, useState } from "react"
import SpinIcon from "components/Icon/SpinIcon"
import { preload } from "utilities/image"
import transform from "utilities/rotation"
import { Zoom } from "../sharedTypes"
import classNames from "classnames"
import * as styles from "../index.module.scss"

type Props = {
  className?: string
  src: string
  zoom?: Zoom
  rotation?: number
  hover?: boolean
  alt?: string
}

function Image({ className, src, zoom, rotation, hover, alt }: Props) {
  const [loading, setLoading] = useState(true)
  const [ready, setReady] = useState(false)
  const [width, setWidth] = useState(0)
  const [height, setHeight] = useState(0)

  const hoverClass = hover && styles.hover

  useEffect(() => {
    let mounted = true
    preload(src)
      .then(() => {
        if (mounted) {
          setLoading(false)
        }
      })
      .catch(() => {
        if (mounted) {
          setLoading(false)
        }
      })
    return () => {
      mounted = false
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const ref = createRef<HTMLImageElement>()
  const onLoad = () => {
    setWidth(ref.current.width)
    setHeight(ref.current.height)
    setReady(true)
  }

  if (loading) {
    return (
      <div className="text-center canopy-mbs-24x font-xxxl">
        <SpinIcon type="spinner" />
      </div>
    )
  }

  return (
    <div className={classNames("image", styles.image, hoverClass)}>
      <img
        className={classNames(className, { invisible: !ready })}
        style={transform(zoom, rotation, height, width)}
        onLoad={onLoad}
        src={src}
        ref={ref}
        alt={alt}
      />
      <div className={styles.overlay} style={{ width: `${zoom}%` }} />
    </div>
  )
}

export default Image
