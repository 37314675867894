// @ts-strict-ignore
import React from "react"
import debounce from "lodash/debounce"
import InternalPayment from "./components/InternalPayment"
import * as api from "./api"
import { DmeOrder, InsurancePolicy } from "sharedTypes"
import {
  Prediction,
  UpdateParams,
  InsuranceRank,
  CarrierAuthorizationsResponse,
} from "./sharedTypes"
import { handleError } from "utilities/error"

type Props = {
  dmeOrder: DmeOrder
  refreshDmeOrder(): Promise<void>
  nextPagePath?: string
}

const Payment = ({ nextPagePath, dmeOrder, refreshDmeOrder }: Props) => {
  const carrierSearch = (query: string) =>
    api.carrierSearch(query).then(({ data }) => data)

  const predict = (
    memberNumber: string,
    rank: InsuranceRank
  ): Promise<Prediction[]> =>
    api
      .predict(memberNumber, rank)
      .then((response) => response.data.predictions)

  const update = (params: UpdateParams) =>
    api.update(params).then(() => refreshDmeOrder().catch(handleError))

  const removePolicy = (policy: InsurancePolicy) =>
    api.removePolicy(policy).then(() => refreshDmeOrder().catch(handleError))

  const verifyInsurance = (rank: InsuranceRank): Promise<boolean> =>
    api.poll(rank)

  const autoUpdateCarrier = async (rank: InsuranceRank) =>
    api.autoUpdateCarrier(rank)

  const updateAuthorizationNumber = debounce((params) => {
    return api
      .updateAuthorizationNumber(params)
      .then(refreshDmeOrder)
      .catch(handleError)
  }, 100)

  const getCarrierAuthorizations = async (
    query: string
  ): Promise<CarrierAuthorizationsResponse> => {
    try {
      const { data } = await api.getCarrierAuthorizations(query)
      return data
    } catch (error) {
      handleError(error)
    }
  }

  return (
    <InternalPayment
      updateAuthorizationNumber={updateAuthorizationNumber}
      dmeOrder={dmeOrder}
      nextPagePath={nextPagePath}
      carrierSearch={carrierSearch}
      predict={predict}
      update={update}
      verifyInsurance={verifyInsurance}
      getCarrierAuthorizations={getCarrierAuthorizations}
      removePolicy={removePolicy}
      autoUpdateCarrier={autoUpdateCarrier}
    />
  )
}

export default Payment
