import { Severity } from "sharedTypes"

const SEVERITIES = [
  Severity.Success,
  Severity.Default,
  Severity.Info,
  Severity.Accent,
  Severity.Warning,
  Severity.Danger,
]

export function getSeverity(severities: Severity[]) {
  return severities.sort((severity1, severity2) => {
    return SEVERITIES.indexOf(severity1) >= SEVERITIES.indexOf(severity2)
      ? -1
      : 1
  })[0]
}
