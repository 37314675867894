import React, { useState } from "react"
import AddReviewerModal from "./AddReviewerModal"
import { createReviewer } from "./api"
import { EventCategory, trackEvent } from "utilities/tracking"
import { userPreferencesUrl } from "./urls"
import Link from "components/Link"
import { Button } from "components/form"
import * as styles from "./index.module.scss"
import classNames from "classnames"
import { ReviewerDetails } from "../../index"

export const CLICK_ADD_REVIEWER_EVENT_NAME = "doctor_dash_add_reviewer"

export const ReviewerManagement = ({
  reviewers: reviewers,
  setReviewers,
  doctorId,
}: {
  reviewers: ReviewerDetails[]
  setReviewers(reviewers: ReviewerDetails[]): void
  doctorId: string
}) => {
  const [showModal, setShowModal] = useState(false)

  const toggleModal = () => {
    setShowModal(!showModal)
  }

  const handleCreateReviewer = ({ email }) => {
    return createReviewer(email, doctorId).then(
      (newReviewer: ReviewerDetails) => {
        if (!!newReviewer) setReviewers([...reviewers, newReviewer])
      }
    )
  }

  return (
    <div className={styles.container}>
      {reviewers.length ? (
        <p>
          <Link className="link font-xs" href={userPreferencesUrl(doctorId)}>
            {`Reviewers (${reviewers.length})`}
          </Link>
        </p>
      ) : (
        <p className="font-xs">{`Reviewers (${reviewers.length})`} </p>
      )}
      <Button
        className={classNames(styles.addReviewerButton, "btn btn-sm btn-brand")}
        onClick={() => {
          toggleModal()
          trackEvent(EventCategory.Resupply, CLICK_ADD_REVIEWER_EVENT_NAME)
        }}
      >
        Add Reviewer
      </Button>
      <AddReviewerModal
        show={showModal}
        onClose={toggleModal}
        onSubmit={handleCreateReviewer}
        reviewers={reviewers}
        doctorId={doctorId}
      />
    </div>
  )
}
