import React, { useContext } from "react"
import { ActionType, ViewContext } from "../view/viewContext"
import { FilterValues } from "./DashboardFilters/sharedTypes"

export const isFilterValuePresent = (value) => {
  if (Array.isArray(value)) {
    return value.filter(Boolean).length > 0
  }
  return value !== undefined && value !== null && value !== ""
}

const isAnyFilterPresent = (filterValues: FilterValues) =>
  Object.values(filterValues).filter(isFilterValuePresent).length > 0

export const ClearFiltersButton = () => {
  const { state, dispatch } = useContext(ViewContext)
  const clearFilters = () =>
    dispatch({
      type: ActionType.CLEAR_FILTER_VALUES,
      data: {},
    })

  if (!isAnyFilterPresent(state.currentView.filterValues)) return null

  return (
    <button
      onClick={clearFilters}
      className="canopy-mis-8x link link-sm nowrap"
    >
      <small>Clear All Filters</small>
    </button>
  )
}
