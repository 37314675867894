import React from "react"

function Agreement() {
  return (
    <>
      <h4>FACILITY PLATFORM ACCESS AGREEMENT</h4>
      <p>
        This Facility Platform Access Agreement
        <strong> (“Agreement”)</strong> is entered into effective as of the date
        set forth on the signature page
        <strong> (“Effective Date”)</strong> by and among Parachute Health, LLC,
        a New York limited liability company, located at 100 Overlook Center, 2
        <sup>nd</sup> Floor, Princeton, NJ 08540 (the
        <strong> “Company”</strong>) and the facility listed on the signature
        page hereto,
        <strong> (“Facility”) </strong> The Company, on the one hand, and
        Facility, on the other hand, are each referred to individually as a
        <strong> “Party”</strong>and together as the
        <strong> “Parties.”</strong>
      </p>
      <p>
        WHEREAS, the Company operates a proprietary technology platform that
        assists in the electronic submission and processing of orders of, among
        other things, durable medical equipment, Prosthetics, Orthotics,
        Suppliers or other related services
        <strong> (“DME”) </strong>and
      </p>
      <p>
        WHEREAS, Facility is a licensed healthcare facility under applicable law
        and desires to access and use the Company’s proprietary platform to
        assist in its processing of DME; and
      </p>
      <p>
        WHEREAS, the Company is willing to provide Facility with access and use
        of its proprietary platform on the terms and conditions set forth in
        this Agreement.
      </p>
      <p>
        NOW, THEREFORE, in consideration of the foregoing and the covenants,
        promises and agreements contained herein, and for other good and
        valuable consideration, the receipt and sufficiency of which are hereby
        acknowledged, the Parties hereby agree as follows:
      </p>
    </>
  )
}

export default Agreement
