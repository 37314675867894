import { FacilityOption as BaseFacilityOption } from "sharedTypes"

export type SupplierFaxBackDocument = {
  id: string
  title: string
}

export type FacilityOption = BaseFacilityOption & {
  hasRecipients: boolean
  isNonParachuteFacility: boolean
}

type RecipientChannel = "fax" | "email" | "sms" | "followable"
export type SelectedRecipient = {
  channel: RecipientChannel
  destination: string
  name?: string
}

export type SendBackRecipient = {
  label: string
  value: string
}

export enum SendBackFormKeys {
  recipients = "recipients",
  followUpOwner = "followUpOwner",
  supplierFollowables = "supplierFollowables",
  supplierFaxBackDocumentIds = "supplierFaxBackDocumentIds",
  reasons = "reasons",
  instructions = "instructions",
  requestedDocumentationRequirementIds = "requestedDocumentationRequirementIds",
  updatedClinicalFacilityId = "updatedClinicalFacilityId",
}

export type SendBackFormValues = {
  [SendBackFormKeys.recipients]: SelectedRecipient[]
  [SendBackFormKeys.followUpOwner]: string
  [SendBackFormKeys.supplierFollowables]: string[]
  [SendBackFormKeys.supplierFaxBackDocumentIds]: string[]
  [SendBackFormKeys.reasons]: string[]
  [SendBackFormKeys.instructions]: string
  [SendBackFormKeys.requestedDocumentationRequirementIds]: string[]
  [SendBackFormKeys.updatedClinicalFacilityId]?: string
}

export type RejectFormValues = {
  recipients: {
    channel: string
    destination: string
  }[]
  reasons: string[]
  instructions: string
  customReason?: string
}
