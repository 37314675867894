// extracted by mini-css-extract-plugin
var _1 = "app-javascript-applications-DoctorReviewerDashboard-components-SignatureRequestDetailView-index-module__action-buttons--WEgR2";
var _2 = "app-javascript-applications-DoctorReviewerDashboard-components-SignatureRequestDetailView-index-module__backLink-container--D5pSw";
var _3 = "app-javascript-applications-DoctorReviewerDashboard-components-SignatureRequestDetailView-index-module__backLink-containerhr--N7rQz";
var _4 = "app-javascript-applications-DoctorReviewerDashboard-components-SignatureRequestDetailView-index-module__deny-request-button--tu6Pz";
var _5 = "app-javascript-applications-DoctorReviewerDashboard-components-SignatureRequestDetailView-index-module__documents-container--doVpF";
var _6 = "app-javascript-applications-DoctorReviewerDashboard-components-SignatureRequestDetailView-index-module__header--bNpY1";
var _7 = "app-javascript-applications-DoctorReviewerDashboard-components-SignatureRequestDetailView-index-module__patient-extended-info--C5iN2";
var _8 = "app-javascript-applications-DoctorReviewerDashboard-components-SignatureRequestDetailView-index-module__patient-info--u00MK";
var _9 = "app-javascript-applications-DoctorReviewerDashboard-components-SignatureRequestDetailView-index-module__patient-name--ievB5";
var _a = "app-javascript-applications-DoctorReviewerDashboard-components-SignatureRequestDetailView-index-module__patient-name-icon--VpdA4";
export { _1 as "actionButtons", _2 as "backLinkContainer", _3 as "backLinkContainerhr", _4 as "denyRequestButton", _5 as "documentsContainer", _6 as "header", _7 as "patientExtendedInfo", _8 as "patientInfo", _9 as "patientName", _a as "patientNameIcon" }
