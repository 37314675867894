/* eslint-disable react/button-has-type */

import React from "react"
import classNames from "classnames"
import SpinIcon from "components/Icon/SpinIcon"

type Props = {
  type?: "submit" | "button" | "reset"
  className?: string
  loading?: boolean
  children?: React.ReactNode
  onClick?(event: React.FormEvent): void
  disabled?: boolean
  ariaLabel?: string
  analyticsTag?: string
  loadingText?: string
}

function Button({
  className,
  type,
  loading,
  loadingText,
  disabled,
  onClick,
  children,
  ariaLabel,
  analyticsTag,
}: Props) {
  const loadingContent: React.ReactElement = (
    <>
      {loadingText && <span className="canopy-pie-2x">{loadingText}</span>}
      <SpinIcon />
    </>
  )
  return (
    <button
      type={type}
      className={classNames("btn", className)}
      onClick={onClick}
      disabled={disabled || loading}
      aria-label={ariaLabel}
      data-analytics={analyticsTag}
      tabIndex={0}
    >
      {loading ? loadingContent : children}
    </button>
  )
}

Button.defaultProps = {
  type: "button",
  className: "btn-primary",
  loading: false,
}

export default Button
