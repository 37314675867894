// @ts-strict-ignore
import React, { FC, useContext, useEffect, useState } from "react"
import CheckCircleIcon from "@material-ui/icons/CheckCircle"
import ErrorIcon from "@material-ui/icons/Error"
import InfoIcon from "@material-ui/icons/Info"
import WarningIcon from "@material-ui/icons/Warning"
import { Snackbar, SnackbarOrigin } from "@material-ui/core"
import Alert from "@material-ui/lab/Alert"

export type AlertSeverity = "success" | "info" | "warning" | "error"

export const iconMapping = {
  success: <CheckCircleIcon />,
  info: <InfoIcon />,
  warning: <WarningIcon />,
  error: <ErrorIcon />,
}

type Position = {
  vertical: "top" | "bottom"
  horizontal: "left" | "right"
}

const DEFAULT_POSITION = { vertical: "bottom", horizontal: "left" }

type AlertType = {
  message: string
  severity: AlertSeverity
  position?: Position
  onClose?: () => void
}

export const ToastContext = React.createContext<{
  persistentAlert: (alert: AlertType) => void
}>({ persistentAlert: (_alert) => {} })

export const useToaster = () => {
  return useContext(ToastContext)
}

export const ToastProvider: FC<{}> = ({ children }) => {
  const [alerts, setAlerts] = useState<AlertType[]>([])
  const persistentAlert = (alert: AlertType) => {
    setAlerts((oldState) => [...oldState, alert])
  }
  const [currentAlert, setCurrentAlert] = useState<AlertType>(undefined)
  const [open, setOpen] = useState(false)
  useEffect(() => {
    if (alerts.length === 0) return
    if (!currentAlert) {
      setCurrentAlert(alerts[0])
      setAlerts((prev) => prev.slice(1))
      setOpen(true)
    } else {
      setOpen(false)
    }
  }, [currentAlert, alerts])
  const closeAlert = () => {
    setOpen(false)
  }
  const handleSnackBarExited = () => setCurrentAlert(undefined)

  return (
    <ToastContext.Provider value={{ persistentAlert: persistentAlert }}>
      <Snackbar
        open={open}
        anchorOrigin={
          currentAlert?.position || (DEFAULT_POSITION as SnackbarOrigin)
        }
        onExited={handleSnackBarExited}
      >
        {currentAlert && (
          <Alert
            iconMapping={iconMapping}
            onClose={closeAlert}
            severity={currentAlert.severity}
          >
            {currentAlert.message}
          </Alert>
        )}
      </Snackbar>
      {children}
    </ToastContext.Provider>
  )
}
