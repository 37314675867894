import React from "react"
import Button from "components/form/Button"

interface Props {
  onCancel: () => void
  isSubmitting: boolean
  isValid: boolean
  submitText: string
}

const FormButtons: React.FC<Props> = ({
  onCancel,
  isSubmitting,
  isValid,
  submitText,
}) => {
  return (
    <div className="text-right">
      <Button className="btn-gray" onClick={onCancel}>
        Cancel
      </Button>
      <Button
        type="submit"
        className="btn-brand"
        disabled={isSubmitting || !isValid}
      >
        {submitText}
      </Button>
    </div>
  )
}

export default FormButtons
