import React from "react"
import logo from "logo.png"

interface Props {
  className?: string
  width?: string
}

function Logo({ className, width }: Props) {
  return (
    <img
      className={className}
      width={width}
      src={logo}
      alt="Parachute Health"
    />
  )
}

export default Logo
