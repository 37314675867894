import {
  Severity,
  SignatureStatus,
  EmployerType,
  OrderStatus,
} from "sharedTypes"

export function getSignatureStatusSeverity(status: SignatureStatus): Severity {
  if ([SignatureStatus.Completed, SignatureStatus.NotNeeded].includes(status)) {
    return Severity.Success
  }
  if ([SignatureStatus.Pending, SignatureStatus.Needed].includes(status)) {
    return Severity.Warning
  }
  return Severity.Default
}

export function showSignatureStatus(
  orderStatus: OrderStatus,
  employerType: EmployerType
) {
  if (employerType === EmployerType.Doctor) {
    return false
  }
  if (employerType === EmployerType.Supplier) {
    return true
  }
  return orderStatus !== OrderStatus.FacilityInProgress
}
