import React from "react"
import SkuQuickAdd from "../SkuQuickAdd"
import { CategoryWithSubcategories, PackageSku } from "../../sharedTypes"
import QuickListButton from "../QuickListButton"
import * as styles from "./index.module.scss"
import { blurOnEnter } from "utilities/browser/event"
import SidebarAwareContainer from "../SideBarAwareContainer"
import CatalogSearchTabs from "../CatalogSearchTabs"
import {
  CatalogBrowseTab,
  CatalogSearchTab,
  CatalogSearchType,
  ConsignmentCloset,
  DmeOrder,
  Nullable,
} from "sharedTypes"
import MobileFilters from "../ProductFilters/MobileFilters"

interface Props {
  availableCategories: CategoryWithSubcategories[]
  canFilterByFormulary: boolean
  canFilterByYourOrganizationsSuppliers: boolean
  catalogSearchType: CatalogSearchType
  categories: CategoryWithSubcategories[]
  categoryId: number
  combinedCatalogSearch: boolean
  consignmentClosetOnly: boolean
  consignmentClosets: ConsignmentCloset[]
  currentCatalogBrowseTab: CatalogBrowseTab
  defaultConsignmentClosetId: string
  defaultServiceAreaState: string
  dmeOrder: DmeOrder
  filterQuery: string
  formularyOnly: boolean
  formularyPriceEnabled: boolean
  searchPackagesWithSku(query: string): Promise<{ data: PackageSku[] }>
  selectedCategory: Nullable<number>
  selectedTab: CatalogSearchTab
  selectCategory(n: number, isSubcategory?: boolean): void
  selectDefaultConsignmentCloset(id: string): Promise<void>
  selectPackageWithSku(s: PackageSku): void
  selectState: (state: string) => void
  serviceAreaState: string
  setSelectedTab(tab: CatalogSearchTab): void
  subcategoryId: number
  supplierIdParam: string
  toggleConsignmentClosetFilter(): void
  toggleFormularyFilter(): void
  toggleYourOrganizationsSuppliersFilter(): void
  updateFilterQuery(query: string): void
  yourOrganizationsSuppliersOnly: boolean
}

const HybridSearch: React.FC<Props> = ({
  availableCategories,
  canFilterByFormulary,
  canFilterByYourOrganizationsSuppliers,
  catalogSearchType,
  categories,
  categoryId,
  combinedCatalogSearch,
  consignmentClosetOnly,
  consignmentClosets,
  currentCatalogBrowseTab,
  defaultConsignmentClosetId,
  defaultServiceAreaState,
  dmeOrder,
  filterQuery,
  formularyOnly,
  formularyPriceEnabled,
  searchPackagesWithSku,
  selectCategory,
  selectedCategory,
  selectState,
  selectedTab,
  selectDefaultConsignmentCloset,
  selectPackageWithSku,
  serviceAreaState,
  setSelectedTab,
  subcategoryId,
  supplierIdParam,
  toggleConsignmentClosetFilter,
  toggleFormularyFilter,
  toggleYourOrganizationsSuppliersFilter,
  updateFilterQuery,
  yourOrganizationsSuppliersOnly,
}) => {
  return (
    <SidebarAwareContainer role="hybridSearch">
      <CatalogSearchTabs
        catalogSearchType={catalogSearchType}
        setSelectedTab={setSelectedTab}
        selectedTab={selectedTab}
      />
      {selectedTab === CatalogSearchTab.SkuQuickAdd ? (
        <div className={styles.hybridSearchContainer}>
          <SkuQuickAdd
            formularyPriceEnabled={formularyPriceEnabled}
            hideIcon
            placeholder="Search by specific SKUs"
            searchPackagesWithSku={searchPackagesWithSku}
            selectPackageWithSku={selectPackageWithSku}
            isInHybridSearch={true}
          />
        </div>
      ) : undefined}
      {selectedTab === CatalogSearchTab.PackageFilter ? (
        <div className={styles.hybridSearchContainer}>
          <input
            className="package-search form-control canopy-mbe-12x"
            value={filterQuery}
            onChange={(event) => updateFilterQuery(event.target.value)}
            placeholder="Search by packages/products"
            onKeyUp={blurOnEnter}
          />
        </div>
      ) : undefined}
      <SidebarAwareContainer role="mobileFilters">
        <MobileFilters
          availableCategories={availableCategories}
          canFilterByFormulary={canFilterByFormulary}
          canFilterByYourOrganizationsSuppliers={
            canFilterByYourOrganizationsSuppliers
          }
          categories={categories}
          categoryId={categoryId}
          combinedCatalogSearch={combinedCatalogSearch}
          consignmentClosetOnly={consignmentClosetOnly}
          consignmentClosets={consignmentClosets}
          currentCatalogBrowseTab={currentCatalogBrowseTab}
          defaultConsignmentClosetId={defaultConsignmentClosetId}
          defaultServiceAreaState={defaultServiceAreaState}
          formularyOnly={formularyOnly}
          manageFulfillmentAgreements={
            dmeOrder.permissions.manageFulfillmentAgreements
          }
          selectCategory={selectCategory}
          selectedCategory={selectedCategory}
          selectDefaultConsignmentCloset={selectDefaultConsignmentCloset}
          selectState={selectState}
          serviceAreaState={serviceAreaState}
          subcategoryId={subcategoryId}
          toggleConsignmentClosetFilter={toggleConsignmentClosetFilter}
          toggleFormularyFilter={toggleFormularyFilter}
          toggleYourOrganizationsSuppliersFilter={
            toggleYourOrganizationsSuppliersFilter
          }
          usesEnterpriseFeatures={
            dmeOrder.clinicalFacility.usesEnterpriseFeatures
          }
          yourOrganizationsSuppliersOnly={yourOrganizationsSuppliersOnly}
        />
      </SidebarAwareContainer>
      <QuickListButton
        canFilterByFormulary={canFilterByFormulary}
        supplierIdParam={supplierIdParam}
      />
    </SidebarAwareContainer>
  )
}

export default HybridSearch
