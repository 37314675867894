import React from "react"
import InternalSigningSignatureRequestsAccess from "./components/InternalSigningSignatureRequestsAccess"
import { login, LoginParams, postRoutingError } from "./api"
import { Doctor, SupplierInformation } from "sharedTypes"
import { Route, Switch, useHistory } from "react-router-dom"
import { withBrowserRouter } from "routers/BrowserRouter"
import ReviewerIdentificationForm from "./components/ReviewerIdentificationForm"
import {
  basePath,
  reviewerIdentificationFormPath,
  parachuteUserLoginPath,
  routingErrorPath,
} from "./urls"
import { RouteComponentProps } from "react-router-dom"
import { signOut } from "applications/Workflow/containers/Navigation/components/TopNavigation/api"
import { navigate } from "utilities/navigation"
import { handleError } from "utilities/error"
import RoutingErrorForm from "./components/RoutingErrorForm"
import { trackParachuteUserSignInEvent } from "./utilities"

type Props = {
  doctor: Doctor
  signableAccessCodeId: string
  supplierInformation?: SupplierInformation
  reviewerInformation?: {
    email: string
    firstName: string
    lastName: string
  }
}

const SigningSignatureRequestsAccess: React.FC<Props> = ({
  doctor,
  signableAccessCodeId,
  supplierInformation,
  reviewerInformation,
}: Props) => {
  const history = useHistory() as RouteComponentProps["history"]

  const handleLogin = (params: LoginParams) => {
    login(params)
      .then(({ path }) => {
        navigate(path)
      })
      .catch(handleError)
  }

  const onUserSignInLink = () =>
    trackParachuteUserSignInEvent(signableAccessCodeId)

  const REASON = "You have the wrong contact"

  const submitRoutingError = (description?: string) =>
    postRoutingError({
      reason: REASON,
      description,
    })

  const description = `A patient's order needs to be signed by Dr.
    ${doctor.firstName} ${doctor.lastName}, NPI ${doctor.npi}`

  const handleBack = () => {
    history.push(basePath)
  }

  return (
    <>
      <Switch>
        <Route path={reviewerIdentificationFormPath}>
          <ReviewerIdentificationForm
            description={description}
            login={handleLogin}
            parachuteUserLoginPath={parachuteUserLoginPath}
            onUserSignInLink={onUserSignInLink}
            handleBack={handleBack}
            reviewerEmail={reviewerInformation?.email}
            reviewerFirstName={reviewerInformation?.firstName}
            reviewerLastName={reviewerInformation?.lastName}
          />
        </Route>
        <Route path={routingErrorPath}>
          <RoutingErrorForm
            doctor={doctor}
            submitRoutingError={submitRoutingError}
            basePath={basePath}
          />
        </Route>
        <Route path={basePath}>
          <InternalSigningSignatureRequestsAccess
            doctor={doctor}
            parachuteUserLoginPath={parachuteUserLoginPath}
            login={handleLogin}
            signOut={signOut}
            onUserSignInLink={onUserSignInLink}
            supplierInformation={supplierInformation}
            reviewerIdentificationFormPath={reviewerIdentificationFormPath}
            routingErrorPath={routingErrorPath}
          />
        </Route>
      </Switch>
    </>
  )
}

export default withBrowserRouter(SigningSignatureRequestsAccess, {})
