import React from "react"
import InternalSupplierFacilitySignUp from "./components/InternalSupplierFacilitySignUp"
import { Supplier } from "./sharedTypes"
import {
  createInvitation,
  getBranches,
  getFacilities,
  getSalesReps,
  getSupplierLegalEntities,
} from "./api"

type Props = {
  suppliers: Supplier[]
}

const SupplierFacilitySignUp = ({ suppliers }: Props) => {
  return (
    <InternalSupplierFacilitySignUp
      createInvitation={createInvitation}
      suppliers={suppliers}
      getSalesReps={getSalesReps}
      getFacilities={getFacilities}
      getSupplierLegalEntities={getSupplierLegalEntities}
      getBranches={getBranches}
    />
  )
}
export default SupplierFacilitySignUp
