import React, { useState } from "react"
import {
  updateOneTimeOrderEnabled,
  updateYourOrganizationsSuppliersOnlyDefault,
} from "../api"
import * as styles from "./index.module.scss"
import { useToaster } from "components/Toaster"
import { Form, RadioButtons } from "components/form"
import { CanopyCheckboxInput } from "@parachutehealth/canopy-checkbox-input"

interface Props {
  clinicalFacilityId: string
  oneTimeOrderEnabled: boolean
  searchByProductEnabled: boolean
  yourOrganizationsSuppliersOnlyDefault: boolean
}

const SupplierAccessForm: React.FC<Props> = ({
  clinicalFacilityId,
  oneTimeOrderEnabled,
  searchByProductEnabled,
  yourOrganizationsSuppliersOnlyDefault,
}) => {
  const [
    showYourOrganizationsSuppliersOnlyCheckbox,
    setShowYourOrganizationsSuppliersOnlyCheckbox,
  ] = useState<boolean>(oneTimeOrderEnabled)
  const [
    yourOrganizationsSuppliersOnly,
    setYourOrganizationsSuppliersOnly,
  ] = useState<boolean>(yourOrganizationsSuppliersOnlyDefault)
  const { persistentAlert } = useToaster()

  const onCheckboxToggle = () => {
    const toggledValue = !yourOrganizationsSuppliersOnly
    updateYourOrganizationsSuppliersOnlyDefault({
      clinicalFacilityId,
      yourOrganizationsSuppliersOnlyDefault: toggledValue,
    })
      .then(() => {
        persistentAlert({
          message: toggledValue
            ? `"Your Organization's Suppliers Only" filter set as ON by default`
            : `"Your Organization's Suppliers Only" filter set as OFF by default`,
          severity: "success",
        })
        setYourOrganizationsSuppliersOnly(toggledValue)
      })
      .catch(() =>
        persistentAlert({
          message: "Error updating Your Organization's Suppliers Only default",
          severity: "error",
        })
      )
  }

  const onSubmit = ({ oneTimeOrderEnabled }) => {
    updateOneTimeOrderEnabled({
      clinicalFacilityId,
      oneTimeOrderEnabled,
    })
      .then(() => {
        persistentAlert({
          message: oneTimeOrderEnabled
            ? "Supplier Access set to Flexible Access"
            : "Supplier Access set to Limited Access",
          severity: "success",
        })
        setShowYourOrganizationsSuppliersOnlyCheckbox(oneTimeOrderEnabled)
      })
      .catch(() =>
        persistentAlert({ message: "Error updating", severity: "error" })
      )
  }

  return (
    <div>
      <Form
        onSubmit={onSubmit}
        submitOnChange
        initialValues={{ oneTimeOrderEnabled }}
      >
        <RadioButtons
          className={`plain ${styles.button}`}
          block
          name="oneTimeOrderEnabled"
          options={[
            {
              label: (
                <span>
                  <strong>Flexible Access · </strong> Users can place orders
                  with any supplier in the Parachute supplier network.
                </span>
              ),
              value: true,
              addOn: () => {
                if (
                  searchByProductEnabled &&
                  showYourOrganizationsSuppliersOnlyCheckbox
                ) {
                  return (
                    <div className={styles.checkbox}>
                      <CanopyCheckboxInput
                        className={styles.checkboxLabel}
                        label={
                          '"Your Organization’s Suppliers Only" filter on by default in "Search by Product".'
                        }
                        defaultChecked={yourOrganizationsSuppliersOnly}
                        onChange={onCheckboxToggle}
                      />
                    </div>
                  )
                }
                return <></>
              },
            },
            {
              label: (
                <span>
                  <strong>Limited Access · </strong>Users may only place orders
                  with your organization's suppliers.
                </span>
              ),
              value: false,
            },
          ]}
        />
      </Form>
    </div>
  )
}

export default SupplierAccessForm
