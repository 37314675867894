// @ts-strict-ignore
import React from "react"
import SpinIcon from "components/Icon/SpinIcon"
import { Employer, EmployerType } from "sharedTypes"
import classNames from "classnames"

function buttonText(
  submissionPending: boolean,
  currentEmployer: Employer,
  signNow: boolean
): string {
  const isSupplier = currentEmployer.employerType === EmployerType.Supplier
  if (isSupplier) {
    return submissionPending ? "Accepting Order" : "Accept Order"
  } else {
    if (submissionPending) {
      return "Sending Order"
    } else {
      return signNow ? "Sign and Send Order" : "Send Order"
    }
  }
}

type Props = {
  submissionPending: boolean
  currentEmployer: Employer
  submit(): void
  signNow?: boolean
  disabled?: boolean
  supplierGeneratedPriorToPushBack?: boolean
}

function SubmitButton({
  submissionPending,
  currentEmployer,
  submit,
  signNow,
  disabled,
  supplierGeneratedPriorToPushBack,
}: Props) {
  const buttonProps = {
    className: classNames("btn btn-block", {
      "btn-brand": !supplierGeneratedPriorToPushBack,
      "btn-brand-o": supplierGeneratedPriorToPushBack,
    }),
    disabled: disabled || submissionPending,
  }
  const buttonContent = (
    <>
      {submissionPending && <SpinIcon className="canopy-mie-4x" />}
      {buttonText(submissionPending, currentEmployer, signNow)}
    </>
  )

  return (
    <>
      <button {...buttonProps} type="button" onClick={submit}>
        {buttonContent}
      </button>
    </>
  )
}

export default SubmitButton
