import groupBy from "lodash/groupBy"
import { unique } from "utilities/array"

const groupLabel = (key) => {
  switch (key) {
    case "recommended":
      return "Recommended"
    case "fulfillment_agreement":
      return "Facilities on Parachute"
    case "global_npi_service":
      return "Other Facilities"
    default:
      return `Facilities from ${key}`
  }
}

const recommendedFacility = (facility, recommendedFacilities) =>
  recommendedFacilities.find((recommended) => recommended.id === facility.id)

export const groupedFacilities = (facilities, recommendedFacilities) => {
  facilities.forEach(
    (facility) =>
      (facility.group = recommendedFacility(facility, recommendedFacilities)
        ? "recommended"
        : facility.source)
  )

  const groups = groupBy(facilities, "group")
  return unique([
    "recommended",
    "fulfillment_agreement",
    ...Object.keys(groups),
  ])
    .filter((key) => groups[key])
    .map((key) => ({
      label: groupLabel(key),
      options: groups[key].map((result) => ({
        label: result.name,
        value: result.id,
        ...result,
      })),
    }))
}
