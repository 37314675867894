import React from "react"
import { Address } from "sharedTypes"
import Icon from "components/Icon"

type Props = {
  deliveryAddress: Address
  editAddress(address: Address): void
}

function EditDeliveryAddressButton({ deliveryAddress, editAddress }: Props) {
  return (
    <a
      className="link link-primary canopy-mis-2x nowrap"
      onClick={() => editAddress(deliveryAddress)}
    >
      Edit <Icon type="pencil-alt" className="canopy-mis-2x" />
    </a>
  )
}

export default EditDeliveryAddressButton
