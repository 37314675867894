export type Form = {
  setFieldValue(name: string, value: string): void
  submitForm(): void
  resetForm(): void
}

export type SelectOption = {
  label: string
  value: number | string
}

export enum TextFieldType {
  Email = "email",
  Password = "password",
  Text = "text",
  Search = "search",
}
