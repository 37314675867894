export default function loadGlassboxScript(src: string): Promise<Event> {
  return new Promise(function (resolve, reject) {
    const script = document.createElement("script")
    script.src = src
    script.onload = resolve
    script.onerror = reject
    script.id = "glassbox"
    document.head.appendChild(script)
  })
}
