import React from "react"
import Modal from "components/Modal"
import { Form, TextField, DateField } from "components/form"
import { NewOrderFormPatient } from "../sharedTypes"
import SexSelect from "components/form/Select/SexSelect"

type Props = {
  closeModal(): void
  showModal: boolean
  setPatient(patient: NewOrderFormPatient): void
  patientSearch?: {
    firstName: string
    lastName: string
    dateOfBirth: string
    sex: string
  }
  patientValidate(patient: NewOrderFormPatient): Promise<{}>
}

function NewPatientForm({
  showModal,
  closeModal,
  setPatient,
  patientSearch,
  patientValidate,
}: Props) {
  const initialValues = patientSearch
    ? {
        firstName: patientSearch.firstName,
        lastName: patientSearch.lastName,
        dateOfBirth: patientSearch.dateOfBirth,
        sex: patientSearch.sex,
      }
    : {
        firstName: "",
        lastName: "",
        dateOfBirth: "",
        sex: "",
      }

  const onSubmit = (values, actions) => {
    patientValidate(values)
      .then(() => {
        setPatient(values)
      })
      .catch((errors) => {
        actions.setErrors(errors)
      })
  }

  return (
    <>
      <Modal
        title="Create New Patient"
        show={showModal}
        cancel={closeModal}
        size="md"
      >
        <Form onSubmit={onSubmit} initialValues={initialValues}>
          <Modal.Body>
            <div className="row">
              <div className="col-lg-6">
                <TextField label="First Name" name="firstName" />
              </div>
              <div className="col-lg-6">
                <TextField label="Last Name" name="lastName" />
              </div>
            </div>
            <div className="row">
              <div className="col-lg-6">
                <DateField label="Date of Birth" name="dateOfBirth" />
              </div>
              <div className="col-lg-6">
                <SexSelect />
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button
              type="submit"
              className="btn btn-primary btn-block"
              disabled={false}
            >
              Create Patient
            </button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  )
}

export default NewPatientForm
