import React, { useState } from "react"
import UploadButton from "./UploadButton"
import UploadsTable from "./UploadsTable"
import { DownloadCsvTemplateButton } from "./DownloadCsvTemplateButton"
import * as styles from "../DocumentationOrders/tabpanels/tab-panels.module.scss"
import Link from "components/Link"

const UploadsTabPanel = ({
  supplierOrganizationId,
  possibleCsvColumns,
  requiredCsvColumns,
  uploadsIndex,
  templatePath,
  resultsUrlBuilder,
  uploadsCreate,
  uploadGuide,
}: {
  supplierOrganizationId: string
  possibleCsvColumns: string[]
  requiredCsvColumns: string[]
  uploadsIndex: ({ supplierOrganizationId, page }) => Promise<any>
  templatePath: string
  resultsUrlBuilder: Function
  uploadsCreate: (supplierOrganizationId: string, file: File) => Promise<any>
  uploadGuide?: {
    label: string
    link: string
  }
}) => {
  const [uploadsTableKey, setUploadsTableKey] = useState<number>(0)
  const afterUpload = () => {
    setUploadsTableKey((currentKey) => currentKey + 1)
  }

  return (
    <div role="tabpanel">
      <div className="d-flex">
        <p className="w-600">
          The uploaded CSV must adhere to the template format to be accepted and
          processed.
          {uploadGuide && (
            <React.Fragment>
              {" "}
              See the{" "}
              <Link
                href={uploadGuide.link}
                target="_blank"
                className="color-brand"
              >
                {uploadGuide.label}
              </Link>{" "}
              for more details and troubleshooting tips.
            </React.Fragment>
          )}
        </p>
        <div className="canopy-mis-auto">
          <UploadButton
            supplierOrganizationId={supplierOrganizationId}
            possibleCsvColumns={possibleCsvColumns}
            requiredCsvColumns={requiredCsvColumns}
            createUploadApi={uploadsCreate}
            afterUpload={afterUpload}
            templatePath={templatePath}
            uploadGuide={uploadGuide}
          />
        </div>
      </div>
      <div>
        <DownloadCsvTemplateButton templatePath={templatePath} />
      </div>
      <div className={styles.uploadTableContainer}>
        <UploadsTable
          key={uploadsTableKey}
          supplierOrganizationId={supplierOrganizationId}
          uploadsIndex={uploadsIndex}
          resultsUrlBuilder={resultsUrlBuilder}
        />
      </div>
    </div>
  )
}

export default UploadsTabPanel
