import React from "react"

enum Type {
  SignOnScreen,
  Request,
  Download,
  Print,
}

type Props = {
  type: Type
  className?: string
  children: React.ReactNode
}

const headers = {
  [Type.SignOnScreen]: "Sign On-Screen",
  [Type.Request]: "Send a Request",
  [Type.Download]: "Download, Print, Attach",
}

function HowItWorks({ type, className, children }: Props) {
  return (
    <div className={className}>
      <h4>{headers[type]}</h4>
      <div className="font-xs">{children}</div>
    </div>
  )
}

HowItWorks.Type = Type

export default HowItWorks
