import { put } from "services/api"
import * as urls from "./urls"

export const updateFulfillment = (
  dmeOrderId,
  fulfillmentId,
  fulfillment,
  employerPrefix
) => {
  return put(
    urls.updateFulfillmentUrl(dmeOrderId, fulfillmentId, employerPrefix),
    { dmeOrderFulfillment: fulfillment }
  )
    .then(({ data }) => data)
    .catch(({ response }) => Promise.reject(response.data))
}
