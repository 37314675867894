export enum Language {
  English = "English",
  Spanish = "Spanish",
  ChineseCantonese = "Chinese - Cantonese",
  ChineseMandarin = "Chinese - Mandarin",
  French = "French",
  Tagalog = "Tagalog",
  Vietnamese = "Vietnamese",
  Korean = "Korean",
  Arabic = "Arabic",
  Russian = "Russian",
  Polish = "Polish",
  Ukrainian = "Ukrainian",
}

export const LANGUAGES = Object.values(Language)
