import React from "react"
import Button from "components/form/Button"
import classNames from "classnames"
import * as styles from "./index.module.scss"

interface Props {
  onClick: () => void
  loading: boolean
  loadingText?: string
  children?: React.ReactNode
  className?: string
}

const PaginationButton = ({
  onClick,
  loading,
  loadingText,
  children,
  className,
}: Props): React.ReactElement => (
  <Button
    loadingText={loadingText}
    className={classNames("btn-rounded", styles.btnPagination, className)}
    loading={loading}
    onClick={onClick}
  >
    {children}
  </Button>
)

PaginationButton.defaultProps = {
  children: "Load More",
  loadingText: "Loading",
}

export default PaginationButton
