import * as api from "services/api"

const path = (slug) => {
  if (window?.parachute?.emrContextIndex) {
    return `/jwt/${slug}.json?emr_context_index=${window.parachute.emrContextIndex}`
  } else {
    return `/jwt/${slug}.json`
  }
}

export const fetchJwt = (slug) => api.post(path(slug))
