function getNameBy(regexp, value, reversed = false) {
  const words = value.trim().split(regexp)
  if (words.length === 2) {
    const firstName = words[reversed ? 0 : 1].trim()
    const lastName = words[reversed ? 1 : 0].trim()
    return { firstName, lastName }
  }
  return null
}

export function getName(value = ""): { firstName?: string; lastName?: string } {
  return getNameBy(/,+/, value) || getNameBy(/\s+/, value, true) || {}
}
