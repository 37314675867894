import React from "react"
import { SvgIcon } from "@material-ui/core"

type Props = {
  fontSize?: "inherit" | "default" | "small" | "large"
  classes?: string
}

const ThreePMUI: React.FC<Props> = ({ fontSize, classes }) => (
  <SvgIcon className={classes} fontSize={fontSize}>
    <path d="M20 2H4.01c-1.1 0-2 .9-2 2L2 22l4-4h14c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2zm-8 4c1.1 0 2 .9 2 2s-.9 2-2 2-2-.9-2-2 .9-2 2-2zm4 8H8v-.57c0-.81.48-1.53 1.22-1.85.85-.37 1.79-.58 2.78-.58.99 0 1.93.21 2.78.58.74.32 1.22 1.04 1.22 1.85V14z"></path>
  </SvgIcon>
)

export default ThreePMUI
