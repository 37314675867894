import React from "react"
import SeverityIcon from "components/SeverityIcon"
import {
  formatDeliveryStatus,
  getDeliveryStatusSeverity,
} from "utilities/deliveryStatus"
import classNames from "classnames"
import { Severity } from "sharedTypes"
import { sentenceCase } from "utilities/string"

type Props = {
  status?: string
  statusLevel?: string
  statusText?: string
  className?: string
}

const DeliveryStatus = ({
  status,
  statusLevel,
  statusText,
  className,
}: Props) => {
  statusLevel = statusLevel || getDeliveryStatusSeverity(status)
  statusText = statusText || formatDeliveryStatus(status)
  if (!statusText) {
    return null
  }
  return (
    <div
      className={classNames(`pill pill-${statusLevel || "default"}`, className)}
    >
      <SeverityIcon className="canopy-mie-2x" type={statusLevel as Severity} />
      {sentenceCase(statusText)}
    </div>
  )
}

export default DeliveryStatus
export const DEFAULT_STATUS = "processing"
