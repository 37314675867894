import React, { useCallback, useEffect, useState } from "react"
import {
  CarrierAuthorization,
  CarrierAuthorizationParams,
  CarrierAuthorizationsResponse,
} from "../sharedTypes"
import {
  CanopyComboboxField,
  OptionItem,
  SelectOption,
} from "@parachutehealth/canopy-combobox-field"
import { CanopyFormFieldFeedbackMessageStatuses } from "@parachutehealth/canopy-form-field-group"
import * as styles from "../index.module.scss"
import AuthorizationSuggestion from "./AuthorizationSuggestion"
import { handleError } from "utilities/error"
import { ApplicationError } from "sharedTypes"

const REVIEW_PAGE_ERROR_TYPE = "preauthorization"

type Props = {
  onSubmit(params: CarrierAuthorizationParams): Promise<void>
  getCarrierAuthorizations(
    query: string
  ): Promise<CarrierAuthorizationsResponse>
  initialAuthorizationNumber: string
}

const AuthorizationSelect = ({
  getCarrierAuthorizations,
  initialAuthorizationNumber,
  onSubmit,
}: Props) => {
  /* INITIAL STATE */
  const initialSelectedAuthorizationOption = initialAuthorizationNumber
    ? ({
        label: initialAuthorizationNumber,
        value: initialAuthorizationNumber,
      } as SelectOption)
    : ""

  const initialAuthorizationOptions = initialSelectedAuthorizationOption
    ? ([initialSelectedAuthorizationOption] as SelectOption[])
    : []

  const initialFeedback = {
    status: "success" as CanopyFormFieldFeedbackMessageStatuses,
    message: "",
  }

  /* STATE */
  const [loading, setLoading] = useState(false)
  const [feedback, setFeedback] = useState(initialFeedback)
  const [searchQuery, setSearchQuery] = useState("")
  const [authorizationOptions, setAuthorizationOptions] = useState(
    initialAuthorizationOptions
  )
  const [
    selectedAuthorizationOption,
    setSelectedAuthorizationOption,
  ] = useState(initialSelectedAuthorizationOption)

  /* CALLBACKS */
  const fetchOptions = useCallback(
    async (query: string = "") => {
      setLoading(true)

      try {
        /* Fetch authorizations matching a search query or all auths if no query */
        const {
          carrierAuthorizations,
        }: { carrierAuthorizations?: CarrierAuthorization[] } =
          (await getCarrierAuthorizations(query)) || {}

        /* Format authorizations into option items */
        const authorizationOptions: OptionItem[] = carrierAuthorizations
          ?.sort((b, a) => a.updatedAt.localeCompare(b.updatedAt))
          .map((auth, i) => ({
            label: auth.parentAuthorizationNumber,
            value: auth.parentAuthorizationNumber,
            key: i.toString(),
            updatedAt: auth.updatedAt,
          }))

        return authorizationOptions || []
      } catch (e) {
        handleError(e as ApplicationError)
      } finally {
        setLoading(false)
      }
    },
    [getCarrierAuthorizations]
  )

  const fetchAndSetOptions = useCallback(async () => {
    const authorizationOptions = (await fetchOptions()) || []

    /* Create option group with header */
    const authorizationOptionGroup = {
      label: "RECENTLY UPDATED AUTHORIZATIONS FOR THIS PATIENT",
      options: authorizationOptions,
    }

    setAuthorizationOptions(
      authorizationOptions.length ? [authorizationOptionGroup] : []
    )
  }, [fetchOptions])

  const findErrorFromReviewPage = useCallback(() => {
    const queryParams = new URLSearchParams(location.search)

    const errorFromReviewPage =
      queryParams.get("errorType") === REVIEW_PAGE_ERROR_TYPE

    if (errorFromReviewPage && !selectedAuthorizationOption && !searchQuery) {
      setFeedback({ status: "error", message: "Pre-authorization is missing" })
    } else {
      setFeedback({ status: "success", message: "" })
    }
  }, [selectedAuthorizationOption, searchQuery])

  /* EFFECTS */
  useEffect(() => {
    if (!searchQuery) fetchAndSetOptions()
    findErrorFromReviewPage()
  }, [searchQuery, fetchAndSetOptions, findErrorFromReviewPage])

  /* CUSTOM PROPERTIES AND METHODS */
  const handleSearch = async (query: string) => {
    setSearchQuery(query)

    const authorizationOptions = (await fetchOptions(query)) || []
    const authorizationValues = authorizationOptions.map((auth) => auth.value)

    const addNewOption: OptionItem = {
      label: `+ Add "${query}" as Authorization Number`,
      value: query,
      selectedLabel: query,
      addNewOption: true,
    }

    if (query && !authorizationValues.length) {
      setAuthorizationOptions([addNewOption])
    } else if (query && !authorizationValues.includes(query)) {
      setAuthorizationOptions([...authorizationOptions, addNewOption])
    } else {
      setAuthorizationOptions(authorizationOptions)
    }
  }

  const handleSelection = (option: OptionItem) => {
    setSelectedAuthorizationOption(option)
    onSubmit({ carrierAuthorizationNumber: (option?.value as string) || "" })
  }

  const handleBlur = () => {
    setSearchQuery("")
  }

  const formatSelectedLabel = (option: OptionItem | null) => {
    return ((option?.selectedLabel || option?.label) as string) || ""
  }

  const renderOption = ({ option }) => {
    if (option.addNewOption) {
      return <span className={styles.addNewOption}>{option.label}</span>
    } else {
      return (
        <AuthorizationSuggestion option={option} searchQuery={searchQuery} />
      )
    }
  }

  return (
    <CanopyComboboxField
      className={styles.authorizationSelect}
      value={selectedAuthorizationOption}
      description="It can take up to 60 minutes for new authorization numbers to appear in
        Parachute."
      feedbackMessage={feedback.message}
      feedbackMessageStatus={feedback.status}
      id="payment-auth-select"
      label="Authorization Number"
      loading={loading}
      noOptionsAvailableMessage="Parachute has not received authorization numbers for this
        patient. It may take up to 60 minutes for newly created
        authorization numbers to appear."
      // fixes ts strict error. onChange should only expect OptionItem[] when multiple is true.
      onChange={
        handleSelection as (newValue: OptionItem | OptionItem[] | null) => void
      }
      onInputChange={handleSearch}
      onBlur={handleBlur}
      options={authorizationOptions}
      optionSelectedFormatFunction={formatSelectedLabel}
      optionRenderFunction={renderOption}
      placeholder="Enter authorization number"
    />
  )
}

export default AuthorizationSelect
