import React from "react"
import { isTest } from "utilities/environment"
import { GridRowData } from "@mui/x-data-grid-pro"
import { useGraphqDataGrid } from "hooks/useGraphqlDataGrid/useGraphqlDataGrid"
import {
  GetIncomingOrdersQuery,
  IncomingDmeOrderConnection,
} from "graphql/__generated__/graphql"
import {
  ApolloError,
  ApolloQueryResult,
  FetchMoreOptions,
} from "@apollo/client"
import { incomingOrderColumnDefinitions } from "./tableColumnDefinitions"
import NoOrders from "./NoOrders"
import { DataGridOptions, FeatureFlagInput } from "sharedTypes"
import IncomingOrdersMuiDataGrid from "applications/IncomingOrders/components/IncomingOrdersMuiDataGrid"
import ClearAllLink from "components/ClinicalFilters/ClearAllLink"

interface Props {
  incomingOrderData: IncomingDmeOrderConnection | undefined
  loading: boolean
  error: ApolloError | undefined
  paginate: (args: FetchMoreOptions) => void
  refetch: (variables?) => Promise<ApolloQueryResult<GetIncomingOrdersQuery>>
  lastAccessedFacilityDashboardUrl: string
  initialDataGridOptions?: Partial<DataGridOptions>
  handleOnClear: () => void
  featureFlags: FeatureFlagInput
}

const IncomingOrdersTable: React.FC<Props> = ({
  incomingOrderData,
  loading,
  error,
  paginate,
  refetch,
  lastAccessedFacilityDashboardUrl,
  initialDataGridOptions,
  handleOnClear,
  featureFlags,
}) => {
  const { nodes, pageInfo, totalCount, pageSize, unfilteredCount } =
    incomingOrderData || {}
  const dmeOrders: Readonly<GridRowData[]> = nodes
    ? (nodes as GridRowData[])
    : []

  const { handlePagination, handleSorting, gridOptions } = useGraphqDataGrid(
    refetch,
    paginate,
    pageInfo,
    pageSize,
    initialDataGridOptions
  )

  const noOrdersToReview = !loading && unfilteredCount === 0

  if (noOrdersToReview) {
    return (
      <NoOrders
        lastAccessedFacilityDashboardUrl={lastAccessedFacilityDashboardUrl}
      />
    )
  }

  const NoRowsOverlay = () => {
    return (
      <div
        className="d-flex flex-column justify-content-center align-items-center MuiDataGrid-overlay"
        style={{ marginTop: "100px" }}
      >
        <h3
          className="canopy-typography-body-medium"
          style={{ marginBottom: "-8px" }}
        >
          No orders match your search.
        </h3>
        <ClearAllLink onClick={handleOnClear} />
      </div>
    )
  }

  return (
    <IncomingOrdersMuiDataGrid
      autoHeight
      columns={incomingOrderColumnDefinitions({
        displayRequiredActions:
          featureFlags.facilityIncomingOrdersDisplayRequiredActions,
      })}
      components={{
        NoRowsOverlay,
      }}
      disableVirtualization={isTest()}
      error={error && "An error occurred. Please try again."}
      disableColumnFilter
      disableColumnMenu
      disableSelectionOnClick
      getRowId={(row) => row.id}
      loading={loading}
      onPageChange={async (page) => await handlePagination(page)}
      onSortModelChange={async (sortModel) => await handleSorting(sortModel)}
      page={gridOptions.page}
      pageSize={pageSize}
      pagination
      paginationMode="server"
      rowCount={totalCount}
      rows={dmeOrders}
      rowsPerPageOptions={[25]}
      sortingMode="server"
      sortModel={gridOptions.sort}
    />
  )
}

export default IncomingOrdersTable
