import React from "react"
import { Field } from "formik"

import { Column, Row } from "./sharedTypes"

interface Props {
  column: Column
  rows: Row[]
}

function RadioInput({ column, rows }: Props) {
  return (
    <Field name={column.name}>
      {({ field, form }) => {
        const checked =
          column.name !== "select_all"
            ? column.value === field.value
            : form.values.employments.every(
                (e) => e.role.toString() === column.value
              )

        return (
          <input
            type={column.type}
            name={column.name}
            value={column.value}
            onChange={() => {
              if (column.name === "select_all") {
                rows.forEach((row) => {
                  const input = row[column.key] as Column
                  if (input && input.name !== "select_all") {
                    form.setFieldValue(input.name, column.value)
                  }
                })
              } else {
                form.setFieldValue(column.name, column.value)
              }
            }}
            checked={checked}
          />
        )
      }}
    </Field>
  )
}

export default RadioInput
