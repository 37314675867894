import React, { FC } from "react"
import { AsyncButton, Result } from "components/AsyncButton"
import { redirectToInboundDocumentsDashboard } from "./redirect-to-inbound-documents-dashboard"
import Link from "components/Link"

export const AttachToRequestButton: FC<{
  attachToRequest: () => Promise<Result>
  supplierId: string
}> = ({ attachToRequest, supplierId }) => {
  return (
    <AsyncButton
      Button={Link}
      onClick={attachToRequest}
      onSuccess={() => redirectToInboundDocumentsDashboard(supplierId)}
    >
      Attach to Request
    </AsyncButton>
  )
}
