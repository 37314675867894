// @ts-strict-ignore
import React from "react"
import { Field } from "formik"
import {
  convert,
  isDateValid,
  DATE_FORMAT,
  SERVER_DATE_FORMAT,
} from "utilities/date"
import DatePickerInput from "components/input/DatePickerInput"
import asQuestion from "./asQuestion"
import { SurveyQuestion, SurveyAnswerType } from "sharedTypes"
import { Values } from "../sharedTypes"
import Checkbox from "../form/Checkbox"

type Props = {
  question: SurveyQuestion
  inputId: string
}

const QuestionInputDate: React.SFC<Props> = ({ question, inputId }) => {
  return (
    <div>
      <Field name="answerValue">
        {({ form, field }) => (
          <DatePickerInput
            {...field}
            id={inputId}
            disabled={form.values.na}
            handleNavigationKey={form.submitForm}
            width="sm"
          />
        )}
      </Field>
      {question.naEnabled && (
        <Field name="na">
          {({ form, field }) => (
            <Checkbox
              {...field}
              id={`na-${question.questionId}`}
              checked={!!form.values.na}
              label={question.naLabel}
              onChange={(checked) => {
                form.setFieldValue("answerValue", "")
                form.setFieldValue("na", checked)
              }}
            />
          )}
        </Field>
      )}
    </div>
  )
}

const config = {
  mapValuesToQuestion: (values: Values) => ({
    answerType: values.na ? SurveyAnswerType.Na : SurveyAnswerType.Value,
    answerValue: values.na
      ? null
      : convert(values.answerValue, SERVER_DATE_FORMAT),
  }),
  mapQuestionToValues: (question: SurveyQuestion) => {
    if (question.answerType === SurveyAnswerType.Na) {
      return { answerValue: null, na: true }
    } else if (isDateValid(question.answerValue)) {
      return { answerValue: convert(question.answerValue, DATE_FORMAT) }
    }
    return { answerValue: null }
  },
  validate: (values: Values) => {
    if (isDateValid(values.answerValue) || values.na) {
      return {}
    }
    return { answerValue: "Invalid date" }
  },
  isButtonVisible: ({ dirty, errors, values }) => {
    return dirty && (!errors.answerValue || values.na)
  },
}
export default asQuestion(QuestionInputDate, config)
