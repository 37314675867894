// @ts-strict-ignore
import React, { useState } from "react"
import Modal from "components/Modal"
import Overlay from "components/Overlay"
import SelfSignAttestation from "components/SelfSignAttestation"
import SignOnScreenAttestation from "./SignOnScreenAttestation"
import Signature from "applications/Workflow/containers/Signature"
import SendSignatureRequestButton from "./SendSignatureRequestButton"
import { DmeOrder, SignatureType } from "sharedTypes"
import { InitialData as SignatureData } from "applications/Workflow/containers/Signature/sharedTypes"

type Props = {
  dismiss(): void
  dmeOrder: DmeOrder
  refreshDmeOrder(): Promise<void>
  createSignature(): Promise<void>
  sendSignatureRequest(sendImmediately: boolean): Promise<void>
  extraAttestationRequested: boolean
}

const SignatureModal = ({
  dismiss,
  dmeOrder,
  refreshDmeOrder,
  createSignature,
  sendSignatureRequest,
  extraAttestationRequested,
}: Props) => {
  const [modalOrder, setModalOrder] = useState(dmeOrder)
  const [loading, setLoading] = useState(true)
  const [signable, setSignable] = useState(true)
  const [isSelfSign, setSelfSign] = useState(false)
  const [isExtraAttestationChecked, setExtraAttestationChecked] = useState(
    false
  )

  const isSignOnScreen = modalOrder.signatureType === SignatureType.OnScreen

  const close = (): Promise<void> => {
    setLoading(true)
    return refreshDmeOrder()
      .then(dismiss)
      .catch(() => setLoading(false))
  }

  const signOrder = (): Promise<void> => {
    setLoading(true)
    return createSignature()
      .then(() => setLoading(false))
      .catch(() => setLoading(false))
  }

  const sendRequest = (): Promise<void> => {
    setLoading(true)
    return sendSignatureRequest(true)
      .then(() => setLoading(false))
      .catch(() => setLoading(false))
  }

  const handleOnSignatureDataChange = (signatureData: SignatureData): void => {
    setSignable(signatureData.signatureRequestSendable)
    setSelfSign(
      signatureData.selfSignDoctorId &&
        modalOrder.doctor?.doctorId === signatureData.selfSignDoctorId
    )
    setLoading(false)
  }

  const signingOrder = (): boolean => {
    return isSignOnScreen || isSelfSign
  }

  const SubmitButton = () => {
    const className = "btn btn-brand btn-block"

    const disabled =
      !signable ||
      (isSignOnScreen &&
        extraAttestationRequested &&
        !isExtraAttestationChecked)

    if (signingOrder()) {
      return (
        <button
          disabled={disabled}
          type="button"
          onClick={signOrder}
          className={className}
        >
          Sign Order
        </button>
      )
    }

    return (
      <SendSignatureRequestButton
        className={className}
        disabled={
          !signable ||
          !modalOrder.doctor ||
          modalOrder.signatureType === SignatureType.Manual
        }
        dmeOrder={modalOrder}
        onClick={sendRequest}
      />
    )
  }

  return (
    <Modal
      backdrop="static"
      cancel={close}
      show
      title="Who will sign for this order?"
      size="md"
    >
      <Overlay active={loading} showSpinner>
        <Modal.Body>
          <Signature
            dmeOrder={modalOrder}
            setDmeOrder={setModalOrder}
            onSignatureDataChange={handleOnSignatureDataChange}
          />
          {!loading && signingOrder() && (
            <div className="canopy-mbs-12x text-left">
              {isSelfSign && <SelfSignAttestation />}
              {!isSelfSign && (
                <SignOnScreenAttestation
                  extraAttestationRequested={extraAttestationRequested}
                  isExtraAttestationChecked={isExtraAttestationChecked}
                  onChange={(event) =>
                    setExtraAttestationChecked(event.target.checked)
                  }
                  doctor={modalOrder.doctor}
                />
              )}
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <div className="row">
            <div className="col-6">
              <button
                type="button"
                onClick={close}
                className="btn btn-brand-o btn-block"
              >
                Close
              </button>
            </div>
            <div className="col-6">
              <SubmitButton />
            </div>
          </div>
        </Modal.Footer>
      </Overlay>
    </Modal>
  )
}

export default SignatureModal
