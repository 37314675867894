// @ts-strict-ignore
import React, { useState, useRef, useEffect } from "react"
import Icon from "components/Icon"
import classNames from "classnames"
import compact from "lodash/compact"

export interface Option {
  id?: number | string
  checked?: boolean
  label: string
}

type Size = "lg"

interface Props {
  label: string
  options: Option[]
  size?: Size
  onClick?(option: Option): void
  onOpen?(): void
  onClose?(): void
  loading?: boolean
  menuClassName?: string
}

const Dropdown = ({
  label,
  options,
  size,
  onClick,
  onOpen,
  onClose,
  loading,
  menuClassName,
}: Props) => {
  const [expanded, setExpanded] = useState(false)
  const close = () => {
    setExpanded(false)
    onClose?.()
  }
  const toggle = () => {
    setExpanded((expanded) => {
      expanded ? onClose?.() : onOpen?.()
      return !expanded
    })
  }
  const dropdown = useRef(null)

  useEffect(() => {
    const onWindowEvent = (event) => {
      if (!dropdown.current.contains(event.target)) {
        close()
      }
    }

    window.addEventListener("mousedown", onWindowEvent)
    window.addEventListener("touchstart", onWindowEvent)
    return () => {
      window.removeEventListener("mousedown", onWindowEvent)
      window.removeEventListener("touchstart", onWindowEvent)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div
      ref={dropdown}
      className={classNames("dropdown-container canopy-mbs-4x")}
    >
      <button className="dropdown-button" onClick={toggle}>
        <strong>{label}</strong>
        <Icon
          className="canopy-mis-4x font-xxs"
          type={loading ? "circle-notch" : "chevron-down"}
          spin={loading}
        />
      </button>
      {expanded && options.length > 0 && (
        <ul
          className={classNames(
            menuClassName,
            "dropdown-menu",
            size && `dropdown-menu-${size}`,
            { "d-block": expanded }
          )}
        >
          {compact(options).map((option) => {
            return (
              <li
                key={option.id || option.label}
                onClick={() => {
                  onClick?.(option)
                  close()
                }}
              >
                <a>
                  <div>
                    <span className="flex-left">{option.label}</span>
                    {option.checked && (
                      <Icon className="canopy-mis-4x" type="check" />
                    )}
                  </div>
                </a>
              </li>
            )
          })}
        </ul>
      )}
    </div>
  )
}

export default Dropdown
