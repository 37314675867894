// @ts-strict-ignore
import React from "react"
import { MentionTextArea } from "components/form"
import { isCtrlEnter } from "utilities/browser/event"
import { EmploymentMention } from "sharedTypes"
import Suggestion, { GroupedSuggestion } from "./Suggestion"
import { groupRecords } from "./utilities/suggestions"

type Props = {
  autoFocus?: boolean
  disabled?: boolean
  fetch(term): Promise<EmploymentMention[]>
  placeholder: string
  submit(): Promise<void>
  alert: React.ReactNode
}

function CommentField({
  autoFocus,
  disabled,
  fetch,
  placeholder,
  submit,
  alert,
}: Props) {
  return (
    <MentionTextArea
      autoFocus={autoFocus}
      placeholder={placeholder}
      name="content"
      onKeyDown={(event) => isCtrlEnter(event) && submit()}
      renderSuggestion={(record: GroupedSuggestion) => (
        <Suggestion {...record} />
      )}
      fetch={(term) => fetch(term).then(groupRecords)}
      disabled={disabled}
      alert={alert}
    />
  )
}

export default CommentField
