const MONTHS = [
  "january",
  "february",
  "march",
  "april",
  "may",
  "june",
  "july",
  "august",
  "september",
  "october",
  "november",
  "december",
]

function currentDay() {
  return new Date().getDay()
}

function currentMonth() {
  return new Date().getMonth()
}

function currentYear() {
  return new Date().getFullYear()
}

function today() {
  return new Date(currentYear(), currentMonth(), currentDay())
}

function tomorrow() {
  return new Date(currentYear(), currentMonth(), currentDay() + 1)
}

function yesterday() {
  return new Date(currentYear(), currentMonth(), currentDay() - 1)
}

function findParamByType(params, type) {
  return params.find((param) => param.type === type)
}

function isToday(string) {
  return string.toLowerCase() === "today"
}

function isTomorrow(string) {
  return string.toLowerCase() === "tomorrow"
}

function isYesterday(string) {
  return string.toLowerCase() === "yesterday"
}

function matchesFormat(params, format) {
  if (params.length === 1 && format.length === 1) {
    return params[0].type === format[0]
  }
  if (params.length === 2 && format.length === 2) {
    return params[0].type === format[0] && params[1].type === format[1]
  }
  if (params.length === 3 && format.length === 3) {
    return params[0].type === format[0] && params[1].type === format[1]
  }
  return false
}

function hasDaySuffix(value) {
  return (
    value.endsWith("st") ||
    value.endsWith("nd") ||
    value.endsWith("rd") ||
    value.endsWith("th")
  )
}

function getDay(value) {
  return value.substring(0, value.length - 2)
}

function deduceParams(string) {
  const values = getValues(string)
  return values.map((value) => {
    const index = MONTHS.indexOf(value.toLowerCase())
    if (index >= 0) {
      return { type: "month", value: index }
    } else if (hasDaySuffix(value)) {
      return { type: "day", value: getDay(value) }
    }
    return { type: "unknown", value }
  })
}

function getValues(string) {
  return string.split(" ").filter((value) => value !== "of")
}

function getDateByMonth(params) {
  const month = params[0]
  return new Date(currentYear(), month.value, 1)
}

function getDateByMonthAndDay(params) {
  const month = findParamByType(params, "month")
  const day = findParamByType(params, "day")
  return new Date(currentYear(), month.value, day.value)
}

function getDateByMonthDayAndYear(params) {
  const month = findParamByType(params, "month")
  const day = findParamByType(params, "day")
  const year = params.find((param) => param.type === "unknown")
  return new Date(year.value, month.value, day.value)
}

export default function parse(string) {
  if (isToday(string)) {
    return today()
  } else if (isTomorrow(string)) {
    return tomorrow()
  } else if (isYesterday(string)) {
    return yesterday()
  }

  const params = deduceParams(string)

  if (matchesFormat(params, ["month"])) {
    return getDateByMonth(params)
  }

  if (
    matchesFormat(params, ["month", "day"]) ||
    matchesFormat(params, ["day", "month"])
  ) {
    return getDateByMonthAndDay(params)
  }

  if (
    matchesFormat(params, ["month", "day", "year"]) ||
    matchesFormat(params, ["day", "month", "year"])
  ) {
    return getDateByMonthDayAndYear(params)
  }

  return null
}
