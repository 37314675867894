import { post } from "services/api"
import { doctorApprovalsUrl, doctorDenialsUrl } from "./urls"
import { DmeOrder } from "sharedTypes"

export const approve = (dmeOrder: DmeOrder) => {
  return post(doctorApprovalsUrl(), { approvals: { orderIds: [dmeOrder.id] } })
}

export const deny = ({ reason = "", description = "", dmeOrderId }) => {
  return post(doctorDenialsUrl(), {
    dmeOrderId,
    denials: { reason, description },
  })
}
