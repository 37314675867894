export function uploadResultsUrl(uploadRecordId: string): string {
  return `uploads/${uploadRecordId}/upload_results`
}

export function dmeOrderUploadResultsUrl(uploadRecordId: string): string {
  return `dme_order_csv_uploads/${uploadRecordId}/upload_results`
}

export function uploadResultsWithOnlyErrorsUrl(uploadRecordId: string): string {
  return `uploads/${uploadRecordId}/upload_results?only_error_rows=true`
}
