import { CanopyButton } from "@parachutehealth/canopy-button"
import { CanopyDialogModal } from "@parachutehealth/canopy-dialog-modal"
import { CanopyForm } from "@parachutehealth/canopy-form"
import { CanopyRadioInputField } from "@parachutehealth/canopy-radio-input-field"
import { CanopyTextareaField } from "@parachutehealth/canopy-textarea-field"
import { CanopyRequiredFieldKey } from "@parachutehealth/canopy-required-field-key"
import { CanopyNotice } from "@parachutehealth/canopy-notice"
import React from "react"
import { cancelReasonRadioOptions, CancelReasons } from "./utils"
import { Field as FormikField, Formik, FormikProps } from "formik"
import { EventCategory, trackEvent } from "utilities/tracking"

type CancelOrderWithReasonModalProps = {
  handleCancel: (cancelReason: string, cancelComment?: string) => void
  onClose: () => void
  isOpen: boolean
}

interface CancelOrderWithReasonFormValues {
  cancelReason: string
  cancelComment?: string
}

const CancelOrderWithReasonModal: React.FC<CancelOrderWithReasonModalProps> = (
  props: CancelOrderWithReasonModalProps
) => {
  const { handleCancel, onClose, isOpen } = props
  const initialValues = {
    cancelReason: "",
    cancelComment: "",
  }

  const activatorElementRef = React.useRef<HTMLButtonElement>(null)

  const validate = ({ cancelReason, cancelComment }) => {
    const errors: {
      cancelReason?: string
      cancelComment?: string
    } = {}

    if (!cancelReason) {
      errors.cancelReason = "Provide a cancellation reason"
    }

    if (cancelReason === CancelReasons.Other && !cancelComment) {
      errors.cancelComment = "Add a comment to cancel order"
    }

    return errors
  }

  const cancelOrderButton = (
    <CanopyButton
      variant="danger"
      type="submit"
      form="cancelWithReasonForm"
      data-testid="cancel-order-submit-button"
    >
      Cancel order
    </CanopyButton>
  )

  const closeModalButton = (
    <CanopyButton onClick={onClose} variant="secondary">
      Close
    </CanopyButton>
  )

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={(values) => {
        trackEvent(
          EventCategory.FacilityCancellationWithReason,
          "cancel_order_with_reason_order_canceled"
        )
        handleCancel(values.cancelReason, values.cancelComment)
      }}
      validate={validate}
      validateOnBlur={false}
      enableReinitialize
    >
      {({
        values,
        handleSubmit,
        errors,
        touched,
      }: FormikProps<CancelOrderWithReasonFormValues>) => {
        return (
          <CanopyDialogModal
            activatorElementRef={activatorElementRef}
            onClose={onClose}
            open={isOpen}
            header="Cancel order"
            primaryFooterButton={cancelOrderButton}
            secondaryFooterButton={closeModalButton}
            size="medium"
          >
            <p>Provide a reason for canceling the order</p>
            <CanopyForm onSubmit={handleSubmit} id="cancelWithReasonForm">
              <CanopyRequiredFieldKey />
              <FormikField
                as={CanopyRadioInputField}
                label="Select reason"
                name="cancelReason"
                options={cancelReasonRadioOptions}
                required
                feedbackMessage={errors.cancelReason}
              />
              <FormikField
                as={CanopyTextareaField}
                label="Add a comment"
                name="cancelComment"
                description="Provide additional information on why you’re canceling the order"
                optional={values.cancelReason !== CancelReasons.Other}
                required={values.cancelReason === CancelReasons.Other}
                feedbackMessage={touched.cancelComment && errors.cancelComment}
              />
              {values.cancelReason ===
                CancelReasons.PatientNeedsToBeSeenFirst && (
                <FormikField
                  as={CanopyNotice}
                  message='Select "Snooze order" under Order actions to complete it once the patient has been seen'
                  title="Do you want to snooze this order instead?"
                  variant="warning"
                />
              )}
            </CanopyForm>
          </CanopyDialogModal>
        )
      }}
    </Formik>
  )
}

export default CancelOrderWithReasonModal
