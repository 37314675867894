import { put } from "services/api"
import { emailNotificationsOptInUrl } from "./urls"

export const optOutOfEmailNotifications = (
  userId: string,
  pendingSigningSignatureRequestEmail: boolean,
  optOutReason: string
): Promise<void> => {
  return put(emailNotificationsOptInUrl, {
    userId,
    pendingSigningSignatureRequestEmail,
    optOutReason,
  }).then(() => Promise.resolve())
}
