// @ts-strict-ignore
type Field = {
  onChange?(event: React.FormEvent): void
  onBlur?(event: React.FormEvent): void
}

type Form = {
  submitForm(): void
}

export function handleChange({
  field,
  onChange,
}: {
  field: Field
  onChange?(event: React.FormEvent)
}) {
  return (event) => {
    onChange && onChange(event)
    field.onChange(event)
  }
}

export function handleBlur({
  field,
  form,
  onBlur,
  submitOnBlur,
}: {
  field: Field
  form: Form
  onBlur?(event: React.FormEvent)
  submitOnBlur?: boolean
}) {
  return (event) => {
    onBlur && onBlur(event)
    field.onBlur(event)
    submitOnBlur && form.submitForm()
  }
}
