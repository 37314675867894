import React from "react"
import Link from "../Link"
import Icon from "../Icon"

interface Props {
  onClick?(): void
  path: string
  labelText: string
}

const BackToPageButton = ({
  onClick,
  path,
  labelText,
}: Props): React.ReactElement => {
  return (
    <div className="canopy-pbe-8x">
      <Link className="link" href={path} onClick={onClick}>
        <Icon type="arrow-left" className="canopy-pie-4x" />
        {labelText}
      </Link>
    </div>
  )
}

export default BackToPageButton
