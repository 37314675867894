import React from "react"
import NameAndLocationForm from "./NameAndLocationForm"
import ContactDetailsForm from "./ContactDetailsForm"
import { useToaster } from "components/Toaster"

type Props = {
  user: {
    firstName: string
    lastName: string
    timeZone: string
    email?: string
    mobileNumber?: string
    phoneNumber?: string
    phoneExtension?: string
  }
}

const ContactInformation = ({ user }: Props) => {
  const { persistentAlert } = useToaster()

  const onSuccess = () => {
    persistentAlert({
      message: "Contact preferences saved.",
      severity: "success",
    })
  }

  return (
    <>
      <NameAndLocationForm
        firstName={user.firstName}
        lastName={user.lastName}
        timeZone={user.timeZone}
        onSuccess={onSuccess}
      />
      <hr />
      <ContactDetailsForm
        email={user.email}
        mobileNumber={user.mobileNumber}
        phoneNumber={user.phoneNumber}
        phoneExtension={user.phoneExtension}
        onSuccess={onSuccess}
      />
    </>
  )
}

export default ContactInformation
