// @ts-strict-ignore
import React, { createContext, useContext, useReducer, useMemo } from "react"
import produce from "immer"
import { ColumnSettings, DashboardView, FilterSettings } from "../sharedTypes"
import { createViewAPI, ViewAPI } from "./api"
import {
  FilterOptions,
  FilterValues,
} from "../components/DashboardFilters/sharedTypes"
import SupplierOrganizationContext from "../SupplierOrganizationContext"
import { SortDirection } from "../../../sharedTypes"
import { viewHasUnsavedChanges, getDirtyFilters } from "./comparison"

export enum ActionType {
  CLEAR_FILTER_VALUES = "CLEAR_FILTER_VALUES",
  SET_CURRENT_VIEW_DATA = "SET_CURRENT_VIEW_DATA",
  SWITCH_VIEW = "SWITCH_VIEW",
  SAVE_AS_NEW_VIEW = "SAVE_AS_NEW_VIEW",
  UPDATE_FILTER_VALUES = "UPDATE_FILTER_VALUES",
  UPDATE_FILTER_OPTIONS = "UPDATE_FILTER_OPTIONS",
  UPDATE_SORT = "UPDATE_SORT",
  UPDATE_SETTINGS = "UPDATE_SETTINGS",
  UPDATE_VIEW = "UPDATE_VIEW",
  RESET_VIEW = "RESET_VIEW",
  DESTROY_VIEW = "DESTROY_VIEW",
}

export type ViewContextState = {
  currentView: DashboardView
  filterOptions: FilterOptions
  hasUnsavedChanges: boolean
  dirtyFilters: { [key: string]: boolean }
}
type InternalViewContextState = ViewContextState & {
  savedViewState: DashboardView
}

export type ViewContextAction =
  | {
      type: ActionType.CLEAR_FILTER_VALUES
      data: {}
    }
  | {
      type: ActionType.RESET_VIEW
      data: {}
    }
  | {
      type: ActionType.SET_CURRENT_VIEW_DATA
      data: ViewContextState
    }
  | {
      type: ActionType.SWITCH_VIEW
      data: { viewId: string }
    }
  | {
      type: ActionType.SAVE_AS_NEW_VIEW
      data: { name: string }
    }
  | {
      type: ActionType.UPDATE_FILTER_VALUES
      data: FilterValues
    }
  | {
      type: ActionType.UPDATE_FILTER_OPTIONS
      data: FilterValues
    }
  | {
      type: ActionType.UPDATE_SORT
      data: { sortDirection: SortDirection; sortColumn: string }
    }
  | {
      type: ActionType.UPDATE_SETTINGS
      data: { filterSettings: FilterSettings; columnSettings: ColumnSettings }
    }
  | {
      type: ActionType.UPDATE_VIEW
      data: {}
    }
  | {
      type: ActionType.RESET_VIEW
      data: {}
    }
  | {
      type: ActionType.DESTROY_VIEW
      data: {}
    }

export type ViewContextValue = {
  state: ViewContextState
  dispatch: (ViewContextAction) => void
}

export const ViewContext = createContext<ViewContextValue>(null)

const hasUnsavedChanges = (
  internalViewContextState: InternalViewContextState
) =>
  // default view updates automatically when fetching results so it is always up to date
  // https://github.com/parachutehealth/app-workflow/blob/master/app/services/supplier_organizations/dashboard/results.rb#L48
  !internalViewContextState.currentView.isDefault &&
  viewHasUnsavedChanges(
    internalViewContextState.currentView,
    internalViewContextState.savedViewState
  )

const calculateDirtyFilters = (
  internalViewContextState: InternalViewContextState
) => {
  if (internalViewContextState.currentView.isDefault) return {}

  return getDirtyFilters(
    internalViewContextState.savedViewState.filterValues,
    internalViewContextState.currentView.filterValues
  )
}

const reducer = (
  state: InternalViewContextState,
  action: ViewContextAction
): InternalViewContextState => {
  switch (action.type) {
    case ActionType.SET_CURRENT_VIEW_DATA:
      return {
        ...action.data,
        savedViewState: action.data.currentView,
        hasUnsavedChanges: false,
        dirtyFilters: {},
      }
    case ActionType.CLEAR_FILTER_VALUES:
      return produce(state, (draft) => {
        draft.currentView.filterValues = {}
        draft.hasUnsavedChanges = hasUnsavedChanges(draft)
        draft.dirtyFilters = calculateDirtyFilters(draft)
      })
    case ActionType.UPDATE_FILTER_VALUES:
      return produce(state, (draft) => {
        draft.currentView.filterValues = {
          ...state.currentView.filterValues,
          ...action.data,
        }
        draft.hasUnsavedChanges = hasUnsavedChanges(draft)
        draft.dirtyFilters = calculateDirtyFilters(draft)
      })
    case ActionType.UPDATE_FILTER_OPTIONS:
      return produce(state, (draft) => {
        draft.filterOptions[action.data.name] = action.data.options
      })
    case ActionType.UPDATE_SORT:
      return produce(state, (draft) => {
        draft.currentView.sortColumn = action.data.sortColumn
        draft.currentView.sortDirection = action.data.sortDirection
        draft.hasUnsavedChanges = hasUnsavedChanges(draft)
      })
    case ActionType.UPDATE_SETTINGS:
      return produce(state, (draft) => {
        draft.currentView.filterSettings = action.data.filterSettings
        draft.currentView.columnSettings = action.data.columnSettings
        draft.hasUnsavedChanges = hasUnsavedChanges(draft)
      })
    case ActionType.RESET_VIEW:
      return produce(state, (draft) => {
        draft.hasUnsavedChanges = false
        draft.dirtyFilters = {}
        draft.currentView = state.savedViewState
      })
    default:
      return state
  }
}

async function switchView(
  api: ViewAPI,
  viewId: string,
  dispatch: (action: ViewContextAction) => void
) {
  await api.setActiveView(viewId)
  const newInitialValues = await api.getInitialValues()
  const setCurrentViewAction = {
    type: ActionType.SET_CURRENT_VIEW_DATA,
    data: newInitialValues,
  }
  dispatch(setCurrentViewAction)
}

export const createAsyncDispatchMiddleware = (
  dispatch: (action: ViewContextAction) => void,
  api: ViewAPI
) => async (state: ViewContextState, action: ViewContextAction) => {
  switch (action.type) {
    case ActionType.SAVE_AS_NEW_VIEW:
      const { ...createViewParams } = state.currentView
      const { externalId: viewId } = await api.createView({
        ...createViewParams,
        name: action.data.name,
      })
      await switchView(api, viewId, dispatch)
      return
    case ActionType.SWITCH_VIEW:
      await switchView(api, action.data.viewId, dispatch)
      return
    case ActionType.UPDATE_VIEW:
      const {
        filterValues,
        filterSettings,
        columnSettings,
        sortColumn,
        sortDirection,
      } = state.currentView
      await api.updateView(state.currentView.externalId, {
        filterValues,
        filterSettings,
        columnSettings,
        sortColumn,
        sortDirection,
      })
      const setCurrentViewAction: ViewContextAction = {
        type: ActionType.SET_CURRENT_VIEW_DATA,
        data: state,
      }
      dispatch(setCurrentViewAction)
      return
    case ActionType.DESTROY_VIEW:
      await api.deleteView(state.currentView.externalId)
      const defaultView = (await api.getDashboardViews()).find(
        (a) => a.isDefault
      )
      const newInitialValues: ViewContextState = {
        currentView: defaultView,
        filterOptions: state.filterOptions,
        hasUnsavedChanges: false,
        dirtyFilters: {},
      }
      const actionToDispatch: ViewContextAction = {
        type: ActionType.SET_CURRENT_VIEW_DATA,
        data: newInitialValues,
      }
      dispatch(actionToDispatch)
      return
    default:
      dispatch(action)
  }
}
export const ViewContextProvider: React.FC<{
  initialState: ViewContextState
}> = ({ children, initialState }) => {
  const supplierOrganizationContext = useContext(SupplierOrganizationContext)
  const [state, dispatch] = useReducer(reducer, {
    hasUnsavedChanges: false,
    ...initialState,
    savedViewState: initialState.currentView,
  })
  const asyncDispatchMiddleware = useMemo(
    () =>
      createAsyncDispatchMiddleware(
        dispatch,
        createViewAPI(supplierOrganizationContext.supplierOrganizationId)
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [reducer]
  )
  return (
    <ViewContext.Provider
      value={{
        state,
        dispatch: (action: ViewContextAction) =>
          asyncDispatchMiddleware(state, action),
      }}
    >
      {children}
    </ViewContext.Provider>
  )
}
