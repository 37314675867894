import React, { useState } from "react"
import { Button } from "components/form"
import { createVerificationEmail } from "./api"
import { handleError } from "utilities/error"
import emailSuccessIllustration from "email-success-illustration.png"
import emailErrorIllustration from "email-error-illustration.png"

interface Props {
  doctorId: string
  reviewerId: string
}

const ReviewerExpiredVerificationOptInLinkForm = (props: Props) => {
  const [showSuccess, setShowSuccess] = useState(false)
  const onSubmit = () => {
    createVerificationEmail(props.doctorId, props.reviewerId)
      .then(() => setShowSuccess(true))
      .catch(() => handleError())
  }

  const renderForm = () => (
    <>
      <div className="text-center">
        <img
          className="canopy-mbe-8x"
          src={emailErrorIllustration}
          width="460"
        />
        <h1> Verification link expired</h1>
        <p>
          Your email verification link has expired. Resend the verification
          email to opt-in to receive signature requests digitally.
        </p>
        <Button
          type="submit"
          className="canopy-mbs-12x btn-brand"
          onClick={onSubmit}
        >
          Resend Verification Link
        </Button>
      </div>
    </>
  )

  const renderSuccess = () => (
    <>
      <div className="text-center">
        <img
          className="canopy-mbe-8x"
          src={emailSuccessIllustration}
          width="460"
        />
        <h1> New verification link sent</h1>
        <p>A verification link has been sent to your email address.</p>
      </div>
    </>
  )

  return showSuccess ? renderSuccess() : renderForm()
}

export default ReviewerExpiredVerificationOptInLinkForm
