import React from "react"
import Select from "components/form/Select"

interface Props {
  label?: string
  name?: string
  defaultTimeZone?: string
}

const US_TIME_ZONES = [
  "America/Adak",
  "Hawaii",
  "Alaska",
  "America/Anchorage",
  "America/Metlakatla",
  "America/Nome",
  "America/Sitka",
  "America/Yakutat",
  "Pacific Time (US & Canada)",
  "America/Boise",
  "Arizona",
  "Mountain Time (US & Canada)",
  "America/Indiana/Knox",
  "America/Indiana/Tell_City",
  "America/Menominee",
  "America/North_Dakota/Beulah",
  "America/North_Dakota/Center",
  "America/North_Dakota/New_Salem",
  "Central Time (US & Canada)",
  "America/Detroit",
  "America/Indiana/Marengo",
  "America/Indiana/Petersburg",
  "America/Indiana/Vevay",
  "America/Indiana/Vincennes",
  "America/Indiana/Winamac",
  "America/Kentucky/Louisville",
  "America/Kentucky/Monticello",
  "Eastern Time (US & Canada)",
  "Indiana (East)",
]

export const getTimeZoneOptions = (defaultTimeZone?: string) => {
  return US_TIME_ZONES.map((timeZone) => {
    if (timeZone === defaultTimeZone) {
      return { label: `${timeZone} (default)`, value: timeZone }
    }
    return { label: timeZone, value: timeZone }
  })
}

function TimezoneSelect({ label, name, defaultTimeZone }: Props) {
  const timezoneOptions = getTimeZoneOptions(defaultTimeZone)
  return <Select label={label} name={name || ""} options={timezoneOptions} />
}

TimezoneSelect.defaultProps = {
  label: "Time zone",
  name: "timeZone",
}

export default TimezoneSelect
