import isEqual from "lodash.isequal"
import omit from "lodash.omit"
import { union } from "lodash"
import {
  DirtyFilters,
  Filters,
} from "applications/SupplierDashboard/sharedTypes"

export const isFilterPresent = (value) => {
  if (Array.isArray(value)) {
    return value.filter(Boolean).length > 0
  }
  return value !== undefined && value !== null && value !== ""
}

export const isAnyFilterPresent = (filters) => {
  const EXCLUDED_KEYS = [
    "sort",
    "direction",
    "validationContext",
    "errors",
    "page",
    "clear",
  ]
  const keys = Object.keys(filters)
  const filteredKeys = keys.filter((key) => !EXCLUDED_KEYS.includes(key))
  return (
    filteredKeys.map((key) => filters[key]).filter(isFilterPresent).length > 0
  )
}

const isAnyFilterDirty = (savedFilters, currentFilters) => {
  const dirtyFilters = getDirtyFilters(savedFilters, currentFilters)
  return Object.values(dirtyFilters).includes(true)
}

const hasEqualFilters = (savedFilters, currentFilters) => {
  const EXCLUDED_KEYS = [
    "validationContext",
    "errors",
    "page",
    "clear",
    "query",
  ]
  return !isAnyFilterDirty(
    omit(savedFilters, EXCLUDED_KEYS),
    omit(currentFilters, EXCLUDED_KEYS)
  )
}

const hasEqualSettings = (savedSettings, currentSettings) => {
  return (
    isEqual(savedSettings.columns, currentSettings.columns) &&
    isEqual(savedSettings.filters, currentSettings.filters)
  )
}

export const anyUnsavedChanges = ({
  savedFilters,
  currentFilters,
  savedSettings,
  currentSettings,
}) =>
  !hasEqualFilters(savedFilters, currentFilters) ||
  !hasEqualSettings(savedSettings, currentSettings)

export const getDirtyFilters = (
  savedFilters: Filters,
  currentFilters: Filters
): DirtyFilters => {
  const dirtyFilters: DirtyFilters = {}
  const filters: string[] = union(
    Object.keys(savedFilters),
    Object.keys(currentFilters)
  )
  filters.forEach((name: string): void => {
    dirtyFilters[name] = !isEqual(
      normalizeFilterValue(savedFilters[name]),
      normalizeFilterValue(currentFilters[name])
    )
  })
  return dirtyFilters
}

export const normalizeFilterValue = (value) => {
  if (Array.isArray(value) && value.length === 0) {
    return undefined
  }
  return value
}
