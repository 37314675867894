import React, { useContext } from "react"
import { Link } from "react-router-dom"
import WorkflowPageContext from "context/WorkflowPage"
import { productsPath } from "applications/Workflow/routes"
import { SidebarTab } from "applications/Workflow/containers/Navigation/sharedTypes"
import * as styles from "../components/CartLineItemGroup/CartLineItemGroup.module.scss"

interface Props {
  canUpdate: boolean
  supplierId?: string
  cartIsEmpty?: boolean
}

const AddProductsLink: React.FC<Props> = ({
  canUpdate,
  supplierId,
  cartIsEmpty,
}: Props) => {
  const { setSidebarTab } = useContext(WorkflowPageContext)
  if (!canUpdate) return <></>

  const onClick = () => {
    setSidebarTab(SidebarTab.Closed)
  }

  return (
    <div className={styles.cartItem}>
      <Link
        className="btn btn-brand-o"
        to={productsPath(supplierId)}
        onClick={onClick}
      >
        {cartIsEmpty ? "Add Items" : "Add more items"}
      </Link>
    </div>
  )
}

export default AddProductsLink
