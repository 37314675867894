import { post } from "services/api"
import { loginPath, postRoutingErrorPath } from "./urls"
import { handleError } from "utilities/error"

export type DoctorParams = {
  doctorId: string
}

export type ReviewerParams = {
  reviewer: {
    email: string
    firstName: string
    lastName: string
  }
}

type TrackingParams = {
  externalTrackerVisitId?: string
}

export type LoginParams = (DoctorParams | ReviewerParams) & TrackingParams

export function login(params: LoginParams): Promise<{ path: string }> {
  return post(loginPath, params)
    .then(({ data }) => data)
    .catch(({ response }) => Promise.reject(response.data))
}

export function postRoutingError({
  reason,
  description,
}: {
  reason: string
  description?: string
}) {
  return post(postRoutingErrorPath, {
    reason,
    description,
  })
    .then(({ data }) => data)
    .catch(handleError)
}
