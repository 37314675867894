import React from "react"
import Icon from "components/Icon"
import { NetworkCoverage } from "sharedTypes"

type Props = {
  networkCoverage?: NetworkCoverage
}

const InsuranceNetworkWarning = ({ networkCoverage }: Props) => {
  let footerClass
  let footerText
  switch (networkCoverage) {
    case NetworkCoverage.Unknown:
      footerClass = "info"
      footerText = "Insurance acceptance unknown"
      break
    case NetworkCoverage.OutOfNetwork:
      footerClass = "alert"
      footerText = "Insurance not accepted"
      break
    case NetworkCoverage.PrimaryOutOfNetwork:
      footerClass = "alert-white"
      footerText = "Primary insurance not accepted"
      break
    default:
      footerClass = "empty"
      footerText = " "
  }

  const icon = () => {
    if (networkCoverage === NetworkCoverage.PrimaryOutOfNetwork) {
      return <Icon type="flag" className="color-dark-gray" />
    } else if (networkCoverage !== NetworkCoverage.InNetwork) {
      return <Icon type="exclamation-triangle" />
    }
  }

  return (
    <div
      className={`insurance-network-warning insurance-network-warning-${footerClass}`}
    >
      {icon()}
      <span className="canopy-mis-2x">{footerText}</span>
    </div>
  )
}

export default InsuranceNetworkWarning
