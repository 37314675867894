import { employerPrefix, EmployerType } from "utilities/url"

export function getSalesRepsUrl(
  employerType: EmployerType,
  employerId: string
): string {
  return `${employerPrefix(employerType, employerId)}/sales_reps.json`
}

export function salesRepsUrl(
  dmeOrderId,
  employerType?: EmployerType,
  employerId?: string
) {
  return `${employerPrefix(
    employerType,
    employerId
  )}/dashboard/dme_orders/${dmeOrderId}/sales_reps.json`
}
