import React, { useState } from "react"
import Modal from "components/Modal"
import { removeOverlappingZips } from "applications/SupplierLegalEntityServiceAreaConflict/api"
import { CanopyButton } from "@parachutehealth/canopy-button"

export type ZipCodeOverlap = {
  id?: string
  name: string
  zips: string[]
}

type Props = {
  zipOverlaps: ZipCodeOverlap[]
  overlappingZipCodesCount: number
  currentRegionName: string
  regionId: string
  supplierId: string
  regionType: string
  path: string
}

const ZipCodeConflictModal = (props: Props) => {
  const [show, setShow] = useState(true)
  const cancel = () => {
    setShow(false)
  }

  const resolveOverlaps = (supplierId: string, regionId: string) => {
    removeOverlappingZips(
      props.path,
      supplierId,
      regionId,
      props.zipOverlaps
    ).then(() => {
      setShow(false)
    })
  }

  const headerText =
    props.regionType === "SalesRepTerritory"
      ? "Territory Conflict"
      : "Service Area Conflict"
  const actionMessage = () => {
    if (props.regionType === "SalesRepTerritory") {
      return `${props.overlappingZipCodesCount} of the zip codes entered overlap with another sales rep territory. By clicking ‘Remove Overlaps’, you will remove these zip codes from the sales rep territories below`
    } else
      return `${props.overlappingZipCodesCount} of the zip codes entered overlap with another legal entity’s service area. By clicking ‘Remove Overlaps’, you will remove these zip codes from the legal entities below`
  }

  const overlapZipList = (overlapZips: string[]) => {
    return overlapZips.map((zip) => {
      return (
        <li key={zip} className="canopy-mbe-2x">
          {zip}
        </li>
      )
    })
  }
  const overlaps = props.zipOverlaps.map((zipCodeOverlap) => (
    <div key={zipCodeOverlap.name}>
      <div>{zipCodeOverlap.name}</div>
      <ul className="canopy-pis-8x font-xs">
        {overlapZipList(zipCodeOverlap.zips)}
      </ul>
    </div>
  ))
  return (
    <>
      <Modal show={show} cancel={cancel}>
        <Modal.Header>{headerText}</Modal.Header>
        <Modal.Body>
          <div>
            <div className="alert alert-danger">
              <p>
                <strong> Action Required</strong>
              </p>
              <p>{actionMessage()}</p>
            </div>
            <br />
            <p>
              <strong>List of Overlapping Zip Codes</strong>
            </p>
            {overlaps}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <CanopyButton variant="secondary" onClick={cancel}>
            Cancel
          </CanopyButton>
          <CanopyButton
            variant="primary"
            className="canopy-mis-6x"
            onClick={() => resolveOverlaps(props.supplierId, props.regionId)}
          >
            Remove Overlaps
          </CanopyButton>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default ZipCodeConflictModal
