import React from "react"
import { Field } from "formik"
import asQuestion from "./asQuestion"
import Textarea from "components/TextArea"
import { SurveyQuestion, SurveyAnswerType } from "sharedTypes"
import { Values } from "../sharedTypes"
import { isTabOrEnter } from "utilities/browser/event"

type Props = {
  inputId: string
  isSubmitting: boolean
}

const QuestionOptional = React.forwardRef<HTMLInputElement, Props>(
  ({ inputId, isSubmitting }, ref) => {
    return (
      <Field name="answerValue">
        {({ form, field }) => (
          <Textarea
            ref={ref}
            {...field}
            className="form-control textarea"
            id={inputId}
            disabled={isSubmitting}
            onKeyDown={(event) => isTabOrEnter(event) && form.handleSubmit()}
          />
        )}
      </Field>
    )
  }
)
const config = {
  mapValuesToQuestion: (values: Values) => ({
    answerType: SurveyAnswerType.Value,
    answerValue: values.answerValue,
  }),
  mapQuestionToValues: (question: SurveyQuestion) => ({
    answerValue: question.answerValue || "",
  }),
  validate: () => ({}),
  isButtonVisible: ({ dirty, isInitial }) => dirty || isInitial,
}
export default asQuestion(QuestionOptional, config)
