import {
  EventCategory,
  trackEvent as trackingTrackEvent,
} from "utilities/tracking"

const EVENT_CATEGORY = EventCategory.Activation
type EventType =
  | "search-patients"
  | "click-patient-search-cta"
  | "click-patient-search-result"

export const trackEvent = (eventType: EventType) =>
  trackingTrackEvent(EVENT_CATEGORY, eventType)
