import React, {
  SetStateAction,
  Dispatch,
  useEffect,
  useState,
  useMemo,
} from "react"
import {
  DoctorSignatureRequest,
  fetchSignatureRequests,
  signatureStatus,
} from "../../api"
import SignatureRequestsToReview from "./components/SignatureRequestsToReview"
import NoSignatureRequestsToReview from "./components/NoSignatureRequeststoReview"
import { useDebounce } from "hooks/useDebounce"
import { useDoctorContext } from "../../hooks/useDoctorContext"
import { Grid, Paper } from "@material-ui/core"
import * as styles from "./index.module.scss"
import { heightCalculation } from "../../utilities/heightCalculation"
import { useFlashContext } from "../../hooks/useFlashContext"
import { useStyles } from "themes/theme"
import classNames from "classnames"

const paperHeightStyles = (flash: boolean) => ({
  paper: {
    height: heightCalculation(flash),
  },
})

interface Props {
  patientSearchQuery: string
  setPatientSearchQuery: Dispatch<SetStateAction<string>>
  currentUserEmail: string
  currentUserEligibleForFacilitySignup: boolean
  redirectToFacilitySignupPage: boolean
  notificationBadgeStatusChecker: (doctorId) => void
  actionableSignatureRequestsStatus: { review: boolean; sign: boolean }
}

const SignatureRequestReviewTab = ({
  patientSearchQuery,
  setPatientSearchQuery,
  currentUserEmail,
  currentUserEligibleForFacilitySignup,
  redirectToFacilitySignupPage,
  notificationBadgeStatusChecker,
  actionableSignatureRequestsStatus,
}: Props) => {
  const debouncedFetch = useDebounce(fetchSignatureRequests)
  const [signatureRequests, setSignatureRequests] = useState<
    DoctorSignatureRequest[]
  >([])
  const [
    selectedSignatureRequestGid,
    setSelectedSignatureRequestGid,
  ] = useState("")
  const [loadingSignatureRequests, setLoadingSignatureRequests] = useState(true)
  const [updatingSearchQuery, setUpdatingSearchQuery] = useState(false)

  const { doctor, updatingDoctor } = useDoctorContext()
  const flash = useFlashContext()
  const muiStyles = useMemo(() => paperHeightStyles(flash), [flash])
  const muiClasses = useStyles(muiStyles)

  useEffect(() => {
    setLoadingSignatureRequests(true)
    debouncedFetch({
      doctorId: doctor.id,
      searchParameters: {
        status: signatureStatus.NEEDS_REVIEW,
        searchKeywords: patientSearchQuery,
      },
    }).then(({ results }) => {
      setSignatureRequests(results)
      setLoadingSignatureRequests(false)
      setUpdatingSearchQuery(false)
      setSelectedSignatureRequestGid(results[0]?.gid)
    })
  }, [patientSearchQuery, doctor.id, debouncedFetch])

  if (updatingDoctor || (loadingSignatureRequests && !updatingSearchQuery)) {
    return (
      <Grid item xs>
        <Paper className={classNames(styles.paper, muiClasses.paper)}>
          <div
            className="fas fa-spinner fa-spin color-primary"
            style={{ fontSize: "64px" }}
          />
        </Paper>
      </Grid>
    )
  }

  const noSignatureRequests =
    signatureRequests.length === 0 &&
    !patientSearchQuery &&
    !loadingSignatureRequests

  if (noSignatureRequests) {
    return (
      <NoSignatureRequestsToReview
        doctor={doctor}
        currentUserEmail={currentUserEmail}
        currentUserEligibleForFacilitySignup={
          currentUserEligibleForFacilitySignup
        }
        redirectToFacilitySignupPage={redirectToFacilitySignupPage}
        showSignTabCta={actionableSignatureRequestsStatus.sign}
      />
    )
  }

  const onSignatureRequestReviewedOrDenied = () => {
    const indexOfNextRequest = signatureRequests.findIndex(
      (s) => s.gid === selectedSignatureRequestGid
    )
    const filteredSignatureRequests = signatureRequests.filter(
      (s) => s.gid !== selectedSignatureRequestGid
    )

    setSignatureRequests(filteredSignatureRequests)
    setSelectedSignatureRequestGid(
      filteredSignatureRequests[indexOfNextRequest]?.gid
    )
  }

  return (
    <SignatureRequestsToReview
      doctor={doctor}
      signatureRequests={signatureRequests}
      onSignatureRequestReviewedOrDenied={onSignatureRequestReviewedOrDenied}
      selectedSignatureRequestGid={selectedSignatureRequestGid}
      setSelectedSignatureRequestGid={setSelectedSignatureRequestGid}
      notificationBadgeStatusChecker={() =>
        notificationBadgeStatusChecker(doctor.id)
      }
      patientSearchQuery={patientSearchQuery}
      setPatientSearchQuery={(query) => {
        setUpdatingSearchQuery(true)
        setPatientSearchQuery(query)
      }}
      loadingSignatureRequests={loadingSignatureRequests}
    />
  )
}

export default SignatureRequestReviewTab
