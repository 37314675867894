import React from "react"
import classNames from "classnames"
import * as styles from "./index.module.scss"
export { default as SuggestionIndicator } from "./SuggestionIndicator"

type Props = {
  className?: string
  small?: boolean
  unselectable?: boolean
  disabled?: boolean
  isHighlighted?: boolean
  onClick?(event): void
  onMouseDown?(event): void
  onTouchStart?(event): void
  children: React.ReactNode
}

function Suggestion({
  className,
  small,
  unselectable,
  disabled,
  isHighlighted,
  onClick,
  onMouseDown,
  onTouchStart,
  children,
}: Props) {
  return (
    <div
      onClick={onClick}
      onMouseDown={onMouseDown}
      onTouchStart={onTouchStart}
      aria-disabled={disabled}
      className={classNames(styles.suggestion, className, {
        [styles.small]: small,
        [styles.highlight]: isHighlighted,
        [styles.unselectable]: unselectable,
        [styles.disabled]: disabled,
      })}
    >
      {children}
    </div>
  )
}

Suggestion.defaultProps = {
  disabled: false,
}

export default Suggestion
