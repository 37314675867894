import React, { useState } from "react"
import { CanopyForm } from "@parachutehealth/canopy-form"
import { CanopyComboboxField } from "@parachutehealth/canopy-combobox-field"
import { GetUsersPatients } from "./graphql/patientQuery"
import PatientSuggestion from "../components/PatientSuggestion"
import { useLazyQuery } from "@apollo/client"
import { useDebounce } from "hooks/useDebounce"
import { withApollo } from "hocs/withApollo"
import { navigate } from "utilities/navigation"
import { patientUrl } from "applications/PatientSelect/urls"

function InternalGlobalPatientSelect() {
  const typeMoreCharactersMessage = "Type 3 or more characters to search"
  const noResultsMessage = "We can't find any patients matching that search"
  const [noOptionsFoundMessage, setNoOptionsFoundMessage] = useState(
    noResultsMessage
  )
  const [needMoreCharacters, setNeedMoreCharacters] = useState(true)
  const [queryValue, setQueryValue] = useState<string>("")

  const [getUsersPatients, { data, loading }] = useLazyQuery(GetUsersPatients)
  const patients = data?.patients?.nodes || []

  const fetchOptions = async (query: string) => {
    setQueryValue(query)
    if (query.length > 2) {
      setNeedMoreCharacters(false)
      await getUsersPatients({
        variables: { query: query },
      })
      setNoOptionsFoundMessage(noResultsMessage)
    } else {
      setNeedMoreCharacters(true)
      setNoOptionsFoundMessage(typeMoreCharactersMessage)
    }
  }

  const debouncedFetch = useDebounce(fetchOptions)

  const convertToOption = (patients) => {
    if (needMoreCharacters) {
      return []
    }
    const groupedOptions: { key?: {}[] } = {}

    patients.forEach((patient) => {
      const patientOption = {
        value: patient.id,
        label: `${patient.firstName} ${patient.lastName}`,
        firstName: patient.firstName,
        lastName: patient.lastName,
        dateOfBirth: patient.dateOfBirth,
        sex: patient.sex,
        masterPatientId: patient.masterPatientId,
        mrn: patient.mrn,
      }

      const source: [{ id: string; name: string }] =
        patient.source.__typename === "ClinicalFacility"
          ? [patient.source]
          : patient.source.clinicalFacilitiesCurrentUserEmployersIntersection

      source.forEach((source) => {
        if (groupedOptions.hasOwnProperty(source.name)) {
          groupedOptions[source.name].push({ ...patientOption, source: source })
        } else {
          groupedOptions[source.name] = [{ ...patientOption, source: source }]
        }
      })
    })
    const sortedKeys = Object.keys(groupedOptions).sort()

    return sortedKeys.map((key) => ({
      label: key,
      options: groupedOptions[key],
    }))
  }

  const onChange = (patient) => {
    if (!patient || Array.isArray(patient)) return // prevents combobox from crashing when backspacing on empty input
    const employerType = patient.source.__typename
    const employerId = patient.source.id
    const masterPatientId = patient.masterPatientId
    navigate(patientUrl(employerType, employerId, masterPatientId))
  }

  return (
    <CanopyForm>
      <CanopyComboboxField
        size="small"
        label="Patient search"
        hiddenLabel
        loading={loading}
        placeholder="Search patients"
        options={convertToOption(patients)}
        onChange={onChange}
        onInputChange={debouncedFetch}
        optionRenderFunction={({ option }: { option }) => {
          return <PatientSuggestion patient={option} query={queryValue} />
        }}
        noOptionsFoundMessage={noOptionsFoundMessage}
        noOptionsAvailableMessage={typeMoreCharactersMessage}
      />
    </CanopyForm>
  )
}

export default withApollo(InternalGlobalPatientSelect)
