import { del } from "services/api"
import {
  facilitySupportPageUrl,
  signInUrl,
  signOutUrl,
  supportUrl,
} from "applications/Workflow/urls"
import { navigate } from "utilities/navigation"

export const signOut = (): void => {
  del(signOutUrl())
  navigate(signInUrl())
}

export const launchKnowledgeBase = (): void => {
  window.open(supportUrl, "_blank", "noopener,noreferrer")
}

export const navigateToFacilitySupportPage = (): void => {
  window.open(facilitySupportPageUrl(), "_self")
}
