// @ts-strict-ignore
import React, { useContext, useEffect, useMemo, useState } from "react"
import { Paper } from "@material-ui/core"
import AssignmentOutlinedIcon from "@material-ui/icons/AssignmentOutlined"
import {
  canopySpace12X,
  canopySpace16X,
  canopySpace24X,
} from "@parachutehealth/canopy-tokens-space"
import { denyOrder, markRequestAsReviewed } from "./api"
import Button from "components/form/Button"
import { ToastContext } from "components/Toaster"
import { DoctorSignatureRequest } from "../../api"
import { Preview } from "./Preview"
import DenyOrderModal from "applications/DoctorApproval/components/DenyOrderModal"
import { Link } from "react-router-dom"
import Icon from "components/Icon"
import * as styles from "./index.module.scss"
import { EventActions, trackEvent } from "../../utilities/tracking"
import { useStyles } from "themes/theme"
import { heightCalculation } from "../../utilities/heightCalculation"
import { useFlashContext } from "../../hooks/useFlashContext"
import { CanopyButton } from "@parachutehealth/canopy-button"

type Props = {
  linkBackTo?: string
  doctorId: string
  signatureRequest: DoctorSignatureRequest | undefined
  onReviewedOrDenied?: (id: string) => void
  notificationBadgeStatusChecker?: () => void
}

const signatureRequestDetailsStyles = (flash) => ({
  container: {
    height: heightCalculation(flash),
    width: "100%",
    paddingTop: canopySpace12X,
    paddingBottom: canopySpace12X,
    paddingLeft: canopySpace24X,
    paddingRight: canopySpace16X,
  },
})

const SignatureRequestDetails = ({
  linkBackTo,
  signatureRequest,
  onReviewedOrDenied,
  doctorId,
  notificationBadgeStatusChecker,
}: Props) => {
  const [loading, setLoading] = useState(false)
  const [showDenyOrderModal, setShowDenyOrderModal] = useState(false)
  const { persistentAlert } = useContext(ToastContext)

  const flash = useFlashContext()

  const muiStyles = useMemo(() => signatureRequestDetailsStyles(flash), [flash])
  const muiClasses = useStyles(muiStyles)

  useEffect(() => setLoading(false), [signatureRequest])

  if (!signatureRequest) return null

  const showActionButtons = !!onReviewedOrDenied

  const markAsReviewed = () => {
    setLoading(true)
    trackEvent(EventActions.ReviewerMarkAsReviewed)

    return markRequestAsReviewed(signatureRequest, doctorId)
      .then(() => {
        persistentAlert({
          message: `Order for ${signatureRequest.patient.name} marked as reviewed`,
          severity: "success",
        })
        onReviewedOrDenied(signatureRequest.gid)
        notificationBadgeStatusChecker()
      })
      .catch((error) => {
        if (error?.response?.data?.alreadyReviewed === true) {
          persistentAlert({
            message: `Order for ${signatureRequest.patient.name} was already reviewed`,
            severity: "warning",
          })
          onReviewedOrDenied(signatureRequest.gid)
          notificationBadgeStatusChecker()
        } else {
          persistentAlert({
            message: "Encountered an error, please try again",
            severity: "error",
          })
        }
        setLoading(false)
      })
  }

  const markAsDenied = (params: { reason: string; description: string }) => {
    setLoading(true)
    return denyOrder(signatureRequest, doctorId, params)
      .then(() => {
        persistentAlert({
          message: `Order for ${signatureRequest.patient.name} denied`,
          severity: "success",
        })
        onReviewedOrDenied(signatureRequest.gid)
        notificationBadgeStatusChecker()
      })
      .catch(() =>
        persistentAlert({
          message: `Encountered an error, please try again`,
          severity: "error",
        })
      )
      .then(() => {
        setLoading(false)
        setShowDenyOrderModal(false)
      })
  }

  const { patient } = signatureRequest
  return (
    <Paper className={muiClasses.container} elevation={0}>
      {linkBackTo && (
        <div className={styles.backLinkContainer}>
          <Link to={linkBackTo} className="link link-nonbold">
            <Icon type="arrow-left" className="d-inline-block canopy-mie-4x" />
            Back
          </Link>
          <hr />
        </div>
      )}
      <div className={styles.header}>
        <div className={styles.patientInfo}>
          <h2 className={styles.patientName}>{patient.name}</h2>
          <div className={styles.patientExtendedInfo}>
            <AssignmentOutlinedIcon
              color="primary"
              className={styles.patientNameIcon}
            />
            Sex: {patient.sex} &middot; Date of Birth: {patient.dateOfBirth}
          </div>
        </div>
        {showActionButtons && (
          <div className={styles.actionButtons}>
            <Button
              className={`btn-secondary ${styles.denyRequestButton}`}
              type="submit"
              disabled={loading}
              onClick={() => {
                trackEvent(EventActions.ReviewerDenyRequest)
                setShowDenyOrderModal(true)
              }}
            >
              Deny Request
            </Button>
            <DenyOrderModal
              show={showDenyOrderModal}
              close={() => setShowDenyOrderModal(false)}
              deny={markAsDenied}
              denialReasons={[
                { label: "Not my patient", value: "Not my patient" },
                {
                  label: "Not medically necessary",
                  value: "Not medically necessary",
                },
                {
                  label: "I need to see the patient first",
                  value: "I need to see the patient first",
                },
                { label: "Other", value: "I decline to sign the order" },
              ]}
            />
            <CanopyButton
              type="submit"
              disabled={loading}
              onClick={markAsReviewed}
            >
              Mark as Reviewed
            </CanopyButton>
          </div>
        )}
      </div>
      <Preview
        signatureRequest={signatureRequest}
        doctorId={doctorId}
        hasBackButton={!!linkBackTo}
      />
    </Paper>
  )
}
export default SignatureRequestDetails
