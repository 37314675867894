import { employerPrefix } from "utilities/url"

export function initialValuesUrl(supplierOrganizationId: string) {
  return `${employerPrefix(
    "SupplierOrganization",
    supplierOrganizationId
  )}/dashboard/initial_values.json`
}

export function ordersUrl(supplierOrganizationId) {
  return `${employerPrefix(
    "SupplierOrganization",
    supplierOrganizationId
  )}/dashboard/results.json`
}

export function orderUrl(dmeOrderId) {
  return `${employerPrefix("SupplierOrganization")}/orders/${dmeOrderId}`
}

export function eventResolutionsUrl(supplierId, dmeOrderId, dmeOrdeEventId) {
  return `${employerPrefix(
    "Supplier",
    supplierId
  )}/dashboard/dme_orders/${dmeOrderId}/dme_order_events/${dmeOrdeEventId}/event_resolutions.json`
}

export function orderEventsUrl(supplierId, dmeOrderId) {
  return `${employerPrefix(
    "Supplier",
    supplierId
  )}/dashboard/dme_orders/${dmeOrderId}/dme_order_events.json`
}

export function reviewsUrl(supplierId, dmeOrderId) {
  return `${employerPrefix("Supplier", supplierId)}/orders/${dmeOrderId}/review`
}

export function assignFollowUpOwnerUrl(dmeOrder) {
  const supplierOrgPrefix = employerPrefix("Supplier", dmeOrder.supplierId)
  return `${supplierOrgPrefix}/dashboard/dme_orders/${dmeOrder.id}/follow_up_owner.json`
}

export function followUpOwnerUrl(dmeOrder) {
  const supplierOrgPrefix = employerPrefix("Supplier", dmeOrder.supplierId)
  return `${supplierOrgPrefix}/orders/${dmeOrder.id}/supplier_followables.json`
}

export function searchClinicalFacilitiesUrl() {
  return `${employerPrefix()}/dashboard/search_clinical_facilities.json`
}

export function getAssigneesUrl() {
  return `${employerPrefix()}/assignees.json`
}

export function followablesUrl() {
  return `${employerPrefix()}/followables.json`
}

export function internalStatusesUrl() {
  return `${employerPrefix()}/internal_statuses.json`
}

export function searchDoctorsUrl() {
  return `/u/doctors_search.json`
}

export function getSalesRepsUrl() {
  return `${employerPrefix()}/sales_reps.json`
}

export function uploadsUrl() {
  return `${employerPrefix()}/dme_order_csv_uploads.json`
}

export function dashboardViewUrl(supplierOrganizationId, id) {
  return `${employerPrefix(
    "SupplierOrganization",
    supplierOrganizationId
  )}/dashboard_views/${id}.json`
}
