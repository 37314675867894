import React from "react"

type Props = {
  entityName: string
}

const Legalese = ({ entityName }: Props) => {
  return (
    <p className="font-subparagraph text-left">
      This message is intended to be used by employees of {entityName}, and may
      contain information from Parachute Health and/or its affiliates and
      subsidiaries that is confidential or privileged, or protected health
      information from Parachute Health that is confidential under HIPAA. If you
      are not the intended recipient, you are hereby notified that any
      disclosure, copying, distribution or use of the contents of this message
      is strictly prohibited. If you have received this message in error or are
      not the named recipient, please contact{" "}
      <a href="mailto:support@parachutehealth.com">
        support@parachutehealth.com
      </a>{" "}
      immediately, and delete and destroy all copies of this message. Thank you.
    </p>
  )
}

export default Legalese
