import React from "react"
import { DmeOrder } from "sharedTypes"
import { formatAddress } from "utilities/address"
import DataRow from "components/DataRow"
import { carrierAuthorizationUrl } from "applications/Workflow/urls"

type Props = {
  dmeOrder: DmeOrder
}
const PaymentSectionContent = ({ dmeOrder }: Props) => {
  const primaryPolicy = dmeOrder.insurancePolicies.find(
    (policy) => policy.rank === "primary"
  )
  const secondaryPolicy = dmeOrder.insurancePolicies.find(
    (policy) => policy.rank === "secondary"
  )
  const tertiaryPolicy = dmeOrder.insurancePolicies.find(
    (policy) => policy.rank === "tertiary"
  )

  const billingAddress = dmeOrder.addresses.find(
    (address) => address.addressType === "billing"
  )

  const policyInfo = (policy) => {
    if (policy) {
      return `${policy.carrierName ? policy.carrierName : "Missing Carrier"}, ${
        policy.memberNumber
      }`
    }
  }

  return (
    <>
      <DataRow label="Primary" value={policyInfo(primaryPolicy)} />
      {secondaryPolicy && (
        <DataRow label="Secondary" value={policyInfo(secondaryPolicy)} />
      )}
      {tertiaryPolicy && (
        <DataRow label="Tertiary" value={policyInfo(tertiaryPolicy)} />
      )}
      {dmeOrder.carrierAuthorizationNumber && (
        <DataRow
          label="Pre-Authorization Number"
          value={
            dmeOrder.carrierAuthorizationId ? (
              <a
                target="_blank"
                rel="noopener noreferrer"
                className="link"
                href={carrierAuthorizationUrl(dmeOrder.carrierAuthorizationId)}
              >
                {dmeOrder.carrierAuthorizationNumber}
              </a>
            ) : (
              dmeOrder.carrierAuthorizationNumber
            )
          }
        />
      )}
      <DataRow label="Billing Address" value={formatAddress(billingAddress)} />
    </>
  )
}

export default PaymentSectionContent
