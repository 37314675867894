import React from "react"
import ChatUnavailableIcon from "./ChatUnavailableIcon"

const ChatUnavailable: React.FC = () => (
  <div className="h-75 canopy-px-12x d-flex flex-column justify-content-center align-items-center">
    <ChatUnavailableIcon />
    <h2 className="canopy-pbs-8x">Chat is not available</h2>
    <p className="text-center">
      Either the phone number on the order is not mobile or the clinical
      facility did not share the Order Tracker with the patient.
    </p>
  </div>
)

export default ChatUnavailable
