import { InputAdornment, TextField, TextFieldProps } from "@material-ui/core"
import React from "react"
import SearchIcon from "@material-ui/icons/Search"

type Props = TextFieldProps & {
  onSearch: (searchKeyword: string) => void
}

const PatientSearchField: React.FC<Props> = ({
  onSearch,
  ...textFieldProps
}: Props) => {
  return (
    <TextField
      data-testid="search-keywords-filter"
      label="Search patient..."
      type="search"
      autoComplete="off"
      size="small"
      InputProps={{
        name: "search-keywords-filter-input",
        endAdornment: (
          <InputAdornment position="end">
            <SearchIcon className="color-mid-gray" />
          </InputAdornment>
        ),
      }}
      variant="outlined"
      onChange={(event) => {
        onSearch(event.target.value)
      }}
      {...textFieldProps}
    />
  )
}

export default PatientSearchField
