// @ts-strict-ignore
import { SignatureRequest } from "../sharedTypes"
import { capitalize } from "utilities/string"
import groupBy from "lodash/groupBy"
import {
  format,
  SHORT_DATE_FORMAT,
  EVENT_DATE_TIME_FORMAT,
} from "utilities/date"
import { toSentence } from "utilities/array"

const summarizeRequests = (requests: SignatureRequest[]): string => {
  if (requests.length === 0) {
    return
  }

  const byDate = groupBy(requests, (request) => {
    return format(request.sentAt, SHORT_DATE_FORMAT)
  })

  const summaryStrings = Object.keys(byDate).map((date) => {
    const contactChannels = byDate[date]
      .map((a) => capitalize(a.contactChannel))
      .sort()

    return `via ${toSentence(contactChannels)} on ${date}`
  })

  return toSentence(summaryStrings)
}

export const summarizeSignatureRequests = (
  signatureRequests: SignatureRequest[]
) => {
  const byContactType = groupBy(signatureRequests, "contactChannel")

  const originalRequests = []
  const reminderRequests = []

  Object.values(byContactType).forEach(([original, ...reminders]) => {
    originalRequests.push(original)

    if (reminders.length) {
      reminderRequests.push(reminders[reminders.length - 1])
    }
  })

  return {
    originalRequestsSummary: summarizeRequests(originalRequests),
    reminderRequestsSummary: summarizeRequests(reminderRequests),
  }
}

export const lastRequestedAt = (signatureRequests: SignatureRequest[]) => {
  const lastRequest = signatureRequests[signatureRequests.length - 1]
  if (!lastRequest) {
    return
  }

  return format(lastRequest.sentAt, EVENT_DATE_TIME_FORMAT)
}
