import React from "react"
import letter from "letter-success.png"
import Button from "components/form/Button"

type Props = {
  email: string
  onInviteAnotherReferralClick(): void
}

function InviteReferralSuccess({ email, onInviteAnotherReferralClick }: Props) {
  return (
    <div className="text-center">
      <div className="canopy-my-24x">
        <img src={letter} width="220" />
      </div>
      <div className="text-xs-left canopy-mbe-24x">
        <h1>Sign up invitation sent!</h1>
        <p>
          An email invitation to create a Parachute Health account has been sent
          to {email}.
        </p>
      </div>
      <form>
        <Button
          className="btn btn-brand btn-block"
          onClick={onInviteAnotherReferralClick}
        >
          Invite Another Referral
        </Button>
      </form>
    </div>
  )
}

export default InviteReferralSuccess
