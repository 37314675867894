import React from "react"
import * as api from "./api"
import { navigate } from "utilities/navigation"
import PasswordForm from "components/PasswordForm"

interface Props {
  token: string
}

function NewInvitationPassword({ token }: Props) {
  const updatePassword = (values) => {
    return api.updatePassword(values, token).then(() => {
      navigate("/")
    })
  }

  return (
    <PasswordForm
      header="Welcome to Parachute Health, please create your password."
      updatePassword={updatePassword}
    />
  )
}

export default NewInvitationPassword
