import React from "react"
import { asForm, EmailField } from "components/form"

type Props = {
  isValid: boolean
}

const EmailForm = ({ isValid }: Props) => {
  return (
    <>
      <EmailField label="Email Address" name="email" />
      <div className="text-right">
        <button type="submit" className="btn btn-primary" disabled={!isValid}>
          Send Email Verification
        </button>
      </div>
    </>
  )
}

export default asForm(EmailForm)
