import Alert from "components/Alert"
import { facilityDashboardReviewerSignUpUrl } from "components/ClinicalDashboard/urls"
import Link from "components/Link"
import React from "react"

interface Props {
  doctorName: string
  showSignupLink?: boolean
}

const RemovedLastReviewerEmploymentAlert: React.FC<Props> = ({
  doctorName,
  showSignupLink,
}) => {
  return (
    <Alert status="success" className="mt-n2" bordered>
      <strong>Successfully removed as a reviewer for {doctorName}.</strong>{" "}
      {doctorName} was the last clinician you reviewed signature requests for.
      {showSignupLink && (
        <>
          {" "}
          If you&apos;d like to add other clinicians, please{" "}
          <Link href={facilityDashboardReviewerSignUpUrl()}>click here</Link>.
        </>
      )}
    </Alert>
  )
}

export default RemovedLastReviewerEmploymentAlert
