import React from "react"
import SignatureRequestPatientCard, {
  PatientSearchResult,
  resultCardKey,
} from "./SignatureRequestPatientCard"
import { ToastProvider } from "../../components/Toaster"

type Props = {
  patientSearchResults: PatientSearchResult[]
  supplierId: string
  csrInboxStateId: string
}

const SignatureRequestPatientCards = (props: Props) => {
  return (
    <ToastProvider>
      {props.patientSearchResults.map((result) => {
        return (
          <SignatureRequestPatientCard
            supplierId={props.supplierId}
            csrInboxStateId={props.csrInboxStateId}
            key={resultCardKey(result)}
            {...result}
          />
        )
      })}
    </ToastProvider>
  )
}

export default SignatureRequestPatientCards
