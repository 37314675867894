import * as api from "utilities/tracking"
import { handleError } from "utilities/error"

export enum EventActions {
  ReviewerSignRequestModal = "reviewer_sign_request_modal",
  ReviewerCancelRequestModal = "reviewer_cancel_request_modal",
  ReviewerReviewTab = "reviewer_review_tab",
  ReviewerSignTab = "reviewer_sign_tab",
  ReviewerReadyForSigTab = "reviewer_ready_for_sig_tab",
  ReviewerSignSelectedOrders = "reviewer_sign_selected_orders",
  ReviewerMarkAsReviewed = "reviewer_mark_as_reviewed",
  ReviewerDenyRequest = "reviewer_deny_request",
  ReviewerDoneTab = "reviewer_done_tab",
  ReviewerExportCompletedOrders = "reviewer_export_completed_orders",
  ReviewerRemoveReviews = "reviewer_remove_reviews",
}

export const trackEvent = (eventAction: EventActions) => {
  api
    .trackEvent(api.EventCategory.Resupply, eventAction)
    .then()
    .catch(handleError)
}
