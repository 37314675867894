import React from "react"
import { DoctorSignatureRequest } from "../../api"
import { TableColumnDefinition } from "components/Table"
import { Link } from "react-router-dom"
import classNames from "classnames"
import { SignPath } from "../../routes"
import Tooltip from "components/Tooltip"
import isTouchDevice from "is-touch-device"

type DoctorSignatureRequestRecord = DoctorSignatureRequest & { key: string }

const tableColumnDefinitions = (
  doctorId: string
): TableColumnDefinition<DoctorSignatureRequestRecord>[] => [
  {
    title: "Order",
    attr: "orderId",
    render: (signatureRequest: DoctorSignatureRequestRecord) => {
      const tooltipContent = (
        <>
          Parachute Order: <strong>{signatureRequest.id}</strong>
        </>
      )
      return (
        <Tooltip
          trigger={["hover"]}
          overlay={tooltipContent}
          disabled={isTouchDevice()}
          placement={Tooltip.Placement.Right}
          width={Tooltip.Width.LimitedWide}
          triggerElementClassName="d-inline"
        >
          <Link
            className={classNames("link color-brand")}
            to={`${SignPath(doctorId)}/${signatureRequest.gid}`}
          >
            View
          </Link>
        </Tooltip>
      )
    },
  },
  {
    title: "Patient Name",
    attr: "patientName",
    render: (signatureRequest: DoctorSignatureRequestRecord) =>
      signatureRequest.patient.name,
  },
  {
    title: "Patient Date of Birth",
    attr: "patientDOB",
    render: (signatureRequest: DoctorSignatureRequestRecord) =>
      signatureRequest.patient.dateOfBirth,
  },
  {
    title: "Creation Date",
    attr: "creationDate",
    render: (signatureRequest: DoctorSignatureRequestRecord) =>
      signatureRequest.createdAt,
  },
  {
    title: "Supplier",
    attr: "supplierName",
  },
  {
    title: "Reviewed By",
    attr: "reviewedBy",
    render: (signatureRequest: DoctorSignatureRequestRecord) => {
      return (
        <span className="color-secondary">
          <i className="fas fa-user-check" /> {signatureRequest.reviewedBy}
        </span>
      )
    },
  },
]

export default tableColumnDefinitions
