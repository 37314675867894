import React from "react"

interface Props {
  title: string
  subtitle?: string
}

function WorkflowHeader({ title, subtitle }: Props) {
  return (
    <>
      <h1>{title}</h1>
      {subtitle && <p>{subtitle}</p>}
    </>
  )
}

export default WorkflowHeader
