import React from "react"
import {
  Accordion as ReactAccordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion"

type Group = {
  title: string
  body: string
}

type Props = {
  groups: Group[]
}

function Accordion({ groups }: Props) {
  return (
    <ReactAccordion allowZeroExpanded>
      {groups.map((group, index) => {
        return (
          <AccordionItem key={index}>
            <AccordionItemHeading>
              <AccordionItemButton>{group.title}</AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>{group.body}</AccordionItemPanel>
          </AccordionItem>
        )
      })}
    </ReactAccordion>
  )
}

export default Accordion
