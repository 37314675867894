import React from "react"
import { Tooltip } from "@material-ui/core"
import { CanopyIconNames } from "@parachutehealth/canopy-icons/build/canopyIconGlyphNames"
import { CanopyButton } from "@parachutehealth/canopy-button"
import classNames from "classnames"
import * as styles from "./index.module.scss"

type InfoTooltipProps = {
  title: string
  icon?: CanopyIconNames
  className?: string
  accessibleText?: string
}

const InfoTooltip = (props: InfoTooltipProps) => {
  const {
    title,
    icon = "circle-info",
    className,
    accessibleText = "More information",
  } = props

  return (
    <Tooltip arrow title={title}>
      <CanopyButton
        variant="tertiary"
        className={classNames(className, styles.minimalButton)}
        iconStart={icon}
        size="small"
        accessibleText={accessibleText}
      />
    </Tooltip>
  )
}

export default InfoTooltip
