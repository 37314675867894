import TextInput from "components/TextInput"
import React from "react"
import { blurOnEnter } from "utilities/browser/event"

type Props = {
  name: string
  onChange(query: string): void
  placeholder: string
}

const Search = (props: Props) => {
  const { name, onChange, placeholder } = props
  return (
    <>
      <label htmlFor={name}>Search Formulary</label>
      <TextInput
        id={name}
        name={name}
        onChange={(e) => onChange(e.target.value)}
        onKeyDown={blurOnEnter}
        placeholder={placeholder}
      />
    </>
  )
}

export default Search
