import { get, post } from "services/api"
import { createUserUrl, getSuppliersUrl } from "./urls"
import { Supplier } from "../../shared/sharedTypes"
import { MethodSelection } from "./components/InternalAccountCreation"

export const createUser = (
  params,
  supplierId: string,
  methodSelection: MethodSelection,
  salesRepEmail: string
): Promise<void> => {
  return post(createUserUrl(), {
    user: params,
    supplierId,
    methodSelection,
    salesRepEmail,
  })
    .then(({ data }) => data)
    .catch(({ response }) => Promise.reject(response.data))
}

export const getSuppliers = (term: string): Promise<Supplier[]> => {
  return get(getSuppliersUrl(), { term })
    .then(({ data }) => data)
    .catch(({ response }) => Promise.reject(response.data))
}
