import { uploadDocumentToUrl } from "services/api"
import { documentationUploadUrl } from "applications/Workflow/urls"

const uploadDocument = (orderId, file, source) => {
  const url = documentationUploadUrl(false)
  return uploadDocumentToUrl(url, file, source)
}

export const uploadDocuments = (orderId, files, source = null) => {
  return Promise.all(
    files.map((file) => {
      return uploadDocument(orderId, file, source)
    })
  )
}
