// @ts-strict-ignore
import React from "react"
import { Field } from "formik"
import classNames from "classnames"

interface Option {
  label: string
  value: string
}

interface Props {
  label: string
  name: string
  options: Option[]
  onChange?(value?: string): void
}

function FilterButtons({ name, label, options, onChange }: Props) {
  return (
    <Field name={name}>
      {({ field, form }) => (
        <>
          <label className="col-form-label">{label}</label>
          <div>
            {options.map((option) => {
              return (
                <a
                  key={option.value}
                  onClick={() => {
                    form.setFieldTouched(name, true)
                    const value =
                      field.value === option.value ? null : option.value
                    onChange && onChange(value)
                    form.setFieldValue(name, value)
                  }}
                  className={classNames(
                    "btn btn-pill btn-sm canopy-mbe-4x canopy-mie-2x",
                    {
                      active: field.value === option.value,
                    }
                  )}
                >
                  {option.label}
                </a>
              )
            })}
          </div>
        </>
      )}
    </Field>
  )
}

export default FilterButtons
