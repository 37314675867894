import React from "react"
import { BrowserRouter, withRouter } from "react-router-dom"
import { HistoryListener } from "./HistoryListener"

const wrap = (Component, { basename }) => (props) => (
  <BrowserRouter basename={basename}>
    <HistoryListener basename={basename} />
    <Component {...props} />
  </BrowserRouter>
)

export const withBrowserRouter = (Component, options) =>
  wrap(withRouter(Component), options)
