import React, { useState } from "react"
import SelectFilter, { Record } from "components/SelectFilter"

export const LastNotificationFilter = (props: {
  onChange: (params: { lastNotificationMethod: string[] }) => void
  options: Record[]
}) => {
  const [
    selectedNotificationMethods,
    setSelectedNotificationMethods,
  ] = useState<string[]>()

  return (
    <SelectFilter
      singular={true}
      onChange={(values) => {
        setSelectedNotificationMethods(values.lastNotificationMethod)
        props.onChange({
          lastNotificationMethod: values.lastNotificationMethod,
        })
      }}
      options={props.options}
      initialValues={selectedNotificationMethods}
      name="lastNotificationMethod"
      label="Last Notification Method"
    />
  )
}
