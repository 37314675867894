export enum Keycode {
  Backspace = 8,
  Enter = 13,
  Escape = 27,
  ArrowLeft = 37,
  ArrowRight = 39,
  A = 65,
  B = 66,
  C = 67,
}
