import React, { useState } from "react"
import { Nullable } from "sharedTypes"
import * as styles from "./index.module.scss"
import * as tokens from "@parachutehealth/canopy-tokens-color"
import { CanopyDialogModal } from "@parachutehealth/canopy-dialog-modal"
import { CanopyButton } from "@parachutehealth/canopy-button"
import { format } from "utilities/date"

type Props = {
  canForceInviteResend: boolean
  facilityInviteResentAt: Nullable<Date>
  phoneNumber: string
  resendFacilityInvite: () => void
}

const ResendInviteLink: React.FC<Props> = ({
  canForceInviteResend,
  facilityInviteResentAt,
  phoneNumber,
  resendFacilityInvite,
}) => {
  const [showResendModal, setShowResendModal] = useState(false)
  const openModal = () => setShowResendModal(true)
  const closeModal = () => setShowResendModal(false)

  if (canForceInviteResend) {
    return (
      <>
        <button className={styles.linkStyleBtn} onClick={openModal}>
          Re-send invite link
        </button>
        <CanopyDialogModal
          header="Re-send Invite Link?"
          headerOverline="Available only once"
          onClose={closeModal}
          open={showResendModal}
          primaryFooterButton={
            <CanopyButton onClick={resendFacilityInvite}>
              Re-Send Invite Link
            </CanopyButton>
          }
          secondaryFooterButton={
            <CanopyButton variant="secondary" onClick={closeModal}>
              Cancel
            </CanopyButton>
          }
          size="medium"
        >
          <p>
            An SMS with a link to the order tracker will be sent to{" "}
            <strong>{phoneNumber}</strong> (patient&apos;s primary phone
            number).
          </p>
          <p className="canopy-typography-body-small">
            By continuing, you confirm that, to the best of your knowledge, the
            patient phone number is true and accurate, and that the patient
            consents to receiving the order tracker via SMS.
          </p>
        </CanopyDialogModal>
      </>
    )
  }
  if (facilityInviteResentAt) {
    return (
      <p style={{ color: tokens.canopyColorPrimitivesGray48 }}>
        Invite link was re-sent on{" "}
        {format(facilityInviteResentAt, "MMM D, YYYY")}
      </p>
    )
  }
  return null
}

export default ResendInviteLink
