import React, { useContext } from "react"
import { PreferredSupplierContext } from "../../hooks/usePreferredSupplier"
import { CanopyRadioInputField } from "@parachutehealth/canopy-radio-input-field"

interface SupplierSelectionFormProps {
  formError?: string
}

export const SupplierSelectionForm: React.FC<SupplierSelectionFormProps> = ({
  formError,
}) => {
  const {
    preferredSuppliers,
    selectedSupplier,
    setSelectedSupplier,
  } = useContext(PreferredSupplierContext)
  const prefSupplierOptions = preferredSuppliers
    .map((p) => ({
      label: p.supplier.name,
      value: p.supplier.externalId,
    }))
    .sort((a, b) => (a.label > b.label ? 1 : -1))

  return (
    <div>
      <div>
        <CanopyRadioInputField
          label="Select a Health Plan Preferred Supplier to redirect your order:"
          name="supplierId"
          options={prefSupplierOptions}
          onChange={(e) => {
            const prefSupplier = preferredSuppliers.find(
              (p) => p.supplier.externalId === e.target.value
            )
            if (prefSupplier) {
              setSelectedSupplier(prefSupplier)
            }
          }}
        />
        {!!formError && !selectedSupplier && (
          <span className="color-danger canopy-typography-body-small">
            {formError}
          </span>
        )}
      </div>
    </div>
  )
}
