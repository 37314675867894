import { employerPrefix, userPrefix } from "utilities/url"
import { Tab } from "applications/DoctorReviewerDashboard/sharedTypes"

export function facilityUrl(employerId: string): string {
  return employerPrefix("ClinicalFacility", employerId)
}

export function findYourClinicianPage(): string {
  return "/u/reviewers/sign_up/new"
}

export const doctorReviewerDashboardUrl = (
  activeTab: Tab,
  doctorId?: string
) => {
  const addTab = activeTab && activeTab !== Tab.FACILITY_SIGN_UP
  return `${employerPrefix(
    "Doctor",
    doctorId
  )}/reviewers_signature_request_dashboard${addTab ? "/" + activeTab : ""}`
}

export const doctorReviewerFacilitySignUpPromoUrl = (doctorId?: string) =>
  `${employerPrefix(
    "Doctor",
    doctorId
  )}/reviewers_signature_request_dashboard/facility_sign_up`

export const facilityDashboardReviewerSignUpUrl = (facilityId?: string) =>
  `${employerPrefix("ClinicalFacility", facilityId)}/reviewer_sign_up`

export const reviewerSignatureRequestPreferencesUrl = (doctorId?: string) =>
  `${employerPrefix(
    "Doctor",
    doctorId
  )}/reviewers/user_preferences/notification_preferences/edit?add_clinician=1`

export const incomingOrdersUrl = `${userPrefix()}/incoming_orders`
