import React from "react"
import InternalAccessCodeLogin from "./components/InternalAccessCodeLogin"
import { verify, login, resend, reject } from "./api"
import { SupplierInformation } from "sharedTypes"

type Props = {
  accessCode: string
  requestCanceled: boolean
  supplierInformation?: SupplierInformation
  reviewerInformation?: {
    email: string
    firstName: string
    lastName: string
  }
}

const AccessCodeLogin: React.FC<Props> = ({
  accessCode,
  requestCanceled,
  supplierInformation,
  reviewerInformation,
}: Props) => {
  return (
    <InternalAccessCodeLogin
      accessCode={accessCode}
      requestCanceled={requestCanceled}
      supplierInformation={supplierInformation}
      reviewerInformation={reviewerInformation}
      resend={resend}
      verifyCodes={verify}
      login={login}
      reject={reject}
    />
  )
}

export default AccessCodeLogin
