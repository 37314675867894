import { put } from "services/api"
import * as urls from "./urls"

export const updatePassword = (values, token) => {
  return put(urls.updatePasswordUrl(), {
    user: { invitationToken: token, ...values },
  })
    .then(({ data }) => data)
    .catch(({ response }) => Promise.reject(response.data))
}
