import $ from "jquery"

export function poll(isActive, uploadButton) {
  if (isActive && uploadButton.data("transcoding")) {
    const path = uploadButton.data("poll-path")
    $.get(path).statusCode({
      206: () => {
        setTimeout(function () {
          poll(isActive, uploadButton)
        }, 2000)
      },
    })
  }
}
