import { post, del } from "services/api"
import * as urls from "./url"

export function convertToFullUser(userId) {
  return post(`${urls.convertToFullUserUrl(userId)}`).then(({ data }) => data)
}

export function removeUserFromFacility(userId) {
  return del(`${urls.removeUserFromFacilityUrl(userId)}`).then(
    ({ data }) => data
  )
}

export function sendWelcomeEmail(userId) {
  return post(`${urls.sendWelcomeEmailUrl(userId)}`).then(({ data }) => data)
}

export function sendResetPasswordEmail(userId) {
  return post(`${urls.sendResetPasswordUrl(userId)}`).then(({ data }) => data)
}
