import React from "react"
import Table, { TableColumnDefinition } from "components/Table"
import * as styles from "./signature-request-patient-card.module.scss"
import PatientCardHeader from "./PatientCardHeader"
import { AttachToRequestButton } from "./AttachToRequestButton"
import { attachToRequest } from "./AttachToRequestButton/attach-to-request"

type SignatureRequest = {
  id: string
  orderDate: string
  orderId: string
  clinicianNpi: string
  hcpcs: string
}

export type PatientSearchResult = {
  patient: {
    firstName: string
    lastName: string
    sex: string
    dateOfBirth: string
  }
  signatureRequests: SignatureRequest[]
}

export const resultCardKey = (result: PatientSearchResult) =>
  Object.values(result.patient).join("-")

const tableColumnDefinitions: TableColumnDefinition<
  SignatureRequest & {
    key: string
    supplierId: string
    csrInboxStateId: string
  }
>[] = [
  {
    title: "Order Date",
    attr: "orderDate",
  },
  {
    title: "Order ID",
    attr: "orderId",
  },
  {
    title: "Clinician NPI",
    attr: "clinicianNpi",
  },
  {
    title: "HCPCs",
    attr: "hcpcs",
  },
  {
    title: "",
    attr: "",
    render: (s) => (
      <AttachToRequestButton
        supplierId={s.supplierId}
        attachToRequest={attachToRequest(s.supplierId, s.csrInboxStateId, s.id)}
      />
    ),
  },
]

const SignatureRequestPatientCard = ({
  patient,
  signatureRequests,
  supplierId,
  csrInboxStateId,
}: PatientSearchResult & { supplierId: string; csrInboxStateId: string }) => {
  return (
    <div className={styles.patientCardContainer}>
      <div className={styles.patientCardInnerContainer}>
        <PatientCardHeader {...patient} />
        <Table
          records={signatureRequests.map((signatureRequest) => ({
            ...signatureRequest,
            key: signatureRequest.id,
            csrInboxStateId,
            supplierId,
          }))}
          truncate={false}
          tableColumns={tableColumnDefinitions}
        />
      </div>
    </div>
  )
}

export default SignatureRequestPatientCard
