import { employerPrefix } from "utilities/url"

export const updateUserUrl = (userId: string) => {
  return `${employerPrefix()}/users/${userId}.json`
}

export const createUserUrl = () => {
  return `${employerPrefix()}/users.json`
}

export const usersUrl = () => {
  return `${employerPrefix()}/users`
}

export const userUrl = (userId: string) => {
  return `${employerPrefix()}/users/${userId}`
}
