import React from "react"
import Icon from "components/Icon"

type Props = {
  onChange(value: string): void
  value: string
  placeholder: string
}

function Search({ onChange, value, placeholder }: Props) {
  return (
    <span className="has-feedback d-inline-block d-xs-block d-sm-block w-lg-250 w-md-250 v-align-top canopy-mie-4x canopy-mbe-8x">
      <input
        onChange={(event) => onChange(event.target.value)}
        value={value}
        className="form-control input-sm input-rounded d-inline-block canopy-mbe-0"
        placeholder={placeholder}
        tabIndex={0}
      />
      <Icon className="form-control-feedback" type="search" />
    </span>
  )
}

Search.defaultProps = {
  value: "",
}

export default Search
