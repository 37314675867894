import { get } from "services/api"
import { emrPatientStatusUrl } from "./urls"
import { pollPromise } from "utilities/poll"

function getEmrPatientStatus(emrContextIndex, emrPatientLoadJobId) {
  return get(emrPatientStatusUrl(emrContextIndex, emrPatientLoadJobId))
}

export const pollForPatientStatus = (
  emrContextIndex,
  emrPatientLoadJobId
): Promise<{ successPath: string }> => {
  return pollPromise(() =>
    getEmrPatientStatus(emrContextIndex, emrPatientLoadJobId)
  )
}
