import React from "react"
import Modal from "components/Modal"
import ModalBody from "./ModalBody"

type Props = {
  closeModal(): void
}

const Index = ({ closeModal }: Props) => {
  return (
    <Modal show size="md" title="Temporary Order Access" cancel={closeModal}>
      <Modal.Body>
        <ModalBody />
      </Modal.Body>
      <Modal.Footer>
        <button type="button" className="btn btn-brand" onClick={closeModal}>
          Done
        </button>
      </Modal.Footer>
    </Modal>
  )
}

export default Index
