// @ts-strict-ignore
import React, { useState } from "react"
import SurveyQuestion from "./SurveyQuestion"
import {
  SurveyQuestion as SurveyQuestionType,
  SurveyAnswerType,
} from "sharedTypes"
import classNames from "classnames"

type Props = {
  className?: string
  questions: SurveyQuestionType[]
  answerQuestion(
    question: SurveyQuestionType,
    answerType: SurveyAnswerType,
    answerValue: string
  ): Promise<void>
  showMostCommon?: boolean
  setFieldSaving?(boolean): void
  requiresConfirmation?: boolean
  missingRequiredInformation?: boolean
}

export default ({
  className,
  questions,
  showMostCommon,
  setFieldSaving,
  requiresConfirmation,
  missingRequiredInformation,
  ...props
}: Props) => {
  const calculateGrouping = () => {
    const groups = []
    questions.forEach((question) => {
      if (question.displayStyle === "inline") {
        if (Array.isArray(groups[groups.length - 1])) {
          groups[groups.length - 1].push(question)
        } else {
          groups.push([question])
        }
      } else {
        groups.push(question)
      }
    })
    return groups
  }
  const ref = React.useRef<HTMLInputElement>()

  const [focusedQuIdx, setFocusedQuIdx] = useState(0)

  ref.current && ref.current.focus()

  const findQuestionIdx = (question: SurveyQuestionType) => {
    return questions.findIndex((qu) => qu.questionId === question.questionId)
  }

  const focusNextQuestion = (question: SurveyQuestionType) => {
    const nextQuIndex = findQuestionIdx(question) + 1
    if (nextQuIndex === focusedQuIdx) {
      ref.current && ref.current.focus()
    } else {
      setFocusedQuIdx(findQuestionIdx(question) + 1)
    }
  }

  const answerQuestion = (
    question: SurveyQuestionType,
    answerType: SurveyAnswerType,
    answerValue: string
  ) => {
    focusNextQuestion(question)
    return props.answerQuestion(question, answerType, answerValue)
  }

  const renderQuestion = (question: SurveyQuestionType) => (
    <SurveyQuestion
      ref={(element) => {
        if (findQuestionIdx(question) === focusedQuIdx) {
          ref.current = element
        }
      }}
      key={question.questionId}
      answerQuestion={answerQuestion}
      question={question}
      showMostCommon={showMostCommon}
      setFieldSaving={setFieldSaving}
      requiresConfirmation={requiresConfirmation}
      missingRequiredInformation={missingRequiredInformation}
    />
  )

  const renderGroup = (group: SurveyQuestionType[]) => (
    <div className="d-flex" key={group[0].questionId}>
      {group.map((question, index) => {
        const isFirst = index === 0
        const isLast = index === group.length - 1
        return (
          <div
            className={classNames({
              "canopy-pis-4x": !isFirst,
              "canopy-pie-4x": !isLast,
            })}
            key={question.questionId}
          >
            {renderQuestion(question)}
          </div>
        )
      })}
    </div>
  )

  return (
    <div className={className}>
      {calculateGrouping().map((questionOrGroup) =>
        Array.isArray(questionOrGroup)
          ? renderGroup(questionOrGroup)
          : renderQuestion(questionOrGroup)
      )}
    </div>
  )
}
