const DEFAULT_TRACKING_TIMEOUT = 10
const DEFAULT_EVENT_VALUE = ""

export const trackEvent = (event): Promise<void> => {
  if (!window._detector) {
    return Promise.resolve()
  }

  window._detector.triggerCustomEvent(event, DEFAULT_EVENT_VALUE)

  return new Promise((resolve) => {
    setTimeout(resolve, DEFAULT_TRACKING_TIMEOUT)
  })
}
