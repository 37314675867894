import React, { useEffect, useState, useContext, useMemo } from "react"
import { Doctor } from "../sharedTypes"
import { refreshDoctor } from "../api"
import { handleError } from "utilities/error"

const DoctorContext = React.createContext<{
  doctor: Doctor
  refreshCurrentDoctor: (doctorId: string) => Promise<void>
  updatingDoctor: boolean
}>({
  doctor: {
    id: "",
    name: "",
    npi: "",
    signOnScreenEnabled: false,
    reviewerAgreementIsSigned: false,
    showNotificationsOptInRequestInfoBanner: false,
  },
  refreshCurrentDoctor: (_doctorId: string) => Promise.resolve(),
  updatingDoctor: false,
})

export const DoctorProvider = ({
  doctorId,
  initialDoctor,
  notificationBadgeStatusChecker,
  children,
}: {
  doctorId: string
  initialDoctor: Doctor
  notificationBadgeStatusChecker: (doctorId: string) => Promise<void>
  children: React.ReactNode
}) => {
  const [currentDoctor, setCurrentDoctor] = useState(initialDoctor)
  const [updatingDoctor, setUpdatingDoctor] = useState(false)

  const refreshCurrentDoctor = useMemo(
    () => async (doctorId: string) => {
      setUpdatingDoctor(true)
      return refreshDoctor(doctorId)
        .then((doctor) => {
          setCurrentDoctor(doctor)
          notificationBadgeStatusChecker(doctorId)
            .then(() => {
              setUpdatingDoctor(false)
            })
            .catch(() => setUpdatingDoctor(false))
        })
        .catch(handleError)
    },
    [notificationBadgeStatusChecker]
  )

  useEffect(() => {
    void refreshCurrentDoctor(doctorId)
    return () => {
      setCurrentDoctor(initialDoctor)
      setUpdatingDoctor(false)
    }
  }, [doctorId, initialDoctor, refreshCurrentDoctor])

  return (
    <DoctorContext.Provider
      value={{ doctor: currentDoctor, refreshCurrentDoctor, updatingDoctor }}
    >
      {children}
    </DoctorContext.Provider>
  )
}

export const useDoctorContext = (): {
  doctor: Doctor
  refreshCurrentDoctor: (doctorId: string) => Promise<void>
  updatingDoctor: boolean
} => {
  const context = useContext(DoctorContext)
  if (!context) {
    throw "useDoctorContext must be used within a DoctorProvider"
  }
  return context
}
