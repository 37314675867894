// @ts-strict-ignore
import React, { useContext } from "react"
import groupBy from "lodash/groupBy"
import { Link } from "react-router-dom"
import DischargeDateForm from "applications/Workflow/containers/DischargeDateForm"
import ContinueButton from "applications/Workflow/components/ContinueButton"
import PatientContactsForm from "./PatientContactsForm"
import FulfillmentGroup from "./FulfillmentGroup"
import OtherContacts from "./OtherContacts"
import * as routes from "applications/Workflow/routes"
import {
  DmeOrder,
  Address,
  EmergencyContact,
  OfferingType,
  EmployerType,
} from "sharedTypes"
import { FormikValues } from "formik"
import { isPatientRequiredBillingInfoComplete } from "../utilities/validation"
import GlobalContext from "context/Global"
import cx from "classnames"

const allowFulfillmentRegrouping = (dmeOrder) => {
  const withoutO2Recerts = dmeOrder.lineItemGroups.filter(
    (lineItemGroup) => lineItemGroup.offeringType !== OfferingType.O2Recert
  )
  const lineItemGroupsByOfferingType = groupBy(withoutO2Recerts, "offeringType")

  return !!Object.values(lineItemGroupsByOfferingType).find(
    (lineItemGroups) => {
      return lineItemGroups.filter((lig) => !lig.isConsignmentCloset).length > 1
    }
  )
}

const areFulfillmentsCompact = (dmeOrder) => {
  const fulfillmentsByOfferingType = groupBy(
    dmeOrder.fulfillments,
    "offeringType"
  )

  return !Object.values(fulfillmentsByOfferingType).find((fulfillments) => {
    return fulfillments.filter((f) => !f.isConsignmentCloset).length > 1
  })
}

type Props = {
  dmeOrder: DmeOrder
  setDmeOrder(dmeOrder: DmeOrder): Promise<void>
  nextPagePath: string
  isPageChecked?: boolean
  updateDelivery(fulfillmenId: string, values: FormikValues): Promise<void>
  updateDeliveryAddress(
    deliveryId: string,
    dmeOrderFulfillment: FormikValues
  ): Promise<void>
  addNewAddress(fulfillmentId: string): void
  editAddress(address: Address): void
  updateEmergencyContact(emergencyContact: EmergencyContact): Promise<void>
  deleteEmergencyContact(emergencyContact: EmergencyContact): Promise<void>
  updatePatientInfo(values): Promise<void>
}

function EditDeliveries({
  dmeOrder,
  setDmeOrder,
  updateDelivery,
  updateDeliveryAddress,
  addNewAddress,
  editAddress,
  updateEmergencyContact,
  deleteEmergencyContact,
  updatePatientInfo,
  nextPagePath,
  isPageChecked,
}: Props) {
  const { currentEmployer } = useContext(GlobalContext)
  const patientContactInfo = (
    <PatientContactsForm
      dmeOrder={dmeOrder}
      updatePatientInfo={updatePatientInfo}
    />
  )

  const showDischargeDateForm =
    dmeOrder.discharge &&
    currentEmployer.employerType === EmployerType.ClinicalFacility
  const dischargeDateForm = showDischargeDateForm && (
    <div className={cx("wide-fixed-row", "well")}>
      <DischargeDateForm
        dischargeDate={dmeOrder.dischargeDate}
        setDmeOrder={setDmeOrder}
      />
    </div>
  )

  const otherContacts = (
    <OtherContacts
      dmeOrder={dmeOrder}
      updateOtherContact={updateEmergencyContact}
      deleteOtherContact={deleteEmergencyContact}
    />
  )

  const fulfillmentGroups = (
    <>
      {Object.entries(
        groupBy(
          dmeOrder.fulfillments.filter(
            (fulfillment) => fulfillment.offeringType !== OfferingType.O2Recert
          ),
          "offeringType"
        )
      ).map(([offeringType, fulfillments]) => (
        <FulfillmentGroup
          key={offeringType}
          dmeOrder={dmeOrder}
          fulfillments={fulfillments}
          updateDelivery={updateDelivery}
          updateDeliveryAddress={updateDeliveryAddress}
          addNewAddress={addNewAddress}
          editAddress={editAddress}
        />
      ))}
    </>
  )

  const footer = (
    <div className="wide-fixed-row">
      <div className="row">
        <div className="col-md-6 order-md-6">
          <div className="text-right text-xs-left canopy-mbe-8x">
            {nextPagePath && (
              <ContinueButton
                isButtonOutlined={!isPageChecked}
                path={nextPagePath}
              />
            )}
          </div>
        </div>
        <div className="col-md-6">
          {allowFulfillmentRegrouping(dmeOrder) && (
            <Link
              to={routes.deliveryEditAddressesPath()}
              className="link nowrap"
            >
              {areFulfillmentsCompact(dmeOrder)
                ? "Shipping to more than one address?"
                : "Change where products/services are going"}
            </Link>
          )}
        </div>
      </div>
    </div>
  )

  return (
    <div className={cx("wide-fixed-row")}>
      {isPatientRequiredBillingInfoComplete(dmeOrder) ? (
        <>
          {patientContactInfo}
          {otherContacts}
          {dischargeDateForm}
          {fulfillmentGroups}
          {footer}
        </>
      ) : (
        <>{patientContactInfo}</>
      )}
    </div>
  )
}

export default EditDeliveries
