import { canopyColorInteractiveLinkDefault } from "@parachutehealth/canopy-tokens-color"
import Alert from "components/Alert"
import Link from "components/Link"
import React, { useContext } from "react"
import { sendEmailVerificationEmail } from "./api"
import { ToastContext } from "components/Toaster"

interface Props {
  reviewerUserId: string
}

const VerifyEmailAlert = ({ reviewerUserId }: Props): React.ReactElement => {
  const { persistentAlert } = useContext(ToastContext)

  const sendVerificationEmail = () => {
    return sendEmailVerificationEmail(reviewerUserId)
      .then((response) => {
        persistentAlert({
          message: `Email successfully sent to ${response.data.email}.`,
          severity: "success",
        })
      })
      .catch(() =>
        persistentAlert({
          message: `Encountered an error. Please use the Help Center button to contact support.`,
          severity: "error",
        })
      )
  }

  return (
    <Alert status="danger" className="w-100-p">
      <strong>Warning! </strong>
      Email address needs to be verified before signature requests preferences
      can be changed.{" "}
      <Link
        style={{ color: canopyColorInteractiveLinkDefault }}
        onClick={sendVerificationEmail}
      >
        Resend email.
      </Link>
    </Alert>
  )
}

export default VerifyEmailAlert
