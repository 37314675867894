import { put } from "services/api"
import { InternalStatus } from "../../sharedTypes"
export function updateDmeOrderInternalStatusUrl(id, employerPrefix: string) {
  return `${employerPrefix}/dashboard/dme_orders/${id}/internal_status.json`
}

export const updateStatus = (
  apiUrl,
  internalStatusId
): Promise<InternalStatus> => {
  return put(apiUrl, { internalStatusId }).then(({ data }) => data)
}
