import React from "react"
import { Form } from "components/form"
import {
  ChartNote,
  ChartNoteQuestion,
  DocumentationRequirement,
} from "sharedTypes"
import DocumentationRequirementsSelection from "./DocumentationRequirementsSelection"
import ReadOnlyChartNote from "./ReadOnlyChartNote"

type Props = {
  className: string
  documentationRequirements: DocumentationRequirement[]
  updateDocumentationRequirementsRequested(
    requestedIds: string[]
  ): Promise<void>
  chartNotes: ChartNote[]
  fetchChartNoteQuestions({ id }: { id: string }): Promise<ChartNoteQuestion[]>
}

const SupplierDocumentationRequirements = ({
  className,
  documentationRequirements,
  updateDocumentationRequirementsRequested,
  chartNotes,
  fetchChartNoteQuestions,
}: Props) => {
  const requestedDocumentationRequirementIds = documentationRequirements
    .filter((docReq) => docReq.requested)
    .map((docReq) => docReq.externalId)

  if (documentationRequirements.length === 0 && chartNotes.length === 0) {
    return <></>
  }

  const renderAvailableDocumentation = () => {
    if (chartNotes.length === 0) {
      return <></>
    }
    return (
      <>
        <hr />
        <p className="color-dark-gray">Available Documentation:</p>
        {chartNotes.map((chartNote) => (
          <div key={chartNote.name}>
            <ReadOnlyChartNote
              chartNote={chartNote}
              fetchChartNoteQuestions={fetchChartNoteQuestions}
            />
          </div>
        ))}
      </>
    )
  }

  return (
    <div className={className}>
      <div className="well">
        <Form
          initialValues={{ requestedDocumentationRequirementIds }}
          onSubmit={({ requestedDocumentationRequirementIds }) =>
            updateDocumentationRequirementsRequested(
              requestedDocumentationRequirementIds
            )
          }
          submitOnChange
        >
          <DocumentationRequirementsSelection
            documentationRequirements={documentationRequirements}
          />
        </Form>
        {renderAvailableDocumentation()}
      </div>
    </div>
  )
}

export default SupplierDocumentationRequirements
