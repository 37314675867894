// @ts-strict-ignore
import $ from "jquery"
import {
  isFileSizeAboveLimit,
  MAX_FILE_SIZE_IN_MB,
} from "utilities/fileValidation"
import sessionStorage from "utilities/storage/session"
import { poll } from "utilities/jquery/document_upload"
import State from "./models/viewer"
import Image from "./models/image"
import Kinetic from "@buxlabs/kinetic"

const ROTATION_AMOUNT = 90

class DocumentViewer {
  el: JQuery
  image: Image
  kinetic: Kinetic
  ui: {
    loader: string
    rotate: string
    file: string
    files: string
    wrapper: string
    overlay: string
    uploadButton: string
    newDocumentInput: string
    signedDocumentInput: string
    submit: string
    workflow: string
  }
  state: State

  constructor(options) {
    this.el = options.el
    this.ui = {
      loader: ".js-ajax-loader",
      rotate: ".js-rotate",
      file: ".document-file",
      files: ".document-files",
      wrapper: ".document-file-wrapper",
      overlay: ".document-overlay",
      uploadButton: ".document-upload-button:not(:disabled)",
      newDocumentInput: "#new_document input[type='file']",
      signedDocumentInput: "#new_document #new_signed_document",
      submit: "#new_document_submit",
      workflow: ".workflow",
    }
  }

  initialize() {
    this.el.addClass("active")
    if (this.el.find(this.ui.loader).length) {
      this.el.find(this.ui.loader).click()
      return
    }

    const that = this
    const isActive = !!this.el.closest(document.documentElement).length
    const downloadButton = this.el.find(".document-upload-button")

    this.state = this.getState()
    this.image = new Image()
    poll(isActive, downloadButton)
    this.transformFiles()
    this.detachHandlers()
    this.attachHandlers()
    this.el.find(this.ui.file).on("load", function () {
      that.transformFile(this)
      const offsetTop = that.state.get("offsetTop")
      const offsetLeft = that.state.get("offsetLeft")
      const node = $(that.ui.files)
      node.scrollTop(offsetTop)
      node.scrollLeft(offsetLeft)
    })
  }

  getState() {
    const state = new State({}, { storage: sessionStorage })
    state.fetch()
    state.on("change:zoom", () => {
      this.setZoom()
    })
    state.on("change:zoom", () => {
      state.save()
    })
    state.on("change:offsetTop", () => {
      state.save()
    })
    state.on("change:offsetLeft", () => {
      state.save()
    })
    return state
  }

  attachHandlers() {
    this.el
      .on("mouseenter", this.ui.rotate, this.onRotateMouseEnter.bind(this))
      .on("mouseleave", this.ui.rotate, this.onRotateMouseLeave.bind(this))
      .on("click", this.ui.rotate, this.onRotateClick.bind(this))
      .on("click", ".js-zoom-in", this.state.zoomIn.bind(this.state))
      .on("click", ".js-zoom-out", this.state.zoomOut.bind(this.state))
      .on("click", ".js-upload-signed", this.onUploadSignedClicked.bind(this))
      .on("click", ".js-upload-other", this.onUploadOtherClicked.bind(this))
      .on("change", this.ui.newDocumentInput, this.onChange.bind(this))
    $(this.ui.files).on("scroll", this.onScroll.bind(this))
    if (this.el.find(this.ui.file).length) {
      const node = this.el.find(this.ui.files)
      this.kinetic = new Kinetic(node[0], { maxvelocity: 80 })
    }
  }

  detachHandlers() {
    this.el.off("click change mouseenter mouseleave")
    $(this.ui.files).off("scroll")
    if (this.el.find(this.ui.file).length && this.kinetic) {
      this.kinetic.destroy()
    }
  }

  destroy() {
    this.detachHandlers()
  }

  transformFiles() {
    this.el.find(this.ui.file).each((index, file) => this.transformFile(file))
  }

  transformFile(file) {
    const $file = $(file)
    const degrees = $file.data("rotation-degrees")
    const css = this.image.transform(
      this.state.get("zoom"),
      degrees,
      $file.height(),
      $file.width()
    )
    $file.css(css)
  }

  getActiveFileWrapper() {
    const midpoint = this.el.find(this.ui.files).height() / 2
    return this.el.find(this.ui.wrapper).filter(function (index, w) {
      const wrapper = $(w)
      const top = wrapper.position().top
      return top <= midpoint && top + wrapper.height() >= midpoint
    })
  }

  onRotateMouseEnter() {
    const wrapper = this.getActiveFileWrapper()
    if (wrapper) {
      $(wrapper).addClass("active")
    }
  }

  onRotateMouseLeave() {
    this.el.find(this.ui.wrapper).removeClass("active")
  }

  onRotateClick() {
    const wrapper = this.getActiveFileWrapper()
    if (wrapper) {
      const file = $(wrapper).find(this.ui.file)
      const path = this.el.find(this.ui.rotate).data("path")
      const body = {
        document_file_transform: {
          file_name: file.data("file-name"),
          rotation_degrees: ROTATION_AMOUNT,
        },
      }
      $.post(path, body)

      const degrees = (file.data("rotation-degrees") + ROTATION_AMOUNT) % 360
      file.data("rotation-degrees", degrees)
      this.transformFile(file)
    }
  }

  setZoom() {
    this.transformFiles()
    this.transformFiles()
    $(this.ui.overlay).css("width", this.state.get("zoom") + "%")
  }

  onUploadOtherClicked() {
    this.onUploadClicked(null)
  }

  onUploadSignedClicked() {
    this.onUploadClicked(true)
  }

  onUploadClicked(signed) {
    this.el.find(this.ui.signedDocumentInput).val(signed)
    this.el.find(this.ui.newDocumentInput).click()
  }

  onChange(e) {
    const size = e.target.files[0].size
    if (isFileSizeAboveLimit(size)) {
      alert(
        "File size is too large. Can not be greater than " +
          MAX_FILE_SIZE_IN_MB +
          "MB"
      )
      return false
    }
    this.el.find(this.ui.uploadButton).each(function (index, button) {
      const $button = $(button)
      $button.attr("disabled", "disabled")
      $button.find(".fas").toggleClass("hidden")
    })
    this.el.find(this.ui.submit).click()
  }

  onScroll() {
    const node = $(this.ui.files)
    const offsetTop = node.scrollTop()
    const offsetLeft = node.scrollLeft()
    this.state.set("offsetTop", offsetTop)
    this.state.set("offsetLeft", offsetLeft)
  }
}

export default DocumentViewer
