import { post } from "services/api"
import { navigate } from "utilities/navigation"
import { employerPrefix } from "utilities/url"
import { Success, Result, Failure } from "components/AsyncButton"

export const reorderDocuments = (supplierId, dmeOrderId) => {
  return (): Promise<Result> => {
    return post(
      `${employerPrefix(
        "Supplier",
        supplierId
      )}/orders/${dmeOrderId}/reorder.json`
    )
      .then(({ data }) => {
        navigate(data["dmeOrderPath"])
        return new Success()
      })
      .catch(
        () =>
          new Failure(
            "Something went wrong. Please refresh the page and try again"
          )
      )
  }
}
