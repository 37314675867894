import React from "react"
import Button from "components/form/Button"
import Icon from "components/Icon"
import classNames from "classnames"

export const ManageViewButton = ({
  showSidebar,
  toggleSidebar,
}: {
  showSidebar: boolean
  toggleSidebar: () => void
}) => (
  <Button
    className={classNames("btn-sm btn-pill dark-outline", {
      "btn-inverted": showSidebar,
    })}
    onClick={toggleSidebar}
  >
    <Icon type="cog" className="canopy-mie-4x" />
    Manage View
  </Button>
)
