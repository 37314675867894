// @ts-strict-ignore
import React from "react"
import Overlay from "components/Overlay"
import SortableTableHeaders from "components/SortableTableHeaders"
import TableCell from "./TableCell"
import { TableColumnDefinition } from "./tableColumnDefinitions"
import { SortDirection } from "sharedTypes"

interface Props<T extends { key: string }> {
  onSortChange(values: {
    sort: string
    direction: SortDirection
  }): Promise<void>

  records: T[]
  loading?: boolean
  sortColumn?: string
  sortDirection?: SortDirection
  tableColumns: TableColumnDefinition<T>[]
}

const Table = <T extends { key: string }>({
  onSortChange,
  records,
  sortColumn,
  sortDirection,
  loading,
  tableColumns,
}: Props<T>) => {
  const stickyColumns = 1

  const renderTableRow = (order: T) => {
    return (
      <tr key={order.key}>
        {tableColumns.map(({ attr, render }, index) => (
          <TableCell
            key={`${order.key}-${attr}`}
            order={order}
            attr={attr}
            render={render}
            isSticky={index < stickyColumns}
          />
        ))}
      </tr>
    )
  }

  return (
    <Overlay active={loading} showSpinner position="top">
      <div className="table-wrapper">
        <div
          className="table-container"
          style={{ maxHeight: "calc(100vh - 300px)" }}
        >
          <table className="table table-striped table-hover table-sortable table-expanded table-dashboard table-flex">
            <SortableTableHeaders
              onSortChange={onSortChange}
              sortColumn={sortColumn}
              sortDirection={sortDirection}
              columns={tableColumns}
              stickyColumns={stickyColumns}
            />
            <tbody>{records.map(renderTableRow)}</tbody>
          </table>
        </div>
      </div>
    </Overlay>
  )
}

export default Table
