import React, { useState } from "react"
import Dropdown from "components/Dropdown"
import { DashboardViewListing } from "../../sharedTypes"
import { handleError } from "utilities/error"

interface Props {
  currentView: DashboardViewListing
  views: DashboardViewListing[]
  fetchViews(): Promise<DashboardViewListing[]>
  onChange(id: string): Promise<void>
}

function ViewsDropdown({ currentView, fetchViews, onChange, views }: Props) {
  const changeHandler = (option) => {
    if (option.id === currentView.externalId) {
      return
    }

    onChange(option.id.toString())
  }
  const [loading, setLoading] = useState(false)
  const startLoading = () => setLoading(true)
  const stopLoading = () => setLoading(false)
  return (
    <Dropdown
      label={
        currentView.isDefault
          ? "Default view"
          : `${currentView.name} (custom view)`
      }
      size="lg"
      onClick={changeHandler}
      onOpen={() => {
        if (views.length === 0) {
          startLoading()
          fetchViews()
            .then(stopLoading)
            .catch((error) => {
              stopLoading()
              handleError(error)
            })
        }
      }}
      loading={loading}
      options={[
        ...views
          .sort((a, b) => (a.createdAt > b.createdAt ? 1 : -1))
          .map((view) => ({
            label: view.isDefault
              ? "Default view"
              : `${view.name} (custom view)`,
            checked: view.externalId === currentView.externalId,
            id: view.externalId,
          })),
      ]}
    />
  )
}

export default ViewsDropdown
