import React from "react"
import PasswordForm from "../../../../components/PasswordForm"

interface Props {
  updatePassword(params): Promise<void>
}

function CreatePassword({ updatePassword }: Props) {
  return (
    <PasswordForm
      header="Create Your Password"
      updatePassword={updatePassword}
    />
  )
}

export default CreatePassword
