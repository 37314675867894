import React from "react"
import Icon from "components/Icon"

type Props = {
  expanded: boolean
  className?: string
}
const DropdownIcon = ({ expanded, className }: Props) => {
  return (
    <Icon type={expanded ? "angle-up" : "angle-down"} className={className} />
  )
}

export default DropdownIcon
