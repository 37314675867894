import React from "react"
import { Button } from "components/form"
import classNames from "classnames"

interface Props {
  active: boolean
  onClick: () => void
}
const ActivityManagementButton: React.FC<Props> = ({ active, onClick }) => {
  const buttonStyleClass = active ? "btn-danger" : "btn-brand"
  const label = active ? "Remove from Menu" : "Add to Menu"

  return (
    <Button
      ariaLabel={label}
      className={classNames("bold btn-sm w-200", buttonStyleClass)}
      onClick={onClick}
    >
      {label}
    </Button>
  )
}

export default ActivityManagementButton
