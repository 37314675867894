import { DoctorSignatureRequest } from "../../api"
import { post } from "services/api"

export const markRequestAsReviewed = async (
  request: DoctorSignatureRequest,
  doctorId: string
) => {
  if (request.type === "Signing::SignatureRequest") {
    return post(
      `/u/d/${doctorId}/signing_signature_requests/${request.id}/reviews`
    )
  } else {
    return post(`/u/d/${doctorId}/reviews?dme_order_id=${request.id}`)
  }
}

export const denyOrder = async (
  request: DoctorSignatureRequest,
  doctorId: string,
  denialData: { reason: string; description: string }
) => {
  if (request.type === "Signing::SignatureRequest") {
    return post(
      `/u/d/${doctorId}/signing_signature_requests/${request.id}/denials`,
      denialData
    )
  } else {
    return post(`/u/d/${doctorId}/denials.json`, {
      dmeOrderId: request.id,
      denials: denialData,
    })
  }
}
