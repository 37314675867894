import React from "react"
import { CanopyIcon } from "@parachutehealth/canopy-icon"
import * as styles from "./index.module.scss"
import Card from "components/Card"
import { Severity } from "sharedTypes"
import classNames from "classnames"

type Props = {
  title?: string
  summary?: string
  qualificationStatus?: "qualified" | "undetermined"
}
const InternalCsrInboxStateAiDetails = ({
  title,
  summary,
  qualificationStatus,
}: Props) => {
  if (!title || !summary) {
    return null
  }
  const severity =
    qualificationStatus === "qualified" ? Severity.Success : Severity.Warning
  return (
    <div className="canopy-my-12x">
      <div className="canopy-mbe-2x">
        <h3 className="d-inline canopy-typography-heading-large">
          Order Summary
        </h3>
        <span className={styles.betaBadge}>Beta</span>
      </div>
      <p> The information is AI-generated. Please confirm the details.</p>
      <Card
        status={severity}
        border="top"
        badge={() =>
          qualificationStatus === "qualified" ? (
            <div
              className={classNames(styles.indicator, styles.successIndicator)}
            >
              <CanopyIcon
                name="circle-check"
                className="canopy-mie-2x"
                color="success"
              />
              Appears to qualify
            </div>
          ) : (
            <div
              className={classNames(styles.indicator, styles.warningIndicator)}
            >
              <CanopyIcon
                name="circle-exclamation"
                className="canopy-mie-2x"
                color="warning"
              />
              Qualification undetermined
            </div>
          )
        }
      >
        <h3 className="canopy-typography-heading-large">{title}</h3>
        <p className={styles.summary}>{summary}</p>
      </Card>
    </div>
  )
}

export default InternalCsrInboxStateAiDetails
