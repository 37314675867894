// @ts-strict-ignore
import React from "react"
import Confirm from "components/Confirm"

type Props = {
  buttonText: string
  cancelText: string
  className: string
  confirmDescription?: string | React.ReactNode
  confirmText: string
  confirmTitle: string
  disabled?: boolean
  onClick(): void
}

const ActionButtonWithConfirm = ({
  buttonText,
  cancelText,
  className,
  confirmDescription,
  confirmText,
  confirmTitle,
  disabled,
  onClick,
}: Props) => {
  return (
    <Confirm
      cancelText={cancelText}
      confirmText={confirmText}
      description={confirmDescription}
      render={(confirm) => (
        <button
          type="submit"
          className={className}
          disabled={disabled}
          onClick={confirm(onClick)}
        >
          {buttonText}
        </button>
      )}
      title={confirmTitle}
    />
  )
}

export default ActionButtonWithConfirm
