import { theme } from "themes/theme"

const navBarHeight = 57
const headerHeight = 64
const documentHeaderHeight = 136
const documentPaddingBottom = 24
const flashHeight = 61
const tableHeaderHeight = 96
const tablePaddingBottom = 24
const infiniteTableHeaderHeight = 154
const infiniteTableFooterHeight = 79
const backButtonHeight = 106

const baseHeight = (flash: boolean) =>
  navBarHeight + headerHeight + theme.spacing(1) + (flash ? flashHeight : 0)
const documentHeight = (flash: boolean, hasBackButton: boolean) =>
  baseHeight(flash) +
  documentHeaderHeight +
  documentPaddingBottom +
  (hasBackButton ? backButtonHeight : 0)
const tableHeight = (flash: boolean) =>
  baseHeight(flash) + tableHeaderHeight + tablePaddingBottom
const infiniteTableHeight = (flash: boolean) =>
  baseHeight(flash) + infiniteTableHeaderHeight + infiniteTableFooterHeight

export const promoHeightCalculation = (flash: boolean) =>
  `calc(100vh - ${navBarHeight}px - ${flash ? flashHeight : 0}px )`

export const heightCalculation = (flash: boolean) =>
  `calc(100vh - ${baseHeight(flash)}px )`

export const documentHeightCalculation = (
  flash: boolean,
  hasBackButton: boolean
) => `calc(100vh - ${documentHeight(flash, hasBackButton)}px )`

export const tableHeightCalculation = (flash: boolean) =>
  `calc(100vh - ${tableHeight(flash)}px )`

export const infiniteTableHeightCalculation = (flash: boolean) =>
  `calc(100vh - ${infiniteTableHeight(flash)}px )`
