// @team @catalog-crew
import React, { createContext, useCallback, useContext } from "react"
import { get } from "lodash"

export type Policies = { model: string; actions: Record<string, boolean> }[]

export const PoliciesContext = createContext<Policies>([])

export const PoliciesProvider = (props: {
  policies?: Policies
  children: any
}) => {
  return (
    <PoliciesContext.Provider value={props.policies || []}>
      {props.children}
    </PoliciesContext.Provider>
  )
}

/**
 * This Hook is the main entry point to using this policies context.
 * Assuming that your component is wrapped in the context, and you loaded data
 * into it during initialization, you can access those permissions by getting the
 * `hasPermission` function and passing it a model name and a policy.
 * e.g.,
 * @example
 *  const hasPermission = usePolicies()
 *  const canDoTheThing = hasPermission("MyModel", "create")
 */
export const usePolicies = (): {
  hasPermission: (model: string, action: string) => boolean
} => {
  const policies: Policies = useContext(PoliciesContext)

  const hasPermission = useCallback(
    (model: string, action: string) => {
      const foundModel = policies.find((p) => p.model === model) || {}

      return get(foundModel, ["actions", action], false)
    },
    [policies]
  )

  return { hasPermission }
}

const wrap = (Component, policies?: Policies) => (props) => (
  <PoliciesProvider policies={policies}>
    <Component {...props} />
  </PoliciesProvider>
)

export const withPoliciesProvider = (Component, policies?: Policies) =>
  wrap(Component, policies)
