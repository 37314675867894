import React from "react"
import Agreement from "./Agreement"
import Definitions from "./Definitions"
import Exhibit from "./Exhibit"

function LegalText() {
  return (
    <>
      <Agreement />
      <Definitions />
      <Exhibit />
    </>
  )
}

export default LegalText
