// @ts-strict-ignore
import React from "react"
import { DmeOrder } from "sharedTypes"
import { DoctorContactAttributes, SignableRequirement } from "../sharedTypes"
import ContactSignature from "./ContactSignature"
import HowItWorks from "./HowItWorks"

type Props = {
  dmeOrder: DmeOrder
  isEpicEnabledForDoctor: boolean
  isCernerEnabledForDoctor: boolean
  updateSignatureChannel(channel: string, isSelected: boolean): Promise<void>
  createDoctorContact(attributes: DoctorContactAttributes): Promise<any>
  signableRequirements: SignableRequirement[]
  verifiedChannels: string[]
}

function DigitalSignature(props: Props) {
  const {
    dmeOrder,
    isEpicEnabledForDoctor,
    isCernerEnabledForDoctor,
    verifiedChannels,
    createDoctorContact,
    updateSignatureChannel,
  } = props

  const { doctor } = dmeOrder

  const isContactSignatureTypeAvailable = (channel) => {
    return Object.keys(dmeOrder.signatureChannels).includes(channel)
  }

  const contactSignatureData = [
    {
      contactName: "Epic login id",
      contactTitle: "InBasket",
      count: doctor && doctor.cacheSignaturesCounts.epic,
      channel: "epic",
      dmeOrder,
      maskedData:
        doctor && isEpicEnabledForDoctor
          ? "Message will be delivered to clinician's Epic InBasket"
          : null,
      updateSignatureChannel,
      verifiedChannels,
    },
    {
      contactName: "Cerner login id",
      contactTitle: "Message Center",
      count: doctor && doctor.cacheSignaturesCounts.cerner,
      channel: "cerner",
      dmeOrder,
      maskedData:
        doctor && isCernerEnabledForDoctor
          ? "Message will be delivered to clinician's Cerner Message Center"
          : null,
      updateSignatureChannel,
      verifiedChannels,
    },
    {
      contactName: "email address",
      contactTitle: "email",
      count: doctor && doctor.cacheSignaturesCounts.email,
      createDoctorContact,
      channel: "email",
      dmeOrder,
      maskedData: doctor && doctor.maskedEmail,
      updateSignatureChannel,
      verifiedChannels,
    },
    {
      contactName: "mobile number",
      contactTitle: "text message",
      count: doctor && doctor.cacheSignaturesCounts.sms,
      createDoctorContact,
      channel: "sms",
      dmeOrder,
      maskedData: doctor && doctor.maskedMobileNumber,
      updateSignatureChannel,
      verifiedChannels,
    },
    {
      contactName: "fax number",
      contactTitle: "fax",
      count: doctor && doctor.cacheSignaturesCounts.fax,
      createDoctorContact,
      channel: "fax",
      dmeOrder,
      maskedData: doctor && doctor.faxNumber,
      updateSignatureChannel,
      verifiedChannels,
    },
  ]

  return (
    <div>
      <HowItWorks className="canopy-mbe-12x" type={HowItWorks.Type.Request}>
        <p>
          This method sends a request for signature to any of the following
          addresses. The request(s) will be sent on submission.
        </p>
      </HowItWorks>
      {contactSignatureData
        .filter((item) => isContactSignatureTypeAvailable(item.channel))
        .map((item) => (
          <ContactSignature key={item.contactName} {...item} />
        ))}
    </div>
  )
}

export default DigitalSignature
