import { dmeOrderUrl } from "applications/Workflow/urls"

export const documentationRequirementPdfDataUrl = (
  documentationRequirementId: string
) =>
  dmeOrderUrl(
    `/documentation_requirements/${documentationRequirementId}/fillable_pdf_data.pdf`
  )

export const updateDocumentationRequirementPdfDataUrl = (
  documentationRequirementId: string
) =>
  dmeOrderUrl(
    `/documentation_requirements/${documentationRequirementId}/fillable_pdf_data`
  )
export const markDocumentationRequirementAsCompleteUrl = (
  documentationRequirementId
): string =>
  dmeOrderUrl(
    `/documentation_requirements/${documentationRequirementId}/mark_complete.json`
  )
