// @ts-strict-ignore
import React from "react"
import Icon from "components/Icon"
import classNames from "classnames"
import { Severity } from "sharedTypes"
import { CanopyIcon } from "@parachutehealth/canopy-icon"

type Props = {
  className?: string
  type?: Severity
}

const ICONS = {
  default: "spinner",
  danger: "exclamation-circle",
  info: "info-circle",
  success: "check-circle",
  warning: "exclamation-triangle",
  waiting: "user-clock",
  ready: "thumbs-up",
  snoozed: "clock-rotate-left",
}

const COLORS = {
  default: "color-dark-gray",
  danger: "color-danger",
  info: "color-info",
  success: "color-check-success",
  warning: "color-warning",
  waiting: "color-black",
  ready: "color-black",
}

function SeverityIcon({ className, type }: Props) {
  if (type === "snoozed") {
    return (
      <CanopyIcon
        className="canopy-mie-2x"
        name="clock-rotate-left"
        fill="canopyColorPrimitivesWhite100"
        size="xSmall"
        data-testid="snoozed-icon"
      />
    )
  } else
    return (
      <Icon
        type={ICONS[type] || "spinner"}
        className={classNames(className, COLORS[type] || "color-dark-gray")}
      />
    )
}

export default SeverityIcon
