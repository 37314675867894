import { employerPrefix } from "utilities/url"

export const fulfillmentAgreementUrl = (id: string) => {
  return `${employerPrefix()}/fulfillment_agreements/${id}.json`
}
export const createPinnedSupplierUrl = () => {
  return `${employerPrefix()}/pinned_suppliers.json`
}

export const destroyPinnedSupplierUrl = (supplierId) => {
  return `${employerPrefix()}/pinned_suppliers/${supplierId}.json`
}
