import React from "react"

const ErrorMessage: React.FC = ({ children }) => {
  return (
    <p className="text-center color-danger font-xs canopy-mbs-12x">
      {children}
    </p>
  )
}

export default ErrorMessage
