import { get, post, put, del } from "services/api"
import { DashboardView, DashboardViewConfiguration } from "../sharedTypes"
import { defaultFilterSettings } from "../filterConfigs"
import { defaultColumnSettings } from "../components/tableColumnDefinitions"

const viewWithDefaultSettings = (view: DashboardView) => {
  const filterSettings = {
    ...defaultFilterSettings,
    ...view.filterSettings,
  }
  const columnSettings = {
    ...defaultColumnSettings,
    ...view.columnSettings,
  }
  return {
    ...view,
    filterSettings,
    columnSettings,
  }
}
const getInitialValues = (supplierOrganizationId) => (): Promise<any> => {
  const url = `/u/r/${supplierOrganizationId}/dashboard/initial_values.json`
  return get(url).then(({ data }) => {
    data.currentView = viewWithDefaultSettings(data.currentView)
    return data
  })
}

const getSupplierDashboardViews = (supplierOrganizationId) => (): Promise<
  DashboardView[]
> => {
  const url = `/u/r/${supplierOrganizationId}/dashboard_views`
  return get(url).then(({ data }) => data.map(viewWithDefaultSettings))
}

type CreateViewParams = Omit<
  DashboardView,
  "externalId" | "createdAt" | "isDefault"
>
const createView = (supplierOrganizationId) => (
  dashboardView: CreateViewParams
): Promise<{ externalId: string }> => {
  const url = `/u/r/${supplierOrganizationId}/dashboard_views`
  return post(url, { dashboardView }).then(({ data }) => data)
}

const updateView = (supplierOrganizationId) => (
  viewId: string,
  dashboardView: DashboardViewConfiguration
): Promise<void> => {
  const url = `/u/r/${supplierOrganizationId}/dashboard_views/${viewId}`
  return put(url, { dashboardView }).then(({ data }) => data)
}

const setActiveView = (supplierOrganizationId) => (
  viewId: string
): Promise<void> => {
  const url = `/u/r/${supplierOrganizationId}/dashboard_views/${viewId}/set_current_view`
  return put(url).then(({ data }) => data)
}

const deleteView = (supplierOrganizationId) => async (
  viewId: string
): Promise<void> => {
  const url = `/u/r/${supplierOrganizationId}/dashboard_views/${viewId}`
  await del(url)
}
const shareViewUrl = (supplierOrganizationId) => (viewId: string): string => {
  return `${window.location.origin}/u/r/${supplierOrganizationId}/dashboard_views/${viewId}/apply`
}

export type ViewAPI = {
  getInitialValues: () => Promise<any>
  getDashboardViews: () => Promise<DashboardView[]>
  setActiveView: (viewId: string) => Promise<void>
  createView(params: CreateViewParams): Promise<{ externalId: string }>
  updateView(viewId: string, params: DashboardViewConfiguration): Promise<void>
  deleteView: (viewId: string) => Promise<void>
  shareViewUrl: (viewId: string) => string
}
export const createViewAPI = (supplierOrganizationId: string): ViewAPI => ({
  getInitialValues: getInitialValues(supplierOrganizationId),
  getDashboardViews: getSupplierDashboardViews(supplierOrganizationId),
  setActiveView: setActiveView(supplierOrganizationId),
  createView: createView(supplierOrganizationId),
  updateView: updateView(supplierOrganizationId),
  deleteView: deleteView(supplierOrganizationId),
  shareViewUrl: shareViewUrl(supplierOrganizationId),
})
