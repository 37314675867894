import React, { FC } from "react"
import { Link } from "react-router-dom"
import { signatureRequestPath, csvUploadPath } from "./routes"
import classNames from "classnames"
import * as styles from "./header.module.scss"

interface Props {
  activeTab: "dashboard" | "uploads"
  supplierOrganizationId: string
  showUploadsTab?: boolean
  dashboardPath?: string
  uploadsPath?: string
  dashboardName?: string
}

export const Header: FC<Props> = (props: Props) => {
  return (
    <div>
      <div className={styles.container}>
        <ul role="tablist" className={styles.tabList}>
          <li role="presentation" className={styles.tabContainer}>
            <Link
              to={
                props.dashboardPath ||
                signatureRequestPath(props.supplierOrganizationId)
              }
              role="tab"
              className={classNames(styles.tab, {
                [styles.tabActive]: props.activeTab === "dashboard",
              })}
              aria-selected={props.activeTab === "dashboard"}
            >
              {props.dashboardName || "Signature Requests"}
            </Link>
          </li>
          {props.showUploadsTab && (
            <li role="presentation" className={styles.tabContainer}>
              <Link
                to={
                  props.uploadsPath ||
                  csvUploadPath(props.supplierOrganizationId)
                }
                role="tab"
                className={classNames(styles.tab, {
                  [styles.tabActive]: props.activeTab === "uploads",
                })}
                aria-selected={props.activeTab === "uploads"}
              >
                Uploads
              </Link>
            </li>
          )}
        </ul>
      </div>
    </div>
  )
}
