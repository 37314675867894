import React, { useEffect, useState } from "react"
import { getSuppliers } from "components/SupplierOrganizationChooseSupplierModal/api"
import SelectFilter from "components/SelectFilter"

export const SupplierSelectFilter = (props: {
  supplierOrganizationId: string
  onChange: (params: { suppliers: string[] }) => void
  selectedSupplierIds: string[] | undefined
}) => {
  const [suppliers, setSuppliers] = useState<{ name: string; id: string }[]>([])
  useEffect(() => {
    getSuppliers(props.supplierOrganizationId, true).then(setSuppliers)
  }, [props.supplierOrganizationId])

  return (
    <SelectFilter
      onChange={(values) => {
        props.onChange({ suppliers: values.suppliers })
      }}
      options={suppliers.map((s) => ({ label: s.name, value: s.id }))}
      name="suppliers"
      label="Suppliers"
      initialValues={props.selectedSupplierIds}
    />
  )
}
