import React, { useState } from "react"
import { Supplier, urlForCreatingOrderWithSupplier } from "./api"
import Modal from "components/Modal"
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from "@material-ui/core"
import { navigate } from "utilities/navigation"
import * as styles from "./index.module.scss"
import { Nullable } from "sharedTypes"

const defaultOnSubmit = (supplierId: string) =>
  navigate(urlForCreatingOrderWithSupplier(supplierId))

export const ChooseSupplierModal = ({
  suppliers,
  show,
  close,
  onSubmit,
}: {
  suppliers: Supplier[]
  show: boolean
  close: () => void
  onSubmit?: (supplierId: string) => void
}) => {
  const [selectedSupplierExternalId, setSelectedSupplierExternalId] = useState<
    Nullable<string>
  >(null)

  const submit = () => {
    if (!selectedSupplierExternalId) return
    ;(onSubmit || defaultOnSubmit)(selectedSupplierExternalId)
  }

  return (
    <Modal
      show={show}
      size="md"
      title="Choose Supplier for Order"
      cancel={close}
    >
      <Modal.Body>
        <FormControl component="fieldset">
          <FormLabel component="legend" className={styles.groupLabel}>
            Suppliers
          </FormLabel>
          <div className={styles.buttonContainer}>
            <RadioGroup
              aria-label="supplier"
              name="supplier"
              onChange={(event, value) => setSelectedSupplierExternalId(value)}
            >
              {suppliers.map((supplier) => (
                <FormControlLabel
                  value={supplier.id}
                  control={<Radio color="primary" />}
                  label={supplier.name}
                  key={supplier.id}
                />
              ))}
            </RadioGroup>
          </div>
        </FormControl>
      </Modal.Body>
      <Modal.Footer>
        <button
          type="submit"
          className="btn btn-primary"
          disabled={!selectedSupplierExternalId}
          onClick={submit}
        >
          Create New Order
        </button>
      </Modal.Footer>
    </Modal>
  )
}
