import React from "react"
import { CanopyButton } from "@parachutehealth/canopy-button"

interface Props {
  href: string
  target: string | undefined
}

const CustomerSupportLinkButton: React.FC<Props> = ({ href, target }) => {
  return (
    <CanopyButton
      as="a"
      accessibleText="support"
      data-analytics="knowledge-base-globalnav-clicked"
      data-analytics-context="CustomerSupport"
      data-analytics-replay-event
      href={href}
      iconStart="question"
      size="small"
      target={target}
      variant="tertiary"
    />
  )
}

export default CustomerSupportLinkButton
