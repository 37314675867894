import React, { ReactElement } from "react"
import SelectFilter from "components/SelectFilter"
import { ClinicalFacility } from "graphql/__generated__/graphql"

interface Props {
  clinicalFacilities: ClinicalFacility[]
  fetchClinicalFacilities(
    term: string
  ): Promise<{ label: string; value: string }[]>
  initialClinicalFacilityIds: string[]
  onChange: (values: { clinicalFacilityIds: string[] }) => void
}

function ClinicalFacilitySelect({
  clinicalFacilities,
  fetchClinicalFacilities,
  initialClinicalFacilityIds,
  onChange,
}: Props): ReactElement<Props> {
  const options = clinicalFacilities?.map((clinicalFacility) => {
    return { label: clinicalFacility.name, value: clinicalFacility.id }
  })

  return (
    <SelectFilter
      name="clinicalFacilityIds"
      label="Facilities"
      initialValues={initialClinicalFacilityIds}
      options={options}
      fetchOptions={fetchClinicalFacilities}
      onChange={onChange}
      minLength={0}
      enableReinitialize
    />
  )
}

export default ClinicalFacilitySelect
