import React from "react"

interface Props {
  stateName: string
}

const ServiceAreaWarning: React.FC<Props> = ({ stateName }) => (
  <div className="d-flex align-items-center canopy-mbe-8x canopy-mbs-4x">
    <span className="icon fas fa-exclamation-triangle color-warning canopy-mie-2x" />
    <span className="font-subparagraph color-dark-gray">
      This supplier may not service {stateName}
    </span>
  </div>
)

export default ServiceAreaWarning
