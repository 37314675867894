import React from "react"

export interface BreadcrumbItem {
  text: string
  href?: string
}

interface Props {
  items: BreadcrumbItem[]
}

function Item({ item }: { item: BreadcrumbItem }) {
  if (item.href) {
    return (
      <li className="breadcrumb-item">
        <a href={item.href}>{item.text}</a>
      </li>
    )
  }
  return <li className="breadcrumb-item">{item.text}</li>
}

function Breadcrumb({ items }: Props) {
  return (
    <ol className="breadcrumb">
      {items.map((item, index) => (
        <Item key={item.text + index} item={item} />
      ))}
    </ol>
  )
}

export default Breadcrumb
