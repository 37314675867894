import { clinicalPath } from "../../routes"
import { RouteComponentProps } from "react-router"

export const LEGAL_WARNING =
  "You are responsible for ensuring that any patient information submitted by you is accurate and correct. Parachute does not provide recommendations related to the appropriateness of any order or plan of care."

export const CRITERIA_NOT_MET =
  "Based on the provided information, evidence may not support the medical necessity of the product or service. You may still submit the order for further review by the payor. To support your submission, please upload any additional documentation that may assist in the medical review process."

export const CRITERIA_MET =
  "Evidence supports the medical necessity of the product or service. Please select the signing clinician and ensure all details are accurate before final submission."

export type FillablePdfPathRouteProps = RouteComponentProps<{
  documentationRequirementExternalId
}>
export const fillablePdfPathMatcher = (): string =>
  `${clinicalPath()}/pdfs/:documentationRequirementExternalId?`
export const fillablePdfPath = (
  documentationRequirementExternalId?: string
): string => `${clinicalPath()}/pdfs/${documentationRequirementExternalId}`
