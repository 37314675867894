import React from "react"
import Suggestion from ".."

interface Props {
  query: string
  prefix?: string
}

const NoResults = ({ prefix, query }: Props) => {
  return (
    <Suggestion small unselectable>
      <strong>{prefix}&ldquo;</strong>
      {query}
      <strong>&rdquo;</strong>
    </Suggestion>
  )
}

NoResults.defaultProps = {
  isHighlighted: false,
  prefix: "No results for ",
}
export default NoResults
