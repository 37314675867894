import React from "react"
import FocusTile from "./FocusTile"
import * as styles from "./FocusTiles.module.scss"
import { useFeatureFlags } from "components/FeatureFlagContext"
import {
  getAllFacilitiesIncomingOrdersCount,
  getIncomingOrdersCount,
  getSentBackForReviewOrdersCount,
} from "applications/ClinicalFacilityDashboard/api"
import { getUnreadEventsCount } from "applications/ClinicalFacilityDashboard/api"
import { QueryClientProvider, QueryClient } from "@tanstack/react-query"
import {
  createdBySupplierSubpageUrl,
  newMessagesSubpageUrl,
  sentBackForReviewSubpageUrl,
} from "./urls"
import { incomingOrdersUrl } from "components/ClinicalDashboard/urls"
import { trackEvent, EventCategory } from "utilities/tracking"

const queryClient = new QueryClient()

type FocusTileEvent =
  | "subpage_new_messages"
  | "subpage_incoming_orders"
  | "subpage_sent_back_for_review"
  | "superfilter_new_messages"
  | "superfilter_incoming_orders"
  | "superfilter_sent_back_for_review"
  | "legacy_new_messages"
  | "legacy_incoming_orders"
  | "legacy_sent_back_for_review"

type Props = {
  clinicalFacilityId: string
  handleNewMessagesSuperfilterClick: () => void
  handleIncomingOrdersSuperfilterClick: () => void
  handleSentBackForReviewSuperfilterClick: () => void
  selectedFocusTile:
    | "newMessages"
    | "incomingOrders"
    | "sentBackForReview"
    | null
  incomingOrdersEnabled: boolean
}

const FocusTiles: React.FC<Props> = ({
  clinicalFacilityId,
  handleNewMessagesSuperfilterClick,
  handleIncomingOrdersSuperfilterClick,
  handleSentBackForReviewSuperfilterClick,
  selectedFocusTile,
  incomingOrdersEnabled,
}: Props) => {
  const { getFlagValue, isFeatureEnabled } = useFeatureFlags()
  const focusTilesFeatureFlagValue = getFlagValue("facilityDashboardFocusTiles")

  if (!focusTilesFeatureFlagValue) return null

  const subpageTilesEnabled = focusTilesFeatureFlagValue === "subpage"
  const superfilterTilesEnabled = focusTilesFeatureFlagValue === "superfilter"
  const legacyTileEnabled = focusTilesFeatureFlagValue === "legacy"

  const isIncomingOrdersEnabledViaSetting =
    isFeatureEnabled("incomingOrdersEnabledSetting") && incomingOrdersEnabled

  const trackFocusTileEvent = (event: FocusTileEvent) => {
    trackEvent(EventCategory.FacilityDashboardFocusTiles, event)
  }

  return (
    <QueryClientProvider client={queryClient}>
      <div className={styles.outerContainer}>
        {subpageTilesEnabled && (
          <>
            <FocusTile
              getCount={getUnreadEventsCount}
              title="New messages"
              subtitle="Orders you follow that have new messages."
              href={newMessagesSubpageUrl(clinicalFacilityId)}
              onClick={() => trackFocusTileEvent("subpage_new_messages")}
            />
            <FocusTile
              getCount={getIncomingOrdersCount}
              title="Incoming orders"
              subtitle="Orders sent from suppliers that require review."
              href={createdBySupplierSubpageUrl(clinicalFacilityId)}
              onClick={() => trackFocusTileEvent("subpage_incoming_orders")}
            />
            <FocusTile
              getCount={getSentBackForReviewOrdersCount}
              title="Sent back for review"
              subtitle="Submitted orders sent back for revision."
              href={sentBackForReviewSubpageUrl(clinicalFacilityId)}
              onClick={() =>
                trackFocusTileEvent("subpage_sent_back_for_review")
              }
            />
          </>
        )}
        {superfilterTilesEnabled && (
          <>
            <FocusTile
              getCount={getUnreadEventsCount}
              title="New messages"
              subtitle="Orders you follow that have new messages."
              selected={selectedFocusTile === "newMessages"}
              onClick={() => {
                trackFocusTileEvent("superfilter_new_messages")
                handleNewMessagesSuperfilterClick()
              }}
            />
            <FocusTile
              getCount={getIncomingOrdersCount}
              title="Incoming orders"
              subtitle="Orders sent from suppliers that require review."
              selected={selectedFocusTile === "incomingOrders"}
              onClick={() => {
                trackFocusTileEvent("superfilter_incoming_orders")
                handleIncomingOrdersSuperfilterClick()
              }}
            />
            <FocusTile
              getCount={getSentBackForReviewOrdersCount}
              title="Sent back for review"
              subtitle="Submitted orders sent back for revision."
              selected={selectedFocusTile === "sentBackForReview"}
              onClick={() => {
                trackFocusTileEvent("superfilter_sent_back_for_review")
                handleSentBackForReviewSuperfilterClick()
              }}
            />
          </>
        )}
        {legacyTileEnabled && (
          <>
            <FocusTile
              getCount={getUnreadEventsCount}
              title="New messages"
              subtitle="Orders you follow that have new messages."
              href={newMessagesSubpageUrl(clinicalFacilityId)}
              onClick={() => trackFocusTileEvent("legacy_new_messages")}
            />
            {isIncomingOrdersEnabledViaSetting && (
              <FocusTile
                getCount={getAllFacilitiesIncomingOrdersCount}
                title="Incoming orders"
                subtitle="Orders sent from suppliers that require review."
                href={incomingOrdersUrl}
                onClick={() => trackFocusTileEvent("legacy_incoming_orders")}
              />
            )}
            <FocusTile
              getCount={getSentBackForReviewOrdersCount}
              title="Sent back for review"
              subtitle="Submitted orders sent back for revision."
              href={sentBackForReviewSubpageUrl(clinicalFacilityId)}
              onClick={() => trackFocusTileEvent("legacy_sent_back_for_review")}
            />
          </>
        )}
      </div>
    </QueryClientProvider>
  )
}

export default FocusTiles
