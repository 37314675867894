import { EventCategory, trackEvent } from "utilities/tracking"

const EVENT_CATEGORY = EventCategory.OrderSubmissionWarnings
const VIEW_EVENT = "viewed"
const CLICK_EVENT = "clicked"

export const trackView = (warningType: string) => {
  trackEvent(EVENT_CATEGORY, `${VIEW_EVENT}-${warningType}`)
}

export const trackClick = (warningType) => {
  trackEvent(EVENT_CATEGORY, `${CLICK_EVENT}-${warningType}`)
}
