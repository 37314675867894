// @team @fhirbenders

import { gql } from "@apollo/client"
import { TypedDocumentNode } from "@graphql-typed-document-node/core"
import {
  PendingOrderMigrateToDmeOrderMutation,
  PendingOrderMigrateToDmeOrderMutationVariables,
} from "graphql/__generated__/graphql"

const PendingOrderMigrateToDmeOrder: TypedDocumentNode<
  PendingOrderMigrateToDmeOrderMutation,
  PendingOrderMigrateToDmeOrderMutationVariables
> = gql`
  mutation PendingOrderMigrateToDmeOrder($pendingOrderId: ID!) {
    pendingOrderMigrateToDmeOrder(input: { pendingOrderId: $pendingOrderId }) {
      pendingOrder {
        dmeOrder {
          id
        }
      }
    }
  }
`
export default PendingOrderMigrateToDmeOrder
