import React from "react"
import classNames from "classnames"

type Props = {
  className?: string
  content?: string | React.ReactElement
}

function InputText({ className, content }: Props) {
  if (!content) {
    return null
  }
  if (typeof content === "string") {
    return (
      <div className={classNames("color-dark-gray font-xs", className)}>
        {content}
      </div>
    )
  }
  return content
}

export default InputText
