import { post } from "../../services/api"
import { addCliniciansToReviewerUrl } from "./urls"
import { SignUpSource } from "sharedTypes"

export function addCliniciansToReviewer(
  doctorIds: string[],
  signUpSource: SignUpSource
): Promise<{ path: string }> {
  return post(addCliniciansToReviewerUrl, {
    doctorIds: doctorIds,
    signUpSource: signUpSource,
  })
    .then(({ data }) => data)
    .catch(({ response }) => Promise.reject(response.data))
}
