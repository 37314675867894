import React from "react"
import { EmrInstance } from "./sharedTypes"
import InternalEpicLogin from "./components/InternalEpicLogin"
import { createSessionUrl } from "./urls"
import { navigate } from "utilities/navigation"

interface Props {
  emrInstances: EmrInstance[]
}

const EpicLogin = ({ emrInstances }: Props) => {
  const createSession = ({
    emrInstanceId,
  }: {
    emrInstanceId: string
  }): Promise<void> => {
    navigate(createSessionUrl(emrInstanceId))
    return Promise.resolve()
  }

  return (
    <InternalEpicLogin
      createSession={createSession}
      emrInstances={emrInstances}
    />
  )
}

export default EpicLogin
