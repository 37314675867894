import { useEffect, useState } from "react"
import { get } from "../../services/api"
import { Nullable, SortDirection } from "../../sharedTypes"
import { useDebounce } from "hooks/useDebounce"

export type SignedResolution = {
  type: "signed"
  completedAt: string
}

export type Filters = {
  suppliers?: string[]
}

export function isDeniedResolution(
  resolution?: DeniedResolution | SignedResolution
): resolution is DeniedResolution {
  return resolution?.type === "denied"
}

export function isSignedResolution(
  resolution?: DeniedResolution | SignedResolution
): resolution is SignedResolution {
  return resolution?.type === "signed"
}

export type DeniedResolution = {
  type: "denied"
  completedAt: string
  denialReason: string
  description?: string
}

export type DocumentationOrder = {
  id: string
  createdAt: string
  lastNotification?: string
  lastNotificationMethod?: string
  supplierId: string
  documentationData: {
    items?: { hcpcsCode?: string }[]
    orderDate?: string
    icd10CodesWithDescription?: string[][]
  }
  patient: {
    name: string
    supplierSystemPatientId?: string
    dateOfBirth: string
  }
  doctor: {
    name: string
    npi: string
    state: string
    city: string
    zip: string
  }
  supplier: {
    name: string
  }
  signatureResolution?: SignedResolution | DeniedResolution
  supplierSystemOrderId?: string
  returnFaxes?: string[]
  patientActivity: string
  patientSelectedDoctor: Nullable<PatientSelectedDoctorInfo>
  patientLastAppointment: Nullable<string>
  productNotNeededReason: Nullable<string>
  productNotNeededOtherReason: Nullable<string>
}

export type PatientSelectedDoctorInfo = {
  firstName: string
  lastName: string
  npi: string
  city: string
  state: string
  zip: string
}

type DocumentationOrdersResponse = {
  orders: DocumentationOrder[]
  totalCount: number
}
export enum SignatureStatuses {
  SIGNED = "signed",
  PENDING = "pending",
  DENIED = "denied",
}

export enum CreationMethods {
  API = "supplier_api",
  UPLOAD = "upload",
}

export const incompleteSignatureStatuses = [
  SignatureStatuses.PENDING,
  SignatureStatuses.DENIED,
]

export const completeSignatureStatuses = [SignatureStatuses.SIGNED]

export type DocumentationOrderQueryParameters = {
  searchText?: string
  clinicians?: string[]
  suppliers?: string[]
  supplierOrganizationId: string
  sortColumn?: string
  sortDirection?: SortDirection
  pageNumber?: number
  signatureStatuses?: SignatureStatuses[]
  createdAtStartAt?: string
  createdAtEndAt?: string
  dateReturnedStartAt?: string
  dateReturnedEndAt?: string
  lastNotificationMethod?: string[]
  patientActivity?: string[]
  patientLastAppointment?: string[]
  creationMethods?: string[]
}

const fetchOrders = async (
  searchParameters: DocumentationOrderQueryParameters
): Promise<DocumentationOrdersResponse> => {
  return get("/web_api/documentation_orders.json", {
    ...searchParameters,
  }).then((res) => {
    return res.data as DocumentationOrdersResponse
  })
}
export const useOrderFetcher = (
  initialQueryParameters: DocumentationOrderQueryParameters,
  initialPatientActivityEnabled: boolean
): {
  updateSearchParameters(
    params: Partial<DocumentationOrderQueryParameters>
  ): void
  orders: DocumentationOrder[]
  searchParameters: DocumentationOrderQueryParameters
  totalCount: number
  loadMore: () => void
  loading: boolean
  removeOrder: (id: string) => void
  filters: Filters
  patientActivityEnabled: boolean
} => {
  const [orders, setOrders] = useState<DocumentationOrder[]>([])
  const [pageNumber, setPageNumber] = useState(1)
  const [searchParameters, setSearchParameters] = useState<
    DocumentationOrderQueryParameters
  >(initialQueryParameters)
  const [totalCount, setTotalCount] = useState(0)
  const [loading, setLoading] = useState(false)
  const [patientActivityEnabled, setPatientActivityEnabled] = useState(
    initialPatientActivityEnabled
  )

  const debouncedFetchOrders = useDebounce(fetchOrders)
  useEffect(() => {
    setLoading(true)
    if (pageNumber === 1) {
      debouncedFetchOrders(searchParameters).then((data) => {
        setOrders(data.orders)
        setTotalCount(data.totalCount)
        setPatientActivityEnabled(data.patientActivityEnabled)
        setLoading(false)
      })
    } else {
      debouncedFetchOrders({ ...searchParameters, pageNumber }).then((data) => {
        setOrders((old: DocumentationOrder[]) => [...old, ...data.orders])
        setTotalCount(data.totalCount)
        setPatientActivityEnabled(data.patientActivityEnabled)
        setLoading(false)
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParameters, pageNumber])
  const updateSearchParameters = (
    updatedParameters: Partial<DocumentationOrderQueryParameters>
  ) => {
    setPageNumber(1)
    setSearchParameters((oldState) => ({ ...oldState, ...updatedParameters }))
  }

  const loadMore = () => setPageNumber((old) => old + 1)

  const removeOrder = (id: string): void => {
    setOrders((orders) => orders.filter((o) => o.id !== id))
  }
  return {
    updateSearchParameters,
    orders,
    searchParameters,
    totalCount,
    loadMore,
    loading,
    removeOrder,
    filters: {
      suppliers: searchParameters.suppliers || [],
    },
    patientActivityEnabled,
  }
}
