import $ from "jquery"

function showOverlay() {
  $(".workflow-overlay").show().addClass("active")
}

function hideOverlay() {
  $(".workflow-overlay").fadeOut(500, function () {
    $(this).removeClass("active")
  })
}

$(document)
  .on("submit", "form.js-overlay", showOverlay)
  .on("submit.rails", "form.js-overlay", showOverlay)

$(window).on("hide:overlay", hideOverlay)
