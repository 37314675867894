// @ts-strict-ignore
export const indexesOfInvalidCharacters = (
  string: string,
  regex: RegExp,
  length: number
): number[] => {
  const indexes = []

  if (string.length < length) {
    for (let index = string.length; index < length; index += 1) {
      indexes.push(index)
    }
  }

  let match = string.match(regex)
  let offset = 0

  while (string.length > 0 && match) {
    indexes.push(match.index + offset)
    offset += match.index + 1

    string = string.substr(match.index + 1)
    match = string.match(regex)
  }

  return indexes
}
