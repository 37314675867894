import Button from "components/form/Button"
import React, { FC, useState } from "react"
import { SelectOption } from "components/form/sharedTypes"
import DenyOrderModal from "../../DoctorApproval/components/DenyOrderModal"
import { post } from "services/api"
import { redirectToDoctorsDashboard } from "./redirectToDoctorsDashboard"
import { employerPrefix } from "../../../utilities/url"

export const DenyOrderButton: FC<{
  denialReasons: SelectOption[]
  signatureRequestId: string
  doctorId: string
}> = (props) => {
  const [showModal, setShowModal] = useState(false)
  const deny = async ({ reason, description }) => {
    await post(
      `${employerPrefix("Doctor", props.doctorId)}/signing_signature_requests/${
        props.signatureRequestId
      }/denials.json`,
      {
        reason,
        description,
      }
    )
    redirectToDoctorsDashboard(props.doctorId)
  }
  return (
    <>
      <Button
        onClick={() => setShowModal(true)}
        type="button"
        className="btn-secondary"
      >
        Deny Order
      </Button>
      <DenyOrderModal
        show={showModal}
        close={() => setShowModal(false)}
        deny={deny}
        denialReasons={props.denialReasons}
      />
    </>
  )
}
