import React, { ReactNode } from "react"
import { Field } from "formik"
import MentionTextArea from "components/MentionTextArea"
import { unique } from "utilities/array"
import { capitalize } from "utilities/string"

type Props = {
  name: string
  fetch(query: string): Promise<any[]>
  alert?: ReactNode
  placeholder?: string
  autoFocus?: boolean
  disabled?: boolean
  onKeyDown?: (event) => Promise<void>
  renderSuggestion?: (
    suggestion,
    search: string,
    highlightedDisplay: React.ReactNode,
    index: number,
    focused: boolean
  ) => React.ReactNode
}

const MentionTextAreaField = ({
  name,
  alert,
  fetch,
  autoFocus,
  placeholder,
  disabled,
  onKeyDown,
  renderSuggestion,
}: Props) => {
  const mentionFieldName = `${name}MentionIds`
  const rawFieldName = `raw${capitalize(name)}`

  return (
    <Field name={name}>
      {({ field, form }) => {
        return (
          <>
            <MentionTextArea
              fetch={fetch}
              value={form.values[rawFieldName]}
              alert={alert}
              autoFocus={autoFocus}
              placeholder={placeholder}
              disabled={disabled}
              onKeyDown={onKeyDown}
              renderSuggestion={renderSuggestion}
              onChange={(event, newValue, newPlainTextValue, mentions) => {
                form.setFieldValue(field.name, newPlainTextValue)
                const mentionsValue = unique(
                  mentions.map((mention) => mention.id)
                )
                form.setFieldValue(mentionFieldName, mentionsValue)
                form.setFieldValue(rawFieldName, newValue)
              }}
            />
            <input type="hidden" name={mentionFieldName} />
            <input type="hidden" name={rawFieldName} />
          </>
        )
      }}
    </Field>
  )
}

export default MentionTextAreaField
