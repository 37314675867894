import React, { useEffect } from "react"
import CreateOrder from "./CreateOrder"
import ResolveOrder from "./ResolveOrder"
import SignUpModal from "./SignUpModal"
import Link from "components/Link"
import TrackOrderStatus from "./TrackOrderStatus"
import * as styles from "./index.module.scss"
import { CheckCircle } from "@material-ui/icons"
import ContinueSigningUpButton from "./ContinueSigningUpButton"
import { EventCategory, trackEvent } from "utilities/tracking"
import { useDoctorContext } from "../../hooks/useDoctorContext"

interface Props {
  currentUserEmail: string
  headingTitle: string
  redirectToFacilitySignupPage: boolean
}

const FacilitySignup: React.FC<Props> = ({
  currentUserEmail,
  headingTitle,
  redirectToFacilitySignupPage,
}) => {
  const { doctor } = useDoctorContext()

  useEffect((): void => {
    if (redirectToFacilitySignupPage) {
      void trackEvent(
        EventCategory.ResupplySignUp,
        "promo_continue_seen_resupply"
      )
    }
  }, [redirectToFacilitySignupPage])

  const renderButton = () => {
    return redirectToFacilitySignupPage ? (
      <ContinueSigningUpButton doctorId={doctor.id} />
    ) : (
      <SignUpModal currentUserEmail={currentUserEmail} />
    )
  }

  const subheaderText = redirectToFacilitySignupPage
    ? "Finish setting up your Parachute Health account!"
    : "Now join the 370,000 users who trust Parachute Health for order management."

  return (
    <div className={styles.container}>
      <div className="d-flex align-items-center justify-content-center">
        <CheckCircle className={styles.icon} />
        <h2 className={styles.noRequestsHeader}>{headingTitle}</h2>
      </div>
      <h3 className={styles.subhead}>{subheaderText}</h3>
      <div className="d-md-none">{renderButton()}</div>
      <div className={styles.marketingContentContainer}>
        <TrackOrderStatus />
        <CreateOrder />
        <ResolveOrder />
      </div>
      {renderButton()}
      <div>
        <Link href="https://www.parachutehealth.com/learnmore" target="_blank">
          Learn More
        </Link>
      </div>
    </div>
  )
}

export default FacilitySignup
