export function hasTruthyProperty(object) {
  return Object.values(object).some(Boolean)
}

export function isEmpty(object) {
  return Object.values(object).length === 0
}

export function filterObjectBySuffix(object: object, suffix: string) {
  return Object.keys(object).reduce((result, key) => {
    if (key.endsWith(suffix)) {
      result[key] = object[key]
    }
    return result
  }, {})
}
