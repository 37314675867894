import React, { useState } from "react"
import classNames from "classnames"
import { Severity } from "sharedTypes"

type Props = {
  className?: string
  dropdown?: boolean
  badge?({ open }): React.ReactNode
  status?: Severity
  border?: "top"
  render?({ open, toggle }): void
  children?: React.ReactNode
  expanded?: boolean
}

function Card({
  className,
  status,
  badge,
  dropdown,
  border,
  render,
  expanded,
  children,
}: Props) {
  const [open, setOpen] = useState(expanded)
  const toggle = () => {
    setOpen(!open)
  }

  const cardClass = (status) => {
    const statusMap = {
      error: "danger",
    }
    if (statusMap[status]) {
      return `card-${statusMap[status]}`
    } else {
      return status || ""
    }
  }

  return (
    <div
      className={classNames(
        `card card-${cardClass(status)}`,
        border && `card-border-${border}`,
        className
      )}
    >
      {(dropdown || badge) && (
        <div
          data-testid="card-dropdown-toggle"
          className="card-dropdown"
          onClick={toggle}
        >
          {badge && <>{badge({ open })}</>}
          {dropdown && (
            <span
              className={classNames("arrow", { bottom: !open, top: open })}
            />
          )}
        </div>
      )}
      {render ? render({ open, toggle }) : children}
    </div>
  )
}

export default Card
