import React, { useState } from "react"
import { Form } from "components/form"
import Alert from "components/Alert"
import { CanopyButton } from "@parachutehealth/canopy-button"
import { CanopyTextInputField } from "@parachutehealth/canopy-text-input-field"
import CaptureAlert from "./CaptureAlert"
import Error from "./Error"
import { isEmailValid } from "utilities/email"
import { EMAIL_PLACEHOLDER } from "utilities/placeholder"
import { blurOnEnter } from "utilities/browser/event"

interface Props {
  emailCapture(params): Promise<void>
}

const CaptureForm: React.FC<Props> = ({ emailCapture }) => {
  const [isProcessing, setIsProcessing] = useState(false)

  const onSubmit = (values, { setStatus }) => {
    setIsProcessing(true) // Using custom isProcessing state, because Formik's build-in isSubmitting state sets to false as soon as the promise resolves, but it's a better UX to have the form appear to be submitting until the redirect occurs
    return emailCapture(values).catch(({ error, type }) => {
      setIsProcessing(false)
      setStatus({ error, type })
    })
  }

  const validate = ({ email }) => {
    const errors: {
      email?: string
    } = {}

    // Allow for submission of blank form
    if (email && !isEmailValid(email)) {
      errors.email =
        "Enter a valid email address, or skip by clearing out email to continue"
    }

    return errors
  }

  return (
    <>
      <h1 className="canopy-mbe-24x">
        Enter your email to receive order notifications!
      </h1>
      <Alert status="info" className="canopy-mbe-24x canopy-mx-4x">
        <strong>Heads up!</strong> Even if you already have a Parachute account,
        please re-enter your email to continue using Parachute.
      </Alert>
      <Form
        initialValues={{ email: "" }}
        onSubmit={onSubmit}
        validate={validate}
      >
        {({ errors, touched, handleChange, handleBlur, values }) => {
          const emailError = touched.email ? errors.email : undefined

          return (
            <>
              {errors && errors.error && errors.type && (
                <CaptureAlert error={errors.error} type={errors.type} />
              )}
              <CanopyTextInputField
                type="email"
                id="email"
                name="email"
                label="Email"
                feedbackMessage={emailError}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.email}
                className="canopy-mbe-12x"
                inputProps={{ autoFocus: true, onKeyUp: blurOnEnter }}
                placeholder={EMAIL_PLACEHOLDER}
                description="Please enter an email address to receive notifications"
              />
              <CanopyButton
                fullWidth
                type="submit"
                loading={isProcessing}
                loadingText="Continuing..."
              >
                Continue
              </CanopyButton>
              {errors && errors.error && errors.type && (
                <Error type={errors.type} />
              )}
            </>
          )
        }}
      </Form>
    </>
  )
}

export default CaptureForm
