// @team @destroythefax
import React from "react"
import InternalClinicalFacilityDashboardSubpage from "../ClinicalFacilityDashboard/components/InternalClinicalFacilityDashboardSubpage"
import { ClinicalFacility, FeatureFlagInput } from "sharedTypes"
import {
  getClinicalFacilityDashboardSentBackForReviewFocusTileResults,
  updateSidebarExpandedPreference,
} from "../ClinicalFacilityDashboard/api"
import { FeatureFlagProvider } from "components/FeatureFlagContext"
import {
  SidebarListDoctor,
  SidebarListFacility,
} from "components/ClinicalDashboard/sharedTypes"

type Props = {
  clinicalFacility: ClinicalFacility
  limit: number
  unreadEventCounts: object
  featureFlags: FeatureFlagInput
  sidebarInformation: {
    incomingOrdersEnabled: boolean
    isReviewer: boolean
    lastAccessedFacilityEmployerId?: string
    doctors?: SidebarListDoctor[]
    facilities?: SidebarListFacility[]
    lastAccessedDoctorEmployerId?: string
  }
  sidebarExpanded: boolean
}

function SentBackForReviewSubpage({
  clinicalFacility,
  limit,
  unreadEventCounts,
  featureFlags,
  sidebarInformation,
  sidebarExpanded,
}: Props) {
  return (
    <FeatureFlagProvider flags={featureFlags}>
      <InternalClinicalFacilityDashboardSubpage
        title="Sent back for review"
        subtitle="Submitted orders sent back for revision."
        clinicalFacility={clinicalFacility}
        limit={limit}
        unreadEventCounts={unreadEventCounts}
        getClinicalFacilityDashboardResults={
          getClinicalFacilityDashboardSentBackForReviewFocusTileResults
        }
        sidebarInformation={sidebarInformation}
        sidebarExpanded={sidebarExpanded}
        updateSidebarExpandedPreference={updateSidebarExpandedPreference}
      />
    </FeatureFlagProvider>
  )
}

export default SentBackForReviewSubpage
