import React from "react"

function Definitions() {
  return (
    <ol>
      <li>
        <h4>DEFINITIONS</h4>
        <ol>
          <li>
            <u>Defined Terms.</u>
            <span>
              {" "}
              For the purposes of this Agreement, the following terms shall have
              the meanings hereinafter specified:
            </span>
            <p>
              <strong>“Affiliate” </strong>
              <span>
                means, with respect to any entity, any other entity that,
                directly or indirectly, through one or more intermediaries,
                controls, is controlled by, or is under common control with,
                such entity. The term “control” means the possession, directly
                or indirectly, of the power to direct or cause the direction of
                the management and policies of an entity, whether through the
                ownership of voting securities, by contract, or otherwise.
              </span>
            </p>
            <p>
              <strong>“Authorized User”</strong>
              <span>
                {" "}
                means any of Facility’s current employees, consultants, or
                agents whom Facility authorizes to access and use the Platform
                pursuant to the terms and conditions of this Agreement;
              </span>
              &nbsp;<u>provided,</u>&nbsp;
              <u>however,</u>&nbsp;that any consultants’ or agents’ access and
              use of the Platform shall be limited to their provision of
              services to Facility.
            </p>
            <p>
              <strong>“Destructive Elements”</strong>
              <span>
                {" "}
                means computer code, programs, or programming devices that are
                intentionally designed to disrupt, modify, access, delete,
                damage, deactivate, disable, harm, or otherwise impede in any
                manner, including aesthetic disruptions or distortions, the
                operation of the Platform or any other associated software,
                firmware, hardware, computer system, or network (including
                “Trojan horses,” “viruses,” “worms,” “time bombs,” “time locks,”
                “devices,” “traps,” “access codes,” or “drop dead” or “trap
                door” devices).
              </span>
            </p>
            <p>
              <strong>“Facility Data”</strong>
              <span>
                {" "}
                means any data that Facility or its Authorized Users submit to
                the Platform, including any data concerning any orders.
              </span>
            </p>
            <p>
              <strong>“Facility’s Network”</strong>
              <span>
                {" "}
                means the Health Insurance Portability and Accountability Act of
                1996, 104 P.L. 191, Subtitle F, and regulations from time to
                time promulgated thereunder.
              </span>
            </p>
            <p>
              <strong>“HIPAA”</strong>
              <span>
                {" "}
                means the Health Insurance Portability and Accountability Act of
                1996, 104 P.L. 191, Subtitle F, and regulations from time to
                time promulgated thereunder.
              </span>
            </p>
            <p>
              <strong>“Improvements”</strong>
              <span>
                {" "}
                means any modifications, improvements, inventions, adaptations,
                enhancements, or translations relating to the Platform.
              </span>
            </p>
            <p>
              <strong>“Law”</strong>
              <span>
                {" "}
                means any statute, law, ordinance, regulation, rule, writ, code,
                order, treaty, decree, directive or other requirement of any
                foreign, federal, state or local government or political
                subdivision thereof, or any court or tribunal of competent
                jurisdiction.
              </span>
            </p>
            <p>
              <strong>“Person”</strong>
              <span>
                {" "}
                means any individual, corporation, partnership, limited
                liability company, governmental authority, association, trust,
                or other entity.
              </span>
            </p>
            <p>
              <strong>“Platform”</strong>
              <span>
                {" "}
                means the Company’s proprietary, cloud-based platform that
                assists in the electronic submission and processing of patient
                orders.
              </span>
            </p>
            <p>
              <strong>“Practitioner”</strong> means a licensed health care
              practitioner authorized to order for a patient.
            </p>
            <p>
              <strong>“Prohibited Content”</strong> means content that: (a) is
              illegal under applicable Law; (b) violates any third party’s
              copyrights, trademarks, patents, trade secrets and other
              intellectual property rights; (c) contains indecent or obscene
              material; (d) contains libelous, slanderous, or defamatory
              material, or material constituting an invasion of privacy or
              misappropriation of publicity rights; (e) promotes unlawful or
              illegal goods, services, or activities; (f) contains false,
              misleading, or deceptive statements, depictions, or sales
              practices; or (g) contains Destructive Elements.
            </p>
            <p>
              <strong>“Services”</strong>
              <span>
                {" "}
                means the hosting, management and operation of the Platform for
                remote electronic access and use by Facility and its Authorized
                Users.
              </span>
            </p>
            <ol>
              <li>
                <p>
                  <u>Interpretation;</u>&nbsp;
                  <u>Exhibits and Schedules.</u>
                  <span>
                    {" "}
                    The titles, headings and captions contained in this
                    Agreement are for reference purposes only and shall not
                    affect in any way the meaning or interpretation of this
                    Agreement. Unless otherwise indicated to the contrary herein
                    by the context or use thereof: (a) the words “hereof,”
                    “hereby,” “herein,” “hereto,” and “hereunder” and words of
                    similar import shall refer to this Agreement as a whole and
                    not to any particular Section or paragraph of this
                    Agreement; (b) the words “include,” “includes” and
                    “including” shall be deemed to be followed by the words
                    “without limitation”; (c) masculine gender shall also
                    include the feminine and neutral genders, and vice versa;
                    (d) words importing the singular shall also include the
                    plural, and vice versa; (e) references to a “Section,”
                    “Schedule” or “Exhibit” shall be to a Section of, or a
                    Schedule or Exhibit to this Agreement; (f) any agreement,
                    instrument or Law defined or referred to in this Agreement
                    shall mean such agreement, instrument or Law as from time to
                    time amended, supplemented or otherwise modified; (g) all
                    Exhibits and Schedules to this Agreement are hereby
                    incorporated in and made a part of this Agreement as if set
                    forth in full herein, and any capitalized terms used in such
                    Exhibits and Schedules and not otherwise defined therein
                    shall have the meaning set forth in this Agreement; (h)
                    “writing,” “written” and comparable terms refer to printing,
                    typing and other means of reproducing words (including
                    electronic media) in a visible form; (i) the sign “$” means
                    the lawful currency of the United States of America; (j) all
                    references to “days” mean calendar days and all references
                    to time mean Eastern Time in the United States of America,
                    in each case unless otherwise indicated; and (k) derivative
                    forms of defined terms will have correlative meanings. The
                    Parties acknowledge that each Party and its attorney has
                    reviewed and participated in the drafting of this Agreement
                    and that any rule of construction to the effect that any
                    ambiguities are to be resolved against the drafting Party,
                    or any similar rule operating against the drafter of an
                    agreement, shall not be applicable to the construction or
                    interpretation of this Agreement.
                  </span>
                </p>
              </li>
            </ol>
          </li>
        </ol>
      </li>
      <li>
        <h4>PROVISION OF SERVICES</h4>
        <ol>
          <li>
            <p>
              <strong>Services. </strong>
              Subject to the terms and conditions of this Agreement, during the
              Term, the Company shall provide the Services to Facility. The
              Company shall use commercially reasonable efforts to make the
              Platform accessible twenty four (24) hours per day, seven (7) days
              per week, 99% of the time as measured over the course of each
              quarter during the Term, except for: (a) scheduled downtime for
              routine maintenance; (b) required repairs; (c) any loss or
              interruption due to a Force Majeure Event; (d) any other
              circumstances beyond the Company’s reasonable control, include
              Facility’s or any Authorized User’s use of the Platform and the
              Services other than in compliance with the terms of this
              Agreement; and (e) any suspension or termination of Facility’s or
              any Authorized Users’ access to or use of the Platform as
              permitted by this Agreement.
            </p>
          </li>
          <li>
            <p>
              <strong>Modifications. </strong>
              The Company reserves the right, in its sole discretion, to make
              any changes to the Platform or the Services that it deems
              necessary or useful to (a) maintain or enhance (i) the quality or
              delivery of the Company’s services to its customers, including to
              improve the user experience, (ii) the competitive strength of or
              market for the Company’s services, or (iii) the cost efficiency or
              performance of the Services; or (b) to comply with applicable Law.
            </p>
          </li>
          <li>
            <p>
              <strong>Customer Support. </strong>
              Support will be available from 9:00 AM to 9:00 PM, Eastern Time,
              Monday through Friday, excluding U.S. national holidays. Live
              support submitted through the Platform will be provided during
              support hours only. The Company attempts to respond to support
              questions within one (1) business day, although it does not
              promise or guarantee any specific response time.
            </p>
          </li>
          <li>
            <p>
              <strong>Service Management. </strong>
              Each of the Company and Facility shall, throughout the Term,
              maintain within its organization a service manager to serve as
              such Party’s primary point of contact for day-to-day
              communications, consultation and decision-making regarding the
              Services. Each service manager shall be responsible for providing
              all day-to-day consents and approvals on behalf of such Party
              under this Agreement. Each of the Company and Facility shall
              ensure its service manager has the requisite organizational
              authority, skill, experience and other qualifications to perform
              in such capacity. If either the Company’s service manager or
              Facility’s service manager ceases to be employed by such Party or
              such Party otherwise wishes to replace its service manager, such
              Party shall promptly name a new service manager by written notice
              to the other Party.
            </p>
          </li>
          <li>
            <p>
              <strong>Cooperation. </strong>
              Facility acknowledges that the Company’s ability to implement and
              provide the Services is dependent on Facility’s providing the
              Company complete, accurate, up-to-date, and timely Facility Data
              and other information and materials necessary to provide the
              Services or otherwise required by the Company. Facility agrees to
              reasonably cooperate with and assist the Company, to provide to
              the Company the Facility Data and such other information and
              materials, and to cause Facility’s personnel (including Authorized
              Users) and third-party service providers to do the same.
            </p>
          </li>
        </ol>
      </li>
      <li>
        <h4>AUTHORIZED USERS</h4>
        <ol>
          <li>
            <p>
              <strong>Onboarding. </strong>Authorized Users must log into the
              Platform. During the initial registration, Authorized Users will
              be prompted to create an account, which includes a sign-in name
              <strong> (“Sign-In Name”), </strong>a password
              <strong> (“Password”), </strong>and perhaps certain additional
              information that will assist in authenticating the Authorized
              User’s identity when he or she logs-in in the future
              <strong> (“Unique Identifiers” </strong>and, together with the
              Sign-In Name and Password,
              <strong> Access Credentials”). </strong>When creating the account,
              Authorized Users must provide true, accurate, current, and
              complete information.
            </p>
          </li>
          <li>
            <p>
              <strong>Access Credentials. </strong>Facility is solely
              responsible for the confidentiality, security and use of
              Authorized Users’ Access Credentials, as well as for any
              communications entered through the Platform using such Access
              Credentials. Facility is solely responsible for maintaining the
              accounts registered to its organization, including adding or
              removing an Authorized User, deactivating a Password or Sign-In
              Name or changing any Unique Identifier. Notwithstanding the
              foregoing, the Company reserves the right to delete or change
              Authorized Users’ Access Credentials at any time and for any
              reason. If Facility becomes aware of any unauthorized use of
              Authorized Users’ Access Credentials or account, Facility agrees
              to promptly, and in no event later than within twenty-four (24)
              hours, notify the Company thereof. Facility is responsible for the
              acts and omissions of its Authorized Users and any other Person
              who accesses and uses or misuses the Platform using any of
              Facility’s or its Authorized Users’ Access Credentials. The
              Company will not be liable for any loss or damage caused by any
              unauthorized use of an Authorized User’s Access Credentials or
              account.
            </p>
          </li>
          <li>
            <p>
              <strong>Accuracy of Information. </strong>Facility attests to the
              accuracy of information entered into the Platform by its
              Authorized Users (including facility staff). Facility acknowledges
              and agrees that the Company does not provide recommendations
              related to the appropriateness of any order or plan of care.
              Parachute Health is required to transmit orders to the suppliers
              exactly as the information is presented by the ordering
              practitioner.
            </p>
          </li>
          <li>
            <p>
              <strong>Contact Information.</strong>
            </p>
            <ol type="a">
              <li>
                <p>
                  Upon request by the Company, Facility shall provide the
                  Company with the names and contact information of Authorized
                  Users (including Facility staff) at the applicable Facility
                  assisting Practitioners with entering orders on the Platform.
                  Facility acknowledges and agrees that the foregoing contact
                  information shall be accurate and correct.
                </p>
              </li>
              <li>
                <p>
                  Facility shall be responsible for notifying each such
                  Authorized User that (i) such Authorized User’s name and
                  contact information has been provided to the Company for use
                  in connection with the provision of Services hereunder, and
                  (ii) such Authorized User is required to comply with the terms
                  of this Agreement. Facility shall cause each Facility, as
                  applicable, to notify the Company in the event any Authorized
                  User objects to any of the foregoing provisions.
                </p>
              </li>
            </ol>
          </li>
        </ol>
      </li>
      <li>
        <h4>LIMITATIONS ON USE</h4>
        <ol>
          <li>
            <p>
              <strong>Restrictions on Use.</strong> Facility will not (and will
              not authorize or knowingly permit any third party to): (a) allow
              anyone other than Authorized Users to access and use the Platform;
              (b) reverse engineer, decompile, disassemble, decode or otherwise
              attempt to discern or obtain access to the source code or
              interface protocols of the Platform, in whole or in part; (c)
              modify, create derivative works of, adapt, or translate the
              Platform; (d) make any copies of the Platform; (e) rent, lease,
              resell, distribute, sublicense or otherwise make available the
              Platform to any Person; (f) remove, alter or obscure any
              proprietary markings or restrictive legends placed on the
              Platform; (g) develop a service or program having any functional
              attributes, visual expressions, or other features similar to those
              of the Platform; (h) introduce, upload, or transmit to or through
              the Platform any Prohibited Content; or (i) otherwise access or
              use the Platform in violation of any applicable Law or for any
              purpose not specifically permitted in this Agreement.
            </p>
            <p>
              <strong>Compliance.</strong> The Company has the right to monitor
              Facility’s compliance with this Agreement. If any such monitoring
              reveals that Facility (including any of its Authorized Users) is
              not using the Platform in compliance with this Agreement, then
              Facility will remedy any such non-compliance within five (5)
              business days of receiving notice from the Company, including, if
              applicable, through the payment of additional Fees.
            </p>
          </li>
        </ol>
      </li>
      <li>
        <h4>FACILITY RESPONSIBILITIES</h4>
        <ol>
          <li>
            <p>
              <strong>Facility Equipment.</strong> Facility shall be responsible
              for obtaining, maintaining and protecting the security of any
              information technology infrastructure and equipment and ancillary
              services necessary for it to connect to, access or otherwise use
              the Platform and Services, including computers, software,
              hardware, servers, databases, operating systems, networks, data
              communications lines and the like (collectively,
              <strong>“Facility Equipment”).</strong>
            </p>
          </li>
          <li>
            <p>
              <strong>Monitor Order Requests</strong> Facility represents that
              the information entered into the Platform by its Authorized Users
              shall be accurate. Facility is responsible for ensuring that all
              authorized Practitioners have sole and final responsibility for
              reviewing orders.
            </p>
          </li>
          <li>
            <p>
              <strong>Privacy and Security.</strong> Facility, including its
              Authorized Users (including facility staff), shall comply with all
              applicable privacy and security Laws.
            </p>
          </li>
          <li>
            <p>
              <strong>Feedback. </strong>
              <span>
                Facility agrees to provide both positive and negative feedback,
                comments, and suggestions
              </span>
              <strong> (Feedback) </strong>
              <span>
                with respect to the Platform and the Services, as currently
                conducted or as proposed to be conducted so that the Company may
                improve and optimize the Platform and the Services.
              </span>
            </p>
          </li>
          <li>
            <p>
              <strong>Emergency Information.</strong> Facility understands that
              emergency contact information for Facility’s patients may be
              entered into the Platform, and that Company may have to contact
              those emergency contacts for assistance with completing any
              orders. Facility represents that it has obtained necessary
              consents for this use of emergency information.
            </p>
            <p>
              Except as expressly set forth herein, Facility will pay and is
              responsible for all expenses incurred by it in connection with its
              activities under this Agreement.
            </p>
          </li>
        </ol>
      </li>
      <li>
        <h4>FEES AND PAYMENT</h4>
        <ol>
          <li>
            <p>
              <strong>Fees.</strong> The parties acknowledge and agree that the
              consideration given and received by the parties hereunder is
              non-monetary and Facility shall not be responsible for the payment
              of any fee to Parachute for the Services provided hereunder.
            </p>
          </li>
        </ol>
      </li>
      <li>
        <h4>TERM</h4>
        <ol>
          <li>
            <p>
              The initial term of this Agreement shall commence on the Effective
              Date and, unless earlier terminated in accordance with the terms
              of this Agreement, shall terminate on the one-year anniversary of
              the Effective Date (the
              <strong> “Initial Term”).</strong>
            </p>
          </li>
          <li>
            <p>
              Following the end of the Initial Term, unless earlier terminated
              in accordance with the terms of this Agreement, the term of this
              Agreement shall automatically renew for successive one-year
              periods (each, a<strong> “Renewal Term” </strong>and, collectively
              with the Initial Term, the
              <strong> “Term”) </strong>unless and until a Party provides
              written notice to the other Party no later than sixty (60) days
              prior to the end of the Initial Term or applicable Renewal Term,
              as the case may be, of its intent not to renew this Agreement.
            </p>
          </li>
        </ol>
      </li>
      <li>
        <h4>FACILITY DATA; USE OF DATA AND FEEDBACK</h4>
        <ol>
          <li>
            <p>
              <strong>Facility Data. </strong>
              <span>
                The Company will use the Facility Data only to provide the
                Services and only as permitted by this Agreement and the
                Business Associate Agreement attached hereto as{" "}
              </span>
              <u>Exhibit B.</u>
              <span>
                {" "}
                Facility shall have sole responsibility and liability for the
                accuracy, quality, and legality of the Facility Data.
              </span>
            </p>
          </li>
          <li>
            <p>
              <strong>Data Security. </strong>The Company shall employ
              commercially reasonable physical, administrative, and technical
              safeguards to secure the Facility Data on the Platform from
              unauthorized use or disclosure.
            </p>
          </li>
          <li>
            <p>
              <strong>Aggregated Data. </strong>The Company may monitor the
              performance and use of the Platform by all of the Company’s
              customers and create data based on such activities (the
              <strong> “Usage Data”), </strong>combine the Usage Data with other
              data (including Facility Data), and use such combined data in an
              aggregate and anonymous manner. Facility hereby agrees that the
              Company may collect, use, and publish such aggregated data for the
              purpose of creating aggregated and anonymized statistics regarding
              the Company’s customer base. Examples of the Company’s use of such
              aggregate data include, but are not limited to, statistics
              aggregated across all of the Company clients on metrics such as
              size of data sets, the number of users of the Platform, revenue,
              number of transactions, and growth rates.
            </p>
          </li>
          <li>
            <p>
              <strong>Use of Feedback. </strong>
              <span>
                Facility agrees that the Company shall be free to use,
                reproduce, disclose, and otherwise exploit any and all Feedback
                provided pursuant to
              </span>
              <u> Section 5.6 </u>
              <span>
                or otherwise, without compensation or attribution to Facility.
              </span>
            </p>
          </li>
        </ol>
      </li>
      <li>
        <h4>INTELLECTUAL PROPERTY</h4>
        <p>
          <span>
            All right, title, and interest in and to the Platform and the Usage
            Data, including all Improvements, and all intellectual property
            rights therein, shall be and remain the Company’s sole and exclusive
            property. Subject to
          </span>
          <u> Section 8.1 </u>
          <span>and</span>
          <u> Section 8.3, </u>
          <span>
            all right, title, and interest in and to Facility Data, and all
            intellectual property rights therein, shall be and remain Facility’s
            sole and exclusive property.
          </span>
        </p>
      </li>
      <li>
        <h4>CONFIDENTIALITY</h4>
        <ol>
          <li>
            <p>
              <strong>Confidential Information. </strong>In connection with this
              Agreement, each Party
              <strong> (“Disclosing Party”) </strong>
              <span>
                may disclose or make available Confidential Information to the
                other Party
              </span>
              <strong> (“Receiving Party”). </strong>
              <span>For the purposes of this Agreement, subject to</span>
              <u> Section 10.2, </u>
              <strong>“Confidential Information” </strong>
              <span>
                means any confidential or other proprietary information of the
                Disclosing Party and its Affiliates, including information
                relating to the Disclosing Party’s operations, financial
                information, forecasts, business, marketing and product
                development plans, customers, suppliers, strategies, know-how,
                properties and pricing, and other information with respect to
                which the Disclosing Party or its Affiliates has confidentiality
                obligations, in each case, whether disclosed orally, in writing
                or in any other manner. For the avoidance of doubt, (a) the
                Platform and any and all source code relating thereto, are
                Confidential Information of the Company, (b) Facility Data is
                Confidential Information of Facility, and (c) the terms and
                conditions of this Agreement are Confidential Information of
                each Party.
              </span>
            </p>
          </li>
          <li>
            <p>
              <strong>Exclusions </strong>Notwithstanding the foregoing in
              <u> Section 10.1, </u>
              <span>
                Confidential Information does not include information that: (a)
                is or becomes public knowledge without any action by, or
                involvement of, the Receiving Party; (b) is documented as being
                known to the Receiving Party free of any obligation of
                confidence prior to the time of disclosure; (c) is independently
                developed by the Receiving Party without use of Confidential
                Information of the Disclosing Party;
              </span>
              <u> provided </u>
              <span>
                that the Receiving Party substantiates such independent
                development with contemporaneous documents; or (d) is obtained
                by the Receiving Party without restrictions on use or disclosure
                from a third party
              </span>
            </p>
          </li>
          <li>
            <p>
              <strong>Protection. </strong>The Receiving Party will: (a) protect
              the confidentiality of the Disclosing Party’s Confidential
              Information using at a minimum the same degree of care that it
              uses with its own confidential and proprietary information of
              similar nature, but with no less than reasonable care; (b) not use
              any of the Disclosing Party’s Confidential Information for any
              purpose outside the scope of this Agreement; and (c) not disclose
              the Disclosing Party’s Confidential Information to any Person
              other than its employees, contractors, advisors, agents, and
              actual or potential investors or acquirers who are bound by
              obligations of confidentiality at least as restrictive as those
              set forth in this Agreement.
            </p>
          </li>
          <li>
            <p>
              <strong>Compelled Disclosure. </strong>
              <span>
                If the Receiving Party is legally compelled to disclose any of
                the Disclosing Party’s Confidential Information, the Receiving
                Party will provide the Disclosing Party prompt written notice of
                such requirement prior to making any such disclosure so that the
                Disclosing Party may seek a protective order or other
                appropriate remedy from the proper remedy and/or waive
                compliance with the terms of this
              </span>
              <u> Section 10. </u>
              <span>
                The Receiving Party agrees to reasonably cooperate with the
                Disclosing Party in seeking such order or other remedy. If such
                protective order or other remedy is not obtained or the
                Disclosing Party waives compliance with the provisions of this
              </span>
              <u> Section 10, </u>
              <span>
                the Receiving Party may furnish only that portion of the
                Confidential Information that it is advised by counsel is
                legally required to be disclosed, and will use its commercially
                reasonable efforts to obtain reliable assurances that
                confidential treatment will be accorded to such disclosed
                portion of the Confidential Information.
              </span>
            </p>
          </li>
        </ol>
      </li>
      <li>
        <h4>REPRESENTATIONS AND WARRANTIES; DISCLAIMER</h4>
        <ol>
          <li>
            <p>
              <strong>Mutual Representations and Warranties. </strong>Each Party
              represents and warrants to the other Party that: (a) it is duly
              organized, validly existing, and in good standing under its
              jurisdiction of organization and has the right to enter into this
              Agreement; (b) the execution, delivery, and performance of this
              Agreement and the consummation of the transactions contemplated
              hereby are within the corporate powers of such Party and have been
              duly authorized by all necessary corporate action on the part of
              such Party, and constitute a valid and binding agreement of such
              Party; and (c) it has the full power, authority, and right to
              perform its obligations and grant the rights it grants hereunder.
            </p>
          </li>
          <li>
            <p>
              <strong>Facility Representations and Warranties. </strong>Facility
              represents and warrants that neither Facility shall enter into any
              improper or unlawful financial relationships with any supplier.
            </p>
          </li>
          <li>
            <p>
              <strong>Disclaimer. </strong>EXCEPT AS EXPRESSLY SET FORTH IN
              <u>SECTION 12.1</u>
              <span>
                , THE SERVICES, THE PLATFORM, THEIR COMPONENTS, AND ANY OTHER
                MATERIALS PROVIDED HEREUNDER ARE PROVIDED “AS IS” AND “AS
                AVAILABLE,” AND NEITHER PARTY MAKES ANY WARRANTIES WITH RESPECT
                TO THE SAME OR OTHERWISE IN CONNECTION WITH THIS AGREEMENT AND
                HEREBY DISCLAIMS ANY AND ALL EXPRESS, IMPLIED, OR STATUTORY
                WARRANTIES, INCLUDING, WITHOUT LIMITATION, ANY WARRANTIES OF NO
                ­INFRINGEMENT, MERCHANTABILITY, FITNESS FOR A PARTICULAR
                PURPOSE, AVAILABILITY, ERROR-FREE OR UNINTERRUPTED OPERATION,
                AND ANY WARRANTIES ARISING FROM A COURSE OF DEALING, COURSE OE
                PERFORMANCE, OR USAGE OF TRADE. COMPANY MAKES NO WARRANTIES WITH
                RESPECT TO ANY PLAN OF CARE, OR ORDER MADE THROUGH THE PLATFORM.
                TO THE EXTENT THAT EITHER PARTY MAY NOT AS A MATTER OF
                APPLICABLE LAW DISCLAIM ANY IMPLIED WARRANTY, THE SCOPE AND
                DURATION OF SUCH WARRANTY WILL BE THE MINIMUM PERMITTED UNDER
                SUCH LAW.
              </span>
            </p>
          </li>
        </ol>
      </li>
      <li>
        <h4>LIMITATION OF LIABILITY</h4>
        <p>
          <span>EXCEPT IN CONNECTION WITH FACILITY’S BREACH OF</span>
          <u> SECTION 4.1 </u>
          <span>OR</span>
          <u> SECTION 11.2, </u>
          <span>
            OR FACILITY’S FAILURE TO PAY ANY AMOUNTS DUE AND OWING: (A) IN NO
            EVENT WILL EITHER PARTY BE LIABLE TO THE OTHER PARTY OR ANY OTHER
            PARTY FOR LOST REVENUES OR LOST PROFITS, OR ANY INCIDENTAL,
            INDIRECT, CONSEQUENTIAL, SPECIAL, EXEMPLARY, OR PUNITIVE DAMAGES OF
            ANY KIND ARISING FROM OR RELATING TO THIS AGREEMENT, REGARDLESS OF
            WHETHER SUCH PARTY WAS ADVISED, HAD OTHER REASON TO KNOW, OR IN FACT
            KNEW OF THE POSSIBILITY THEREOF; AND (B) EACH PARTY’S AGGREGATE
            LIABILITY FOR DIRECT DAMAGES UNDER THIS AGREEMENT WILL NOT EXCEED
            $10,000.
          </span>
        </p>
      </li>
      <li>
        <h4>INDEMNIFICATION</h4>
        <ol>
          <li>
            <p>
              <strong>Indemnification by Company.</strong>Subject to
              <u> Section 13.2 </u>
              <span>
                , the Company will indemnify, defend and hold harmless Facility
                and its officers, directors, managers, employees and agents from
                and against any and all losses, damages, liabilities, costs and
                expenses (including reasonable attorneys’ fees) (collectively,
              </span>
              <strong> “Losses”) </strong>incurred by Facility and its
              indemnitees in connection with any third-party action, suit,
              claim, or proceeding to the extent such Losses arise out of (i)
              any allegation that the use of the Platform in accordance with
              this Agreement infringes or misappropriates any third-party
              copyrights or trade secrets; or (ii) any grossly negligent acts,
              omissions or intentional acts of the Company.
            </p>
          </li>
          <li>
            <p>
              <strong>
                Exceptions to Company's Indemnification Obligations.{" "}
              </strong>
              The Company is not obligated to indemnify, defend or hold harmless
              hereunder Facility or any third party to the extent: (a) the claim
              arises from or is based upon (i) Facility’s or any of its
              Authorized Users’ use of the Platform not in accordance with the
              documentation or this Agreement; or (ii) any unauthorized
              modifications, alterations, or implementations of the Platform
              made by Facility or at Facility’s request (other than by the
              Company); (b) the claim arises from use of the Platform in
              combination with unauthorized modules, apparatus, hardware,
              software, or services not supplied or specified in writing by the
              Company; or (c) the claim arises from any use of the Platform for
              which they were not designed.
            </p>
          </li>
          <li>
            <p>
              <strong>Infringement Claims. </strong>In the event that the
              Company reasonably determines that the Platform is likely to be
              the subject of a claim of infringement or misappropriation of
              third-party rights, the Company shall have the right (but not the
              obligation), at its own expense and option, to: (a) procure for
              Facility the right to continue to use the Platform as set forth
              hereunder; (b) replace the infringing components of the Platform
              with other components with the equivalent functionality; or (c)
              modify the Platform so that it is non-infringing and functionally
              equivalent. If none of the foregoing options is commercially
              practicable, the Company may terminate this Agreement without
              further liability to Facility. This
              <u> Section 13.3, </u>
              <span>together with the indemnity provided under</span>
              <u> Section 13.1, </u>
              <span>
                states Facility’s sole and exclusive remedy, and the Company’s
                sole and exclusive liability, regarding infringement or
                misappropriation of any intellectual property rights of a third
                party.
              </span>
            </p>
          </li>
          <li>
            <p>
              <strong>Indemnification by Facility. </strong>Facility will
              defend, indemnify, and hold harmless the Company and its officers,
              directors, managers, and employees from and against any and all
              Losses incurred by the Company and its indemnitees in connection
              with any third-party action, suit, claim, or proceeding to the
              extent such Losses arise out of (a) Facility’s (or any of its
              Authorized Users’) breach or violation of any representation,
              warranty, covenant, agreement, obligation, or undertaking made by
              Facility or its Affiliates in this Agreement (including any
              schedule or exhibit thereto) or any other agreement, instrument or
              other document delivered by or on behalf of Facility in connection
              with this Agreement or any of the transactions contemplated
              thereby, (b) Facility’s (or any of its Authorized Users’)
              violations of HIPAA or other Laws related to the delivery of
              health care of health care services or the payment for health care
              or health care services, including any Laws relating to Medicare
              fraud and abuse, and (c) inaccurate information or documentation
              entered into the Portal by Practitioners or any of Facility’s
              staff.
            </p>
          </li>
        </ol>
      </li>
      <li>
        <h4>TERMINATION</h4>
        <ol>
          <li>
            <p>
              <strong>Termination. </strong>Either Party may terminate this
              Agreement, including all rights granted hereunder, upon thirty
              (30) days’ notice to the other Party (i) without cause, or (ii) if
              the other Party breaches a material term of this Agreement, and
              the breach is either incapable of cure or remains uncured at the
              expiration of such 30-day period. A Party may terminate this
              Agreement immediately, upon written notice to the other Party, if
              such other Party becomes the subject of a petition in bankruptcy
              or any other proceeding relating to insolvency, liquidation, or
              assignment for the benefit of creditors. The Company may also
              terminate this Agreement, upon ten (10) days’ written notice to
              Facility, if (i) Facility is formally charged with Medicare fraud,
              (ii) serious moral business issues arise with respect to Facility,
              or (iii) under the limited circumstances set forth in
              <u>Section 13.3.</u>
              <span>
                Facility may also terminate this Agreement, upon ten (10) days’
                written notice to the Company, if (i) the Company is formally
                charged with Medicare fraud, or (iii) serious moral business
                issues arise with respect to the Company.
              </span>
            </p>
          </li>
          <li>
            <p>
              <strong>Effect of Expiration or Termination </strong>Upon
              termination or expiration of this Agreement: (a) the Company will
              stop providing the Services, and Facility will stop all access to
              and use of the Platform; (b) all rights and obligations described
              in this Agreement shall terminate and cease to be in effect,
              except to the extent any provision therein is intended by its
              terms to survive following termination; and (c) subject to
              <u> Section 8.3, </u>each Party shall return to the other Party
              (or, at such other Party’s instruction, destroy and provide such
              other Party with written certification of the destruction of) all
              documents, computer files, and other materials containing any of
              such other Party’s Confidential Information that are in its
              possession or control.
            </p>
          </li>
          <li>
            <p>
              <strong>Survival. </strong>The following provisions will survive
              expiration or termination of this Agreement:
              <u> Section 1 </u>(“Definitions”),
              <u> Section 5.5 </u>(“Feedback”),
              <u> Section 6 </u>(“Fees and Payment”),
              <u> Section 8.3 </u>(“Aggregated Data”),
              <u> Section 8.4 </u>(“Use of Feedback”),
              <u> Section 9 </u>(“Intellectual Property”),
              <u> Section 10 </u>(“Confidentiality”),
              <u> Section 11.3 </u>(“Disclaimer”),
              <u> Section 12 </u>(“Limitation of Liability”),
              <u> Section 13 </u>(“Indemnification”),
              <u> Section 14.3 </u>(“Effect of Expiration or Termination”), this
              <u> Section 14.4 </u>(“Survival”), and
              <u> Section 15 </u>(“General Provisions”).
            </p>
          </li>
        </ol>
      </li>
      <li>
        <h4>GENERAL PROVISIONS</h4>
        <ol>
          <li>
            <p>
              <strong>Assignment. </strong>Neither Party may assign or otherwise
              transfer this Agreement, or any of its rights or obligations
              hereunder, without the prior written consent of the other Party;
              <u> provided </u>
              <span>,</span>
              <u> however </u>
              <span>
                , that the Company may, upon written notice to Facility and
                without the consent of Facility, assign or otherwise transfer
                this Agreement (a) to one of its Affiliates, or (b) in
                connection with a change of control transaction (whether by
                merger, consolidation, sale or exchange of equity interests,
                sale of all or substantially all assets, or otherwise);
              </span>
              <u> provided </u>
              <span>
                that, in the case of clause (b), the assignee shall agree in
                writing to be bound by the terms and conditions of this
                Agreement. Any assignment or other transfer in violation of this
              </span>
              <u> Section 16.1 </u>
              <span>
                will be null and void and a material breach of this Agreement.
                Subject to the foregoing, this Agreement will be binding upon
                and inure to the benefit of the Parties and their permitted
                successors and assigns.
              </span>
            </p>
          </li>
          <li>
            <p>
              <strong>Counterparts. </strong>This Agreement may be executed in
              counterparts (which may be exchanged by facsimile or PDF or other
              electronic transmission), each of which will be deemed an
              original, but all of which together will constitute the same
              agreement.
            </p>
          </li>
          <li>
            <p>
              <strong>Entire Agreement. </strong>This Agreement (including the
              Schedules and Exhibits attached hereto) constitutes the final and
              complete agreement between the Parties regarding the subject
              matter hereof, and supersedes any prior or contemporaneous
              communications, representations, or agreements between the
              Parties, whether oral or written, including, without limitation,
              any confidentiality or non-disclosure agreements.
            </p>
          </li>
          <li>
            <p>
              <strong>Exclusive Forum. </strong>The Parties hereby consent and
              agree to the exclusive jurisdiction of the state and federal
              courts located in the State of New York, Borough of Manhattan for
              all suits, actions, or proceedings directly or indirectly arising
              out of or relating to this Agreement, and waive any and all
              objections to such courts, including but not limited to,
              objections based on improper venue or inconvenient forum, and each
              Party hereby irrevocably submits to the exclusive jurisdiction of
              such courts in any suits, actions, or proceedings arising out of
              or relating to this Agreement.
            </p>
          </li>
          <li>
            <p>
              <strong>Force Majeure. </strong>Except for Facility’s obligations
              to pay any sums due hereunder, no Party shall be deemed to be in
              breach of this Agreement for any failure or delay in performance
              to the extent caused by reasons beyond its reasonable control,
              including, but not limited to, acts of God, earthquakes, strikes,
              or shortages of materials or resources.
            </p>
          </li>
          <li>
            <p>
              <strong>Governing Law. </strong>This Agreement shall be governed
              by and construed in accordance with the Laws of the State of New
              York, without regard for choice of law provisions thereof.
            </p>
          </li>
          <li>
            <p>
              <strong>Independent Contractors. </strong>The Parties are
              independent contractors. No Party shall be deemed to be an
              employee, agent, partner, joint venturer, or legal representative
              of the other for any purpose, and neither shall have any right,
              power, or authority to create any obligation or responsibility on
              behalf of the other.
            </p>
          </li>
          <li>
            <p>
              <strong>Non-Solicitation. </strong>Each Party recognizes that the
              employees of the other party, and such employees’ loyalty and
              service to that other Party, constitute a valuable asset of that
              other Party. Accordingly, each Party agrees not to canvass,
              solicit directly or indirectly, contract, or hire any employee,
              candidate for employment, or other personnel of the other Party
              during the Term and for six (6) months after any termination of
              this Agreement, or six (6) months following termination of
              employment of an employee or personnel with the other Party,
              whichever occurs first. For the avoidance of doubt, nothing herein
              shall preclude the hiring of any such person in response to
              general solicitations in newspapers, Internet job boards, and the
              like.
            </p>
          </li>
          <li>
            <p>
              <strong>Publicity. </strong>Facility hereby consents and agrees to
              Company’s use of Facility’s Customer web site names (including
              associated trademarks) in Company’s press releases, website, and
              marketing materials.
            </p>
          </li>
          <li>
            <p>
              <strong>Notices. </strong>All notices required under this
              Agreement (other than routine operational communications) must be
              in writing in one of the following forms. Notices shall be
              effective upon: (a) actual delivery to the other Party, if
              delivered in person, or by facsimile, or by e-mail (other than
              notices under
              <u> Section 14.1 </u>, which may not be made via e-mail), or by
              national overnight courier; or (b) five (5) business days after
              being mailed via US postal service, postage prepaid.
            </p>
          </li>
          <li>
            <p>
              <strong>Severability. </strong>If any provision of this Agreement
              is found invalid or unenforceable by a court of competent
              jurisdiction, that provision shall be amended to achieve as nearly
              as possible the same economic effect as the original provision,
              and the remainder of this Agreement shall remain in full force and
              effect. Any provision of this Agreement, which is unenforceable in
              any jurisdiction, shall be ineffective only as to that
              jurisdiction, and only to the extent of such unenforceability,
              without invalidating the remaining provisions hereof.
            </p>
          </li>
          <li>
            <p>
              <strong>Third-Party Beneficiaries. </strong>Except as set forth in
              <u> Section 13.1 </u>
              <span>and</span>
              <u> Section 13.4 </u>
              <span>
                , nothing in this Agreement is intended to or shall confer upon
                any other Person any right, benefit or remedy of any nature
                whatsoever under or by reason of this Agreement.
              </span>
            </p>
          </li>
          <li>
            <p>
              <strong>Waiver. </strong>No failure or delay by any Party in
              exercising any right or remedy under this Agreement shall operate
              or be deemed as a waiver of any such right or remedy.
            </p>
          </li>
          <li>
            <p>
              <strong>Further Assurances. </strong>Each Party agrees to do and
              perform all such further acts and things and will execute and
              deliver such other agreements, certificates, instruments and
              documents necessary or that the other Party may deem advisable in
              order to carry out the intent and accomplish the purposes of this
              Agreement and to evidence, perfect or otherwise confirm its rights
              hereunder.
            </p>
          </li>
          <li>
            <p>
              <strong>Arbitration. </strong>In the event of any controversy or
              claim arising out of or relating to this Agreement or the breach
              thereof, or a dispute as to the meaning of this Agreement or any
              of its terms
              <strong>(“Dispute”) </strong>, the Parties agree to submit any
              such Dispute to final and binding arbitration in accordance with
              the Commercial Arbitration Rules of the American Arbitration
              Association, provided that such arbitration shall be presided over
              by a single arbitrator. Judgment upon the arbitration award may be
              entered in any court having jurisdiction. All mediation or
              arbitration hearings shall be held in New York, New York, and all
              aspects of the same shall be treated as confidential. Decisions of
              the arbitrator shall be in writing. Notwithstanding the foregoing,
              any Party may apply to any court of competent jurisdiction for
              injunctive relief without breach of this arbitration provision.
            </p>
          </li>
          <li>
            <p>
              <strong>Regulatory Event </strong>
              <span>
                Notwithstanding anything to the contrary herein, if the Company
                determines in good faith, upon the advice of legal counsel, that
                a change to the Agreement is required to comply with applicable
                Law (a
              </span>
              <u>“Regulatory Event” </u>
              <span>
                ), then upon request by the Company, Facility and the Company
                shall cooperate in good faith to restructure the terms of this
                Agreement (including any exhibits or schedules thereto) in a
                manner acceptable to the Company so as to remediate the
                circumstances giving rise to such Regulatory Event.
              </span>
            </p>
          </li>
        </ol>
        <p>
          <i>[Remainder of Page Intentionally Left Blank]</i>
        </p>
      </li>
    </ol>
  )
}

export default Definitions
