import React from "react"
import { SigningSignatureRequest } from "../DoctorDashboard/components/OrdersList/SignatureRequestCard"
import { withCamelizedProps } from "utilities/withCamelizedProps"
import { Grid } from "@material-ui/core"
import { unsignedPdfUrl } from "./urls"
import { ThemeProvider } from "@material-ui/core/styles"
import { materialTheme } from "themes/theme"
import { Footer } from "./Footer"
import { DocumentationCard } from "./DocumentationCard"
import { PatientCard } from "./PatientCard"
import DocumentsBrowserCard from "../DoctorApproval/components/Cards/DocumentsBrowserCard"
import { ToastProvider } from "components/Toaster"
import { SelectOption } from "components/form/sharedTypes"
import { isMobile } from "react-device-detect"

type SigningSignatureRequestApprovalPageParams = {
  signingSignatureRequest: SigningSignatureRequest
  doctorId: string
  denialReasons: SelectOption[]
}

const SigningSignatureRequestApprovalPage = (
  props: SigningSignatureRequestApprovalPageParams
) => {
  const {
    patient,
    documentDisplayName,
    id: signatureRequestId,
    supplierSystemOrderId,
    document,
  } = props.signingSignatureRequest
  const downloadPath = unsignedPdfUrl(props.doctorId, signatureRequestId)

  if (document) {
    document.downloadUrl = downloadPath
  }

  return (
    <ThemeProvider theme={materialTheme}>
      <ToastProvider>
        <Grid container spacing={2}>
          <Grid item xs={isMobile ? 12 : 6}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <PatientCard patient={patient} />
              </Grid>
              <Grid item xs={12}>
                <DocumentationCard
                  documentDisplayName={documentDisplayName}
                  downloadPath={downloadPath}
                  supplierSystemOrderId={supplierSystemOrderId}
                />
              </Grid>
            </Grid>
          </Grid>
          {!isMobile && (
            <Grid item xs={6}>
              <DocumentsBrowserCard documents={[document]} />
            </Grid>
          )}
        </Grid>
        <Footer
          signatureRequestId={signatureRequestId}
          doctorId={props.doctorId}
          denialReasons={props.denialReasons}
        />
      </ToastProvider>
    </ThemeProvider>
  )
}

export default withCamelizedProps<SigningSignatureRequestApprovalPageParams>(
  SigningSignatureRequestApprovalPage
)
