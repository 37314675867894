import React from "react"
import classNames from "classnames"
import { Severity } from "./sharedTypes"

type Props = {
  notifications: string[]
  severity: Severity
}

function Notifications({ notifications, severity }: Props) {
  let notificationText = `Attention: ${notifications.join(" ")}`
  if (severity === Severity.Danger) {
    notificationText += " Click edit to add them."
  }

  return (
    <div className={classNames("well well-expand", `well-${severity}`)}>
      {notificationText}
    </div>
  )
}

export default Notifications
