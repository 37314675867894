import React, { useState } from "react"
import { highlight } from "utilities/select"
import { SelectedRecipient, SendBackRecipient } from "./sharedTypes"
import { Select } from "components/form"
import Suggestion from "components/form/Select/Suggestion"
import { EventCategory, trackEvent } from "utilities/tracking"
import { GroupedSendBackRecipients } from "applications/Workflow/containers/Review/components/SupplierSendBack/SupplierSendBackForm"
import { useClinicalFacilityContext } from "./useClinicalFacilityContext"

export type Props = {
  name: string
  defaultSendBackRecipient?: SendBackRecipient
  searchSendBackRecipients(
    term: string,
    clinicalFacilityId?: string
  ): Promise<SendBackRecipient[] | GroupedSendBackRecipients[]>
  placeholder: string
  focusOnCreate: boolean
  setSearchRecipientRecommendations: (recommendationIds: string[]) => void
  selectedRecipients: SelectedRecipient[]
}

const FollowableSelect = ({
  name,
  placeholder,
  focusOnCreate,
  searchSendBackRecipients,
  defaultSendBackRecipient,
  setSearchRecipientRecommendations,
  selectedRecipients,
}: Props) => {
  const [userHasClicked, setUserHasClicked] = useState(false)
  const [userHasTyped, setUserHasTyped] = useState(false)
  const { clinicalFacilityId } = useClinicalFacilityContext()

  const renderSuggestion = (followable, { query, isHighlighted }) => {
    return (
      <Suggestion key={followable.value} isHighlighted={isHighlighted}>
        <div className="basic">
          <span>{highlight(followable.label, query)}</span>
          <span className="icon" />
        </div>
      </Suggestion>
    )
  }

  const searchSendBackRecipientsWrapper = (term: string) => {
    return searchSendBackRecipients(term, clinicalFacilityId).then((result) => {
      const recommendations = result.find(
        (item) => item.label === "Recommended by Parachute"
      )

      const selectedRecipientIDs = selectedRecipients.map(
        (id) => id.destination
      )
      if (recommendations) {
        const groupedSendbackRecipients: GroupedSendBackRecipients = recommendations as GroupedSendBackRecipients

        const recommendationIds = groupedSendbackRecipients.options.map(
          (recipient) => recipient.value
        )
        setSearchRecipientRecommendations(recommendationIds)

        groupedSendbackRecipients.options = groupedSendbackRecipients.options.filter(
          (user) => !selectedRecipientIDs.includes(user.value)
        )

        const otherRecipients = result.find(
          (item) => item.label === "Other User(s)"
        ) as GroupedSendBackRecipients

        otherRecipients.options = otherRecipients.options.filter(
          (user) => !selectedRecipientIDs.includes(user.value)
        )
        return result
      } else {
        const sendBackRecipients = result as SendBackRecipient[]
        return sendBackRecipients.filter(
          (ele) => !selectedRecipientIDs.includes(ele["value"])
        )
      }
    })
  }

  return (
    <div
      onClick={(e) => {
        if (e && !userHasClicked) {
          setUserHasClicked(true)
          void trackEvent(EventCategory.PushbackUserSelection, "ClickSearch")
        }
      }}
    >
      <Select
        key={clinicalFacilityId}
        name={name}
        placeholder={placeholder}
        options={defaultSendBackRecipient ? [defaultSendBackRecipient] : []}
        fetchOptions={searchSendBackRecipientsWrapper}
        isSearchable
        minLength={0}
        autoFocus={focusOnCreate}
        isClearable
        openMenuOnFocus
        onKeyDown={(e) => {
          if (e && !userHasTyped) {
            setUserHasTyped(true)
            void trackEvent(EventCategory.PushbackUserSelection, "TypeInSearch")
          }
        }}
        renderEmpty={(query) => (
          <Suggestion small unselectable>
            <strong>No matches for "</strong>
            {query}
            <strong>"</strong>
          </Suggestion>
        )}
        renderOption={(option, { query, isSelected }) =>
          renderSuggestion(option, { query, isHighlighted: isSelected })
        }
      />
    </div>
  )
}

export default FollowableSelect
