// @ts-strict-ignore
import React from "react"
import { formatAddress } from "utilities/address"
import { FacilitySearchResult } from "../../sharedTypes"
import { pluralize } from "utilities/string"
import Suggestion from "components/form/Select/Suggestion"

type Props = {
  facilitySearchResult: FacilitySearchResult
  isHighlighted?: boolean
}

function FacilityOption({ facilitySearchResult, isHighlighted }: Props) {
  const { name, npi, usersCount } = facilitySearchResult
  const hasUsersInfo = typeof usersCount === "number"

  return (
    <Suggestion isHighlighted={isHighlighted}>
      <div className="bold">{name}</div>
      <div>{formatAddress(facilitySearchResult)}</div>
      <div>
        {npi && <span>NPI: {npi}</span>}
        {npi && hasUsersInfo && <span className="canopy-mx-4x">|</span>}
        {hasUsersInfo && (
          <>
            <div className="logo logo-sm v-align-text-bottom canopy-mie-2x">
              <div className="image"></div>
            </div>
            <span>
              {usersCount} {pluralize("user", usersCount)}
            </span>
          </>
        )}
      </div>
    </Suggestion>
  )
}

FacilityOption.defaultProps = {
  isHighlighted: false,
}

export default FacilityOption
