import React, { useEffect, useState } from "react"
import { navigate } from "utilities/navigation"
import { searchPatients } from "./api"
import { patientUrl, newOrderUrl } from "./urls"
import InternalPatientSelect from "./components/InternalPatientSelect"
import { ChooseSupplierModal } from "components/SupplierOrganizationChooseSupplierModal"
import {
  getSuppliers,
  Supplier,
  urlForCreatingOrderWithSupplier,
} from "components/SupplierOrganizationChooseSupplierModal/api"
import { MaterialThemeProvider } from "themes/theme"
import { SearchableFields } from "./components/./SearchPrompt"
import { EmployerType } from "utilities/url"
import { trackEvent } from "./utilities/analytics"

type Props = {
  features: {
    newPatientShortcut: boolean
  }
  searchableFields: SearchableFields
  supplierOrganizationId?: string
  debounceTimeInMilliseconds?: number
}
const PatientSelect = ({
  features,
  supplierOrganizationId,
  debounceTimeInMilliseconds,
  searchableFields,
}: Props) => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [suppliers, setSuppliers] = useState<Supplier[]>([])
  const isSupplierOrganization = !!supplierOrganizationId

  const employerType: EmployerType | undefined = isSupplierOrganization
    ? "SupplierOrganization"
    : undefined
  const employerId: string | undefined = isSupplierOrganization
    ? supplierOrganizationId
    : undefined

  useEffect(() => {
    supplierOrganizationId &&
      getSuppliers(supplierOrganizationId).then((sups) => setSuppliers(sups))
  }, [supplierOrganizationId])

  const newPatientShortcut = isSupplierOrganization
    ? features.newPatientShortcut && suppliers.length > 0
    : features.newPatientShortcut
  const openSupplierSelectModal =
    isSupplierOrganization &&
    features.newPatientShortcut &&
    suppliers.length > 1

  const onNewPatientSelect = (query: string) => {
    if (!isSupplierOrganization) return navigate(`${newOrderUrl()}${query}`)

    if (suppliers.length === 0) return
    if (suppliers.length === 1)
      return navigate(urlForCreatingOrderWithSupplier(suppliers[0].id))
    setIsModalOpen(true)
  }
  const searchPatientsWithAnalytics = (query: string) => {
    trackEvent("search-patients")
    return searchPatients(employerType, employerId, query)
  }

  return (
    <MaterialThemeProvider>
      <InternalPatientSelect
        searchPatients={searchPatientsWithAnalytics}
        onPatientSelect={(patientId: string) =>
          navigate(patientUrl(employerType, employerId, patientId))
        }
        onNewPatientSelect={(query: string) => onNewPatientSelect(query)}
        debounceTimeInMilliseconds={debounceTimeInMilliseconds}
        newPatientShortcut={newPatientShortcut}
        searchableFields={searchableFields}
        clearOnSelect={openSupplierSelectModal}
      />
      {isSupplierOrganization && (
        <ChooseSupplierModal
          suppliers={suppliers}
          show={isModalOpen}
          close={() => setIsModalOpen(false)}
        />
      )}
    </MaterialThemeProvider>
  )
}

export default PatientSelect
