// @ts-strict-ignore
export default class FileHandler {
  file: File
  lines: string[]

  constructor(file: File) {
    this.file = file
  }

  async getLines(): Promise<string[]> {
    if (this.lines) {
      return this.lines
    }

    const text = await this.file.text()
    return text.split("\n")
  }

  async firstLine(): Promise<string> {
    return (await this.getLines())[0]
  }

  size(): number {
    return this.file.size
  }

  async lineCount(): Promise<number> {
    return (await this.getLines()).length
  }
}
