import { Dispatch, SetStateAction, useCallback, useState } from "react"
import { removeReviewerEmployment } from "../api"
import { navigate } from "utilities/navigation"
import { handleError } from "utilities/error"
import { ReviewerEmployment } from "../sharedTypes"

const useRemoveEmployment = (): {
  setEmploymentIdToRemove: Dispatch<
    SetStateAction<ReviewerEmployment["id"] | undefined>
  >
  removeEmployment: () => void
  isRemoving: boolean
} => {
  const [employmentIdToRemove, setEmploymentIdToRemove] = useState<
    ReviewerEmployment["id"] | undefined
  >(undefined)
  const [isRemoving, setIsRemoving] = useState<boolean>(false)

  const removeEmployment = useCallback(async () => {
    if (!employmentIdToRemove) return

    setIsRemoving(true)
    removeReviewerEmployment(employmentIdToRemove)
      .then((response) => {
        const redirectUrl = response.data.redirectUrl
        navigate(redirectUrl)
      })
      .catch(handleError)
  }, [employmentIdToRemove])

  return {
    setEmploymentIdToRemove,
    removeEmployment,
    isRemoving,
  }
}

export default useRemoveEmployment
