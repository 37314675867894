// @ts-strict-ignore
import React, { useState } from "react"
import CreateAccountForm from "./CreateAccountForm"
import InviteReferralForm from "./InviteReferralForm"
import { withHashRouter } from "routers/HashRouter"
import { Redirect, Route } from "react-router-dom"
import { RouteComponentProps } from "react-router"
import VerifyEmailMemo from "components/VerifyEmailMemo"
import InviteReferralSuccess from "./InviteReferralSuccess"
import MethodSelector from "./MethodSelector"
import { Supplier } from "../../../shared/sharedTypes"
import { Option as SelectOption } from "components/form/Select"

type Props = {
  createUser(
    params,
    supplier: string,
    methodSelection: MethodSelection,
    salesRepEmail: string
  ): Promise<void>
  getSuppliers(term: string): Promise<Supplier[]>
  supplier?: Supplier
} & RouteComponentProps

export enum MethodSelection {
  Invite = "invite_referral",
  Create = "create_account",
}

enum Routes {
  Root = "/",
  CreateAccount = "/create-account",
  CreateAccountSuccess = "/create-account-success",
  InviteReferral = "/invite-referral",
  InviteReferralSuccess = "/invite-referral-success",
}

const InternalAccountCreation = ({
  createUser,
  getSuppliers,
  history,
  supplier,
}: Props) => {
  const [email, setEmail] = useState(null)
  const [salesRepEmail, setSalesRepEmail] = useState(null)
  const [supplierOption, setSupplierOption] = useState(
    supplier
      ? ({ label: supplier.name, value: supplier.externalId } as SelectOption)
      : null
  )
  return (
    <>
      <Route exact path={Routes.Root}>
        <MethodSelector
          onCreateAccountClick={() => {
            history.push({ pathname: Routes.CreateAccount })
          }}
          onInviteReferralClick={() => {
            history.push({ pathname: Routes.InviteReferral })
          }}
        />
      </Route>
      <Route exact path={Routes.InviteReferral}>
        <InviteReferralForm
          createUser={(params) => {
            const { supplierId, salesRepEmail, ...remainingParams } = params
            return createUser(
              remainingParams,
              supplierId,
              MethodSelection.Invite,
              salesRepEmail
            ).then(() => {
              setEmail(params.email)
              setSalesRepEmail(salesRepEmail)
              history.push({ pathname: Routes.InviteReferralSuccess })
            })
          }}
          getSuppliers={getSuppliers}
          supplier={supplierOption}
          salesRepEmail={salesRepEmail}
          onSupplierOptionChange={(supplierOption) =>
            setSupplierOption(supplierOption)
          }
        />
      </Route>
      <Route exact path={Routes.CreateAccount}>
        <CreateAccountForm
          createUser={(params) => {
            const { supplierId, salesRepEmail, ...remainingParams } = params
            return createUser(
              remainingParams,
              supplierId,
              MethodSelection.Create,
              salesRepEmail
            ).then(() => {
              setEmail(params.email)
              history.push({ pathname: Routes.CreateAccountSuccess })
            })
          }}
          getSuppliers={getSuppliers}
          supplier={supplier}
        />
      </Route>
      <Route exact path={Routes.CreateAccountSuccess}>
        {email ? (
          <VerifyEmailMemo email={email} />
        ) : (
          <Redirect to={Routes.Root} />
        )}
      </Route>
      <Route exact path={Routes.InviteReferralSuccess}>
        {email ? (
          <InviteReferralSuccess
            email={email}
            onInviteAnotherReferralClick={() => {
              history.push({ pathname: Routes.InviteReferral })
            }}
          />
        ) : (
          <Redirect to={Routes.Root} />
        )}
      </Route>
    </>
  )
}

export default withHashRouter(InternalAccountCreation)
