import React from "react"
import Select from "components/form/Select"
import { SendBackFormKeys, SendBackRecipient } from "./sharedTypes"
import LabelSubtext from "./LabelSubtext"

type Props = {
  search(term: string): Promise<SendBackRecipient[]>
}

const FollowUpOwnerSelector = ({ search }: Props) => {
  const label = (
    <>
      Follow-up Owner (Optional)
      <LabelSubtext>
        Who is responsible for following up with the referral on this order?
      </LabelSubtext>
    </>
  )

  return (
    <Select
      fetchOptions={search}
      label={label}
      placeholder="Add a user or team from your organization"
      name={SendBackFormKeys.followUpOwner}
      isClearable
      isSearchable
    />
  )
}

export default FollowUpOwnerSelector
