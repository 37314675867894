import React from "react"
import { Field } from "formik"

interface Header {
  key: string
  label: string
}

interface Column {
  name: string
  value: string
}

interface Row {
  name: string
  [key: string]: string | Column
}

interface Props {
  headers: Header[]
  rows: Row[]
}

function RadioTable({ headers, rows }: Props) {
  return (
    <div className="form-group">
      <table className="table table-striped">
        <thead>
          <tr>
            {headers.map((header) => (
              <th key={header.key}>{header.label}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {rows.map((row) => {
            return (
              <tr key={row.name}>
                {headers.map((header) => {
                  const column = row[header.key]
                  if (typeof column === "string") {
                    return <td key={column}>{column}</td>
                  }
                  return (
                    <td key={column.value}>
                      <Field name={column.name}>
                        {({ field, form }) => (
                          <input
                            type="radio"
                            name={column.name}
                            value={column.value}
                            onChange={() => {
                              if (column.name === "select_all") {
                                rows.forEach((row) => {
                                  const input = row[column.value] as Column
                                  if (input) {
                                    form.setFieldValue(input.name, column.value)
                                  }
                                })
                              } else {
                                form.setFieldValue(column.name, column.value)
                              }
                            }}
                            checked={column.value === field.value}
                          />
                        )}
                      </Field>
                    </td>
                  )
                })}
              </tr>
            )
          })}
        </tbody>
      </table>
    </div>
  )
}

export default RadioTable
