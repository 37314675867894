import React, { useState } from "react"
import {
  DocumentationOrderQueryParameters,
  CreationMethods,
} from "../fetchOrders"
import SelectFilter from "components/SelectFilter"

export const CreationMethodFilter: React.FC<{
  updateSearchParameters(
    updatedParameters: Partial<DocumentationOrderQueryParameters>
  ): void
}> = ({ updateSearchParameters }) => {
  const [statusFilter, setStatusFilter] = useState<CreationMethods[]>([])
  const onChange = async (values) => {
    updateSearchParameters({
      creationMethods: values.creationMethods,
    })
    setStatusFilter(values.creationMethods)
  }
  return (
    <SelectFilter
      singular={true}
      options={[
        { label: "API", value: CreationMethods.API },
        { label: "Upload", value: CreationMethods.UPLOAD },
      ]}
      initialValues={statusFilter}
      onChange={onChange}
      name="creationMethods"
      label="Creation Method"
    />
  )
}
