import React, { ReactNode, useRef } from "react"
import { TableCell, TableRow } from "@material-ui/core"
import { SvgIconComponent } from "@material-ui/icons"
import { makeStyles } from "@material-ui/core/styles"
import { useDrag, useDrop } from "react-dnd"
import * as styles from "./index.module.scss"

import { isNullOrUndefined } from "../../../../utilities/isNullOrUndefined"

const useStyles = makeStyles({
  tableCell: {
    width: 0,
    cursor: "move",
  },
})

type DragItem = {
  index: number
  id: string
  type: string
}

type DraggableTableRowProps = {
  id: string
  index: number
  onMove: (fromIndex: number, toIndex: number) => void
  onDrop: (fromIndex: number, toIndex: number) => void
  children?: ReactNode
  icon: SvgIconComponent
}

const DraggableTableRow: React.FC<DraggableTableRowProps> = ({
  id,
  index,
  onMove,
  onDrop,
  children,
  icon: IconComponent,
}: DraggableTableRowProps): JSX.Element => {
  const classes = useStyles()
  const rowRef = useRef<HTMLTableRowElement>(null)
  const handleRef = useRef<HTMLElement>(null)

  const [, drop] = useDrop({
    accept: "row",
    hover(item: DragItem, monitor) {
      if (!rowRef.current) {
        return
      }
      const fromIndex = item.index
      const toIndex = index

      if (fromIndex === toIndex) {
        return
      }

      const hoverBoundingRect = rowRef.current?.getBoundingClientRect()
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2
      const clientOffset = monitor.getClientOffset()
      if (isNullOrUndefined(clientOffset) || !clientOffset?.y) {
        return
      }

      const hoverClientY = clientOffset.y - hoverBoundingRect.top

      if (fromIndex < toIndex && hoverClientY < hoverMiddleY) {
        return
      }
      if (fromIndex > toIndex && hoverClientY > hoverMiddleY) {
        return
      }

      // the hover handler can update the table too fast;
      // see https://github.com/react-dnd/react-dnd/issues/361
      setTimeout(() => onMove(fromIndex, toIndex))

      item.index = toIndex
    },
    drop(item: DragItem) {
      const fromIndex = item.index
      const toIndex = index

      onDrop(fromIndex, toIndex)
    },
  })

  const [{ isDragging }, drag, preview] = useDrag({
    item: { type: "row", id, index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  })

  preview(rowRef)
  drop(rowRef)
  drag(handleRef)

  const opacity = isDragging ? 0 : 1

  return (
    <TableRow className={styles.draggableRow} style={{ opacity }} ref={rowRef}>
      <TableCell
        className={classes.tableCell}
        ref={handleRef}
        title="Click and hold to drag"
      >
        <IconComponent className="canopy-mie-4x" fontSize="medium" />
      </TableCell>
      {children}
    </TableRow>
  )
}

export default DraggableTableRow
