import { del, put } from "services/api"
import * as urls from "applications/Workflow/urls"
import { LineItemGroup } from "sharedTypes"

const removePackageConfiguration = (packageConfigurationId: string) =>
  del(`${urls.packageConfigurationUrl(packageConfigurationId)}`)

export const removeLineItemGroup = (
  lineItemGroup: LineItemGroup
): Promise<void> =>
  removePackageConfiguration(lineItemGroup.packageConfigurationId).then(
    () => undefined
  )

export const updateLineItemGroup = (
  { id }: LineItemGroup,
  params: { quantity: number }
): Promise<void> =>
  put(urls.updateLineItemGroupUrl(id), { dmeOrderLineItemGroup: params }).then(
    () => undefined
  )
