import React from "react"
import Select from "components/form/Select"

interface Props {
  isDisabled?: boolean
}

function SexSelect({ isDisabled }: Props) {
  return (
    <Select
      name="sex"
      label="Sex"
      placeholder="Select sex..."
      options={[
        { label: "Female", value: "female" },
        { label: "Male", value: "male" },
        { label: "Unspecified/Prefer not to say", value: "unknown" },
      ]}
      isDisabled={isDisabled}
    />
  )
}

export default SexSelect
