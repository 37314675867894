import Matomo from "services/matomo"

const DEFAULT_TRACKING_TIMEOUT = 10

export const trackEvent = (params: string[]): Promise<void> => {
  Matomo.push(["trackEvent", ...params])
  return new Promise((resolve) => {
    setTimeout(resolve, DEFAULT_TRACKING_TIMEOUT)
  })
}

export const trackPageView = (url?: string) => {
  if (url) {
    Matomo.push(["setCustomUrl", url])
  }
  Matomo.push(["trackPageView"])
  Matomo.push(["enableHeartBeatTimer"])
}
