import { del, get, post } from "../../services/api"
import { makeUrlProvider } from "./urls"

export const makeApi = (supplierId) => {
  const urls = makeUrlProvider(supplierId)

  return {
    updateAssignee: (id, params) => {
      return post(urls.assigneesUrl(id), {
        supplierCarrierAuthorization: params,
      }).then(({ data }) => data)
    },
    deleteAssignee: (id) => {
      return del(urls.assigneesUrl(id)).then(({ data }) => data)
    },
    getAuthorizations: (filters) => {
      return get(urls.authorizationsUrl, filters).then(({ data }) => data)
    },
    getAssignees: (term) => {
      return get(urls.getAssigneesUrl(), { term }).then(({ data }) => data)
    },
    getMembers: (term) => {
      return get(urls.searchMembersUrl, { term }).then(({ data }) =>
        data.results.map(({ id, text }) => ({
          label: text,
          value: id.toString(),
        }))
      )
    },
    getCarrierAuthorizationNumbers: (term) => {
      return get(urls.carrierAuthorizationNumbersUrl, {
        term,
      }).then(({ data }) =>
        data.results.map(({ id, text }) => ({
          label: text,
          value: id.toString(),
        }))
      )
    },
    updateStatus: (id, params) => {
      return post(urls.authorizationStatusUrl(id), {
        supplierCarrierAuthorization: params,
      }).then(({ data }) => data)
    },
    authorizationUrl: urls.authorizationUrl,
  }
}

export const fetchInitialDashboardData = ({ supplierId }) => {
  const urls = makeUrlProvider(supplierId)
  return get(urls.initialDashboardDataUrl)
    .then(({ data }) => data)
    .catch(({ response }) => Promise.reject(response.data))
}
