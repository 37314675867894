import { get } from "services/api"
import { EmployerType } from "utilities/url"
import { searchPatientsUrl } from "./urls"

export const searchPatients = (
  employerType: EmployerType | undefined,
  employerId: string | undefined,
  query: string
) =>
  get(searchPatientsUrl(employerType, employerId), { query: query }).then(
    ({ data }) => data.patients
  )
