// @ts-strict-ignore
import React from "react"
import { OrderStatus, EmployerType } from "sharedTypes"
import DataRow from "components/DataRow"
import { productsPackageConfigurationPath } from "applications/Workflow/routes"
import { Link } from "react-router-dom"

interface LineItemGroup {
  packageConfigurationId: string
  packageSupplier: { externalId: string }
}

interface RxDetailsOutput {
  label: string
  value: string
}

interface Props {
  rxDetailsOutputs: RxDetailsOutput[]
  lineItemGroup: LineItemGroup
  orderStatus: string
  employerType: string
  transferredOrderLocked: boolean
}

const PrescriptionDetails = ({
  rxDetailsOutputs,
  lineItemGroup,
  orderStatus,
  transferredOrderLocked,
  employerType,
}: Props) => {
  const showEdit = () => {
    switch (employerType) {
      case EmployerType.ClinicalFacility:
        return orderStatus === OrderStatus.FacilityInProgress
      case EmployerType.Supplier:
        return (
          (orderStatus === OrderStatus.SupplierReview ||
            orderStatus === OrderStatus.Accepted) &&
          !transferredOrderLocked
        )
      default:
        return false
    }
  }

  const list = (
    <>
      <ul className="list-dashed">
        {rxDetailsOutputs.map((output) => (
          <li key={output.label}>
            {output.label} - {output.value}
          </li>
        ))}
      </ul>
      {showEdit() && (
        <Link
          className="link"
          to={productsPackageConfigurationPath(
            lineItemGroup.packageSupplier.externalId,
            lineItemGroup.packageConfigurationId,
            true
          )}
        >
          Edit Prescription Details
        </Link>
      )}
    </>
  )

  return (
    <DataRow
      className="font-xs canopy-mbe-0"
      label="Prescription Details"
      value={list}
    />
  )
}

export default PrescriptionDetails
