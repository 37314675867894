// @team @destroythefax
import React, { Dispatch, SetStateAction, useState } from "react"
import { CanopyButton } from "@parachutehealth/canopy-button"
import { CanopyDialogModal } from "@parachutehealth/canopy-dialog-modal"
import OrderActionsDropdownButton from "applications/Workflow/containers/Navigation/components/OrderActionsDropdown/OrderActionsDropdownButton"

type Props = {
  unsnoozeDmeOrder: () => Promise<void>
  setOrderActionsDropdownOpen: Dispatch<SetStateAction<boolean>>
}

const UnsnoozeButton = ({
  unsnoozeDmeOrder,
  setOrderActionsDropdownOpen,
}: Props) => {
  const activatorElementRef = React.useRef<HTMLButtonElement>(null)
  const [open, setOpen] = useState(false)
  const onClose = () => {
    setOpen(false)
    setOrderActionsDropdownOpen(false)
  }
  const onClick = () => setOpen(true)

  const handleUnsnooze = () => {
    unsnoozeDmeOrder().then(() => {
      onClose()
    })
  }

  return (
    <>
      <OrderActionsDropdownButton
        iconName="clock-rotate-left"
        title="Unsnooze&nbsp;order"
        subtitle="Remove snooze status from order"
        onClick={onClick}
      />
      <CanopyDialogModal
        activatorElementRef={activatorElementRef}
        onClose={onClose}
        open={open}
        header="Unsnooze Order"
        primaryFooterButton={
          <CanopyButton onClick={handleUnsnooze}>Confirm</CanopyButton>
        }
        secondaryFooterButton={
          <CanopyButton onClick={onClose} variant="secondary">
            Cancel
          </CanopyButton>
        }
        size="small"
      >
        <div className="canopy-pbe-4x">
          Are you sure you want to unsnooze this order?
        </div>
      </CanopyDialogModal>
    </>
  )
}

export default UnsnoozeButton
