import { put, get, post, del } from "services/api"
import { Doctor } from "sharedTypes"
import {
  defaultDoctorUrl,
  defaultDoctorSearchUrl,
  defaultDoctorCreateUrl,
} from "./urls"

export function create(
  doctorId: string,
  params: { mobileNumber?: string; email?: string }
): Promise<Doctor[]> {
  const { mobileNumber, email } = params

  return post(defaultDoctorCreateUrl(), { doctorId, mobileNumber, email })
    .then((response) => response.data)
    .catch(({ response }) => Promise.reject(response.data.errors))
}

export function update(
  doctorId: string,
  params: { mobileNumber?: string; email?: string }
): Promise<Doctor[]> {
  const { mobileNumber, email } = params

  return put(defaultDoctorUrl(doctorId), { mobileNumber, email })
    .then((response) => response.data)
    .catch(({ response }) => Promise.reject(response.data.errors))
}

export function remove(doctorId: string): Promise<Doctor[]> {
  return del(defaultDoctorUrl(doctorId)).then((response) => response.data)
}

export function search(term: string): Promise<Doctor[]> {
  return get(defaultDoctorSearchUrl(), { search: term })
    .then((response) => response.data)
    .catch(({ response }) => Promise.reject(response.data.errors))
}
