import { useMemo } from "react"
import debounce from "awesome-debounce-promise"

const DEBOUNCE_IN_MILLISECONDS = 300

export function useDebounce(
  callback,
  debounceInMilliseconds: number = DEBOUNCE_IN_MILLISECONDS,
  leading: boolean = true,
  memoDependencies: any[] = []
) {
  return useMemo(
    () => debounce(callback, debounceInMilliseconds, { leading }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    memoDependencies
  )
}
