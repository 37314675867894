// @team @paypals
import {
  ApolloClient,
  createHttpLink,
  from,
  InMemoryCache,
} from "@apollo/client"
import { RetryLink } from "@apollo/client/link/retry"
import { onError } from "@apollo/client/link/error"
import { relayStylePagination } from "@apollo/client/utilities"
import fetch from "cross-fetch"
import { getEmployerId, employerInitial } from "utilities/url"
import { setContext } from "@apollo/client/link/context"
import * as Sentry from "@sentry/react"

const csrfToken =
  document.querySelector("meta[name=csrf-token]")?.getAttribute("content") || ""

const httpLink = createHttpLink({
  fetch,
  uri: "/api/internal/graphql",
  credentials: "same-origin",
  headers: {
    "X-CSRF-Token": csrfToken,
  },
})

// setup default logging to sentry for errors
const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.forEach(({ message, locations, path }) => {
      const scope = new Sentry.Scope()
      scope.setTag("path", path?.toString())
      scope.setExtra("location", locations)
      Sentry.captureException(`[GraphQL error]: ${message}`, () => scope)
    })

  if (networkError)
    Sentry.captureException(`[Network error]: ${networkError}`, {
      level: "warning",
    })
})

// setup retries for network errors
const retryLink = new RetryLink({
  delay: {
    initial: 300,
    max: Infinity,
    jitter: true,
  },
  attempts: {
    max: 5,
    retryIf: (error, _operation) => !!error,
  },
})

const contextLink = setContext((_, { headers }) => {
  return {
    headers: {
      ...headers,
      "Employer-Type-Shorthand": employerInitial(),
      "Employer-ID": getEmployerId(),
    },
  }
})

// apply employee type routing to links
const prefixedLinks = [retryLink, errorLink, httpLink].map(function (link) {
  return contextLink.concat(link)
})

const cache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        uspsZipCodes: relayStylePagination(),
        insuranceProducts: relayStylePagination(),
        payorDmeOrders: {
          keyArgs: ["memberId"],
          merge(existing = { nodes: [] }, incoming) {
            return {
              ...incoming,
              nodes: [...existing.nodes, ...incoming.nodes],
            }
          },
        },
        incomingOrders: relayStylePagination(),
        users: relayStylePagination(),
      },
    },
  },
})

export const apolloClient = new ApolloClient({
  cache,
  link: from(prefixedLinks),
  // allow all errors and partial data to be returned by default
  defaultOptions: {
    query: {
      errorPolicy: "all",
    },
    mutate: {
      errorPolicy: "all",
    },
  },
})
