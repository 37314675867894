import { capitalize } from "utilities/string"

const AddressOrder = ["billing", "emergency", "facility", null]

export const addressesByType = (a, b) => {
  return (
    AddressOrder.indexOf(a.addressType) - AddressOrder.indexOf(b.addressType)
  )
}

export function cityLine(address) {
  if (!address || !address.city) {
    return null
  }
  return `${address.city}, ${address.state} ${address.zip}`
}

export function formatAddress(address) {
  if (!address || !address.line1) {
    return null
  }
  let result = address.line1
  if (address.line2) {
    result += " " + address.line2
  }
  result += ` ${cityLine(address)}`
  return result
}

export function formatAddressType({ addressType }) {
  if (!addressType) {
    return ""
  }

  return addressType === "billing" ? "home/billing" : addressType
}

function addressTypePrefix(address) {
  if (!address.addressType) {
    return ""
  }

  return `${capitalize(formatAddressType(address))} - `
}

export function formatAddressWithType(address) {
  return `${addressTypePrefix(address)}${formatAddress(address)}`
}

export function abbreviatedAddressWithType(address) {
  return `${addressTypePrefix(address)}${address.line1}`
}

export function addressForFulfillment(addresses, fulfillment) {
  const address = addresses.find((a) => a.id === fulfillment.deliveryAddressId)
  if (fulfillment.isConsignmentCloset) {
    let addressType = fulfillment.consignmentCloset.name
    if (addressType.toLowerCase() !== "consignment") {
      addressType += " (Consignment)"
    }
    return { ...address, addressType }
  }
  return address
}

export const states: [string, string][] = [
  ["Alabama", "AL"],
  ["Alaska", "AK"],
  ["Arizona", "AZ"],
  ["Arkansas", "AR"],
  ["California", "CA"],
  ["Colorado", "CO"],
  ["Connecticut", "CT"],
  ["Delaware", "DE"],
  ["District of Columbia", "DC"],
  ["Florida", "FL"],
  ["Georgia", "GA"],
  ["Hawaii", "HI"],
  ["Idaho", "ID"],
  ["Illinois", "IL"],
  ["Indiana", "IN"],
  ["Iowa", "IA"],
  ["Kansas", "KS"],
  ["Kentucky", "KY"],
  ["Louisiana", "LA"],
  ["Maine", "ME"],
  ["Maryland", "MD"],
  ["Massachusetts", "MA"],
  ["Michigan", "MI"],
  ["Minnesota", "MN"],
  ["Mississippi", "MS"],
  ["Missouri", "MO"],
  ["Montana", "MT"],
  ["Nebraska", "NE"],
  ["Nevada", "NV"],
  ["New Hampshire", "NH"],
  ["New Jersey", "NJ"],
  ["New Mexico", "NM"],
  ["New York", "NY"],
  ["North Carolina", "NC"],
  ["North Dakota", "ND"],
  ["Ohio", "OH"],
  ["Oklahoma", "OK"],
  ["Oregon", "OR"],
  ["Pennsylvania", "PA"],
  ["Puerto Rico", "PR"],
  ["Rhode Island", "RI"],
  ["South Carolina", "SC"],
  ["South Dakota", "SD"],
  ["Tennessee", "TN"],
  ["Texas", "TX"],
  ["Utah", "UT"],
  ["Vermont", "VT"],
  ["Virginia", "VA"],
  ["Washington", "WA"],
  ["West Virginia", "WV"],
  ["Wisconsin", "WI"],
  ["Wyoming", "WY"],
]

export const stateAbbreviationToName = (
  stateAbbreviation: string
): string | undefined =>
  states.find(([, abbreviation]) => abbreviation === stateAbbreviation)?.[0]
