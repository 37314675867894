import React, { useCallback } from "react"
import * as styles from "./review-signature-requests.module.scss"
import { ReviewPath } from "../../../../routes"
import { useHistory } from "react-router-dom"
import { Button } from "components/form"
import { Severity } from "sharedTypes"
import SeverityIcon from "components/SeverityIcon"
import { useDoctorContext } from "../../../../hooks/useDoctorContext"

const ReviewSignatureRequests = () => {
  const { doctor } = useDoctorContext()
  const history = useHistory()
  const handleOnClick = useCallback(() => {
    history.push(ReviewPath(doctor.id))
  }, [history, doctor.id])

  return (
    <div className={styles.container}>
      <SeverityIcon className={styles.icon} type={Severity.Warning} />
      <span className={styles.message}>
        There are orders that need to be reviewed before they can be signed
      </span>
      <Button className="btn btn-brand" onClick={handleOnClick}>
        Review Requests
      </Button>
    </div>
  )
}

export default ReviewSignatureRequests
