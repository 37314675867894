import React, { useState } from "react"
import { CanopyTextInputField } from "@parachutehealth/canopy-text-input-field"
import { states } from "utilities/address"
import { CanopySelectField } from "@parachutehealth/canopy-select-field"
import { dataEntryLineContainer } from "../index.module.scss"
import { useCsrInboxState } from "../../../csrInboxStateContext"
import { useIsMutating, useMutation } from "@tanstack/react-query"
import { updatePatientAddress } from "./api"
import { CanopyTextInputTypeValues } from "@parachutehealth/canopy-text-input"
import { ResourceUpdateResponse } from "../api"

export type PatientAddress = {
  line1?: string
  line2?: string
  city?: string
  state?: string
  zip?: string
  phoneNumber?: string
}

const updatePatientAddressMutationKey = ["updatePatientAddress"]
const useUpdatePatientAddress = (fieldName: keyof PatientAddress) => {
  const csrInboxState = useCsrInboxState()
  return useMutation({
    mutationFn: (fieldValue: string) =>
      updatePatientAddress(
        csrInboxState.supplierOrganization.id,
        csrInboxState.id,
        { [fieldName]: fieldValue }
      ),
    mutationKey: updatePatientAddressMutationKey,
  })
}

const StateSelect = ({ value }: { value?: string }) => {
  const numberOfMutations = useIsMutating({
    mutationKey: updatePatientAddressMutationKey,
  })
  const mutateAddress = useUpdatePatientAddress("state")
  const stateOptions = states.map((state) => ({
    label: state[1],
    value: state[1],
    disabled: numberOfMutations > 0,
  }))
  const options = [
    { label: "Enter state", value: "item_not_selected", disabled: true },
    ...stateOptions,
  ]

  const defaultValue = stateOptions.some((o) => o.value === value)
    ? value
    : "item_not_selected"
  return (
    <CanopySelectField
      label="State"
      defaultValue={defaultValue}
      options={options}
      onChange={(e) => mutateAddress.mutateAsync(e.target.value)}
      disabled={mutateAddress.isLoading}
    />
  )
}

const isSuccess = (
  response: ResourceUpdateResponse
): response is { success: boolean } => "success" in response

const TextField = ({
  address,
  label,
  fieldName,
  placeholder,
  type,
}: {
  address: PatientAddress
  fieldName: keyof PatientAddress
  label: string
  placeholder: string
  type?: CanopyTextInputTypeValues
}) => {
  const numberOfMutations = useIsMutating({
    mutationKey: updatePatientAddressMutationKey,
  })

  const [error, setError] = useState<string | undefined>(undefined)
  const [value, setValue] = useState(address[fieldName])
  const mutateAddress = useUpdatePatientAddress(fieldName)

  const onBlur = async (e: React.FocusEvent<HTMLInputElement>) => {
    if (e.target.value !== value) {
      mutateAddress.mutateAsync(e.target.value).then((response) => {
        if (isSuccess(response)) {
          setError(undefined)
        } else {
          const errors = response.errors
          const error = errors[fieldName]

          setError(error ? error[0] : "Something went wrong")
        }
      })
      setValue(e.target.value)
    }
  }

  const val = address[fieldName]
  const formattedValue =
    fieldName === "phoneNumber" && val
      ? [val.slice(0, 3), val.slice(3, 6), val.slice(6)].join("-")
      : val

  return (
    <CanopyTextInputField
      label={label}
      defaultValue={formattedValue}
      onBlur={onBlur}
      feedbackMessage={error}
      placeholder={placeholder}
      readOnly={mutateAddress.isLoading || numberOfMutations > 0}
      type={type}
    />
  )
}

export const ContactInformation = ({
  address,
}: {
  address: PatientAddress
}) => {
  return (
    <>
      <h2 className="canopy-typography-heading-xlarge">Patient info</h2>
      <h3 className="canopy-typography-heading-large">Contact info</h3>
      <div className={dataEntryLineContainer}>
        <TextField
          address={address}
          label="Address - Line 1"
          fieldName="line1"
          placeholder="Enter address line 1"
        />
        <TextField
          address={address}
          label="Address - Line 2"
          placeholder="Enter address line 2"
          fieldName="line2"
        />
      </div>
      <div className={dataEntryLineContainer}>
        <TextField
          address={address}
          fieldName="city"
          label="City"
          placeholder="Enter city"
        />
        <div className={dataEntryLineContainer}>
          <StateSelect value={address.state} />
          <TextField
            address={address}
            fieldName="zip"
            label="Zip"
            placeholder="Enter zip"
          />
        </div>
      </div>
      <div className={dataEntryLineContainer}>
        <TextField
          address={address}
          fieldName="phoneNumber"
          label="Phone Number"
          placeholder="Enter phone number"
          type="tel"
        />
        <div></div>
      </div>
    </>
  )
}
