import React from "react"
import { ErrorType } from "../sharedTypes"

function Error({ type }) {
  if (type === ErrorType.Invalid) {
    return (
      <div className="color-danger font-xs text-center canopy-my-8x">
        Invalid email or phone number
      </div>
    )
  }
  return <></>
}

export default Error
