import React, { useContext } from "react"
import Checkbox from "components/form/Checkbox"
import { Form } from "components/form"
import Section from "components/Section"
import filterConfigs from "../../filterConfigs"
import tableColumnDefinitions, {
  TableColumnDefinition,
} from "../tableColumnDefinitions"
import { FilterType } from "../DashboardFilters/sharedTypes"
import {
  ColumnSettings,
  FilterSettings,
  SupplierOrganizationDashboardDmeOrder,
} from "../../sharedTypes"
import { ActionType, ViewContext } from "../../view/viewContext"
import * as api from "../../api"
import { handleError } from "../../../../utilities/error"
import { Divider } from "@material-ui/core"
import { Header } from "./header"
import * as styles from "./index.module.scss"
import SupplierOrganizationContext from "../../SupplierOrganizationContext"

const FilterConfigSection = () => (
  <Section
    className="canopy-mbe-8x canopy-mbs-8x canopy-mis-12x canopy-pis-0 canopy-mie-12x canopy-pie-0"
    title={<h4 className="bold">Filters</h4>}
    initialExpanded
  >
    {filterConfigs.map((filterConfig) => {
      let label
      switch (filterConfig.filterType) {
        case FilterType.Toggle:
          label = filterConfig.options.map((option) => option.label).join("/")
          break
        case FilterType.Select:
          label = filterConfig.label
          break
        case FilterType.DateRange:
          label = filterConfig.title
          break
      }
      return (
        <Checkbox
          key={`${filterConfig.name}Filter`}
          label={label}
          name={`filterSettings.${filterConfig.name}Filter`}
          type={Checkbox.Type.Basic}
          unwrapped
        />
      )
    })}
  </Section>
)

const ColumnConfigSection = ({
  configurableColumns,
}: {
  configurableColumns: TableColumnDefinition<
    SupplierOrganizationDashboardDmeOrder
  >[]
}) => (
  <Section
    className="canopy-mis-12x canopy-pis-0 canopy-mie-12x canopy-pie-0"
    title={<h4 className="bold">Columns</h4>}
    initialExpanded
  >
    {configurableColumns.map((col) => (
      <Checkbox
        key={`columnSettings.${col.attr}`}
        label={col.title}
        name={`columnSettings.${col.attr}`}
        type={Checkbox.Type.Basic}
        unwrapped
      />
    ))}
  </Section>
)

const SettingsEditor = (props: { closeSidebar: () => void }) => {
  const { state, dispatch } = useContext(ViewContext)
  const { supplierOrganizationId } = useContext(SupplierOrganizationContext)
  const currentView = state.currentView
  const onChange = (settings: {
    filterSettings: FilterSettings
    columnSettings: ColumnSettings
  }) => {
    dispatch({ type: ActionType.UPDATE_SETTINGS, data: settings })
    return api
      .updateDashboardView(
        supplierOrganizationId,
        state.currentView.externalId,
        settings
      )
      .catch(handleError)
  }
  const configurableColumns = tableColumnDefinitions.filter(
    (col) => !col.alwaysVisible
  )
  const removeView = () =>
    dispatch({
      type: ActionType.DESTROY_VIEW,
      data: {},
    })
  return (
    <div className={styles.container}>
      <Header
        view={currentView}
        closeSidebar={props.closeSidebar}
        isDefaultView={currentView.isDefault}
        removeView={removeView}
      />
      <Divider />
      <div className={styles.content}>
        <Form
          onSubmit={onChange}
          initialValues={{
            filterSettings: currentView.filterSettings,
            columnSettings: currentView.columnSettings,
          }}
          submitOnChange
        >
          <FilterConfigSection />
          <ColumnConfigSection configurableColumns={configurableColumns} />
        </Form>
      </div>
    </div>
  )
}
export default SettingsEditor
