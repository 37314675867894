import React from "react"
import Icon from "components/Icon"

function SaveChangesButton({ onClick }) {
  return (
    <a
      className="link link-understated font-xs canopy-mis-8x"
      onClick={onClick}
    >
      <Icon className="canopy-mie-2x" prefix="fas" type="save" />
      Save Changes
    </a>
  )
}

export default SaveChangesButton
