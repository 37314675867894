import React, { useState } from "react"
import { Button } from "components/form"
import * as styles from "./index.module.scss"
import classNames from "classnames"
import PushbackSignUpModal from "applications/Workflow/containers/Review/components/PushbackSignUpModal"

const SignUpButton = () => {
  const [showModal, setShowModal] = useState(false)

  const openModal = (e) => {
    e.currentTarget.blur()
    setShowModal(true)
  }

  const closeModal = () => {
    setShowModal(false)
  }

  return (
    <>
      <Button
        className={classNames(
          "btn-brand-o",
          "canopy-mie-2x",
          styles.btnBlockSm
        )}
        onClick={openModal}
      >
        <div className={styles.borderButtonText}>Sign Up</div>
      </Button>

      {showModal && (
        <PushbackSignUpModal source="SignUpButton" dismiss={closeModal} />
      )}
    </>
  )
}

export default SignUpButton
