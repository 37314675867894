import React from "react"
import { Doctor } from "sharedTypes"
import DoctorGrid from "./DoctorGrid"
import DoctorSearch from "./DoctorSearch"
import AddPrompt from "./AddPrompt"

type Props = {
  affiliatedDoctors: Doctor[]
  updateAffiliatedDoctor(doctor: Doctor, params): Promise<void>
  searchDoctors(value: string): Promise<Doctor[]>
  selectDoctor(doctor: Doctor, params): Promise<void>
  removeAffiliatedDoctor(doctor: Doctor): Promise<void>
}

const InternalAffiliatedDoctorManagement = ({
  affiliatedDoctors,
  updateAffiliatedDoctor,
  searchDoctors,
  selectDoctor,
  removeAffiliatedDoctor,
}: Props) => {
  return (
    <div className="container">
      <h1>Manage Clinicians</h1>
      <DoctorSearch
        existingDoctors={affiliatedDoctors}
        searchDoctors={searchDoctors}
        selectDoctor={selectDoctor}
      />
      <h3>My Facility's Network</h3>
      {!!affiliatedDoctors.length ? (
        <DoctorGrid
          doctors={affiliatedDoctors}
          updateDoctor={updateAffiliatedDoctor}
          removeDoctor={removeAffiliatedDoctor}
        />
      ) : (
        <AddPrompt />
      )}
    </div>
  )
}

export default InternalAffiliatedDoctorManagement
