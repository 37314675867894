import React, { useContext } from "react"
import { DmeOrder, Document } from "sharedTypes"
import DownloadLink from "components/DownloadLink"
import WorkflowPageContext from "context/WorkflowPage"
import { SidebarTab } from "applications/Workflow/containers/Navigation/sharedTypes"
import {
  canopyColorInteractiveLinkDefault,
  canopyColorTextSecondary,
} from "@parachutehealth/canopy-tokens-color"
import {
  canopyTypographyBodyMediumFontSize,
  canopyTypographyFontWeightBold,
} from "@parachutehealth/canopy-tokens-typography"

type Props = {
  dmeOrder: DmeOrder
  downloadableDocuments: Document[]
  requiresTwoStepDownload: boolean
}

const DocumentDownloads = ({
  dmeOrder,
  downloadableDocuments,
  requiresTwoStepDownload,
}: Props) => {
  const workflowPageContext = useContext(WorkflowPageContext)
  const orderId = dmeOrder.id
  if (downloadableDocuments.length === 0) {
    return (
      <div className="alert alert-warning alert-rounded alert-borderless">
        There are no documents available to download at this time.
      </div>
    )
  }
  const openDocumentsTab = () => {
    workflowPageContext.setSidebarTab(SidebarTab.Documents)
  }
  return (
    <>
      <p
        style={{
          color: canopyColorTextSecondary,
          fontSize: canopyTypographyBodyMediumFontSize,
        }}
      >
        Use these links to download unsigned documents. Upload, fax, or download
        signed documents from the{" "}
        <button
          style={{
            background: "none",
            border: "none",
            padding: "0px",
            color: canopyColorInteractiveLinkDefault,
            fontWeight: canopyTypographyFontWeightBold,
          }}
          onClick={openDocumentsTab}
        >
          documents panel.
        </button>
      </p>
      <ul className="list-unstyled canopy-mbe-0">
        {downloadableDocuments.map(
          ({ key, title, dmeOrderDocumentationRequirementId }) => (
            <li key={key}>
              <DownloadLink
                orderId={orderId}
                dmeOrderDocumentationRequirementId={
                  dmeOrderDocumentationRequirementId
                }
                documentKey={key}
                label={
                  title !== "Order Summary" ? `${title} (unsigned)` : title
                }
                className="link"
                requiresTwoStepDownload={requiresTwoStepDownload}
              />
            </li>
          )
        )}
      </ul>
    </>
  )
}

export default DocumentDownloads
