import React from "react"
import TruncatedText from "components/TruncatedText"
import * as styles from "../index.module.scss"

interface Props {
  title: string
}

const CardTitle: React.FC<Props> = ({ title }) => (
  <div data-testid="supplier-card-title">
    <TruncatedText className={styles.title} text={title} />
  </div>
)

export default CardTitle
