import React from "react"

export const ErrorContext = React.createContext({
  displayErrors: false,
  setDisplayErrors: (_value: boolean) => {},
})

export const useErrorContext = () => React.useContext(ErrorContext)

export const ErrorContextProvider = ({ children }) => {
  const [displayErrors, setDisplayErrors] = React.useState(false)

  return (
    <ErrorContext.Provider value={{ displayErrors, setDisplayErrors }}>
      {children}
    </ErrorContext.Provider>
  )
}
