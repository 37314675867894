// @ts-strict-ignore
import React, { useContext } from "react"
import { CarrierAuthorization } from "sharedTypes"
import SortableTableHeaders from "components/SortableTableHeaders"
import { SortDirection } from "sharedTypes"
import Overlay from "components/Overlay"
import { timeAgoInWords } from "utilities/date"
import AssignedToEditor from "components/AssignedToEditor"
import StatusEditor from "./StatusEditor"
import { Record } from "components/SelectFilter"
import { fullName } from "utilities/person"
import { ApiContext } from "./apiContext"

type Props = {
  authorizations: CarrierAuthorization[]
  sortColumn?: string
  sortDirection?: SortDirection
  onSortChange(values: { [key: string]: string }): Promise<void>
  loading?: boolean
  statuses: Record[]
}

const Table = ({
  authorizations,
  sortColumn,
  sortDirection,
  onSortChange,
  loading,
  statuses,
}: Props) => {
  const tableColumns = [
    { title: "Auth #" },
    { title: "Member" },
    { title: "Member ID" },
    { title: "Enrollment Status" },
    { title: "HCPCS" },
    { title: "Internal Notes" },
    { title: "Assigned To" },
    { title: "Status", value: "status" },
    { title: "New/Changed", value: "updated_at" },
  ]
  const {
    deleteAssignee,
    updateAssignee,
    authorizationUrl,
    getAssignees,
  } = useContext(ApiContext)

  const renderHCPCS = (authorization) => {
    return authorization.carrierLineItemAuthorizations
      .map((item) => item.hcpcs)
      .join(", ")
  }

  const renderAssignedTo = (authorization) => {
    return (
      <div className="assignee">
        <AssignedToEditor
          id={authorization.id}
          assignee={authorization.assignee}
          canAssign={authorization.permissions.update}
          updateAssignee={updateAssignee}
          deleteAssignee={deleteAssignee}
          fetchAssignees={getAssignees}
        />
      </div>
    )
  }

  const renderStatus = (authorization) => {
    return (
      <StatusEditor
        id={authorization.id}
        initialStatus={authorization.status}
        statuses={statuses}
        canAssign={authorization.permissions.update}
      />
    )
  }

  const renderAuthorizationRow = (authorization) => {
    return (
      <tr key={authorization.id}>
        <td>
          <a href={authorizationUrl(authorization.id)} className="link">
            {authorization.parentAuthorizationNumber}
          </a>
        </td>
        <td>{fullName(authorization.carrierMembership)}</td>
        <td>{authorization.carrierMembership?.memberNumber}</td>
        <td>{authorization.carrierMembership?.enrollmentStatus}</td>
        <td>{renderHCPCS(authorization)}</td>
        <td className="truncate mw-200">{authorization.internalNotes}</td>
        <td>{renderAssignedTo(authorization)}</td>
        <td>{renderStatus(authorization)}</td>
        <td>{timeAgoInWords(authorization.updatedAt)}</td>
      </tr>
    )
  }

  return (
    <Overlay active={loading} showSpinner position="top">
      <div
        className="table-container"
        style={{ maxHeight: "calc(100vh - 300px)" }}
      >
        <table className="table table-striped table-hover table-sortable table-expanded table-dashboard table-flex">
          <SortableTableHeaders
            onSortChange={onSortChange}
            sortColumn={sortColumn}
            sortDirection={sortDirection}
            columns={tableColumns}
          />
          <tbody>{authorizations.map(renderAuthorizationRow)}</tbody>
        </table>
      </div>
    </Overlay>
  )
}

export default Table
