import React from "react"
import HelpOutlineOutlinedIcon from "@material-ui/icons/HelpOutlineOutlined"
import ToggleButton from "components/ToggleButton"
import Tooltip from "components/Tooltip"
import { trackClick } from "../analytics"
import { navigateToFacilitySupportPage } from "../api"

interface Props {
  launchKnowledgeBase: () => void
}

const KnowledgeBaseButton: React.FC<Props> = ({ launchKnowledgeBase }) => {
  const navigateToFacilitySupportPageOnClick =
    window.parachute?.employerType === "ClinicalFacility"

  const onClick = () =>
    trackClick("knowledge-base").then(() => {
      navigateToFacilitySupportPageOnClick
        ? navigateToFacilitySupportPage()
        : launchKnowledgeBase()
    })

  return (
    <Tooltip
      overlay="Get support"
      placement={Tooltip.Placement.Bottom}
      trigger={["hover"]}
    >
      <ToggleButton
        ariaLabel="get support"
        icon={<HelpOutlineOutlinedIcon />}
        onClick={onClick}
      />
    </Tooltip>
  )
}

export default KnowledgeBaseButton
