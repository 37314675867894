import { updatePasswordUrl, updateUserUrl, resendInvitationUrl } from "./urls"
import { post, put } from "services/api"
import { User } from "../shared/sharedTypes"

export const updatePassword = (
  userId: string,
  invitationToken: string,
  params
): Promise<{ redirectUrl: string }> => {
  return put(updatePasswordUrl(userId), {
    user: { ...params, invitationToken },
  })
    .then(({ data }) => data)
    .catch(({ response }) => Promise.reject(response.data))
}

export const updateUser = (
  userId: string,
  invitationToken: string,
  params
): Promise<{ redirectUrl: string }> => {
  return put(updateUserUrl(userId), { user: { ...params }, invitationToken })
    .then(({ data }) => data)
    .catch(({ response }) => Promise.reject(response.data))
}

export const resendInvitation = (
  userId: string,
  invitationToken: string
): Promise<{ user: User }> => {
  return post(resendInvitationUrl(userId), { invitationToken })
    .then(({ data }) => data)
    .catch(({ response }) => Promise.reject(response.data))
}
