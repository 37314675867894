// @ts-strict-ignore
import React, { useState, useEffect } from "react"
import { Form } from "components/form"
import MultiCharacterInput from "components/MultiCharacterInput"
import ErrorMessage from "./ErrorMessage"
import { Field } from "formik"
import { indexesOfInvalidCharacters } from "utilities/regex"
import { trackInvitationEvent } from "../utilities"
import classNames from "classnames"
import Cobranding from "components/Cobranding"
import Logo from "components/Logo"
import { SupplierInformation } from "sharedTypes"

const NON_ALPHANUM_REGEX = /[^a-z0-9]/i
const NON_NUM_REGEX = /[^0-9]/i
const ACCESS_CODE_LENGTH = 6
const SECURITY_CODE_LENGTH = 4

type Props = {
  initialAccessCode: string
  verify({
    accessCode,
    securityCode,
  }: {
    accessCode: string
    securityCode: string
  }): Promise<void>
  supplierInformation?: SupplierInformation
}

const validate = ({ securityCode, accessCode }) => {
  const errors: {
    accessCode?: string
    securityCode?: string
    accessCodeCharacters?: number[]
    securityCodeCharacters?: number[]
  } = {}

  const accessCodeMatches = indexesOfInvalidCharacters(
    accessCode,
    NON_ALPHANUM_REGEX,
    ACCESS_CODE_LENGTH
  )
  const securityCodeMatches = indexesOfInvalidCharacters(
    securityCode,
    NON_NUM_REGEX,
    SECURITY_CODE_LENGTH
  )

  if (accessCodeMatches.length > 0) {
    errors.accessCode = "Invalid code"
    errors.accessCodeCharacters = accessCodeMatches
  }

  if (securityCodeMatches.length > 0) {
    errors.securityCode = "Invalid code"
    errors.securityCodeCharacters = securityCodeMatches
  }

  return errors
}

const VerificationForm = ({
  initialAccessCode,
  verify,
  supplierInformation,
}: Props) => {
  const [baseError, setBaseError] = useState()

  useEffect(() => {
    trackInvitationEvent("verification-form-viewed")
  }, [])

  const onSubmit = (values, { setErrors, setSubmitting }) => {
    setSubmitting(true)
    setBaseError(null)
    return verify(values).catch(({ errors }) => {
      const { base, ...otherErrors } = errors

      setSubmitting(false)
      setBaseError(base)
      setErrors(otherErrors)

      if (base === "not_found") {
        trackInvitationEvent("verification-form-verification-failed-not-found")
      } else if (base === "no_longer_valid") {
        trackInvitationEvent("verification-form-verification-failed-expired")
      } else if (base === "request_canceled") {
        trackInvitationEvent("verification-form-verification-request-canceled")
      }
    })
  }

  const errorText = () => {
    switch (baseError) {
      case "not_found":
        return (
          <>
            Oops! We can't locate this order.
            <br />
            Check the order code and security code on your invitation, and try
            again.
          </>
        )
      case "no_longer_valid":
        return "Oh no! This link has expired for security purposes"
      case "request_canceled":
        return "The request associated with this access code is no longer valid."
      default:
        return baseError
    }
  }

  const getErrorText = (errors) => {
    if (errors.accessCode && errors.securityCode) {
      return "Please enter a valid access and security code"
    } else if (errors.accessCode) {
      return "Please enter a valid access code"
    } else if (errors.securityCode) {
      return "Please enter a valid security code"
    }
    return null
  }

  const SupplierInfo = () => {
    if (!supplierInformation) return null

    return supplierInformation.logoUrl ? (
      <div className="canopy-mbe-0">
        <Cobranding
          supplierName={supplierInformation.name}
          supplierLogoUrl={supplierInformation.logoUrl}
        />
      </div>
    ) : (
      <div className="cobranding d-inline-flex align-items-center canopy-mbe-0">
        <h3 className="canopy-mbe-0">{supplierInformation.name}</h3>
        <span className="cobranding-plus">+</span>
        <Logo />
      </div>
    )
  }

  return (
    <>
      <div className="onboarding-intro">
        <h1 className="text-center">Let's locate your order.</h1>
        <p>
          Parachute Health partners with trusted suppliers to make
          ordering&nbsp;
          <br className="d-none d-lg-block" />
          DME and services simpler, easier, and more efficient.
        </p>
      </div>
      <SupplierInfo />
      <Form
        initialValues={{
          accessCode: initialAccessCode || "",
          securityCode: "",
        }}
        onSubmit={onSubmit}
        validate={validate}
        validateOnChange={false}
        validateOnBlur={false}
        validateOnMount={false}
      >
        {({ errors, isSubmitting }) => (
          <>
            <div className="canopy-pbs-12x canopy-mbs-12x multi-character-container">
              <label>fixDME.com/</label>
              <Field name="accessCode">
                {({ field }) => (
                  <MultiCharacterInput
                    length={ACCESS_CODE_LENGTH}
                    errorIndexes={errors.accessCodeCharacters}
                    autoFocus={
                      (initialAccessCode || "").length !== ACCESS_CODE_LENGTH
                    }
                    {...field}
                  />
                )}
              </Field>
            </div>
            <div className="multi-character-container">
              <label>Security Code:</label>
              <Field name="securityCode">
                {({ field }) => (
                  <MultiCharacterInput
                    length={SECURITY_CODE_LENGTH}
                    errorIndexes={errors.securityCodeCharacters}
                    autoFocus={
                      (initialAccessCode || "").length === ACCESS_CODE_LENGTH
                    }
                    {...field}
                  />
                )}
              </Field>
            </div>
            {baseError && (
              <p className="text-center color-danger font-xs canopy-mbs-12x">
                {errorText()}
              </p>
            )}
            {getErrorText(errors) && (
              <ErrorMessage>{getErrorText(errors)}</ErrorMessage>
            )}

            <div
              className={classNames("text-center canopy-mbs-12x", {
                "canopy-pbs-12x": !baseError,
              })}
            >
              <button
                type="submit"
                disabled={isSubmitting}
                className="btn btn-brand canopy-px-12x"
              >
                Continue
              </button>
            </div>
          </>
        )}
      </Form>
    </>
  )
}

export default VerificationForm
