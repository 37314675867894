type Handler = {
  event: string
  callback(value): void
}

export default class Model {
  attributes
  options
  handlers: Handler[]

  constructor(attributes, options?) {
    this.attributes = attributes
    this.options = options
    this.handlers = []
  }
  get(key) {
    return this.attributes[key]
  }
  set(key, value) {
    this.attributes[key] = value
    this.handlers.forEach((handler) => {
      if (handler.event === `change:${key}`) {
        handler.callback(value)
      }
    })
  }
  on(event, callback) {
    this.handlers.push({ event, callback })
  }
  off(event, callback) {
    if (event) {
      this.handlers = this.handlers.filter((handler) => {
        if (!callback) {
          return handler.event !== event
        }
        return handler.event !== event && handler.callback !== callback
      })
    } else {
      this.handlers = []
    }
  }
  destroy() {
    this.handlers = []
  }
}
