// @ts-strict-ignore
import React from "react"
import {
  QuickListProductVariation,
  QuickListCategory,
  QuickListSubcategory,
  QuickListPackage,
} from "../sharedTypes"
import { findItemWithExternalId, sortValue } from "../utilities"
import { unique } from "utilities/array"
import classNames from "classnames"
import PackageProductVariationCheckboxes from "applications/Workflow/containers/QuickList/components/PackageProductVariationCheckboxes"

type Props = {
  selectedCategories: QuickListCategory[]
  filteredProductVariations: QuickListProductVariation[]
  packages: QuickListPackage[]
  subcategories: QuickListSubcategory[]
  formularyPriceEnabled: boolean
}

const GroupedProductVariationOptions = (props: Props) => {
  const {
    selectedCategories,
    filteredProductVariations,
    packages,
    subcategories,
    formularyPriceEnabled,
  } = props

  const headerClassNames = (additionalClassNames = undefined) =>
    classNames(
      "modal-banner-width",
      "canopy-my-0",
      "canopy-py-4x",
      additionalClassNames
    )

  const filteredPackages: QuickListPackage[] = unique(
    filteredProductVariations.map((pv) => pv.packageIds[0])
  ).map((id) => findItemWithExternalId(packages, id))

  const filteredSubcategories: QuickListSubcategory[] = unique(
    filteredPackages.reduce((acc, p) => acc.concat(p.subcategoryIds), [])
  ).map((id) => findItemWithExternalId(subcategories, id))

  const filteredCategories: QuickListCategory[] = unique(
    filteredSubcategories.map((p) => p.categoryId)
  )
    .map((id) => findItemWithExternalId(selectedCategories, id))
    .filter((c) => c.externalId)

  const renderPackage = (pkg: QuickListPackage) => (
    <React.Fragment key={pkg.externalId}>
      <h4 className={headerClassNames()}>{pkg.name}</h4>
      <PackageProductVariationCheckboxes
        pkg={pkg}
        filteredProductVariations={filteredProductVariations}
        formularyPriceEnabled={formularyPriceEnabled}
      />
    </React.Fragment>
  )

  const renderSubcategory = (subcategory: QuickListSubcategory) => {
    const subcategoryPackages = filteredPackages
      .filter((p) => p.subcategoryIds.includes(subcategory.externalId))
      .sort((p1, p2) => sortValue(p1.name, p2.name))
    return (
      <React.Fragment key={subcategory.externalId}>
        <h4 className={headerClassNames("bg-lightest-gray canopy-mbe-8x")}>
          {subcategory.name}
        </h4>
        {subcategoryPackages.map((p) => renderPackage(p))}
      </React.Fragment>
    )
  }

  const renderCategory = (category: QuickListCategory) => {
    const categorySubcategories = filteredSubcategories
      .filter((sc) => sc.categoryId === category.externalId)
      .sort((sc1, sc2) => sortValue(sc1.displayOrder, sc2.displayOrder))
    return (
      <React.Fragment key={category.externalId}>
        <h3 className={headerClassNames("bg-lighter-gray")}>{category.name}</h3>
        {categorySubcategories.map((sc) => renderSubcategory(sc))}
      </React.Fragment>
    )
  }

  return (
    <>
      {filteredCategories
        .sort((c1, c2) => sortValue(c1.displayOrder, c2.displayOrder))
        .map((c) => renderCategory(c))}
    </>
  )
}

export default GroupedProductVariationOptions
