import React from "react"

interface Props {
  order: {
    facilityName: string
    organicOrderSequence: number | undefined
  }
}
const FacilityCell: React.FC<Props> = ({ order }) => (
  <div>
    <span>{order.facilityName}</span>
    {order.organicOrderSequence === 1 && (
      <>
        <div className="nowrap pill pill-info pill-solid pill-basic">
          First Order
        </div>
      </>
    )}
  </div>
)

export default FacilityCell
