import React from "react"
import DataRow from "components/DataRow"
import { DmeOrderSnapshotField } from "sharedTypes"

type Props = {
  fields: DmeOrderSnapshotField[]
}

function SnapshotSectionContent({ fields }: Props) {
  return (
    <div>
      {fields.map((field, index) => {
        return (
          <div className="preline" key={`${field.label}${index}`}>
            <DataRow label={field.label} value={field.value} />
          </div>
        )
      })}
    </div>
  )
}

export default SnapshotSectionContent
