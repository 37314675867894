import {
  DmeOrderWarning,
  DmeOrderWarningSeverity,
  Employer,
  OrderStatus,
  Supplier,
  TitleStatus,
} from "sharedTypes"

export const getTitleStatus = (
  orderStatus: OrderStatus,
  warnings: DmeOrderWarning[],
  employer: Employer,
  supplier: Supplier | undefined
) => {
  const isSupplier = employer.employerType === "supplier"
  const isFacility = employer.employerType === "clinical_facility"
  const isTransferredToOtherSupplier =
    isSupplier && employer.employerId !== supplier?.externalId
  const anyErrors = !!warnings.filter(
    ({ severity }) => severity === DmeOrderWarningSeverity.Error
  ).length
  const anyWarnings = !!warnings.filter(
    ({ severity }) => severity === DmeOrderWarningSeverity.Warning
  ).length

  if (isTransferredToOtherSupplier) {
    return TitleStatus.TransferredSupplierView
  }

  if (orderStatus === OrderStatus.Accepted) {
    return TitleStatus.OrderAccepted
  }

  if (orderStatus === OrderStatus.Canceled) {
    return TitleStatus.OrderCanceled
  }
  if (isFacility) {
    if (orderStatus === OrderStatus.Transferred) {
      return TitleStatus.TransferredFacilityView
    }
    if (orderStatus === OrderStatus.SupplierReview) {
      return TitleStatus.SupplierReview
    } else if (anyErrors) {
      return TitleStatus.ActionRequired
    } else if (anyWarnings) {
      return TitleStatus.ActionRecommended
    }
    return TitleStatus.ReadyToSend
  } else {
    if (orderStatus === OrderStatus.FacilityInProgress) {
      return TitleStatus.FacilityWorkingOrder
    } else if (anyErrors || anyWarnings) {
      return TitleStatus.InformationNeeded
    }
    return TitleStatus.ReadyToAccept
  }
}
