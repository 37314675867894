import { useState } from "react"
import { QueryClient } from "@tanstack/react-query"

export const useStableQueryClient = (): QueryClient => {
  const [queryClient] = useState(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            staleTime: Infinity,
          },
        },
      })
  )

  return queryClient
}
