// @team @expanse

import { useState, useEffect } from "react"
import { DmeOrder } from "sharedTypes"

/**
 * See WF-26969
 *
 * This hook attempts to sidestep the issue detailed in WF-26969
 *
 * After initial render, the Activity component will sometimes be sent a dmeOrder object with a clinicalFacility object that is missing the signedAgreements property
 * This hook only updates the clinicalFacilityHasSignedAgreements state if the signedAgreements property is defined on the clinicalFacility object
 *
 * Once that bug is resolved this file can be deleted and the Activity component can be updated to use the signedAgreements property directly
 */

const useClinicalFacilityHasSignedAgreements = (
  dmeOrder: DmeOrder
): boolean => {
  const [
    clinicalFacilityHasSignedAgreements,
    setClinicalFacilityHasSignedAgreements,
  ] = useState(dmeOrder.clinicalFacility.signedAgreements)

  useEffect(() => {
    if (dmeOrder.clinicalFacility.signedAgreements !== undefined) {
      setClinicalFacilityHasSignedAgreements(
        dmeOrder.clinicalFacility.signedAgreements
      )
    }
  }, [dmeOrder.clinicalFacility])

  return clinicalFacilityHasSignedAgreements
}

export default useClinicalFacilityHasSignedAgreements
