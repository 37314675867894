import React from "react"
import TextField from "./TextField"
import { EMAIL_PLACEHOLDER } from "utilities/placeholder"
import { EMAIL_VALIDATION_MESSAGE, isEmailValid } from "utilities/email"
import { TextFieldType } from "./sharedTypes"

type Props = {
  className?: string
  id?: string
  name: string
  label?: string | React.ReactElement
  disabled?: boolean
  unwrapped?: boolean
  validateField?: boolean
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void
  placeholder?: string
  autoFocus?: boolean
  optional?: boolean
}

function EmailField({
  className,
  id,
  name,
  label,
  disabled,
  unwrapped,
  validateField,
  onChange,
  placeholder,
  autoFocus,
  optional,
}: Props) {
  const validate = (email: string) => {
    if (!isEmailValid(email, optional)) {
      return EMAIL_VALIDATION_MESSAGE
    }
  }
  return (
    <TextField
      className={className}
      id={id}
      name={name}
      label={label}
      placeholder={placeholder || EMAIL_PLACEHOLDER}
      autoFocus={autoFocus}
      type={TextFieldType.Email}
      disabled={disabled}
      validate={validateField ? validate : undefined}
      onChange={onChange}
      unwrapped={unwrapped}
    />
  )
}

export default EmailField
