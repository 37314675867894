import React, { useContext } from "react"
import { SignableRequirement } from "../sharedTypes"
import Alert, { Status } from "components/Alert"
import NoRequestedWarning from "./NoRequestedWarning"
import GlobalContext from "context/Global"
import { EmployerType } from "sharedTypes"
import {
  titleWithCategory,
  titleWithUsageContext,
} from "applications/Workflow/utilities/documentationRequirements"
import { useFeatureFlags } from "components/FeatureFlagContext"

const SignableRequirementsList = ({
  signableRequirements,
}: {
  signableRequirements: SignableRequirement[]
}) => {
  const getAlertDisplayValues = () => {
    const anySignatureReadyRequirements = !!signableRequirements.find(
      (r) => r.signatureReady
    )

    if (!anySignatureReadyRequirements) {
      return {
        status: "warning",
        headline: "No documents are ready to be signed",
      }
    }
    return {
      status: "info",
      headline: "The clinician will be asked to sign these documents:",
    }
  }

  const { isFeatureEnabled } = useFeatureFlags()
  const contextDrivenDocs = isFeatureEnabled("contextDrivenDocs")

  const renderSignableRequirement = (requirement: SignableRequirement) => (
    <li className="canopy-pbe-2x" key={requirement.title}>
      {contextDrivenDocs
        ? titleWithUsageContext(requirement)
        : titleWithCategory(requirement)}
      <span className="float-right color-dark-gray">
        {requirement.signatureReady ? "Ready for Signature" : "Not Ready"}
      </span>
    </li>
  )

  const alertDisplayValues = getAlertDisplayValues()
  const { currentEmployer } = useContext(GlobalContext)

  if (signableRequirements.length === 0) {
    if (currentEmployer.employerType !== EmployerType.Supplier) {
      return <NoRequestedWarning />
    }
    return (
      <Alert leftIcon status="warning">
        <strong>No documents will be signed</strong>
      </Alert>
    )
  }

  return (
    <Alert leftIcon status={alertDisplayValues.status as Status}>
      <strong>{alertDisplayValues.headline}</strong>
      <ul className="canopy-py-4x">
        {signableRequirements.map((r) => renderSignableRequirement(r))}
      </ul>
    </Alert>
  )
}

export default SignableRequirementsList
