import React from "react"
import Modal from "components/Modal"
import * as styles from "./reviewer-agreement-modal.module.scss"
import { Doctor } from "../../sharedTypes"

function ReviewerAgreementModal(props: {
  doctor: Doctor
  onAgree: () => void
  submitting: boolean
}) {
  return (
    <div className={styles.reviewerAgreementModalContainer}>
      <Modal.NonFixedContainer size="md" verticalCenter={true}>
        <Modal.Header>Reviewer Agreement for {props.doctor.name}</Modal.Header>
        <Modal.Body>
          <div className="color-dark-gray">
            <p className="canopy-typography-body-small">
              By signing in as a reviewer, you represent that this physician has
              given you access to this platform to assist the physician in
              providing treatment to the physician’s patients. You understand
              and agree that all information in the platform is protected by
              HIPAA and you agree that you will only access information related
              to patients as directed by the physician who granted you reviewer
              rights and for whom you are involved in treatment.
            </p>
            <p className="canopy-typography-body-small">
              You acknowledge that certain orders may now be delivered digitally
              and not via facsimile.
            </p>
            <p className="canopy-typography-body-small italic">
              You must accept this agreement to use the Reviewer Dashboard.
            </p>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-brand"
            onClick={props.onAgree}
            disabled={props.submitting}
          >
            Accept
          </button>
        </Modal.Footer>
      </Modal.NonFixedContainer>
    </div>
  )
}

export default ReviewerAgreementModal
