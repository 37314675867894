import React, { useState } from "react"
import { SupplierOrganizationDashboardBaseDmeOrder } from "../../sharedTypes"
import Modal from "components/Modal"
import { Form, Select } from "components/form"
import { searchSupplierFollowables, updateFollowUpOwner } from "../../api"
import { handleError } from "utilities/error"

type Props = {
  order: SupplierOrganizationDashboardBaseDmeOrder
}

const FollowUpOwnerEditor = ({ order }: Props) => {
  const [isOpen, setIsOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const [followUpOwner, setFollowUpOwner] = useState(order.followUpOwner)
  const status = followUpOwner?.label || "+ Add Owner"

  const openEditor = () => setIsOpen(true)
  const closeEditor = () => setIsOpen(false)

  const onSubmit = ({ followUpOwner }) => {
    setLoading(true)
    updateFollowUpOwner(order, followUpOwner)
      .then((response) => {
        setFollowUpOwner(response)
        setLoading(false)
      })
      .catch((response) => {
        setLoading(false)
        handleError(response)
      })
    closeEditor()
  }

  return (
    <Form initialValues={{ followUpOwner: order.followUpOwner?.value }}>
      {({ values }) => (
        <>
          <a onClick={openEditor} className="link link-teal position-relative">
            <div>{status}</div>
          </a>
          <Modal
            title="Follow-up Owner"
            show={isOpen}
            cancel={closeEditor}
            size="md"
          >
            <Modal.Body>
              <Select
                name="followUpOwner"
                label="Who is responsible for following up with the referral?"
                placeholder="Add a user or team from your organization"
                isClearable={false}
                fetchOptions={(term) => searchSupplierFollowables(order, term)}
                isDisabled={loading}
                isSearchable
              />
              <p>
                Note: Changing an existing follow-up owner will send an email
                notification to the user selected above.
              </p>
            </Modal.Body>
            <Modal.Footer>
              <a
                className="btn btn-link bold color-dark-gray"
                onClick={closeEditor}
              >
                Cancel
              </a>
              <button
                type="submit"
                className="btn btn-brand"
                disabled={loading}
                onClick={() => onSubmit(values)}
              >
                Save
              </button>
            </Modal.Footer>
          </Modal>
        </>
      )}
    </Form>
  )
}

export default FollowUpOwnerEditor
