import React from "react"
import { Form, NpiField, PhoneField, TextField } from "components/form"
import SalesRepFields, { initialSalesRepFieldValues } from "./SalesRepFields"
import FormButtons from "./FormButtons"
import { RequestSupplierFormFields } from "./sharedTypes"

export interface Props {
  onCancel: () => void
  onSubmit: (values: RequestSupplierFormFields) => void
}

const RequestSupplierForm: React.FC<Props> = ({ onCancel, onSubmit }) => {
  const initialValues: RequestSupplierFormFields = {
    ...initialSalesRepFieldValues,
    supplierName: "",
    supplierFaxNumber: "",
    supplierNpi: "",
    supplierAddress: "",
  }

  const validate = ({ supplierName }: RequestSupplierFormFields) => {
    const errors: Partial<RequestSupplierFormFields> = {}

    if (!supplierName) {
      errors.supplierName = "Enter supplier name"
    }
    return errors
  }

  return (
    <>
      <Form
        onSubmit={onSubmit}
        initialValues={initialValues}
        validate={validate}
      >
        {({ isSubmitting, isValid }) => (
          <>
            <h3>Supplier</h3>
            <TextField label="Supplier name" name="supplierName" />
            <NpiField
              label="Supplier NPI (Optional)"
              name="supplierNpi"
              validateField
              optional
            />
            <PhoneField
              label="Supplier fax number (Optional)"
              name="supplierFaxNumber"
              validateField
              optional
            />
            <TextField
              label="Supplier address (Optional)"
              name="supplierAddress"
            />
            <>
              <h3>Sales Rep</h3>
              <SalesRepFields />
            </>
            <FormButtons
              onCancel={onCancel}
              isSubmitting={isSubmitting}
              isValid={isValid}
              submitText="Request Supplier"
            />
          </>
        )}
      </Form>
    </>
  )
}

export default RequestSupplierForm
