import React, { useState } from "react"
import { Checkbox } from "components/form"
import { CanopyDialogModal } from "@parachutehealth/canopy-dialog-modal"
import { CanopyButton } from "@parachutehealth/canopy-button"
import Link from "components/Link"

const ReviewerAttestation = () => {
  const [showModal, setShowModal] = useState(false)
  const closeModal = () => {
    setShowModal(false)
  }
  return (
    <>
      <Checkbox
        primary
        name="reviewerAttestationCheckbox"
        label={
          <>
            <span>I agree to the </span>
            <Link
              onClick={(event) => {
                event.preventDefault()
                setShowModal(true)
              }}
            >
              Reviewer Agreement
            </Link>
            <span> for selected clinicians. </span>
          </>
        }
        type={Checkbox.Type.Basic}
        size={Checkbox.Size.Small}
      />
      <CanopyDialogModal
        header="Reviewer Agreement for Selected Clinicians"
        onClose={closeModal}
        open={showModal}
        primaryFooterButton={
          <CanopyButton onClick={closeModal}>Close</CanopyButton>
        }
      >
        <p className="canopy-typography-body-small">
          By signing in as a reviewer, you represent that this physician has
          given you access to this platform to assist the physician in providing
          treatment to the physician’s patients. You understand and agree that
          all information in the platform is protected by HIPAA and you agree
          that you will only access information related to patients as directed
          by the physician who granted you reviewer rights and for whom you are
          involved in treatment.
        </p>
        <p className="canopy-typography-body-small">
          Selected clinicians or associated admins will be notified that you've
          become a reviewer.
        </p>
        <p className="canopy-typography-body-small">
          You acknowledge that certain orders may now be delivered digitally and
          not via facsimile.
        </p>
        <p className="canopy-typography-body-small italic">
          You must accept this agreement to use the Reviewer Dashboard.
        </p>
      </CanopyDialogModal>
    </>
  )
}

export default ReviewerAttestation
