import React from "react"
import trackOrder from "track-order-status.png"
import MarketingContent from "./MarketingContent"

const TrackOrderStatus: React.FC = () => (
  <MarketingContent
    title="Track Order Status"
    description="Stay up to date with order status visibility and automatic order updates."
    imageSrc={trackOrder}
  />
)

export default TrackOrderStatus
