import { decamelizeKeys } from "humps"

export const toURLSearchParams = (
  obj: Record<string, any>
): URLSearchParams => {
  const params = new URLSearchParams()

  Object.keys(obj)
    .filter((key) => obj[key])
    .forEach((key) => {
      const value: any = obj[key]

      if (Array.isArray(value)) {
        value
          .filter((entry) => entry)
          .forEach((entry) => {
            params.append(key, entry)
          })
      } else {
        params.append(key, value)
      }
    })

  return params
}

/**
 * Given an object that will eventually be turned into query parameters,
 * transform its keys to match the Rack/Rails convention of array parameter names
 * ending with `[]`. Will only apply to properties whose value is an Array and doesn't
 * already follow this convention.
 */
export const transformArrayKeys = (
  obj: Record<string, any>
): Record<string, any> => {
  const transformedObject: Record<string, any> = {}

  Object.keys(obj).forEach((key) => {
    let transformedKey
    if (Array.isArray(obj[key]) && !key.endsWith("[]")) {
      transformedKey = `${key}[]`
    } else {
      transformedKey = key
    }
    transformedObject[transformedKey] = obj[key]
  })

  return transformedObject
}

/**
 * A composed function that does three separate transformations (probably) necessary for interacting with a Rails backend:
 * 1. Transforming top-level items that are arrays to have `foo[]`-style names
 * 2. Converting from camelCase to snake_case
 * 3. Producing a URLSearchParams object from the result key/value pairs.
 */
export const toRailsStyle = (obj: Record<string, any>): URLSearchParams =>
  toURLSearchParams(decamelizeKeys(transformArrayKeys(obj)))
