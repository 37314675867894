// @ts-strict-ignore
import React, { useEffect } from "react"
import Alert, { Status } from "components/Alert"
import { Link } from "react-router-dom"
import classNames from "classnames"
import { trackView, trackClick } from "./analytics"

type Props = {
  children?: React.ReactNode
  editable?: boolean
  severity: Status
  text?: string
  title?: string
  to?: string
  trackEvents: boolean
  warningType: string
}

const Warning = ({
  children,
  editable,
  severity,
  text,
  title,
  to,
  trackEvents,
  warningType,
}: Props) => {
  useEffect(() => {
    if (trackEvents) {
      trackView(warningType)
    }
  }, [warningType, trackEvents])
  const canUpdate = editable && !!to
  const onClick = () => {
    if (canUpdate && trackEvents) {
      trackClick(warningType)
    }
  }

  const errorStripe = (
    <Alert status={severity}>
      {title && (
        <div>
          <strong>{title}</strong>
        </div>
      )}
      <div className={classNames({ "canopy-pie-8x": canUpdate })}>{text}</div>
      {children}
      {canUpdate && <span className="arrow right" />}
    </Alert>
  )

  if (!canUpdate) {
    return errorStripe
  }
  return (
    <Link onClick={onClick} to={to}>
      {" "}
      {errorStripe}{" "}
    </Link>
  )
}

export default Warning
