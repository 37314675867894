import React from "react"
import { Doctor } from "sharedTypes"
import ReviewerIdentificationForm from "../SigningSignatureRequestsAccess/components/ReviewerIdentificationForm"
import { DoctorParams, ReviewerParams, invitationOptIn } from "./api"
import { navigate } from "utilities/navigation"
import { handleError } from "utilities/error"
import { parachuteUserLoginPath } from "./urls"

export type Props = {
  doctor: Doctor
  reviewerEmail: string
}

const ReviewerInvitationWelcomeForm: React.FC<Props> = ({
  doctor,
  reviewerEmail,
}) => {
  const handleLogin = (params: ReviewerParams) => {
    const doctorParams = { doctor: { id: doctor.doctorId } } as DoctorParams
    invitationOptIn({ ...params, ...doctorParams })
      .then(({ path }) => {
        navigate(path)
      })
      .catch(handleError)
  }

  const description = `Please enter your name and email to be added as
  a reviewer for ${doctor.firstName} ${doctor.lastName}, NPI ${doctor.npi}`

  return (
    <ReviewerIdentificationForm
      description={description}
      login={handleLogin}
      parachuteUserLoginPath={parachuteUserLoginPath(doctor.doctorId)}
      reviewerEmail={reviewerEmail}
    />
  )
}

export default ReviewerInvitationWelcomeForm
