import React from "react"
import RadioTable from "components/form/RadioTable"
import { ClinicalOrganizationsEmployment } from "sharedTypes"

interface Props {
  employments: ClinicalOrganizationsEmployment[]
}

enum Fields {
  Name = "name",
  FacilityType = "facility_type",
  NoAccess = "no_access",
  ViewOnly = "view_only",
  Standard = "standard",
  Admin = "admin",
}

function UserRoleRadioTable({ employments }: Props) {
  return (
    <RadioTable
      headers={[
        { key: Fields.Name, label: "Name" },
        { key: Fields.FacilityType, label: "Type" },
        { key: Fields.NoAccess, label: "No Access" },
        { key: Fields.ViewOnly, label: "View Only" },
        { key: Fields.Standard, label: "Standard" },
        { key: Fields.Admin, label: "Admin" },
      ]}
      rows={[
        {
          [Fields.Name]: "All Facilities",
          [Fields.FacilityType]: "--",
          [Fields.NoAccess]: {
            name: "select_all",
            value: "no_access",
          },
          [Fields.ViewOnly]: {
            name: "select_all",
            value: "view_only",
          },
          [Fields.Standard]: {
            name: "select_all",
            value: "standard",
          },
          [Fields.Admin]: {
            name: "select_all",
            value: "admin",
          },
        },
        ...employments.map((employment, index) => {
          const name = `employments[${index}].role`
          function checkbox(value) {
            return {
              name,
              value,
            }
          }
          return {
            [Fields.Name]: employment.employerName,
            [Fields.FacilityType]: employment.facilityType || "",
            [Fields.NoAccess]: checkbox(Fields.NoAccess),
            [Fields.ViewOnly]: checkbox(Fields.ViewOnly),
            [Fields.Standard]: checkbox(Fields.Standard),
            [Fields.Admin]: checkbox(Fields.Admin),
          }
        }),
      ]}
    />
  )
}

export default UserRoleRadioTable
