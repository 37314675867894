import { get, uploadDocumentToUrl } from "services/api"
import { createUploadUrl } from "applications/DocumentationOrders/routes"

export interface Upload {
  fileName: string
  id: string
  gid: string
  uploadedBy: string
  uploadDate: string
  processed: boolean
  jobFailed: boolean
  numberOfRowsWithErrors?: number
  numberOfSuccessfulRows?: number
  rows: number
  failedUploadResults: UploadResult[]
}

export interface UploadResult {
  failureReason: FailureReason
}

export enum FailureReason {
  DUPLICATE = "duplicate",
  OTHER = "other",
}

export const fetchUploads = async ({
  supplierOrganizationId,
  page,
}: {
  supplierOrganizationId: string
  page: number
}): Promise<{
  uploads: Upload[]
  currentCount: number
  hasNextPage: boolean
}> => {
  const data = await get(
    `/u/r/${supplierOrganizationId}/documentation_orders/uploads.json`,
    { page }
  ).then((data) => {
    return data.data
  })
  //@ts-ignore
  return data
}
export const createUpload = (supplierOrganizationId: string, file: File) => {
  return uploadDocumentToUrl(createUploadUrl(supplierOrganizationId), file)
}
