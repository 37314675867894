import { GridColDef, GridFilterModel } from "@mui/x-data-grid-pro"
import { decamelize } from "humps"
import { camelCase } from "lodash"
import { ServerSideDataGridSort } from "../hooks/useServerSideDataGrid"

/**
 * A helper method for stringifying a user-entered filter value for use in sending to the server.
 * Its raison d'être is to append timezone data to user-entered timestamps.
 * @param columns The column definition list of the DataGrid
 * @param columnField The DataGrid column name being examined
 * @param value The user-entered filter value
 */
export const stringifyFilterValue = (
  columns: GridColDef[],
  columnField: string,
  value?: any
): string => {
  if (!value) {
    return ""
  } else if (
    columns.find((c) => c.type === "dateTime" && c.field === columnField)
  ) {
    return new Date(value).toISOString()
  } else {
    return value.toString()
  }
}

/**
 * Centralized method for converting a DataGridPro GridFilterModel into the marshalled format we expect
 * on the server side.  Will also take care of accounting for user timezone.
 * @param columns The list of column definitions
 * @param filterModel The GridFilterModel provided by DataGridPro
 */
export const parameterizeFilterModel = (
  columns: GridColDef[],
  filterModel?: GridFilterModel
): string[] | undefined => {
  return filterModel?.items?.map((cf) => {
    const value: string = stringifyFilterValue(
      columns,
      cf.columnField!,
      cf.value
    )
    return `${decamelize(cf.columnField)}:${cf.operatorValue}:${value}`
  })
}

/**
 * Centralized method for converting a DataGridPro GridSortModel into the marshalled format we expect
 * on the server side.
 * @param sortModel The GridFilterModel provided by DataGridPro
 */
export const parameterizeSortModel = (
  sortModel?: ServerSideDataGridSort
): string[] | undefined => {
  return sortModel?.map((sort) => `${decamelize(sort.field)}:${sort.sort}`)
}

/**
 * For a given sort model, make sure that the field names are camelized.
 * These frequently correspond either to column names or Ruby naming conventions,
 * both of which may involve underscores.
 */
export const camelizeSortModel = (
  sortModel?: ServerSideDataGridSort
): ServerSideDataGridSort | undefined => {
  return sortModel?.map((sort) => {
    return { field: camelCase(sort.field), sort: sort.sort }
  })
}
