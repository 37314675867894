import classNames from "classnames"
import React, { useState } from "react"
import {
  DocumentationRequirement,
  DocumentationRequirementSatisfyStatus,
} from "sharedTypes"
import Icon from "components/Icon"
import AttachManualDocumentationModal from "applications/Workflow/components/AttachManualDocumentationModal"

interface Props {
  btnClass: string
  documentationRequirements: DocumentationRequirement[]
  updateDocumentationRequirementsSatisfyStatus(values: {
    manualSatisfyStatus: DocumentationRequirementSatisfyStatus
    documentationRequirementIds: string[]
  })
  uploadDocuments(
    documentationRequirementIds: string[],
    files: File[],
    signed: boolean,
    otherAttachmentTitle: string
  ): Promise<void>
  dmeOrderId: string
  supplierFaxNumber: string
  hasSigningClinician: boolean
  manuallySatisfyGeneratedDocs: boolean
}

const AttachManualDocumentationModalButton = ({
  btnClass,
  documentationRequirements,
  updateDocumentationRequirementsSatisfyStatus,
  uploadDocuments,
  dmeOrderId,
  supplierFaxNumber,
  hasSigningClinician,
  manuallySatisfyGeneratedDocs,
}: Props) => {
  const [showModal, setShowModal] = useState(false)

  return (
    <>
      <button
        className={classNames("file-label btn btn-block", btnClass)}
        onClick={() => setShowModal(true)}
      >
        <Icon type="paperclip" className="canopy-mie-4x" />
        Upload or fax documents
      </button>
      <AttachManualDocumentationModal
        showModal={showModal}
        closeModal={() => setShowModal(false)}
        documentationRequirements={documentationRequirements}
        updateDocumentationRequirementsSatisfyStatus={
          updateDocumentationRequirementsSatisfyStatus
        }
        uploadDocuments={uploadDocuments}
        dmeOrderId={dmeOrderId}
        supplierFaxNumber={supplierFaxNumber}
        hasSigningClinician={hasSigningClinician}
        manuallySatisfyGeneratedDocs={manuallySatisfyGeneratedDocs}
      />
    </>
  )
}

export default AttachManualDocumentationModalButton
