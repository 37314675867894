import React, { useEffect, useState } from "react"
import Modal from "components/Modal"
import withInitialData from "components/withInitialData"
import * as api from "./api"
import PushbackSignUpBody from "./PushbackSignUpBody"
import VerifyEmailModal from "components/VerifyEmailModal"
import { EventCategory, trackEvent } from "utilities/tracking"
import cx from "classnames"
import { PushbackSignUpModalSource } from "./sharedTypes"
import { emailSubmittedEvent, modalSeenEvent } from "./utilities"

type InitialData = {
  initialEmail: string
  currentUserEmail: string
}

type Props = {
  dismiss(): void
  initialData: InitialData
  source: PushbackSignUpModalSource
}

function PushbackSignUpModal({ dismiss, initialData, source }: Props) {
  const { initialEmail, currentUserEmail } = initialData
  const [submittedEmail, setSubmittedEmail] = useState<string | null>(
    currentUserEmail
  )

  const updateEmail = (email) => {
    void trackEvent(
      EventCategory.PushbackSignUp,
      emailSubmittedEvent(source) as string
    )
    return api.updateEmail(email)
  }

  useEffect(() => {
    void trackEvent(
      EventCategory.PushbackSignUp,
      modalSeenEvent(source) as string
    )

    if (currentUserEmail) {
      void trackEvent(
        EventCategory.PushbackSignUp,
        "sign_up_button_clicked_pushback_invite_resent"
      )
      void api.sendPushbackInvitation()
    }
  }, [source, currentUserEmail])

  type CloseButtonProps = {
    additionalClassName?: string
  }

  const CloseButton = ({ additionalClassName }: CloseButtonProps) => (
    <button
      className={cx("close", additionalClassName)}
      type="button"
      onClick={dismiss}
    >
      ×
    </button>
  )

  const RunAroundModal = () => {
    return (
      <Modal closeable size="lg" show cancel={dismiss}>
        <Modal.Body>
          <CloseButton />
          <PushbackSignUpBody
            initialEmail={initialEmail}
            updateEmail={updateEmail}
            setSubmittedEmail={setSubmittedEmail}
          />
        </Modal.Body>
      </Modal>
    )
  }

  if (!submittedEmail) {
    return <RunAroundModal />
  } else {
    return <VerifyEmailModal dismiss={dismiss} email={submittedEmail} />
  }
}

const fetchInitialData = () => api.getUserEmail().then(({ data }) => data)
export default withInitialData(fetchInitialData)(PushbackSignUpModal)
