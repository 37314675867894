import React, { useContext } from "react"
import Card from "./Card"
import { DmeOrder, Document } from "sharedTypes"
import DownloadLink from "components/DownloadLink"
import GlobalContext from "context/Global"
import { EventCategory, trackEvent } from "utilities/tracking"

export const RESUPPLY_EVENT_CATEGORY = EventCategory.Resupply
export const DOCTOR_DASHBOARD_DOWNLOAD_EVENT = "doctor_dash_download_dme"

type Props = {
  dmeOrder: DmeOrder
  downloadableDocuments: Document[]
}

const trackDownloadEvent = () => {
  trackEvent(RESUPPLY_EVENT_CATEGORY, DOCTOR_DASHBOARD_DOWNLOAD_EVENT)
}

const DocumentationCard = (props: Props) => {
  const { requiresTwoStepDownload } = useContext(GlobalContext)
  const { dmeOrder, downloadableDocuments } = props
  const documentationDocuments = downloadableDocuments.filter(
    (d) => d.dmeOrderDocumentationRequirementId
  )

  const cardContent = () => {
    if (documentationDocuments.length === 0) {
      return (
        <div className="alert alert-warning alert-rounded alert-borderless">
          There are no documents available to download at this time.
        </div>
      )
    }
    return (
      <ul className="list-unstyled canopy-mbe-0">
        {documentationDocuments.map(
          ({ key, title, dmeOrderDocumentationRequirementId }) => (
            <li key={key}>
              <DownloadLink
                orderId={dmeOrder.id}
                dmeOrderDocumentationRequirementId={
                  dmeOrderDocumentationRequirementId
                }
                documentKey={key}
                label={title}
                className="btn-link"
                requiresTwoStepDownload={requiresTwoStepDownload}
                onDownload={trackDownloadEvent}
              />
            </li>
          )
        )}
      </ul>
    )
  }

  return (
    <Card dropdown={false} title="Documentation">
      {cardContent()}
    </Card>
  )
}

export default DocumentationCard
