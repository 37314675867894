import React from "react"

const ChatUnavailableIcon: React.FC = () => (
  <svg
    width="83"
    height="82"
    viewBox="0 0 83 82"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M41.5002 70.9303C58.0301 70.9303 71.4302 57.5302 71.4302 41.0003C71.4302 24.4704 58.0301 11.0703 41.5002 11.0703C24.9703 11.0703 11.5702 24.4704 11.5702 41.0003C11.5702 57.5302 24.9703 70.9303 41.5002 70.9303Z"
      fill="#F3ECFF"
    />
    <path
      d="M57.1924 41.5451C57.1945 35.5135 49.995 30.6204 41.1104 30.6166C32.2264 30.613 25.022 35.5001 25.0199 41.5316C25.0175 47.5639 32.2172 52.4564 41.1019 52.4602C44.2135 52.4615 47.1165 51.8611 49.5786 50.8237C51.0345 51.6924 52.8652 53.2902 53.7412 56.0984C54.6522 53.2618 54.4225 50.4351 53.529 48.4799C55.8164 46.5948 57.191 44.1792 57.1924 41.5451Z"
      fill="#F3ECFF"
      stroke="#440061"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M32.9033 43.1969C34.0418 43.4662 35.1829 42.7615 35.4522 41.623C35.7214 40.4845 35.0167 39.3433 33.8782 39.0741C32.7397 38.8049 31.5985 39.5096 31.3293 40.6481C31.0601 41.7866 31.7648 42.9277 32.9033 43.1969Z"
      stroke="#440061"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M40.618 43.1996C41.7565 43.4688 42.8977 42.7641 43.1669 41.6257C43.4361 40.4872 42.7314 39.346 41.5929 39.0768C40.4544 38.8076 39.3133 39.5123 39.044 40.6508C38.7748 41.7893 39.4795 42.9304 40.618 43.1996Z"
      stroke="#440061"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M48.3327 43.2038C49.4712 43.473 50.6124 42.7683 50.8816 41.6298C51.1508 40.4913 50.4461 39.3502 49.3076 39.081C48.1691 38.8117 47.028 39.5164 46.7588 40.6549C46.4896 41.7934 47.1942 42.9346 48.3327 43.2038Z"
      stroke="#440061"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default ChatUnavailableIcon
