// @ts-strict-ignore
import $ from "jquery"
import escapeHTML from "escape-html"
import { handleRemoteFormChange } from "../form"
import * as templateFunctions from "./templates"
import * as resultProcessors from "./resultProcessors"
import "select2"

function isAjax(node) {
  return node.data("ajax-url-function-name") || node.data("ajax-url")
}

function addSelectHandler(node) {
  const placeholder = node.getAttribute("placeholder") || ""
  node = $(node)
  const enableTags = !!node.data("insert-option")
  const noResultsText = node.data("no-results") || "No results found"
  const insertOption = node.data("insert-option") || "Add Option: "
  const queryTerm = node.data("query-term") || "term"
  const idField = node.data("ajax-id-field") || "id"
  const textField = node.data("ajax-text-field") || "text"
  const resultsRoot = node.data("ajax-results-root") || "results"
  const resultsProcessor = node.data("ajax-results-processor")
  const templateName = node.data("template-name")
  const selectOptions = {
    width: "100%",
    theme: "bootstrap",
    minimumInputLength: node.data("minimum-input-length") || 0,
    placeholder,
    templateResult: templateFunctions[templateName],
    language: {
      noResults() {
        return noResultsText
      },
    },
    escapeMarkup(markup) {
      if (markup === noResultsText) {
        return markup
      } else {
        return escapeHTML(markup)
      }
    },
    tags: enableTags,
    createTag(tag) {
      return {
        id: tag.term,
        text: tag.term,
        newTag: true,
      }
    },
    insertTag(data, tag) {
      if (tag.newTag) {
        tag.text = `${insertOption} '${tag.text}'`
      }
      data.push(tag)
    },
    ajax: null,
    allowClear: null,
  }
  if (isAjax(node)) {
    selectOptions.ajax = {
      url: window[node.data("ajax-url-function-name")] || node.data("ajax-url"),
      dataType: "json",
      delay: 100,
      data(params) {
        const query = {}

        query[queryTerm] = params.term

        return query
      },
      processResults:
        resultProcessors[resultsProcessor] ||
        ((data) => {
          const results = data[resultsRoot] || []

          return {
            results: results.map(function (item) {
              return {
                ...item,
                id: item[idField],
                text: item[textField],
              }
            }),
          }
        }),
    }
  }
  if (node.hasClass("js-allow-clear")) {
    selectOptions.allowClear = true
  }
  if (node.hasClass("js-submit")) {
    node.on("change", function () {
      handleRemoteFormChange()
    })
  }
  if (node.hasClass("js-select-ordered")) {
    node.on("select2:select", function (e) {
      const id = e.params.data.id
      const option = $(e.target).children("[value=" + id + "]")
      option.detach()
      $(e.target).append(option).change()
    })
  }

  return node.select2(selectOptions)
}

export function addSelectHandlers(context) {
  $(context)
    .find(".js-select")
    .each(function (index, node) {
      addSelectHandler(node)
    })
}

export function removeSelectHandlers(context) {
  $(context).find(".js-select").select2("destroy")
  $(context).find(".js-select").next(".select2").remove()
}

$(window).on("load", function () {
  addSelectHandlers(document)
})

$(document)
  .on("show.bs.modal", ".modal", function () {
    addSelectHandlers(this)
  })
  .on("hidden.bs.modal", ".modal", function () {
    removeSelectHandlers(this)
  })
  .on("sjr:start", ".js-sjr-select", function () {
    removeSelectHandlers(this)
  })
  .on("sjr:end", ".js-sjr-select", function () {
    addSelectHandlers(this)
  })
  .on("unload", function () {
    removeSelectHandlers(document)
  })
