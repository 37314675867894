import React from "react"
import { Context, DmeOrder, DmeOrderWarning } from "sharedTypes"
import { dmeOrderReviewUrl } from "./urls"
import Warnings from "./Warnings"
import Header from "./Header"

type Props = {
  dmeOrder: DmeOrder
  warnings: DmeOrderWarning[]
  context: Context
}

const SoftLandingPage: React.FC<Props> = ({ dmeOrder, warnings, context }) => {
  const packageNames = dmeOrder.lineItemGroups.map(
    ({ packageName }) => packageName
  )

  return (
    <div className="canopy-my-12x my-md-n4 canopy-mx-8x text-center d-flex flex-column align-items-center">
      <Header
        packageNames={packageNames}
        patient={dmeOrder.patient}
        supplier={dmeOrder.supplier}
      />
      <Warnings dmeOrder={dmeOrder} warnings={warnings} context={context} />
      <a
        className="btn btn-brand"
        href={dmeOrderReviewUrl(
          dmeOrder.clinicalFacility.externalId,
          dmeOrder.id
        )}
      >
        Complete this order
      </a>
    </div>
  )
}

export default SoftLandingPage
