import { Card, CardContent, Typography } from "@material-ui/core"
import { orderUrl } from "../../urls"
import React from "react"
import * as styles from "./order-card.module.scss"
import { EventCategory, trackEvent } from "utilities/tracking"

export const OrderCard = ({ order }) => {
  const DOCTOR_VIEW_DME_ORDER_EVENT_NAME = "doctor_dash_view_dme_order"
  return (
    <Card variant="outlined" className={styles.card}>
      <CardContent className={styles.content}>
        <div className={styles.flexContainer}>
          <Typography className={styles.patientNameColumn} variant="subtitle1">
            <strong>
              <span role="note" aria-label="Patient Name">
                {order.patientName}{" "}
              </span>
              <span role="note" aria-label="Patient MRN">
                {order.externalPatientId && `(MRN: ${order.externalPatientId})`}
              </span>
            </strong>
          </Typography>
          <div className={styles.viewButtonColumn}>
            <a
              href={orderUrl(order.id)}
              className="link"
              onClick={() => {
                trackEvent(
                  EventCategory.Resupply,
                  DOCTOR_VIEW_DME_ORDER_EVENT_NAME
                )
              }}
            >
              View
            </a>
          </div>
        </div>
        <Typography className={styles.dateOfBirth} variant="subtitle1">
          DOB: {order.patientDob}
        </Typography>
        <Typography variant="subtitle1">
          Facility: {order.facilityName}
        </Typography>
        <Typography variant="subtitle1">
          Requested by: {order.creatorName}
        </Typography>
        <Typography variant="subtitle1">
          Products: {order.productNamesWithDetails}
        </Typography>
        {order.review && (
          <Typography variant="subtitle1">
            Reviewed By: {order.review.reviewerName}
          </Typography>
        )}
      </CardContent>
    </Card>
  )
}
