import OptInNotificationsEmailAlert from "./OptInNotificationsEmailAlert"
import React from "react"
import { useDoctorContext } from "../../hooks/useDoctorContext"
import SelfSignUpAlert from "./SelfSignUpAlert"
import OptInSuccessAlert from "./OptInSuccessAlert"

interface Props {
  fromSelfSignUp: boolean
  optIn: boolean
  reviewer: {
    id: string
    email: string
    name: string
  }
}

const Alerts = (props: Props) => {
  const { doctor } = useDoctorContext()

  return (
    <>
      {props.fromSelfSignUp && <SelfSignUpAlert doctor={doctor} />}
      {props.optIn && <OptInSuccessAlert doctor={doctor} />}
      {doctor.showNotificationsOptInRequestInfoBanner && (
        <OptInNotificationsEmailAlert
          reviewer={props.reviewer}
          selectedDoctor={doctor}
        />
      )}
    </>
  )
}

export default Alerts
