import React from "react"
import Alert from "components/Alert"
import { Doctor } from "../../sharedTypes"
import ChangeInUserPreferencesLink from "./ChangeInUserPreferencesLink"

const SelfSignUpAlert = ({ doctor }: { doctor: Doctor }) => (
  <Alert status="info">
    <span>
      <strong>{`You've been added as a reviewer!`}</strong>
      {` You will now receive any signature requests related to your clinicians directly to your email. `}
      <ChangeInUserPreferencesLink doctor={doctor} />
    </span>
  </Alert>
)

export default SelfSignUpAlert
