// @ts-strict-ignore
import React from "react"
import InternalSupplierOrganizationUserForm from "./InternalSupplierOrganizationUserForm"
import { User, Employment, FeatureFlagInput } from "sharedTypes"
import * as api from "./api"
import * as urls from "./urls"
import { navigate } from "utilities/navigation"
import { FeatureFlagProvider } from "../../components/FeatureFlagContext"

interface Props {
  defaultTimeZone: string
  user: User
  employments: Employment[]
  featureFlags: FeatureFlagInput
}

function SupplierOrganizationUserForm({
  user,
  defaultTimeZone,
  employments,
  featureFlags,
}: Props) {
  const createUser = (params) => {
    return api.createUser(params).then(() => {
      navigate(urls.usersUrl())
    })
  }
  const updateUser = (params) => {
    const { userId } = user
    return api.updateUser(userId, params).then(() => {
      navigate(urls.userUrl(userId))
    })
  }
  return (
    <FeatureFlagProvider flags={featureFlags}>
      <InternalSupplierOrganizationUserForm
        user={user}
        defaultTimeZone={defaultTimeZone}
        employments={employments}
        createUser={createUser}
        updateUser={updateUser}
      />
    </FeatureFlagProvider>
  )
}

export default SupplierOrganizationUserForm
