import React from "react"
import {
  DocumentationRequirement,
  DocumentationRequirementSatisfyStatus,
} from "sharedTypes"
import { Form } from "components/form"
import { formatPhone } from "utilities/phone"
import DownloadFaxCoverSheetLink from "components/DownloadFaxCoverSheetLink"
import GenerateRequirementsSection from "./GenerateRequirementsSection"
import ManualRequirementsSection from "./ManualRequirementsSection"
import Modal from "components/Modal"
import Tooltip from "components/Tooltip"

type Props = {
  updateDocumentationRequirementsSatisfyStatus(values: {
    manualSatisfyStatus: DocumentationRequirementSatisfyStatus
    documentationRequirementIds: string[]
  }): void
  manualRequirements: DocumentationRequirement[]
  generateRequirements: DocumentationRequirement[]
  supplierFaxNumber: string
  closeModal(): void
}

const FaxTab = ({
  updateDocumentationRequirementsSatisfyStatus,
  manualRequirements,
  generateRequirements,
  supplierFaxNumber,
  closeModal,
}: Props) => {
  if (manualRequirements.length > 0 || generateRequirements.length > 0) {
    const validate = ({ documentationRequirementIds, signed, otherAnswer }) => {
      if (documentationRequirementIds.length === 0 && !signed) {
        return {
          documentationRequirementIds:
            "must select at least one documentation requirement",
        }
      }
      if (
        documentationRequirementIds.includes(ManualRequirementsSection.OTHER) &&
        !otherAnswer
      ) {
        return { otherAnswer: "Document name required" }
      }
    }

    const filteredDocumentationRequirementIds = (ids) =>
      ids.filter((id) => ![ManualRequirementsSection.OTHER].includes(id))
    const coverSheetDocumentationRequirementIds = ({
      documentationRequirementIds,
      signed,
    }) => {
      if (signed) {
        return filteredDocumentationRequirementIds(
          documentationRequirementIds
        ).concat(
          generateRequirements.map((requirement) => requirement.externalId)
        )
      }
      return filteredDocumentationRequirementIds(documentationRequirementIds)
    }

    const downloadFaxCoverSheetLink = (values) => {
      const disabled = !!validate(values)
      const link = (
        <DownloadFaxCoverSheetLink
          disabled={disabled}
          documentationRequirementIds={coverSheetDocumentationRequirementIds(
            values
          )}
          otherTitle={
            values.documentationRequirementIds.includes(
              ManualRequirementsSection.OTHER
            )
              ? values.otherAnswer
              : null
          }
        />
      )

      if (disabled) {
        return (
          <Tooltip
            triggerElementClassName="d-inline-block"
            overlay="Select documents above to download a cover sheet."
          >
            {link}
          </Tooltip>
        )
      }

      return link
    }

    return (
      <Form
        onSubmit={({ documentationRequirementIds }) =>
          updateDocumentationRequirementsSatisfyStatus({
            manualSatisfyStatus:
              DocumentationRequirementSatisfyStatus.FaxInProgress,
            documentationRequirementIds: filteredDocumentationRequirementIds(
              documentationRequirementIds
            ),
          })
        }
        validate={validate}
        initialValues={{
          documentationRequirementIds: [],
        }}
      >
        {({ isSubmitting, isValid, values }) => (
          <>
            <Modal.Body>
              <div className="row canopy-mbe-4x">
                <div className="col-12">
                  <strong className="canopy-mie-8x">Step 1:</strong>
                  <strong>Select the items you will be faxing in</strong>
                </div>
              </div>
              <ManualRequirementsSection requirements={manualRequirements} />
              <GenerateRequirementsSection
                requirements={generateRequirements}
              />
              <div className="row canopy-mbe-4x">
                <div className="col-12">
                  <strong className="canopy-mie-8x">Step 2:</strong>
                  <strong>Fax your documents with a cover sheet</strong>
                </div>
              </div>
              <div className="row canopy-mbe-4x">
                <div className="col-1">1.</div>
                <div className="col-11">
                  {downloadFaxCoverSheetLink(values)}
                </div>
              </div>
              <div className="row canopy-mbe-4x">
                <div className="col-1">2.</div>
                <div className="col-11">
                  Fax to {formatPhone(supplierFaxNumber)}
                </div>
              </div>
              <div className="row canopy-mbe-4x">
                <div className="col-1">3.</div>
                <div className="col-11">
                  Finish & Send Parachute Order to Supplier
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <button
                className="btn btn-brand"
                disabled={isSubmitting || !isValid}
                type="submit"
              >
                Done
              </button>
            </Modal.Footer>
          </>
        )}
      </Form>
    )
  } else {
    return (
      <>
        <Modal.Body>
          <div className="row canopy-mbe-4x">
            <div className="col-10">
              <strong>Fax your documents with a cover sheet</strong>
            </div>
          </div>
          <div className="row canopy-mbe-4x">
            <div className="col-1">1.</div>
            <div className="col-11">
              <DownloadFaxCoverSheetLink otherTitle="Any other relevant documents for this order" />
            </div>
          </div>
          <div className="row canopy-mbe-4x">
            <div className="col-1">2.</div>
            <div className="col-11">
              Fax to {formatPhone(supplierFaxNumber)}
            </div>
          </div>
          <div className="row canopy-mbe-4x">
            <div className="col-1">3.</div>
            <div className="col-11">
              Finish & Send Parachute Order to Supplier
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-brand" type="button" onClick={closeModal}>
            Done
          </button>
        </Modal.Footer>
      </>
    )
  }
}

export default FaxTab
