import { post } from "services/api"
import { createOrderUrl } from "../../../urls"

export const createOrder = ({
  supplierOrganizationId,
  csrInboxStateId,
}: {
  supplierOrganizationId: string
  csrInboxStateId: string
}): Promise<{ orderPath: string }> => {
  return post(createOrderUrl(supplierOrganizationId, csrInboxStateId)).then(
    (response) => {
      return {
        orderPath: response?.data?.orderPath,
      }
    }
  )
}
