import React, { useCallback, useState } from "react"
import { navigate } from "utilities/navigation"
import { EventCategory, trackEvent } from "utilities/tracking"
import { newOrderUrl } from "./urls"
import InternalWelcomeModal from "./components/InternalWelcomeModal"
import { markWelcomeModalAsViewed } from "./api"
import { handleError } from "utilities/error"

const EVENT_CATEGORY = EventCategory.Activation

const onMount = () => {
  return markWelcomeModalAsViewed().catch(handleError)
}

const WelcomeModal = (): JSX.Element => {
  const [show, toggle] = useState(true)

  const hide = () => {
    toggle(false)
  }

  const dismiss = useCallback(() => {
    trackEvent(EVENT_CATEGORY, "welcome-modal-dismissed").then(hide)
  }, [])

  const onComplete = useCallback(() => {
    trackEvent(EVENT_CATEGORY, "welcome-modal-start-ordering")
    navigate(newOrderUrl())
  }, [])

  const onStepChange = useCallback((step: string) => {
    const steps = [
      "welcome_screen",
      "order_screen",
      "track_screen",
      "collaborate_screen",
    ]
    trackEvent(EVENT_CATEGORY, steps[step])
  }, [])

  return (
    <InternalWelcomeModal
      dismiss={dismiss}
      onComplete={onComplete}
      onStepChange={onStepChange}
      onMount={onMount}
      show={show}
    />
  )
}

export default WelcomeModal
