// @ts-strict-ignore
import React, { useState } from "react"
import { Form, Select } from "components/form"
import Button from "components/form/Button"
import FacilityOption from "./FacilityOption"
import FacilityPreview from "./FacilityPreview"
import { Facility } from "../../sharedTypes"
import { groupFacilities } from "./utilities/select"

interface Props {
  onSubmit(): Promise<void>
  getFacilities(term: string): Promise<Facility[]>
  onFacilityChange(facility: Facility): void
  skipFacilitySelect(): Promise<void>
}

const FacilitySelectForm = ({
  onSubmit,
  getFacilities,
  onFacilityChange,
  skipFacilitySelect,
}: Props) => {
  const [selectedFacility, setSelectedFacility] = useState<Facility>(null)
  const [
    isSubmittingWithoutFacility,
    setIsSubmittingWithoutFacility,
  ] = useState<boolean>(false)

  const initialValues = { facilityId: "" }
  const validate = () => {
    if (!selectedFacility) {
      return { facilityId: "can't be blank" }
    }
  }

  const onSkipFacilitySelect = () => {
    setIsSubmittingWithoutFacility(true)
    skipFacilitySelect()
      .then(() => setIsSubmittingWithoutFacility(false))
      .catch(() => setIsSubmittingWithoutFacility(false))
  }

  return (
    <>
      <h1>Select a healthcare facility</h1>
      <p className="text-center text-xs-left canopy-mbe-8x">
        Search for the healthcare facility that you want to invite your referral
        to join.
      </p>
      <Form
        onSubmit={onSubmit}
        initialValues={initialValues}
        validate={validate}
      >
        {({ isSubmitting }) => (
          <>
            <Select
              name="facilityId"
              placeholder="Search by name or address"
              isClearable
              isSearchable
              onChange={(_newValue, facility: Facility) => {
                setSelectedFacility(facility)
                onFacilityChange(facility)
              }}
              fetchOptions={(term: string) =>
                getFacilities(term).then((facilities) =>
                  groupFacilities(
                    facilities.map((facility) => ({
                      ...facility,
                      label: facility.name,
                      value: facility.facilityId,
                    }))
                  )
                )
              }
              renderOption={(facility) => (
                <FacilityOption facility={facility} />
              )}
            />
            <div className="facility-preview-wrapper">
              {selectedFacility && (
                <FacilityPreview facility={selectedFacility} />
              )}
            </div>
            <Button
              className="btn-brand btn-block canopy-mbs-24x"
              type="submit"
              disabled={isSubmitting || isSubmittingWithoutFacility}
            >
              {!selectedFacility || selectedFacility.facilityId
                ? "Select Facility and Send Invite"
                : "Select Facility and Review Details"}
            </Button>
            <Button
              className="link btn-block canopy-pbs-24x"
              onClick={onSkipFacilitySelect}
              disabled={isSubmitting || isSubmittingWithoutFacility}
            >
              Send invite without selecting a facility
            </Button>
          </>
        )}
      </Form>
    </>
  )
}
export default FacilitySelectForm
