import React from "react"
import TableOverviewPresentation from "components/TableOverviewPresentation"

type Props = {
  loading?: boolean
  currentCount: number
  totalCount: number
  sortingBy?: string
  suffix: string
}

function TableOverview({
  loading,
  currentCount,
  totalCount,
  sortingBy,
  suffix,
}: Props) {
  const results = `${
    loading ? "x" : currentCount
  } of ${totalCount} total ${suffix}`
  return <TableOverviewPresentation sortingBy={sortingBy} results={results} />
}

export default TableOverview
