import React from "react"
import { User } from "sharedTypes"
import InternalActions from "./components/InternalActions"

type Props = {
  user: User & { availableForSignUp: boolean }
  currentEmployerName: string
  canConvertToFullUser: boolean
  canRemoveUserFromFacility: boolean
  canSendWelcomeEmail: boolean
  invitePreviouslySent: boolean
}

const Actions: React.FC<Props> = ({
  user,
  currentEmployerName,
  canConvertToFullUser,
  canRemoveUserFromFacility,
  canSendWelcomeEmail,
  invitePreviouslySent,
}) => {
  return (
    <InternalActions
      user={user}
      currentEmployerName={currentEmployerName}
      canConvertToFullUser={canConvertToFullUser}
      canRemoveUserFromFacility={canRemoveUserFromFacility}
      canSendWelcomeEmail={canSendWelcomeEmail}
      invitePreviouslySent={invitePreviouslySent}
    />
  )
}

export default Actions
