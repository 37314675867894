import React, { useContext } from "react"
import { Route, Switch, useHistory } from "react-router-dom"
import SeedsPage from "./components/SeedsPage"
import SeedDetailsPage from "./components/SeedDetailsPage"
import { withMaterialTheme } from "../../../../themes/theme"
import SeedEditPage from "./components/SeedEditPage"
import { NoticeContext, withNoticeProvider } from "../../contexts/NoticeContext"
import { withBrowserRouter } from "routers/BrowserRouter"

const SeedsWorkflow: React.FC = () => {
  const history = useHistory()
  const { clearNotice } = useContext(NoticeContext)

  React.useEffect(() => {
    clearNotice()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history.location.pathname])

  return (
    <Switch>
      <Route path="/cms/catalog/seeds" exact>
        <SeedsPage />
      </Route>
      <Route path="/cms/catalog/seeds/new" exact>
        <SeedEditPage />
      </Route>
      <Route path="/cms/catalog/seeds/:seedId/edit" exact>
        <SeedEditPage />
      </Route>
      <Route path="/cms/catalog/seeds/:seedId" exact>
        <SeedDetailsPage />
      </Route>
    </Switch>
  )
}

export default withBrowserRouter(
  withNoticeProvider(withMaterialTheme(SeedsWorkflow)),
  {}
)
