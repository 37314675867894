import React from "react"
import { ShoppingCart } from "@material-ui/icons"
import AddProductsLink from "./AddProductsLink"

interface Props {
  canUpdate: boolean
}

const EmptyCart: React.FC<Props> = ({ canUpdate }) => (
  <div className="text-center canopy-my-24x canopy-py-24x">
    <div className="font-xxl">
      <ShoppingCart fontSize="inherit" />
    </div>
    <div>
      <h2>Your cart is empty</h2>
    </div>
    <AddProductsLink canUpdate={canUpdate} cartIsEmpty={true} />
  </div>
)

export default EmptyCart
