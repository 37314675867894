import React, { Dispatch, SetStateAction, useContext, useState } from "react"
import { ToastContext } from "components/Toaster"
import { Approvals, bulkSignSignatureRequests } from "../api"
import { DoctorSignatureRequest } from "../../../api"
import { pluralize } from "utilities/string"
import SignRequestsModal from "./SignRequestsModal"
import { EventActions, trackEvent } from "../../../utilities/tracking"
import { CanopyButton } from "@parachutehealth/canopy-button"

interface Props {
  selectedSignatureRequestsGids: string[]
  pendingSignatureRequests: DoctorSignatureRequest[]
  doctor: { name: string; npi: string; id: string }
  onSign: () => void
  loading: boolean
  setLoading: Dispatch<SetStateAction<boolean>>
  notificationBadgeStatusChecker: () => void
}

const SignSignatureRequestsOnScreenButton = ({
  selectedSignatureRequestsGids,
  pendingSignatureRequests,
  doctor,
  onSign,
  loading,
  setLoading,
  notificationBadgeStatusChecker,
}: Props) => {
  const { persistentAlert } = useContext(ToastContext)
  const [showSignRequestsModal, setShowSignRequestsModal] = useState(false)

  const convertSelectedGidsToIds = (
    selectedSignatureRequestsGids
  ): Approvals => {
    const approvals: Approvals = {
      orderIds: [],
      signingSignatureRequestIds: [],
    }
    pendingSignatureRequests
      .filter((s) => selectedSignatureRequestsGids.includes(s.gid))
      .forEach((s) => {
        if (s.type === "DmeOrder") {
          approvals.orderIds.push(s.id)
        } else if (s.type === "Signing::SignatureRequest") {
          approvals.signingSignatureRequestIds.push(s.id)
        }
      })
    return approvals
  }

  const markAsSigned = () => {
    setLoading(true)
    trackEvent(EventActions.ReviewerSignRequestModal)
    return bulkSignSignatureRequests(
      doctor.id,
      convertSelectedGidsToIds(selectedSignatureRequestsGids)
    )
      .then((res) => {
        if (res && res.data && res.data.alreadySigned) {
          persistentAlert({
            message: `Some of the selected orders have already been signed`,
            severity: "warning",
          })
        } else {
          persistentAlert({
            message: `${selectedSignatureRequestsGids.length} ${pluralize(
              "Request",
              selectedSignatureRequestsGids.length
            )} Signed Successfully`,
            severity: "success",
          })
        }
        onSign()
        notificationBadgeStatusChecker()
        setShowSignRequestsModal(false)
      })
      .catch(() =>
        persistentAlert({
          message: `Encountered an error, please try again`,
          severity: "error",
        })
      )
  }

  const onClose = () => {
    trackEvent(EventActions.ReviewerCancelRequestModal)
    setShowSignRequestsModal(false)
  }

  return (
    <>
      <CanopyButton
        variant="primary"
        disabled={selectedSignatureRequestsGids.length === 0 || loading}
        onClick={() => {
          trackEvent(EventActions.ReviewerSignSelectedOrders)
          setShowSignRequestsModal(true)
        }}
      >
        Sign Selected Orders
      </CanopyButton>
      <SignRequestsModal
        show={showSignRequestsModal}
        close={onClose}
        sign={markAsSigned}
        requestCount={selectedSignatureRequestsGids.length}
        doctor={doctor}
        loading={loading}
      />
    </>
  )
}

export default SignSignatureRequestsOnScreenButton
