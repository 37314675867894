import { put } from "services/api"
import { employerPrefix } from "utilities/url"
import { Failure, Result, Success } from "components/AsyncButton"
import { navigate } from "utilities/navigation"

export const attachToDmeOrder = (supplierId, csrInboxStateId, dmeOrderId) => {
  return (): Promise<Result> => {
    return put(
      `${employerPrefix(
        "Supplier",
        supplierId
      )}/inbox/${csrInboxStateId}/assign.json`,
      { dme_order_id: dmeOrderId }
    )
      .then((data) => {
        navigate(data.data.dmeOrderPath)
        return new Success()
      })
      .catch(
        () =>
          new Failure(
            "Sorry, there was a problem on our end. Try refreshing the page and trying again"
          )
      )
  }
}
