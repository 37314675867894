import { get, post, put } from "services/api"
import * as urls from "applications/Workflow/urls"

export function getDocumentsData() {
  return get(urls.sidebarDocumentsUrl())
}

export function archiveAttachment(attachmentId: string) {
  return put(urls.archiveAttachmentsUrl(attachmentId))
}

export function transformImage(id, data) {
  return post(urls.transformImageUrl(id), data)
}
