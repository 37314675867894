import React from "react"
import Icon from "components/Icon"
import classNames from "classnames"

interface Props {
  children: React.ReactNode
  status?: string
  className?: string
  solid?: boolean
  noBottomMargin?: boolean
}

function Pill({ children, status, className, solid, noBottomMargin }: Props) {
  status = status || "default"
  return (
    <div
      className={classNames(
        `pill pill-${status}`,
        {
          "pill-basic": status === "default",
        },
        {
          "pill-solid": solid,
        },
        className
      )}
      style={noBottomMargin ? { marginBottom: 0 } : {}}
    >
      {status === "success" && <Icon prefix="fas" type="check-circle" />}
      {status === "danger" && <Icon prefix="fas" type="exclamation-circle" />}
      {children}
    </div>
  )
}

export default Pill
