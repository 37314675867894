import { gql, TypedDocumentNode } from "@apollo/client"
import {
  SupplierInsuranceExternalIdUpsertMutation,
  SupplierInsuranceExternalIdUpsertMutationVariables,
} from "../../../graphql/__generated__/graphql"

export const SupplierInsuranceExternalIdUpsert: TypedDocumentNode<
  SupplierInsuranceExternalIdUpsertMutation,
  SupplierInsuranceExternalIdUpsertMutationVariables
> = gql`
  mutation SupplierInsuranceExternalIdUpsert(
    $insuranceProductId: ID!
    $externalSystemId: ID
  ) {
    supplierInsuranceExternalSystemIdUpsert(
      input: {
        insuranceProductId: $insuranceProductId
        externalSystemId: $externalSystemId
      }
    ) {
      action
    }
  }
`
