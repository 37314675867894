// @ts-strict-ignore
import React from "react"
import PrintSignature from "./PrintSignature"
import DigitalSignature from "./DigitalSignature"
import OnScreenSignature from "./OnScreenSignature"
import { DmeOrder, SignatureType } from "sharedTypes"
import { DoctorContactAttributes, SignableRequirement } from "../sharedTypes"
import SignableRequirementsList from "./SignableRequirementsList"

type Props = {
  allowFaxPacketDownload: boolean
  createDoctorContact(attributes: DoctorContactAttributes): Promise<any>
  dmeOrder: DmeOrder
  isChangingSignatureType: boolean
  isEpicEnabledForDoctor: boolean
  isCernerEnabledForDoctor: boolean
  signatureType: SignatureType
  updateSignatureChannel(channel: string, isSelected: boolean): Promise<void>
  setSignatureType(type: SignatureType): void
  signableRequirements: SignableRequirement[]
  verifiedChannels: string[]
  signOnScreenEnabled: boolean
}

function SignatureChannelSelector({
  setSignatureType,
  isEpicEnabledForDoctor,
  isCernerEnabledForDoctor,
  dmeOrder,
  updateSignatureChannel,
  signableRequirements,
  verifiedChannels,
  createDoctorContact,
  isChangingSignatureType,
  signatureType,
  allowFaxPacketDownload,
  signOnScreenEnabled,
}: Props) {
  const renderSignatureTypeDetail = () => {
    switch (signatureType) {
      case SignatureType.OnScreen:
        return <OnScreenSignature dmeOrder={dmeOrder} />
      case SignatureType.Digital:
        return (
          <DigitalSignature
            dmeOrder={dmeOrder}
            isEpicEnabledForDoctor={isEpicEnabledForDoctor}
            isCernerEnabledForDoctor={isCernerEnabledForDoctor}
            updateSignatureChannel={updateSignatureChannel}
            createDoctorContact={createDoctorContact}
            signableRequirements={signableRequirements}
            verifiedChannels={verifiedChannels}
          />
        )
      case SignatureType.Manual:
        return (
          <PrintSignature
            allowFaxPacketDownload={allowFaxPacketDownload}
            dmeOrder={dmeOrder}
          />
        )
    }
  }

  const availableSignatureTypes = []
  if (signOnScreenEnabled) {
    availableSignatureTypes.push(SignatureType.OnScreen)
  }
  availableSignatureTypes.push(SignatureType.Digital)
  if (!dmeOrder.restrictToDigitalSignature) {
    availableSignatureTypes.push(SignatureType.Manual)
  }

  const buttonLabel = (signatureMethod) => {
    switch (signatureMethod) {
      case SignatureType.OnScreen:
        return "Sign On-Screen"
      case SignatureType.Digital:
        return "Send a Request"
      case SignatureType.Manual:
        return "Print and Attach"
    }
  }

  const renderSignatureMethodRadio = (signatureMethod) => {
    return (
      <div key={signatureMethod} className="radio primary">
        <input
          type="radio"
          id={signatureMethod}
          name={signatureMethod}
          value={signatureMethod}
          checked={signatureMethod === signatureType}
          onChange={() => setSignatureType(signatureMethod)}
        />
        <label htmlFor={signatureMethod}>{buttonLabel(signatureMethod)}</label>
      </div>
    )
  }
  return (
    <div className="canopy-mbs-12x">
      <h4>Signature Method</h4>
      {availableSignatureTypes.length > 1 && (
        <div className="canopy-mbe-8x">
          {availableSignatureTypes.map(renderSignatureMethodRadio)}
        </div>
      )}

      {!isChangingSignatureType &&
        availableSignatureTypes.includes(signatureType) &&
        renderSignatureTypeDetail()}
      <div className="canopy-mbs-12x">
        <SignableRequirementsList signableRequirements={signableRequirements} />
      </div>
    </div>
  )
}

export default SignatureChannelSelector
