import { post } from "services/api"
import * as urls from "./urls"

export function sendPasswordResetInstructions({
  email,
}): Promise<{ redirectTo?: string }> {
  return post(urls.sendPasswordResetInstructionsUrl(), {
    user: { email },
  }).then(({ data }) => data)
}
