import React, { useState } from "react"
import Modal from "components/Modal"
import UploadForm from "applications/Uploads/UploadForm"
import { DownloadCsvTemplateButton } from "applications/Uploads/DownloadCsvTemplateButton"
import Link from "components/Link"

const UploadButton = (props: {
  supplierOrganizationId: string
  possibleCsvColumns: string[]
  requiredCsvColumns: string[]
  createUploadApi: (supplierOrganizationId, file) => Promise<any>
  afterUpload: () => void
  templatePath: string
  uploadGuide?: {
    label: string
    link: string
  }
}) => {
  const [showModal, setShowModal] = useState(false)

  const openModal = () => {
    setShowModal(true)
  }

  const closeModal = () => {
    setShowModal(false)
  }

  const afterUpload = () => {
    closeModal()
    props.afterUpload()
  }

  return (
    <>
      <button className="btn btn-brand btn-sm" onClick={openModal}>
        Upload CSV
      </button>
      <Modal show={showModal} cancel={closeModal} title="Upload CSV" size="md">
        <Modal.Body>
          <p>
            The uploaded CSV must adhere to the template format to be accepted
            and processed.
            {props.uploadGuide && (
              <React.Fragment>
                {" "}
                See the{" "}
                <Link
                  href={props.uploadGuide.link}
                  target="_blank"
                  className="color-brand"
                >
                  {props.uploadGuide.label}
                </Link>{" "}
                for more details and troubleshooting tips.
              </React.Fragment>
            )}
          </p>
          <DownloadCsvTemplateButton templatePath={props.templatePath} />
          <UploadForm
            upload={(file) => {
              return props.createUploadApi(props.supplierOrganizationId, file)
            }}
            afterUpload={afterUpload}
            requiredCsvColumns={props.requiredCsvColumns}
            possibleCsvColumns={props.possibleCsvColumns}
          />
        </Modal.Body>
      </Modal>
    </>
  )
}

export default UploadButton
