// @ts-strict-ignore
import { EmploymentMention } from "sharedTypes"
import { GroupedSuggestion } from "../Suggestion"

export function groupRecords(
  records: EmploymentMention[]
): GroupedSuggestion[] {
  const groups = []
  const FOLLOWERS = "Followers"

  const convertToGroupedSuggestion = (
    record: EmploymentMention
  ): GroupedSuggestion => {
    const groupName = record.isFollower ? FOLLOWERS : record.employerName
    const firstInGroup = !groups.includes(groupName)
    if (firstInGroup) {
      groups.push(groupName)
    }
    return {
      ...record,
      firstInGroup,
      groupName,
      display: record.name,
    }
  }
  return records.map(convertToGroupedSuggestion)
}
