import React, { useState } from "react"
import {
  DocumentationOrderQueryParameters,
  SignatureStatuses,
} from "../fetchOrders"
import SelectFilter from "components/SelectFilter"

export const OrderStatusFilter: React.FC<{
  updateSearchParameters(
    updatedParameters: Partial<DocumentationOrderQueryParameters>
  ): void
}> = ({ updateSearchParameters }) => {
  const [statusFilter, setStatusFilter] = useState<SignatureStatuses[]>([])
  const onChange = async (values) => {
    updateSearchParameters({
      signatureStatuses: values.signatureStatuses,
    })
    setStatusFilter(values.signatureStatuses)
  }
  return (
    <SelectFilter
      singular={true}
      options={[
        { label: "Pending", value: "pending" },
        { label: "Signed", value: "signed" },
        { label: "Denied", value: "denied" },
      ]}
      initialValues={statusFilter}
      onChange={onChange}
      name="signatureStatuses"
      label="Signature Status"
    />
  )
}
