// @ts-strict-ignore
export function meta(property: string): string {
  const result: { [s: string]: string } = {}
  const nodes = document.querySelectorAll("meta")
  for (let i = 0, ilen = nodes.length; i < ilen; i += 1) {
    const node = nodes[i]
    const name = node.getAttribute("name")
    if (name) {
      result[name] = node.getAttribute("content")
    }
  }
  return result[property]
}
