import { get, post } from "services/api"
import * as urls from "../SalesRepEditor/urls"
import { SalesRep } from "applications/SupplierDashboard/sharedTypes"
import { getSalesRepsUrl } from "../SalesRepEditor/urls"

export const updateSalesRep = ({ supplierId }: { supplierId: string }) => (
  dmeOrderId,
  params: { externalId: string }
) => {
  return post(urls.salesRepsUrl(dmeOrderId, "Supplier", supplierId), {
    salesRep: params,
  }).then(({ data }) => data)
}

export const getSalesReps = ({ supplierId }: { supplierId: string }) => (
  term: string
): Promise<SalesRep[]> => {
  return get(
    `${getSalesRepsUrl("Supplier", supplierId)}?all_potential_sales_reps=true`,
    { term }
  ).then(({ data }) => data)
}
