export function loginUrl() {
  return "/users/log_in.json"
}

export function forgotPasswordUrl() {
  return "/users/password/new"
}

export function pointClickCareLoginUrl() {
  return "/users/auth/point_click_care"
}

export function epicLoginUrl() {
  return "/users/log_in_with_epic"
}

export function newConfirmationUrl() {
  return "/users/confirmation/new"
}
