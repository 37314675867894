import React from "react"
import Card from "./Card"
import { toList } from "utilities/array"
import { DmeOrder } from "sharedTypes"
import DataRow from "components/DataRow"

type Props = {
  dmeOrder: DmeOrder
}

const PaymentCard = (props: Props) => {
  const { dmeOrder } = props
  const { insurancePolicies } = dmeOrder
  const primaryPolicy = insurancePolicies.find((p) => p.rank === "primary")
  const secondaryPolicy = insurancePolicies.find((p) => p.rank === "secondary")
  const tertiaryPolicy = insurancePolicies.find((p) => p.rank === "tertiary")

  const policyInfo = (policy) => {
    if (policy) {
      return `${policy.carrierName}, ${policy.memberNumber}`
    }
  }

  return (
    <Card
      title="Payment"
      summary={toList(
        insurancePolicies.map((p) => `${p.carrierName} - ${p.memberNumber}`),
        3
      )}
    >
      <DataRow label="Primary" value={policyInfo(primaryPolicy)} />
      {secondaryPolicy && (
        <DataRow label="Secondary" value={policyInfo(secondaryPolicy)} />
      )}
      {tertiaryPolicy && (
        <DataRow label="Tertiary" value={policyInfo(tertiaryPolicy)} />
      )}
    </Card>
  )
}

export default PaymentCard
