import { DocumentationRequirement } from "sharedTypes"
import { Link } from "react-router-dom"
import { EventCategory, trackEvent } from "utilities/tracking"
import React from "react"
import { fillablePdfPath } from "../../../utilities"

const EVENT_PREFIX = "documentation-requirement-edit-fillable-pdf"

type EditChartNoteLinkProps = Pick<
  DocumentationRequirement,
  "externalId" | "satisfyStatus"
>

export const FillablePdfDetails = ({
  documentationRequirement,
}: {
  documentationRequirement: EditChartNoteLinkProps
}) => {
  return (
    <div className="row">
      <div className="col-9" />
      <div className="col-3">
        <Link
          className="link canopy-mx-4x font-subparagraph float-right"
          onClick={() => {
            trackEvent(
              EventCategory.Activation,
              `${EVENT_PREFIX}-${documentationRequirement.satisfyStatus}`
            )
          }}
          to={fillablePdfPath(documentationRequirement.externalId)}
        >
          Edit
        </Link>
      </div>
    </div>
  )
}
