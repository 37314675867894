export function isEmpty(value: string) {
  return value.length === 0
}

export const customizeErrors = (errors, customMessages) => {
  for (const key in errors) {
    const fieldErrors = errors[key]
    for (let i = 0; i < fieldErrors.length; i++) {
      if (customMessages[key] && customMessages[key][fieldErrors[i]]) {
        fieldErrors[i] = customMessages[key][fieldErrors[i]]
      }
    }
  }
  return errors
}
