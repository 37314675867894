import React, { useCallback, useState } from "react"
import { loadSearchSettings, searchSuppliers } from "../api"
import {
  SearchSettings,
  Supplier,
  SupplierGrouping,
  SuppliersRequest,
  SuppliersResponse,
} from "../sharedTypes"
import SearchInput from "components/SearchInput"
import ServiceAreaStateFilter from "../ServiceAreaStateFilter"
import CategoriesFilter from "../CategoriesFilter"
import withInitialData from "components/withInitialData"
import SupplierCardGrid from "../SupplierCardGrid"
import NoResults from "../SupplierCards/NoResults/NoResults"
import SortDescription from "../SortDescription"
import * as styles from "./SingleViewSupplierList.module.scss"
import YourOrganizationsSuppliersHeader from "../YourOrganizationsSuppliersHeader"
import OtherSuppliersOnParachuteHeader from "../OtherSuppliersOnParachuteHeader"
import Header from "../InternalSupplierList/Header"
import debounce from "awesome-debounce-promise"
import { handleError } from "utilities/error"
import { useEffectThatWontRunOnMount } from "hooks/useEffectThatWontRunOnMount"
import { allowSearchByProduct } from "../../../utilities/searchByProduct"
import { useFeatureFlags } from "components/FeatureFlagContext"

interface Props {
  manageFulfillmentAgreements: boolean
  initialData: SearchSettings
  usesEnterpriseFeatures: boolean
}

const debouncedFetchSuppliers = debounce(
  (suppliersRequest: SuppliersRequest): Promise<SuppliersResponse> =>
    searchSuppliers(suppliersRequest),
  200,
  {
    leading: true,
  }
)

const SingleViewSupplierList: React.FC<Props> = ({
  manageFulfillmentAgreements,
  initialData,
  usesEnterpriseFeatures,
}) => {
  const {
    categories,
    defaultServiceAreaStates,
    oneTimeOrderEnabled,
  } = initialData
  const [selectedCategories, setSelectedCategories] = useState<string[]>(
    initialData.filters.selectedCategories || []
  )
  const [selectedServiceAreaStates, setSelectedServiceAreaStates] = useState<
    string[]
  >(initialData.filters.selectedServiceAreaStates || [])
  const [query, setQuery] = useState<string>(initialData.filters.query)
  const [loading, setLoading] = useState<boolean>(false)
  const filtersApplied =
    selectedCategories.length > 0 || selectedServiceAreaStates.length > 0

  const [connectedOrgSuppliers, setConnectedOrgSuppliers] = useState<
    Supplier[]
  >(initialData.results.orgSuppliers)
  const [orgSupplierCount, setOrgSupplierCount] = useState(
    initialData.results.orgSupplierCount
  )
  const [otherSuppliers, setOtherSuppliers] = useState<Supplier[]>(
    initialData.results.otherSuppliers
  )
  const [otherSupplierCount, setOtherSupplierCount] = useState(
    initialData.results.otherSupplierCount
  )
  const [hasActiveSuppliers, setHasActiveSuppliers] = useState(
    initialData.results.hasActiveSuppliers
  )
  const [currentOrgSuppliersPage, setCurrentOrgSuppliersPage] = useState<
    number
  >(1)
  const [currentOtherSuppliersPage, setCurrentOtherSuppliersPage] = useState<
    number
  >(1)
  const { isFeatureEnabled } = useFeatureFlags()

  const fetchNextOrgSuppliersPage = (nextPage: number) => {
    const page = nextPage
    setCurrentOrgSuppliersPage(nextPage)
    setLoading(true)
    return debouncedFetchSuppliers({
      page,
      query,
      selectedServiceAreaStates,
      selectedCategories,
    })
      .then((response) => {
        setLoading(false)
        setConnectedOrgSuppliers((orgSuppliers) => [
          ...orgSuppliers,
          ...response.orgSuppliers,
        ])
        setOrgSupplierCount(response.orgSupplierCount)
      })
      .catch((error) => {
        setLoading(false)
        handleError(error)
      })
  }

  const fetchNextOtherSuppliersPage = (nextPage: number) => {
    const page = nextPage
    setCurrentOtherSuppliersPage(nextPage)
    setLoading(true)
    return debouncedFetchSuppliers({
      page,
      query,
      selectedServiceAreaStates,
      selectedCategories,
    })
      .then((response) => {
        setLoading(false)
        setOtherSuppliers((otherSuppliers) => [
          ...otherSuppliers,
          ...response.otherSuppliers,
        ])
        setOtherSupplierCount(response.otherSupplierCount)
      })
      .catch((error) => {
        setLoading(false)
        handleError(error)
      })
  }

  const fetchInitialSupplierResults = useCallback(() => {
    setLoading(true)
    return debouncedFetchSuppliers({
      page: 1,
      query,
      selectedServiceAreaStates,
      selectedCategories,
    })
      .then((response) => {
        setLoading(false)
        setConnectedOrgSuppliers(response.orgSuppliers)
        setOrgSupplierCount(response.orgSupplierCount)
        setOtherSuppliers(response.otherSuppliers)
        setOtherSupplierCount(response.otherSupplierCount)
        setHasActiveSuppliers(response.hasActiveSuppliers)
        setCurrentOrgSuppliersPage(1)
        setCurrentOtherSuppliersPage(1)
      })
      .catch((error) => {
        setLoading(false)
        handleError(error)
      })
  }, [query, selectedServiceAreaStates, selectedCategories])

  const clearFilters = () => {
    setSelectedCategories([])
    setSelectedServiceAreaStates([])
  }

  useEffectThatWontRunOnMount(() => {
    fetchInitialSupplierResults()
  }, [fetchInitialSupplierResults])

  const showSectionBreakBetweenSupplierSections = oneTimeOrderEnabled
    ? true
    : connectedOrgSuppliers.length === 0
  const showNoResults = oneTimeOrderEnabled
    ? connectedOrgSuppliers.length === 0 && otherSuppliers.length === 0
    : connectedOrgSuppliers.length === 0
  const isProductFirstSearchEnabled = allowSearchByProduct(
    isFeatureEnabled("userActivationProductFirstSearchIncludeEnterprise"),
    usesEnterpriseFeatures
  )

  return (
    <>
      {isProductFirstSearchEnabled ? null : (
        <Header oneTimeOrderEnabled={oneTimeOrderEnabled} />
      )}
      <SearchInput
        name="query"
        placeholder="Search Suppliers by name"
        value={query}
        onChange={setQuery}
        loading={loading}
      />
      <div className={styles.summaryRow}>
        <div>
          <ServiceAreaStateFilter
            defaultServiceAreaStates={defaultServiceAreaStates}
            selectedServiceAreaStates={selectedServiceAreaStates}
            setSelectedServiceAreaStates={setSelectedServiceAreaStates}
          />
          <CategoriesFilter
            selectedCategories={selectedCategories}
            categories={categories}
            setSelectedCategories={setSelectedCategories}
          />
        </div>
        <SortDescription query={query}></SortDescription>
      </div>
      <hr className="canopy-mbe-12x canopy-mbs-8x" />
      <SupplierCardGrid
        Header={YourOrganizationsSuppliersHeader}
        supplierGrouping={SupplierGrouping.Organization}
        manageFulfillmentAgreements={manageFulfillmentAgreements}
        suppliers={connectedOrgSuppliers}
        suppliersCount={orgSupplierCount}
        hasActiveSuppliers={hasActiveSuppliers}
        loading={loading}
        fetchNextPage={() =>
          fetchNextOrgSuppliersPage(currentOrgSuppliersPage + 1)
        }
        usesEnterpriseFeatures={usesEnterpriseFeatures}
      />
      {showSectionBreakBetweenSupplierSections && (
        <hr data-testid="supplier-section-break" />
      )}
      {oneTimeOrderEnabled && (
        <>
          <SupplierCardGrid
            Header={OtherSuppliersOnParachuteHeader}
            supplierGrouping={SupplierGrouping.Other}
            manageFulfillmentAgreements={manageFulfillmentAgreements}
            suppliers={otherSuppliers}
            suppliersCount={otherSupplierCount}
            hasActiveSuppliers={hasActiveSuppliers}
            loading={loading}
            fetchNextPage={() =>
              fetchNextOtherSuppliersPage(currentOtherSuppliersPage + 1)
            }
            usesEnterpriseFeatures={usesEnterpriseFeatures}
          />
          {otherSuppliers.length === 0 && <hr className="canopy-mbe-0" />}
        </>
      )}
      {showNoResults && (
        <NoResults
          clearFilters={clearFilters}
          filtersApplied={filtersApplied}
        />
      )}
    </>
  )
}

export default withInitialData(loadSearchSettings)(SingleViewSupplierList)
