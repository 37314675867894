import React from "react"
import { signOut } from "../../api"
import { Button } from "components/form"

const SignOutButton = () => {
  return (
    <Button ariaLabel="sign out" onClick={signOut} className="btn-ghost">
      Sign Out
    </Button>
  )
}

export default SignOutButton
