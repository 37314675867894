import { get, put } from "services/api"
import { searchInsuranceProductsUrl, updateInsuranceUrl } from "../../../urls"
import { InsuranceRank } from "../../../sharedTypes"

type InsuranceProductResult = {
  id: string
  name: string
}

export const searchInsuranceProducts = async ({
  supplierOrganizationId,
  csrInboxStateId,
  query,
}: {
  supplierOrganizationId: string
  csrInboxStateId: string
  query: string
}): Promise<InsuranceProductResult[]> => {
  return await get(
    searchInsuranceProductsUrl(supplierOrganizationId, csrInboxStateId),
    {
      query,
    }
  )
    .then(({ data }) => {
      return data || []
    })
    .catch(() => [])
}

export type UpdateInsuranceParams = Partial<{
  insuranceProductId: string
  memberId: string
}>
export const updateInsurance = async ({
  supplierOrganizationId,
  csrInboxStateId,
  rank,
  params,
}: {
  supplierOrganizationId: string
  csrInboxStateId: string
  rank: InsuranceRank
  params: UpdateInsuranceParams
}): Promise<void> => {
  return await put(
    updateInsuranceUrl(supplierOrganizationId, csrInboxStateId, rank),
    {
      insurance: params,
    }
  ).then(({ data }) => {
    return data
  })
}
