import { SalesRepTerritory } from "../../index"
import React from "react"
import Modal from "components/Modal"
import { del } from "services/api"
import { navigate } from "utilities/navigation"
import { CanopyButton } from "@parachutehealth/canopy-button"
import { handleError } from "utilities/error"
type Props = {
  territory: SalesRepTerritory
  shouldShow: boolean
  closeModal: () => void
}

const destroy = async (territoryPath: string, closeModal) => {
  const deleteTerritoryPath = territoryPath.replace(/\/edit/, "")
  return await del(deleteTerritoryPath)
    .then((response) => {
      navigate(response.data.path) // easy way to refresh the page
    })
    .catch((error) => {
      closeModal()
      handleError(error)
    })
}

const SalesRepTerritoriesTableRemoveModal: React.FC<Props> = ({
  territory,
  shouldShow,
  closeModal,
}) => {
  return (
    <>
      {territory && (
        <Modal show={shouldShow && !!territory} size="lg">
          <Modal.Header>Remove Territory</Modal.Header>
          <Modal.Body>
            <p>
              Confirm you want to remove the sales rep from all facilities and
              open orders within {territory.territoryName}
            </p>
          </Modal.Body>
          <Modal.Footer>
            <CanopyButton
              onClick={closeModal}
              variant="secondary"
              className="canopy-mie-8x"
            >
              Cancel
            </CanopyButton>
            <CanopyButton
              onClick={() => destroy(territory.territoryPath, closeModal)}
              variant="danger"
            >
              Remove
            </CanopyButton>
          </Modal.Footer>
        </Modal>
      )}
    </>
  )
}

export default SalesRepTerritoriesTableRemoveModal
