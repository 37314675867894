import { put, get } from "services/api"
import { ChartNoteQuestion } from "sharedTypes"
import {
  updateOrderDateUrl,
  updateDocumentationRequirementsRequestedUrl,
  chartNoteQuestionsUrl,
} from "applications/Workflow/urls"

export const updateOrderDate = (orderDate) => {
  return put(updateOrderDateUrl(), { orderDate })
}

export const fetchChartNoteQuestions = ({
  id,
}): Promise<ChartNoteQuestion[]> => {
  return get(chartNoteQuestionsUrl(id)).then(({ data }) => data)
}

export const updateDocumentationRequirementsRequested = (requestedIds) => {
  return put(updateDocumentationRequirementsRequestedUrl(), {
    requestedDocumentationRequirementIds: requestedIds,
  })
}
