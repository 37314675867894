import React from "react"
import classNames from "classnames"
import * as styles from "./index.module.scss"

interface Props {
  active?: boolean
  badge?: boolean
  icon: React.ReactElement
}

export default function Icon({ icon, badge, active }: Props) {
  return (
    <div className={styles.container}>
      {React.cloneElement(icon, {
        className: classNames(styles.icon, { [styles.active]: active }),
      })}
      {badge && <div className={styles.badge} />}
    </div>
  )
}
