// @team @facilitators
import { SurveyQuestion } from "../../../../../sharedTypes"

type Nullable<T> = T | null | undefined

// 1. The questions that appear in our Clinical Info Section are not one unified form sharing a single form state, instead they are collections of individual forms
// 2. See app/javascript/applications/Workflow/components/Survey/questions/asQuestion.tsx and its usages... :'(
// 3. If we had one unified form with state managed by Formik, formik has out-of-the-box functionality to identify the first question with an error state, and we could remove this function entirely
export const scrollToFirstIncompleteQuestion: (
  document: Document,
  questions: SurveyQuestion[]
) => void = (document, questions) => {
  // Find the first unanswered question
  let unansweredQuestionElement: Nullable<HTMLElement> = null
  const unansweredQuestion: Nullable<SurveyQuestion> = questions.find(
    (question) => !question.answerValue
  )
  if (unansweredQuestion) {
    const escapedQuestionId =
      "#question-" + unansweredQuestion.questionId.replace(/:/g, "\\:")
    unansweredQuestionElement = document.querySelector(
      escapedQuestionId
    ) as HTMLElement
  }

  // Find the first instance of 'Saving is required', which is a question with an unsaved answer
  const saveWarningElement: Nullable<HTMLElement> = document
    .querySelector(".save-warning")
    ?.closest(".form-group")
    ?.querySelector(
      "input[name='answerValue'], textarea[name='answerValue']"
    ) as HTMLElement

  // Scroll to whichever element is first in the DOM
  let inputToScrollTo: Nullable<HTMLElement> = null
  if (unansweredQuestionElement && saveWarningElement) {
    /**
     * this uses the bitwise AND operator to check if the saveWarningElement is following
     *  the unansweredQuestionElement in the DOM
     *
     * see https://developer.mozilla.org/en-US/docs/Web/API/Node/compareDocumentPosition
     */
    const saveWarningElementIsFollowing: number =
      unansweredQuestionElement.compareDocumentPosition(saveWarningElement) &
      Node.DOCUMENT_POSITION_FOLLOWING
    if (saveWarningElementIsFollowing) {
      inputToScrollTo = unansweredQuestionElement
    } else {
      inputToScrollTo = saveWarningElement
    }
  } else {
    inputToScrollTo = unansweredQuestionElement
      ? unansweredQuestionElement
      : saveWarningElement
  }

  inputToScrollTo?.scrollIntoView({
    behavior: "smooth",
    block: "center",
  })
  setTimeout(() => inputToScrollTo?.focus(), 1000)
}
