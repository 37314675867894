import React from "react"
import FacilityDetails from "../FacilityDetails"
import { Facility } from "../../../sharedTypes"
import { formatAddress } from "utilities/address"
import Suggestion from "components/form/Select/Suggestion"

interface Props {
  facility: Facility
}

const FacilityOption = ({ facility }: Props) => {
  const { name } = facility

  return (
    <Suggestion>
      <div className="bold">{name}</div>
      <div>{formatAddress(facility)}</div>
      <FacilityDetails facility={facility} />
    </Suggestion>
  )
}
export default FacilityOption
