import React from "react"
import TextField from "components/form/TextField"
import { isZipValid, ZIP_VALIDATION_MESSAGE } from "utilities/zip"

interface Props {
  name?: string
  label?: string
  placeholder?: string
  validateField?: boolean
}

function ZipField({ name, label, placeholder, validateField }: Props) {
  const validate = (value) => {
    if (!isZipValid(value)) {
      return ZIP_VALIDATION_MESSAGE
    }
  }
  return (
    <TextField
      label={label}
      name={name || ""}
      placeholder={placeholder}
      validate={validateField ? validate : undefined}
    />
  )
}

ZipField.defaultProps = {
  label: "ZIP Code",
  name: "zip",
}

export default ZipField
