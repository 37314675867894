import React from "react"
import Modal from "components/Modal"
import { pluralize } from "utilities/string"
import logo from "../../../../../../assets/images/logo.png"

type Props = {
  show: boolean
  close(): void
  sign(): void
  requestCount: number
  doctor: { id: string; name: string; npi: string }
  loading: boolean
}

const SignRequestsModal: React.FunctionComponent<Props> = (props: Props) => {
  const { show, close, sign, requestCount, doctor, loading } = props

  return (
    <Modal size="md" show={show} cancel={close}>
      <Modal.Body>
        <div className="modal-body">
          <div className="border-bottom canopy-mbe-12x">
            <img
              width="28"
              height="28"
              src={logo}
              alt="Parachute Health"
              className="center-block canopy-mbe-2x"
            />
            <p className="text-center compact color-dark-gray canopy-mbe-12x">
              e-sign By Parachute Health
            </p>
          </div>
          <div className="text-center canopy-mbe-12x">
            <h2 className="bold">{doctor.name}</h2>
            <h3 className="color-dark-gray">{`NPI ${doctor.npi}`}</h3>
          </div>
          <div className="text-center canopy-mbe-12x">
            <button
              type="button"
              className="btn btn-secondary color-dark-gray canopy-mie-8x"
              onClick={close}
            >
              Cancel
            </button>
            <button
              type="button"
              className="btn btn-brand"
              onClick={sign}
              disabled={loading}
            >
              {`Sign ${requestCount} ${pluralize("Request", requestCount)}`}
            </button>
          </div>
          <p className="compact color-dark-gray">
            {doctor.name} has reviewed and authorized this order and has
            certified that the medical necessity info contained herein is true
            accurate and complete, to the best of their knowledge, and is
            consistent with the information contained in my clinical records for
            the Patient and/or in the Patient’s permanent medical record. I
            certify that the patient/caregiver is capable and has successfully
            completed training or will be trained on the proper use of the
            products prescribed on this written order. I understand that any
            falsification, omission, or concealment of material fact in this
            order with respect to any information contained herein may subject
            me to civil or criminal liability.
          </p>
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default SignRequestsModal
