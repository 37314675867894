// @team @expanse
import React from "react"
import { Address, PatientSnapshot } from "sharedTypes"
import { cityLine } from "utilities/address"
import {
  formatPhoneNumbers,
  getPatientEmail,
  getPreferredLanguage,
} from "./utilities"

const OrderReviewContactAddress = (props: {
  address: Address
  patientSnapshot: PatientSnapshot
}) => {
  const { address, patientSnapshot } = props

  const formattedPhoneNumbers = formatPhoneNumbers(address)

  const addressDetails = [
    address.line1,
    address.line2,
    address.room,
    cityLine(address),
    formattedPhoneNumbers.primaryPhoneNumber,
    formattedPhoneNumbers.alternatePhoneNumber,
    getPatientEmail(address, patientSnapshot),
    getPreferredLanguage(address, patientSnapshot),
  ].filter((addressDetail) => !!addressDetail)

  return (
    <>
      {addressDetails.map((addressDetail) => (
        <React.Fragment key={addressDetail}>
          {addressDetail}
          <br />
        </React.Fragment>
      ))}
    </>
  )
}

export default OrderReviewContactAddress
