import { Nullable } from "sharedTypes"

const CURRENCY_SYMBOL = "$"
const DEFAULT_PRECISION = 2

const round = (n: number, precision: number): number => {
  const precisionAdjuster = 10 ** precision
  return (
    Math.round((n + Number.EPSILON) * precisionAdjuster) / precisionAdjuster
  )
}

const actualNumberToCurrency = (n: number, precision: number): string =>
  `${CURRENCY_SYMBOL}${round(n, precision).toFixed(precision)}`

export const numberToCurrency = (
  n: Nullable<number | string> | undefined,
  precision: number = DEFAULT_PRECISION
): string => {
  const numericValue = parseFloat(n as string)
  if (!numericValue && numericValue !== 0) {
    return ""
  }

  return actualNumberToCurrency(numericValue, precision)
}
