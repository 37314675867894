import { DocumentationRequirementSatisfyMethod } from "sharedTypes"
import { Checkbox, Form } from "components/form"
import React from "react"
import { DocumentationRowProps } from "./documentation-row-props"

type ToggleSatisfyMethodProps = Pick<
  DocumentationRowProps,
  "permissions" | "documentationRequirement" | "updateSatisfyMethod"
>
export const canToggleSatisfyMethod = ({
  permissions,
  documentationRequirement,
  updateSatisfyMethod,
}: ToggleSatisfyMethodProps) =>
  permissions.updateManualDocumentation &&
  documentationRequirement.chartNotesRequired &&
  updateSatisfyMethod

export const ToggleSatisfyMethod = (props: ToggleSatisfyMethodProps) => {
  const { permissions, documentationRequirement, updateSatisfyMethod } = props
  const showSatisfyMethodToggle =
    permissions.updateManualDocumentation &&
    documentationRequirement.chartNotesRequired &&
    updateSatisfyMethod

  if (!showSatisfyMethodToggle) return null

  const satisfyMethodCheckboxId = `requirement-${documentationRequirement.externalId}`
  const toggleSatisfyMethod = (values) => {
    updateSatisfyMethod(
      documentationRequirement,
      values[satisfyMethodCheckboxId]
        ? DocumentationRequirementSatisfyMethod.Manual
        : DocumentationRequirementSatisfyMethod.Generate
    )
  }

  return (
    <Form
      submitOnChange
      onSubmit={toggleSatisfyMethod}
      initialValues={{
        [satisfyMethodCheckboxId]:
          documentationRequirement.satisfyMethod ===
          DocumentationRequirementSatisfyMethod.Manual,
      }}
      enableReinitialize
    >
      <Checkbox
        unwrapped
        primary
        type={Checkbox.Type.Basic}
        size={Checkbox.Size.Small}
        name={satisfyMethodCheckboxId}
        label="I'd prefer to upload or fax my own document(s)"
        labelClass="color-dark-gray"
      />
    </Form>
  )
}
