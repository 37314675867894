import React, { useMemo } from "react"
import { useStyles } from "themes/theme"
import { Badge as MuiBadge } from "@material-ui/core"
import * as tokens from "@parachutehealth/canopy-tokens-color"

const styles = ({ color, flex, dotStyles }) => ({
  badge: {
    display: flex ? "flex" : "inline !important",
  },
  badgeColor: {
    backgroundColor: color,
  },
  dot: dotStyles,
})

const Badge = ({
  color,
  flex,
  dotStyles,
  children,
}: {
  color?: string
  flex?: boolean
  dotStyles?: Partial<CSSStyleDeclaration>
  children: React.ReactNode
}) => {
  const muiStyles = useMemo(
    () =>
      styles({
        color,
        flex,
        dotStyles,
      }),
    [color, flex, dotStyles]
  )

  const classes = useStyles(muiStyles)

  return (
    <MuiBadge
      variant="dot"
      data-testid="notification-badge"
      classes={{
        root: classes.badge,
        badge: classes.badgeColor,
        dot: classes.dot,
      }}
    >
      {children}
    </MuiBadge>
  )
}

Badge.defaultProps = {
  color: tokens.canopyColorBackgroundAttention,
}

export default Badge
