import React from "react"
import { Link } from "react-router-dom"
import * as routes from "applications/Workflow/routes"
import { useFeatureFlags } from "components/FeatureFlagContext"

interface Props {
  canFilterByFormulary: boolean
  supplierIdParam: string
}
const QuickListButton: React.FC<Props> = ({
  canFilterByFormulary,
  supplierIdParam,
}) => {
  const { isFeatureEnabled } = useFeatureFlags()
  if (!isFeatureEnabled("quickList")) {
    return null
  }
  if (!canFilterByFormulary) {
    return null
  }

  return (
    <Link
      className="btn btn-brand-o btn-block"
      to={routes.productsQuickListPath(supplierIdParam)}
    >
      Formulary Quick List
    </Link>
  )
}

export default QuickListButton
