import React, { useContext, useState, useEffect } from "react"
import { ActionType, ViewContext } from "../viewContext"
import ViewEditor from "./ViewEditor"
import SupplierOrganizationContext from "../../SupplierOrganizationContext"
import { createViewAPI } from "../api"
import { DashboardView } from "applications/SupplierOrganizationDashboard/sharedTypes"

export const ViewSelectorAndSaver = () => {
  const { state: viewContextState, dispatch } = useContext(ViewContext)
  const { supplierOrganizationId } = useContext(SupplierOrganizationContext)
  const [views, setViews] = useState<DashboardView[]>([])

  useEffect(() => {
    setViews([])
  }, [viewContextState.currentView.externalId])

  const { currentView } = viewContextState

  const fetchViews = async () => {
    const viewApi = createViewAPI(supplierOrganizationId)
    const fetchedViews = await viewApi.getDashboardViews()
    setViews(fetchedViews)
    return fetchedViews
  }

  const changeView = async (viewId) =>
    dispatch({ type: ActionType.SWITCH_VIEW, data: { viewId: viewId } })

  const createView = async (name) =>
    dispatch({ type: ActionType.SAVE_AS_NEW_VIEW, data: { name } })
  const resetView = async () =>
    dispatch({ type: ActionType.RESET_VIEW, data: {} })
  const saveView = async () =>
    dispatch({ type: ActionType.UPDATE_VIEW, data: {} })

  return (
    <ViewEditor
      currentView={currentView}
      fetchViews={fetchViews}
      createView={createView}
      changeView={changeView}
      resetView={resetView}
      saveView={saveView}
      views={views}
      anyUnsavedChanges={viewContextState.hasUnsavedChanges}
    />
  )
}
