import { makeStyles } from "@material-ui/styles"
import { CanopyButton } from "@parachutehealth/canopy-button"
import { CanopyIcon } from "@parachutehealth/canopy-icon"
import React from "react"
import { noOrdersContainerHeightCalculation } from "../heightCalculation"

const useStyles = makeStyles({
  noOrdersContainer: {
    height: noOrdersContainerHeightCalculation(),
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
})

const NoOrders = ({
  lastAccessedFacilityDashboardUrl,
}: {
  lastAccessedFacilityDashboardUrl: string
}): JSX.Element => {
  const classes = useStyles()

  return (
    <div className={classes.noOrdersContainer}>
      <CanopyIcon
        size="large"
        name="circle-check"
        fill="canopyColorBrandSecondary"
        className="canopy-mbe-16x"
      />
      <h3 className="canopy-typography-heading-xlarge canopy-mbe-8x">
        No incoming orders require your attention.
      </h3>
      <span className="canopy-typography-body-large canopy-mbe-16x">
        Go to your facility dashboard to follow up on orders that have been sent
        to the supplier.
      </span>
      <CanopyButton
        as="a"
        className="btn btn-brand"
        href={lastAccessedFacilityDashboardUrl}
      >
        Go to facility dashboard
      </CanopyButton>
    </div>
  )
}
export default NoOrders
