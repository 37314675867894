import React from "react"
import Link from "components/Link"
import actionColumnLinkText from "applications/IncomingOrders/utilities/actionColumnLinkText"
import { DmeOrderSubmissionWarningsEnum } from "graphql/__generated__/graphql"
import ActionColumnPill from "./ActionColumnPill"
import * as styles from "./ActionColumnStyles.module.scss"
import { dmeOrderUrl } from "applications/IncomingOrders/urls"

interface Props {
  row: {
    id: string
    clinicalFacility: { id: string }
    submissionWarnings: { key: DmeOrderSubmissionWarningsEnum }[]
  }
  onClick: any
}

const ActionColumnCell: React.FC<Props> = ({ row, onClick }) => {
  return (
    <Link
      aria-label={actionColumnLinkText(row.submissionWarnings)}
      href={dmeOrderUrl(row.clinicalFacility.id, row.id)}
      onClick={onClick}
    >
      <div className={styles.container}>
        {actionColumnLinkText(row.submissionWarnings)}
        <ActionColumnPill submissionWarnings={row.submissionWarnings} />
      </div>
    </Link>
  )
}

export default ActionColumnCell
