import { User } from "./sharedTypes"
import { EventCategory, trackEvent } from "../../../utilities/tracking"

const trackSignupEvent = (user: User, event: string) => {
  if (user.pushbackSignupUser) {
    void trackEvent(EventCategory.PushbackSignUp, `${event}_pushback`)
  } else if (user.resupplySignupUser) {
    void trackEvent(EventCategory.ResupplySignUp, `${event}_resupply`)
  }
}
export const trackCreateYourAccount = (user: User) => {
  trackSignupEvent(user, "sign_up_completed_create_your_account_page")
}
export const trackCreatePassword = (user: User) => {
  trackSignupEvent(user, "sign_up_completed_password_page")
}
export const trackCreateFacility = (user: User) => {
  trackSignupEvent(user, "sign_up_new_facility_created_successfully")
}
export const trackJoinFacility = (user: User) => {
  trackSignupEvent(user, "sign_up_join_facility_first_user")
}
export const trackRequestToJoinFacility = (user: User) => {
  trackSignupEvent(user, "sign_up_request_join_facility")
}
export const trackReviewDetails = (user: User) => {
  trackSignupEvent(user, "sign_up_review_details_facility")
}
export const trackJoinFacilityAfterReviewingDetails = (user: User) => {
  trackSignupEvent(user, "sign_up_review_and_confirm_join_facility")
}
