import React from "react"
import { Patient } from "../../../sharedTypes"
import { format } from "utilities/date"
import { highlight } from "utilities/select"
import Suggestion from "components/form/Select/Suggestion"
import { trackEvent } from "../../../utilities/analytics"

type Props = {
  patient: Patient
  query?: string
  isHighlighted?: boolean
}

function PatientSuggestion({ patient, query, isHighlighted }: Props) {
  const patientTemplate = (patient: Patient): string => {
    const sex = patient.sex ? ` (${patient.sex.toUpperCase()[0]})` : ""
    const mrn = patient.mrn ? `MRN: ${patient.mrn}\n` : ""
    const dob = `DOB: ${format(patient.dateOfBirth)}`
    const supplierSystemPatientId = patient.supplierSystemPatientId
      ? `\nSSP ID: ${patient.supplierSystemPatientId}`
      : ""
    return `${patient.firstName} ${patient.lastName}${sex}\n${mrn}${dob}${supplierSystemPatientId}`
  }
  return (
    <Suggestion
      onClick={() => trackEvent("click-patient-search-result")}
      key={patient.id}
      isHighlighted={isHighlighted}
      className="font-xs"
    >
      {highlight(patientTemplate(patient), query || "")}
    </Suggestion>
  )
}

export default PatientSuggestion
