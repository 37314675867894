import React from "react"
import { newOrderUrl, newQuickOrderUrl } from "./urls"
import { EventCategory, trackEvent } from "utilities/tracking"
import NewOrderButton from "applications/ClinicalFacilityNewOrderButton/NewOrderButton"

type Props = {
  quickCreate: boolean
}

const ClinicalFacilityNewOrderButton = ({ quickCreate }: Props) => {
  const [showQuickCreateDropdown, setShowQuickCreateDropdown] = React.useState(
    false
  )

  const trackClickEvent = () => {
    trackEvent(EventCategory.Activation, `click-activation-new-order-button`)
  }
  const toggleDropdown = () => {
    trackClickEvent()
    setShowQuickCreateDropdown(!showQuickCreateDropdown)
  }

  if (quickCreate) {
    return (
      <div className="dropdown">
        <NewOrderButton onClick={toggleDropdown} />
        {showQuickCreateDropdown && (
          <ul className="dropdown-menu d-block">
            <li>
              <a href={newOrderUrl()}>Full Order</a>
            </li>
            <li>
              <a href={newQuickOrderUrl()}>Quick Order</a>
            </li>
          </ul>
        )}
      </div>
    )
  } else {
    return (
      <NewOrderButton href={newOrderUrl()} onClick={() => trackClickEvent()} />
    )
  }
}

export default ClinicalFacilityNewOrderButton
