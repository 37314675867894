import React from "react"
import { getTitleStatus } from "../utilities/titleStatus"
import {
  DmeOrderWarning,
  Employer,
  OrderStatus,
  Severity,
  Supplier,
  TitleStatus,
} from "sharedTypes"
import SeverityIcon from "components/SeverityIcon"
import Tooltip from "../../../../../components/Tooltip"
import Lock from "@material-ui/icons/Lock"

type Props = {
  orderStatus: OrderStatus
  warnings: DmeOrderWarning[]
  employer: Employer
  rightChild: React.ReactElement
  supplier: Supplier
}

type TitleContent = {
  text: string
  actionType: Severity
  tooltip?: string
}

const ReviewTitle = ({
  orderStatus,
  warnings,
  employer,
  rightChild,
  supplier,
}: Props): JSX.Element => {
  const titleContentByStatus = {
    [TitleStatus.OrderAccepted]: {
      text: "Order Accepted",
      actionType: Severity.Success,
    },
    [TitleStatus.OrderCanceled]: {
      text: "Order Canceled",
      actionType: Severity.Danger,
    },
    [TitleStatus.ReadyToAccept]: {
      text: "Ready to Accept",
      actionType: Severity.Success,
    },
    [TitleStatus.ReadyToSend]: {
      text: "Ready to Send",
      actionType: Severity.Ready,
    },
    [TitleStatus.ActionRequired]: {
      text: "Action Required",
      actionType: Severity.Danger,
    },
    [TitleStatus.ActionRecommended]: {
      text: "Action Recommended",
      actionType: Severity.Warning,
    },
    [TitleStatus.FacilityWorkingOrder]: {
      text: "Facility Working Order",
      actionType: Severity.Default,
    },
    [TitleStatus.SupplierReview]: {
      text: "Supplier Review",
      tooltip: `${supplier?.name} is reviewing your order. You will be notified when the status changes.`,
      actionType: Severity.Waiting,
    },
    [TitleStatus.InformationNeeded]: {
      text: "Information Needed",
      actionType: Severity.Warning,
    },
    [TitleStatus.TransferredSupplierView]: {
      text: `Transferred to: ${supplier?.name}`,
      actionType: Severity.Locked,
    },
    [TitleStatus.TransferredFacilityView]: {
      text: `Transferred`,
      tooltip: `The supplier you selected transferred your order to ${supplier?.name}.`,
      actionType: Severity.Waiting,
    },
  }

  const titleContent: TitleContent =
    titleContentByStatus[
      getTitleStatus(orderStatus, warnings, employer, supplier)
    ]
  const titleText = titleContent.text
  const actionType = titleContent.actionType
  const TitleTooltip = () => {
    if (!titleContent.tooltip) {
      return null
    }
    return (
      <Tooltip
        triggerElementClassName="d-inline-block canopy-mis-2x canopy-pbe-1"
        trigger={["click", "hover"]}
        overlay={titleContent.tooltip}
      >
        <span
          style={{ fontSize: "1rem" }}
          className="far fa-question-circle color-mid-gray"
        />
      </Tooltip>
    )
  }

  const icon = () =>
    actionType === Severity.Locked ? (
      <Lock
        className="color-black canopy-mie-4x"
        data-testid="lockIcon"
        style={{ transform: "translateY(3px)" }}
      />
    ) : (
      <SeverityIcon type={actionType} className="canopy-mie-4x" />
    )

  return (
    <div className="row gutter-0 d-flex justify-content-between">
      <h3 className="canopy-mbs-8x">
        {icon()}
        {titleText}
        <TitleTooltip />
      </h3>
      <div>{rightChild}</div>
    </div>
  )
}

export default ReviewTitle
