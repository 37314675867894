import { employerPrefix } from "utilities/url"

export const ordersPage = (facilityId: string): string =>
  employerPrefix("ClinicalFacility", facilityId)

export const learnMoreUrl = "https://www.parachutehealth.com/renewals-review"

export const reviewerAffiliatedDoctorSearch = "/u/reviewers/affiliated_doctors"

export const addCliniciansToReviewerUrl = "/u/reviewers/sign_up"
