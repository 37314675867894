import React, { useEffect, useState } from "react"

import { Event } from "sharedTypes"
import Logo from "components/Logo"
import Tooltip from "components/Tooltip"
import { timestampInWords } from "utilities/date"
import { isRecentComment } from "./utilities/date"
import { getTooltip } from "./utilities/tooltip"
import { CSSTransition } from "react-transition-group"
import { lowercase } from "utilities/string"

type Props = {
  activeUser?: boolean
  event: Event
  currentUserId?: string
}

const ONE_MINUTE_IN_MILLISECONDS = 60000
const TIME_FORMAT_THIS_YEAR = "M/D ∙ h:mmA"
const TIME_FORMAT_OTHER_YEARS = "M/D/YYYY ∙ h:mmA"

const Header: React.FC<Props> = ({ activeUser, currentUserId, event }) => {
  const formatTimestamp = () => {
    const now = new Date()
    let timeFormat: string
    if (new Date(event.eventTimestamp).getFullYear() === now.getFullYear()) {
      timeFormat = TIME_FORMAT_THIS_YEAR
    } else {
      timeFormat = TIME_FORMAT_OTHER_YEARS
    }
    return timestampInWords(event.eventTimestamp, timeFormat)
  }
  const isEventOfCurrentUser = currentUserId === event.userId
  const isUserOnline = activeUser || isEventOfCurrentUser
  const actorName = event.userName || event.userEmployerName
  const label = isEventOfCurrentUser ? `${actorName} (You)` : actorName
  const [formattedTimestamp, setFormattedTimestamp] = useState(
    formatTimestamp()
  )
  const tooltip = getTooltip(event, isUserOnline)
  const showNameSubtext =
    lowercase(actorName) !== lowercase(event.userEmployerName)

  useEffect(() => {
    const intervalId = setInterval(() => {
      setFormattedTimestamp(formatTimestamp())
    }, ONE_MINUTE_IN_MILLISECONDS)
    return () => clearInterval(intervalId)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const formatReadAt = (timestamp: string) =>
    new Date(timestamp).toLocaleDateString("en-us", {
      month: "numeric",
      day: "numeric",
    })

  return (
    <div className="clearfix">
      <div className="float-left">
        <Tooltip
          triggerElementClassName="d-inline-block"
          trigger={event.showContactInfo ? ["click", "hover"] : []}
          overlay={tooltip}
          placement={Tooltip.Placement.Top}
          width={Tooltip.Width.Unlimited}
        >
          <div className="d-inline text-nowrap">
            {event.superAdmin && (
              <Logo className="canopy-mie-4x position-relative" width="19" />
            )}
            <span className="bold font-subparagraph">{label}</span>{" "}
            {isUserOnline && (
              <span className="message-user-status canopy-mx-4x" />
            )}
          </div>
          {showNameSubtext && (
            <span className="color-dark-gray font-subparagraph d-block">
              {event.userEmployerName}
            </span>
          )}
        </Tooltip>
      </div>
      <div className="float-right message-time">
        <div className="message-timestamp font-subparagraph">
          {formattedTimestamp}
        </div>
        <CSSTransition
          in={isRecentComment(event) && !isEventOfCurrentUser}
          timeout={300}
          mountOnEnter
          unmountOnExit
          classNames="message-badge"
        >
          <span className="message-badge">New</span>
        </CSSTransition>
        {event.readAt && (
          <span className="color-dark-gray font-xxs d-flex justify-content-end">
            {`Read ${formatReadAt(event.readAt)}`}
          </span>
        )}
      </div>
    </div>
  )
}

export default Header
