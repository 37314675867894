import React, { useMemo } from "react"
import * as styles from "./colors.module.scss"
import { createTheme, MuiThemeProvider } from "@material-ui/core/styles"
import { css } from "@emotion/css"
import { Spacing } from "@material-ui/core/styles/createSpacing"
import { Breakpoints } from "@material-ui/core/styles/createBreakpoints"
import { Mixins } from "@material-ui/core/styles/createMixins"
import { StylesProvider } from "@material-ui/core"
// rules for adding new colors:
// 1. Don't
// 2. Use these colors

const colors = {
  primary: {
    default: styles.colorPrimary,
    light: styles.colorPrimaryLight,
  },
  secondary: {
    default: styles.colorSecondary,
    light: styles.colorSecondaryLight,
  },
  danger: {
    default: styles.colorDanger,
    light: styles.colorDangerLight,
  },
  alert: {
    default: styles.colorAlert,
    light: styles.colorAlertLight,
  },
  info: {
    default: styles.colorInfo,
    light: styles.colorInfoLight,
  },
  accent: {
    default: styles.colorAccent,
    light: styles.colorAccentLight,
  },
  black: {
    default: styles.colorBlack,
  },
  gray: {
    dark: styles.colorDarkGray,
    mid: styles.colorMidGray,
    light: styles.colorLightGray,
    lighter: styles.colorLighterGray,
    lightest: styles.colorLightestGray,
  },
  white: {
    default: styles.colorWhite,
  },
  background: {
    paper: styles.colorWhite,
  },
}
export interface ParachuteTheme {
  colors: typeof colors
  spacing: Spacing
  breakpoints: Breakpoints
  mixins: Mixins
  layout: {
    navbarHeight: string
    navbarZIndex: number
    leftNavZIndex: number
  }
}

export const materialTheme = createTheme({
  palette: {
    primary: {
      main: "#520079",
    },
    secondary: {
      main: "#16baaa",
    },
    error: {
      main: "#d71d42",
    },
    grey: {
      50: "#F7F9FA",
      100: "#E6E6E6",
      200: "#C9C9C9",
      300: "#ADADAD",
      400: "#919191",
      500: "#757575",
      600: "#5C5C5C",
      700: "#454545",
      800: "#2E2E2E",
      900: "#1B1B1B",
    },
  },
  breakpoints: {
    values: {
      xs: 480,
      sm: 768,
      md: 992,
      lg: 1200,
      xl: 1920,
    },
  },
  typography: {
    fontFamily: "Avenir, Helvetica, Verdana, sans-serif",
    subtitle1: {
      lineHeight: 1.425,
    },
    button: {
      textTransform: "none",
    },
  },
})
export const theme: ParachuteTheme = {
  colors,
  spacing: materialTheme.spacing,
  mixins: materialTheme.mixins,
  breakpoints: materialTheme.breakpoints,
  layout: {
    navbarHeight: "60px",
    navbarZIndex: 100,
    leftNavZIndex: 95,
  },
}

type ClassStyle = { [key: string]: string | number | ClassStyle }
type StylesObject = {
  [key: string]: ClassStyle
}
type StylesFunction = (theme: ParachuteTheme) => StylesObject

type ClassesObject = { [className: string]: string }

export const useStyles = (
  styles: StylesObject | StylesFunction
): ClassesObject => {
  return useMemo(() => {
    const stylesObject = typeof styles === "function" ? styles(theme) : styles
    return Object.entries(stylesObject).reduce(
      (acc, [k, v]) => ({ ...acc, [k]: css(v) }),
      {}
    )
  }, [styles])
}

export const MaterialThemeProvider: React.FC = ({ children }) => (
  <StylesProvider injectFirst>
    <MuiThemeProvider theme={materialTheme}>{children}</MuiThemeProvider>
  </StylesProvider>
)

const wrap = (Component) => (props) => (
  <MaterialThemeProvider>
    <Component {...props} />
  </MaterialThemeProvider>
)

export const withMaterialTheme = (Component) => wrap(Component)
