import React, { useContext } from "react"
import { useDoctorContext } from "./useDoctorContext"

const FlashContext = React.createContext<boolean>(false)

export const FlashProvider = (props: { optIn: boolean; children: any }) => {
  const { doctor } = useDoctorContext()
  return (
    <FlashContext.Provider
      value={props.optIn || doctor.showNotificationsOptInRequestInfoBanner}
    >
      {props.children}
    </FlashContext.Provider>
  )
}

export const useFlashContext = (): boolean => useContext(FlashContext)
