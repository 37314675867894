import React from "react"

interface Props {
  events: {
    href: string
    message: string
    timestamp: string
  }[]
  loading: boolean
  show: boolean
}

const NotificationDropdownMenu: React.FC<Props> = ({
  events,
  loading,
  show,
}) => {
  if (!show) {
    return <></>
  }

  if (loading) {
    return (
      <ul className="dropdown-menu dropdown-menu-right">
        <li className="canopy-ml-2">
          <a>Loading...</a>
        </li>
      </ul>
    )
  }

  if (events.length === 0) {
    return (
      <ul className="dropdown-menu dropdown-menu-right">
        <li className="canopy-mis-2">
          <a>No notifications</a>
        </li>
      </ul>
    )
  }

  return (
    <ul className="dropdown-menu dropdown-menu-right">
      {events.map((event, index) => (
        <li key={index}>
          <a href={event.href}>
            {event.message}
            <br />
            <small className="color-mid-grey">{event.timestamp}</small>
          </a>
        </li>
      ))}
    </ul>
  )
}

export default NotificationDropdownMenu
