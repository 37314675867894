import { ServerSideDataGridParams } from "../../../../hooks/useServerSideDataGrid"
import { get } from "../../../../../../services/api"

export type SupplierProductVariationsDataGridRecord = {
  id: number
  productExternalId: string
  productName: string
  productVariationDescription: string
  productVariationExternalId: string
  virtual: boolean
  supplierCatalogId?: string
  supplierSystemId?: string
  supplierProductId?: string
}

export type SupplierProductVariationsDataGridResponse = {
  records: SupplierProductVariationsDataGridRecord[]
  totalCount: number
}

export const getProductVariationsForSupplier = async (
  supplierId: string,
  params?: ServerSideDataGridParams
): Promise<SupplierProductVariationsDataGridResponse> => {
  return await get(
    `/cms/catalog/suppliers/${supplierId}/product_variations.json`,
    params
  ).then((response) => response.data)
}
