export interface NotificationGroup {
  lineItemGroupExternalId: string
  warnings: string[]
  errors: string[]
}

export type Notifications = NotificationGroup[]

export enum Severity {
  Warning = "warning",
  Danger = "danger",
}
