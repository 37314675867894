import { heightCalculation } from "./heightCalculation"

interface Props {
  flash: boolean
}

const selectDrawerWidth = 300

const baseStyles = (props: Props, theme) => ({
  divider: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    backgroundColor: theme.colors.gray.lighter,
  },
  column: {
    width: selectDrawerWidth,
  },
  header: {
    color: theme.colors.black.default,
    margin: 0,
    marginLeft: theme.spacing(3),
    marginTop: theme.spacing(3),
  },
  listItem: {
    borderRadius: theme.spacing(1),
    padding: theme.spacing(2),
  },
  selectedListItem: {
    "&.Mui-selected": {
      backgroundColor: `${theme.colors.gray.lighter}`,
    },
  },
  drawerPaper: {
    width: selectDrawerWidth,
    zIndex: theme.layout.leftNavZIndex,
    borderRight: theme.colors.gray.lighter,
    position: "static",
    height: heightCalculation(props.flash),
  },
  drawerContainer: {
    overflow: "auto",
    padding: theme.spacing(2),
    paddingBottom: "64px",
  },
})

export const doctorDrawerStyles = (props: Props) => (theme) =>
  baseStyles(props, theme)

export const signatureRequestsDrawerStyles = (props: Props) => (theme) => ({
  ...baseStyles(props, theme),
  searchBar: {
    padding: theme.spacing(2),
  },
  spacer: {
    height: theme.spacing(3),
  },
})
