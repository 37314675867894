import React from "react"
import { Nullable } from "sharedTypes"
import FormularyUnitPrice from "./FormularyUnitPrice"
import FormularySubtotal from "./FormularySubtotal"
import { formularyUnitPrice } from "../../../../../../utilities/formularyPrice"

interface Props {
  formularyPriceEnabled: boolean
  lineItemGroup: {
    quantity: number
    lineItems: {
      formularyPrice: Nullable<string>
    }[]
  }
}

const FormularyPrice: React.FC<Props> = ({
  formularyPriceEnabled,
  lineItemGroup,
}) => {
  if (!formularyPriceEnabled) return null
  const unitPrice = formularyUnitPrice(lineItemGroup)

  return (
    <div className="canopy-mbe-4x">
      <FormularyUnitPrice unitPrice={unitPrice} />
      <hr className="canopy-my-4x" />
      <FormularySubtotal
        unitPrice={unitPrice}
        quantity={lineItemGroup.quantity}
      />
    </div>
  )
}

export default FormularyPrice
