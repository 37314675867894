import React from "react"
import Logo from "components/Logo"

const DocumentationRequirementIcon = ({ isDocumentParachuteGenerated }) => {
  return (
    <div className="documentation-requirement-icon">
      <div className="bg-lightest-gray logo-box text-center">
        {!isDocumentParachuteGenerated ? (
          <i
            data-testid="Not Parachute Health Logo"
            className="far fa-file-alt v-align-bottom color-dark-gray"
          />
        ) : (
          <Logo />
        )}
      </div>
    </div>
  )
}

export default DocumentationRequirementIcon
