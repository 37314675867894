import React from "react"
import { CanopyComboboxField } from "@parachutehealth/canopy-combobox-field"
import { OptionItem } from "@parachutehealth/canopy-combobox"
import { transformOptionItems } from "../../utilities/form"

export type Props = {
  onChange?: (newValue: string | string[] | null) => void
  suppliers: { id: string | number; name: string }[]
  selectedValue?: string | number
  id?: string
  className?: string
  style?: React.CSSProperties
  disabled?: boolean
}

const SupplierAutocomplete: React.FC<Props> = (props: Props) => {
  const options = props.suppliers.map((supplier) => {
    return { label: supplier.name, value: supplier.id.toString() }
  })

  const onChangeWrapper = (newValue: OptionItem | OptionItem[] | null) => {
    if (props.onChange) props.onChange(transformOptionItems(newValue))
  }

  return (
    <CanopyComboboxField
      label="Supplier"
      id={props.id}
      disabled={props.disabled}
      className={props.className}
      data-testid="supplier-filter"
      options={options}
      onChange={onChangeWrapper}
      value={props.selectedValue?.toString()}
      size="small"
    />
  )
}

export default SupplierAutocomplete
