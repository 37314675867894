import { PushbackSignUpModalSource } from "./sharedTypes"

const EMAIL_SUBMITTED_EVENTS: Record<PushbackSignUpModalSource, string> = {
  SignUpButton: "sign_up_button_email_submitted_pushback",
  OrderSubmitted: "sign_up_modal_email_entered_pushback",
}

const MODAL_SEEN_EVENTS: Record<PushbackSignUpModalSource, string> = {
  SignUpButton: "sign_up_button_selected_pushback",
  OrderSubmitted: "sign_up_modal_seen_pushback",
}

export const emailSubmittedEvent = (source: PushbackSignUpModalSource) => {
  return EMAIL_SUBMITTED_EVENTS[source]
}

export const modalSeenEvent = (source: PushbackSignUpModalSource) => {
  return MODAL_SEEN_EVENTS[source]
}
