import React, { useState } from "react"
import Icon from "components/Icon"
import { createDocumentDownload, getDocumentDownload } from "../services/api"
import { navigate } from "utilities/navigation"
import classNames from "classnames"
import { EmployerType } from "utilities/url"
import { isEpicHyperdrive } from "utilities/browser/userAgent"

type Props = {
  orderId: string
  documentKey: string
  dmeOrderDocumentationRequirementId?: string
  label: string
  className?: string
  requiresTwoStepDownload: boolean
  disabled?: boolean
  employerType?: EmployerType
  employerId?: string
  onDownload?(): void
}

const DownloadLink = ({
  orderId,
  documentKey,
  dmeOrderDocumentationRequirementId,
  label,
  className,
  requiresTwoStepDownload,
  disabled,
  employerType,
  employerId,
  onDownload,
}: Props) => {
  const [showSpinner, setShowSpinner] = useState(false)
  const [downloadPath, setDownloadPath] = useState<string | undefined>(
    undefined
  )

  const requiresTwoStepDownloadWithPopup = () => {
    return requiresTwoStepDownload && isEpicHyperdrive()
  }
  const download = () => {
    if (downloadPath) {
      return
    }

    setShowSpinner(true)
    createDocumentDownload(
      orderId,
      documentKey,
      dmeOrderDocumentationRequirementId,
      employerType,
      employerId
    )
      .then((response) => {
        handleDownloadResponse(response.data)
      })
      .catch((error) => {
        downloadError()
        throw error
      })
    if (onDownload) {
      onDownload()
    }
  }

  const handleDownloadResponse = (data) => {
    if (data.status === "success") {
      if (!requiresTwoStepDownload) {
        navigate(data.documentDownloadPath)
      }
      setShowSpinner(false)
      setDownloadPath(
        requiresTwoStepDownload ? data.documentDownloadPath : undefined
      )
    } else if (data.status === "pending") {
      setTimeout(() => {
        checkDownload(data.id)
      }, 2000)
    } else {
      downloadError()
    }
  }

  const checkDownload = (downloadId) => {
    getDocumentDownload(downloadId, employerType, employerId)
      .then((response) => {
        handleDownloadResponse(response.data)
      })
      .catch((error) => {
        downloadError()
        throw error
      })
  }

  const downloadError = () => {
    setShowSpinner(false)
    alert(
      "Something went wrong. Please check that everything is valid and try again."
    )
  }

  const getLabel = () => {
    if (!requiresTwoStepDownload || downloadPath) {
      return `Download ${label}`
    }
    return `Prepare ${label} for Download`
  }

  const content = (
    <>
      <Icon
        type={showSpinner ? "spinner" : "download"}
        spin={showSpinner}
        className="canopy-mie-4x"
      />
      {getLabel()}
    </>
  )

  if (disabled) {
    return <a className={classNames(className, "disabled")}>{content}</a>
  }

  const target =
    downloadPath && requiresTwoStepDownloadWithPopup()
      ? { target: "_blank" }
      : {}

  return (
    <a
      className={className}
      onClick={download}
      href={downloadPath ?? undefined}
      {...target}
    >
      {content}
    </a>
  )
}

export default DownloadLink
