// @ts-strict-ignore
import React from "react"
import { Field } from "formik"
import classNames from "classnames"
import ErrorText from "components/form/ErrorText"
import { getFieldError } from "components/form/utilities"

enum Type {
  Basic,
  Block,
}

enum Size {
  Small,
  Medium,
}

type Props = {
  name: string
  label: React.ReactNode
  type?: Type
  size?: Size
  unwrapped?: boolean
  primary?: boolean
  labelClass?: string
  validate?(value): void
  onChange?(event): void
  selectAllCheckbox?: boolean
}

function Checkbox({
  name,
  label,
  type,
  size,
  unwrapped,
  primary,
  labelClass,
  validate,
  onChange,
}: Props) {
  const callbacks = {
    [Type.Block]: ({ field, form }) => (
      <div className="checkbox primary block">
        <input
          type="checkbox"
          id={name}
          checked={!!field.value}
          onChange={(event) => {
            onChange && onChange(event)
            form.setFieldValue(name, event.target.checked)
          }}
        />
        <label htmlFor={name}>{label}</label>
      </div>
    ),
    [Type.Basic]: ({ field, form }) => (
      <label
        htmlFor={name}
        className={classNames(
          `checkbox basic ${labelClass ? labelClass : ""}`,
          {
            small: size === Size.Small,
            "checkbox-brand": primary,
          }
        )}
      >
        {label}
        <input
          type="checkbox"
          id={name}
          checked={!!field.value}
          onChange={(event) => {
            onChange && onChange(event)
            form.setFieldValue(name, event.target.checked)
          }}
        />
        <span className="icon" />
      </label>
    ),
  }
  const renderError = (form) => {
    const { errors, touched } = form
    const error = getFieldError(errors, touched, name)
    return <ErrorText error={error} />
  }

  return (
    <div className={classNames({ "form-group": !unwrapped })}>
      <Field name={name} validate={validate}>
        {({ field, form }) => {
          return (
            <>
              {callbacks[type]({ field, form })}
              {renderError(form)}
            </>
          )
        }}
      </Field>
    </div>
  )
}

Checkbox.defaultProps = {
  type: Type.Block,
}

Checkbox.Type = Type
Checkbox.Size = Size

export default Checkbox
