// @ts-strict-ignore
import React, { useCallback, useEffect, useMemo, useState } from "react"
import {
  CarrierSearchSuggestion,
  InsuranceRank,
  InsuranceVerificationInterfaceStatus,
} from "../sharedTypes"
import {
  CanopyComboboxField,
  OptionItem,
} from "@parachutehealth/canopy-combobox-field"
import { CanopyFormFieldFeedbackMessageStatuses } from "@parachutehealth/canopy-form-field-group"
import { debounce, isString } from "lodash"

const insuranceVerificationFeedbackMessages = {
  [InsuranceVerificationInterfaceStatus.Verified]: "Verified",
  [InsuranceVerificationInterfaceStatus.VerifiedAndUpdated]:
    "Verified. Carrier updated with better match.",
  [InsuranceVerificationInterfaceStatus.Error]:
    "We encountered an error while processing the insurance", // Possibly triggered by a timeout
}

const insuranceVerificationFeedbackMessageStatuses = {
  [InsuranceVerificationInterfaceStatus.Verified]: "success",
  [InsuranceVerificationInterfaceStatus.VerifiedAndUpdated]: "success",
  [InsuranceVerificationInterfaceStatus.Error]: "error",
}

const feedbackStatuses = [
  InsuranceVerificationInterfaceStatus.Verified,
  InsuranceVerificationInterfaceStatus.VerifiedAndUpdated,
  InsuranceVerificationInterfaceStatus.Error,
]

type InsuranceCarrierSelectProps = {
  title: string
  rank: InsuranceRank
  carrier: CarrierSearchSuggestion
  onBlur?(): void
  onRemove?(): void
  fetch(term): Promise<any>
  onCarrierSelect(suggestion: CarrierSearchSuggestion): void
  focus: boolean
  status: InsuranceVerificationInterfaceStatus
  errorFromReviewPage: boolean
}

function InsuranceCarrierSelect({
  title,
  rank,
  carrier,
  onRemove,
  status,
  fetch,
  onCarrierSelect,
  focus,
  errorFromReviewPage,
}: InsuranceCarrierSelectProps) {
  const id = `${rank}-insurance-carrier`

  // STATE
  const [carrierValue, setCarrierValue] = useState(
    carrier?.name ? { label: carrier.name, value: carrier.externalId } : null
  )
  const [carrierOptions, setCarrierOptions] = useState(
    carrier?.name ? [carrierValue] : []
  )
  const [optionsLoading, setOptionsLoading] = useState(false)
  const [loadingText, setLoadingText] = useState("Loading...")
  const [carrierFeedbackMessage, setCarrierFeedbackMessage] = useState(
    errorFromReviewPage ? "Carrier is missing" : undefined
  )
  const [
    carrierFeedbackMessageStatus,
    setCarrierFeedbackMessageStatus,
  ] = useState<CanopyFormFieldFeedbackMessageStatuses>("error")
  const [comboboxDisabled, setComboboxDisabled] = useState(false)
  const [userGeneratedValue, setUserGeneratedValue] = useState("")
  const [fetchingOptions, setFetchingOptions] = useState(false)

  useEffect(() => {
    setCarrierValue(
      carrier?.name ? { label: carrier.name, value: carrier.externalId } : null
    )
  }, [carrier, setCarrierValue])

  // EVENT HANDLERS
  const displayVerifyingMessage = useCallback(() => {
    setOptionsLoading(true)
    setComboboxDisabled(true)
    setLoadingText("Verifying selected carrier...")
  }, [setOptionsLoading, setComboboxDisabled, setLoadingText])

  const displayPredictingMessage = useCallback(() => {
    setOptionsLoading(true)
    setComboboxDisabled(true)
    setLoadingText("Predicting carrier...")
  }, [setComboboxDisabled, setLoadingText])

  const debouncedFetch = useMemo(
    () =>
      debounce(async (query) => {
        const results = await fetch(query)
        const resultsLabels = results.map((result) => result.label)
        if (!resultsLabels.includes(query)) {
          // Append "Add new option" but only when name doesn't match something already in search results
          const addNewOption = {
            label: `+ Add "${query}" as Carrier`,
            value: query,
            selectedDisplayName: query,
          }
          setUserGeneratedValue(query)
          results.push(addNewOption)
        } else {
          setUserGeneratedValue("")
        }
        setCarrierOptions(results)
        setOptionsLoading(false)
        setFetchingOptions(false)
      }, 300),
    [fetch]
  )

  const handleInputChange = useCallback(
    (newValue: string) => {
      setOptionsLoading(true)
      setLoadingText("Searching for carriers...")
      if (newValue.length <= 0) {
        setOptionsLoading(false)
        setCarrierOptions([])
      } else {
        setFetchingOptions(true)
        debouncedFetch(newValue)
      }
    },
    [setOptionsLoading, setLoadingText, debouncedFetch]
  )

  const handleSelectionChange = useCallback(
    (newValue: OptionItem) => {
      if (newValue && isString(newValue.value)) {
        // The isString check is required because Combobox's onChange can return an array of values if multi-select is enabled
        // A value was chosen
        // This triggers carrier verification, while this is happening show a loading state and a corresponding message
        displayVerifyingMessage()
        const selectedCarrier = {
          name: newValue.label,
          externalId: newValue.value,
        }
        if (newValue.value === userGeneratedValue) {
          selectedCarrier.name = userGeneratedValue
          selectedCarrier.externalId = undefined
        }
        onCarrierSelect(selectedCarrier)
      } else {
        // newValue is undefined, the value was cleared
        onRemove()
        setCarrierOptions([]) // Reset carrier options
      }
    },
    [
      userGeneratedValue,
      onCarrierSelect,
      onRemove,
      setCarrierOptions,
      displayVerifyingMessage,
    ]
  )

  const formatSelectedValue = useCallback((option) => {
    if (option && option.selectedDisplayName) {
      // If a user-generated option was selected, use the name the user typed in
      return option.selectedDisplayName as string
    } else if (option) {
      // Otherwise if an option was selected, use its label
      return option.label as string
    } else {
      // Otherwise (the field has been cleared), display nothing
      return ""
    }
  }, [])

  // EFFECTS
  useEffect(() => {
    let feedbackMessage = undefined // Reset the feedback message if the status doesn't require feedback
    let feedbackMessageStatus: CanopyFormFieldFeedbackMessageStatuses = "error" // Reset the feedback message status, defaulting to 'error'

    if (feedbackStatuses.includes(status)) {
      // If the current status requires error/success feedback, save that in state
      feedbackMessage = insuranceVerificationFeedbackMessages[status]
      feedbackMessageStatus = insuranceVerificationFeedbackMessageStatuses[
        status
      ] as CanopyFormFieldFeedbackMessageStatuses
    }
    if (!errorFromReviewPage) {
      // If the user is returning to this page due to an error on the review screen, don't override the default error message
      setCarrierFeedbackMessage(feedbackMessage)
      setCarrierFeedbackMessageStatus(feedbackMessageStatus)
    }

    if (status === InsuranceVerificationInterfaceStatus.Predicting) {
      displayPredictingMessage()
    } else if (status === InsuranceVerificationInterfaceStatus.Verifying) {
      displayVerifyingMessage()
    } else if (!fetchingOptions) {
      setOptionsLoading(false) // Reset loading spinner in CanopyCombobox when a verification status is returned
      setComboboxDisabled(false) // Enable combobox so it's interactive again
    }
  }, [
    status,
    errorFromReviewPage,
    displayVerifyingMessage,
    displayPredictingMessage,
    fetchingOptions,
  ])

  return (
    <>
      <CanopyComboboxField
        value={carrierValue}
        disabled={comboboxDisabled}
        feedbackMessage={carrierFeedbackMessage}
        feedbackMessageStatus={carrierFeedbackMessageStatus}
        id={id}
        inputProps={{ autoFocus: focus }}
        label={title}
        loading={optionsLoading}
        loadingText={loadingText}
        noOptionsAvailableMessage="Begin typing to search for a carrier..."
        onChange={handleSelectionChange}
        onInputChange={handleInputChange}
        options={carrierOptions}
        optionSelectedFormatFunction={formatSelectedValue}
        placeholder="Enter insurance carrier"
      />
    </>
  )
}

export default InsuranceCarrierSelect
