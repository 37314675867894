import { put, get } from "services/api"
import {
  icd10CodesUrl,
  searchIcd10CodesUrl,
  dmeOrderDiagnosisUrl,
} from "applications/Workflow/urls"

export const syncIcd10Codes = (codes) => {
  return put(icd10CodesUrl(), {
    icd10_codes: codes.map(({ code }) => code),
  }).then(({ data }) => data)
}

export const searchIcd10Codes = ({ value }) => {
  return get(searchIcd10CodesUrl(), { search: value })
}

export const getDmeOrderDiagnosis = () => {
  return get(dmeOrderDiagnosisUrl())
}
