import React, { Dispatch, SetStateAction } from "react"
import SelectFilter from "components/SelectFilter"
import { states } from "utilities/address"
import { EventCategory, trackEvent } from "utilities/tracking"

interface Props {
  defaultServiceAreaStates: string[]
  setSelectedServiceAreaStates: Dispatch<SetStateAction<string[]>>
  selectedServiceAreaStates: string[]
}

const ServiceAreaStateFilter = ({
  defaultServiceAreaStates,
  setSelectedServiceAreaStates,
  selectedServiceAreaStates,
}: Props) => {
  const stateLabel = (stateAbbreviation) =>
    defaultServiceAreaStates.includes(stateAbbreviation)
      ? `${stateAbbreviation} (default)`
      : stateAbbreviation

  const stateOptions = states.map(
    ([, stateAbbreviation]: [string, string]) => ({
      label: stateLabel(stateAbbreviation),
      value: stateAbbreviation,
    })
  )

  const eventCategory = EventCategory.AnySupplier
  const onChange = async ({
    serviceAreaStates,
  }: {
    serviceAreaStates: string[]
  }) => {
    trackEvent(eventCategory, "service-area-changed")
    await setSelectedServiceAreaStates(serviceAreaStates)
  }

  const onToggle = (value: boolean) => {
    if (value) {
      trackEvent(eventCategory, "service-area-clicked")
    }
  }

  return (
    <SelectFilter
      initialValues={selectedServiceAreaStates}
      label="Service Areas"
      name="serviceAreaStates"
      onChange={onChange}
      onToggle={onToggle}
      options={stateOptions}
      enableReinitialize
    />
  )
}

export default ServiceAreaStateFilter
