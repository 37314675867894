// @ts-strict-ignore
import React, { useState } from "react"
import { RouteComponentProps } from "react-router"
import FacilityForm from "./FacilityForm"
import FacilitySearch from "./FacilitySearch"
import { Redirect, Route } from "react-router-dom"
import {
  UpdateFacilityResponse,
  FacilitySearchResult,
  FacilityUpdateParams,
  FacilityFormParams,
  FacilityDetails,
} from "../sharedTypes"
import RequestSent from "./RequestSent"
import { navigate } from "utilities/navigation"
import { withHashRouter } from "routers/HashRouter"
import { facilityDashboardUrl } from "applications/FacilitySelection/urls"
import eventEmitter from "utilities/eventEmitter"
import { User } from "../../FacilitySignUp/shared/sharedTypes"
import {
  trackCreateFacility,
  trackJoinFacilityAfterReviewingDetails,
} from "../../FacilitySignUp/shared/tracking"

type Props = {
  defaultFacility?: FacilityDetails
  fetchFacilityDetails(
    externalId?: string,
    npi?: string
  ): Promise<FacilityDetails>
  updateFacility(params: FacilityUpdateParams): Promise<UpdateFacilityResponse>
  search(term: string): Promise<FacilitySearchResult[]>
  createFacility(params: FacilityFormParams): Promise<FacilityDetails>
  user: User
  reviewerDashboardDoctorId?: string
} & RouteComponentProps

enum PathName {
  Create = "create-facility",
  RequestSent = "request-sent",
  Confirm = "confirm-facility",
}
const pathFor = (pathName: PathName) => `/${pathName}`

const InternalFacilitySelection = (props: Props) => {
  const {
    defaultFacility,
    fetchFacilityDetails,
    search,
    history,
    user,
    reviewerDashboardDoctorId,
  } = props
  const [dmeOrderUrl, setDmeOrderUrl] = useState(null)
  const [selectedFacility, setSelectedFacility] = useState<FacilityDetails>(
    defaultFacility
  )
  const updateFacility = (params: FacilityUpdateParams) => {
    return props.updateFacility(params).then(({ redirectUrl, dmeOrderUrl }) => {
      if (redirectUrl) {
        navigate(redirectUrl)
      } else {
        setDmeOrderUrl(dmeOrderUrl)
        history.push({ pathname: PathName.RequestSent })
      }
    })
  }

  const createFacilityWithDetails = (params: FacilityFormParams) => {
    return props
      .createFacility(params)
      .then((facilityDetails: FacilityDetails) =>
        navigate(facilityDashboardUrl(facilityDetails.externalId))
      )
  }

  const navigateToFacilitySearch = () => {
    setSelectedFacility(null)
    history.push({ pathname: "/" })
  }

  const trackFacilityJoin = () => {
    trackJoinFacilityAfterReviewingDetails(user)
  }

  const trackFacilityCreate = () => {
    trackCreateFacility(user)
  }

  return (
    <>
      <Route exact path="/">
        <FacilitySearch
          defaultFacility={defaultFacility}
          selectedFacility={selectedFacility}
          setSelectedFacility={setSelectedFacility}
          fetchFacilityDetails={fetchFacilityDetails}
          updateFacility={updateFacility}
          search={search}
          navigateToFacilityCreate={() =>
            history.push({ pathname: PathName.Create })
          }
          navigateToFacilityConfirm={() =>
            history.push({ pathname: PathName.Confirm })
          }
          user={user}
        />
      </Route>
      <Route exact path={pathFor(PathName.Create)}>
        <FacilityForm
          titleText="Create healthcare facility"
          subtitleText="Enter details to create your healthcare facility (hospital, clinic, nursing home, etc)"
          submitText="Create Facility"
          onSubmit={createFacilityWithDetails}
          onBackButtonClick={navigateToFacilitySearch}
          trackOnSubmit={trackFacilityCreate}
        />
      </Route>
      <Route exact path={pathFor(PathName.RequestSent)}>
        {selectedFacility ? (
          <RequestSent
            dmeOrderUrl={dmeOrderUrl}
            selectedFacility={selectedFacility}
            onChatLinkClick={() => {
              eventEmitter.emit("chat-link:clicked")
            }}
            linkBackToReviewDashboardDoctorId={reviewerDashboardDoctorId}
          />
        ) : (
          <Redirect to="/" />
        )}
      </Route>
      <Route exact path={pathFor(PathName.Confirm)}>
        {selectedFacility ? (
          <FacilityForm
            titleText="Review details and join facility"
            subtitleText="Complete and confirm facility details to join this facility on Parachute Health."
            submitText="Join Facility"
            onSubmit={createFacilityWithDetails}
            onBackButtonClick={navigateToFacilitySearch}
            selectedFacility={selectedFacility}
            trackOnSubmit={trackFacilityJoin}
          />
        ) : (
          <Redirect to="/" />
        )}
      </Route>
    </>
  )
}

export default withHashRouter(InternalFacilitySelection)
