import { DmeOrderSubmissionWarningsEnum } from "graphql/__generated__/graphql"
import {
  PRODUCT_RELATED_WARNING_DEPENDENTS_FOR_REQUIRED_ACTIONS,
  ORDERED_STEPS,
  StepId,
  stepIdToStepTitle,
} from "applications/Workflow/utilities/steps"
import type { CanopyIconNames } from "@parachutehealth/canopy-icons/build/canopyIconGlyphNames"
import { warningToStepMap } from "applications/IncomingOrders/utilities/actionColumnLinkText"
import {
  canopySpace2X,
  canopySpace4X,
} from "@parachutehealth/canopy-tokens-space"
import { CanopyIcon } from "@parachutehealth/canopy-icon"
import * as styles from "applications/IncomingOrders/components/ActionColumnCell/ActionColumnStyles.module.scss"
import React from "react"
import ActionColumnPillTooltip from "applications/IncomingOrders/components/ActionColumnCell/ActionColumnTooltip"
import flatten from "lodash/flatten"

interface Props {
  submissionWarnings: { key: DmeOrderSubmissionWarningsEnum }[]
}

const ActionColumnPill: React.FC<Props> = ({ submissionWarnings }) => {
  const stepToIconMap: {
    [key in StepId]: CanopyIconNames
  } = {
    [StepId.PAYMENT_STEP]: "address-card",
    [StepId.PRODUCTS_STEP]: "cart-shopping",
    [StepId.DELIVERY_STEP]: "user",
    [StepId.CLINICAL_STEP]: "file-lines",
    [StepId.SIGNATURE_STEP]: "marker",
    [StepId.REVIEW_STEP]: "arrow-right",
  }

  const steps = () => {
    const warningHasDependentSteps = (
      warning: DmeOrderSubmissionWarningsEnum
    ): boolean =>
      warning === DmeOrderSubmissionWarningsEnum.Products ||
      warning === DmeOrderSubmissionWarningsEnum.ProductRequirements

    const stepsWithDependents = submissionWarnings.map((warning) => {
      if (warningHasDependentSteps(warning.key)) {
        return [StepId.PRODUCTS_STEP].concat(
          PRODUCT_RELATED_WARNING_DEPENDENTS_FOR_REQUIRED_ACTIONS
        )
      } else {
        return warningToStepMap[warning.key]
      }
    })

    return flatten(stepsWithDependents)
  }
  const noWarnings = submissionWarnings.length === 0

  const actionColumnIcon = (): CanopyIconNames => {
    if (noWarnings) return "arrow-right"
    const currentStep = ORDERED_STEPS.find((orderedStep) =>
      steps().includes(orderedStep)
    )

    return currentStep ? stepToIconMap[currentStep] : "triangle-exclamation"
  }

  const additionalSteps = () => {
    const filteredAndOrderedSteps = ORDERED_STEPS.filter((orderedStep) =>
      steps().includes(orderedStep)
    ).slice(1)

    return filteredAndOrderedSteps.map((step) => {
      const title = stepIdToStepTitle(step)
      return title.charAt(0).toUpperCase() + title.slice(1).toLowerCase()
    })
  }

  const pillClassNames = noWarnings ? "pill pill-success" : "pill pill-warning"
  const iconColor = noWarnings
    ? "canopyColorTextSuccess"
    : "canopyColorPrimitivesYellow53"

  return (
    <ActionColumnPillTooltip remainingSteps={additionalSteps()}>
      <div
        className={pillClassNames}
        style={{
          marginBlockEnd: 0,
          marginInlineStart: canopySpace4X,
          paddingInline: canopySpace2X,
          paddingBlockEnd: 0,
          verticalAlign: "middle",
        }}
      >
        <CanopyIcon
          fill={iconColor}
          name={actionColumnIcon()}
          data-testid={`action-column-pill-${actionColumnIcon()}`}
        />
        {additionalSteps().length > 0 ? (
          <span className={styles.pillText}>+{additionalSteps().length}</span>
        ) : null}
      </div>
    </ActionColumnPillTooltip>
  )
}

export default ActionColumnPill
