export const PASSWORD_HINT =
  "Password must be at least 8 characters and contain at least one capital letter, one lowercase letter and one number."

export const validate = ({ password, passwordConfirmation }) => {
  const errors: {
    password?: string
    passwordConfirmation?: string
  } = {}

  if (!password) {
    errors.password = "Enter password"
  }
  if (!passwordConfirmation) {
    errors.passwordConfirmation = "Enter password confirmation"
  }
  if (password !== passwordConfirmation) {
    errors.passwordConfirmation = "Password confirmation must match password"
  }
  return errors
}

export const humanizeErrors = (errors) => {
  if (errors.password) {
    errors.password = errors.password.map((error) => "Password " + error)
  }
  return errors
}
