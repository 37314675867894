import React from "react"
import { Form, TextField, Select, EmailField } from "components/form"
import Button from "components/form/Button"
import { isEmailValid } from "utilities/email"
import { Supplier } from "../../../shared/sharedTypes"
import { Option as SelectOption } from "components/form/Select"

interface Props {
  createUser(params): Promise<void>
  getSuppliers(term: string): Promise<Supplier[]>
  onSupplierOptionChange(supplierOption: SelectOption): void
  supplier?: SelectOption
  salesRepEmail?: string
}

function InviteReferralForm({
  createUser,
  getSuppliers,
  onSupplierOptionChange,
  supplier,
  salesRepEmail,
}: Props) {
  const onSubmit = (params, { setErrors }) => {
    const { firstName, lastName, email, supplierId, salesRepEmail } = params
    return createUser({
      firstName,
      lastName,
      email,
      supplierId,
      salesRepEmail,
    }).catch(({ errors }) => {
      if (errors.user) {
        setErrors(errors.user)
      }
    })
  }

  const validate = ({
    email,
    firstName,
    lastName,
    supplierId,
    salesRepEmail,
  }) => {
    const errors: {
      email?: string
      firstName?: string
      lastName?: string
      supplierId?: string
      salesRepEmail?: string
    } = {}

    if (!isEmailValid(email)) {
      errors.email = "Enter email"
    }

    if (!firstName) {
      errors.firstName = "Enter first name"
    }
    if (!lastName) {
      errors.lastName = "Enter last name"
    }
    if (!supplierId) {
      errors.supplierId = "Select supplier"
    }
    if (salesRepEmail && !isEmailValid(salesRepEmail)) {
      errors.salesRepEmail = "Enter email"
    }
    return errors
  }

  const initialValues = {
    firstName: "",
    lastName: "",
    email: "",
    supplierId: supplier?.value || "",
    salesRepEmail: salesRepEmail || "",
  }

  return (
    <>
      <h1 className="canopy-mbe-24x">Invite a referral</h1>
      <Form
        initialValues={initialValues}
        onSubmit={onSubmit}
        validate={validate}
      >
        {({ isSubmitting }) => (
          <>
            <EmailField label="Referral email" name="email" autoFocus />
            <div className="row">
              <div className="col-md-6">
                <TextField label="Referral first name" name="firstName" />
              </div>
              <div className="col-md-6">
                <TextField label="Referral last name" name="lastName" />
              </div>
            </div>
            <Select
              label="Referring supplier"
              name="supplierId"
              isSearchable
              isClearable
              minLength={2}
              onChange={(newValue, supplierOption) => {
                onSupplierOptionChange(supplierOption)
              }}
              options={supplier ? [supplier] : []}
              fetchOptions={(term: string) =>
                getSuppliers(term).then((suppliers) => {
                  return suppliers.map((supplier) => ({
                    value: supplier.externalId,
                    label: supplier.name,
                  }))
                })
              }
            />
            <EmailField
              label="Referring sales rep email (Optional)"
              name="salesRepEmail"
            />
            <Button
              disabled={isSubmitting}
              className="btn-brand btn-block"
              type="submit"
            >
              Submit
            </Button>
          </>
        )}
      </Form>
    </>
  )
}

export default InviteReferralForm
