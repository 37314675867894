import { DocumentationRowProps } from "./documentation-row-props"
import { DocumentationRequirementSatisfyMethod } from "sharedTypes"
import Button from "components/form/Button"
import { Link } from "react-router-dom"
import { EventCategory, trackEvent } from "utilities/tracking"
import { chartNotePath } from "../../../../../routes"
import { slugify } from "utilities/string"
import { ToggleSatisfyMethod } from "./ToggleSatisfyMethod"
import React from "react"

const EVENT_PREFIX = "documentation-requirement-edit"

type EditDocumentationLinkProps = Pick<
  DocumentationRowProps,
  "bounceToSection" | "setShowIcd10Error"
> & {
  itemId: string
  linkPath: string
  satisfyStatus: string
}
export const EditDocumentationLink = (props: EditDocumentationLinkProps) => {
  const {
    itemId,
    linkPath,
    bounceToSection,
    satisfyStatus,
    setShowIcd10Error,
  } = props
  const { shouldBounce, triggerBounce } = bounceToSection || {}

  const handleClick = () => {
    setShowIcd10Error && setShowIcd10Error(true)
    triggerBounce()
  }

  return shouldBounce ? (
    <Button
      className="link canopy-mis-4x canopy-pie-4x font-subparagraph float-right"
      onClick={handleClick}
      data-test-id={`${itemId}-edit-button`}
    >
      Edit
    </Button>
  ) : (
    <Link
      className="link canopy-mx-4x font-subparagraph float-right"
      onClick={() => {
        trackEvent(EventCategory.Activation, `${EVENT_PREFIX}-${satisfyStatus}`)
      }}
      to={linkPath}
      data-test-id={`${itemId}-edit-link`}
    >
      Edit
    </Link>
  )
}

type GenerateDetailsProps = Pick<
  DocumentationRowProps,
  | "chartNote"
  | "permissions"
  | "missingChartNotesPrerequisites"
  | "documentationRequirement"
  | "bounceToSection"
  | "setShowIcd10Error"
>

export const GenerateDetails = (props: GenerateDetailsProps) => {
  const {
    chartNote,
    documentationRequirement,
    permissions,
    missingChartNotesPrerequisites,
  } = props
  const chartNoteEditable =
    chartNote && permissions.updateChartNotes && !missingChartNotesPrerequisites

  const isDocumentParachuteGenerated =
    documentationRequirement.satisfyMethod ===
    DocumentationRequirementSatisfyMethod.Generate
  const showEditChartNoteLink =
    chartNoteEditable && isDocumentParachuteGenerated

  return (
    <div className="row">
      <div className={showEditChartNoteLink ? "col-9" : "col-12"}>
        {!documentationRequirement.chartNotesRequired && (
          <div className="color-dark-gray font-subparagraph">
            Parachute creates the script for you
          </div>
        )}
        <ToggleSatisfyMethod {...props} />
      </div>
      {showEditChartNoteLink && (
        <div className="col-3">
          <EditDocumentationLink
            {...props}
            itemId={chartNote.id}
            linkPath={chartNotePath(slugify(chartNote.name), chartNote.id)}
            satisfyStatus={documentationRequirement.satisfyStatus}
          />
        </div>
      )}
    </div>
  )
}
