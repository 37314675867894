import React from "react"
import { SvgIcon } from "@material-ui/core"

export default (props: React.ComponentProps<typeof SvgIcon>) => (
  <SvgIcon {...props} viewBox="0 0 32 28">
    <g>
      <rect fill="none" height="24" width="24" />
    </g>
    <g>
      <g>
        <path d="M3.59146 2.95732C4.02439 1.21951 5.58537 -9.53674e-07 7.37805 -9.53674e-07H23.8415C25.6341 -9.53674e-07 27.1951 1.21951 27.628 2.95732L31.1037 16.8537C31.1829 17.1646 31.2195 17.4817 31.2195 17.7988V23.4146C31.2195 25.5671 29.4695 27.3171 27.3171 27.3171H3.90244C1.75 27.3171 0 25.5671 0 23.4146V17.7988C0 17.4817 0.0365854 17.1646 0.115854 16.8537L3.59146 2.95732ZM23.8415 3.90244H7.37805L4.45122 15.6098H7.57317C8.31098 15.6098 8.98781 16.0244 9.31707 16.689L10.189 18.4329C10.5183 19.0915 11.1951 19.5122 11.9329 19.5122H19.2805C20.0183 19.5122 20.6951 19.0976 21.0244 18.4329L21.8963 16.689C22.2256 16.0305 22.9024 15.6098 23.6402 15.6098H26.7683L23.8415 3.90244ZM10.7317 6.82927H20.4878C21.0244 6.82927 21.4634 7.26829 21.4634 7.80488C21.4634 8.34146 21.0244 8.78049 20.4878 8.78049H10.7317C10.1951 8.78049 9.7561 8.34146 9.7561 7.80488C9.7561 7.26829 10.1951 6.82927 10.7317 6.82927ZM8.78049 11.7073H22.439C22.9756 11.7073 23.4146 12.1463 23.4146 12.6829C23.4146 13.2195 22.9756 13.6585 22.439 13.6585H8.78049C8.2439 13.6585 7.80488 13.2195 7.80488 12.6829C7.80488 12.1463 8.2439 11.7073 8.78049 11.7073Z" />
      </g>
    </g>
  </SvgIcon>
)
