import React from "react"
import SingleViewSupplierList from "../SingleViewSupplierList/SingleViewSupplierList"

interface Props {
  manageFulfillmentAgreements: boolean
  usesEnterpriseFeatures: boolean
}

const InternalSupplierList = ({
  manageFulfillmentAgreements,
  usesEnterpriseFeatures,
}: Props) => {
  return (
    <SingleViewSupplierList
      manageFulfillmentAgreements={manageFulfillmentAgreements}
      usesEnterpriseFeatures={usesEnterpriseFeatures}
    />
  )
}

export default InternalSupplierList
