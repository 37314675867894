import React from "react"
import * as styles from "../index.module.scss"

interface Props {
  text: string
}

const CardOverline: React.FC<Props> = ({ text }) => (
  <h6 className={styles.overline}>{text}</h6>
)

export default CardOverline
