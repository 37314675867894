import React from "react"
import * as styles from "./index.module.scss"
import userIcon from "icon-user.svg"
import letterIcon from "icon-letter.svg"

interface Props {
  onClick(): void
  icon: "letter" | "user"
  children: React.ReactNode
}

function Card({ onClick, icon, children }: Props) {
  return (
    <div className={styles.container}>
      <a className={styles.card} onClick={onClick}>
        <div className={styles.circle}>
          {icon === "user" && <img src={userIcon} />}
          {icon === "letter" && <img src={letterIcon} />}
        </div>
        <div className={styles.content}>{children}</div>
      </a>
    </div>
  )
}

export default Card
