// extracted by mini-css-extract-plugin
var _1 = "app-javascript-applications-PatientPortalMessages-index-module__add_variable_button--RoKaN";
var _2 = "app-javascript-applications-PatientPortalMessages-index-module__container--ilmTw";
var _3 = "app-javascript-applications-PatientPortalMessages-index-module__tab--GCO8b";
var _4 = "app-javascript-applications-PatientPortalMessages-index-module__tabActive--hbLIg";
var _5 = "app-javascript-applications-PatientPortalMessages-index-module__tabContainer--cvfuf";
var _6 = "app-javascript-applications-PatientPortalMessages-index-module__tabList--bG7oI";
var _7 = "app-javascript-applications-PatientPortalMessages-index-module__table--KxXdu";
var _8 = "app-javascript-applications-PatientPortalMessages-index-module__template-var--qky5K";
export { _1 as "addVariableButton", _2 as "container", _3 as "tab", _4 as "tabActive", _5 as "tabContainer", _6 as "tabList", _7 as "table", _8 as "templateVar" }
