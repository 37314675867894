import React from "react"
import { DmeOrder, Address, Fulfillment as FulfillmentType } from "sharedTypes"
import { FormikValues } from "formik"
import Fulfillment from "./Fulfillment"

type Props = {
  dmeOrder: DmeOrder
  fulfillments: FulfillmentType[]
  updateDelivery(fulfillmenId: string, values: FormikValues): Promise<void>
  updateDeliveryAddress(
    deliveryId: string,
    dmeOrderFulfillment: FormikValues
  ): Promise<void>
  addNewAddress(fulfillmentId: string): void
  editAddress(address: Address): void
}

export default (props: Props) => {
  const {
    dmeOrder,
    fulfillments,
    updateDelivery,
    updateDeliveryAddress,
    addNewAddress,
    editAddress,
  } = props

  const disallowAddressInlineEditing =
    fulfillments.filter((fulfillment) => !fulfillment.isConsignmentCloset)
      .length > 1
  return (
    <>
      {fulfillments.map((fulfillment) => (
        <Fulfillment
          key={fulfillment.id}
          fulfillment={fulfillment}
          disallowAddressInlineEditing={disallowAddressInlineEditing}
          dmeOrder={dmeOrder}
          updateDelivery={updateDelivery}
          updateDeliveryAddress={updateDeliveryAddress}
          addNewAddress={addNewAddress}
          editAddress={editAddress}
        />
      ))}
    </>
  )
}
