import React, { useEffect, Dispatch, SetStateAction } from "react"
import MessageForm from "./components/MessageForm"
import Messages from "./components/Messages"
import ChatUnavailable from "./components/ChatUnavailable"
import ChatDisabled from "./components/ChatDisabled"
import { PatientPortalEndpoint } from "services/patientPortal"
import {
  useSupplierChatTemplatesQuery,
  useChatQuery,
  useChatSubscription,
} from "./api"
import { interpolateTemplates } from "./utilities/templateInterpolator"
import { useFeatureFlags } from "components/FeatureFlagContext"

type Props = {
  dmeOrderId: string
  chatId: string
  chatAvailable: boolean
  patientPortalEndpoint: PatientPortalEndpoint
  setUnreadPatientMessages: Dispatch<SetStateAction<boolean>>
  supplierName: string
  portalMessagingSupplierEnabled: boolean
}

const PatientPortal: React.FC<Props> = ({
  dmeOrderId,
  chatId,
  chatAvailable,
  patientPortalEndpoint,
  setUnreadPatientMessages,
  supplierName,
  portalMessagingSupplierEnabled,
}) => {
  const { isFeatureEnabled } = useFeatureFlags()

  const { data: supplierChatTemplates } = useSupplierChatTemplatesQuery(
    dmeOrderId
  )

  const { data: chat } = useChatQuery(chatId)

  useChatSubscription(chatId, chat?.token)

  useEffect(() => {
    setUnreadPatientMessages(false)
  }, [chatId, chat?.token, setUnreadPatientMessages])

  const onSubmit = (params, { resetForm }) => {
    return patientPortalEndpoint.sendMessage(params).then(() => {
      resetForm()
    })
  }

  if (!supplierChatTemplates || !chat) {
    return null
  }

  const { messages, patient } = chat

  const chatTemplates = interpolateTemplates(supplierChatTemplates, {
    supplierName,
    patientFirstName: patient.firstName,
    patientLastName: patient.lastName,
  })

  if (chatAvailable) {
    return (
      <>
        <MessageForm
          chatId={chatId}
          chatTemplates={chatTemplates}
          patientConsentsToSms={patient.consentsToSms}
          patientOptedOutAt={patient.optedOutAt}
          onSubmit={onSubmit}
        />
        <Messages
          messages={messages}
          chat={{ patient }}
          insuranceUploadUrl={patientPortalEndpoint.insuranceUploadUrl}
        />
      </>
    )
  } else if (
    isFeatureEnabled("chatCommercialization") &&
    !portalMessagingSupplierEnabled
  ) {
    return <ChatDisabled supplierName={supplierName} />
  } else {
    return <ChatUnavailable />
  }
}
export default PatientPortal
