import React, { useCallback, useContext, useEffect, useState } from "react"
import { DocumentationRequirement } from "sharedTypes"
import { PdfViewer } from "./PdfViewer"
import {
  documentationRequirementPdfDataUrl,
  updateDocumentationRequirementPdfDataUrl,
} from "./urls"
import WorkflowPageContext from "context/WorkflowPage"
import { SidebarTab } from "applications/Workflow/containers/Navigation/sharedTypes"
import { PageHeader, PdfSavingState } from "./PageHeader"
import { markDocumentationRequirementAsComplete } from "./api"
import * as styles from "./styles.module.scss"
import axios from "axios"
import { getAuthorizationHeaders } from "services/api"
import { SaveDocumentCallback } from "./PdfViewer/viewSDKClient"

type Props = {
  refreshDmeOrder(): Promise<void>
  documentationRequirement: DocumentationRequirement
  nextPagePath: string | undefined
}

export const FillablePdfDocumentationRequirement = ({
  refreshDmeOrder,
  documentationRequirement,
  nextPagePath,
}: Props) => {
  const { setSidebarTab } = useContext(WorkflowPageContext)
  useEffect(() => {
    setSidebarTab(SidebarTab.Closed)
  }, [setSidebarTab])
  const [savingState, setSavingState] = useState<PdfSavingState>("unsaved")

  useEffect(() => {
    setSavingState("unsaved")
  }, [documentationRequirement.externalId])

  const saveDocument = useCallback<SaveDocumentCallback>(
    (data) => {
      setSavingState("saving")
      return axios({
        method: "put",
        url: updateDocumentationRequirementPdfDataUrl(
          documentationRequirement.externalId
        ),
        data: data,
        headers: {
          "Content-Type": "application/pdf",
          ...getAuthorizationHeaders(),
        },
      })
        .then(() => {
          setSavingState("saved")
        })
        .catch((e) => {
          setSavingState("unsaved")
          throw e
        })
    },
    [documentationRequirement.externalId]
  )
  return (
    <div className={styles.fullHeightContainer}>
      <PageHeader
        savingState={savingState}
        documentationRequirement={documentationRequirement}
        markComplete={() =>
          markDocumentationRequirementAsComplete(
            documentationRequirement.externalId
          )
        }
        refreshDmeOrder={refreshDmeOrder}
        nextPagePath={nextPagePath}
      />
      <PdfViewer
        documentUrl={documentationRequirementPdfDataUrl(
          documentationRequirement.externalId
        )}
        saveDocument={saveDocument}
      />
    </div>
  )
}
