import { employerPrefix } from "../../utilities/url"

export const makeUrlProvider = (supplierId) => {
  const urlPrefix = employerPrefix("Supplier", supplierId)
  return {
    authorizationsUrl: `${urlPrefix}/authorizations.json`,
    assigneesUrl: (authorizationId) =>
      `${urlPrefix}/authorizations/${authorizationId}/assignees.json`,
    getAssigneesUrl: () => `${urlPrefix}/assignees.json`,
    authorizationStatusUrl: (authorizationId) =>
      `${urlPrefix}/authorizations/${authorizationId}/statuses.json`,
    searchMembersUrl: `${urlPrefix}/authorizations/search_member.json`,
    carrierAuthorizationNumbersUrl: `${urlPrefix}/authorizations/search_carrier_authorization_numbers.json`,
    authorizationUrl: (id) => `${urlPrefix}/authorizations/${id}`,
    dashboardUrl: urlPrefix,
    initialDashboardDataUrl: `${urlPrefix}/authorizations/initial_dashboard_data.json`,
  }
}
