// @team @clinical-intelligence

import { put } from "services/api"
import {
  questionnaireResponseAnswerUrl,
  startQuestionnaireResponseUrl,
} from "applications/Workflow/urls"
import {
  SurveyQuestion,
  SurveyAnswerValue,
  SurveyAnswerType,
} from "sharedTypes"

export function answerQuestion(
  questionnaireResponseId: string,
  question: SurveyQuestion,
  answerType: SurveyAnswerType,
  answerValue: SurveyAnswerValue
) {
  return put(
    questionnaireResponseAnswerUrl(
      questionnaireResponseId,
      question.questionId
    ),
    {
      answerType,
      answerValue,
    }
  )
}

export const startQuestionnaireResponse = (questionnaireResponseId: string) => {
  return put(startQuestionnaireResponseUrl(questionnaireResponseId)).then(
    ({ data }) => data
  )
}
