import { easeInOutQuad } from "./easing"

function getOffsetTop(element) {
  let offset = 0
  while (element) {
    offset += element.offsetTop
    element = element.offsetParent
  }
  return offset
}

function scrollBody(x: number, y: number) {
  window.document.body.scrollLeft = x
  window.document.body.scrollTop = y
}

function scroll(x: number, y: number) {
  // https://caniuse.com/#search=scroll
  if (typeof window.scroll === "function") {
    try {
      window.scroll({ behavior: "smooth", left: x, top: y })
    } catch (exception) {
      scrollBody(x, y)
    }
  } else {
    scrollBody(x, y)
  }
}

function animatedScrollTo(target, offsetTop: number, duration: number) {
  const start = target.scrollTop,
    change = offsetTop - start,
    timeout = 16.66
  let currentTime = 0

  const animatedScroll = function () {
    currentTime += timeout
    const currentOffset = easeInOutQuad(currentTime, start, change, duration)
    target.scrollTop = currentOffset
    if (currentTime < duration) {
      setTimeout(animatedScroll, timeout)
    }
  }
  animatedScroll()
}

export function getWindowOffsetTop() {
  return window.pageYOffset || window.scrollY || 0
}

export function scrollTo(element, offset = 0) {
  const totalOffset = getOffsetTop(element) + offset
  scroll(0, totalOffset)
}

export function scrollTop() {
  animatedScrollTo(document.documentElement, 0, 200)
}
