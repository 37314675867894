import React from "react"
import Icon from "components/Icon"

interface Props {
  onClick(): void
}

function RemoveButton({ onClick }: Props) {
  return (
    <a className="link link-danger canopy-mis-8x" onClick={onClick}>
      <Icon type="trash-alt" prefix="fas" className="canopy-mie-4x" />
      <small>Remove View</small>
    </a>
  )
}

export default RemoveButton
