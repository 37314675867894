import { get } from "services/api"
import * as urls from "../../urls"
import { Assignee, Doctor } from "sharedTypes"
import {
  ClinicalFacilitySearchResult,
  Followable,
  InternalStatus,
  Package,
  SalesRep,
} from "./sharedTypes"
import { employerPrefix } from "utilities/url"

/**
 * @param term Assignee search term
 * @param options.useGlobalExternalIds When true, returns assignee with global external IDs. When false, returns assignees with their global ID. TODO: Remove during WF-23683 when we get everything off of GIDs
 * @returns List of assignees
 */
export const getAssignees = (
  term: string,
  options: { useGlobalExternalIds: boolean } = { useGlobalExternalIds: false }
): Promise<Assignee[]> => {
  return get(urls.getAssigneesUrl(), {
    term,
    useGlobalExternalIds: options.useGlobalExternalIds,
  }).then(({ data }) => data)
}

export const getCatalogPackages = (term: string): Promise<Package[]> => {
  return get(`${employerPrefix()}/catalog_packages.json`, {
    search_term: term,
  }).then(({ data }) => data.results)
}

export const getCatalogSubcategories = (
  term: string
): Promise<ClinicalFacilitySearchResult[]> => {
  return get(`${employerPrefix()}/catalog_categories.json`, {
    search_term: term,
  }).then(({ data }) => data.results)
}

export const getClinicalFacilities = (
  term: string
): Promise<ClinicalFacilitySearchResult[]> => {
  return get(urls.searchClinicalFacilitiesUrl(), { term }).then(
    ({ data }) => data.results
  )
}

export const getClinicians = (term: string): Promise<Doctor[]> => {
  return get(urls.searchDoctorsUrl(), { term }).then(({ data }) => data.results)
}

export const getFollowables = (term: string): Promise<Followable[]> => {
  return get(urls.followablesUrl(), { term }).then(({ data }) => data)
}

export const getInternalStatuses = (
  term: string
): Promise<InternalStatus[]> => {
  return get(urls.internalStatusesUrl(), { term }).then(({ data }) => data)
}

export const getLegalEntities = (
  term: string
): Promise<ClinicalFacilitySearchResult[]> => {
  return get(`${employerPrefix()}/legal_entities.json`, {
    search_term: term,
  }).then(({ data }) => data.results)
}

export const getSalesReps = (term: string): Promise<SalesRep[]> => {
  return get(urls.getSalesRepsUrl(), { term }).then(({ data }) => data)
}
