// @team @facilitators
import { withStyles } from "@material-ui/core"
import { canopyColorPrimitivesGray86 } from "@parachutehealth/canopy-tokens-color"
import MuiAccordion from "@material-ui/core/Accordion"
import MuiAccordionSummary from "@material-ui/core/AccordionSummary"

export const Accordion = withStyles({
  root: {
    border: `1px solid ${canopyColorPrimitivesGray86}`,
    boxShadow: "none",
    "&:before": {
      display: "none",
    },
    "&$expanded": {
      margin: "auto",
    },
  },
})(MuiAccordion)

export const AccordionSummary = withStyles({
  root: {
    minHeight: 56,
    "&$expanded": {
      minHeight: 56,
    },
  },
  content: {
    "&$expanded": {
      margin: "12px 0",
    },
  },
  expanded: {
    margin: "0",
  },
})(MuiAccordionSummary)
