import Select from "components/form/Select"
import { reviewerAffiliatedDoctorSearch } from "../urls"
import React, { useState } from "react"
import { Form } from "components/form"
import { get } from "services/api"
import { Doctor, SignUpSource } from "sharedTypes"
import DoctorSuggestion from "./DoctorSuggestion"
import Alert from "components/Alert"
import SelectedDoctor from "./SelectedDoctor"
import ReviewerAttestation from "./ReviewerAttestation"
import { EventCategory, trackEvent } from "utilities/tracking"
import { navigate } from "utilities/navigation"
import * as styles from "./SignUp.module.scss"
import { handleError } from "utilities/error"
import { addCliniciansToReviewer } from "../api"
import { useDebounce } from "hooks/useDebounce"
import { CanopyButton } from "@parachutehealth/canopy-button"
import { pluralize } from "utilities/string"

type Props = {
  buttonFullWidth?: boolean
  signUpSource: SignUpSource
  preSelectedDoctors: Doctor[]
}

const SignUp = ({
  buttonFullWidth,
  signUpSource,
  preSelectedDoctors,
}: Props) => {
  const [selectedDoctors, setSelectedDoctors] = useState(
    preSelectedDoctors as Doctor[]
  )
  const [isFetching, setIsFetching] = useState(false)
  const [attemptedSubmit, setAttemptedSubmit] = useState(false)

  const doctorLabel = (doctor) =>
    `${doctor.firstName} ${doctor.lastName} - ${doctor.npi}`

  const debouncedGetAffiliatedDoctors = useDebounce(search)

  function search(term: string = ""): Promise<Doctor[]> {
    return get(reviewerAffiliatedDoctorSearch, { search: term })
      .then((response) => response.data)
      .catch(({ response }) => Promise.reject(response.data.errors))
  }

  const addSelectedDoctorsToReviewer = (doctors: Doctor[]) => {
    const doctorIds: string[] = doctors.map((doctor) => doctor.doctorId)
    addCliniciansToReviewer(doctorIds, signUpSource)
      .then(({ path }) => {
        trackEvent(EventCategory.ReviewerSidebarSelfSignUp, "add_clinician")
        navigate(path)
      })
      .catch(handleError)
  }

  const onDoctorRemove = (
    doctorId: string,
    setFieldValue: (field: string, value: any) => void
  ) => {
    setSelectedDoctors((prevDoctors) => {
      const newSelectedDoctors = prevDoctors.filter(
        (prevDoctor) => prevDoctor.doctorId !== doctorId
      )
      setFieldValue(
        "doctors",
        newSelectedDoctors.map((doctor) => doctor.doctorId)
      )
      initialDoctors.includes(doctorId) &&
        trackEvent(EventCategory.ReviewerSidebarSelfSignUp, "deselect_npi")
      return newSelectedDoctors
    })
  }
  const clinicianText = `${selectedDoctors.length} ${pluralize(
    "Clinician",
    selectedDoctors.length
  )}`

  const validate = ({ doctors, reviewerAttestationCheckbox }) => {
    const errors = {}
    if (doctors.length === 0) {
      errors["doctors"] = "Please select clinicians to continue."
    }
    if (!reviewerAttestationCheckbox) {
      errors["reviewerAttestationCheckbox"] =
        "Please agree to the Reviewer Agreement to continue."
    }
    return errors
  }

  const initialDoctors = preSelectedDoctors.map((doctor) => doctor.doctorId)

  const mapDoctorOptions = (doctors: Doctor[]) => {
    return doctors.map((doctor) => ({
      value: doctor.doctorId,
      label: doctorLabel(doctor),
      ...doctor,
    }))
  }

  return (
    <>
      <p className="canopy-typography-heading-medium canopy-mbe-1x">
        Select clinicians to review signature requests for:
      </p>
      <Form
        initialValues={{
          doctors: initialDoctors,
          reviewerAttestationCheckbox: false,
        }}
        onSubmit={() => {
          addSelectedDoctorsToReviewer(selectedDoctors)
        }}
        validate={validate}
        validateOnChange={attemptedSubmit}
        validateOnBlur={false}
        validateOnMount={false}
      >
        {({ isSubmitting, setFieldValue }) => (
          <>
            <Select
              aria-label="Doctor Select"
              name="doctors"
              options={mapDoctorOptions(selectedDoctors)}
              renderEmpty={() => {
                return (
                  <div className="canopy-px-8x canopy-pbs-8x canopy-pbe-4x">
                    <Alert status="danger">
                      <strong>No results.</strong>
                    </Alert>
                  </div>
                )
              }}
              onChange={(_id, doctors) => {
                setSelectedDoctors(doctors)
              }}
              isSearchable
              isMulti
              maxMenuHeight={232}
              openMenuOnFocus
              isLoading={isFetching}
              fetchOptions={(value) => {
                setIsFetching(true)
                return debouncedGetAffiliatedDoctors(value).then((doctors) => {
                  setIsFetching(false)
                  return [
                    {
                      label: "Suggested for you",
                      options: mapDoctorOptions(doctors),
                    },
                  ]
                })
              }}
              closeMenuOnSelect={false}
              hideSelections={true}
              renderOption={(doctor, { query }) => (
                <DoctorSuggestion doctor={doctor} query={query} />
              )}
              placeholder="Search by name or NPI"
            />
            <div className={styles.selectedDoctorsContainer}>
              <div>
                <p className="canopy-typography-heading-medium">
                  Selected clinicians:
                </p>
                <p className={styles.helpText}>
                  This includes clinicians for your orders that were Signed
                  On-screen.
                </p>
              </div>
              <ul className={styles.selectedDoctorsList}>
                {selectedDoctors.length > 0 ? (
                  selectedDoctors.map((doctor) => (
                    <SelectedDoctor
                      doctor={doctor}
                      onDoctorRemove={(doctorId) =>
                        onDoctorRemove(doctorId, setFieldValue)
                      }
                      key={doctor.doctorId}
                    />
                  ))
                ) : (
                  <Alert
                    className={styles.noCliniciansSelectedAlert}
                    status="info"
                  >
                    <strong>No clinicians selected.</strong>
                  </Alert>
                )}
              </ul>
            </div>
            <ReviewerAttestation />
            <CanopyButton
              fullWidth={buttonFullWidth}
              type="submit"
              loading={isSubmitting}
              loadingText={`Adding ${clinicianText}`}
              onClick={() => setAttemptedSubmit(true)}
            >
              {`Add ${clinicianText}`}
            </CanopyButton>
          </>
        )}
      </Form>
    </>
  )
}

export default SignUp
