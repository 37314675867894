import { DataGridPro } from "@mui/x-data-grid-pro"
import { styled } from "@material-ui/core"

const ParachuteMuiDataGridPro = styled(DataGridPro)(() => ({
  "& .MuiDataGrid-columnSeparator": {
    display: "none",
  },
  "&.MuiDataGrid-root": {
    borderLeft: "none",
    borderRight: "none",
    borderBottom: "none",
  },
  "&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus, &.MuiDataGrid-root .MuiDataGrid-cell:focus": {
    outline: "none",
  },
}))

export default ParachuteMuiDataGridPro
