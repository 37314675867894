import React from "react"
import { Document, Zoom } from "../sharedTypes"
import Image from "./Image"

type Props = {
  document?: Document
  zoom: Zoom
  activeImageIndex?: number
  isRotateHovered: boolean
}

function Preview({ document, zoom, activeImageIndex, isRotateHovered }: Props) {
  return (
    <div>
      {!document && <h4>No documents uploaded</h4>}
      {document && (
        <div aria-label="document preview">
          {document.files.map((file, currentIndex) => {
            const altText = `Page ${file.pageNumber}${
              file.rotation ? ", rotated" : ""
            }`
            return (
              <Image
                key={file.url}
                src={file.url}
                alt={altText} // Basic description of each file for a screen reader
                zoom={zoom}
                rotation={file.rotation}
                hover={isRotateHovered && activeImageIndex === currentIndex}
              />
            )
          })}
        </div>
      )}
    </div>
  )
}

export default Preview
