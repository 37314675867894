// @ts-strict-ignore
import { createContext } from "react"

type SupplierOrganizationInfo = {
  supplierOrganizationId: string
  supplierOrganizationName: string
}
export default createContext<SupplierOrganizationInfo>({
  supplierOrganizationId: null,
  supplierOrganizationName: null,
})
