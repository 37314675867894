import { Divider, Drawer, List, ListItem } from "@material-ui/core"
import React, {
  Dispatch,
  ReactElement,
  ReactNode,
  SetStateAction,
  useMemo,
} from "react"
import { useStyles } from "themes/theme"
import classNames from "classnames"
import Alert from "components/Alert"
import PatientSearchField from "../../PatientSearchField"
import { useFlashContext } from "../../../hooks/useFlashContext"
import { signatureRequestsDrawerStyles } from "../../../utilities/drawerStyles"

interface Props {
  selectedId: string
  setSelectedSignatureRequestGid: Dispatch<SetStateAction<string>>
  signatureRequests: {
    displayText: ReactNode
    id: string
  }[]
  patientSearchQuery?: string
  setPatientSearchQuery: (string) => void
  noSearchResults: boolean
}

const SignatureRequestsDrawer = ({
  selectedId,
  setSelectedSignatureRequestGid,
  signatureRequests,
  patientSearchQuery,
  setPatientSearchQuery,
  noSearchResults,
}: Props): ReactElement => {
  const flash = useFlashContext()
  const styles = useMemo(
    () =>
      signatureRequestsDrawerStyles({
        flash: flash,
      }),
    [flash]
  )
  const classes = useStyles(styles)

  const title = "Pending Signatures"
  const headerId = `${title}-drawer`

  return (
    <Drawer
      variant="permanent"
      className={classes.column}
      classes={{
        paper: classNames(classes.drawerPaper),
      }}
    >
      <h4 className={classes.header} id={headerId}>
        {title}
      </h4>
      <div className={classes.searchBar}>
        <PatientSearchField
          onSearch={(searchQuery) => setPatientSearchQuery(searchQuery)}
          autoFocus={!!patientSearchQuery}
          value={patientSearchQuery || ""}
          fullWidth
        />
      </div>
      <div className={classes.drawerContainer}>
        <List
          role="tablist"
          component="nav"
          aria-labelledby={headerId}
          key="top"
        >
          {noSearchResults ? (
            <Alert status="danger">
              <strong>No results.</strong>
            </Alert>
          ) : (
            signatureRequests.map((signatureRequest, index) => {
              const selected = selectedId === signatureRequest.id
              return (
                <React.Fragment key={signatureRequest.id}>
                  <ListItem
                    classes={{ selected: classes.selectedListItem }}
                    className={classes.listItem}
                    onClick={() =>
                      setSelectedSignatureRequestGid(signatureRequest.id)
                    }
                    button
                    selected={selected}
                    aria-selected={selected}
                    role="tab"
                  >
                    {signatureRequest.displayText}
                  </ListItem>
                  {index < signatureRequests.length - 1 && (
                    <Divider
                      className={classes.divider}
                      key={`${index}-divider`}
                    />
                  )}
                </React.Fragment>
              )
            })
          )}
        </List>
      </div>
    </Drawer>
  )
}

export default SignatureRequestsDrawer
