import React from "react"
import { HospicePatient } from "sharedTypes"
import { isDateValid, convert, DATE_FORMAT } from "utilities/date"
import DatePickerField from "components/form/DatePickerField"
import { Form } from "components/form"

export type FormData = {
  hospiceEpisodeAdmitDate?: string
  hospiceEpisodeDischargeDate?: string
}

type Props = {
  hospicePatient: HospicePatient
  onSave(values: FormData): Promise<void>
  cancelButton(): void
}

export default (props: Props) => {
  const { hospicePatient, onSave, cancelButton } = props

  const convertHospiceDate = (date, format) => {
    if (!date) {
      return null
    }
    return convert(date, format)
  }

  const onSubmit = (values, { setErrors }) => {
    onSave(values).catch(({ errors }) => {
      !!errors && setErrors(errors)
    })
  }

  return (
    <>
      <Form
        onSubmit={onSubmit}
        initialValues={{
          hospiceEpisodeAdmitDate: convertHospiceDate(
            hospicePatient.hospiceEpisodeAdmitDate,
            DATE_FORMAT
          ),
          hospiceEpisodeDischargeDate: convertHospiceDate(
            hospicePatient.hospiceEpisodeDischargeDate,
            DATE_FORMAT
          ),
        }}
        validate={({
          hospiceEpisodeAdmitDate,
          hospiceEpisodeDischargeDate,
        }) => {
          if (
            !!hospiceEpisodeAdmitDate &&
            !isDateValid(hospiceEpisodeAdmitDate)
          ) {
            return { hospiceEpisodeAdmitDate: "Invalid Date" }
          }
          if (
            !!hospiceEpisodeDischargeDate &&
            !isDateValid(hospiceEpisodeDischargeDate)
          ) {
            return { hospiceEpisodeDischargeDate: "Invalid Date" }
          }
          return {}
        }}
      >
        <div className="row">
          <div className="col-6">
            <DatePickerField
              name="hospiceEpisodeAdmitDate"
              label="Admission Date"
            />
          </div>
          <div className="col-6">
            <DatePickerField
              name="hospiceEpisodeDischargeDate"
              label="Discharge Date"
            />
          </div>
        </div>
        <div className="row">
          <div className="float-right">
            <button
              className="link link-danger canopy-mie-8x"
              onClick={cancelButton}
              type="button"
            >
              Cancel
            </button>
            <button className="btn btn-primary canopy-mie-8x" type="submit">
              Save
            </button>
          </div>
        </div>
      </Form>
    </>
  )
}
