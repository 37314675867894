import React from "react"

type Props = {
  warnings: string[]
}

function Warnings({ warnings }: Props) {
  if (warnings.length === 0) {
    return <></>
  }
  return (
    <ul className="list-unstyled">
      {warnings.map((warning) => {
        return (
          <li key={warning}>
            <small className="bold font-subparagraph color-black">
              {warning}
            </small>
          </li>
        )
      })}
    </ul>
  )
}

export default Warnings
