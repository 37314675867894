import React, { ReactElement } from "react"
import isTouchDevice from "is-touch-device"
import { SidebarTab } from "../../../../sharedTypes"
import ToggleButton from "components/ToggleButton"
import { trackClick } from "../../analytics"
import Tooltip from "components/Tooltip"

interface Props {
  currentTab: SidebarTab
  setTab(tab: SidebarTab): void
  tab: SidebarTab
  icon: ReactElement
  label: string
  collapsedLabel?: string
  badge?: boolean
}

export default function TabToggleButton({
  currentTab,
  setTab,
  tab,
  icon,
  label,
  collapsedLabel,
  badge,
}: Props): ReactElement {
  const active = currentTab === tab
  const onClick = () => {
    trackClick(label).then(() => setTab(tab))
  }

  return (
    <Tooltip
      overlay={label}
      hideTooltipAtBreakpoint="lg"
      disabled={isTouchDevice()}
    >
      <ToggleButton
        active={active}
        icon={icon}
        onClick={onClick}
        label={label}
        collapsedLabel={collapsedLabel}
        badge={badge}
      />
    </Tooltip>
  )
}
