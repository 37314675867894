import React, { useMemo, useState } from "react"
import { states } from "utilities/address"
import * as styles from "./ServiceAreaStateModal.module.scss"
import Link from "components/Link"
import { CanopySelectField } from "@parachutehealth/canopy-select-field"
import ServiceAreaStateModal from "./ServiceAreaStateModal"
import { OptionItem } from "@parachutehealth/canopy-combobox-field"

interface Props {
  defaultState: string
  isMobile: boolean
  serviceAreaState: string
  setServiceAreaState: (state: string) => void
}

const ServiceAreaState: React.FC<Props> = ({
  defaultState,
  isMobile,
  serviceAreaState,
  setServiceAreaState,
}) => {
  const [showServiceAreaStateModal, setShowServiceAreaStateModal] = useState<
    boolean
  >(false)

  const stateOptions: OptionItem[] = useMemo(
    () =>
      states.map((state) => ({
        label: state[1] === defaultState ? `${state[0]} (Default)` : state[0],
        value: state[1],
      })),
    [defaultState]
  )

  if (isMobile) {
    return (
      <>
        <CanopySelectField
          label="Service Area"
          options={stateOptions}
          defaultValue={serviceAreaState + " (Default)"}
          onChange={(newValue) => setServiceAreaState(newValue.target.value)}
          value={serviceAreaState}
        />
        <hr />
      </>
    )
  }

  const serviceAreaStateLabel =
    serviceAreaState === defaultState
      ? `${serviceAreaState} (Default)`
      : serviceAreaState

  return (
    <div className="canopy-p-4x">
      <h3 className={styles.filterHeading}>Service Area</h3>
      <Link
        className={styles.serviceAreas}
        onClick={() => setShowServiceAreaStateModal(true)}
      >
        {serviceAreaStateLabel}
      </Link>
      <hr className="canopy-mbe-8x canopy-mbs-8x"></hr>
      <ServiceAreaStateModal
        initialServiceAreaState={serviceAreaState}
        showServiceAreaStateModal={showServiceAreaStateModal}
        setServiceAreaState={setServiceAreaState}
        setShowServiceAreaStateModal={setShowServiceAreaStateModal}
        stateOptions={stateOptions}
      />
    </div>
  )
}

export default ServiceAreaState
