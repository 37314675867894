import React from "react"
import Badge from "components/Badge"
import { SvgIcon } from "@material-ui/core"

interface Props {
  icon: React.FC<React.ComponentProps<typeof SvgIcon>>
  badgeEnabled?: boolean
  badgeColor?: string
}

const OptionIcon = ({ badgeEnabled, badgeColor, icon }: Props) => {
  const ListIcon = icon
  if (badgeEnabled) {
    return (
      <Badge color={badgeColor} flex>
        <ListIcon style={{ fontSize: "1rem" }} />
      </Badge>
    )
  }

  return <ListIcon style={{ fontSize: "1rem" }} />
}

export default OptionIcon
