import React from "react"
import { Button } from "@material-ui/core"
import { SvgIconComponent } from "@material-ui/icons"

type Props = {
  onClick: () => void
  name: string
  icon: SvgIconComponent
  label: string
}

function DataGridToolbarButton(props: Props) {
  const { onClick, icon: IconComponent, name, label } = props

  return (
    <div>
      <Button name={name} color="primary" size="small" onClick={onClick}>
        <IconComponent className="canopy-mie-4x" fontSize="small" />
        {label}
      </Button>
    </div>
  )
}

export default DataGridToolbarButton
