import React from "react"
import { Formik, FormikErrors, FormikProps, Field as FormikField } from "formik"
import { CanopyTextInputField } from "@parachutehealth/canopy-text-input-field"
import { CanopyButton } from "@parachutehealth/canopy-button"
import { CanopyForm } from "@parachutehealth/canopy-form"
import { isEmailValid } from "utilities/email"

interface Props {
  onSubmit({ email }): void
}

interface ForgotPasswordFormValues {
  email: string
}

const ForgotPasswordForm = ({ onSubmit }: Props) => {
  const validate = ({ email }: ForgotPasswordFormValues) => {
    const errors: FormikErrors<ForgotPasswordFormValues> = {}

    if (!email || !isEmailValid(email)) {
      errors.email = "Enter a valid email address"
    }
    return errors
  }

  const initialValues: ForgotPasswordFormValues = {
    email: "",
  }

  return (
    <div style={{ maxWidth: "440px", margin: "0 auto" }}>
      <h1>Forgot your password?</h1>
      <p className="text-xs-left canopy-mbe-16x">
        Enter the email you use to log in to your Parachute Health account and
        we will send instructions to reset your password.
      </p>
      <Formik
        initialValues={initialValues}
        validate={validate}
        onSubmit={onSubmit}
      >
        {({
          handleSubmit,
          isSubmitting,
          touched,
          errors,
          submitCount,
        }: FormikProps<ForgotPasswordFormValues>) => {
          return (
            <CanopyForm onSubmit={handleSubmit}>
              <FormikField
                as={CanopyTextInputField}
                name="email"
                label="Email address"
                type="email"
                feedbackMessage={
                  touched.email && submitCount > 0 ? errors.email : undefined
                }
              />
              <CanopyButton type="submit" loading={isSubmitting}>
                Send password reset instructions
              </CanopyButton>
            </CanopyForm>
          )
        }}
      </Formik>
    </div>
  )
}

export default ForgotPasswordForm
