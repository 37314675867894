import React, { useState, useContext } from "react"
import { handleError } from "utilities/error"
import Popover from "components/Popover"
import Icon from "components/Icon"
import { Form, NativeSelect } from "components/form"
import { SelectOption } from "components/form/sharedTypes"
import classNames from "classnames"
import { ApiContext } from "./apiContext"

type Props = {
  id: string
  canAssign: boolean
  initialStatus: string
  statuses: SelectOption[]
}

const CLASSES_MAP = {
  new_auth: "color-secondary",
  auth_changed: "color-secondary",
  processed: "color-success",
  complete: "color-success",
  reviewed: "color-warning",
  cancelled: "color-info",
}

const DEFAULT_CLASS = "color-info"

const StatusEditor = ({ id, initialStatus, statuses, canAssign }: Props) => {
  const [status, setStatus] = useState(initialStatus)
  const [isSubmitting, setSubmitting] = useState(false)
  const [open, setOpen] = useState(false)
  const { updateStatus } = useContext(ApiContext)

  const toggle = () => setOpen((open) => !open)
  const hide = () => setOpen(false)
  const close = () => {
    hide()
    setSubmitting(false)
  }

  const getStatusLabel = () => {
    return statuses.find((option) => option.value === status)?.label
  }

  const getStatusClass = () => CLASSES_MAP[status] || DEFAULT_CLASS

  const canShowSelect = statuses
  const isReadOnly = !(canAssign && canShowSelect)

  const onChange = (values) => {
    setSubmitting(true)
    updateStatus(id, values)
      .then((response) => {
        setStatus(response.status)
        close()
      })
      .catch((response) => {
        handleError(response)
        close()
      })
  }

  if (isReadOnly) {
    return <>{getStatusLabel()}</>
  }

  return (
    <Popover
      open={open}
      onHide={hide}
      onClick={toggle}
      label={
        <a title="Show popover">
          <div className={classNames("nowrap", getStatusClass())}>
            {getStatusLabel()}
            <Icon
              type="chevron-down"
              className="canopy-mis-2x position-absolute"
            />
          </div>
        </a>
      }
    >
      <ul className="list-unstyled list-bordered canopy-py-0 canopy-px-12x">
        {canShowSelect && (
          <li className="canopy-pbe-12x">
            <Form
              initialValues={{ status: status }}
              onSubmit={onChange}
              submitOnChange
            >
              <NativeSelect
                disabled={isSubmitting}
                name="status"
                options={statuses}
                autoFocus
              />
            </Form>
          </li>
        )}
      </ul>
    </Popover>
  )
}

export default StatusEditor
