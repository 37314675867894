import React from "react"
import { Formik, FormikErrors } from "formik"
import { CanopyDialogModal } from "@parachutehealth/canopy-dialog-modal"
import { CanopyButton } from "@parachutehealth/canopy-button"
import { CanopyForm } from "@parachutehealth/canopy-form"
import { CanopyTextInputField } from "@parachutehealth/canopy-text-input-field"
import { CanopyRequiredFieldKey } from "@parachutehealth/canopy-required-field-key"

type SupplierSystemClinicalFacilityExternalSystemIdModalProps = {
  /**
   * Function called when the modal is closed
   */
  onClose(): void
  /**
   * Function called when the form is submitted (and all fields are valid)
   */
  onSubmit: (externalSystemId) => Promise<void>
  /**
   * When true, the modal is visible
   */
  open?: boolean
}

interface SupplierSystemClinicalFacilityExternalSystemIdFields {
  externalSystemId?: string
}

const SupplierSystemClinicalFacilityExternalSystemIdModal = (
  props: SupplierSystemClinicalFacilityExternalSystemIdModalProps
): JSX.Element => {
  const { onClose, onSubmit, open = true } = props

  const handleFormSubmission = (values) => {
    return onSubmit(values.externalSystemId).then(() => onClose())
  }

  const initialValues: SupplierSystemClinicalFacilityExternalSystemIdFields = {
    externalSystemId: "",
  }

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleFormSubmission}
      validate={(values) => {
        const errors: FormikErrors<SupplierSystemClinicalFacilityExternalSystemIdFields> = {}
        if (!values.externalSystemId || values.externalSystemId.length === 0) {
          errors.externalSystemId = "Enter this facility's external system ID"
        }
        return errors
      }}
    >
      {({
        errors,
        isSubmitting,
        handleBlur,
        handleChange,
        handleSubmit,
        submitCount,
        touched,
        values,
      }) => {
        const externalSystemIdError =
          submitCount > 0 && touched.externalSystemId
            ? (errors.externalSystemId as string)
            : undefined

        return (
          <CanopyDialogModal
            header="Set facility's external system ID"
            onClose={onClose}
            open={open}
            primaryFooterButton={
              <CanopyButton
                type="submit"
                form="suppliersystemclinicalfacilityexternalsystemid"
                loading={isSubmitting}
                loadingText="Saving facility's external system ID..."
              >
                Save
              </CanopyButton>
            }
          >
            <CanopyForm
              id="suppliersystemclinicalfacilityexternalsystemid"
              onSubmit={handleSubmit}
            >
              <p>
                This order is from a new facility. This facility&apos;s external
                system ID must be entered before this order can be accepted.
              </p>
              <CanopyRequiredFieldKey />
              <CanopyTextInputField
                feedbackMessage={externalSystemIdError}
                label="Facility's external system ID"
                name="externalSystemId"
                onChange={handleChange}
                onBlur={handleBlur}
                required
                value={values.externalSystemId}
              />
            </CanopyForm>
          </CanopyDialogModal>
        )
      }}
    </Formik>
  )
}

export default SupplierSystemClinicalFacilityExternalSystemIdModal
