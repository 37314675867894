import React, { useState } from "react"
import Link from "components/Link"
import { SvgIcon } from "@material-ui/core"
import OptionIcon from "./OptionIcon"
import OptionPill from "./OptionPill"
import classNames from "classnames"
import * as styles from "./SidebarOption.module.scss"
import { CanopyIcon } from "@parachutehealth/canopy-icon"
import Tooltip from "components/Tooltip"
import SidebarSubOption, { SidebarSuboption } from "./SidebarSubOption"
import SidebarSubOptionCta, { SidebarSuboptionCta } from "./SidebarSubOptionCta"
import { lowercase } from "../../../utilities/string"

interface Option {
  icon: React.FC<React.ComponentProps<typeof SvgIcon>>
  text: string
  active?: boolean
  href?: string
  tooltip?: string
  count?: number
  badgeEnabled?: boolean
  badgeColor?: string
  pill?: {
    icon?: React.ReactNode
    text: string
    className?: string
    ariaLabel?: string
  }
  expanded?: boolean
  extraWidth?: boolean
  subOptions?: SidebarSuboption[]
  subOptionsExpanded?: boolean
  subOptionCta?: SidebarSuboptionCta
  onClick?: () => void
  multilineText?: boolean
}

const SubOptionMenuToggle = ({
  toggleSubOptionMenuExpanded,
  subOptionMenuExpanded,
  text,
}) => {
  const handleOnClick = (event) => {
    toggleSubOptionMenuExpanded()
    event.preventDefault()
    event.stopPropagation()
  }

  return (
    <button
      type="button"
      onClick={handleOnClick}
      aria-label={subOptionMenuExpanded ? `Collapse ${text}` : `Expand ${text}`}
      className="btn-link"
    >
      {subOptionMenuExpanded ? (
        <CanopyIcon name="chevron-up" title={`Collapse ${text}`} />
      ) : (
        <CanopyIcon name="chevron-down" title={`Expand ${text}`} />
      )}
    </button>
  )
}

const ExpandedLink = (option: {
  icon: React.FC<React.ComponentProps<typeof SvgIcon>>
  text: string
  count?: number
  badgeEnabled?: boolean
  badgeColor?: string
  pill?: {
    icon?: React.ReactNode
    text: string
    className?: string
    ariaLabel?: string
  }
  subOptions?: SidebarSuboption[]
  subOptionMenuExpanded: boolean
  subOptionCta?: SidebarSuboptionCta
  toggleSubOptionMenuExpanded: () => void
}) => {
  return (
    <div className="d-flex w-100-p h-100-p">
      <OptionIcon
        badgeEnabled={option.badgeEnabled}
        badgeColor={option.badgeColor}
        icon={option.icon}
      />
      <div className="canopy-typography-heading-small canopy-mis-4x">
        {option.text}
      </div>
      <div className="canopy-mis-auto align-self-center">
        {option.pill && (
          <OptionPill
            text={option.pill.text}
            icon={option.pill.icon}
            className={classNames(option.pill.className, styles.pill)}
            ariaLabel={option.pill.ariaLabel}
          />
        )}
        {option.count !== undefined && (
          <div className="canopy-typography-body-xsmall">{option.count}</div>
        )}
        {(!!option.subOptions || !!option.subOptionCta) && (
          <SubOptionMenuToggle
            subOptionMenuExpanded={option.subOptionMenuExpanded}
            toggleSubOptionMenuExpanded={option.toggleSubOptionMenuExpanded}
            text={lowercase(option.text)}
          />
        )}
      </div>
    </div>
  )
}

const SidebarOption = (props: Option) => {
  const {
    text,
    active,
    href,
    expanded,
    badgeColor,
    badgeEnabled,
    icon,
    pill,
    count,
    subOptions,
    tooltip,
    subOptionsExpanded,
    subOptionCta,
    onClick,
    multilineText,
  } = props

  const [subOptionMenuExpanded, setSubOptionMenuExpanded] = useState(
    !!subOptionsExpanded
  )

  const handleOnClick = (event) => {
    active && event.preventDefault()
    onClick && onClick()
  }
  const toggleSubOptionMenuExpanded = () =>
    setSubOptionMenuExpanded((prevState) => !prevState)

  const showSubOptions =
    expanded && (subOptions || subOptionCta) && subOptionMenuExpanded

  return (
    <Tooltip
      trigger="hover"
      placement={Tooltip.Placement.Right}
      overlay={tooltip || text}
      disabled={expanded}
    >
      <div
        className={classNames(styles.optionContainer, {
          [styles.active]: active,
        })}
      >
        <Link
          className={classNames(styles.leftSidebarLink, {
            [styles.singleLineText]: multilineText !== true,
          })}
          href={href}
          onClick={handleOnClick}
          aria-label={text}
        >
          {expanded ? (
            <ExpandedLink
              icon={icon}
              text={text}
              count={count}
              badgeEnabled={badgeEnabled}
              badgeColor={badgeColor}
              pill={pill}
              subOptions={subOptions}
              subOptionMenuExpanded={subOptionMenuExpanded}
              subOptionCta={subOptionCta}
              toggleSubOptionMenuExpanded={toggleSubOptionMenuExpanded}
            />
          ) : (
            <OptionIcon
              badgeEnabled={badgeEnabled}
              badgeColor={badgeColor}
              icon={icon}
            />
          )}
        </Link>
      </div>
      {showSubOptions && (
        <>
          <div
            className={classNames(styles.subOptionContainer, "canopy-mbs-8x")}
          >
            {subOptions &&
              subOptions.map((subOption) => (
                <SidebarSubOption
                  href={subOption.href}
                  text={subOption.text}
                  key={subOption.text}
                  active={subOption.active}
                  badgeEnabled={subOption.badgeEnabled}
                  onClick={subOption.onClick}
                />
              ))}
          </div>
          {subOptionCta && (
            <SidebarSubOptionCta
              href={subOptionCta.href}
              text={subOptionCta.text}
              onClick={subOptionCta.onClick}
            />
          )}
        </>
      )}
    </Tooltip>
  )
}

export default SidebarOption
