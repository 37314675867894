import React from "react"
import { Button } from "components/form"
import * as styles from "./index.module.scss"
import classNames from "classnames"
import { navigate } from "utilities/navigation"
import * as urls from "applications/FacilitySignUp/AccountCompletion/urls"
import { trackEvent, EventCategory } from "utilities/tracking"
import { ContextEmployer } from "sharedTypes"

interface Props {
  currentEmployer: ContextEmployer
}
const JoinFacilityButton = ({ currentEmployer }: Props) => {
  const joinFacility = () => {
    void trackEvent(
      EventCategory.PushbackSignUp,
      "join_facility_button_clicked_pushback"
    )
    navigate(
      urls.joinFacilityUrl({ default_facility_id: currentEmployer.employerId })
    )
  }

  return (
    <Button
      className={classNames("btn-brand-o", "canopy-mie-2x", styles.btnBlockSm)}
      onClick={joinFacility}
    >
      <div className={styles.borderButtonText}>Join Facility</div>
    </Button>
  )
}

export default JoinFacilityButton
