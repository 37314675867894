import React, { useState } from "react"
import Modal from "components/Modal"
import { Form, Select } from "components/form"
import { searchSupplierLegalEntities } from "./api"
import Overlay from "components/Overlay"

type Props = {
  updateSupplierLegalEntity(values: {
    [supplierLegalEntityId: string]: string
  }): Promise<void>
  close(): void
  supplierLegalEntityId: string
}

const LegalEntityModal = ({
  close,
  supplierLegalEntityId,
  ...props
}: Props) => {
  const [showOverlay, setShowOverlay] = useState(false)

  const getSupplierLegalEntities = (term) => {
    return searchSupplierLegalEntities(term).then((response) => {
      return response.supplierLegalEntities.map((result) => ({
        label: result.name,
        value: result.id,
      }))
    })
  }

  const updateSupplierLegalEntity = (values) => {
    setShowOverlay(true)
    return props
      .updateSupplierLegalEntity(values)
      .then(() => close())
      .catch(() => setShowOverlay(false))
  }
  return (
    <Modal show title="Legal Entity" closeable cancel={close} size="md">
      <Modal.Body>
        <Overlay
          active={showOverlay}
          showSpinner
          position="top"
          className="overlay-flex-height"
        >
          <Form
            onSubmit={updateSupplierLegalEntity}
            initialValues={{ supplierLegalEntityId }}
          >
            <Select
              isClearable
              isSearchable
              placeholder="Search legal entities…"
              name="supplierLegalEntityId"
              fetchOptions={getSupplierLegalEntities}
            />
            <div className="row">
              <div className="col-12">
                <button className="btn btn-brand float-right" type="submit">
                  Done
                </button>
              </div>
            </div>
          </Form>
        </Overlay>
      </Modal.Body>
    </Modal>
  )
}

export default LegalEntityModal
