import { post } from "services/api"

interface EmrCreateResponse {
  redirectPath?: string
}

export const emrEmailCapture = (
  submitPath: string,
  params
): Promise<EmrCreateResponse> => {
  return post(submitPath, { capture: params })
    .then(({ data }) => data)
    .catch(({ response }) => Promise.reject(response.data))
}
