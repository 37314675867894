import { handleError } from "utilities/error"
import { reload } from "utilities/navigation"

const getUnresolvedPromise = () => new Promise(() => {})
const LOGIN_URL = "/users/log_in.json"

export const errorResponseHandler = (error) => {
  if (!error) {
    return getUnresolvedPromise()
  }
  const { response } = error
  if (response) {
    const { status, config } = response
    if (status >= 500) {
      handleError(error)
      return getUnresolvedPromise()
    } else if (config.url !== LOGIN_URL && status === 401) {
      reload()
      return getUnresolvedPromise()
    }
  }

  return Promise.reject(error)
}
