import React from "react"
import Icon from "components/Icon"
import { Document, Zoom } from "../sharedTypes"
import ArchiveButton from "./ArchiveButton"
import { CanopyButton } from "@parachutehealth/canopy-button"
import * as styles from "../index.module.scss"

export type Props = {
  documentIndex: number
  documents: Document[]
  archive?(document: Document): void
  previous(): void
  next(): void
  zoomIn(): void
  zoomOut(): void
  rotate?(): void
  onRotateMouseEnter(): void
  onRotateMouseLeave(): void
  onDownload?(): void
  newUi?: boolean
  activeImageIndex?: number
  zoom?: number
  hideDocumentsNav?: boolean
}

function Actions({
  archive,
  documents,
  documentIndex,
  previous,
  next,
  zoomIn,
  zoomOut,
  rotate,
  onRotateMouseEnter,
  onRotateMouseLeave,
  onDownload,
  newUi,
  activeImageIndex,
  zoom,
  hideDocumentsNav,
}: Props) {
  const document = documents[documentIndex]
  const canArchive = archive && document?.permissions?.archive

  const oldToolbar = (
    <div id="sidebar-actions">
      {documents.length > 0 && (
        <div className="canopy-mbs-8x">
          <div className="float-left">
            <button
              className="btn btn-secondary btn-xs zoom-out"
              onClick={zoomOut}
            >
              <Icon type="minus" />
            </button>
            <button
              className="btn btn-secondary btn-xs zoom-in canopy-mis-4x"
              onClick={zoomIn}
            >
              <Icon type="plus" />
            </button>
            {rotate && (
              <button
                className="btn btn-secondary btn-xs rotate canopy-mis-4x"
                onClick={rotate}
                onMouseEnter={onRotateMouseEnter}
                onMouseLeave={onRotateMouseLeave}
              >
                <Icon type="redo" />
              </button>
            )}
            {canArchive && (
              <ArchiveButton newUi={newUi} archive={() => archive(document)} />
            )}
          </div>
          {!hideDocumentsNav && (
            <>
              <button
                className="btn btn-secondary btn-xs previous canopy-mbe-4x"
                onClick={previous}
              >
                <Icon className="canopy-mie-4x" type="chevron-left" />
                {documentIndex + 1}
              </button>
              <small className="canopy-mx-4x canopy-mbe-4x">of</small>
              <button
                className="btn btn-secondary btn-xs next canopy-mbe-4x"
                onClick={next}
              >
                {documents.length}
                <Icon className="canopy-mis-4x" type="chevron-right" />
              </button>
            </>
          )}
          <a
            className="btn btn-secondary btn-xs canopy-mis-4x download canopy-mbe-4x"
            href={document.downloadUrl}
            onClick={onDownload}
          >
            <Icon type="cloud-download-alt" />
            <span className="d-none d-lg-inline canopy-mis-4x">
              Download this document
            </span>
          </a>
        </div>
      )}
    </div>
  )

  /**
   * This is the new DocumentsBrowser Actions UI, it has been migrated to use Canopy components
   * and accounts for some additional requirements identified by Jedi to support SupplierOrganizationInboxDocument
   */
  const hasDocuments = documents.length > 0
  const isFirstDocument = hasDocuments && documentIndex === 0
  const isLastDocument = hasDocuments && documentIndex + 1 === documents.length
  const currentPage = activeImageIndex ? activeImageIndex + 1 : 1

  const newToolbar = (
    <div className={styles.actions}>
      {hasDocuments && (
        <>
          {!hideDocumentsNav && (
            <div className={styles.documentNav}>
              <div>
                <div className={styles.start}>
                  Document {documentIndex + 1} of {documents.length}
                  <CanopyButton
                    variant="tertiary"
                    size="small"
                    onClick={previous}
                    disabled={isFirstDocument}
                    iconStart="angle-left"
                    accessibleText="Previous document"
                  />
                  <CanopyButton
                    variant="tertiary"
                    size="small"
                    onClick={next}
                    disabled={isLastDocument}
                    iconStart="angle-right"
                    accessibleText="Next document"
                  />
                </div>
              </div>
              <div className={styles.end}>
                {canArchive && (
                  <ArchiveButton
                    newUi={newUi}
                    archive={() => archive(document)}
                  />
                )}
              </div>
            </div>
          )}
          <div className={styles.documentControls}>
            <div className={styles.start}>
              <CanopyButton
                variant="secondary"
                size="small"
                iconStart="minus"
                accessibleText="Zoom out"
                disabled={zoom === Zoom.Min}
                onClick={zoomOut}
              />
              <CanopyButton
                variant="secondary"
                size="small"
                iconStart="plus"
                accessibleText="Zoom in"
                disabled={zoom === Zoom.Max}
                onClick={zoomIn}
              />
              {rotate && (
                <CanopyButton
                  variant="secondary"
                  size="small"
                  iconStart="arrow-rotate-right"
                  onClick={rotate}
                  accessibleText="Rotate clockwise"
                  onMouseEnter={onRotateMouseEnter}
                  onMouseLeave={onRotateMouseLeave}
                />
              )}
              <CanopyButton
                as="a"
                href={document.downloadUrl}
                variant="secondary"
                size="small"
                iconStart="file-arrow-down"
              >
                Download
              </CanopyButton>
            </div>
            <div className={styles.end}>
              <div className={styles.paginationLabel}>
                Page {currentPage} of {document.files.length}
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  )

  return newUi ? newToolbar : oldToolbar
}

export default Actions
