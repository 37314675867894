import React from "react"
import { highlight } from "utilities/select"
import Suggestion from "components/form/Select/Suggestion"
import Icon from "components/Icon"
import * as styles from "./index.module.scss"

interface Result {
  value: string
  label: string
  connected: boolean
}

interface Props {
  supplier: Result
  query: string
  isHighlighted: boolean
}

export default function SupplierSuggestion({
  supplier,
  query,
  isHighlighted,
}: Props) {
  const { connected } = supplier
  return (
    <Suggestion
      className={styles.suggestion}
      disabled={connected}
      unselectable={connected}
      isHighlighted={isHighlighted}
    >
      {highlight(supplier.label, query)}
      {connected && (
        <div>
          <div className="pill pill-warning">
            <Icon type="exclamation-triangle" />
            <span className="canopy-pis-2x">Already Connected</span>
          </div>
        </div>
      )}
    </Suggestion>
  )
}
