import { post } from "services/api"
import { shareRequestLinkUrl } from "../routes"
import { AxiosPromise } from "axios"

export const shareRequest = (
  documentationOrderId: string,
  values: {
    firstName: string
    lastName: string
    email: string
  }
): AxiosPromise => {
  return post(shareRequestLinkUrl(documentationOrderId), values)
}
