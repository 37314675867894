import React from "react"
import { Redirect, Route } from "react-router-dom"
import { RouteComponentProps } from "react-router"
import CreateAccount from "./CreateAccount"
import CreatePassword from "../../shared/components/CreatePassword"
import ExpiredToken from "../../shared/components/ExpiredToken"
import NewInvitation from "../../shared/components/NewInvitation"
import { User, Employment } from "../../shared/sharedTypes"
import { loginUrl } from "../urls"
import { withHashRouter } from "routers/HashRouter"

type Props = {
  updateUser(params): Promise<void>
  updatePassword(params): Promise<void>
  resendInvitation(): Promise<void>
  tokenExpired: boolean
  user: User
  employment: Employment
  titles: string[]
} & RouteComponentProps

enum Routes {
  Root = "/",
  Password = "/password",
  TokenExpired = "/token-expired",
  NewInvitation = "/new-invitation",
}

const InternalFacilitySignUp = ({
  updateUser,
  updatePassword,
  resendInvitation,
  employment,
  user,
  titles,
  history,
  tokenExpired,
}: Props) => {
  return (
    <>
      <Route exact path={Routes.Root}>
        {!tokenExpired ? (
          <CreateAccount
            user={user}
            employment={employment}
            updateUser={(values) =>
              updateUser(values).then(() =>
                history.push({
                  pathname: Routes.Password,
                })
              )
            }
            titles={titles}
          />
        ) : (
          <Redirect to={Routes.TokenExpired} />
        )}
      </Route>
      <Route exact path={Routes.Password}>
        <CreatePassword updatePassword={(values) => updatePassword(values)} />
      </Route>
      <Route exact path={Routes.TokenExpired}>
        <ExpiredToken
          resendInvitation={() =>
            resendInvitation().then(() => {
              history.push({ pathname: Routes.NewInvitation })
            })
          }
        />
      </Route>
      <Route exact path={Routes.NewInvitation}>
        <NewInvitation />
      </Route>
      <div className="actions">
        <span className="canopy-mie-2x">Already have an account?</span>
        <a className="link link-primary" href={loginUrl()}>
          Log in
        </a>
      </div>
    </>
  )
}

export default withHashRouter(InternalFacilitySignUp)
