// @ts-strict-ignore
import React, { useEffect, useState } from "react"
import { navigate } from "utilities/navigation"
import { ChooseSupplierModal } from "components/SupplierOrganizationChooseSupplierModal"
import {
  getSuppliers,
  urlForCreatingOrderWithSupplier,
} from "components/SupplierOrganizationChooseSupplierModal/api"
import { MaterialThemeProvider } from "themes/theme"
import NewOrderButton from "applications/SupplierOrganizationNewOrderButton/NewOrderButton"

const SupplierOrganizationNewOrderButton = ({
  supplierOrganizationId,
}: {
  supplierOrganizationId: string
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [suppliers, setSuppliers] = useState([])
  useEffect(() => {
    getSuppliers(supplierOrganizationId).then((sups) => setSuppliers(sups))
  }, [supplierOrganizationId])

  const onClick =
    suppliers.length === 1
      ? () => navigate(urlForCreatingOrderWithSupplier(suppliers[0].id))
      : () => setIsModalOpen(!isModalOpen)

  if (suppliers.length === 0) return null

  return (
    <MaterialThemeProvider>
      <NewOrderButton onClick={onClick} />
      <ChooseSupplierModal
        suppliers={suppliers}
        show={isModalOpen}
        close={() => setIsModalOpen(false)}
      />
    </MaterialThemeProvider>
  )
}
export default SupplierOrganizationNewOrderButton
