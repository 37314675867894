import React from "react"

interface Props {
  newAgreement: boolean
  name: string
}

const SupplierName: React.FC<Props> = ({ newAgreement, name }) => (
  <>
    <div data-testid="supplier-name">{name}</div>
    {newAgreement && (
      <div className="pill pill-info pill-basic ba color-info canopy-mis-4x canopy-mbe-0 bold">
        NEW
      </div>
    )}
  </>
)

export default SupplierName
