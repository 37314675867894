// @ts-strict-ignore
import React from "react"
import { Route, Switch } from "react-router-dom"
import * as api from "./api"
import * as routes from "applications/Workflow/routes"
import InternalDelivery from "./components/InternalDelivery"
import { DmeOrder } from "sharedTypes"
import { handleError } from "utilities/error"

type Props = {
  setDmeOrder(dmeOrder: DmeOrder): Promise<void>
  dmeOrder: DmeOrder
  nextPagePath?: string
  isPageChecked?: boolean
}

const Delivery = ({
  dmeOrder,
  setDmeOrder,
  nextPagePath,
  isPageChecked,
}: Props) => {
  const updateDelivery = (deliveryId, dmeOrderFulfillment) => {
    return api
      .updateDelivery(deliveryId, dmeOrderFulfillment)
      .then((response) => {
        setDmeOrder(response.data)
      })
      .catch((httpResponse) => Promise.reject(httpResponse.response.data))
  }

  const updateDeliveryAddress = (deliveryId, dmeOrderFulfillment) => {
    return api
      .updateDeliveryAddress(deliveryId, dmeOrderFulfillment)
      .then((response) => {
        setDmeOrder(response.data)
      })
      .catch((httpResponse) => Promise.reject(httpResponse.response.data))
  }

  const updateEmergencyContact = (emergencyContact) => {
    return api
      .updateEmergencyContact({ dmeOrderEmergencyContact: emergencyContact })
      .then(setDmeOrder)
  }

  const deleteEmergencyContact = (emergencyContact) => {
    return api.deleteEmergencyContact(emergencyContact).then(setDmeOrder)
  }

  const createDeliveryAddress = (lineItemGroupId, dmeOrderFulfillment) => {
    return api
      .createDelivery({ lineItemGroupId, dmeOrderFulfillment })
      .then((response) => {
        setDmeOrder(response.data)
      })
      .catch((httpResponse) => Promise.reject(httpResponse.response.data))
  }

  const updateLineItemGroupDeliveryAddress = (
    lineItemGroupId,
    dmeOrderFulfillment
  ) => {
    api
      .updateLineItemGroupDeliveryAddress(lineItemGroupId, dmeOrderFulfillment)
      .then((response) => {
        setDmeOrder(response.data)
      })
      .catch(handleError)
  }

  const updatePatientInfo = (values) => {
    return api
      .updatePatientInfo(values)
      .then(setDmeOrder)
      .catch((httpResponse) => Promise.reject(httpResponse.response.data))
  }

  const updateDeliveryData = (values) => {
    return api
      .updateDeliveryData(values)
      .then(setDmeOrder)
      .catch((httpResponse) => Promise.reject(httpResponse.response.data))
  }

  return (
    <Switch>
      <Route
        path={routes.deliveryPath.matcher}
        render={(innerProps) => {
          return (
            <>
              <InternalDelivery
                {...innerProps}
                dmeOrder={dmeOrder}
                setDmeOrder={setDmeOrder}
                updateLineItemGroupDeliveryAddress={
                  updateLineItemGroupDeliveryAddress
                }
                createDeliveryAddress={createDeliveryAddress}
                updateDelivery={updateDelivery}
                updateDeliveryAddress={updateDeliveryAddress}
                updateEmergencyContact={updateEmergencyContact}
                deleteEmergencyContact={deleteEmergencyContact}
                updatePatientInfo={updatePatientInfo}
                updateDeliveryData={updateDeliveryData}
                nextPagePath={nextPagePath}
                isPageChecked={isPageChecked}
              />
            </>
          )
        }}
      />
    </Switch>
  )
}

export default Delivery
