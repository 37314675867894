import React from "react"
import { RouteComponentProps } from "react-router"
import InternalAccountVerification from "./components/InternalAccountVerification"
import * as api from "./api"
import * as urls from "./urls"
import { navigate } from "utilities/navigation"
import { User } from "../shared/sharedTypes"

import { trackCreatePassword } from "../shared/tracking"

type Props = {
  user: User
  titles: string[]
  invitationToken: string
  tokenExpired: boolean
  reviewerDashboardDoctorId?: string
} & RouteComponentProps

function AccountVerification(props: Props) {
  const {
    user,
    invitationToken,
    tokenExpired,
    titles,
    reviewerDashboardDoctorId,
  } = props

  const updatePassword = (params) => {
    return api
      .updatePassword(user.externalId, invitationToken, params)
      .then(() => {
        trackCreatePassword(user)
        navigate(
          urls.joinFacilityUrl({
            reviewer_dashboard_doctor_id: reviewerDashboardDoctorId,
          })
        )
        return Promise.resolve()
      })
  }

  const updateUser = (params) => {
    return api.updateUser(user.externalId, invitationToken, params)
  }

  const resendInvitation = () => {
    return api.resendInvitation(user.externalId, invitationToken)
  }

  return (
    <InternalAccountVerification
      user={user}
      updateUser={updateUser}
      updatePassword={updatePassword}
      resendInvitation={resendInvitation}
      tokenExpired={tokenExpired}
      titles={titles}
    />
  )
}

export default AccountVerification
