import React from "react"
import classNames from "classnames"
import { ServiceTier } from "sharedTypes"
import logo from "logo-transparent.png"
import * as styles from "./index.module.scss"

interface Supplier {
  serviceTier: ServiceTier
}
type Props = {
  className?: string
  supplier: Supplier
}

const ServiceTierBadge = ({ className, supplier }: Props) => {
  const connectedSupplier = supplier.serviceTier !== ServiceTier.Basic
  return (
    <span
      className={classNames("pill pill-shadow", className, styles.pill, {
        "pill-solid pill-info": connectedSupplier,
        "pill-default pill-basic": !connectedSupplier,
      })}
    >
      {connectedSupplier && <img src={logo} />}
      {connectedSupplier ? "Connected" : "eFax Only"}
    </span>
  )
}

export default ServiceTierBadge
