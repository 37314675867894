import React from "react"
import Overlay from "components/Overlay"
import { handleError } from "utilities/error"

const LENGTH_OF_DELAY = 300

function withInitialData<InitialData, Props extends {}>(
  fetcher: (props: Props) => Promise<InitialData>,
  Placeholder?: React.ComponentType<Props>
) {
  return <P extends Props & { initialData: InitialData }>(
    Component: React.ComponentType<P>
  ): React.ComponentType<Omit<P, "initialData">> =>
    // @ts-ignore
    class extends React.Component<P> {
      // @ts-ignore
      mounted: boolean
      // @ts-ignore
      timeout: number

      state = {
        delayComplete: false,
        loadComplete: false,
        loadResult: null,
      }

      componentDidMount() {
        this.mounted = true
        this.timeout = window.setTimeout(
          () => this.setState({ delayComplete: true }),
          LENGTH_OF_DELAY
        )
        fetcher(this.props)
          .then((loadResult) => {
            if (this.mounted) {
              this.setState({ loadResult, loadComplete: true })
            }
          })
          .catch((e) => {
            if (this.mounted) {
              handleError(e)
            }
          })
      }

      componentWillUnmount() {
        clearTimeout(this.timeout)
        this.mounted = false
      }

      render() {
        const { loadComplete, loadResult, delayComplete } = this.state

        if (loadComplete) {
          return <Component {...this.props} initialData={loadResult} />
        }
        return Placeholder ? (
          <Placeholder {...this.props} />
        ) : (
          <Overlay showSpinner active={delayComplete} />
        )
      }
    }
}

export default withInitialData
