import { Tab } from "components/Tabs"
import React from "react"
import { SupplierFilter } from "../../sharedTypes"
import { SvgIconComponent } from "@material-ui/icons"

interface Props {
  value: SupplierFilter
  text: string
  Icon: SvgIconComponent
  supplierFilter: SupplierFilter
  setSupplierFilter: (supplierFilter: SupplierFilter) => void
}

const SupplierTab: React.FC<Props> = ({
  value,
  text,
  Icon,
  supplierFilter,
  setSupplierFilter,
}) => {
  const selected = supplierFilter === value
  return (
    <Tab
      key={value}
      selected={selected}
      onClick={selected ? undefined : () => setSupplierFilter(value)}
    >
      <Icon className="v-align-top" /> {text}
    </Tab>
  )
}

export default SupplierTab
