import {
  getItemFromSessionStorage,
  setItemInSessionStorage,
} from "./sessionStorageHelper"
import { useState } from "react"

export function getFromSessionStorage<T>({
  key,
  defaultValue,
}: {
  key: string
  defaultValue: T
}): T {
  const value = getItemFromSessionStorage(key)
  if (!value) return defaultValue
  const { storedValue, expirationTime } = JSON.parse(value)
  if (expirationTime <= Date.now()) return defaultValue
  return storedValue || defaultValue
}

export function useSessionBackedState<T>({
  defaultValue,
  expirationInSeconds,
  key,
}: {
  defaultValue: T
  expirationInSeconds: number
  key: string
}): [T, (T) => void] {
  const [value, setValue] = useState<T>(
    getFromSessionStorage({ key, defaultValue })
  )
  const sessionSet = (val: T): void => {
    setItemInSessionStorage(
      key,
      JSON.stringify({
        storedValue: val,
        expirationTime: Date.now() + expirationInSeconds * 1000,
      })
    )
    setValue(val)
  }
  return [value, sessionSet]
}
