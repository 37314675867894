import React, { useState } from "react"
import EmptyCart from "./EmptyCart"
import AddProductsLink from "./AddProductsLink"
import { LineItemGroup } from "sharedTypes"
import Overlay from "components/Overlay"
import { Notifications } from "./sharedTypes"
import CartPrice from "./CartPrice"
import { unique } from "utilities/array"
import CartLineItemGroup from "./CartLineItemGroup/CartLineItemGroup"
import * as styles from "../components/CartLineItemGroup/CartLineItemGroup.module.scss"

interface Props {
  removeLineItemGroup(lineItemGroup: LineItemGroup): Promise<void>
  updateLineItemGroup(
    lineItemGroup: LineItemGroup,
    params: { quantity: number }
  ): Promise<void>
  lineItemGroups: LineItemGroup[]
  canUpdate: boolean
  notifications: Notifications
  formularyPriceEnabled: boolean
}

const InternalCart: React.FC<Props> = ({
  formularyPriceEnabled,
  lineItemGroups,
  removeLineItemGroup,
  updateLineItemGroup,
  canUpdate,
  notifications,
}) => {
  const [loading, setLoading] = useState<boolean>(false)

  if (lineItemGroups.length === 0) {
    return <EmptyCart canUpdate={canUpdate} />
  }

  const onRemoveLineItemGroup = (lineItemGroup: LineItemGroup) => {
    setLoading(true)
    return removeLineItemGroup(lineItemGroup)
      .then(() => setLoading(false))
      .catch(() => setLoading(false))
  }

  const notificationFor = (lineItemGroup: LineItemGroup) =>
    notifications.find(
      (group) => group.lineItemGroupExternalId === lineItemGroup.id
    )

  const catalogSupplierId = () => {
    const uniqueSupplierIdsForCartItems = unique(
      lineItemGroups.map((group) => group.packageSupplier.externalId)
    )
    const allItemsInCartAreForSameSupplier =
      uniqueSupplierIdsForCartItems.length === 1

    return allItemsInCartAreForSameSupplier
      ? uniqueSupplierIdsForCartItems[0]
      : undefined
  }

  return (
    <>
      <Overlay active={loading} showSpinner className={styles.internalCart}>
        {lineItemGroups.map((lineItemGroup) => (
          <CartLineItemGroup
            notifications={notificationFor(lineItemGroup)}
            lineItemGroup={lineItemGroup}
            key={lineItemGroup.id}
            canUpdate={canUpdate}
            removeLineItemGroup={onRemoveLineItemGroup}
            updateLineItemGroup={updateLineItemGroup}
            formularyPriceEnabled={formularyPriceEnabled}
          />
        ))}
        <AddProductsLink
          canUpdate={canUpdate}
          supplierId={catalogSupplierId()}
        />
        <CartPrice
          lineItemGroups={lineItemGroups}
          formularyPriceEnabled={formularyPriceEnabled}
        />
      </Overlay>
    </>
  )
}

export default InternalCart
