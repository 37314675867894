import React, { useContext, useCallback } from "react"
import { instrumentFeatureFlagEvaluation } from "services/clientsideInstrumentation"

import { FeatureFlagName, FeatureFlagInput } from "sharedTypes"

const FeatureFlagContext = React.createContext<FeatureFlagInput>({})

export const FeatureFlagProvider = (props: {
  flags?: FeatureFlagInput
  children: any
}) => {
  return (
    <FeatureFlagContext.Provider value={props.flags || {}}>
      {props.children}
    </FeatureFlagContext.Provider>
  )
}

const featureFlagEvaluationFunction = (
  flagName: FeatureFlagName,
  flagSettings: FeatureFlagInput
): boolean | string => {
  const evaluatedValue = flagSettings[flagName] || false

  instrumentFeatureFlagEvaluation(flagName, !!evaluatedValue)

  return evaluatedValue
}

export const useFeatureFlags = (): {
  isFeatureEnabled: (flagName: FeatureFlagName) => boolean
  getFlagValue: (flagName: FeatureFlagName) => boolean | string
} => {
  const flagSettings = useContext(FeatureFlagContext)
  const getFlagValue = useCallback(
    (flagName: FeatureFlagName) =>
      featureFlagEvaluationFunction(flagName, flagSettings),
    [flagSettings]
  )
  function isFeatureEnabled(flagName: FeatureFlagName) {
    return !!getFlagValue(flagName)
  }

  return { isFeatureEnabled, getFlagValue }
}

// For use in test setup
const wrap = (Component, flags?: FeatureFlagInput) => (props) => (
  <FeatureFlagProvider flags={flags || {}}>
    <Component {...props} />
  </FeatureFlagProvider>
)

export const withFeatureFlagProvider = (Component, flags?: FeatureFlagInput) =>
  wrap(Component, flags)
