import React from "react"
import { User } from "sharedTypes"
import { CanopyButton } from "@parachutehealth/canopy-button"
import classNames from "classnames"
import * as styles from "./InternalActions.module.scss"
import { convertToFullUser, removeUserFromFacility } from "../api"
import { reload, navigate } from "utilities/navigation"
import SendEmailButtons from "./SendEmailButtons"

export type Props = {
  user: User & { availableForSignUp: boolean }
  currentEmployerName: string
  canConvertToFullUser: boolean
  canRemoveUserFromFacility: boolean
  canSendWelcomeEmail: boolean
  invitePreviouslySent: boolean
}

const InternalActions: React.FC<Props> = ({
  user,
  currentEmployerName,
  canConvertToFullUser,
  canRemoveUserFromFacility,
  canSendWelcomeEmail,
  invitePreviouslySent,
}) => {
  const acceptUser = async () => {
    await convertToFullUser(user.userId)
    reload()
  }

  const handleRemoveUserFromFacility = (userId) => {
    const userConfirmed = window.confirm(
      "Are you sure you want to remove this user?"
    )

    if (userConfirmed) {
      removeUserFromFacility(userId).then(({ redirectUrl }) => {
        navigate(redirectUrl)
      })
    } else {
      return null
    }
  }

  return (
    <div className="panel">
      <div className="panel-header">Actions</div>
      <div className="panel-body">
        {canConvertToFullUser ? (
          <div>
            <div className="canopy-mbe-12x">
              <h1 className="text-center">
                {`Want to add ${user.firstName} ${user.lastName} to ${currentEmployerName}?`}
              </h1>
            </div>
            <div className="canopy-px-48x">
              <CanopyButton
                fullWidth
                variant="primary"
                type="button"
                onClick={acceptUser}
              >
                Yes, add user
              </CanopyButton>
              {canRemoveUserFromFacility && (
                <CanopyButton
                  fullWidth
                  variant="danger"
                  type="button"
                  onClick={() => {
                    handleRemoveUserFromFacility(user.userId)
                  }}
                  className={classNames(styles.denyUser, "canopy-mbs-8x")}
                >
                  No, remove user
                </CanopyButton>
              )}
            </div>
          </div>
        ) : (
          <>
            {canSendWelcomeEmail && (
              <SendEmailButtons
                user={user}
                invitePreviouslySent={invitePreviouslySent}
              />
            )}
            {canRemoveUserFromFacility && (
              <CanopyButton
                fullWidth
                variant="danger"
                type="button"
                onClick={() => {
                  handleRemoveUserFromFacility(user.userId)
                }}
                className="canopy-mbs-2x"
              >
                {`Remove From ${currentEmployerName}`}
              </CanopyButton>
            )}
          </>
        )}
      </div>
    </div>
  )
}

export default InternalActions
