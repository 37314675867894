import { BasicFulfillment } from "sharedTypes"
import { unique } from "utilities/array"

type Props = {
  fulfillments: BasicFulfillment[]
  createdAt: string
}

export type DeliveryDate = {
  type: DeliveryDateType
  value: string
}

export enum DeliveryDateType {
  CreatedAt,
  Actual,
  Expected,
  Requested,
}

export const getDeliveryDates = ({
  fulfillments,
  createdAt,
}: Props): DeliveryDate[] => {
  if (fulfillments.length === 0) {
    return [{ type: DeliveryDateType.CreatedAt, value: createdAt }]
  }
  const deliveryDates = fulfillments
    .map(({ actualDeliveryDate, expectedDeliveryDate, deliveryDate }) => {
      if (actualDeliveryDate) {
        return { type: DeliveryDateType.Actual, value: actualDeliveryDate }
      }
      if (expectedDeliveryDate) {
        return { type: DeliveryDateType.Expected, value: expectedDeliveryDate }
      }
      if (deliveryDate) {
        return { type: DeliveryDateType.Requested, value: deliveryDate }
      }
      return null
    })
    .filter(Boolean)
  if (deliveryDates.length === 0) {
    return [{ type: DeliveryDateType.CreatedAt, value: createdAt }]
  }
  return unique(deliveryDates)
}
