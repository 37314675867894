import { DmeOrderSubmissionWarningsEnum } from "graphql/__generated__/graphql"
import {
  ORDERED_STEPS,
  StepId,
  stepIdToStepTitle,
} from "../../Workflow/utilities/steps"
import { lowercase } from "utilities/string"

export const warningToStepMap: {
  [key in DmeOrderSubmissionWarningsEnum]: StepId
} = {
  billing_address: StepId.DELIVERY_STEP,
  billing_phone_number: StepId.DELIVERY_STEP,
  carrier_authorization_number: StepId.PAYMENT_STEP,
  delivery_phone_number: StepId.DELIVERY_STEP,
  diagnoses: StepId.CLINICAL_STEP,
  discharge_date: StepId.CLINICAL_STEP,
  ordering_doctor: StepId.SIGNATURE_STEP,
  pecos_certification: StepId.SIGNATURE_STEP,
  primary_insurance_policy: StepId.PAYMENT_STEP,
  product_requirements: StepId.PRODUCTS_STEP,
  products: StepId.PRODUCTS_STEP,
  rx_details: StepId.PRODUCTS_STEP,
  secondary_insurance_policy: StepId.PAYMENT_STEP,
  signature_ready_documentation: StepId.CLINICAL_STEP,
  signature_request_method: StepId.SIGNATURE_STEP,
  tertiary_insurance_policy: StepId.PAYMENT_STEP,
}

const actionColumnLinkText = (
  warnings: { key: DmeOrderSubmissionWarningsEnum }[]
) => {
  const steps = warnings.map((warning) => warningToStepMap[warning.key])

  const currentStep = ORDERED_STEPS.find((orderedStep) =>
    steps.includes(orderedStep)
  )

  if (currentStep) {
    return `Complete ${lowercase(stepIdToStepTitle(currentStep))}`
  } else {
    return "Review and send"
  }
}

export default actionColumnLinkText
