// @ts-strict-ignore
import React, { useCallback, useMemo, useState } from "react"
import Table from "components/Table"
import { exportResolvedSignatureRequestsCSV } from "./api"
import {
  fetchSignatureRequests,
  SignatureRequestQueryParameters,
} from "../../api"
import { useDebounce } from "hooks/useDebounce"
import TableOverviewPresentation from "components/TableOverviewPresentation"
import { InfiniteLoadTable } from "components/Table/InfiniteLoadTable"
import makeTableColumnDefinitions from "./tableColumnDefinitions"
import * as styles from "./index.module.scss"
import { Paper } from "@material-ui/core"
import { ResolutionStatusToggleFilter } from "./ResolutionStatusToggleFilter"
import { DonePath } from "../../routes"
import SignatureRequestDetailView from "../SignatureRequestDetailView"
import { DoctorSignatureRequest, signatureStatus } from "../../api"
import { Route } from "react-router-dom"
import { handleError } from "utilities/error"
import Alert from "components/Alert"
import { Button } from "components/form"
import { EventActions, trackEvent } from "../../utilities/tracking"
import PatientSearchField from "../PatientSearchField"
import Link from "components/Link"
import {
  heightCalculation,
  infiniteTableHeightCalculation,
} from "../../utilities/heightCalculation"
import { useFlashContext } from "../../hooks/useFlashContext"
import { useDoctorContext } from "../../hooks/useDoctorContext"
import { useStyles } from "themes/theme"
import classNames from "classnames"

const paperHeightStyles = (flash) => ({
  paper: {
    height: `${heightCalculation(flash)}`,
  },
})

const SignatureRequestDoneTab = () => {
  const { doctor } = useDoctorContext()
  const [currentCount, setCurrentCount] = useState(0)
  const [loading, setLoading] = useState(false)
  const [searchParameters, setSearchParameters] = useState<
    SignatureRequestQueryParameters
  >({ status: signatureStatus.DONE, searchKeywords: undefined })
  const [selectedSignatureRequest, setSelectedSignatureRequest] = useState<
    DoctorSignatureRequest
  >()
  const [showDownloadAlert, setShowDownloadAlert] = useState(false)

  const tableColumnDefinitions = makeTableColumnDefinitions(
    setSelectedSignatureRequest,
    doctor.id
  )

  const getSignatureRequests = async (
    doctorId: string,
    page: number,
    searchParameters: SignatureRequestQueryParameters
  ) => {
    const response = await fetchSignatureRequests({
      doctorId,
      page,
      pageSize: 25,
      searchParameters,
    })
    const { results, currentCount, hasNextPage } = response
    setCurrentCount(currentCount)

    setShowDownloadAlert(false)
    setLoading(false)
    return {
      records: results.map((request) => ({
        ...request,
        key: request.gid,
      })),
      totalCount: currentCount,
      hasNextPage: hasNextPage,
    }
  }
  const debouncedGetSignatureRequests = useDebounce(getSignatureRequests)
  const loadSignatureRequests = useCallback(
    (newPage) =>
      debouncedGetSignatureRequests(doctor.id, newPage, {
        ...searchParameters,
      }),
    [doctor.id, searchParameters, debouncedGetSignatureRequests]
  )
  const updateSearchParameters = (
    updatedParameters: Partial<SignatureRequestQueryParameters>
  ) => {
    setSearchParameters((oldState) => ({ ...oldState, ...updatedParameters }))
  }

  const exportSignatureRequestsCSV = (doctorId: string) =>
    exportResolvedSignatureRequestsCSV({ doctorId })
      .then(() => {
        setShowDownloadAlert(true)
      })
      .catch(handleError)

  const reviewerEmail = window.parachute.currentUserEmail

  const handleSearch = (query: string) =>
    updateSearchParameters({ searchKeywords: query })

  const showExportButton =
    currentCount > 0 && searchParameters.status === signatureStatus.DONE

  const noSearchResults =
    currentCount === 0 && !!searchParameters.searchKeywords

  const flash = useFlashContext()
  const muiStyles = useMemo(() => paperHeightStyles(flash), [flash])
  const muiClasses = useStyles(muiStyles)

  return (
    <Route
      path={`${DonePath(doctor.id)}/:signatureRequestGid?`}
      render={({ match }) => {
        const showDetailView =
          !!selectedSignatureRequest &&
          match.params.signatureRequestGid === selectedSignatureRequest.gid

        return (
          <>
            <Paper
              elevation={0}
              className={classNames(styles.container, muiClasses.paper)}
              hidden={showDetailView}
            >
              {showDownloadAlert && (
                <Alert status="success">
                  <span>
                    <strong>CSV file is being exported!</strong> It will be sent
                    to you in an email at {reviewerEmail} when completed.
                  </span>
                </Alert>
              )}
              <ResolutionStatusToggleFilter
                updateSearchParameters={updateSearchParameters}
                initialState={null}
              />
              <fieldset
                className={`d-flex flex-row align-items-center float-right ${styles.actionBar}`}
              >
                <PatientSearchField
                  onSearch={handleSearch}
                  className={`float-left ${styles.searchField}`}
                  value={searchParameters.searchKeywords || ""}
                  autoFocus={!!searchParameters.searchKeywords}
                  type="text"
                />
                {showExportButton && (
                  <Button
                    onClick={() => {
                      trackEvent(EventActions.ReviewerExportCompletedOrders)
                      exportSignatureRequestsCSV(doctor.id)
                    }}
                    className={`btn btn-brand float-left ${styles.exportButton}`}
                  >
                    Export CSV
                  </Button>
                )}
              </fieldset>
              <TableOverviewPresentation
                results={`${loading ? "x" : currentCount} requests`}
              />
              <InfiniteLoadTable
                loadPage={loadSignatureRequests}
                Table={Table}
                handleLoadingIndicators={true}
                placeholderRowHeight={50}
                tableProps={{
                  tableColumns: tableColumnDefinitions,
                  maxHeight: infiniteTableHeightCalculation(flash),
                }}
              />
              {noSearchResults && (
                <div className="text-center">
                  <Link
                    onClick={() => {
                      setLoading(true)
                      updateSearchParameters({
                        searchKeywords: undefined,
                      })
                    }}
                    className="link link-sm"
                  >
                    <small>Clear Search Filter</small>
                  </Link>
                </div>
              )}
            </Paper>
            {showDetailView && (
              <SignatureRequestDetailView
                linkBackTo={DonePath(doctor.id)}
                doctorId={selectedSignatureRequest.doctorId}
                signatureRequest={selectedSignatureRequest}
              />
            )}
          </>
        )
      }}
    />
  )
}

export default SignatureRequestDoneTab
