import React from "react"
import Card from "../Card"
import { DmeOrder, OfferingType, RxDetailsOutput } from "sharedTypes"
import { toList } from "utilities/array"
import ServiceLineItemGroup from "./ServiceLineItemGroup"
import DocumentLineItemGroup from "./DocumentLineItemGroup"
import EquipmentLineItemGroup from "./EquipmentLineItemGroup"

type Props = {
  dmeOrder: DmeOrder
  rxDetailsOutputs: RxDetailsOutput[]
}

const CartCard = (props: Props) => {
  const { dmeOrder, rxDetailsOutputs } = props
  const { lineItemGroups } = dmeOrder

  const renderLineItemGroup = (lineItemGroup) => {
    const key = lineItemGroup.id
    const lineItemGroupOutputs = rxDetailsOutputs.filter(
      (output) => output.lineItemGroupId === lineItemGroup.id
    )
    switch (lineItemGroup.offeringType) {
      case OfferingType.Service:
        return (
          <ServiceLineItemGroup
            key={key}
            lineItemGroup={lineItemGroup}
            rxDetailsOutputs={lineItemGroupOutputs}
            dmeOrder={dmeOrder}
          />
        )
      case OfferingType.O2Recert:
        return (
          <DocumentLineItemGroup
            key={key}
            lineItemGroup={lineItemGroup}
            rxDetailsOutputs={lineItemGroupOutputs}
          />
        )
      default:
        return (
          <EquipmentLineItemGroup
            key={key}
            lineItemGroup={lineItemGroup}
            rxDetailsOutputs={lineItemGroupOutputs}
          />
        )
    }
  }

  return (
    <Card
      title="Cart"
      summary={toList(
        lineItemGroups.map((lineItem) => lineItem.packageName),
        3
      )}
    >
      {lineItemGroups.map(renderLineItemGroup)}
    </Card>
  )
}

export default CartCard
