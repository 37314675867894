import React from "react"
import SelectFilter from "components/SelectFilter"
import { getClinicians } from "applications/SupplierOrganizationDashboard/components/DashboardFilters/api"
import { DocumentationOrderQueryParameters } from "../fetchOrders"

export const CliniciansSelectFilter = ({
  updateSearchParameters,
  clinicians,
}: {
  updateSearchParameters(
    updatedParameters: Partial<DocumentationOrderQueryParameters>
  ): void
  clinicians: string[] | undefined
}) => {
  return (
    <SelectFilter
      name="clinician"
      label="Clinicians"
      fetchOptions={(term) =>
        getClinicians(term).then((doctors) =>
          doctors.map((d) => ({
            label: `${d.firstName} ${d.lastName} ${d.npi} ${d.state}`,
            value: d.doctorId,
          }))
        )
      }
      initialValues={clinicians}
      onChange={(values) => {
        return updateSearchParameters({ clinicians: values.clinician })
      }}
    />
  )
}
