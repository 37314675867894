import React from "react"
import * as styles from "./external-system-ids.module.scss"

const ExternalSystemIds = ({ externalSystemError, externalSystemIds }) => {
  if (externalSystemError) {
    return <span className={styles.error}>ERROR</span>
  }
  const ids = externalSystemIds || []
  return (
    <>
      {ids.map(({ text, url }, index) => {
        return (
          <span key={text}>
            {url ? <a href={url}>{text}</a> : text}
            {index < ids.length - 1 && <>, </>}
          </span>
        )
      })}
    </>
  )
}

export default ExternalSystemIds
