import React, { useState } from "react"
import AsyncSelect from "react-select/async"
import classNames from "classnames"
import { highlight } from "utilities/select"
import { useDebounce } from "hooks/useDebounce"
import { fetchSupplierLegalEntities } from "applications/AsyncSupplierLegalEntitySelect/api"
import { camelizeKeys } from "humps"
import cx from "classnames"
import { Option } from "components/form/Select"
import Link from "components/Link"

export type SupplierLegalEntity = {
  id: string
  searchId?: number
  name: string
}

type Props = {
  currentSupplierLegalEntity: {
    id: string
    search_id: number
    name: string
  }
  supplierId: number
  legalEntityAssignmentMethod: string
  editLegalEntityPath: string
  serviceAreaOverride: boolean
  supplierLegalEntityControlledByServiceArea: boolean
}

type ServiceAreaSubLabelProps = {
  editLegalEntityPath: string
}

const ServiceAreaSubLabel = (props: ServiceAreaSubLabelProps) => {
  return (
    <div className="sub-label">
      To change the Supplier Legal Entity on this facility, update the&nbsp;
      <Link
        href={props.editLegalEntityPath}
        target="_blank"
        className="color-brand"
      >
        service area
      </Link>
      .
    </div>
  )
}

const AsyncSupplierLegalEntitySelect = (props: Props) => {
  const convertToOption = (obj?: SupplierLegalEntity) => {
    if (obj && obj.searchId) {
      return {
        value: obj.searchId,
        label: obj.name,
        ...obj,
      }
    } else {
      return {
        value: "",
        label: "Select...",
      }
    }
  }

  const ALL_SEARCH = ""
  const renderSuggestion = (suggestion, { query, isSelected }) => (
    <div className={classNames({ highlight: isSelected })}>
      {highlight(suggestion.label, query)}
    </div>
  )

  const [query, setQuery] = useState(ALL_SEARCH)
  const [labelClass, setLabelClass] = useState("")
  const [serviceAreaOverride, setServiceAreaOverride] = useState(
    props.serviceAreaOverride
  )

  const debouncedSupplierLegalEntities = useDebounce(fetchSupplierLegalEntities)

  const fetchOptions = () =>
    debouncedSupplierLegalEntities(query).then(({ data }) =>
      data.supplierLegalEntities.map(convertToOption)
    )

  const handleChange = (option: Option, { action }) => {
    if (
      action === "clear" ||
      option.value !== props.currentSupplierLegalEntity.search_id
    ) {
      setLabelClass("")
    }
  }

  const controlledByServiceArea = () => {
    return (
      props.supplierLegalEntityControlledByServiceArea && !serviceAreaOverride
    )
  }

  return (
    <>
      <div className="form-group">
        <label className="col-form-label">Supplier legal entity</label>
        {controlledByServiceArea() && (
          <ServiceAreaSubLabel
            editLegalEntityPath={props.editLegalEntityPath}
          />
        )}
        <AsyncSelect
          id="supplier_legal_entity_select"
          isDisabled={controlledByServiceArea()}
          isSearchable
          defaultOptions
          cacheOptions
          isClearable
          loadOptions={fetchOptions}
          onInputChange={(inputValue: string) => {
            setLabelClass("")
            setQuery(inputValue)
          }}
          onChange={(option: Option, action: { action: string }) =>
            handleChange(option, action)
          }
          defaultValue={convertToOption(
            camelizeKeys(props.currentSupplierLegalEntity)
          )}
          name="fulfillment_agreement[supplier_legal_entity_id]"
          placeholder="Select..."
          className={cx(
            labelClass,
            "select2-selection select2-selection--multiple"
          )}
          renderOption={renderSuggestion}
        />
      </div>
      <div className="form-group">
        <div className="checkbox">
          <label>
            <input
              type="hidden"
              name="fulfillment_agreement[service_area_override]"
              value="false"
            />
            <input
              id="fulfillment_agreement[service_area_override]"
              name="fulfillment_agreement[service_area_override]"
              type="checkbox"
              value={serviceAreaOverride.toString()}
              checked={serviceAreaOverride}
              onChange={() => setServiceAreaOverride(!serviceAreaOverride)}
            />
            Set legal entity for this facility above and override service area
            changes
          </label>
        </div>
      </div>
    </>
  )
}

export default AsyncSupplierLegalEntitySelect
