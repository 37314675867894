// @ts-strict-ignore
import axios, { AxiosPromise } from "axios"
import { camelizeKeys, decamelizeKeys } from "humps"
import { errorResponseHandler } from "./interceptors"
import { employerPrefix, EmployerType } from "utilities/url"
import { filterNullValues } from "./utilities"

window.parachute = window.parachute || {}

export function getAuthorizationHeaders() {
  return {
    "X-CSRF-Token": window.parachute.authenticity_token,
  }
}

axios.interceptors.response.use(
  (response) => response,
  (error) => errorResponseHandler(error)
)

const request = (
  method,
  url: string,
  data = {},
  params = {},
  headers = {}
): AxiosPromise => {
  return axios({
    method,
    url,
    data,
    params: decamelizeKeys(params),
    headers: { ...headers, ...getAuthorizationHeaders() },
    transformRequest: [].concat(
      (data) => decamelizeKeys(data),
      axios.defaults.transformRequest
    ),
    transformResponse: [].concat(axios.defaults.transformResponse, (data) =>
      camelizeKeys(data)
    ),
  })
}

interface SsrRequestHeaders {
  Authorization: string
  Accept: string
}

/*
  Note that body params aren't supported in the HTTP spec and
  this conversion is done by the axios library.
  Axios library can handle flat params but not nested params,
  which is the reason for stronger params.
  If nested params are expected by the API,
  consider sending them as a part of the url.
  Example: /foo?bar[baz]=qux
*/

interface AxiosGetParams {
  [key: string]: string | number | boolean | string[] | number[] | boolean[]
}

export function get(
  url: string,
  params?: AxiosGetParams,
  headers?: SsrRequestHeaders
) {
  return request("get", url, {}, filterNullValues(params) || {}, headers)
}

export function post(url: string, data?, headers?: SsrRequestHeaders) {
  return request("post", url, data, {}, headers)
}

export function put(url: string, data?, headers?: SsrRequestHeaders) {
  return request("put", url, data, {}, headers)
}

export function patch(url: string, data?, headers?: SsrRequestHeaders) {
  return request("patch", url, data, {}, headers)
}

export function del(url: string, data?) {
  return request("delete", url, data, {})
}

function readFileAsBuffer(file) {
  return new Promise(function (resolve, reject) {
    const reader = new FileReader()
    reader.onload = function () {
      resolve(reader.result)
    }
    reader.onerror = function () {
      reject(reader.error)
    }
    reader.readAsArrayBuffer(file)
  })
}

export function uploadDocumentToUrl(
  url: string,
  file,
  source = null,
  name = ""
) {
  const contentType = file.type || "application/pdf"
  return readFileAsBuffer(file).then((buffer) => {
    return axios({
      method: "post",
      url,
      data: buffer,
      headers: {
        "Content-Type": contentType,
        ...getAuthorizationHeaders(),
      },
      params: { upload_source: source, file_name: file.name, name: name },
      transformResponse: [].concat(axios.defaults.transformResponse, (data) =>
        camelizeKeys(data)
      ),
    })
  })
}

export function createDocumentDownload(
  dmeOrderId,
  documentKey,
  dmeOrderDocumentationRequirementId,
  employerType: EmployerType = null,
  employerId: string = null
) {
  return post(
    `${employerPrefix(employerType, employerId)}/document_downloads.json`,
    {
      documentDownload: {
        documentKey,
        dmeOrderId,
        dmeOrderDocumentationRequirementId,
      },
    }
  )
}

export function getDocumentDownload(
  downloadId,
  employerType: EmployerType = null,
  employerId: string = null
) {
  return get(
    `${employerPrefix(
      employerType,
      employerId
    )}/document_downloads/${downloadId}.json`
  )
}
