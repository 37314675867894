import {
  Context,
  DmeOrder,
  DmeOrderWarning,
  DmeOrderWarningKey,
} from "../../sharedTypes"
import {
  ORDERED_STEPS,
  PRODUCTS_STEP,
  REVIEW_STEP,
  SIGNATURE_STEP,
  StepContext,
  StepId,
} from "../Workflow/utilities/steps"
import { warningsByStep } from "../Workflow/utilities/validation"

const warningKeyToStepId = (key: DmeOrderWarningKey): StepId | undefined => {
  switch (key) {
    case "not_addable_package_configuration":
    case "product_requirements":
    case "proof_of_delivery":
      return PRODUCTS_STEP
    case "medicare_signing_ability_unknown":
      return SIGNATURE_STEP
    case "supplier_signature":
      return REVIEW_STEP
    default:
      return undefined
  }
}

// This returns an object whose keys are all possible values
// of `StepId` (in order), and whose values are empty arrays.
const buildEmptyStepsWithWarningsAccumulator = (): Record<
  StepId,
  DmeOrderWarning[]
> => {
  return ORDERED_STEPS.reduce(
    (acc, stepId) => ({ ...acc, [stepId]: [] }),
    {}
  ) as Record<StepId, DmeOrderWarning[]>
}
export const groupWarningsByStep = (
  warnings: DmeOrderWarning[],
  context: Context,
  dmeOrder: DmeOrder
): [StepId, DmeOrderWarning[]][] => {
  const stepContext: StepContext = {
    currentEmployer: context.currentEmployer,
    isOrderTransferred: dmeOrder.everTransferred,
  }

  const steps = warningsByStep(stepContext)
  const stepsWithWarnings = buildEmptyStepsWithWarningsAccumulator()

  warnings.forEach((warning) => {
    let stepId = steps.find((s) => s.key === warning.key)?.step

    if (!stepId) {
      stepId = warningKeyToStepId(warning.key)
    }

    if (stepId) {
      stepsWithWarnings[stepId].push(warning)
    }
  })

  return Object.entries(stepsWithWarnings).filter(
    ([_, warnings]) => warnings.length > 0
  ) as [StepId, DmeOrderWarning[]][]
}
