import React from "react"
import { Doctor } from "sharedTypes"
import DoctorCard from "./DoctorCard"

type Props = {
  doctors: Doctor[]
  updateDoctor(doctor: Doctor, params): Promise<void>
  removeDoctor(doctor: Doctor): Promise<void>
}

const DoctorGrid = ({ doctors, updateDoctor, removeDoctor }: Props) => {
  return (
    <div className="row">
      {doctors.map((doctor) => (
        <div className="col-md-4" key={doctor.doctorId}>
          <DoctorCard
            doctor={doctor}
            updateDoctor={updateDoctor}
            removeDoctor={removeDoctor}
          />
        </div>
      ))}
    </div>
  )
}

export default DoctorGrid
