import React, { ReactNode, createRef, useEffect } from "react"
import NativeSwiper from "components/Swiper"
import * as styles from "../index.module.scss"

type Props = {
  id?: string
  children: ReactNode
  onScroll?(node: HTMLElement): void
  className?: string
}

function Swiper({ id, onScroll, children, className }: Props) {
  const ref = createRef<HTMLDivElement>()
  useEffect(() => {
    const swiper = new NativeSwiper(ref.current, { onScroll })
    return () => {
      swiper.destroy()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div
      id={id}
      ref={ref}
      className={`${styles.swiper} ${className ? ` ${className}` : ""}`}
    >
      {children}
    </div>
  )
}

export default Swiper
