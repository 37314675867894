import React from "react"
import TextField from "components/form/TextField"
import { isNpiValid, NPI_VALIDATION_MESSAGE } from "utilities/npi"

interface Props {
  name: string
  label: string
  placeholder?: string
  optional?: boolean
  validateField?: boolean
}

function NpiField({
  name,
  label,
  placeholder,
  optional,
  validateField,
}: Props) {
  const validate = (value) => {
    if (optional && !value) {
      return
    }
    if (!isNpiValid(value)) {
      return NPI_VALIDATION_MESSAGE
    }
  }
  return (
    <TextField
      label={label}
      name={name}
      placeholder={placeholder}
      validate={validateField ? validate : undefined}
    />
  )
}

export default NpiField
