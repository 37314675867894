import React, { useContext } from "react"
import { CatalogBrowseTab } from "sharedTypes"
import * as styles from "./index.module.scss"
import classNames from "classnames"
import WorkflowPageContext from "context/WorkflowPage"

interface Props {
  setSelectedTab(tab: CatalogBrowseTab): void
  selectedTab: CatalogBrowseTab
  showSearchByProductTabFirst: boolean
}

const ProductSearchTabs: React.FC<Props> = ({
  setSelectedTab,
  selectedTab,
  showSearchByProductTabFirst,
}) => {
  const { isSidebarOpen } = useContext(WorkflowPageContext)
  const textStyle = isSidebarOpen
    ? styles.tabTextWithSidebarOpen
    : styles.tabText

  const searchBySupplierTab = (
    <li
      role="presentation"
      key={CatalogBrowseTab.Supplier}
      className={classNames(styles.tabContainer, {
        [styles.selectedTabContainer]:
          selectedTab === CatalogBrowseTab.Supplier,
      })}
    >
      <span
        role="tab"
        className={classNames(styles.tab, textStyle, {
          [styles.selectedTab]: selectedTab === CatalogBrowseTab.Supplier,
        })}
        aria-selected={selectedTab === CatalogBrowseTab.Supplier}
        onClick={() => setSelectedTab(CatalogBrowseTab.Supplier)}
      >
        Search by Supplier
      </span>
    </li>
  )

  const searchByProductTab = (
    <li
      role="presentation"
      key={CatalogBrowseTab.Product}
      className={classNames(styles.tabContainer, {
        [styles.selectedTabContainer]: selectedTab === CatalogBrowseTab.Product,
      })}
    >
      <span
        role="tab"
        className={classNames(styles.tab, textStyle, {
          [styles.selectedTab]: selectedTab === CatalogBrowseTab.Product,
        })}
        aria-selected={selectedTab === CatalogBrowseTab.Product}
        onClick={() => setSelectedTab(CatalogBrowseTab.Product)}
      >
        Search by Product
      </span>
    </li>
  )

  return (
    <div>
      <div className={styles.container}>
        <ul role="tablist" className={styles.tabList}>
          {showSearchByProductTabFirst
            ? [searchByProductTab, searchBySupplierTab]
            : [searchBySupplierTab, searchByProductTab]}
        </ul>
      </div>
    </div>
  )
}

export default ProductSearchTabs
