export function getClipboardData(event) {
  return (event.clipboardData || window.clipboardData).getData("text")
}

export function copyToClipboard(text) {
  const el = document.createElement("textarea")
  el.value = text
  document.body.appendChild(el)
  el.select()
  document.execCommand("copy")
  document.body.removeChild(el)
}
