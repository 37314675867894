import React from "react"
import { LANGUAGES } from "utilities/languages"
import { Select } from "components/form"
import {
  convertValueToOption,
  filterOptionsByQuery,
} from "components/form/Select"
import Suggestion from "components/form/Select/Suggestion"

const PreferredLanguageSelect = () => {
  const options = LANGUAGES.map(convertValueToOption)

  return (
    <Select
      name="preferredLanguage"
      label="Preferred Language (Optional)"
      placeholder="Select or add language"
      fetchOptions={filterOptionsByQuery(options)}
      openMenuOnFocus
      minLength={0}
      debounce={false}
      isSearchable
      isClearable
      renderOtherOption={(option, { query }) =>
        query.length > 0 && <Suggestion>+ Add “{query}”</Suggestion>
      }
    />
  )
}

export default PreferredLanguageSelect
