import React from "react"
import classNames from "classnames"
import * as styles from "./index.module.scss"
import { Drawer } from "@material-ui/core"
import { CanopyIcon } from "@parachutehealth/canopy-icon"

export type CmsDrawerProps = {
  /**
   * Indicates whether the drawer is visible. Note that even when `open` is false, the body of the drawer is evaluated.
   */
  open?: boolean
  /**
   * The content that shows in the gray header of the drawer.
   */
  title: React.ReactNode
  /**
   * The function to be run when the "Close" button is clicked; usually sets the "open" state of the drawer in the parent component.
   */
  onClose: () => void
  /**
   * Whatever the content of the drawer is to be.
   */
  children: React.ReactNode
  /**
   * The edge of the screen from which the drawer protrudes. Defaults to `right`.
   */
  anchor?: "left" | "top" | "right" | "bottom"
  /**
   * Indicates whether the page is overlayed with a dark translucent scrim when the drawer is open. Defaults to `false`.
   */
  scrim?: boolean
  /**
   * Indicates whether the drawer should close when the scrim is clicked (as opposed to just the drawer close button). Defaults to `false`.
   */
  closeOnScrimClick?: boolean
}

/**
 * This component is an extremely thin wrapper around MUI's Drawer component, but with various styles
 * centralized so they aren't copied and pasted for every invocation.
 */
const CmsDrawer: React.FC<CmsDrawerProps> = ({
  children,
  title,
  onClose,
  open = false,
  anchor = "right",
  scrim = false,
  closeOnScrimClick = false,
}: CmsDrawerProps): React.JSX.Element => {
  const Scrim = () => {
    if (!scrim) return null

    const onScrimClick = closeOnScrimClick ? onClose : undefined
    return (
      <div className={classNames(styles.scrim)} onClick={onScrimClick}></div>
    )
  }

  return (
    <>
      <Scrim />
      <Drawer
        role="dialog"
        aria-labelledby="cms-drawer-header"
        variant="persistent"
        open={open}
        anchor={anchor}
        className={classNames(styles.cmsDrawer)}
        transitionDuration={500}
      >
        <div className={styles.headerContainer}>
          <h3 id="cms-drawer-header">{title}</h3>
          <button
            aria-label="Close"
            className="close"
            type="button"
            onClick={onClose}
          >
            <CanopyIcon
              name="xmark"
              size="medium"
              fill="canopyColorPrimitivesGray27"
            ></CanopyIcon>
          </button>
        </div>

        <div className="canopy-p-12x">{children}</div>
      </Drawer>
    </>
  )
}

export default CmsDrawer
