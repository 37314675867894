import { stringify } from "utilities/querystring"

export function updatePasswordUrl(userId) {
  return `/facility_sign_up/passwords/${userId}.json`
}

export function updateUserUrl(userId) {
  return `/facility_sign_up/account_completion/users/${userId}.json`
}

export function resendInvitationUrl(userId: string) {
  return `/facility_sign_up/account_completion/invitations/${userId}/resend_invitation.json`
}

export function joinFacilityUrl(params?: {
  reviewer_dashboard_doctor_id?: string
  default_facility_id?: string
}) {
  return params
    ? `/facility_sign_up/facilities/edit?${stringify(params)}`
    : "/facility_sign_up/facilities/edit"
}

export function loginUrl() {
  return "/users/log_in"
}
