import { employerPrefix } from "utilities/url"

export function carrierAuthorizationUrl(id) {
  return `${employerPrefix()}/authorizations/${id}.json`
}

export function carrierAuthorizationDashboardUrl() {
  return `${employerPrefix()}/authorizations`
}

export function dmeOrderUrl(id) {
  return `${employerPrefix()}/orders/${id}`
}

export function dmeOrderReorderUrl(id) {
  return `${employerPrefix()}/orders/${id}/reorder.json`
}
