export function getActiveImageIndex(node) {
  const images = node.querySelectorAll(".image")
  if (images.length > 1) {
    const { scrollTop, scrollHeight, clientHeight } = node
    if (scrollTop === scrollHeight - clientHeight) {
      return images.length - 1
    }
    let totalHeight = 0
    for (let i = 0, ilen = images.length; i < ilen; i += 1) {
      const image = images[i]
      totalHeight += image.clientHeight
      if (scrollTop <= totalHeight * 0.75 || i === images.length - 1) {
        return i
      }
    }
  }
  return 0
}

export function getPreviousIndex(index, count) {
  if (count > 1) {
    if (index === 0) {
      return count - 1
    }
    return index - 1
  }
  return 0
}

export function getNextIndex(index, count) {
  if (count > 1) {
    if (index === count - 1) {
      return 0
    }
    return index + 1
  }
  return 0
}
