import React, { useState } from "react"
import { Doctor } from "sharedTypes"
import * as styles from "../index.module.scss"
import { Button, Form, TextArea } from "components/form"
import Link from "components/Link"
import Icon from "../../../components/Icon"
import { useHistory } from "react-router-dom"

type Props = {
  doctor: Doctor
  submitRoutingError(description?: string): Promise<void>
  basePath: string
}

const RoutingErrorForm: React.FC<Props> = ({
  doctor,
  submitRoutingError,
  basePath,
}: Props) => {
  const history = useHistory()
  const [submitted, setSubmitted] = useState(false)

  const handleSubmit = ({ denialReason }: { denialReason?: string }) => {
    submitRoutingError(denialReason).then(() => setSubmitted(true))
  }

  const renderForm = () => {
    return (
      <>
        <Form onSubmit={handleSubmit}>
          {({ isSubmitting }) => (
            <>
              <div>
                Please provide additional information to the supplier so we can
                resolve the issue with this order.
              </div>
              <TextArea
                name="denialReason"
                placeholder="Provide details for the supplier."
                rows={3}
                unwrapped
              />
              <p className="font-xs color-mid-gray">Optional</p>
              <Button
                type="submit"
                className="btn-brand btn-block canopy-mbs-12x"
                disabled={isSubmitting}
              >
                Submit
              </Button>
            </>
          )}
        </Form>
        <div className="text-center canopy-mbs-12x">
          <span className="canopy-mie-2x">
            Need to contact Parachute Health?
          </span>
          <Link
            className="link link-secondary"
            href="mailto:support@parachutehealth.com"
          >
            Contact Us
          </Link>
        </div>
      </>
    )
  }

  const renderConfirmation = () => {
    return (
      <div
        className={styles.signatureLoginIntro + " well well-success font-xs"}
      >
        <strong>Thanks for your submission.</strong>
        <span>{" We’ve notified the supplier about your feedback."}</span>
      </div>
    )
  }

  return (
    <>
      <div className="back-button">
        <Link onClick={() => history.push(basePath)}>
          <Icon className="canopy-mie-4x" type="chevron-left" /> {` Back`}
        </Link>
      </div>
      <div className={styles.signatureLoginIntro}>
        <h1 className="text-center canopy-mbe-8x">
          Welcome to Parachute Health
        </h1>
        <p className="text-center canopy-mbe-24x">
          A patient&apos;s order needs to be signed by Dr. {doctor.firstName}{" "}
          {doctor.lastName}, NPI {doctor.npi}
        </p>
      </div>
      {submitted ? renderConfirmation() : renderForm()}
    </>
  )
}

export default RoutingErrorForm
