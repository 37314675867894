export enum FacilityType {
  NursingOrRehab = "nursing_or_rehab",
  Hospital = "hospital",
  HomeHealth = "home_health",
  SleepLab = "sleep_lab",
  AmbulatoryPractice = "ambulatory_practice",
  CancerCenter = "cancer_center",
  DialysisCenter = "dialysis_center",
  Hospice = "hospice",
  DoctorOffice = "doctor_office",
  Recert = "recert",
}

export const OtherOption = { value: null, label: "Other" }
