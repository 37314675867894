import React from "react"
import { Nullable } from "sharedTypes"
import { numberToCurrency } from "utilities/price"
import PricingDisclaimers from "./PricingDisclaimers"
import {
  formularyUnitPrice,
  hasMissingPrices,
} from "../../../../../utilities/formularyPrice"
import { pluralize } from "utilities/string"
import * as styles from "../components/CartLineItemGroup/CartLineItemGroup.module.scss"
import cx from "classnames"

interface LineItemGroup {
  quantity: number
  lineItems: {
    formularyPrice: Nullable<string>
  }[]
}

interface Props {
  formularyPriceEnabled: boolean
  lineItemGroups: LineItemGroup[]
}
const CartPrice: React.FC<Props> = ({
  formularyPriceEnabled,
  lineItemGroups,
}) => {
  if (!formularyPriceEnabled) return null
  const totalQuantity = lineItemGroups.reduce(
    (sum, { quantity }) => sum + quantity,
    0
  )

  const subtotalFor = (lineItemGroup: LineItemGroup) => {
    const unitPrice = formularyUnitPrice(lineItemGroup)
    if (!unitPrice) {
      return 0
    } else {
      return unitPrice * lineItemGroup.quantity
    }
  }

  const totalPrice = lineItemGroups.reduce(
    (sum, lineItemGroup) => sum + subtotalFor(lineItemGroup),
    0
  )

  const formattedTotalPrice = numberToCurrency(totalPrice)

  return (
    <div
      className={cx(
        "bg-lightest-gray",
        "canopy-my-8x",
        " canopy-p-12x",
        styles.cartItem
      )}
    >
      <h3 className="color-black">Cart total</h3>
      <h4 className="color-dark-gray font-weight-normal">
        {totalQuantity} {pluralize("item", totalQuantity)}
      </h4>
      <h2 className="font-weight-bold d-flex justify-content-end">
        {formattedTotalPrice}
      </h2>
      <PricingDisclaimers missingPrice={hasMissingPrices(lineItemGroups)} />
    </div>
  )
}

export default CartPrice
