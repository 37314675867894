import { DmeOrder, Document } from "sharedTypes"
import React, { useContext } from "react"
import GlobalContext from "../../../../../context/Global"
import DownloadLink from "../../../../../components/DownloadLink"
import { EventCategory, trackEvent } from "utilities/tracking"

type Props = {
  dmeOrder: DmeOrder
  downloadableDocuments: Document[]
  doctorId: string
}
const DOWNLOAD_DME_ORDER_DOCUMENT_EVENT_NAME = "reviewer_dash_download_dme"

const Documentation = (props: Props) => {
  const { requiresTwoStepDownload } = useContext(GlobalContext)
  const { dmeOrder, downloadableDocuments } = props
  const documentationDocuments = downloadableDocuments.filter(
    (d) => d.dmeOrderDocumentationRequirementId
  )

  const content = () => {
    if (documentationDocuments.length === 0) {
      return (
        <div className="alert alert-warning alert-rounded alert-borderless">
          <span className="bold color-black">Documents</span>
          There are no documents available to download at this time.
        </div>
      )
    }
    return (
      <div>
        <span className="bold color-black">Documents</span>
        <ul className="list-unstyled canopy-mbe-0">
          {documentationDocuments.map(
            ({ key, title, dmeOrderDocumentationRequirementId }) => (
              <li key={key} aria-label={`download ${title} link`}>
                <DownloadLink
                  orderId={dmeOrder.id}
                  dmeOrderDocumentationRequirementId={
                    dmeOrderDocumentationRequirementId
                  }
                  documentKey={key}
                  label={title}
                  className="canopy-mis-2x btn-link color-primary"
                  requiresTwoStepDownload={requiresTwoStepDownload}
                  employerType="Doctor"
                  employerId={props.doctorId}
                  onDownload={() => {
                    trackEvent(
                      EventCategory.Resupply,
                      DOWNLOAD_DME_ORDER_DOCUMENT_EVENT_NAME
                    )
                  }}
                />
              </li>
            )
          )}
        </ul>
      </div>
    )
  }

  return content()
}

export default Documentation
