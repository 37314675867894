import React from "react"
import InputText from "./InputText"

type Props = {
  content: string | React.ReactElement
}

const SubText = ({ content }: Props) => {
  return <InputText className="canopy-mbs-4x" content={content} />
}

export default SubText
