import React from "react"
import { pollForPatientStatus } from "./api"
import { FeatureFlagInput } from "sharedTypes"
import { FeatureFlagProvider } from "components/FeatureFlagContext"
import EmrDataLoader from "components/EmrDataLoader"

interface Props {
  emrContextIndex: string
  emrPatientLoadJobId: string
  featureFlags: FeatureFlagInput
}

function EmrPatientLoading({
  emrContextIndex,
  emrPatientLoadJobId,
  featureFlags,
}: Props) {
  const poll = () => pollForPatientStatus(emrContextIndex, emrPatientLoadJobId)

  return (
    <FeatureFlagProvider flags={featureFlags}>
      <EmrDataLoader poll={poll} />
    </FeatureFlagProvider>
  )
}

export default EmrPatientLoading
