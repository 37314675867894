import { DataGridPro } from "@mui/x-data-grid-pro"
import { styled } from "@material-ui/core"
import { canopyColorBorderDefault } from "@parachutehealth/canopy-tokens-color"
import { canopySpace4X } from "@parachutehealth/canopy-tokens-space"

const IncomingOrdersMuiDataGrid = styled(DataGridPro)(() => ({
  "&.MuiDataGrid-root": {
    border: `1px solid ${canopyColorBorderDefault}`,
    padding: canopySpace4X,
  },
  "&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus, &.MuiDataGrid-root .MuiDataGrid-cell:focus": {
    outline: "none",
  },
}))

export default IncomingOrdersMuiDataGrid
