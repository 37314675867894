import React, { ReactElement, useContext } from "react"
import GlobalContext from "context/Global"
import SignOutButton from "./SignOutButton"
import HomeLink from "./HomeLink"
import PushbackButtons from "./PushbackButtons"
import FacilityWorkflowBackLink from "applications/Workflow/containers/Navigation/components/TopNavigation/TopLeftNavButton/FacilityWorkflowBackLink/FacilityWorkflowBackLink"

export default function TopLeftNavLink(): ReactElement {
  const {
    dmeOrderSpecific,
    emrPatientContext,
    nonprodHeaderDetails,
    showJoinFacilityButton,
    currentEmployer,
  } = useContext(GlobalContext)

  const facilityWorkflowBackPath = document.cookie
    .split("; ")
    .find((row) => row.startsWith("facility_workflow_back_path="))
    ?.split("=")[1]

  if (facilityWorkflowBackPath) {
    return (
      <FacilityWorkflowBackLink
        facilityWorkflowBackPath={facilityWorkflowBackPath}
        nonprodHeaderDetails={nonprodHeaderDetails}
      />
    )
  }
  if (dmeOrderSpecific && emrPatientContext) {
    return <SignOutButton />
  } else if (dmeOrderSpecific && !emrPatientContext) {
    return (
      <PushbackButtons
        showJoinFacilityButton={showJoinFacilityButton}
        currentEmployer={currentEmployer}
      />
    )
  } else if (emrPatientContext) {
    return (
      <HomeLink
        label="Patient Profile"
        nonprodHeaderDetails={nonprodHeaderDetails}
      />
    )
  } else {
    return (
      <HomeLink label="Dashboard" nonprodHeaderDetails={nonprodHeaderDetails} />
    )
  }
}
