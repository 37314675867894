import * as React from "react"
import pluralize from "pluralize"
import { SalesRepAssignment } from "../ReassignSalesRepsTable"

type Props = {
  salesRepAssignments: SalesRepAssignment[]
  givingSalesRepName: string
  recipientSalesRepName: string
  territoryNames: string[]
  transferZipCodes: boolean
}
const TransferSalesRepsEditMessage: React.FC<Props> = ({
  salesRepAssignments,
  givingSalesRepName,
  recipientSalesRepName,
  territoryNames,
  transferZipCodes,
}: Props) => {
  const territoriesString = (): string => {
    if (transferZipCodes && territoryNames && territoryNames.length > 0) {
      return ", including " + territoryNames.join(", ") + ", "
    } else {
      return ""
    }
  }

  return (
    <>
      {salesRepAssignments && givingSalesRepName && recipientSalesRepName && (
        <div className="well well-info">
          <span>
            {salesRepAssignments.length}{" "}
            {pluralize("facility", salesRepAssignments.length)}
            {territoriesString()} will be transferred from {givingSalesRepName}{" "}
            to {recipientSalesRepName}.
          </span>
        </div>
      )}
    </>
  )
}

export default TransferSalesRepsEditMessage
