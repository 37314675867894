// @team @expanse
import { capitalize } from "lodash"
import React from "react"
import { EmergencyContact } from "sharedTypes"
import { formatPhone } from "utilities/phone"

const OrderReviewAdditionalContact = ({
  emergencyContact,
}: {
  emergencyContact: EmergencyContact
}) => {
  if (!emergencyContact) {
    return null
  }
  const relationship =
    emergencyContact.relationship &&
    `(${capitalize(emergencyContact.relationship)})`
  const contactNameWithRelationship = [
    emergencyContact.firstName,
    emergencyContact.lastName,
    relationship,
  ]
    .filter((l) => !!l)
    .join(" ")
  const phoneNumber =
    emergencyContact.phoneNumber && formatPhone(emergencyContact.phoneNumber)
  const email = emergencyContact.email
  return (
    <>
      {[contactNameWithRelationship, phoneNumber, email]
        .filter((l) => !!l)
        .map((l) => (
          <React.Fragment key={l}>
            {l}
            <br />
          </React.Fragment>
        ))}
    </>
  )
}

export default OrderReviewAdditionalContact
