import React, { useState } from "react"

type Props = {
  sendSignatureRequest(params: {}): Promise<void>
  disabled: boolean
  className?: string
  renderButtonAsLink?: boolean
}

function SendSignatureReminderButton({
  className,
  sendSignatureRequest,
  disabled,
  renderButtonAsLink,
}: Props) {
  const [sending, setSending] = useState(false)
  const onClick = function () {
    setSending(true)
    return sendSignatureRequest({ sendImmediately: true })
      .then(() => setSending(false))
      .catch(() => setSending(false))
  }

  const renderLink = () => {
    if (disabled) {
      return null
    }
    return !sending ? (
      <div className="canopy-mt-6x">
        <a onClick={onClick} className="border-left-0 btn-link color-primary">
          Send Signature Reminder Now
        </a>
      </div>
    ) : (
      <div className="canopy-mt-6x">Sending...</div>
    )
  }

  return renderButtonAsLink ? (
    renderLink()
  ) : (
    <button
      className={className}
      onClick={onClick}
      disabled={sending || disabled}
    >
      {sending ? "Sending..." : "Send Signature Reminder Now"}
    </button>
  )
}

export default SendSignatureReminderButton
