// @ts-strict-ignore
import React, { useState, useRef, useEffect, createRef } from "react"
import { Keycode } from "enums/keycodes"
import { getKeyCode } from "utilities/browser/event"
import BigInput from "./BigInput"

type Props = {
  id?: string
  name: string
  value: string
  length: number
  onChange(value: React.ChangeEvent<HTMLInputElement>): void
  errorIndexes?: number[]
  autoFocus?: boolean
  min?: number
  max?: number
}

const getInputValue = (values: string[]): string => {
  return values
    .map((value) => {
      if (!value) {
        return " "
      }
      return value
    })
    .join("")
    .trimRight()
}

const MultiCharacterInput = ({
  id,
  onChange,
  value,
  length,
  name,
  errorIndexes,
  autoFocus,
}: Props) => {
  const [values, setValues] = useState(
    new Array(length).fill("").map((_, index) => (value && value[index]) || "")
  )
  const refs = useRef(
    new Array(length).fill(null).map(() => createRef<HTMLInputElement>())
  )
  useEffect(() => {
    if (onChange) {
      onChange(({
        target: { value: getInputValue(values), name },
      } as unknown) as React.ChangeEvent<HTMLInputElement>)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values])

  const moveTo = (index) => {
    const nextRef = refs.current[index]

    if (!nextRef) {
      return
    }

    nextRef.current.focus()
  }

  const handleKeyDownAtInput = (index) => (event) => {
    const keyCode = getKeyCode(event)
    const selectionStart = refs.current[index].current.selectionStart
    const selectionEnd = refs.current[index].current.selectionEnd

    switch (keyCode) {
      case Keycode.Backspace:
      case Keycode.ArrowLeft:
        if (selectionStart === 0 && selectionEnd === 0) {
          moveTo(index - 1)
        }
        break
      case Keycode.ArrowRight:
        if (selectionStart === 1) {
          moveTo(index + 1)
        }
        break
      default:
        return event
    }
  }

  const handleChangeAtInput = (index) => (event) => {
    let newValue = ""
    if (event.target.value) {
      newValue = event.target.value
      moveTo(index + 1)
    }
    setValues((oldValues) =>
      oldValues.map((oldValue, valIndex) =>
        valIndex === index ? newValue : oldValue
      )
    )
  }

  const onPaste: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    if (event.target.value) {
      setValues((oldValues) =>
        oldValues.map(
          (oldValue, idx) => event.target.value.split("")[idx] || ""
        )
      )

      moveTo(Math.max(value.length, length) - 1)
    }
  }
  return (
    <div className="multi-character-input d-flex justify-content-center flex-nowrap">
      {refs.current.map((ref, index) => (
        <BigInput
          id={id && `${id}-${index}`}
          name={name && `${name}-${index}`}
          key={index}
          value={values[index]}
          hasError={errorIndexes && errorIndexes.includes(index)}
          center
          maxLength={1}
          ref={ref}
          onKeyDown={handleKeyDownAtInput(index)}
          onChange={handleChangeAtInput(index)}
          onPaste={onPaste}
          autoFocus={autoFocus && index === value.length}
        />
      ))}
    </div>
  )
}

export default MultiCharacterInput
