import React from "react"
import Icon from "components/Icon"
import { highlight } from "utilities/select"
import Suggestion from "components/form/Select/Suggestion"
import * as styles from "./index.module.scss"
import classNames from "classnames"

type Props = {
  icd10Code: {
    codeLabel: string
    description: string
  }
  query: string
  checked?: boolean
  deny?: boolean
  allow?: boolean
  isHighlighted?: boolean
}

export default function Icd10CodeSuggestion({
  icd10Code,
  query,
  checked,
  deny,
  allow,
  isHighlighted,
}: Props) {
  return (
    <Suggestion className={styles.suggestion} isHighlighted={isHighlighted}>
      <div className="checkbox checkbox-brand basic">
        <div className={styles.label}>{icd10Code.codeLabel}</div>
        <input
          className="canopy-mie-2x"
          type="checkbox"
          checked={checked}
          onChange={() => {}}
        />
        <span className="icon" />
      </div>
      <div className={styles.content}>
        {highlight(icd10Code.description, query)}
      </div>
      {allow && (
        <Icon
          type="check-circle"
          className={classNames(styles.icon, "color-success")}
        />
      )}
      {deny && (
        <Icon
          type="exclamation-circle"
          className={classNames(styles.icon, "color-danger")}
        />
      )}
    </Suggestion>
  )
}
