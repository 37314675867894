import React from "react"
import { LLMResult } from "../sharedTypes"
import { Nullable } from "sharedTypes"
import { CanopyNotice } from "@parachutehealth/canopy-notice"
import { jsonCode, jsonCodeWrapper } from "../index.module.scss"

interface Props {
  llmResult: Nullable<LLMResult>
}

const LLMResultJson = ({ llmResult }: Props) => {
  if (!llmResult) {
    return <CanopyNotice title="LLM result missing" variant="warning" />
  }
  return (
    <div className={jsonCodeWrapper}>
      <code className={jsonCode}>{JSON.stringify(llmResult, null, 2)}</code>
    </div>
  )
}

export default LLMResultJson
