import React, { useContext, useEffect } from "react"
import { RouterChildContext, useHistory, useParams } from "react-router-dom"
import {
  CatalogCustomAttribute,
  CatalogCustomAttributeOption,
  CatalogProduct,
} from "../../../../types/sharedTypes"
import { isNullOrUndefined } from "../../../../../../utilities/isNullOrUndefined"
import {
  Backdrop,
  CircularProgress,
  List,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core"
import { NoticeContext } from "../../../../contexts/NoticeContext"
import {
  destroyCatalogAttribute,
  getCatalogAttribute,
} from "../../../../api/attributes"
import SidebarLayout from "../../../../components/SidebarLayout"
import {
  archiveAttributeOption,
  getProductsForAttributeOption,
  rearrangeAttributeOptions,
} from "../../../../api/attributeOptions"
import { DndProvider } from "react-dnd"
import { HTML5Backend } from "react-dnd-html5-backend"
import RowContainer from "../../../../components/RowContainer"
import { DragIndicator } from "@material-ui/icons"
import { attributesUrl } from "../../../../urls/attributes"
import Breadcrumbs from "../../../../components/Breadcrumbs"
import DropdownMenu from "../../../../components/DropdownMenu"
import EditAttributeDrawer from "../EditAttributeDrawer"
import ConfirmDialog from "../../../../components/ConfirmDialog"
import EditAttributeOptionDrawer from "../EditAttributeOptionDrawer"
import Timestamp from "../../../../components/Timestamp"
import NoRows from "../../../../components/DataGrid/NoRows"
import { usePolicies } from "../../../../contexts/PoliciesContext"
import { CanopyButton } from "@parachutehealth/canopy-button"
import AddAttributeValueDrawer from "../AddAttributeValueDrawer"
import CmsDrawer from "../../../../components/CmsDrawer"
import StyledTable from "../../../../components/StyledTable"

type InternalAttributeDetailsPageProps = {
  catalogAttribute: CatalogCustomAttribute
  reload: () => void
}

const InternalAttributeDetailsPage: React.FC<InternalAttributeDetailsPageProps> = (
  props: InternalAttributeDetailsPageProps
): React.JSX.Element => {
  const history: RouterChildContext["router"]["history"] = useHistory()
  const { showNotice } = useContext(NoticeContext)
  const { hasPermission } = usePolicies()
  const { reload, catalogAttribute } = props

  const [tableRows, setTableRows] = React.useState<
    CatalogCustomAttributeOption[]
  >([...(catalogAttribute.options || [])])

  const [destroyDialogOpen, setDestroyDialogOpen] = React.useState<boolean>(
    false
  )
  const [editDrawerOpen, setEditDrawerOpen] = React.useState<boolean>(false)
  const [addOptionDrawerOpen, setAddOptionDrawerOpen] = React.useState<boolean>(
    false
  )
  const [
    attributeOptionSelectedForDeletion,
    setAttributeOptionSelectedForDeletion,
  ] = React.useState<CatalogCustomAttributeOption | undefined>(undefined)

  const [
    attributeOptionSelectedForEdit,
    setAttributeOptionSelectedForEdit,
  ] = React.useState<CatalogCustomAttributeOption | undefined>(undefined)

  useEffect(() => {
    document.title = `Attribute: ${catalogAttribute.name}`
  })

  const OpenContent = (): React.ReactNode[] => {
    const items: { [p: string]: React.JSX.Element } = {
      Caption: <>{catalogAttribute.selectionCaption || "(none)"}</>,
      "Attribute auto-selection enabled": (
        <>
          {catalogAttribute.attributeAutoSelectionEnabled?.toString() ||
            "false"}
        </>
      ),
      "Last edited": <Timestamp time={catalogAttribute.updatedAt} />,
      "Product count": <>{catalogAttribute.productCount || 0}</>,
    }
    return [
      <div key="usage-details">
        <div className="canopy-typography-heading-small">
          <b>Usage Details</b>
        </div>
        <ul className="list-unstyled canopy-typography-body-small canopy-mb-0">
          {Object.keys(items).map((key) => {
            return (
              <li key={key} className="canopy-my-0">
                <span>
                  {key}: <b>{items[key]}</b>
                </span>
              </li>
            )
          })}
        </ul>
      </div>,
    ]
  }

  const performAttributeDestroy = async () => {
    const response = await destroyCatalogAttribute(catalogAttribute.externalId)

    if (response === true) {
      history.push(attributesUrl())
      showNotice(
        `Successfully removed the attribute ${catalogAttribute.name}`,
        "success"
      )
    } else {
      setDestroyDialogOpen(false)
      showNotice(
        `Failed to delete attribute: ${
          response["errors"] || "unknown error occurred"
        }`,
        "error"
      )
    }
  }

  const performAttributeOptionArchive = async () => {
    if (!attributeOptionSelectedForDeletion) {
      showNotice("No option is selected", "error")
      return
    }

    const success = await archiveAttributeOption(
      catalogAttribute.externalId,
      attributeOptionSelectedForDeletion.externalId
    )
    if (success) {
      showNotice("You've successfully archived that option", "success")
      reload()
    } else {
      showNotice("An error occurred while archiving that option", "error")
    }
  }

  const [productDrawerTarget, setProductDrawerTarget] = React.useState<
    CatalogCustomAttributeOption | undefined
  >(undefined)

  const ProductsDrawer = ({
    option,
  }: {
    option: CatalogCustomAttributeOption
  }): React.JSX.Element => {
    const [products, setProducts] = React.useState<CatalogProduct[]>([])
    const [loading, setLoading] = React.useState<boolean>(products.length === 0)

    React.useEffect(() => {
      getProductsForAttributeOption(
        catalogAttribute.externalId,
        option.externalId
      )
        .then((data) => setProducts(data))
        .finally(() => setLoading(false))
    }, [option])

    const onClose = () => {
      setProducts([])
      setProductDrawerTarget(undefined)
    }

    return (
      <CmsDrawer
        open={true}
        title={`Products using "${option.description}" value`}
        onClose={onClose}
      >
        {loading && (
          <div style={{ textAlign: "center" }}>
            <CircularProgress title="Loading..." color="primary" size={64} />
          </div>
        )}
        {!loading && (
          <StyledTable>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell
                    className="canopy-typography-body-large"
                    component="th"
                    scope="col"
                  >
                    Product
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {products.map((product) => {
                  return (
                    <TableRow key={product.id}>
                      <TableCell>
                        <a href={product.url}>{product.name}</a>
                      </TableCell>
                    </TableRow>
                  )
                })}
              </TableBody>
            </Table>
          </StyledTable>
        )}
      </CmsDrawer>
    )
  }

  const AttributeOptionsTable = (): React.JSX.Element => {
    const handleRowDrop = async (): Promise<void> => {
      const success: boolean = await rearrangeAttributeOptions(
        catalogAttribute.externalId,
        tableRows.map((x) => x.externalId)
      )
      if (!success) {
        showNotice(
          "An error occurred while attempting to reorder the options",
          "error"
        )
      }
    }
    const handleRowMove = (fromIndex: number, toIndex: number) => {
      const row = tableRows.at(fromIndex)
      const newTableRows = [...tableRows]
      newTableRows.splice(fromIndex, 1)
      newTableRows.splice(toIndex, 0, row!)
      setTableRows(newTableRows)
    }

    const productLink = (option: CatalogCustomAttributeOption) => {
      if (isNullOrUndefined(option.productCount) || option.productCount === 0) {
        return "-"
      }

      return (
        <a
          href=""
          onClick={(e) => {
            e.preventDefault()
            setProductDrawerTarget(option)
          }}
        >
          {option.productCount}
        </a>
      )
    }

    return (
      <TableContainer>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell component="th"></TableCell>
              {[
                "Value",
                "# Products",
                "Patient Preference",
                "ID",
                "Actions",
              ].map((s) => {
                return (
                  <TableCell key={s} component="th" scope="col">
                    <b>{s}</b>
                  </TableCell>
                )
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {tableRows.length === 0 && (
              <TableRow>
                <TableCell className="canopy-pin-0" colSpan={6}>
                  <NoRows
                    message="There are currently no options for this attribute."
                    dataGrid={false}
                  />
                </TableCell>
              </TableRow>
            )}
            {tableRows.map((row, index) => (
              <RowContainer
                key={row.id}
                row={row}
                index={index}
                rearrangeable={true}
                onMove={handleRowMove}
                onDrop={handleRowDrop}
                icon={DragIndicator}
              >
                <TableCell>{row.description}</TableCell>
                <TableCell>{productLink(row)}</TableCell>
                <TableCell>{row.patientPreference ? "Yes" : "No"}</TableCell>
                <TableCell>{row.externalId}</TableCell>
                <TableCell>
                  <DropdownMenu
                    label="Actions"
                    buttonProps={{ size: "small", variant: "tertiary" }}
                  >
                    {[
                      {
                        label: "Edit",
                        onClick: () => setAttributeOptionSelectedForEdit(row),
                        ifTrue: Boolean(row.permissions?.edit),
                      },
                      {
                        label: "Delete",
                        variant: "danger",
                        onClick: () =>
                          setAttributeOptionSelectedForDeletion(row),
                        ifTrue: Boolean(row.permissions?.destroy),
                      },
                    ]}
                  </DropdownMenu>
                </TableCell>
              </RowContainer>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    )
  }

  const CustomAttributeTable = (): React.JSX.Element => {
    return <AttributeOptionsTable />
  }
  const ClosedContent: React.JSX.Element = <List></List>

  const HeaderContent: React.JSX.Element = (
    <>
      <Breadcrumbs>
        {[
          { label: "All Attributes", to: attributesUrl() },
          { label: catalogAttribute.name },
        ]}
      </Breadcrumbs>
      <h1 className="canopy-typography-heading-xlarge canopy-mt-4x canopy-mbe-4x">
        {catalogAttribute.name}
      </h1>
    </>
  )
  const MainContent = (): React.JSX.Element => {
    return (
      <>
        <div
          className="canopy-mbs-2x canopy-mbe-10x d-flex justify-content-end"
          data-testid="main-actions"
        >
          {hasPermission("Catalog::CustomAttributeOption", "create") && (
            <>
              <CanopyButton
                onClick={() => setAddOptionDrawerOpen(true)}
                variant="primary"
                size="small"
                className="canopy-mie-6x"
              >
                Add Attribute Value
              </CanopyButton>
              {productDrawerTarget && (
                <ProductsDrawer option={productDrawerTarget} />
              )}
              {addOptionDrawerOpen && (
                <AddAttributeValueDrawer
                  customAttributeId={catalogAttribute.externalId}
                  open={addOptionDrawerOpen}
                  onSave={reload}
                  onClose={() => setAddOptionDrawerOpen(false)}
                />
              )}
            </>
          )}
          <DropdownMenu label="Actions" buttonProps={{ variant: "secondary" }}>
            {[
              {
                label: "Audit Log",
                ifTrue: Boolean(catalogAttribute.auditLogUrl),
                href: catalogAttribute.auditLogUrl,
              },
              {
                label: "Edit",
                ifTrue: Boolean(catalogAttribute.permissions?.edit),
                onClick: () => setEditDrawerOpen(true),
              },
              {
                label: "Delete",
                variant: "danger",
                disabled: () =>
                  Boolean(
                    catalogAttribute.options?.find(
                      (o) => o.productCount || 0 > 0
                    )
                  ),
                disabledMessage:
                  "This attribute cannot be deleted because its options are in use",
                ifTrue: Boolean(catalogAttribute.permissions?.destroy),
                onClick: () => setDestroyDialogOpen(true),
              },
            ]}
          </DropdownMenu>
        </div>
        {editDrawerOpen && (
          <EditAttributeDrawer
            customAttribute={catalogAttribute}
            onClose={() => setEditDrawerOpen(false)}
            open={editDrawerOpen}
            onSave={reload}
          />
        )}
        {attributeOptionSelectedForEdit && (
          <EditAttributeOptionDrawer
            customAttribute={catalogAttribute}
            open={Boolean(attributeOptionSelectedForEdit)}
            customAttributeOption={attributeOptionSelectedForEdit!}
            onClose={() => setAttributeOptionSelectedForEdit(undefined)}
            onSave={reload}
          />
        )}
        <ConfirmDialog
          title="Attribute Removal"
          message="Are you sure you want to remove this attribute?"
          confirmButtonText="Yes, I'm sure"
          cancelButtonText="Cancel"
          open={destroyDialogOpen}
          onConfirm={performAttributeDestroy}
          onCancel={() => setDestroyDialogOpen(false)}
          handleClose={() => setDestroyDialogOpen(false)}
        />
        <ConfirmDialog
          title="Remove Attribute Option"
          message="Are you sure you want to remove this attribute option?"
          confirmButtonText="Yes, I'm sure"
          cancelButtonText="Cancel"
          open={!isNullOrUndefined(attributeOptionSelectedForDeletion)}
          onConfirm={performAttributeOptionArchive}
          onCancel={() => setAttributeOptionSelectedForDeletion(undefined)}
          handleClose={() => setAttributeOptionSelectedForDeletion(undefined)}
        />
        <CustomAttributeTable />
      </>
    )
  }

  return (
    <>
      <SidebarLayout
        openContent={OpenContent()}
        mainContent={<MainContent />}
        closedContent={ClosedContent}
        headerContent={HeaderContent}
        sidebarTitle="Attribute Details"
      />
    </>
  )
}

const AttributeDetailsPage: React.FC = () => {
  const { attributeId } = useParams()

  const [catalogAttribute, setCatalogAttribute] = React.useState<
    CatalogCustomAttribute | undefined
  >()

  const [loading, setLoading] = React.useState<boolean>(
    isNullOrUndefined(catalogAttribute)
  )

  const loadAttribute = async (attributeId: string) => {
    await getCatalogAttribute(attributeId)
      .then((attr) => {
        setCatalogAttribute(attr)
      })
      .finally(() => setLoading(false))
  }

  const reload = () => {
    setLoading(true)
    setCatalogAttribute(undefined)
    void loadAttribute(attributeId)
  }

  useEffect(() => {
    if (!catalogAttribute) {
      void loadAttribute(attributeId)
    }
  }, [attributeId, catalogAttribute])

  if (loading) {
    return (
      <Backdrop style={{ zIndex: 999 }} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    )
  } else {
    return (
      <DndProvider backend={HTML5Backend}>
        <InternalAttributeDetailsPage
          reload={reload}
          catalogAttribute={catalogAttribute!}
        />
      </DndProvider>
    )
  }
}

export default AttributeDetailsPage
