import React from "react"
import { EmailField, PhoneField, TextField } from "components/form"
import { SalesRepFormFields } from "./sharedTypes"

export const initialSalesRepFieldValues: SalesRepFormFields = {
  salesRepName: "",
  salesRepEmail: "",
  salesRepPhoneNumber: "",
}

const SalesRepFields: React.FC = () => (
  <>
    <TextField label="Sales rep name (Optional)" name="salesRepName" />
    <PhoneField
      label="Sales rep phone number (Optional)"
      name="salesRepPhoneNumber"
      validateField
      optional
    />
    <EmailField
      label="Sales rep email (Optional)"
      name="salesRepEmail"
      validateField
      optional
    />
  </>
)

export default SalesRepFields
