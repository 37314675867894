import React from "react"
import Card from "./Card"
import { Icd10Code } from "sharedTypes"
import { toList } from "utilities/array"

type Props = {
  icd10Codes: Icd10Code[]
}

const DiagnosesCard = (props: Props) => {
  const { icd10Codes } = props

  return (
    <Card
      title="Diagnoses"
      summary={toList(
        icd10Codes.map((c) => c.codeLabel),
        3
      )}
    >
      <ul>
        {icd10Codes.map((icd10Code) => (
          <li className="canopy-mbe-4x" key={icd10Code.code}>
            <span className="bold color-black">{icd10Code.codeLabel}</span> -{" "}
            {icd10Code.description}
          </li>
        ))}
      </ul>
    </Card>
  )
}

export default DiagnosesCard
