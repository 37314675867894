export const EMAIL_VALIDATION_MESSAGE = "invalid email"

const EMAIL_REGEXP = /.+@.+\..+/

export function isEmailValid(value?: string, optional = false): boolean {
  if (optional && !value) {
    return true
  }
  if (!value) {
    return false
  }
  return EMAIL_REGEXP.test(value)
}
