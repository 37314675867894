import React from "react"
import { pollDmeOrderStatus } from "./api"
import EmrDataLoader from "components/EmrDataLoader"

interface Props {
  token: string
}

function EmrDmeOrderCreationPoller({ token }: Props) {
  const poll = () => pollDmeOrderStatus(token)
  return <EmrDataLoader poll={poll} />
}

export default EmrDmeOrderCreationPoller
