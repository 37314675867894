import { post } from "services/api"
import { employerPrefix } from "utilities/url"
import { Failure, Result, Success } from "components/AsyncButton"

export const attachToRequest = (
  supplierId,
  csrInboxStateId,
  signatureRequestId
) => {
  return (): Promise<Result> => {
    return post(
      `${employerPrefix(
        "Supplier",
        supplierId
      )}/inbox/${csrInboxStateId}/signature_request_attachments`,
      { signing_signature_request_id: signatureRequestId }
    )
      .then(() => new Success())
      .catch(
        () =>
          new Failure(
            "Sorry, there was a problem on our end. Try refreshing the page and trying again"
          )
      )
  }
}
