import React, { ReactElement } from "react"
import SignOutButton from "./SignOutButton"
import SignUpButton from "./SignUpButton"
import * as styles from "./index.module.scss"
import classNames from "classnames"
import JoinFacilityButton from "./JoinFacilityButton"
import { ContextEmployer } from "sharedTypes"

interface Props {
  showJoinFacilityButton: boolean
  currentEmployer: ContextEmployer
}

export default function PushbackButtons({
  showJoinFacilityButton,
  currentEmployer,
}: Props): ReactElement {
  const signOutButtonText = showJoinFacilityButton
    ? "Sign Out"
    : "Sign Out As Guest"

  return (
    <>
      <nav
        className={classNames(
          "navbar",
          "navbar-expand-sm",
          "navbar-light",
          styles.navbarWithoutPadding
        )}
      >
        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#topLeftButtons"
          aria-controls="topLeftButtons"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div
          className={classNames(
            "collapse",
            "navbar-collapse",
            styles.navbarCollapse
          )}
          id="topLeftButtons"
        >
          <ul className="navbar-nav canopy-mie-auto">
            <li className="nav-item">
              {showJoinFacilityButton ? (
                <JoinFacilityButton currentEmployer={currentEmployer} />
              ) : (
                <SignUpButton />
              )}
            </li>
            <li className="nav-item">
              <SignOutButton text={signOutButtonText} />
            </li>
          </ul>
        </div>
      </nav>
    </>
  )
}
