import { formatAddress } from "utilities/address"

export const clinicalFacilitySearch = (result) => {
  if (result.loading) {
    return result
  }

  if (result.children) {
    return $(`<div class='bold'>${result.text}</div>`)
  }

  let btId
  if (result.supplier_system_id) {
    btId = `#${result.supplier_system_id}`
  }

  let userLine
  if (result.source === "fulfillment_agreement") {
    userLine = `${result.user_count} user${result.user_count > 1 ? "s" : ""}`
  } else {
    userLine = "Not on Parachute"
  }

  return $(`
    <div class='row'>
      <div class='col-md-8'>
        <div class='bold'>${[result.name, btId]
          .filter(Boolean)
          .join(" - ")}</div>
      </div>
      <div class='col-md-4 text-right'>
        ${userLine}
      </div>
      <div class='col-md-12'>
        ${formatAddress(result)}
      </div>
    </div>
  `)
}
