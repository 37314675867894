import React, { useEffect } from "react"
import { Supplier } from "../../../types/sharedTypes"
import { useParams } from "react-router-dom"
import { isNullOrUndefined } from "../../../../../utilities/isNullOrUndefined"
import { getCatalogSupplier } from "../../../api/suppliers"
import { Backdrop, CircularProgress } from "@material-ui/core"
import Breadcrumbs from "../../../components/Breadcrumbs"
import { suppliersUrl } from "../../../urls/suppliers"
import SidebarLayout from "../../../components/SidebarLayout"
import SimpleTabs from "../../../components/SimpleTabs"
import PackageConfigurationDataGrid from "./PackageConfigurationsDataGrid"
import DropdownMenu from "../../../components/DropdownMenu"
import ProductVariationsDataGrid from "./ProductVariationsDataGrid"
import { CanopyNotice } from "@parachutehealth/canopy-notice"
import SupplierCatalogUploadModal from "./SupplierCatalogUploadModal"
import { usePolicies } from "../../../contexts/PoliciesContext"

type InternalSupplierDetailsPageProps = {
  supplier: Supplier
}

const InternalSupplierDetailsPage: React.FC<InternalSupplierDetailsPageProps> = ({
  supplier,
}: InternalSupplierDetailsPageProps): React.JSX.Element => {
  useEffect(() => {
    document.title = `Supplier: ${supplier.name}`
  }, [supplier.name])

  const { hasPermission } = usePolicies()

  const [uploadFormModalOpen, setUploadFormModalOpen] = React.useState<boolean>(
    false
  )

  const HeaderContent = (): React.JSX.Element => {
    return (
      <>
        <Breadcrumbs>
          {[
            { label: "All Suppliers", to: suppliersUrl() },
            { label: supplier.name },
          ]}
        </Breadcrumbs>

        <div className="d-flex justify-content-space-between canopy-mbe-4x">
          <h1 className="canopy-typography-heading-xlarge canopy-mt-4x canopy-mbe-4x">
            {supplier.name}
          </h1>
          <DropdownMenu
            label="More actions"
            buttonProps={{ variant: "secondary" }}
          >
            {[
              {
                label: "Export Supplier Catalog",
                href: `/cms/catalog/supplier_product_variations.csv?supplier_id=${supplier.externalId}`,
              },
              {
                label: "Import Seed",
                href: `/cms/catalog/suppliers/${supplier.externalId}/catalog_imports/new`,
                ifTrue: hasPermission(
                  "Catalog::SupplierProductVariation",
                  "create"
                ),
              },
              {
                label: "Upload Supplier Catalog",
                onClick: () => setUploadFormModalOpen(true),
                ifTrue: hasPermission(
                  "Catalog::SupplierProductVariation",
                  "create"
                ),
              },
            ]}
          </DropdownMenu>
          <SupplierCatalogUploadModal
            open={uploadFormModalOpen}
            supplier={supplier}
            onClose={() => setUploadFormModalOpen(false)}
          />
        </div>
      </>
    )
  }

  const UnconfigurablePackagesNotice = (): React.JSX.Element => {
    if ((supplier["unconfiguredPackageCount"] || 0) === 0) return <></>

    return (
      <CanopyNotice
        role="note"
        variant="error"
        title="Unconfigured Packages"
        message={`This Supplier has ${supplier["unconfiguredPackageCount"]} unconfigured Packages.`}
      />
    )
  }

  const OpenSidebarContent = (): React.ReactNode[] => {
    const items: { [p: string]: React.ReactNode } = {
      "Supplier Profile": (
        <a href={`/cms/suppliers/${supplier.externalId}`}>{supplier.name}</a>
      ),
      ID: supplier.externalId,
      "Test Supplier": supplier["test"] ? "Yes" : "No",
      NPI: supplier["npi"],
      "Addable by facility": supplier["addable_by_facility"] ? "No" : "Yes",
    }

    return [
      <UnconfigurablePackagesNotice key="broken-package-notice" />,
      <div key="usage-details">
        {/* TODO: extract this into a reusable descriptionlist component */}
        <dl
          data-testid="usage-details"
          className="canopy-typography-body-small canopy-mb-0"
        >
          {Object.keys(items).map((key) => {
            return (
              <React.Fragment key={key}>
                <dt id={`term-${key}`} role="term">
                  {key}
                </dt>
                <dd aria-labelledby={`term-${key}`} className="canopy-mbe-6x">
                  {items[key]}
                </dd>
              </React.Fragment>
            )
          })}
        </dl>
      </div>,
    ]
  }

  const MainContent = (
    <>
      <SimpleTabs fullWidth>
        {[
          {
            key: "packages",
            label: <>Supplier Package Configurations</>,
            content: (
              <>
                <PackageConfigurationDataGrid supplier={supplier!} />
              </>
            ),
          },
          {
            key: "skus",
            label: "SKUs",
            content: (
              <>
                <ProductVariationsDataGrid supplier={supplier!} />
              </>
            ),
          },
        ]}
      </SimpleTabs>
    </>
  )

  return (
    <SidebarLayout
      openContent={OpenSidebarContent()}
      mainContent={MainContent}
      closedContent={<></>}
      headerContent={HeaderContent}
      sidebarTitle="Supplier Details"
    />
  )
}
const SupplierDetailsPage: React.FC = (): React.JSX.Element => {
  const { supplierId } = useParams()

  const [supplier, setSupplier] = React.useState<Supplier | undefined>()

  const [loading, setLoading] = React.useState<boolean>(
    isNullOrUndefined(supplier)
  )

  const loadSupplier = async (supplierId: string) => {
    await getCatalogSupplier(supplierId)
      .then((supplier) => {
        setSupplier(supplier)
      })
      .finally(() => setLoading(false))
  }

  React.useEffect(() => {
    if (!supplier) {
      void loadSupplier(supplierId)
    }
  }, [supplierId, supplier])

  if (loading) {
    return (
      <Backdrop style={{ zIndex: 999 }} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    )
  } else {
    return <InternalSupplierDetailsPage supplier={supplier!} />
  }
}

export default SupplierDetailsPage
