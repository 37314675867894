import $ from "jquery"
import Inputmask from "inputmask/dist/inputmask/inputmask"
import "inputmask/dist/inputmask/inputmask.numeric.extensions"
import "inputmask/dist/inputmask/inputmask.phone.extensions"
import "inputmask/dist/inputmask/inputmask.date.extensions"
import emitter from "utilities/eventEmitter"

$(window).on("load", attach)
$(document).on("show.bs.modal", ".modal", attach).on("ajaxComplete", attach)
emitter.on("react:reload", attach)

function query(name) {
  return document.querySelectorAll(`[data-mask="${name}"]`)
}

export function attach() {
  Inputmask({ mask: "99999[-9999]", jitMasking: true, greedy: true }).mask(
    query("zip")
  )
  Inputmask({ mask: "(999) 999-9999", jitMasking: true }).mask(query("phone"))
  Inputmask({ mask: "99/99/9999", jitMasking: true }).mask(query("date"))
}

export function detach() {
  Inputmask.remove(query("zip"))
  Inputmask.remove(query("phone"))
  Inputmask.remove(query("date"))
}
