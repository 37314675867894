import React, { useState } from "react"
import { Formik, Form } from "formik"
import { isEmpty } from "utilities/object"
import useDeepCompareEffect from "use-deep-compare-effect"

function InternalForm({ formikProps, props, onSubmit, Component }) {
  const { values, errors, validateForm } = formikProps
  const allProps = { ...formikProps, ...props }
  const showErrors = !props.hideErrors
  const [active, activate] = useState(false)

  useDeepCompareEffect(() => {
    if (props.submitOnChange && active) {
      validateForm(values).then((errors) => {
        if (isEmpty(errors)) {
          onSubmit(values, formikProps)
        }
      })
    }
    activate(true)
  }, [values])

  return (
    <Form className={props.className}>
      {props.children ? (
        typeof props.children === "function" ? (
          props.children({ ...allProps })
        ) : (
          props.children
        )
      ) : (
        <Component {...allProps} />
      )}
      {showErrors && errors && errors.base && (
        <div className="color-danger">{errors.base}</div>
      )}
    </Form>
  )
}

function render(formikProps, props, onSubmit, Component) {
  return (
    <InternalForm
      formikProps={formikProps}
      props={props}
      onSubmit={onSubmit}
      Component={Component}
    />
  )
}

const asForm = (Component) => (props) => {
  const onSubmit =
    typeof props.onSubmit === "function" ? props.onSubmit : function noop() {}

  return (
    <Formik
      initialValues={props.initialValues || {}}
      validateOnMount
      {...props}
      onSubmit={onSubmit}
    >
      {(formikProps) => render(formikProps, props, onSubmit, Component)}
    </Formik>
  )
}

export default asForm
