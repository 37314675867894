// @team @clinical-intelligence
import { ChartNote, DocumentationRequirement } from "sharedTypes"

export const isChartNoteRelatedToDocumentationRequirement = (
  documentationRequirement: DocumentationRequirement,
  chartNote: ChartNote,
  contextDrivenDocs: boolean
) => {
  if (
    documentationRequirement.generatorType === "prescription" ||
    documentationRequirement.generatorType === "wopd"
  ) {
    return false
  } else {
    let titleName
    if (
      documentationRequirement.usageContextTitle !== null &&
      contextDrivenDocs
    ) {
      titleName = documentationRequirement.usageContextTitle
    } else {
      titleName = documentationRequirement.categoryName
    }
    return chartNote.name === titleName
  }
}
