import React from "react"
import Alert from "components/Alert"

type Props = {
  onClose(): void
}

const SuccessBanner = ({ onClose }: Props) => {
  return (
    <Alert leftIcon status="success" rightIcon="fa-times" onClick={onClose}>
      <span>
        <strong>Great!</strong> We've sent notifications to verify your contact
        information.
      </span>
    </Alert>
  )
}

export default SuccessBanner
