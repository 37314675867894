import React from "react"
import classNames from "classnames"

type Props = {
  children: React.ReactNode
  className?: string
}

function ModalBody({ className, children }: Props) {
  return <div className={classNames(className, "modal-body")}>{children}</div>
}

export default ModalBody
