import React from "react"
import { Form, Select } from "components/form"

export type Supplier = {
  id: string
  name: string
}

export const SupplierSelector = ({
  supplierId,
  suppliers,
  onSupplierSelected,
}: {
  supplierId: string
  suppliers: Supplier[]
  onSupplierSelected: ({ supplierId }) => any
}) => {
  if (suppliers.length === 1) return null

  const supplierOptions = suppliers.map((supplier: Supplier) => {
    return { label: supplier.name, value: supplier.id }
  })

  return (
    <Form
      initialValues={{ supplierId }}
      onSubmit={onSupplierSelected}
      submitOnChange
    >
      <Select
        name="supplierId"
        options={supplierOptions}
        placeholder="Select Supplier"
      />
    </Form>
  )
}
