// @ts-strict-ignore
import React from "react"
import { Field } from "formik"
import SuperText from "components/SuperText"
import SubText from "components/SubText"
import classNames from "classnames"
import { blurOnEnter, isTabOrEnter } from "utilities/browser/event"
import { handleChange, handleBlur } from "./event-handlers"
import { Step } from "sharedTypes"
import { getFieldError } from "./utilities"
import ErrorText from "components/form/ErrorText"
import * as styles from "./NumberField.module.scss"

type Props = {
  name: string
  label?: string
  supertext?: string | React.ReactElement
  subtext?: string | React.ReactElement
  id?: string
  disabled?: boolean
  className?: string
  onChange?(event: React.FormEvent): void
  onBlur?(event: React.FormEvent): void
  submitOnBlur?: boolean
  placeholder?: string
  prefix?: React.ReactNode
  suffix?: React.ReactNode
  min?: number
  minExclusive?: number
  max?: number
  maxExclusive?: number
  step?: Step
  inline?: boolean
  autoComplete?: string
  autoFocus?: boolean
  submitOnNavigationKey?: boolean
  unwrapped?: boolean
  groupClassName?: string
  isRequired?: boolean
}

const NumberField = React.forwardRef<HTMLInputElement, Props>(
  (
    {
      disabled,
      label,
      supertext,
      subtext,
      id,
      name,
      className,
      placeholder,
      prefix,
      suffix,
      min,
      minExclusive,
      max,
      maxExclusive,
      step,
      inline,
      autoComplete,
      autoFocus,
      onChange,
      onBlur,
      submitOnBlur,
      submitOnNavigationKey,
      unwrapped,
      groupClassName,
      isRequired,
    },
    ref
  ) => {
    const renderError = (error) => <ErrorText error={error} />

    const renderInputGroup = (input) => {
      if (!prefix && !suffix) {
        return (
          <>
            <SuperText content={supertext} />
            {input}
            <SubText content={subtext} />
          </>
        )
      }
      return (
        <>
          <SuperText content={supertext} />
          <div
            className={classNames("input-group", { "canopy-mbe-0": subtext })}
          >
            {prefix && (
              <span className="input-group-text input-group-prepend">
                {prefix}
              </span>
            )}
            {input}
            {suffix && (
              <span className="input-group-text input-group-append">
                {suffix}
              </span>
            )}
          </div>
          <SubText content={subtext} />
        </>
      )
    }

    const renderLabel = (labelClassNames) => {
      if (isRequired) {
        labelClassNames += ` ${styles.required}`
      }
      return (
        <label className={labelClassNames} htmlFor={name}>
          {label}
        </label>
      )
    }

    const renderRegularInput = ({ input, error }) => (
      <div
        className={classNames(
          { "form-group": !unwrapped },
          "input-group-numeric",
          groupClassName,
          error && "has-error"
        )}
      >
        {label && renderLabel("")}
        {renderInputGroup(input)}
        {renderError(error)}
      </div>
    )

    const renderInlineInput = ({ input, error }) => (
      <div
        className={classNames(
          { "form-group": !unwrapped },
          "row gutter-0",
          "input-group-numeric",
          groupClassName,
          error && "has-error"
        )}
      >
        {label && renderLabel("col-3 col-form-label inline")}
        <div className="col-9">
          {renderInputGroup(input)}
          {renderError(error)}
        </div>
      </div>
    )

    const validate = (value: number) => {
      const isNumber = (input) => {
        return typeof input === "number"
      }

      if (isNumber(minExclusive) && value <= minExclusive) {
        return `Value must be greater than ${minExclusive}`
      }
      if (isNumber(maxExclusive) && value >= maxExclusive) {
        return `Value must be less than ${maxExclusive}`
      }
      if (isNumber(min) && value < min) {
        return `Value must be greater than or equal to ${min}`
      }
      if (isNumber(max) && value > max) {
        return `Value must be less than or equal to ${max}`
      }
    }

    return (
      <Field name={name} validate={validate} className="input-group-numeric">
        {({ field, form }) => {
          const { errors, touched } = form
          const error = getFieldError(errors, touched, name)
          const inputProps = {
            ...field,
            placeholder,
            disabled,
            min,
            max,
            step,
            autoComplete,
            autoFocus,
            type: "number",
            inputMode: "numeric",
            pattern: "\\d*",
            id: id || name,
            className: classNames("form-control", className, {
              "canopy-mbe-0": subtext || error,
            }),
            ref: ref,
            onKeyUp: blurOnEnter,
            onKeyDown: (event) => {
              submitOnNavigationKey && isTabOrEnter(event) && form.submitForm()
            },
            onChange: handleChange({ field, onChange }),
            onBlur: handleBlur({ form, field, onBlur, submitOnBlur }),
          }

          const input = <input {...inputProps} />
          return inline
            ? renderInlineInput({ input, error })
            : renderRegularInput({ input, error })
        }}
      </Field>
    )
  }
)

export default NumberField
