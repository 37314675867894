import React from "react"
import { DmeOrder } from "sharedTypes"
import { updateDischargeDate } from "./api"
import InternalDischargeDateForm from "./InternalDischargeDateForm"

type Props = {
  dischargeDate: string
  setDmeOrder(dmeOrder: DmeOrder): Promise<void>
}

export default function DischargeDateForm({
  dischargeDate,
  setDmeOrder,
}: Props) {
  return (
    <InternalDischargeDateForm
      dischargeDate={dischargeDate}
      updateDischargeDate={updateDischargeDate}
      setDmeOrder={setDmeOrder}
    />
  )
}
