import { pluralize } from "utilities/string"
import OrdersList from "../OrdersList"
import React, { useState } from "react"
import { DoctorDashboardDmeOrder } from "../../sharedTypes"
import { SigningSignatureRequest } from "../OrdersList/SignatureRequestCard"
import StickyFooter from "components/StickyFooter"
import SelfSignAttestation from "components/SelfSignAttestation"
import { Tab, Tabs } from "components/Tabs"
import * as styles from "./index.module.scss"
import { EventCategory, trackEvent } from "utilities/tracking"

export const APPROVE_ALL_ORDERS_EVENT_NAME = "doctor_dash_approve_all_orders"
export const APPROVE_ALL_REVIEWED_ORDERS_EVENT_NAME =
  "doctor_dash_approve_all_reviewed_orders"

const ApproveButton = ({
  orders,
  signingSignatureRequests,
  approveOrders,
  disableApproveButton,
  onlyListingReviewedOrders,
}: {
  approveOrders: (args: {
    orderIds: string[]
    signingSignatureRequestIds: string[]
  }) => void
  onlyListingReviewedOrders: boolean
  disableApproveButton: boolean
  signingSignatureRequests: SigningSignatureRequest[]
  orders: DoctorDashboardDmeOrder[]
}) => {
  const orderDescriptor = onlyListingReviewedOrders ? "Reviewed " : ""
  const buttonLabel =
    orders.length + signingSignatureRequests.length > 1
      ? `Approve All ${orderDescriptor}Orders`
      : `Approve ${orderDescriptor}Order`

  const onClick = () => {
    const eventName = onlyListingReviewedOrders
      ? APPROVE_ALL_REVIEWED_ORDERS_EVENT_NAME
      : APPROVE_ALL_ORDERS_EVENT_NAME
    trackEvent(EventCategory.Resupply, eventName)
    approveOrders({
      orderIds: orders.map((o) => o.id),
      signingSignatureRequestIds: signingSignatureRequests.map((s) => s.id),
    })
  }

  return (
    <StickyFooter>
      <SelfSignAttestation />
      <div className="canopy-mbs-12x">
        <button
          onClick={onClick}
          disabled={disableApproveButton}
          className="btn btn-primary"
        >
          {buttonLabel}
        </button>
      </div>
    </StickyFooter>
  )
}

export const OrdersSection = (props: {
  approveOrders: (args: {
    orderIds: string[]
    signingSignatureRequestIds: string[]
  }) => void
  currentDoctorId: string
  disableApproveButton: boolean
  signingSignatureRequests: SigningSignatureRequest[]
  orders: DoctorDashboardDmeOrder[]
  showReviewedOrders: boolean
}) => {
  const [listReviewedOrdersOnly, setListReviewedOrdersOnly] = useState(false)
  const orders = listReviewedOrdersOnly
    ? props.orders.filter((o) => o.review)
    : props.orders

  const signingSignatureRequests = listReviewedOrdersOnly
    ? props.signingSignatureRequests.filter((o) => o.review)
    : props.signingSignatureRequests
  return (
    <>
      {props.showReviewedOrders && (
        <Tabs className={styles.tabs}>
          <Tab
            onClick={() => setListReviewedOrdersOnly(false)}
            selected={!listReviewedOrdersOnly}
          >
            All Orders
          </Tab>
          <Tab
            onClick={() => {
              setListReviewedOrdersOnly(true)
            }}
            selected={listReviewedOrdersOnly}
          >
            Reviewed Orders
          </Tab>
        </Tabs>
      )}
      <h3 className={styles.orderHeader}>
        You have {orders.length + signingSignatureRequests.length}{" "}
        {listReviewedOrdersOnly ? "reviewed " : ""}
        {pluralize(
          "order",
          orders.length + signingSignatureRequests.length
        )}{" "}
        pending signature.
      </h3>
      <OrdersList
        doctorId={props.currentDoctorId}
        orders={orders}
        signingSignatureRequests={signingSignatureRequests}
      />
      <ApproveButton
        onlyListingReviewedOrders={listReviewedOrdersOnly}
        orders={orders}
        signingSignatureRequests={signingSignatureRequests}
        approveOrders={props.approveOrders}
        disableApproveButton={props.disableApproveButton}
      />
    </>
  )
}
