import { DashboardViewConfiguration } from "../sharedTypes"
import { FilterValues } from "../components/DashboardFilters/sharedTypes"
import { union } from "lodash"
import isEqual from "lodash.isequal"

export const viewHasUnsavedChanges = (
  currentView: DashboardViewConfiguration,
  savedView: DashboardViewConfiguration
) => {
  const hasUnsavedFilterValues = isAnyFilterDirty(
    savedView.filterValues,
    currentView.filterValues
  )
  const hasUnsavedFilterSettings =
    JSON.stringify(currentView.filterSettings) !==
    JSON.stringify(savedView.filterSettings)
  const hasUnsavedColumnSettings =
    JSON.stringify(currentView.columnSettings) !==
    JSON.stringify(savedView.columnSettings)

  const hasUnsavedSortSettings =
    currentView.sortColumn !== savedView.sortColumn ||
    currentView.sortDirection !== savedView.sortDirection

  return (
    hasUnsavedFilterValues ||
    hasUnsavedFilterSettings ||
    hasUnsavedColumnSettings ||
    hasUnsavedSortSettings
  )
}

const isAnyFilterDirty = (savedFilters, currentFilters) => {
  const dirtyFilters = getDirtyFilters(savedFilters, currentFilters)
  return Object.values(dirtyFilters).includes(true)
}

export const getDirtyFilters = (
  savedFilterValues: FilterValues,
  currentFilterValues: FilterValues
): { [key: string]: boolean } => {
  const IGNORED_FILTERS = ["query"]
  const dirtyFilters = {}
  const filters: string[] = union(
    Object.keys(savedFilterValues),
    Object.keys(currentFilterValues)
  )

  filters.forEach((name: string): void => {
    if (IGNORED_FILTERS.includes(name)) return

    dirtyFilters[name] = !isEqual(
      normalizeFilterValue(savedFilterValues[name]),
      normalizeFilterValue(currentFilterValues[name])
    )
  })
  return dirtyFilters
}

const normalizeFilterValue = (value) => {
  if (Array.isArray(value) && value.length === 0) {
    return undefined
  }
  return value
}
