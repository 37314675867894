// @ts-strict-ignore
import { EmployerType as ContextEmployerType } from "../sharedTypes"

export function getCurrentUrlWithQuery(query: string) {
  return location.origin + location.pathname + "?" + query
}

export function getCurrentUrl(): string {
  return location.origin + location.pathname
}

export function setCurrentUrlWithQuery(query: string) {
  const url = getCurrentUrlWithQuery(query)
  window.history.pushState({}, "", url)
}

export type EmployerType =
  | "ClinicalFacility"
  | "ClinicalOrganization"
  | "Supplier"
  | "SupplierOrganization"
  | "Doctor"
  | "Insurance::Payor"

enum EmployerTypePrefix {
  ClinicalFacility = "f",
  ClinicalOrganization = "o",
  Supplier = "s",
  SupplierOrganization = "r",
  Doctor = "d",
  "Insurance::Payor" = "p",
}

export function employerTypeFromContext(
  contextEmployerType: ContextEmployerType
): EmployerType {
  return {
    [ContextEmployerType.Supplier]: "Supplier",
    [ContextEmployerType.ClinicalFacility]: "ClinicalFacility",
    [ContextEmployerType.SupplierOrganization]: "SupplierOrganization",
  }[contextEmployerType]
}

export function getEmployerType(employerType?: EmployerType): EmployerType {
  return (
    employerType ||
    ((window.parachute && window.parachute.employerType) as EmployerType)
  )
}

export function getEmployerId(employerId?: string): string {
  return employerId || (window.parachute && window.parachute.employerId)
}

export const employerInitial = (
  employerType?: EmployerType
): EmployerTypePrefix => {
  const type = getEmployerType(employerType)
  return EmployerTypePrefix[type]
}

function emrContextPrefix() {
  const emrContextIndex = window.parachute?.emrContextIndex
  if (!emrContextIndex) return ""
  return `/${emrContextIndex}`
}

export function userPrefix() {
  return `/u${emrContextPrefix()}`
}

export function employerPrefix(
  employerType?: EmployerType,
  employerId?: string
) {
  return `${userPrefix()}/${employerInitial(employerType)}/${getEmployerId(
    employerId
  )}`
}

export function baseUrl() {
  return `${location.origin}${employerPrefix()}`
}
