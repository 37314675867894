import { del, post } from "services/api"
import * as urls from "./urls"

import { isNullOrUndefined } from "../../utilities/isNullOrUndefined"

export const assignDocument = (documentId, assigneeId, supplierId?: string) => {
  if (isNullOrUndefined(assigneeId)) {
    return del(urls.csrInboxDocumentAssigneeUrl(documentId, supplierId))
  } else {
    return post(urls.csrInboxDocumentAssigneeUrl(documentId, supplierId), {
      csr_inbox_state: { assignee_id: assigneeId },
    })
  }
}
