import React from "react"
import Modal from "components/Modal"
import { Button, Form, TextField } from "components/form"
import { DmeOrder } from "sharedTypes"
import { DmeOrderDeliveryTicketSignature } from "../../sharedTypes"
import SignaturePadField from "components/form/SignaturePadField"
import { validate } from "./validation"
import { FormikProps } from "formik"

type Props = {
  dismiss(): void
  dmeOrder: DmeOrder
  signDeliveryTicket(
    values: DmeOrderDeliveryTicketSignature,
    setErrors: (errors: string) => void
  ): Promise<void>
}

const DeliveryReceipt = ({ dismiss, dmeOrder, signDeliveryTicket }: Props) => {
  const initialValues = {
    base64File: "",
    dispensedBy: dmeOrder.creatorName,
    signedBy: "",
    relationship: "",
    reason: "",
    equipmentSerialNumber: "",
  }

  const onSubmit = (values, { setErrors }) => {
    signDeliveryTicket(values, setErrors)
  }

  const FirstTwoFields = () => {
    return (
      <>
        <div>
          <TextField label="Signed By" name="signedBy" />
        </div>
        <div>
          <TextField label="Dispensing User" name="dispensedBy" />
        </div>
      </>
    )
  }

  return (
    <Modal
      size="md"
      backdrop="static"
      cancel={dismiss}
      show
      title="Sign Proof of Delivery"
    >
      <Form
        onSubmit={onSubmit}
        initialValues={initialValues}
        validate={(fields) => validate(fields)}
      >
        {({ isSubmitting }: FormikProps<DmeOrderDeliveryTicketSignature>) => {
          return (
            <Modal.Body>
              <SignaturePadField name="base64File" />
              <FirstTwoFields />
              <div>
                <TextField
                  label="Relationship to Patient (enter 'self', if patient is signing)"
                  name="relationship"
                />
              </div>
              <div>
                <TextField
                  label="Reason Patient could not sign, if Patient is not signing"
                  name="reason"
                />
              </div>
              <div>
                <TextField
                  label="Equipment Serial Number"
                  name="equipmentSerialNumber"
                />
              </div>
              <div className="row">
                <div className="col-lg-6">
                  <button
                    type="button"
                    className="btn btn-secondary float-left"
                    onClick={dismiss}
                  >
                    Cancel
                  </button>
                  <Button
                    disabled={isSubmitting}
                    type="submit"
                    className="btn btn-primary float-right"
                  >
                    Sign
                  </Button>
                </div>
              </div>
            </Modal.Body>
          )
        }}
      </Form>
    </Modal>
  )
}

export default DeliveryReceipt
