import { InvitationFields } from "./sharedTypes"
import { get, post } from "services/api"
import {
  salesRepsUrl,
  supplierLegalEntitiesUrl,
  facilitiesUrl,
  invitationUrl,
  branchesUrl,
} from "./urls"

export const getSalesReps = (supplierId: string, term: string) => {
  return get(salesRepsUrl(supplierId), { term })
    .then(({ data }) => data)
    .catch(({ response }) => Promise.reject(response.data))
}

export const getSupplierLegalEntities = (supplierId: string, term: string) => {
  return get(supplierLegalEntitiesUrl(supplierId), { term })
    .then(({ data }) => data.supplierLegalEntities)
    .catch(({ response }) => Promise.reject(response.data))
}

export const getBranches = (supplierId: string, term: string) => {
  return get(branchesUrl(supplierId), { term })
    .then(({ data }) => data.results)
    .catch(({ response }) => Promise.reject(response.data))
}

export const getFacilities = (supplierId: string, term: string) => {
  return get(facilitiesUrl(supplierId), { term })
    .then(({ data }) => data)
    .catch(({ response }) => Promise.reject(response.data))
}

export const createInvitation = (
  supplierId: string,
  fields: InvitationFields
) => {
  return post(invitationUrl(supplierId), { invitation: fields }).then(
    ({ data }) => data
  )
}
