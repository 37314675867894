import React from "react"
import Tooltip from "components/Tooltip"

interface Props {
  remainingSteps: string[]
}
const ActionColumnPillTooltip: React.FC<Props> = ({
  children,
  remainingSteps,
}) => {
  if (remainingSteps.length === 0) return <span>{children}</span>

  return (
    <Tooltip
      overlay={remainingSteps.map((step, idx) => (
        <div key={idx}>
          <span>• {step}</span>
          <br />
        </div>
      ))}
    >
      {children}
    </Tooltip>
  )
}

export default ActionColumnPillTooltip
