import React from "react"
import Icon from "components/Icon"
import { NetworkCoverage as NetworkCoverageType } from "sharedTypes"
import * as styles from "./index.module.scss"

type Props = {
  networkCoverage: NetworkCoverageType
}

function getLabel(networkCoverage: NetworkCoverageType) {
  switch (networkCoverage) {
    case NetworkCoverageType.Unknown:
      return "Insurance acceptance unknown"
    case NetworkCoverageType.OutOfNetwork:
      return "Insurance not accepted"
    case NetworkCoverageType.PrimaryOutOfNetwork:
      return "Primary insurance not accepted"
    default:
      return ""
  }
}

function isCoverageOutOfNetwork(networkCoverage: NetworkCoverageType) {
  return (
    networkCoverage === NetworkCoverageType.OutOfNetwork ||
    networkCoverage === NetworkCoverageType.PrimaryOutOfNetwork
  )
}

function isCoverageUnknown(networkCoverage: NetworkCoverageType) {
  return networkCoverage === NetworkCoverageType.Unknown
}

const NetworkCoverage = ({ networkCoverage }: Props) => {
  const label = getLabel(networkCoverage)

  if (!label) {
    return null
  }

  return (
    <div className={styles.insurance}>
      {isCoverageOutOfNetwork(networkCoverage) && (
        <Icon className={styles.icon} type="ban" />
      )}
      {isCoverageUnknown(networkCoverage) && (
        <Icon className={styles.icon} type="question" />
      )}
      {label}
    </div>
  )
}

export default NetworkCoverage
