type FilteredResult = object | undefined | null

export function filterNullValues(object: FilteredResult): FilteredResult {
  if (!object) {
    return object
  }
  const result = {}
  for (const key in object) {
    if (object[key] !== null) {
      result[key] = object[key]
    }
  }
  return result
}
