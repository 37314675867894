import React from "react"
import InternalLogin from "./components/InternalLogin"
import * as api from "./api"
import { Flash } from "./sharedTypes"

interface Props {
  epicOauth: boolean
  flash?: Flash
  redirectTo: string
  samlDomains: string[]
  signUpUrl: string
}

const Login: React.FC<Props> = ({
  epicOauth,
  flash,
  redirectTo,
  samlDomains,
  signUpUrl,
}) => (
  <InternalLogin
    epicOauth={epicOauth}
    flash={flash}
    login={api.login}
    redirectTo={redirectTo}
    samlDomains={samlDomains}
    signUpUrl={signUpUrl}
  />
)

export default Login
