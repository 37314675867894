// @ts-strict-ignore
import React, { useState } from "react"
import {
  ClinicalFacility,
  Doctor,
  DoctorDashboardDmeOrder,
} from "../sharedTypes"
import { EmrInstance } from "sharedTypes"
import { handleError } from "utilities/error"
import { formatPhone } from "utilities/phone"
import FacilitiesList from "./FacilitiesList"
import GoDigitalLink from "./GoDigitalLink"
import { resendIdentityVerifications, updateProfile } from "../api"
import SuccessBanner from "./SuccessBanner"
import ErrorBanner from "./ErrorBanner"
import GoDigitalModal from "./GoDigitalModal"
import tadaImage from "tada.png"
import Section from "components/Section"
import classNames from "classnames"
import { SigningSignatureRequest } from "./OrdersList/SignatureRequestCard"
import { ReviewerManagement } from "./ReviewerManagement"
import { OrdersSection } from "./OrdersSection"
import { isEmpty } from "utilities/object"
import Card from "../../../components/Card"
import { ReviewerDetails } from "../index"

type Props = {
  initialOrders: DoctorDashboardDmeOrder[]
  currentDoctor: Doctor
  approveOrders(
    orderIds,
    signingSignatureRequestIds,
    doctorId: string
  ): Promise<void>
  removeFacility(facilityId): Promise<Doctor>
  addFacility(facilityId): Promise<Doctor>
  searchFacilities(value: string): Promise<ClinicalFacility[]>
  initialShowPrompt?: boolean
  pendingVerifications: string[]
  emrInstance?: EmrInstance
  signingSignatureRequests: SigningSignatureRequest[]
  initialReviewers: ReviewerDetails[]
}

const InternalDoctorDashboard = ({
  initialOrders,
  currentDoctor,
  approveOrders,
  removeFacility,
  addFacility,
  searchFacilities,
  initialShowPrompt,
  pendingVerifications,
  emrInstance,
  signingSignatureRequests: initialSigningSignatureRequests,
  initialReviewers,
}: Props) => {
  const [bannerVisibility, setBannerVisibility] = useState("")
  const [resendedVerifications, setResendedVerifications] = useState([])
  const [orders, setOrders] = useState(initialOrders)
  const [signingSignatureRequests, setSigningSignatureRequests] = useState(
    initialSigningSignatureRequests
  )
  const [doctor, setDoctor] = useState(currentDoctor)
  const [reviewers, setReviewers] = useState(initialReviewers)
  const hasSignables = orders.length > 0 || signingSignatureRequests.length > 0
  const [submitting, setSubmitting] = useState(false)
  const [showPrompt, setShowPrompt] = useState(initialShowPrompt)
  const isMissingAnyContactInformation = !doctor.email || !doctor.mobileNumber
  const isMissingAllContactInformation = !doctor.email && !doctor.mobileNumber
  const showGoDigitalLink =
    isMissingAnyContactInformation &&
    (!emrInstance || !emrInstance.emrMessagingEnabled)
  const showGoDigitalModal =
    showPrompt &&
    isMissingAllContactInformation &&
    !emrInstance?.emrMessagingEnabled

  const onResend = (identity) => {
    setResendedVerifications((resendedVerifications) =>
      resendedVerifications.concat([identity])
    )
    resendIdentityVerifications(identity)
      .then(() => {
        setBannerVisibility("success")
      })
      .catch(() => {
        setBannerVisibility("error")
      })
  }

  const renderDigitalContact = (label, value, identityType) => {
    if (value) {
      return (
        <p className="font-subparagraph canopy-m-0">
          {label}: {value}
          {identityType && pendingVerifications.includes(identityType) && (
            <a
              onClick={() => onResend(identityType)}
              className={classNames("canopy-mis-8x link", {
                disabled: resendedVerifications.includes(identityType),
              })}
            >
              <small>Resend verification</small>
            </a>
          )}
        </p>
      )
    }
  }

  const renderContacts = () => {
    const { mobileNumber, email, faxNumber } = doctor
    return (
      <>
        {renderDigitalContact("SMS", mobileNumber, "mobile_number")}
        {renderDigitalContact("Email", email, "email")}
        {faxNumber && (
          <p className="font-subparagraph canopy-m-0">
            Fax: {formatPhone(faxNumber)}
          </p>
        )}
      </>
    )
  }

  const handleRemoveFacility = (id) => {
    removeFacility(id).then(setDoctor)
  }

  const handleAddFacility = (id) => {
    addFacility(id).then(setDoctor)
  }

  const handleUpdateProfile = (values) => {
    return updateProfile(values)
      .then(setDoctor)
      .then(() => {
        setBannerVisibility("success")
      })
  }

  const doctorTitle = `Clinician ${doctor.name}, NPI ${doctor.npi}`

  const approveOrdersAndManageState = ({
    orderIds,
    signingSignatureRequestIds,
  }: {
    orderIds: string[]
    signingSignatureRequestIds: string[]
  }) => {
    setSubmitting(true)
    approveOrders(orderIds, signingSignatureRequestIds, currentDoctor.id)
      .then(() => {
        setSubmitting(false)
        setOrders(orders.filter((o) => !orderIds.some((id) => o.id === id)))
        setSigningSignatureRequests(
          signingSignatureRequests.filter(
            (s) => !signingSignatureRequestIds.some((id) => s.id === id)
          )
        )
        setShowPrompt(true)
      })
      .catch((error) => {
        setSubmitting(false)
        handleError(error)
      })
  }
  return (
    <div>
      {bannerVisibility === "success" && (
        <SuccessBanner onClose={() => setBannerVisibility("")} />
      )}
      {bannerVisibility === "error" && (
        <ErrorBanner onClose={() => setBannerVisibility("")} />
      )}
      <div className="d-none d-md-flex justify-content-between">
        <div>
          <h4 className="canopy-mbe-2x color-dark-gray">{doctorTitle}</h4>
          {renderContacts()}
        </div>
        <div>
          <Card
            render={() => (
              <ReviewerManagement
                reviewers={reviewers}
                setReviewers={setReviewers}
                doctorId={doctor.id}
              />
            )}
          ></Card>
        </div>
      </div>
      <Section
        className="bg-lightest-gray well-borderless canopy-pis-0 d-flex flex-column d-md-none"
        title={doctorTitle}
      >
        <div> {renderContacts()} </div>
        <div className="canopy-mbs-4x canopy-mbe-12x">
          <ReviewerManagement
            reviewers={reviewers}
            setReviewers={setReviewers}
            doctorId={doctor.id}
          />
        </div>
      </Section>
      {!hasSignables && (
        <>
          {showGoDigitalModal && (
            <GoDigitalModal
              onClose={() => setShowPrompt(false)}
              updateProfile={handleUpdateProfile}
              doctor={doctor}
              showIcon
            />
          )}
          <div className="image image-lg with-description canopy-my-24x d-none d-md-block">
            <img
              src={tadaImage}
              alt="A party popper exploding, creating a shower of confetti"
            />
            <div className="content text-center-xs">
              <h3 className="canopy-pbs-12x canopy-mbe-2x">
                Your work here is done!
              </h3>
              <p className="color-dark-gray mx-xs-5">
                You will be notified of any future patient
                <br />
                orders requiring your approval.
              </p>
            </div>
          </div>
          <div className="image image-sm text-center-xs d-block d-md-none">
            <h4>Your work here is done!</h4>
            <div className="canopy-my-8x">
              <img
                width="65"
                src={tadaImage}
                alt="A party popper exploding, creating a shower of confetti"
              />
            </div>
            <p className="color-dark-gray mx-xs-5">
              You will be notified of any future patient orders requiring your
              approval.
            </p>
          </div>
          {showGoDigitalLink && (
            <GoDigitalLink
              doctor={doctor}
              updateProfile={handleUpdateProfile}
            />
          )}
          <FacilitiesList
            doctor={doctor}
            searchFacilities={searchFacilities}
            addFacility={handleAddFacility}
            removeFacility={handleRemoveFacility}
          />
        </>
      )}
      {hasSignables && (
        <OrdersSection
          approveOrders={approveOrdersAndManageState}
          orders={orders}
          signingSignatureRequests={signingSignatureRequests}
          disableApproveButton={submitting}
          currentDoctorId={currentDoctor.id}
          showReviewedOrders={!isEmpty(reviewers)}
        />
      )}
    </div>
  )
}

export default InternalDoctorDashboard
