import React from "react"
import classNames from "classnames"

interface Props<T> {
  attr: string
  isSticky: boolean
  render?: (order: T) => any
  order: T
}

const TableCell = <T extends object>({
  attr,
  isSticky,
  render,
  order,
}: Props<T>) => {
  const tableCellContent = () => {
    if (!render) {
      return order[attr]
    }

    return render(order)
  }

  return (
    <td
      style={{ minWidth: "160px" }}
      className={classNames({
        "table-cell-sticky": isSticky,
      })}
    >
      {tableCellContent()}
    </td>
  )
}

export default TableCell
