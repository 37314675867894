import React from "react"
import {
  SelectedRecipient,
  SendBackFormKeys,
  SupplierFaxBackDocument,
} from "../sharedTypes"
import LabelSubtext from "../LabelSubtext"
import Select from "components/form/Select"

export const SupplierFaxDocumentSelector = (props: {
  supplierFaxBackDocuments: SupplierFaxBackDocument[]
  selectedRecipients: Pick<SelectedRecipient, "channel">[]
}) => {
  const { supplierFaxBackDocuments } = props

  if (!props.selectedRecipients.some((r) => r.channel === "fax")) {
    return null
  }

  if (supplierFaxBackDocuments.length === 0) {
    return null
  }
  return (
    <div className="row gutter-10">
      <div className="col-md-12">
        <Select
          label={
            <>
              Fax Additional Documents (Optional)
              <LabelSubtext>
                Select additional documents to send via fax. These documents
                will not be available digitally.
              </LabelSubtext>
            </>
          }
          placeholder="Select documents"
          options={supplierFaxBackDocuments
            .sort((a, b) => a.title.localeCompare(b.title))
            .map((r) => ({
              label: r["title"],
              value: r["id"],
            }))}
          name={SendBackFormKeys.supplierFaxBackDocumentIds}
          isMulti
          closeMenuOnSelect={false}
        />
      </div>
    </div>
  )
}
