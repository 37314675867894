import React from "react"
import { navigate } from "../utilities/navigation"
import classNames from "classnames"

type TabsProps = {
  className?: string
  children: React.ReactNode
}

type TabProps = {
  children: React.ReactNode
  selected?: boolean
  url?: string
  onClick?: () => void
}

export const Tabs = ({ className, children }: TabsProps) => {
  return <div className={classNames("tabs", className)}>{children}</div>
}

export const Tab = ({ selected, children, url, onClick }: TabProps) => {
  const onClickFunction = onClick
    ? onClick
    : () => {
        if (url) {
          navigate(url)
        }
      }

  return (
    <div
      className={classNames("tab", { selected: selected })}
      onClick={onClickFunction}
      role="tab"
      tabIndex={0}
      aria-selected={selected}
    >
      {children}
    </div>
  )
}
