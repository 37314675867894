import React from "react"

type Props = {
  onClick(): void
}

const EditButton: React.FC<Props> = ({ onClick }) => {
  return (
    <button
      type="button"
      className="float-right canopy-mis-8x btn btn-sm btn-brand-o"
      onClick={onClick}
    >
      Edit
    </button>
  )
}

export default EditButton
