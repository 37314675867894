import { employerPrefix } from "utilities/url"

const basePath = (orgId) => employerPrefix("SupplierOrganization", orgId)

export const RouteParams = {
  SupplierOrganizationId: "supplierOrganizationId",
  Tab: "tab",
}
export const RoutePattern = `${basePath(
  `:${RouteParams.SupplierOrganizationId}`
)}/:${RouteParams.Tab}?`

export const dashboardPath = (orgId: string) => `${basePath(orgId)}/dashboard`
export const uploadsPath = (orgId: string) => `${basePath(orgId)}/uploads`
