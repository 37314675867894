import { CanopyFormFieldGroup } from "@parachutehealth/canopy-form-field-group"
import React from "react"
import * as styles from "./index.module.scss"

type Props = {
  label: string
  children: React.JSX.Element
}

/**
 * The component exists solely as a method of allowing a label-less section
 * within an inline row of Canopy form fields (e.g., a button).
 *
 * It still requires setting a label, but the label is hidden visually.
 */
const ActionsFormGroup: React.FC<Props> = ({
  children,
  label,
}: Props): React.JSX.Element => {
  return (
    <CanopyFormFieldGroup className={styles.hiddenLabel} label={label}>
      {() => children}
    </CanopyFormFieldGroup>
  )
}

export default ActionsFormGroup
