import React from "react"
import Icon from "components/Icon"

interface Props {
  onClick(): void
  isDefaultView?: boolean
}

function SaveButton({ onClick, isDefaultView }: Props) {
  const text = isDefaultView ? "Save Current View" : "Save as New"
  return (
    <a
      className="link link-understated font-xs canopy-mis-8x"
      onClick={onClick}
    >
      <Icon className="canopy-mie-2x" type="star" />
      {text}
    </a>
  )
}

export default SaveButton
