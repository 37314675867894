import { Nullable } from "sharedTypes"
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query"
import { build as buildEndpoint } from "services/patientPortal"
import {
  getPatientMessageTemplates,
  getSupplierCommentTemplates,
  fetchSupplierCommentTemplate,
  createSupplierCommentTemplate,
  updateSuppliercommentTemplate,
  deleteSupplierCommentTemplate,
} from "./api"

export const useSupplierSettingsQuery = (supplierId: string) => {
  return useQuery({
    queryKey: ["supplierSettings", supplierId],
    queryFn: () =>
      buildEndpoint()
        .fetchSupplier(supplierId)
        .then(({ data }) => data),
  })
}

export const usePatientMessageTemplatesQuery = (supplierId: string) => {
  return useQuery({
    queryKey: ["patientMessageTemplates", supplierId],
    queryFn: getPatientMessageTemplates,
  })
}

export const useUpdateAutomatedWelcomeMessage = (supplierId: string) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: (template: string) => {
      return buildEndpoint().updateSupplier(supplierId, template)
    },
    onSuccess: ({ data }) => {
      queryClient.setQueryData(["supplierSettings", supplierId], data)
    },
  })
}

export const useSupplierCommentTemplatesQuery = (supplierId: string) => {
  return useQuery({
    queryKey: ["supplierCommentTemplates", supplierId],
    queryFn: getSupplierCommentTemplates,
  })
}

export const useFetchSupplierCommentTemplate = (
  supplierId: string,
  templateId: Nullable<string>,
  enabled: boolean
) => {
  return useQuery({
    queryKey: ["showSupplierCommentTemplate", { supplierId, templateId }],
    queryFn: () => fetchSupplierCommentTemplate(templateId),
    enabled: enabled,
  })
}

export const useCreateSupplierCommentTemplate = (supplierId: string) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: createSupplierCommentTemplate,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["supplierCommentTemplates", supplierId],
      })
    },
  })
}

export const useUpdateSupplierCommentTemplate = (supplierId: string) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: updateSuppliercommentTemplate,
    onSuccess: (template) => {
      queryClient.setQueryData(
        [
          "showSupplierCommentTemplate",
          { supplierId, templateId: template["id"] },
        ],
        template
      )
      queryClient.invalidateQueries({
        queryKey: ["supplierCommentTemplates", supplierId],
      })
    },
  })
}

export const useDeleteSupplierCommentTemplate = (supplierId: string) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: deleteSupplierCommentTemplate,
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: ["supplierCommentTemplates", supplierId],
      })
    },
  })
}
