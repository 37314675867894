import React from "react"
import { DoctorSignatureRequest } from "../../../api"
import * as styles from "./signature-request-card.module.scss"

const SignatureRequestCard = (props: {
  signatureRequest: DoctorSignatureRequest
}) => {
  return (
    <div className={styles.card}>
      <p className={styles.cardHeader}>{props.signatureRequest.patient.name}</p>
      <p className={styles.cardDetails}>
        DOB: <strong> {props.signatureRequest.patient.dateOfBirth} </strong>
      </p>
      <p className={styles.cardDetails}>
        Requested by: <strong> {props.signatureRequest.requestedBy} </strong>
      </p>
      <p className={styles.cardDetails}>
        Products:{" "}
        <strong> {props.signatureRequest.productDescriptions} </strong>
      </p>
    </div>
  )
}

export default SignatureRequestCard
