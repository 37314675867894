import { get, put } from "services/api"
import {
  lineItemGroupCustomAttributeConfigurationsUrl,
  updateLineItemGroupCustomAttributeConfigurationUrl,
} from "applications/Workflow/urls"

export const filterLineItemGroupCustomAttributeConfigurations = (
  lineItemGroupId,
  customAttributeOptionIds
) =>
  get(lineItemGroupCustomAttributeConfigurationsUrl(lineItemGroupId), {
    customAttributeOptionIds,
  })

export const selectLineItemGroupCustomAttributeConfiguration = (
  lineItemGroupId,
  activeCustomAttributeConfigurationId
) =>
  put(updateLineItemGroupCustomAttributeConfigurationUrl(lineItemGroupId), {
    dmeOrderLineItemGroup: { activeCustomAttributeConfigurationId },
  })
