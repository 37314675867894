import { createContext, useCallback, useEffect, useState } from "react"
import { PackageConfiguration as PackageConfigurationData } from "../sharedTypes"
import { handleError } from "../../../../../utilities/error"
import {
  ApplicationError,
  DmeOrder,
  PreferredSupplier,
} from "../../../../../sharedTypes"
import { fetchPreferredSuppliers } from "../utilities/supplier"

export interface IPreferredSupplierContext {
  preferredSuppliers: PreferredSupplier[]
  preferredSuppliersLoading: boolean
  selectedSupplier?: PreferredSupplier
  showDefaultSelectedSupplier: boolean
  hasRankedSuppliers: boolean

  setSelectedSupplier(supplier: PreferredSupplier): void
}

export const PreferredSupplierContext = createContext<
  IPreferredSupplierContext
>({
  preferredSuppliers: [],
  preferredSuppliersLoading: false,
  setSelectedSupplier(_: PreferredSupplier) {},
  showDefaultSelectedSupplier: false,
  hasRankedSuppliers: false,
})

export const usePreferredSupplier = (dmeOrder: DmeOrder) => {
  const [prefSuppliersLoading, setPrefSuppliersLoading] = useState<boolean>(
    false
  )
  const [preferredSuppliers, setPreferredSuppliers] = useState<
    PreferredSupplier[]
  >([])
  const hasOnePreferredSupplier = preferredSuppliers.length === 1
  const hasRankedSuppliers =
    preferredSuppliers.filter((ps) => ps.rank !== null).length > 0

  const [selectedSupplier, setSelectedSupplier] = useState<PreferredSupplier>()

  const loadPreferredSuppliers = useCallback(
    async (packageConfig: PackageConfigurationData | undefined) => {
      setPrefSuppliersLoading(true)
      if (!packageConfig) {
        setPrefSuppliersLoading(false)
        return
      }
      const lineItemGroup = packageConfig.dmeOrderLineItemGroup
      try {
        const supplierData = await fetchPreferredSuppliers({
          order: dmeOrder,
          lineItemGroup: lineItemGroup,
        })
        setPreferredSuppliers(supplierData)

        setPrefSuppliersLoading(false)
      } catch (e) {
        setPrefSuppliersLoading(false)

        handleError(e as ApplicationError)
      }
    },
    [dmeOrder]
  )

  useEffect(() => {
    const highestRankedSupplier = () => {
      return preferredSuppliers.reduce((acc, curr) => {
        return (acc.rank || Infinity) < (curr.rank || Infinity) ? acc : curr
      })
    }

    if (hasRankedSuppliers) {
      setSelectedSupplier(highestRankedSupplier())
    } else if (hasOnePreferredSupplier) {
      setSelectedSupplier(preferredSuppliers[0])
    }
  }, [
    preferredSuppliers,
    setSelectedSupplier,
    hasOnePreferredSupplier,
    hasRankedSuppliers,
  ])

  return {
    loadPreferredSuppliers: loadPreferredSuppliers,
    preferredSuppliers,
    loading: prefSuppliersLoading,
    selectedSupplier,
    setSelectedSupplier,
    showDefaultSelectedSupplier: !hasRankedSuppliers && hasOnePreferredSupplier,
    hasRankedSuppliers,
  }
}
