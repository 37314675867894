import React, { useState } from "react"
import { CanopyTextInputField } from "@parachutehealth/canopy-text-input-field"
import { CanopyButton } from "@parachutehealth/canopy-button"

interface SearchNavbarProps {
  searchFetchCallback(searchString: string): void

  addUserCallback(): void
}

export const SearchNavbar = ({
  searchFetchCallback,
  addUserCallback,
}: SearchNavbarProps) => {
  const [searchVal, setSearchVal] = useState("")

  const handleSearch = async () => {
    await searchFetchCallback(searchVal)
  }

  const handleClear = async () => {
    setSearchVal("")
    await searchFetchCallback("")
  }

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      handleSearch()
    }
  }
  const handleChange = async (e) => {
    const searchString = e.target.value

    if (searchString === "") {
      await handleClear()
    } else {
      setSearchVal(searchString)
    }
  }
  return (
    <div className="d-flex justify-content-between canopy-my-12x">
      <div className="d-flex">
        <CanopyTextInputField
          type="search"
          label="Search"
          id="search"
          value={searchVal}
          autocorrect="off"
          hiddenLabel
          onChange={handleChange}
          onKeyDown={handleKeyDown}
          className="canopy-mie-4x"
        />
        <CanopyButton
          variant="secondary"
          onClick={handleSearch}
          className="canopy-mie-4x"
        >
          Search
        </CanopyButton>
        <CanopyButton
          variant="tertiary"
          onClick={handleClear}
          className="canopy-mie-4x"
        >
          Clear
        </CanopyButton>
      </div>
      <CanopyButton
        style={{ minWidth: "fit-content", maxHeight: "fit-content" }}
        onClick={addUserCallback}
      >
        Add user
      </CanopyButton>
    </div>
  )
}
