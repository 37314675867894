import React from "react"
import CardComponent from "components/Card"

type Props = {
  title: string
  dropdown: boolean
  summary?: React.ReactNode
  children: React.ReactNode
}

const Card = ({ title, summary, children, dropdown }: Props) => {
  return (
    <CardComponent
      dropdown={dropdown}
      render={({ open, toggle }) => {
        return (
          <>
            <h3 className="pointer" onClick={toggle}>
              {title}
            </h3>
            {!open && summary && <div className="canopy-mbs-8x">{summary}</div>}
            {(open || !dropdown) && <>{children}</>}
          </>
        )
      }}
    />
  )
}

Card.defaultProps = {
  dropdown: true,
}

export default Card
