import React from "react"
import { emailCumulativeReport, getFacilities, getSuppliers } from "./api"
import { navigate } from "utilities/navigation"
import { Tabs, Tab } from "components/Tabs"
import { cumulativeReportUrl } from "./urls"
import ClinicalOrganizationCumulativeReport from "./components/ClinicalOrganizationCumulativeReport"
import { CumulativeInvoiceFormFields } from "./sharedTypes"

export default function ClinicalOrganizationFinancials() {
  const submit = (values: CumulativeInvoiceFormFields) => {
    return emailCumulativeReport(values).then(({ redirectUrl }) =>
      navigate(redirectUrl)
    )
  }
  return (
    <>
      <h1>Financials</h1>
      <Tabs>
        <Tab selected url={cumulativeReportUrl()}>
          Cumulative Report
        </Tab>
      </Tabs>
      <ClinicalOrganizationCumulativeReport
        emailCumulativeReport={submit}
        getFacilities={getFacilities}
        getSuppliers={getSuppliers}
      />
    </>
  )
}
