import React, { useState } from "react"
import { EmrCachedPatients } from "sharedTypes"
import { pluralize } from "utilities/string"
import EmrCachedPatientSection from "./EmrCachedPatientSection"

type Props = {
  emrCachedPatients: EmrCachedPatients
  createDmeOrder(patientId: string, cachedEncounterId?: string): Promise<void>
  canCreateOrder: boolean
  hasPrimaryAction: boolean
}

const IntegratedData: React.FC<Props> = ({
  emrCachedPatients,
  createDmeOrder,
  canCreateOrder,
  hasPrimaryAction,
}) => {
  const [showAllRecords, setShowAllRecords] = useState(false)

  const toggleAllRecords = () => {
    setShowAllRecords(true)
  }

  const renderAdditionalRecords = (showAllRecords) => {
    if (!showAllRecords) {
      return (
        <div className="text-center canopy-mbs-12x">
          <a className="link" onClick={toggleAllRecords}>
            <strong>See Additional Records</strong>
          </a>
        </div>
      )
    }

    const additionalRecords = emrCachedPatients.slice(1)

    return additionalRecords.map((emrCachedPatient) => (
      <EmrCachedPatientSection
        key={emrCachedPatient.mrn}
        emrCachedPatient={emrCachedPatient}
        createDmeOrder={createDmeOrder}
        isPrimary={false}
        canCreateOrder={canCreateOrder}
      />
    ))
  }

  const firstRecord = emrCachedPatients[0]
  const numberOfRecords = emrCachedPatients.length

  return (
    <>
      <div>
        <h2 className="d-inline-block">Integrated Data</h2>
        <div className="float-right">
          <span className="font-xs color-dark-gray">
            {numberOfRecords} {pluralize("Record", numberOfRecords)} Available
          </span>
        </div>
      </div>
      <EmrCachedPatientSection
        key={firstRecord.mrn}
        emrCachedPatient={firstRecord}
        isPrimary={hasPrimaryAction}
        createDmeOrder={createDmeOrder}
        canCreateOrder={canCreateOrder}
      />
      {numberOfRecords > 1 && renderAdditionalRecords(showAllRecords)}
    </>
  )
}

export default IntegratedData
