import React from "react"
import Alert from "components/Alert"
import { Flash } from "../sharedTypes"

interface Props {
  flash?: Flash
}

const STATUS_MAP = {
  notice: "info",
  alert: "danger",
}

function FlashAlerts({ flash }: Props) {
  if (!flash) {
    return <></>
  }

  const FlashAlert = ({ flashType, message }) => {
    if (!message) {
      return <></>
    }
    return (
      <Alert className="canopy-mbe-8x" status={STATUS_MAP[flashType]} leftIcon>
        {message}
      </Alert>
    )
  }
  return (
    <>
      {Object.keys(flash).map((key) => (
        <FlashAlert
          key={key + flash[key]}
          flashType={key}
          message={flash[key]}
        />
      ))}
    </>
  )
}

export default FlashAlerts
