import React, { useState } from "react"
import Cards from "./Cards"
import DenyOrderModal from "./DenyOrderModal"
import { DmeOrder, Document, RxDetailsOutput } from "sharedTypes"
import { SelectOption } from "components/form/sharedTypes"
import SelfSignAttestation from "components/SelfSignAttestation"
import { Document as DocumentsBrowserDocument } from "components/DocumentsBrowser/sharedTypes"

type Props = {
  dmeOrder: DmeOrder
  approve(): Promise<void>
  deny(params: { reason: string; description: string }): Promise<void>
  denialReasons: SelectOption[]
  isApproving: boolean
  downloadableDocuments: Document[]
  documents: DocumentsBrowserDocument[]
  rxDetailsOutputs: RxDetailsOutput[]
}

const InternalApproval = (props: Props) => {
  const {
    approve,
    denialReasons,
    deny,
    dmeOrder,
    downloadableDocuments,
    documents,
    isApproving,
    rxDetailsOutputs,
  } = props
  const [isDenyModalOpen, setDenyModalOpen] = useState(false)

  return (
    <div>
      <div>
        <h1>Approve Order</h1>
        <Cards
          dmeOrder={dmeOrder}
          downloadableDocuments={downloadableDocuments}
          documents={documents}
          rxDetailsOutputs={rxDetailsOutputs}
        />
      </div>
      <div className="sticky-footer-push" />
      <div className="sticky-footer">
        <div className="text-center">
          <SelfSignAttestation />
          <div className="canopy-mbs-12x">
            <button
              type="button"
              className="btn btn-primary canopy-mie-8x"
              onClick={approve}
              disabled={isApproving}
            >
              Approve Order
            </button>
            <button
              onClick={() => setDenyModalOpen(true)}
              type="button"
              className="btn btn-secondary"
            >
              Deny Order
            </button>
          </div>
        </div>
      </div>
      <DenyOrderModal
        show={isDenyModalOpen}
        close={() => setDenyModalOpen(false)}
        deny={deny}
        denialReasons={denialReasons}
      />
    </div>
  )
}
export default InternalApproval
