import React from "react"
import Modal from "components/Modal"
import { Form, EmailField, PhoneField } from "components/form"
import Link from "../../../../../components/Link"

type Props = {
  id: string
  name: string
  title: string
  show: boolean
  cancel(): void
  onSubmit(values, { setErrors }): Promise<void>
  validate(attributes): object
  initialValues?: object
}

const ContactFormModal = ({
  id,
  name,
  title,
  show,
  cancel,
  onSubmit,
  validate,
  initialValues,
}: Props) => {
  const renderBody = () => {
    if (name === "email") {
      return <EmailField id={id} name={name} />
    }

    const disclaimer = (
      <div className="canopy-mis-2x canopy-mie-2x font-xs">
        <p>
          A signature request will be sent by text message. By entering this
          phone number, you confirm that the recipient agrees to receive such
          messages. Message & data rates may apply. Mobile Carriers are not
          liable for delayed or undelivered messages.{" "}
          <Link
            href="https://www.parachutehealth.com/privacy-policy"
            target="_blank"
          >
            View Privacy Policy
          </Link>
        </p>
      </div>
    )

    return (
      <>
        <PhoneField id={id} name={name} />
        {name === "mobileNumber" ? disclaimer : null}
      </>
    )
  }

  return (
    <Modal show={show} size="md" title={title} cancel={cancel}>
      <Form
        onSubmit={onSubmit}
        validate={validate}
        initialValues={initialValues}
      >
        {({ isSubmitting }) => (
          <>
            <Modal.Body>{renderBody()}</Modal.Body>

            <Modal.Footer>
              <button
                type="submit"
                className="btn btn-primary"
                disabled={isSubmitting}
              >
                Save
              </button>
            </Modal.Footer>
          </>
        )}
      </Form>
    </Modal>
  )
}

export default ContactFormModal
