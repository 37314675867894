export function formatAddress({
  city,
  state,
}: {
  city?: string
  state?: string
}): string {
  return [city, state].filter(Boolean).join(", ")
}

export function supplierOverlineText({
  recentlyOrderedFrom,
  connected,
}: {
  recentlyOrderedFrom: boolean
  connected: boolean
}): string | undefined {
  if (connected) {
    return "In Your Org's Supplier Menu"
  }
  if (recentlyOrderedFrom) {
    return "Recently Ordered From"
  }
}
