import React, { useState } from "react"
import { Context, DmeOrder, Document, RxDetailsOutput } from "sharedTypes"
import { Document as DocumentsBrowserDocument } from "components/DocumentsBrowser/sharedTypes"
import { handleError } from "utilities/error"
import { navigate } from "utilities/navigation"
import InternalApproval from "./components/InternalApproval"
import * as api from "./api"
import { SelectOption } from "components/form/sharedTypes"
import GlobalContext from "context/Global"

type Props = {
  denialReasons: SelectOption[]
  dmeOrder: DmeOrder
  downloadableDocuments: Document[]
  documents: DocumentsBrowserDocument[]
  rxDetailsOutputs: RxDetailsOutput[]
  context: Context
}

const DoctorApproval = (props: Props) => {
  const {
    denialReasons,
    dmeOrder,
    downloadableDocuments,
    documents,
    rxDetailsOutputs,
    context,
  } = props

  const [isApproving, setApproving] = useState(false)

  const approve = () => {
    setApproving(true)
    return api
      .approve(dmeOrder)
      .then(({ data }) => navigate(data.afterApprovePath))
      .catch(handleError)
  }

  const deny = (params: { reason: string; description: string }) => {
    return api
      .deny({ ...params, dmeOrderId: dmeOrder.id })
      .then(({ data }) => navigate(data.afterDenyPath))
      .catch((httpResponse) => Promise.reject(httpResponse.response.data))
  }

  return (
    <GlobalContext.Provider value={context}>
      <InternalApproval
        approve={approve}
        deny={deny}
        denialReasons={denialReasons}
        isApproving={isApproving}
        downloadableDocuments={downloadableDocuments}
        documents={documents}
        dmeOrder={dmeOrder}
        rxDetailsOutputs={rxDetailsOutputs}
      />
    </GlobalContext.Provider>
  )
}

export default DoctorApproval
