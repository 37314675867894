import React from "react"
import { useFeatureFlags } from "components/FeatureFlagContext"
import { Card, CardContent, Typography } from "@material-ui/core"
import { employerPrefix } from "utilities/url"
import * as styles from "./index.module.scss"
import { EventCategory, trackEvent } from "utilities/tracking"
import { Document } from "components/DocumentsBrowser/sharedTypes"

export interface SigningSignatureRequest {
  id: string
  patient: {
    name: string
    dateOfBirth: string
  }
  review?: {
    reviewerName: string
  }
  documentDisplayName: string
  productDescriptions: string[]
  supplierSystemOrderId: string
  document: Document
}

export const SignatureRequestCard = (props: {
  signatureRequest: SigningSignatureRequest
  doctorId: string
}) => {
  const DOCTOR_VIEW_RESUPPLY_ORDER_EVENT_NAME =
    "doctor_dash_view_resupply_order"
  const { isFeatureEnabled } = useFeatureFlags()
  const renameWopdToPrescription = isFeatureEnabled("renameWopdToPrescription")
  return (
    <Card variant="outlined" className={styles.card}>
      <CardContent className={styles.content}>
        <div className={styles.flexContainer}>
          <Typography variant="subtitle1" className={styles.patientNameColumn}>
            <strong>{props.signatureRequest.patient.name}</strong>
          </Typography>
          <div className={styles.viewButtonColumn}>
            <a
              className="link"
              href={`${employerPrefix(
                "Doctor",
                props.doctorId
              )}/signing_signature_requests/${props.signatureRequest.id}`}
              onClick={() => {
                trackEvent(
                  EventCategory.Resupply,
                  DOCTOR_VIEW_RESUPPLY_ORDER_EVENT_NAME
                )
              }}
            >
              View
            </a>
          </div>
        </div>
        <Typography variant="subtitle1">
          {renameWopdToPrescription &&
          props.signatureRequest.documentDisplayName
            ? "Prescription"
            : props.signatureRequest.documentDisplayName}
        </Typography>
        {props.signatureRequest.productDescriptions.length > 0 && (
          <Typography variant="subtitle1">
            Products: {props.signatureRequest.productDescriptions.join("; ")}
          </Typography>
        )}
        {props.signatureRequest.review && (
          <Typography variant="subtitle1">
            Reviewed By: {props.signatureRequest.review.reviewerName}
          </Typography>
        )}
      </CardContent>
    </Card>
  )
}
