import { employerPrefix } from "../../utilities/url"

export const emailNotificationsOptInUrl =
  "/u/reviewers/email_notification_opt_in"

export const notificationPreferencesPath = (doctorId: string) =>
  `${employerPrefix(
    "Doctor",
    doctorId
  )}/reviewers/user_preferences/notification_preferences/edit`

export const refreshDoctorPath = (doctorId: string) =>
  `${employerPrefix(
    "Doctor",
    doctorId
  )}/reviewers_signature_request_dashboard/doctor`

export const actionableSignatureRequestsCheckUrl = (doctorId: string) =>
  `${employerPrefix(
    "Doctor",
    doctorId
  )}/reviewers_signature_request_dashboard/reviewable_and_signable_signature_requests_check`

export const fetchSignatureRequestsUrl = (doctorId: string) =>
  `${employerPrefix("Doctor", doctorId)}/signature_requests.json`
