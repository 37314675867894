import React from "react"
import {
  asForm,
  TextField,
  PhoneField,
  NativeSelect as Select,
} from "components/form"
import Overlay from "components/Overlay"
import { states } from "utilities/address"

type Props = {
  isSubmitting: boolean
}

function AddressForm({ isSubmitting }: Props) {
  const stateOptions = states.map((state) => ({
    label: state[0],
    value: state[1],
  }))

  return (
    <Overlay active={isSubmitting} showSpinner>
      <div className="row">
        <div className="col-md-6">
          <TextField
            label="Street - Line 1"
            name="line1"
            placeholder="Enter Address Line 1"
          />
        </div>
        <div className="col-md-6">
          <TextField
            label="Street - Line 2 (Optional)"
            name="line2"
            placeholder="Enter Address Line 2"
          />
        </div>
      </div>
      <div className="row">
        <div className="col-md-6">
          <TextField
            label="City"
            name="city"
            placeholder="Enter City"
            unwrapped
          />
        </div>
        <div className="col-md-3">
          <Select label="State" name="state" options={stateOptions} unwrapped />
        </div>
        <div className="col-md-3">
          <TextField
            label="ZIP"
            name="zip"
            numeric
            placeholder="Enter ZIP"
            unwrapped
          />
        </div>
      </div>
      <hr />
      <div className="row">
        <div className="col-md-6">
          <PhoneField label="Phone Number" name="phoneNumber" />
        </div>
      </div>
      <div className="form-actions text-right">
        <button type="submit" className="btn btn-brand" disabled={isSubmitting}>
          Save
        </button>
      </div>
    </Overlay>
  )
}

export default asForm(AddressForm)
