import React, { useState } from "react"
import { meta } from "utilities/meta"

type Props = {
  action: string
  className: string
  buttonText: string
}

const SingleButtonForm = ({ action, className, buttonText }: Props) => {
  const [disabled, setDisabled] = useState(false)

  return (
    <form
      action={action}
      method="post"
      onSubmit={() => {
        setDisabled(true)
      }}
    >
      <input
        type="hidden"
        name="authenticity_token"
        value={meta("csrf-token")}
      />
      <button disabled={disabled} type="submit" className={className}>
        {buttonText}
      </button>
    </form>
  )
}

export default SingleButtonForm
