// @team @fhirbenders

import { gql } from "@apollo/client"
import {
  GetPendingOrderQuery,
  GetPendingOrderQueryVariables,
} from "../../../../graphql/__generated__/graphql"
import { TypedDocumentNode } from "@graphql-typed-document-node/core"

const GetPendingOrder: TypedDocumentNode<
  GetPendingOrderQuery,
  GetPendingOrderQueryVariables
> = gql`
  query GetPendingOrder($patientExternalId: String!) {
    pendingOrder(patientExternalId: $patientExternalId) {
      id
      creator {
        firstName
        lastName
      }
      createdAt
    }
  }
`
export default GetPendingOrder
