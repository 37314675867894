import React, { ReactElement } from "react"
import SelectFilter from "components/SelectFilter"
import { Doctor } from "graphql/__generated__/graphql"

interface Props {
  doctors: Doctor[]
  fetchClinicians(term: string): Promise<{ label: string; value: string }[]>
  onChange(values: { doctorIds: string[] }): void
  initialDoctorIds: string[]
}

function ClinicianSelect({
  doctors,
  fetchClinicians,
  onChange,
  initialDoctorIds,
}: Props): ReactElement {
  const options = doctors.map((doctor) => {
    return {
      label: doctor.name,
      value: doctor.id,
    }
  })

  return (
    <SelectFilter
      name="doctorIds"
      label="Clinicians"
      initialValues={initialDoctorIds}
      options={options}
      fetchOptions={fetchClinicians}
      onChange={onChange}
      minLength={0}
      enableReinitialize
    />
  )
}

export default ClinicianSelect
