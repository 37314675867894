import React from "react"

type Props = {
  loading?: boolean
  filteredCount: number
  suffix: string
  sortingBy: string
}

function TableOverview({ loading, filteredCount, suffix, sortingBy }: Props) {
  return (
    <div className="canopy-pis-8x canopy-my-4x h-50px bt bc-g">
      <span className="font-subparagraph">
        <span className="color-dark-gray">Results:</span>{" "}
        {loading ? "x" : filteredCount} {suffix}
      </span>
      {sortingBy && (
        <span className="font-subparagraph canopy-mis-12x">
          <span className="color-dark-gray">Sorting by:</span> {sortingBy}{" "}
        </span>
      )}
    </div>
  )
}

export default TableOverview
