import React from "react"
import ReactSwitch from "react-switch"
import { theme } from "themes/theme"

type Props = {
  id: string
  onChange: (checked: boolean) => void
  checked: boolean
  label?: string
  disabled?: boolean
  className?: string
  uncheckedIcon?: JSX.Element | boolean
  checkedIcon?: JSX.Element | boolean
  height?: number
  width?: number
  dark?: boolean
}

function Switch({
  id,
  onChange,
  checked,
  label,
  disabled,
  className,
  uncheckedIcon,
  checkedIcon,
  height,
  width,
  dark,
}: Props) {
  const { colors } = theme
  return (
    <div className={className + " switch"}>
      <ReactSwitch
        id={id}
        disabled={disabled}
        onChange={onChange}
        checked={checked}
        checkedIcon={checkedIcon ? checkedIcon : false}
        uncheckedIcon={uncheckedIcon ? uncheckedIcon : false}
        height={height || 16}
        width={width || 28}
        handleDiameter={14}
        onColor={colors.primary.default}
        offColor={dark ? colors.gray.dark : colors.gray.mid}
      />
      {label && (
        <label
          htmlFor={id}
          className="color-dark-gray font-subparagraph"
          data-testid="switch-label"
        >
          {label}
        </label>
      )}
    </div>
  )
}

export default Switch
