import React from "react"
import withInitialData from "components/withInitialData"
import Section from "components/Section"
import SnapshotSectionContent from "./components/SnapshotSectionContent"
import { getSnapshotData } from "./api"
import { DmeOrderSnapshot } from "sharedTypes"
import WorkflowHeader from "applications/Workflow/components/WorkflowHeader"

type InitialData = {
  snapshot: DmeOrderSnapshot
  snapshotTitle?: string
}

type Props = {
  initialData: InitialData
}

function Snapshot({ initialData }: Props) {
  const { snapshot, snapshotTitle } = initialData

  const titleText = () => {
    if (snapshotTitle) {
      return snapshotTitle
    }
    return "Order Summary"
  }
  return (
    <div>
      <WorkflowHeader title={titleText()} />
      <div>
        {snapshot.map((section) => {
          return (
            <Section
              key={section.section}
              title={section.section}
              initialExpanded
              hideToggle
            >
              <SnapshotSectionContent fields={section.fields} />
            </Section>
          )
        })}
      </div>
    </div>
  )
}

const fetchInitialData = () => getSnapshotData().then(({ data }) => data)
export default withInitialData(fetchInitialData)(Snapshot)
