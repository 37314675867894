import React from "react"
import { Form, PasswordField } from "components/form"
import Button from "components/form/Button"
import Pill from "components/Pill"
import ErrorText from "components/form/ErrorText"
import { humanizeErrors } from "utilities/password"
import {
  validate,
  validatePassword,
  PasswordError,
} from "./utilities/validation"
import { getPillStatus } from "./utilities/pill"

interface Props {
  header: string
  updatePassword(params): Promise<void>
}

function PasswordForm({ header, updatePassword }: Props) {
  const onSubmit = (values, { setErrors }) => {
    return updatePassword(values).catch(({ errors }) => {
      setErrors(humanizeErrors(errors))
    })
  }

  return (
    <>
      <h1 className="canopy-mbe-24x">{header}</h1>
      <Form
        initialValues={{ password: "", passwordConfirmation: "" }}
        onSubmit={onSubmit}
        validate={validate}
      >
        {({ values, touched, errors, isSubmitting }) => {
          const { password } = values
          const passwordErrors = validatePassword(password)
          return (
            <>
              <PasswordField
                autoFocus
                autoComplete="new-password"
                subtext="Password must contain at least:"
                unwrapped
              />
              <div className="canopy-mbe-8x">
                <Pill
                  className="canopy-mie-2x pill-default-bg"
                  status={getPillStatus({
                    valid: !passwordErrors.includes(PasswordError.TooShort),
                    touched: touched.password,
                  })}
                >
                  8 characters
                </Pill>
                <Pill
                  className="canopy-mie-2x pill-default-bg"
                  status={getPillStatus({
                    valid: !passwordErrors.includes(
                      PasswordError.MissingCapitalLetter
                    ),
                    touched: touched.password,
                  })}
                >
                  1 capital letter
                </Pill>
                <Pill
                  className="canopy-mie-2x pill-default-bg"
                  status={getPillStatus({
                    valid: !passwordErrors.includes(
                      PasswordError.MissingLowercaseLetter
                    ),
                    touched: touched.password,
                  })}
                >
                  1 lowercase letter
                </Pill>
                <Pill
                  className="pill-default-bg"
                  status={getPillStatus({
                    valid: !passwordErrors.includes(
                      PasswordError.MissingNumber
                    ),
                    touched: touched.password,
                  })}
                >
                  1 number
                </Pill>
              </div>
              <PasswordField confirmation autoComplete="new-password" />
              <Button
                className="btn-brand btn-block"
                type="submit"
                disabled={isSubmitting}
              >
                Continue
              </Button>
              {errors && errors.resetPasswordToken && (
                <ErrorText error="Reset password token is invalid" />
              )}
            </>
          )
        }}
      </Form>
    </>
  )
}

export default PasswordForm
