import React, { useEffect } from "react"
import { useHistory } from "react-router-dom"
import { trackPageView } from "../utilities/analytics"

type Props = {
  basename?: string
}

export const HistoryListener: React.FC<Props> = ({ basename }) => {
  const history = useHistory()
  const baseUri = basename || ""
  useEffect(() => {
    const unlisten = history.listen((location) => {
      trackPageView(`${baseUri}${location.pathname}${location.search}`)
    })

    return unlisten
  }, [history, baseUri])
  return <></>
}
