import React from "react"
import Button from "components/form/Button"
import { Form } from "components/form"
import FacilitySelect from "../FacilitySelect"
import FacilityPreview from "./FacilityPreview"
import FacilityPrompt from "./FacilityPrompt"
import SignedAgreementCheckbox from "../SignedAgreementCheckbox"
import {
  FacilitySearchResult,
  FacilityDetails,
  FacilityUpdateParams,
} from "../../sharedTypes"
import {
  buttonText,
  isSignable,
  promptTitle,
  promptContent,
  trackFacilityEvent,
} from "./utilities"
import { handleError } from "utilities/error"
import { User } from "../../../FacilitySignUp/shared/sharedTypes"

import { trackReviewDetails } from "../../../FacilitySignUp/shared/tracking"

type Props = {
  fetchFacilityDetails(
    externalId?: string,
    npi?: string
  ): Promise<FacilityDetails>
  updateFacility(params): Promise<void>
  search(term: string): Promise<FacilitySearchResult[]>
  navigateToFacilityConfirm(): void
  navigateToFacilityCreate(): void
  defaultFacility?: FacilityDetails
  selectedFacility?: FacilityDetails
  setSelectedFacility(facility): void
  user: User
}

const FacilitySearch = (props: Props) => {
  const {
    defaultFacility,
    selectedFacility,
    setSelectedFacility,
    fetchFacilityDetails,
    updateFacility,
    search,
    navigateToFacilityConfirm,
    navigateToFacilityCreate,
    user,
  } = props

  const onFacilityChange = (_newValue, facility: FacilitySearchResult) => {
    if (facility) {
      fetchFacilityDetails(facility.externalId, facility.npi)
        .then(setSelectedFacility)
        .catch(handleError)
    } else {
      setSelectedFacility(null)
    }
  }

  const initialValues = {
    facilityId: selectedFacility?.externalId || "",
    signedAgreement: false,
  }
  const validate = (params: FacilityUpdateParams) => {
    const { signedAgreement } = params
    const errors: {
      facilityId?: string
      signedAgreement?: string
    } = {}

    if (!selectedFacility) {
      errors.facilityId = "Select a facility"
    }
    if (
      selectedFacility &&
      selectedFacility.externalId &&
      !selectedFacility.signedAgreements &&
      !signedAgreement
    ) {
      errors.signedAgreement = "Must agree to Facility Terms of Use to continue"
    }
    return errors
  }

  const onSubmit = (params: FacilityUpdateParams, { setErrors }) => {
    if (!params.facilityId) {
      trackReviewDetails(user)
      navigateToFacilityConfirm()
      return Promise.resolve()
    }
    return updateFacility(params)
      .then(() => {
        trackFacilityEvent(user, selectedFacility)
      })
      .catch(({ errors }) => setErrors(errors))
  }

  const CreateFacilityLink = () => (
    <div className="canopy-mbs-24x canopy-pbs-4x text-center">
      Can’t find your healthcare facility?
      <a onClick={navigateToFacilityCreate} className="bold color-secondary">
        {" "}
        Create your facility
      </a>
    </div>
  )
  return (
    <>
      <h1>Join Your Healthcare Facility</h1>
      <p className="text-center text-xs-left canopy-mbe-24x">
        Search for your healthcare facility (hospital, clinic, nursing home,
        etc.) by name or address to join them on Parachute Health.
      </p>
      <Form
        initialValues={initialValues}
        onSubmit={onSubmit}
        validate={validate}
      >
        {({ isSubmitting }) => (
          <>
            <FacilitySelect
              defaultFacility={defaultFacility}
              name="facilityId"
              search={search}
              onChange={onFacilityChange}
            />
            <div className="facility-preview-wrapper">
              {selectedFacility && (
                <>
                  <FacilityPreview facility={selectedFacility} />
                  <FacilityPrompt
                    title={promptTitle(selectedFacility)}
                    content={promptContent(selectedFacility)}
                  />
                  {isSignable(selectedFacility) && <SignedAgreementCheckbox />}
                </>
              )}
            </div>
            <Button
              disabled={isSubmitting}
              className="btn-brand btn-block canopy-mbs-24x"
              type="submit"
            >
              {buttonText(selectedFacility)}
            </Button>
          </>
        )}
      </Form>
      <CreateFacilityLink />
    </>
  )
}

export default FacilitySearch
