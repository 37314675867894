import React from "react"
import { Formik, FormikErrors } from "formik"
import { CanopyDialogModal } from "@parachutehealth/canopy-dialog-modal"
import { CanopyButton } from "@parachutehealth/canopy-button"
import { CanopyForm } from "@parachutehealth/canopy-form"
import { CanopyTextInputField } from "@parachutehealth/canopy-text-input-field"
import { CanopyRequiredFieldKey } from "@parachutehealth/canopy-required-field-key"

// Component API
type OrderLinkageModalProps = {
  /**
   * Function called when the modal is closed
   */
  onClose: () => void
  /**
   * Function called when the form is submitted (and all fields are valid)
   */
  onSubmit: (supplierSystemId) => Promise<void>
  /**
   * When true, the modal is visible
   */
  open?: boolean
  /**
   * Initial value that populates the single text input on the form
   */
  supplierSystemId?: string
}

// Form field interface
interface OrderLinkageFields {
  supplierSystemId?: string
}

/**
 * Modal form used to link Brightree orders to Parachute orders
 */
const OrderLinkageModal = (props: OrderLinkageModalProps): JSX.Element => {
  const { onClose, onSubmit, open = true, supplierSystemId } = props

  const handleFormSubmission = (values) => {
    return onSubmit(values.supplierSystemId).then(() => onClose())
  }

  const initialValues: OrderLinkageFields = {
    supplierSystemId: supplierSystemId,
  }

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={handleFormSubmission}
      validate={(values) => {
        const errors: FormikErrors<OrderLinkageFields> = {}
        if (!values.supplierSystemId) {
          errors.supplierSystemId =
            "Enter the Brightree sales ID to link to this Parachute order"
        }
        return errors
      }}
    >
      {({
        values,
        errors,
        touched,
        handleBlur,
        handleSubmit,
        handleChange,
        isSubmitting,
        submitCount,
      }) => {
        const supplierSystemIdError =
          submitCount > 0 && touched.supplierSystemId
            ? (errors.supplierSystemId as string)
            : undefined

        return (
          <CanopyDialogModal
            header="Link Parachute order to Brightree"
            onClose={onClose}
            open={open}
            primaryFooterButton={
              <CanopyButton
                type="submit"
                form="brightreeSalesIdLinkageForm"
                loading={isSubmitting}
                loadingText="Linking Brightree Order..."
              >
                Save
              </CanopyButton>
            }
          >
            <CanopyForm
              id="brightreeSalesIdLinkageForm"
              onSubmit={handleSubmit}
            >
              <CanopyRequiredFieldKey />
              <CanopyTextInputField
                feedbackMessage={supplierSystemIdError}
                label="Brightree sales ID"
                name="supplierSystemId"
                onChange={handleChange}
                onBlur={handleBlur}
                required
                value={values.supplierSystemId}
              />
            </CanopyForm>
          </CanopyDialogModal>
        )
      }}
    </Formik>
  )
}

export default OrderLinkageModal
