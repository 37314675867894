import React from "react"
import * as styles from "./index.module.scss"
import { supportUrl } from "applications/Workflow/urls"
import { CanopyIcon } from "@parachutehealth/canopy-icon"
import { EventCategory, trackEvent } from "utilities/tracking"
import { CanopyButton } from "@parachutehealth/canopy-button"
import { openChat } from "./api/zendesk"
import { ChatSupportLevel } from "./sharedTypes"
import eventEmitter from "../../utilities/eventEmitter"

const EVENT_CATEGORY = EventCategory.FacilitySupportPage

interface Props {
  chatSupportLevel: ChatSupportLevel
}

const FacilitySupportPage: React.FC<Props> = ({ chatSupportLevel }: Props) => {
  const handleOpenChat = () => {
    if (chatSupportLevel === ChatSupportLevel.ZendeskNativeChat) {
      openChat()
    } else if (chatSupportLevel === ChatSupportLevel.CustomChat) {
      eventEmitter.emit("chat-link:clicked")
    }
  }

  const liveChatCard = (
    <div className={styles.card}>
      <CanopyIcon className={styles.icon} name="message"></CanopyIcon>
      <h3>Live chat</h3>
      <p>Available Monday to Friday</p>
      <CanopyButton
        className={styles.btn}
        size="medium"
        variant="tertiary"
        onClick={() => {
          handleOpenChat()
          trackEvent(EVENT_CATEGORY, "open_chat_button_clicked")
        }}
      >
        Open Chat
      </CanopyButton>
    </div>
  )

  const emailCard = (
    <div className={styles.card}>
      <CanopyIcon className={styles.icon} name="envelope"></CanopyIcon>
      <h3>Send an email</h3>
      <p>support@parachutehealth.com</p>
      <CanopyButton
        className={styles.btn}
        size="medium"
        variant="tertiary"
        onClick={() => {
          window.open("mailto:support@parachutehealth.com")
          trackEvent(EVENT_CATEGORY, "email_button_clicked")
        }}
      >
        Email Us
      </CanopyButton>
    </div>
  )

  return (
    <>
      <div className={styles.container}>
        <h1 className={styles.mainHeader}>Need help using Parachute Health?</h1>
        <h3 className="canopy-mbe-0">We are happy to provide assistance!</h3>
        <div className={styles.cardContainer}>
          {chatSupportLevel === ChatSupportLevel.NoChat
            ? emailCard
            : liveChatCard}
          <div className={styles.card}>
            <CanopyIcon name="laptop" className={styles.icon} />
            <h3>Schedule a demo</h3>
            <p>Live step by step guidance</p>
            <CanopyButton
              className={styles.btn}
              size="medium"
              variant="tertiary"
              onClick={() => {
                window.open("https://www.parachutehealth.com/demo", "_blank")
                trackEvent(EVENT_CATEGORY, "schedule_demo_button_clicked")
              }}
            >
              Schedule Demo
            </CanopyButton>
          </div>
          <div className={styles.card}>
            <CanopyIcon name="book-open" className={styles.icon} />
            <h3>Browse FAQs</h3>
            <p>Read from the knowledge base</p>
            <CanopyButton
              className={styles.btn}
              size="medium"
              variant="tertiary"
              onClick={() => {
                window.open(supportUrl, "_blank", "noopener,noreferrer")
                trackEvent(EVENT_CATEGORY, "view_faqs_button_clicked")
              }}
            >
              View FAQs
            </CanopyButton>
          </div>
        </div>
      </div>
      <div className={styles.background} />
    </>
  )
}

export default FacilitySupportPage
