import React, { CSSProperties } from "react"
import classNames from "classnames"
import { Prefix } from "sharedTypes"

type Flip = "horizontal" | "vertical" | "both"

export const ICON_TYPES = [
  "address-card",
  "angle-double-down",
  "angle-double-left",
  "angle-double-right",
  "angle-double-up",
  "angle-down",
  "angle-left",
  "angle-right",
  "angle-up",
  "arrow-down",
  "arrow-left",
  "arrow-right",
  "arrow-up",
  "ban",
  "bell",
  "bell-slash",
  "birthday-cake",
  "book",
  "calendar-alt",
  "camera",
  "caret-down",
  "caret-up",
  "cart-plus",
  "check",
  "check-circle",
  "chevron-down",
  "chevron-left",
  "chevron-right",
  "chevron-up",
  "circle",
  "circle-notch",
  "clinic-medical",
  "cloud-download-alt",
  "cloud-upload-alt",
  "cog",
  "comment",
  "comment-dots",
  "download",
  "edit",
  "envelope",
  "exclamation-circle",
  "exclamation-triangle",
  "exclamation-diamond",
  "external-link-alt",
  "eye-slash",
  "fax",
  "file",
  "file-alt",
  "file-pdf",
  "flag",
  "home",
  "hospital",
  "info-circle",
  "link",
  "lock",
  "long-arrow-alt-left",
  "long-arrow-alt-right",
  "minus",
  "mobile",
  "paperclip",
  "parachute-box",
  "pencil-alt",
  "phone",
  "plus",
  "redo",
  "redo-alt",
  "save",
  "search",
  "share",
  "shipping-fast",
  "shopping-cart",
  "sign-out-alt",
  "sort-down",
  "sort-up",
  "spinner",
  "star",
  "thumbtack",
  "times",
  "trash",
  "trash-alt",
  "question",
  "question-circle",
  "user",
  "user-nurse",
] as const

export type IconType = typeof ICON_TYPES[number]

type Props = {
  className?: string
  prefix?: Prefix
  type: IconType
  spin?: boolean
  size?: string
  id?: string
  flip?: Flip
  onClick?(event): void
  clickable?: boolean
  disabled?: boolean
  ariaLabel?: string
  style?: CSSProperties
}

function Icon({
  className,
  id,
  ariaLabel,
  clickable,
  disabled,
  flip,
  onClick,
  prefix,
  size,
  spin,
  style,
  type,
}: Props) {
  return (
    <span
      id={id}
      className={classNames(
        className,
        "icon",
        `${prefix} fa-${type}`,
        size && `fa-${size}`,
        flip && `fa-flip-${flip}`,
        {
          "fa-spin": spin,
          clickable: clickable,
          disabled: disabled,
        }
      )}
      aria-label={ariaLabel}
      onClick={onClick}
      style={style}
    />
  )
}

Icon.defaultProps = {
  prefix: "fas",
}

export default Icon
