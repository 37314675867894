import React from "react"
import Modal from "components/Modal"
import { Form, RadioButtons } from "components/form"
import Button from "components/form/Button"
import { ConsignmentCloset } from "sharedTypes"
import { handleError } from "utilities/error"
import { closetLabel } from "./utils"

type Props = {
  show: boolean
  closets: ConsignmentCloset[]
  cancel(): void
  selectDefaultCloset(id: string): Promise<void>
}

const ConsignmentClosetNudgeModal = ({
  show,
  cancel,
  selectDefaultCloset,
  closets,
}: Props) => {
  const onSubmit = ({ consignmentClosetId }) => {
    selectDefaultCloset(consignmentClosetId).catch(handleError)
  }

  const closetOptions = closets.map((closet) => ({
    label: closetLabel(closet),
    value: closet.externalId,
  }))

  return (
    <Modal title="Consignment Locations" show={show} cancel={cancel} size="md">
      <Form onSubmit={onSubmit} initialValues={{ consignmentClosetId: "" }}>
        {({ values, isSubmitting }) => (
          <>
            <Modal.Body>
              <h4>Do you order consignment products?</h4>
              <p className="canopy-mbe-8x">
                Save time later and set your default closet location.
              </p>
              <RadioButtons
                name="consignmentClosetId"
                options={closetOptions}
                block
              />
            </Modal.Body>
            <Modal.Footer>
              <div className="text-right">
                <button
                  type="button"
                  className="btn btn-brand-o canopy-mie-8x"
                  onClick={cancel}
                >
                  Skip for now
                </button>
                <Button
                  type="submit"
                  className="btn-brand"
                  loading={isSubmitting}
                  disabled={!values.consignmentClosetId}
                >
                  Continue
                </Button>
              </div>
            </Modal.Footer>
          </>
        )}
      </Form>
    </Modal>
  )
}

export default ConsignmentClosetNudgeModal
