import React from "react"
import SearchFilter from "components/SearchFilter"

interface Props {
  initialQuery: string
  loading?: boolean
  onChange(query: string): void
}

function PatientSelect({ initialQuery, loading, onChange }: Props) {
  return (
    <SearchFilter
      loading={loading}
      onChange={onChange}
      placeholder="Filter by patient"
      value={initialQuery}
    />
  )
}

export default PatientSelect
