import {
  StepId,
  stepIdToStepTitle,
} from "applications/Workflow/utilities/steps"
import { getWarningText } from "applications/Workflow/utilities/warningText"
import React from "react"
import { Context, DmeOrder, DmeOrderWarning } from "sharedTypes"
import * as styles from "./index.module.scss"
import { groupWarningsByStep } from "./utilities"
import classNames from "classnames"

type Props = {
  dmeOrder: DmeOrder
  warnings: DmeOrderWarning[]
  context: Context
}

const renderStep = (
  stepWithWarnings: [StepId, DmeOrderWarning[]],
  index: number
) => {
  const [stepName, warnings] = stepWithWarnings
  const combinedWarningText = warnings
    .map((warning) => warning.text)
    .join(" + ")

  return (
    <li key={stepName}>
      <h4 className={styles.warningsListItem} data-testid={`step-${index}`}>
        {stepIdToStepTitle(stepName)}
      </h4>
      <p className={styles.warningsListItem}>{combinedWarningText}</p>
    </li>
  )
}

const Warnings = ({ dmeOrder, warnings, context }: Props) => {
  if (warnings.length === 0) return null

  const warningsWithText: DmeOrderWarning[] = warnings.map((warning) => ({
    ...warning,
    text: getWarningText(warning, context.currentEmployer),
  }))

  const stepsWithWarnings = groupWarningsByStep(
    warningsWithText,
    context,
    dmeOrder
  )

  return (
    <div
      className={classNames(
        "canopy-mbe-12x canopy-pbs-8x canopy-px-12x",
        styles.warningsContainer
      )}
    >
      <h3 className={styles.warningsHeader}>Information Needed</h3>
      <ul className={styles.warningsList}>
        {stepsWithWarnings.map((step, index) => renderStep(step, index))}
      </ul>
    </div>
  )
}

export default Warnings
