import React from "react"
import { CatalogBrowseTab } from "sharedTypes"
import Link from "components/Link"

interface Props {
  setSelectedTab(tab: CatalogBrowseTab): void
  selectedTab: CatalogBrowseTab
}

const ProductSearchTabsSubheader: React.FC<Props> = ({
  setSelectedTab,
  selectedTab,
}) => {
  const subheadersByTab = {
    [CatalogBrowseTab.Product]: {
      subheading:
        "Choose a product, then select a supplier. Want to choose a supplier first?",
      linkText: "Switch to Search by Supplier",
    },
    [CatalogBrowseTab.Supplier]: {
      subheading:
        "Choose a supplier, then add products. Want to choose a product first?",
      linkText: "Switch to Search by Product",
    },
  }

  const handleTabSelect = () =>
    selectedTab === CatalogBrowseTab.Product
      ? setSelectedTab(CatalogBrowseTab.Supplier)
      : setSelectedTab(CatalogBrowseTab.Product)

  return (
    <div className="canopy-my-8x">
      {subheadersByTab[selectedTab].subheading}
      <Link className="canopy-mis-2x link" href="#" onClick={handleTabSelect}>
        {subheadersByTab[selectedTab].linkText}
      </Link>
    </div>
  )
}

export default ProductSearchTabsSubheader
