import React from "react"

interface Props {
  nonprodHeaderDetails?: string
}
const NonProdHeader: React.FC<Props> = ({ nonprodHeaderDetails }) => {
  if (!nonprodHeaderDetails) {
    return null
  }

  return (
    <div className="pill pill-solid pill-secondary text-dark bold canopy-mbs-4x">
      <div className="fas fa-exclamation-circle text-dark" />
      {nonprodHeaderDetails}
    </div>
  )
}

export default NonProdHeader
