export function verifyUrl() {
  return "/invitations"
}

export function loginUrl() {
  return "/invitations/login"
}

export function resendUrl() {
  return "/invitations/resend"
}

export function rejectUrl(identityVerificationId) {
  return `/identity_verifications/${identityVerificationId}/reject.json`
}

export function thankyouUrl(identityVerificationId) {
  return `/identity_verifications/${identityVerificationId}/thanks`
}

export function dmeOrderUrl(clinicalFacilityId, dmeOrderId) {
  return `/u/f/${clinicalFacilityId}/orders/${dmeOrderId}`
}

export function dmeOrderLandingUrl(clinicalFacilityId, dmeOrderId) {
  return `${dmeOrderUrl(clinicalFacilityId, dmeOrderId)}/landing`
}
