import React, { useState } from "react"
import RequestSupplierModal from "applications/ManageSuppliers/components/RequestSupplierModal"
import { useToaster } from "components/Toaster"
import classNames from "classnames"
import cx from "classnames"
import { CanopyButton } from "@parachutehealth/canopy-button"
import * as styles from "./NoResults.module.scss"

interface Props {
  clearFilters: () => void
  filtersApplied: boolean
}

const NoResults: React.FC<Props> = ({ clearFilters, filtersApplied }) => {
  const { persistentAlert } = useToaster()
  const [showModal, toggleModal] = useState<boolean>(false)
  const openModal = () => toggleModal(true)
  const closeModal = () => toggleModal(false)
  const afterSubmit = (message) => {
    closeModal()
    persistentAlert({
      message,
      severity: "info",
      position: { vertical: "top", horizontal: "right" },
    })
    return Promise.resolve()
  }

  return (
    <div className="canopy-mt-12x">
      <div
        className={classNames(
          "canopy-py-12x canopy-px-12x bg-white well well-expand"
        )}
      >
        <h3 className="canopy-typography-heading-large canopy-mb-8x">
          Can't find the supplier you're looking for?
        </h3>
        <div
          className={cx(
            "d-flex",
            "flex-column",
            "flex-md-row",
            styles.buttonContainer
          )}
        >
          {filtersApplied && (
            <CanopyButton
              variant="primary"
              onClick={() => {
                clearFilters()
              }}
            >
              Clear filters
            </CanopyButton>
          )}
          <CanopyButton variant="secondary" onClick={openModal}>
            Request outside supplier
          </CanopyButton>

          <RequestSupplierModal
            showModal={showModal}
            onCancel={closeModal}
            afterSubmit={afterSubmit}
          />
        </div>
      </div>
    </div>
  )
}

export default NoResults
