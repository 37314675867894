import React from "react"
import { Grid } from "@material-ui/core"
import { DoctorDashboardDmeOrder } from "../../sharedTypes"
import {
  SignatureRequestCard,
  SigningSignatureRequest,
} from "./SignatureRequestCard"
import { OrderCard } from "./OrderCard"

const OrdersList = ({
  orders,
  signingSignatureRequests,
  doctorId,
}: {
  orders: DoctorDashboardDmeOrder[]
  signingSignatureRequests: SigningSignatureRequest[]
  doctorId: string
}) => {
  return (
    <Grid container spacing={3}>
      {orders.map((s) => (
        <Grid key={s.id} item xs={12} sm={12} md={6}>
          <OrderCard order={s} />
        </Grid>
      ))}
      {signingSignatureRequests.map((s) => (
        <Grid key={s.id} item xs={12} sm={12} md={6}>
          <SignatureRequestCard signatureRequest={s} doctorId={doctorId} />
        </Grid>
      ))}
    </Grid>
  )
}

export default OrdersList
