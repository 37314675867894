import { get, post } from "../../services/api"
import { employerPrefix } from "utilities/url"
import { Document as SignatureRequestDocument } from "components/DocumentsBrowser/sharedTypes"
import { DmeOrder, Document as DmeOrderDocument, Context } from "sharedTypes"
import {
  emailNotificationsOptInUrl,
  refreshDoctorPath,
  actionableSignatureRequestsCheckUrl,
  fetchSignatureRequestsUrl,
} from "./urls"

export enum signatureStatus {
  NEEDS_REVIEW = "needs_review",
  REVIEWED = "reviewed",
  DONE = "done",
  DENIED = "denied",
  SIGNED = "signed",
}

export type DoctorSignatureRequest = {
  gid: string
  id: string
  patient: {
    name: string
    dateOfBirth: string
    sex: string
  }
  type: "DmeOrder" | "Signing::SignatureRequest"
  productDescriptions: string
  requestedBy: string
  status: "Reviewed" | "Pending Review" | "Signed" | "Denied"
  createdAt: string
  reviewedBy?: string
  doctorId: string
  supplierName: string
  denialReason?: { reason: string; description: string | null }
}

export type SignatureRequestsParams = {
  searchKeywords: string
}

const doctorEmployerPrefix = (doctorId) => employerPrefix("Doctor", doctorId)

export type SignatureRequestQueryParameters = {
  status: string
  searchKeywords?: string
}

export const fetchSignatureRequests = async ({
  doctorId,
  page,
  pageSize,
  searchParameters,
}: {
  doctorId: string
  page?: number
  pageSize?: number
  searchParameters: SignatureRequestQueryParameters
}): Promise<{
  results: DoctorSignatureRequest[]
  currentCount: number
  hasNextPage: boolean
}> => {
  const data = await get(fetchSignatureRequestsUrl(doctorId), {
    ...searchParameters,
    ...(page && { page }),
    ...(pageSize && { pageSize }),
  }).then((data) => {
    data.data.results.forEach(
      (s: DoctorSignatureRequest) => (s.doctorId = doctorId)
    )
    return data.data
  })
  //@ts-ignore
  return data
}

export type DoctorSignatureRequestDetails = DoctorSignatureRequest & {
  document?: SignatureRequestDocument
  signedDocument?: SignatureRequestDocument
}

export const getSignatureRequestDetails = async (
  doctorId: string,
  signatureRequestId: string
): Promise<DoctorSignatureRequestDetails> => {
  const detailsUrl = `${doctorEmployerPrefix(
    doctorId
  )}/signature_requests/${signatureRequestId}.json`

  return get(detailsUrl).then(({ data }) => {
    if (data.document) {
      data.document.permissions = { archive: false }
      data.document.downloadUrl = `${doctorEmployerPrefix(
        doctorId
      )}/signing_signature_requests/${signatureRequestId}/unsigned_pdf`
    }
    if (data.signedDocument) {
      data.signedDocument.permissions = { archive: false }
      data.signedDocument.downloadUrl = `${doctorEmployerPrefix(
        doctorId
      )}/signing_signature_requests/${signatureRequestId}/signed_pdf`
    }
    return data
  })
}

export type DoctorDmeOrderDetails = DoctorSignatureRequest & {
  downloadableDocuments?: DmeOrderDocument[]
  dmeOrder?: DmeOrder
  context?: Context
}

export const getDmeOrderDetails = async (
  doctorId: string,
  dmeOrderId: string
): Promise<DoctorDmeOrderDetails> => {
  const detailsUrl = `${doctorEmployerPrefix(
    doctorId
  )}/orders/${dmeOrderId}.json`

  return get(detailsUrl).then(({ data }) => {
    return data
  })
}

export const signReviewerAgreement = (
  doctorId: string
): Promise<{ reviewerAgreementIsSigned: boolean }> => {
  return post(`${doctorEmployerPrefix(doctorId)}/reviewer_agreement`).then(
    ({ data }) => data
  )
}

export const sendEmailNotificationsOptInEmail = async (
  userId: string,
  doctorId: string
) => {
  return post(emailNotificationsOptInUrl, {
    userId,
    doctorId,
  })
}

export const refreshDoctor = (doctorId: string) => {
  return get(refreshDoctorPath(doctorId)).then(({ data }) => data)
}

export const checkForActionableSignatureRequests = (
  doctorId: string
): Promise<{ review: boolean; sign: boolean }> => {
  return get(actionableSignatureRequestsCheckUrl(doctorId)).then(
    ({ data }) => data
  )
}
