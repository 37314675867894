import { NetworkCoverage, ServiceTier } from "sharedTypes"

export interface SuppliersRequest {
  [key: string]: string | number | string[] | boolean

  page: number
  query: string
  selectedServiceAreaStates: string[]
  selectedCategories: string[]
}

export enum SupplierGrouping {
  Organization = "organization",
  Other = "other",
}

export interface EFaxSupplier {
  name: string
  externalId: string
}

export interface SuppliersResponse {
  orgSupplierCount: number
  orgSuppliers: Supplier[]
  otherSupplierCount: number
  otherSuppliers: Supplier[]
  hasActiveSuppliers: boolean
}

export interface Supplier {
  externalId: string
  name: string
  connected: boolean
  serviceTier: ServiceTier
  networkCoverage: NetworkCoverage
  pinned: boolean
  recentlyOrderedFrom: boolean
  promoted: boolean
}

export interface EFaxSupplier {
  externalId: string
  name: string
}

export interface SearchFilters {
  query: string
  selectedServiceAreaStates: string[]
  selectedCategories: string[]
}

export interface SearchSettings {
  categories: string[]
  defaultServiceAreaStates: string[]
  filters: SearchFilters
  oneTimeOrderEnabled: boolean
  results: SuppliersResponse
}
