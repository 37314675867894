import React from "react"
import eventEmitter from "utilities/eventEmitter"
import { EventCategory, trackEvent } from "utilities/tracking"
import classNames from "classnames"

type Props = {
  className?: string
}

const CantFindClinician = ({ className }: Props) => {
  return (
    <p className={classNames(className)}>
      Can't find your clinician?{" "}
      <a
        className="link link-secondary"
        onClick={() => {
          eventEmitter.emit("chat-link:clicked")
          trackEvent(
            EventCategory.ReviewerSidebarSelfSignUp,
            "contact_us_find_clinician"
          )
        }}
      >
        Chat with us.
      </a>
    </p>
  )
}

export default CantFindClinician
