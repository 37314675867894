import React from "react"
import { DmeOrder, Employer, EmployerType } from "sharedTypes"
import { capitalize } from "utilities/string"
import { fullName } from "utilities/person"
import { formatHeight } from "utilities/conversion"
import DataRow from "components/DataRow"
import Tooltip from "components/Tooltip"
import classNames from "classnames"

type Props = {
  dmeOrder: DmeOrder
  currentEmployer: Employer
  allowUpdateClinicalFacility: boolean
  updateClinicalFacility: () => void
}

const PatientSectionContent = ({
  dmeOrder,
  currentEmployer,
  allowUpdateClinicalFacility,
  updateClinicalFacility,
}: Props) => {
  const { patient, patientSnapshot, useFeetForHeight } = dmeOrder
  const { heightInches, weightLbs } = patientSnapshot
  const showFacility =
    currentEmployer.employerType === EmployerType.Supplier ||
    currentEmployer.multiEmployersInOrganization

  const signUpLogo = () => {
    if (currentEmployer.employerType !== EmployerType.Supplier) {
      return
    }

    const hasSignedAgreements = dmeOrder.clinicalFacility.signedAgreements
    const tooltipText = hasSignedAgreements
      ? "This referral is on Parachute"
      : "This referral is not yet signed up for Parachute"

    return (
      <Tooltip
        triggerElementClassName="d-inline-block"
        trigger={["click", "hover"]}
        overlay={tooltipText}
        placement={Tooltip.Placement.Top}
      >
        <div
          className={classNames(
            "logo logo-sm v-align-text-bottom canopy-mx-2x",
            {
              "logo-translucent": !hasSignedAgreements,
            }
          )}
        >
          <div className="image"></div>
        </div>
      </Tooltip>
    )
  }

  const height = heightInches && formatHeight(useFeetForHeight, heightInches)
  const weight = weightLbs && `${weightLbs} lbs`
  const sex = capitalize(
    patient.sex === "unknown" ? patient.sex : patient.sex[0]
  )

  return (
    <>
      <DataRow label="Name" value={`${fullName(patient)} (${sex})`} />
      <DataRow label="Date of Birth" value={patient.dateOfBirth} />
      <DataRow
        label="Height / Weight"
        value={[height, weight].filter(Boolean).join(", ")}
      />
      {showFacility && (
        <>
          <DataRow label="Facility">
            {dmeOrder.clinicalFacility.name} {signUpLogo()}
            {allowUpdateClinicalFacility && updateClinicalFacility && (
              <div>
                <a href="#" className="link" onClick={updateClinicalFacility}>
                  <small>Update Facility</small>
                </a>
              </div>
            )}
          </DataRow>
        </>
      )}
      {dmeOrder.discharge && (
        <DataRow label="Discharge Date" value={dmeOrder.dischargeDate} />
      )}
    </>
  )
}

export default PatientSectionContent
