// @ts-strict-ignore
import React from "react"
import { Select } from "components/form"
import { getSelectableFacilityTypes } from "./utilities"
import { OtherOption } from "./sharedTypes"

const defaultValidate = (value: string): string | undefined => {
  if (value === "") {
    return "Select facility type"
  }
}

type validateFnType = (value: string) => string | undefined

interface Props {
  name: string
  label: string
  validate: false | validateFnType
}

const FacilityTypeSelect = ({ name, label, validate }: Props) => {
  const facilityTypeOptions = getSelectableFacilityTypes()
    .map(([value, label]) => ({
      value,
      label,
    }))
    .concat(OtherOption)

  const validateFn = (): validateFnType | undefined => {
    if (!validate) {
      return
    }
    if (typeof validate === "function") {
      return validate
    }
    return defaultValidate
  }

  return (
    <Select
      label={label}
      name={name}
      options={facilityTypeOptions}
      validate={validateFn()}
    />
  )
}

FacilityTypeSelect.defaultProps = {
  name: "facilityType",
  label: "Facility Type",
  validate: true,
}

export default FacilityTypeSelect
