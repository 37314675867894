import { TableContainer } from "@material-ui/core"
import React from "react"
import * as styles from "./index.module.scss"
import classNames from "classnames"

type Props = {
  children: React.ReactNode
  className?: string
}

/**
 * This is a thin wrapper around MUI's Table;
 * its sole reason for existence is to centralize styling for use within
 * the CMS. The intended use is to replace the <TableContainer>, but its children as specified normally.
 * @example
 *  <StyledTable>
 *    <Table>
 *     {...etc}
 *    </Table>
 *  </StyledTable>
 */
const StyledTable: React.FC<Props> = ({
  children,
  className,
}: Props): React.JSX.Element => {
  return (
    <TableContainer className={classNames(styles.tableContainer, className)}>
      {children}
    </TableContainer>
  )
}

export default StyledTable
