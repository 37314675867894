import React from "react"
import {
  Form,
  TextField,
  EmailField,
  PhoneField,
  TimezoneSelect,
} from "components/form"
import { User, ClinicalOrganizationsEmployment } from "sharedTypes"
import UserRoleRadioTable from "./UserRoleRadioTable"
import Button from "components/form/Button"

interface Props {
  defaultTimeZone: string
  user: User
  emailDisabled?: boolean
  employments: ClinicalOrganizationsEmployment[]
  onSubmit(params): Promise<void>
}

function UserForm({
  defaultTimeZone,
  user,
  emailDisabled,
  employments,
  onSubmit,
}: Props) {
  const initialValues = {
    user: {
      firstName: user.firstName || "",
      lastName: user.lastName || "",
      email: user.email || "",
      mobileNumber: user.mobileNumber || "",
      phoneNumber: user.phoneNumber || "",
      phoneExtension: user.phoneExtension || "",
      timeZone: user.timeZone || "",
    },
    employments: employments.map((employment) => ({
      id: employment.id,
      employerType: employment.employerType,
      employerId: employment.employerId,
      role: employment.role || "no_access",
    })),
  }

  const handleSubmit = (params, { setErrors }) => {
    return onSubmit(params).catch(({ errors }) => {
      setErrors(errors)
    })
  }

  return (
    <Form initialValues={initialValues} onSubmit={handleSubmit}>
      <TextField label="First name" name="user.firstName" />
      <TextField label="Last name" name="user.lastName" />
      <EmailField label="Email" name="user.email" disabled={emailDisabled} />
      <PhoneField label="Mobile number" name="user.mobileNumber" />
      <div className="form-group">
        <div className="row">
          <div className="col-md-6">
            <PhoneField label="Work number" name="user.phoneNumber" unwrapped />
          </div>
          <div className="col-md-6">
            <TextField
              label="Work extension"
              name="user.phoneExtension"
              unwrapped
            />
          </div>
        </div>
      </div>
      <TimezoneSelect name="user.timeZone" defaultTimeZone={defaultTimeZone} />
      <UserRoleRadioTable employments={employments} />
      <Button type="submit" className="btn-primary">
        Save
      </Button>
    </Form>
  )
}

export default UserForm
