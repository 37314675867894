import { Card, CardContent, CardHeader, Typography } from "@material-ui/core"
import Icon from "../../components/Icon"
import { FluidLabelAndValue } from "./FluidLabelAndValue"
import React from "react"
import { EventCategory, trackEvent } from "utilities/tracking"

export const RESUPPLY_EVENT_CATEGORY = EventCategory.Resupply
export const DOCTOR_DASHBOARD_DOWNLOAD_EVENT =
  "doctor_dash_download_documentation"

const trackDownloadEvent = () => {
  trackEvent(RESUPPLY_EVENT_CATEGORY, DOCTOR_DASHBOARD_DOWNLOAD_EVENT)
}

export const DocumentationCard = ({
  documentDisplayName,
  downloadPath,
  supplierSystemOrderId,
}: {
  documentDisplayName: string
  downloadPath: string
  supplierSystemOrderId: string
}) => (
  <Card>
    <CardHeader title="Documentation" />
    <CardContent>
      <a className="link" href={downloadPath} onClick={trackDownloadEvent}>
        <Typography>
          <Icon type="download" className="canopy-mie-4x" />
          Download {documentDisplayName}
        </Typography>
      </a>
      <br />
      <FluidLabelAndValue
        label="External Order ID"
        value={supplierSystemOrderId}
      />
    </CardContent>
  </Card>
)
