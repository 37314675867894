import React from "react"
import withInitialData from "components/withInitialData"
import { copyToClipboard } from "utilities/clipboard"
import { createInvitation } from "./api"
import { handleError } from "utilities/error"
import { DmeOrderInvitation } from "../../sharedTypes"

type Props = {
  initialData: DmeOrderInvitation
}

const ModalBody = ({ initialData }: Props) => {
  const invitation = initialData
  return (
    <>
      <p className="canopy-mbe-4x color-dark-gray">Order Link:</p>
      <div className="well well-borderless bg-lighter-gray">
        <div className="row">
          <div className="col-md-8">{invitation.url}</div>
          <div className="col-md-4 text-right">
            <a className="link" onClick={() => copyToClipboard(invitation.url)}>
              Copy Link
            </a>
          </div>
        </div>
      </div>
      <p className="canopy-mbe-4x color-dark-gray">Security Code:</p>
      <p>{invitation.securityCode}</p>
    </>
  )
}

const generateInvitation = () =>
  createInvitation()
    .then(({ data }) => data)
    .catch(handleError)

export default withInitialData(generateInvitation)(ModalBody)
