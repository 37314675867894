import { post } from "services/api"
import { ZipCodeOverlap } from "../ZipCodeConflictModal"

export const removeOverlappingZips = (
  path: string,
  supplierId: string,
  legalEntityId: string,
  zips: ZipCodeOverlap[]
) => {
  const overlappingPath = path + "/remove_overlapping_zips"

  return post(overlappingPath, {
    overlappingZips: zips,
  })
    .then(({ data }) => data)
    .catch(({ response }) => Promise.reject(response.data))
}
