import React, { useEffect } from "react"
import { get } from "services/api"

type Props = {
  url: string
  frequency: number
}
const Pinger = ({ url, frequency }: Props) => {
  useEffect(() => {
    const interval = setInterval(() => get(url), frequency)
    return () => clearInterval(interval)
  }, [url, frequency])

  return <></>
}

export default Pinger
