import uniqueWith from "lodash.uniqwith"
import isEqual from "lodash.isequal"

export function wrap(value) {
  if (Array.isArray(value)) {
    return value
  }
  return [value]
}

export function toSentence(array, word = "and") {
  if (!array) {
    return ""
  }

  if (array.length <= 2) {
    return array.join(` ${word} `)
  }

  return `${array.slice(0, -1).join(", ")}, ${word} ${array[array.length - 1]}`
}

export function toList(array, limit) {
  if (!array) {
    return ""
  }

  if (array.length <= limit) {
    return array.join(", ")
  }

  return `${array.slice(0, limit - 1).join(", ")}, +${
    array.length - limit + 1
  } more`
}

export function arrayEquals(a, b) {
  if (a === b) {
    return true
  }
  if (a === null || b === null) {
    return false
  }
  if (a.length !== b.length) {
    return false
  }

  for (let i = 0; i < a.length; ++i) {
    if (a[i] !== b[i]) {
      return false
    }
  }
  return true
}

export function unique(array) {
  return uniqueWith(array, isEqual)
}

export function toArray(value) {
  if (Array.isArray(value)) {
    return value
  }
  return []
}
