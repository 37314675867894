import React from "react"
import classNames from "classnames"

interface Props {
  icon?: React.ReactNode
  text: string
  className?: string
  ariaLabel?: string
}

const OptionPill = ({ className, icon, text, ariaLabel }: Props) => (
  <span
    className={classNames(
      "pill",
      className ? className : "pill-default pill-basic"
    )}
    aria-label={ariaLabel}
  >
    {icon && <>{icon}</>}
    {text}
  </span>
)

export default OptionPill
