import React from "react"
import FacilityCommentForm from "./FacilityCommentForm"
import Warnings from "./Warnings"
import {
  CommentType,
  DmeOrder,
  DmeOrderWarning,
  EmploymentMention,
} from "sharedTypes"
import BasicSupplierWarning from "applications/Workflow/components/BasicSupplierWarning"
import ContactInfo from "./ContactInfo"

type Props = {
  createComment: (values: { content: string }) => Promise<void>
  warnings: DmeOrderWarning[]
  warningMessages: string[]
  dmeOrder: DmeOrder
  getEmploymentMentions(
    term: string,
    commentType: CommentType
  ): Promise<EmploymentMention[]>
}

const ClinicalFacilityCommentForm = ({
  warnings,
  createComment,
  warningMessages,
  dmeOrder,
  getEmploymentMentions,
}: Props) => {
  const basicSupplierWarning = warnings.find(
    (warning) => warning.key === "basic_supplier"
  )
  return (
    <div>
      <ContactInfo dmeOrder={dmeOrder} />
      <Warnings warnings={warningMessages} />
      {!!basicSupplierWarning && (
        <BasicSupplierWarning warning={basicSupplierWarning} />
      )}
      <FacilityCommentForm
        dmeOrder={dmeOrder}
        onSubmit={createComment}
        getEmploymentMentions={getEmploymentMentions}
      />
    </div>
  )
}

export default ClinicalFacilityCommentForm
