import React from "react"
import Icon from "components/Icon"

function ResetButton({ onClick }) {
  return (
    <a
      className="link link-understated font-xs canopy-mis-8x"
      onClick={onClick}
    >
      <Icon className="canopy-mie-2x" type="redo-alt" flip="horizontal" />
      Reset View
    </a>
  )
}

export default ResetButton
