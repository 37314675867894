// @ts-strict-ignore
import React, { useState } from "react"
import Modal from "components/Modal"
import { Button, Form, TextField } from "components/form"
import { EventCategory, trackEvent } from "utilities/tracking"
import Alert from "components/Alert"
import Link from "components/Link"
import { userPreferencesUrl } from "./urls"
import * as styles from "./AddReviewerModal.module.scss"
import classNames from "classnames"
import { FormikErrors } from "formik"
import { isEmailValid } from "utilities/email"
import Bulletin from "components/Bulletin"
import { isEmpty } from "utilities/object"
import { ReviewerDetails } from "../../index"

type Props = {
  onClose(): void
  show?: boolean
  onSubmit(params: { email: string }): Promise<void>
  reviewers: ReviewerDetails[]
  doctorId: string
}

const RESUPPLY_EVENT_CATEGORY = EventCategory.Resupply
const DOCTOR_SAVE_REVIEWER_ATTEMPT_EVENT_NAME = "doctor_dash_save_email_attempt"
const DOCTOR_SAVE_REVIEWER_SUCCESS_EVENT_NAME = "doctor_dash_save_email_success"

interface FormValues {
  email?: string
}

const formErrors = {
  emailMissing: "Email is missing.",
  emailInvalid: "Invalid email format.",
  userNotFound: "User not found.",
  userAlreadyExists: "User has already been taken",
}

const AddReviewerModal = ({
  show,
  onClose,
  onSubmit,
  reviewers,
  doctorId,
}: Props) => {
  const [inviteeEmail, setInviteeEmail] = useState<string>("")

  const validate = ({ email }) => {
    const errors: FormikErrors<FormValues> = {}

    if (!email) {
      errors.email = formErrors.emailMissing
    } else if (!isEmailValid(email)) {
      errors.email = formErrors.emailInvalid
    }
    return errors
  }
  const handleSubmit = (
    { email },
    { setErrors, setSubmitting, setFieldValue, setStatus }
  ) => {
    trackEvent(RESUPPLY_EVENT_CATEGORY, DOCTOR_SAVE_REVIEWER_ATTEMPT_EVENT_NAME)
    onSubmit({ email })
      .then(() => {
        trackEvent(
          RESUPPLY_EVENT_CATEGORY,
          DOCTOR_SAVE_REVIEWER_SUCCESS_EVENT_NAME
        )
        setInviteeEmail(email)
        setFieldValue("email", "", false)
        setErrors({})
        setStatus("success")
        setSubmitting(false)
      })
      .catch((errors) => {
        setErrors(errors)
        setStatus(null)
        setSubmitting(false)
        setInviteeEmail("")
      })
  }

  const renderError = (error) => {
    if (!error) {
      return null
    }
    if (error.includes(formErrors.userAlreadyExists)) {
      return (
        <Bulletin status="warning" className="canopy-mbs-4x">
          <div className="fas fa-exclamation-triangle" /> User already exists.
        </Bulletin>
      )
    } else {
      return (
        <Bulletin status="danger" className="canopy-mbs-4x">
          <div className="fas fa-exclamation-triangle" /> {error}
        </Bulletin>
      )
    }
  }

  return (
    <Modal size="md" title="Reviewers" show={show} cancel={onClose}>
      <Modal.Body>
        <Form
          onSubmit={handleSubmit}
          validate={validate}
          initialValues={{ email: "" }}
          validateOnBlur={false}
        >
          {({
            isSubmitting,
            status,
            errors,
            setStatus,
          }: {
            isSubmitting: boolean
            status: string
            errors: FormikErrors<FormValues>
            setStatus: (status) => void
          }) => (
            <>
              <Alert status="info">
                Assigned reviewers can review signature requests to verify order
                details and can reject orders if anything needs to be adjusted
                prior to signing.
              </Alert>
              <div className="row canopy-mbs-12x">
                <div className="col-md-12">
                  <strong className="font-xs">My Reviewers:</strong>
                </div>
              </div>
              <div className="row">
                <div className="col-md-10">
                  <span className="font-xs">
                    {reviewers.length !== 0
                      ? reviewers.map((reviewer) => reviewer.name).join(", ")
                      : "None"}
                  </span>
                </div>
                <div
                  className={classNames("col-md-2", styles.editLinkContainer)}
                >
                  <Link
                    className="link font-xs"
                    href={userPreferencesUrl(doctorId)}
                  >
                    Edit
                  </Link>
                </div>
              </div>
              <div className="row canopy-mbs-12x">
                <div
                  className={classNames(
                    "col-md-10",
                    styles.emailFieldContainer
                  )}
                >
                  <TextField
                    name="email"
                    label="Add Reviewer"
                    placeholder="******@******.com"
                    autoFocus
                    renderCustomError={renderError}
                    onChange={() => setStatus(null)}
                  />
                  {isEmpty(errors) && status === "success" && (
                    <Bulletin status="success">
                      <div className="fas fa-check mt-n2" /> Reviewer invitation
                      sent to {inviteeEmail}.
                    </Bulletin>
                  )}
                </div>
                <div
                  className={classNames("col-md-2", styles.addButtonContainer)}
                >
                  <Button
                    type="submit"
                    className={classNames(
                      "btn-block btn-brand",
                      styles.addButton
                    )}
                    disabled={isSubmitting}
                  >
                    Add
                  </Button>
                </div>
              </div>
            </>
          )}
        </Form>
      </Modal.Body>
    </Modal>
  )
}

export default AddReviewerModal
