import { gql, TypedDocumentNode } from "@apollo/client"
import {
  IncomingOrdersUserUpdateMutation,
  IncomingOrdersUserUpdateMutationVariables,
} from "graphql/__generated__/graphql"

export const IncomingOrdersUserUpdate: TypedDocumentNode<
  IncomingOrdersUserUpdateMutation,
  IncomingOrdersUserUpdateMutationVariables
> = gql`
  mutation IncomingOrdersUserUpdate(
    $clinicalFacilityIds: [ID]
    $productSubcategoryIds: [ID]
    $doctorIds: [ID]
    $supplierIds: [ID]
    $followerIds: [ID]
    $deliveryDateStartAt: String
    $deliveryDateEndAt: String
  ) {
    incomingOrdersUserUpdate(
      input: {
        clinicalFacilityIds: $clinicalFacilityIds
        productSubcategoryIds: $productSubcategoryIds
        doctorIds: $doctorIds
        supplierIds: $supplierIds
        followerIds: $followerIds
        deliveryDateStartAt: $deliveryDateStartAt
        deliveryDateEndAt: $deliveryDateEndAt
      }
    ) {
      action
    }
  }
`
