import { get } from "services/api"
import { employerPrefix } from "utilities/url"
import { SupplierPermissions } from "./components/SupplierSettingsList"

export const fetchSupplierPermissions = (
  supplierId: string
): Promise<SupplierPermissions> => {
  const url = `${employerPrefix(
    "Supplier",
    supplierId
  )}/setting_permissions.json`

  return get(url)
    .then(({ data }) => data)
    .catch(({ response }) => Promise.reject(response.data))
}
