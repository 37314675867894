import React from "react"
import {
  CanopyDialogModal,
  CanopyDialogModalProps,
} from "@parachutehealth/canopy-dialog-modal"
import { CanopyButton } from "@parachutehealth/canopy-button"

export type ConfirmDialogProps = {
  title: string
  message: React.ReactNode
  confirmButtonText?: string
  confirmButtonVariant?: "primary" | "danger" | "warning"
  headerOverline?: CanopyDialogModalProps["headerOverline"]
  cancelButtonText?: string
  open: boolean
  onConfirm?: () => void
  onCancel?: () => void
  handleClose?: () => void
}

const ConfirmDialog: React.FC<ConfirmDialogProps> = (
  props: ConfirmDialogProps
) => {
  const {
    title,
    message,
    confirmButtonText = "Yes, I am sure",
    confirmButtonVariant = "primary",
    cancelButtonText = "Cancel",
    open,
    onConfirm,
    onCancel,
    handleClose,
    headerOverline,
  } = props

  const handleDialogClose = () => {
    if (onCancel) onCancel()
    if (handleClose) handleClose()
  }

  const handleCancelButtonClick = () => {
    if (onCancel) onCancel()
    if (handleClose) handleClose()
  }

  const handleConfirmButtonClick = () => {
    if (onConfirm) onConfirm()
    if (handleClose) handleClose()
  }

  const PrimaryFooterButton = (): JSX.Element => (
    <CanopyButton
      onClick={handleConfirmButtonClick}
      variant={confirmButtonVariant}
    >
      {confirmButtonText}
    </CanopyButton>
  )
  const SecondaryFooterButton = (): JSX.Element => (
    <CanopyButton onClick={handleCancelButtonClick} variant="secondary">
      {cancelButtonText}
    </CanopyButton>
  )

  return (
    <CanopyDialogModal
      aria-label={title}
      size="medium"
      header={title}
      open={open}
      headerOverline={headerOverline}
      onClose={handleDialogClose}
      primaryFooterButton={<PrimaryFooterButton />}
      secondaryFooterButton={<SecondaryFooterButton />}
    >
      {message}
    </CanopyDialogModal>
  )
}

export default ConfirmDialog
