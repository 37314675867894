import { ServerSideDataGridParams } from "../../../../hooks/useServerSideDataGrid"
import { get } from "../../../../../../services/api"

export type SupplierPackageConfigurationsDataGridRecord = {
  id: number
  externalId: string
  name?: string
  default: boolean
  fullyConfigured: boolean // changing soon
  consignmentClosetCount: number
  packageName: string
  packageExternalId: string
}

export type SupplierPackageConfigurationsDataGridResponse = {
  records: SupplierPackageConfigurationsDataGridRecord[]
  totalCount: number
}

export const getPackageConfigurationsForSupplier = async (
  supplierId: string,
  params?: ServerSideDataGridParams
): Promise<SupplierPackageConfigurationsDataGridResponse> => {
  return await get(
    `/cms/catalog/suppliers/${supplierId}/packages.json`,
    params
  ).then((response) => response.data)
}
