import React from "react"
import letter from "letter-success.png"

function NewInvitation() {
  return (
    <div className="text-center">
      <div className="canopy-my-24x">
        <img src={letter} width="220" />
      </div>
      <div className="text-xs-left">
        <h1>New invitation link sent</h1>
        <p>A new invitation link has been sent to your email address.</p>
      </div>
    </div>
  )
}

export default NewInvitation
