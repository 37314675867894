import React from "react"
import { BasicSupplierHelpUrl } from "applications/Workflow/urls/external"
import { DmeOrderWarning, BasicSupplierWarningMetadata } from "sharedTypes"
import Alert from "components/Alert"
import { formatPhone } from "utilities/phone"

interface Props {
  warning: DmeOrderWarning
}

const BasicSupplierWarning = ({ warning }: Props) => {
  const { severity, metadata } = warning

  const nameMessage = (names: string[]): string => {
    return `${names.join(", ")} ${
      names.length > 1 ? "do" : "does"
    } not have messaging and status updates enabled on Parachute.`
  }

  const contactMessage = (phoneNumber: string, faxNumber: string): string => {
    if (phoneNumber) {
      return `You can contact this supplier by phone (${formatPhone(
        phoneNumber
      )}) or by fax (${formatPhone(faxNumber)}).`
    }
    return `You can contact this supplier by fax at (${formatPhone(
      faxNumber
    )}).`
  }

  const message = (): string => {
    const {
      supplierLegalEntity,
      supplierNames,
    } = metadata as BasicSupplierWarningMetadata
    if (supplierLegalEntity) {
      return `${nameMessage([supplierLegalEntity.name])} ${contactMessage(
        supplierLegalEntity.phoneNumber,
        supplierLegalEntity.faxNumber
      )}`
    }
    return `${nameMessage(supplierNames)}`
  }

  return (
    <Alert status={severity}>
      {message()}&nbsp;
      <a target="_blank" href={BasicSupplierHelpUrl} rel="noreferrer">
        Learn More
      </a>
    </Alert>
  )
}

export default BasicSupplierWarning
