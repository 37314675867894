import React, { useState } from "react"
import Popover from "components/Popover"
import Icon from "components/Icon"
import AssigneeForm from "./Form"
import { handleError } from "utilities/error"
import { Assignee } from "sharedTypes"

type Props = {
  assignee: Assignee
  setAssignee({ assigneeId }): Promise<Assignee>
  fetchAssignees?: (term: string) => Promise<Assignee[]>
}

const AssigneeEditor = ({ assignee, setAssignee, fetchAssignees }: Props) => {
  const [currentAssignee, setCurrentAssignee] = useState(assignee)
  const [isSubmitting, setSubmitting] = useState(false)
  const [open, setOpen] = useState(false)

  const toggle = () => setOpen((open) => !open)
  const hide = () => setOpen(false)
  const close = () => {
    hide()
    setSubmitting(false)
  }

  const onAssign = ({ assigneeId }) => {
    setSubmitting(true)
    setAssignee({ assigneeId })
      .then((assignee) => {
        setCurrentAssignee(assignee)
        close()
      })
      .catch((response) => {
        handleError(response)
        close()
      })
  }

  return (
    <Popover
      open={open}
      onHide={hide}
      onClick={toggle}
      allowOverflow={true}
      label={
        <a title="Show popover" className="link link-teal">
          <span className="canopy-mie-2x">
            {currentAssignee?.name || "Unassigned"}
          </span>
          <Icon type="chevron-down" className="position-absolute" />
        </a>
      }
    >
      <AssigneeForm
        assignee={currentAssignee}
        setAssignee={onAssign}
        isSubmitting={isSubmitting}
        fetchAssignees={fetchAssignees}
      />
    </Popover>
  )
}

export default AssigneeEditor
