import React, { useState } from "react"
import Icon from "components/Icon"
import { useEffectThatWontRunOnMount } from "hooks/useEffectThatWontRunOnMount"

export type RenderPageProps = {
  goBack: () => void
  goForward: () => void
}
export type PageDefinition = {
  render: (props: RenderPageProps) => React.ReactElement
}

const doNothingOnPageChange = () => {}
export const PagedContent: (props: {
  backText: string
  onPageChange?: (currentPage: number) => void
  pages: PageDefinition[]
}) => React.ReactElement = (props) => {
  const [page, setPage] = useState(0)
  const goBack = () => setPage((prevState) => Math.max(prevState - 1, 0))
  const goForward = () =>
    setPage((prevState) => Math.min(prevState + 1, props.pages.length - 1))
  const onPageChange = props.onPageChange
    ? props.onPageChange
    : doNothingOnPageChange
  useEffectThatWontRunOnMount(() => onPageChange(page), [page, onPageChange])
  return (
    <>
      {page > 0 && (
        <p>
          <button className="link btn-link" onClick={goBack}>
            <Icon type="chevron-left" className="canopy-mie-4x" />
            {props.backText}
          </button>
        </p>
      )}
      {props.pages[page].render({ goBack, goForward })}
    </>
  )
}
