// @ts-strict-ignore
import React, { useState } from "react"
import { DmeOrderInvitation } from "../../sharedTypes"
import { navigate } from "utilities/navigation"
import { handleError } from "utilities/error"
import InvitationIdentificationForm from "./InvitationIdentificationForm"
import InvitationEmailForm from "./InvitationEmailForm"
import WelcomePage from "./WelcomePage"
import { dmeOrderLandingUrl } from "../../urls"

type Props = {
  accessCode: string
  securityCode: string
  invitation: DmeOrderInvitation
  login(props): Promise<void>
}

enum Step {
  IdentificationForm,
  EnterEmail,
  Welcome,
}

const InvitationLogin = (props: Props) => {
  const { accessCode, invitation, login, securityCode } = props
  const [step, setStep] = useState(Step.IdentificationForm)
  const [firstName, setFirstName] = useState<string>()
  const [lastName, setLastName] = useState<string>()

  const navigateToSoftLanding = () =>
    navigate(
      dmeOrderLandingUrl(invitation.clinicalFacilityId, invitation.dmeOrderId)
    )

  const handleInvitationLogin = ({
    firstName,
    lastName,
    email,
  }: {
    firstName?: string
    lastName?: string
    email?: string
  }) => {
    return login({ accessCode, securityCode, firstName, lastName, email })
      .then(() => {
        setFirstName(firstName)
        if (invitation.instructions) {
          setStep(Step.Welcome)
        } else {
          navigateToSoftLanding()
        }
      })
      .catch(handleError)
  }

  const submitInvitationIdentificationForm = ({
    firstName,
    lastName,
  }: {
    firstName?: string
    lastName?: string
  }) => {
    if (invitation.promptEmail) {
      setFirstName(firstName)
      setLastName(lastName)
      setStep(Step.EnterEmail)
      return Promise.resolve()
    }
    return handleInvitationLogin({ firstName, lastName })
  }

  const submitInvitationEmailForm = ({ email }: { email?: string }) => {
    return handleInvitationLogin({ firstName, lastName, email })
  }
  const skipEmail = () => {
    return handleInvitationLogin({ firstName, lastName })
  }

  switch (step) {
    case Step.IdentificationForm:
      return (
        <InvitationIdentificationForm
          invitation={invitation}
          onSubmit={submitInvitationIdentificationForm}
        />
      )
    case Step.EnterEmail:
      return (
        <InvitationEmailForm
          invitation={invitation}
          onSubmit={submitInvitationEmailForm}
          skipEmail={skipEmail}
        />
      )
    case Step.Welcome:
      return (
        <WelcomePage
          invitation={invitation}
          name={firstName}
          onClickContinue={navigateToSoftLanding}
        />
      )
  }
}

export default InvitationLogin
