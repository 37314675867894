import React, { useState } from "react"
import Icon from "components/Icon"
import RemoveButton from "./RemoveButton"
import RemoveModal from "./RemoveModal"
import ShareViewLink from "./ShareViewLink"
import * as styles from "./header.module.scss"

export const Header = ({
  view,
  closeSidebar,
  isDefaultView,
  removeView,
}: {
  view: {
    name: string
    externalId: string
  }
  closeSidebar: () => void
  isDefaultView: boolean
  removeView: () => void
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const removeViewAndCloseModal = () => {
    removeView()
    setIsModalOpen(false)
  }
  return (
    <div className={styles.container}>
      <div className={styles.titleRow}>
        <div className={styles.viewName}>
          <h3 className={styles.title}>{view.name}</h3>
        </div>
        <div className={styles.closeButton}>
          <Icon type="times" clickable onClick={closeSidebar} />
        </div>
      </div>

      <div className={styles.buttonRow}>
        <ShareViewLink view={view} />
        {!isDefaultView && (
          <>
            <RemoveButton onClick={() => setIsModalOpen(true)} />
            <RemoveModal
              show={isModalOpen}
              cancel={() => setIsModalOpen(false)}
              submit={removeViewAndCloseModal}
              viewName={view.name}
            />
          </>
        )}
      </div>
    </div>
  )
}
