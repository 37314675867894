import $ from "jquery"
import debounce from "debounce"
import { addSelectHandlers } from "./select"

const POPOVER_SELECTOR = "[rel=popover]"

const destroyPopovers = () => {
  $(POPOVER_SELECTOR).popover("dispose")
}

$(document).on("click", POPOVER_SELECTOR, function () {
  const contentUrl = $(this).attr("contentUrl")

  if ($(this).data("bs.popover")) {
    $(this).popover("dispose")
    return false
  }
  destroyPopovers()

  const that = this
  $.ajax({
    url: contentUrl,
    success(response) {
      $(that).popover({
        content: response,
        sanitizeFn: (content) => content,
      } as any)
      $(that).popover("show")
      addSelectHandlers($(".popover-content"))
    },
  })
  return false
})

$(document).on("click", function (event) {
  const outsidePopover = !$(event.target).closest(".popover").length
  const currentElement = (event.target as unknown) as HTMLElement

  if (outsidePopover) {
    destroyPopovers()
    // close non-ajax popovers
    $('[data-toggle="popover"]').not(currentElement).popover("hide")
  }
})

function onWindowResize() {
  const link = $(POPOVER_SELECTOR + "+.in").prev()
  if (link.length > 0) {
    link.popover("hide")
    setTimeout(() => link.popover("show"), 300)
  }
}

$(window).on("resize", debounce(onWindowResize, 300))
