import React, { useCallback } from "react"
import { CheckCircle } from "@material-ui/icons"
import * as styles from "./no-signature-requests.module.scss"
import { SignPath } from "../../routes"
import { useHistory } from "react-router-dom"
import { Button } from "components/form"
import { EventCategory, trackEvent } from "utilities/tracking"
import { handleError } from "utilities/error"
import { useDoctorContext } from "../../hooks/useDoctorContext"

interface Props {
  message: string
  showSignTabCta?: boolean
  signOnScreenEnabled?: boolean
}

export const CLICK_REVIEW_SIGN_REQUESTS_CTA = "review_sign_requests_cta"

export const NoSignatureRequests = (props: Props) => {
  const { doctor } = useDoctorContext()
  const { message, showSignTabCta, signOnScreenEnabled } = props

  const history = useHistory()
  const handleOnClick = useCallback(() => {
    trackEvent(EventCategory.Resupply, CLICK_REVIEW_SIGN_REQUESTS_CTA)
      .then()
      .catch(handleError)
    history.push(SignPath(doctor.id))
  }, [history, doctor.id])

  const buttonMessage = signOnScreenEnabled
    ? "Sign Requests"
    : "View Ready to Sign"

  return (
    <div className={styles.container}>
      <CheckCircle className={styles.icon} />
      <span className={styles.message}>{`${message}`}</span>
      {showSignTabCta && (
        <Button className="btn btn-brand" onClick={handleOnClick}>
          {buttonMessage}
        </Button>
      )}
    </div>
  )
}
