import React from "react"

export interface GroupedSuggestion {
  name: string
  display: string
  employerName: string
  groupName: string
  email: string
  firstInGroup: boolean
}

function Suggestion({
  email,
  employerName,
  firstInGroup,
  groupName,
  name,
}: GroupedSuggestion) {
  return (
    <>
      {firstInGroup && <div className="text-editor-group">{groupName}</div>}
      <div className="text-editor-suggestion">
        <div className="row">
          <div className="bold col-7">{name}</div>
          <div className="text-right color-dark-gray col-5">{employerName}</div>
        </div>
        <div>{email}</div>
      </div>
    </>
  )
}

export default Suggestion
