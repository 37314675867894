import * as React from "react"
import { Form, TextArea, TextField } from "components/form"
import { CanopyButton } from "@parachutehealth/canopy-button"
import {
  CanopyComboboxField,
  OptionItem,
  SelectOption,
  SelectOptions,
} from "@parachutehealth/canopy-combobox-field"
import { Field as FormikField } from "formik"
import { useCallback, useEffect, useMemo, useState } from "react"
import { getSalesReps } from "../api"
import { debounce } from "lodash"

type Props = {
  onSubmit(values: any, formikBag: any): Promise<void>
  initialValues: {
    territoryName: string
    territories: string
    selectedSalesRep: string
    initialSalesRep?: SelectOption
  }
  salesRepSearchPath: string
}

const ManageTerritoriesForm: React.FC<Props> = ({
  onSubmit,
  initialValues,
  salesRepSearchPath,
}: Props) => {
  const validate = ({ territoryName, territories }) => {
    const errors: {
      territoryName?: string
      territories?: string
    } = {}

    if (!territoryName) {
      errors.territoryName = "Territory name is required"
    }

    if (!territories) {
      errors.territories = "Territory Zip Codes is required"
    }

    return errors
  }

  const [controlledOptions, setControlledOptions] = useState<SelectOptions>([])
  const [optionsLoading, setOptionsLoading] = useState(false)

  useEffect(() => {
    if (initialValues.initialSalesRep) {
      setControlledOptions([initialValues.initialSalesRep])
    }
  }, [initialValues.initialSalesRep])

  const debouncedFetch = useMemo(
    () =>
      debounce(async (newValue) => {
        const searchResults: {
          name: string
          email: string
          externalId: string
        }[] = await getSalesReps(newValue, salesRepSearchPath)
        setControlledOptions(
          searchResults.map((rep) => {
            return {
              label: `${rep.name}, (${rep.email})`,
              value: rep.externalId,
            }
          })
        )
        setOptionsLoading(false)
      }, 500),
    [salesRepSearchPath]
  )

  const searchSalesReps = useCallback(
    async (newValue: string) => {
      setOptionsLoading(true)
      if (newValue.length > 1) {
        debouncedFetch(newValue)
      } else {
        setControlledOptions([])
        setOptionsLoading(false)
      }
    },
    [debouncedFetch]
  )
  return (
    <Form
      onSubmit={(
        fields: {
          territories: string
          territoryName: string
          selectedSalesRep: OptionItem
        },
        formikBag
      ) => {
        const fieldsWithSalesRepId = {
          territories: fields.territories,
          territoryName: fields.territoryName,
          selectedSalesRep: fields.selectedSalesRep?.value,
        }
        return onSubmit(fieldsWithSalesRepId, formikBag)
      }}
      enableReinitialize
      initialValues={initialValues}
      validate={validate}
    >
      {({ isSubmitting, values, setFieldTouched, setFieldValue }) => {
        return (
          <>
            <TextField name="territoryName" label="Territory Name" />
            <FormikField
              as={CanopyComboboxField}
              className="form-group"
              label="Select a sales rep"
              id="selectedSalesRep"
              loading={optionsLoading}
              options={controlledOptions}
              onInputChange={searchSalesReps}
              onBlur={() => {
                void (async () => {
                  await setFieldTouched("selectedSalesRep")
                })()
              }}
              onChange={(newValue) => {
                void (async (newValue) => {
                  await setFieldValue("selectedSalesRep", newValue)
                })(newValue)
              }}
              noOptionsAvailableMessage="Search by name or email"
              noOptionsFoundMessage="No users found"
              value={values["selectedSalesRep"]}
            />
            <TextArea
              label="Territory Zip Codes"
              name="territories"
              rows={5}
              placeholder="Enter or copy and paste the service area zip codes"
            />
            <div className="form-group textarea-container has-autosize">
              {"Territory data defines which sales rep is associated to a facility. " +
                "When adding a zip code, all facilities and open orders in that zip code will update to this sales rep. " +
                "When removing a zip code, all facilities an open orders in that zip code will no longer be associated to the sales rep."}
            </div>
            <CanopyButton
              variant="primary"
              loading={isSubmitting}
              loadingText="Update Assignments"
              className="btn btn-brand"
              type="submit"
              disabled={!values.territoryName || !values.territories}
            >
              Submit
            </CanopyButton>
          </>
        )
      }}
    </Form>
  )
}
export default ManageTerritoriesForm
