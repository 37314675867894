interface CustomGlobal {
  $?
  jQuery?
  [key: string]: any
}

const globalAny: CustomGlobal = global

import $ from "jquery"
globalAny.$ = $
globalAny.jQuery = $
import "jquery-ujs"
import "vendor/jquery.iframe-transport"
import "vendor/jquery.remotipart"
import "bootstrap"
