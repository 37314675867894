import { theta } from "./math"

const transformations = {
  0: {
    translate: [0, 0],
    origin: [50, 50],
  },
  90: {
    translate: [0, -100],
    origin: [0, 100],
  },
  180: {
    translate: [0, 0],
    origin: [50, 50],
  },
  270: {
    translate: [-100, 0],
    origin: [100, 0],
  },
}

function getHeight(height, width, rotation) {
  return (
    width * Math.abs(Math.sin(theta(rotation))) +
    height * Math.abs(Math.cos(theta(rotation)))
  )
}

export function getScale(height, width, rotation) {
  if (!width || !height) {
    return 1
  }
  const ratio = width / height
  const scale = Math.abs(
    Math.cos(theta(rotation)) + ratio * Math.sin(theta(rotation))
  )
  return scale
}

function getDeltaHeight(height, width, rotation) {
  const scale = getScale(height, width, rotation)
  const delta = scale * getHeight(height, width, rotation) - height
  return delta || 0
}

function getTransformValues(type, axis, rotation) {
  const map = { x: 0, y: 1 }
  return transformations[rotation][type][map[axis]]
}

function getTranslate(rotation) {
  const x = getTransformValues("translate", "x", rotation)
  const y = getTransformValues("translate", "y", rotation)
  return "translate(" + x + "%, " + y + "%)"
}

function getTransformOrigin(rotation) {
  const x = getTransformValues("origin", "x", rotation)
  const y = getTransformValues("origin", "y", rotation)
  return x + "% " + y + "% 0"
}

function getTransform(rotation, height, width) {
  return (
    getTranslate(rotation) +
    " rotate(" +
    rotation +
    "deg) scale(" +
    getScale(height, width, rotation) +
    ")"
  )
}

export default function transform(zoom, rotation, height, width) {
  return {
    width: zoom + "%",
    marginBottom: getDeltaHeight(height, width, rotation),
    transformOrigin: getTransformOrigin(rotation),
    transform: getTransform(rotation, height, width),
  }
}
