import React, { useCallback, useState } from "react"
import Actions from "./components/Actions"
import Swiper from "./components/Swiper"
import Preview from "./components/Preview"
import { Document, Zoom } from "./sharedTypes"
import debounce from "debounce"
import {
  getActiveImageIndex,
  getNextIndex,
  getPreviousIndex,
} from "./utilities/preview"
import * as styles from "./index.module.scss"

type Props = {
  /**
   * Additional classes to pass to the document container.
   */
  className?: string

  /**
   * The documents displayed. See Document type for expected shape
   * of items in array.
   */
  documents: Document[]

  /**
   * Callback fired when Archive action is clicked.
   */
  handleArchive?(document: Document): void

  /**
   * Callback fired when Rotate action is clicked. Used to update
   * individual file inside documents rotation value.
   */
  handleImageRotated?(
    documentId: string,
    imageIndex: number,
    rotationDegrees: number
  ): void

  /**
   * If true, hides document navigation controls. Used to support use
   * cases where only one document is displayed.
   */
  hideDocumentsNav?: boolean

  /**
   * Callback fired when Download action is clicked.
   */
  onDownload?(): void

  /**
   * If true, displays component with new UI.
   * TODO: Remove this prop once all instances can be migrated to the new UI.
   */
  newUi?: boolean
}

const DocumentsBrowser = ({
  className,
  documents,
  handleArchive,
  handleImageRotated,
  hideDocumentsNav = false,
  onDownload,
  newUi = false,
}: Props) => {
  const [index, setIndex] = useState<number>(0)
  const [zoom, setZoom] = useState<number>(Zoom.Min)

  const zoomIn = () => {
    if (zoom < Zoom.Max) {
      setZoom(zoom + Zoom.Delta)
    }
  }

  const zoomOut = () => {
    if (zoom > Zoom.Min) {
      setZoom(zoom - Zoom.Delta)
    }
  }

  const [isRotateHovered, setRotateHover] = useState<boolean>(false)
  const [activeImageIndex, setActiveImageIndex] = useState<number>(0)

  const previous = () => setIndex(getPreviousIndex(index, documents.length))
  const next = () => setIndex(getNextIndex(index, documents.length))

  const onRotateMouseEnter = () => setRotateHover(true)
  const onRotateMouseLeave = () => setRotateHover(false)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onScroll: (node) => void = useCallback(
    debounce((node) => {
      const index = getActiveImageIndex(node)
      setActiveImageIndex(index)
    }, 100),
    []
  )

  const rotate =
    handleImageRotated &&
    (() => {
      handleImageRotated(documents[index].id, activeImageIndex, 90)
    })

  // TODO: Remove once newUi is adopted everywhere
  const hardcodedSwiperId = newUi ? undefined : "sidebar-documents"

  return (
    <div className={styles.documentsBrowser}>
      <Actions
        activeImageIndex={activeImageIndex}
        archive={handleArchive}
        documents={documents}
        documentIndex={index}
        hideDocumentsNav={hideDocumentsNav}
        newUi={newUi}
        next={next}
        onDownload={onDownload}
        onRotateMouseEnter={onRotateMouseEnter}
        onRotateMouseLeave={onRotateMouseLeave}
        previous={previous}
        rotate={rotate}
        zoom={zoom}
        zoomIn={zoomIn}
        zoomOut={zoomOut}
      />
      <Swiper id={hardcodedSwiperId} onScroll={onScroll} className={className}>
        <Preview
          document={documents[index]}
          zoom={zoom}
          activeImageIndex={activeImageIndex}
          isRotateHovered={isRotateHovered}
        />
      </Swiper>
    </div>
  )
}

export default DocumentsBrowser
