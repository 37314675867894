import React from "react"
import Card from "./Card"
import { Document as DocumentsBrowserDocument } from "components/DocumentsBrowser/sharedTypes"
import DocumentsBrowser from "components/DocumentsBrowser"

type Props = {
  documents: DocumentsBrowserDocument[]
}

const DocumentsBrowserCard = (props: Props) => {
  const { documents } = props
  return (
    <Card dropdown={false} title="Document Preview">
      <div className="right-sidebar-body">
        <DocumentsBrowser documents={documents} />
      </div>
    </Card>
  )
}

export default DocumentsBrowserCard
