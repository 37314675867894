import React from "react"
import * as api from "./api"
import { navigate } from "utilities/navigation"
import PasswordForm from "../../components/PasswordForm"

interface Props {
  token: string
}

function ResetPassword({ token }: Props) {
  const updatePassword = (values) => {
    return api.updatePassword(values, token).then(({ redirectTo }) => {
      navigate(redirectTo || "/")
    })
  }

  return (
    <PasswordForm
      header="Reset your password"
      updatePassword={updatePassword}
    />
  )
}

export default ResetPassword
