import React, { useEffect } from "react"
import { trackView } from "./analytics"

interface Props {
  warningType: string
  trackEvents: boolean
  children: React.ReactNode
}
const WarningTracker = ({ warningType, trackEvents, children }: Props) => {
  useEffect(() => {
    if (trackEvents) {
      trackView(warningType)
    }
  }, [trackEvents, warningType])

  return <>{children}</>
}

export default WarningTracker
