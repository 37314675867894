import React from "react"
import StickyFooter from "components/StickyFooter"
import SelfSignAttestation from "components/SelfSignAttestation"
import { Grid } from "@material-ui/core"
import { DenyOrderButton } from "./DenyOrderButton"
import { SelectOption } from "components/form/sharedTypes"
import { ApproveOrderButton } from "./ApproveOrderButton"
import * as styles from "./index.module.scss"

export const Footer = (props: {
  signatureRequestId: string
  doctorId: string
  denialReasons: SelectOption[]
}) => {
  return (
    <StickyFooter>
      <SelfSignAttestation />
      <div className={styles.actions}>
        <Grid container spacing={3} justify="center">
          <Grid item>
            <ApproveOrderButton
              signatureRequestId={props.signatureRequestId}
              doctorId={props.doctorId}
            />
          </Grid>
          <Grid item>
            <DenyOrderButton
              signatureRequestId={props.signatureRequestId}
              denialReasons={props.denialReasons}
              doctorId={props.doctorId}
            />
          </Grid>
        </Grid>
      </div>
    </StickyFooter>
  )
}
