// @ts-strict-ignore
export const convertToOption = (icd10Code) => ({
  value: icd10Code.code,
  label: `${icd10Code.codeLabel} - ${icd10Code.description}`,
  ...icd10Code,
})
const categorize = (icd10Codes) => {
  const primary = []
  const common = []
  const other = []
  icd10Codes.forEach((icd10Code) => {
    const option = convertToOption(icd10Code)
    if (icd10Code.allow) {
      primary.push(option)
    } else if (icd10Code.common) {
      common.push(option)
    } else {
      other.push(option)
    }
  })
  return { primary, common, other }
}

const wrapByCategory = ({ primary, common, other }) => {
  return [
    primary.length && { label: "Primary Diagnoses", options: primary },
    common.length && { label: "Common Diagnoses", options: common },
    other.length && { label: "Other Diagnoses", options: other },
  ].filter(Boolean)
}

export const groupByCategory = (icd10Codes) => {
  return wrapByCategory(categorize(icd10Codes))
}
