import { get, put, post } from "services/api"
import {
  createDoctorContactUrl,
  doctorSearchUrl,
  initialSignatureDataUrl,
  updateDoctorUrl,
  updateSignatureChannelUrl,
  updateSignatureTypeUrl,
} from "applications/Workflow/urls"
import { DoctorContactAttributes } from "./sharedTypes"

export function createDoctorContact(attributes: DoctorContactAttributes) {
  return post(createDoctorContactUrl(), attributes).catch(({ response }) =>
    Promise.reject(response.data.errors)
  )
}

export function doctorSearch({ value }) {
  return get(doctorSearchUrl(), { search: value }).then(
    (response) => response.data
  )
}

export function loadInitialSignatureData() {
  return get(initialSignatureDataUrl())
}

export function selectDoctor(doctorId) {
  return put(updateDoctorUrl(), { doctorId })
}

export function updateSignatureChannel(channel, isSelected) {
  return put(updateSignatureChannelUrl(), { [channel]: isSelected })
}

export function updateSignatureType(signatureType) {
  return put(updateSignatureTypeUrl(), { signatureType })
}
