import { post } from "services/api"

export const transformImage = (
  supplierOrganizationId: string,
  csrInboxStateId: string,
  data: object
): Promise<void> => {
  const pathToCsrInboxState = `/u/r/${supplierOrganizationId}/inbox_documents/${csrInboxStateId}`

  return post(`${pathToCsrInboxState}/transform.json`, data).then(() =>
    Promise.resolve()
  )
}
