import { employerPrefix } from "utilities/url"

const baseContactInformationPath = `${employerPrefix()}/reviewers/user_preferences/contact_information`
export const editContactInformationPath = `${baseContactInformationPath}/edit`
export const updateContactInformationPath = baseContactInformationPath

const baseNotificationPreferencesPath = `${employerPrefix()}/reviewers/user_preferences/notification_preferences`
export const editNotificationPreferencesPath = `${baseNotificationPreferencesPath}/edit`
export const notificationPreferencesPath = baseNotificationPreferencesPath

export const sendVerificationEmailPath =
  "/u/reviewers/user_preferences/email_verifications"
