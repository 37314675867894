import React, { useState } from "react"
import Survey from "applications/Workflow/components/Survey"
import { FormikValues } from "formik"
import { SurveyQuestion, SurveyAnswerType } from "sharedTypes"
import * as t from "../sharedTypes"

type Props = {
  answerQuestion(
    question: SurveyQuestion,
    answerType: SurveyAnswerType,
    answerValue: string
  ): Promise<void>
  rxDetails: t.RxDetails
  onContinue(
    params?: FormikValues
  ): Promise<t.PackageConfiguration> | Promise<void>
}

function RxDetails(props: Props) {
  const [fieldSaving, setFieldSaving] = useState(false)
  const { rxDetails, answerQuestion, onContinue } = props

  return (
    <>
      <Survey
        questions={rxDetails.questions}
        answerQuestion={answerQuestion}
        setFieldSaving={setFieldSaving}
      />
      {rxDetails.isDisqualified && (
        <div
          className="alert alert-danger well well-expand"
          style={{ whiteSpace: "pre-line" }}
        >
          <strong>Invalid entry.</strong> {rxDetails.disqualifiedMessage}
        </div>
      )}
      <div className="row">
        <div className="col-12">
          <button
            type="button"
            onClick={() => onContinue()}
            className="btn btn-brand float-right"
            disabled={!rxDetails.isComplete || fieldSaving}
          >
            Continue
          </button>
        </div>
      </div>
    </>
  )
}

export default RxDetails
