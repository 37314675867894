import { differenceInSeconds } from "utilities/date"
import { isComment } from "utilities/event"
import { Event } from "sharedTypes"

const ONE_MINUTE_IN_SECONDS = 60

export const isRecentComment = (event: Event): boolean => {
  if (!isComment(event)) {
    return false
  }
  const now = new Date().getTime()
  const from = new Date(event.eventTimestamp).getTime()
  return differenceInSeconds(now, from) < ONE_MINUTE_IN_SECONDS
}
