import React, { useState, Dispatch, SetStateAction } from "react"
import { CanopyDialogModal } from "@parachutehealth/canopy-dialog-modal"
import { CanopyButton } from "@parachutehealth/canopy-button"
import { cancelOrder } from "./api"
import { navigate } from "utilities/navigation"
import { employerHomeUrl } from "applications/Workflow/urls"
import { incomingOrdersUrl } from "applications/Workflow/urls"
import { handleError } from "utilities/error"
import { canopyColorTextDanger } from "@parachutehealth/canopy-tokens-color"
import { Originator } from "sharedTypes"
import { useFeatureFlags } from "components/FeatureFlagContext"
import { getEmployerType } from "utilities/url"
import CancelOrderWithReasonModal from "./components/./CancelOrderWithReasonModal"
import OrderActionsDropdownButton from "applications/Workflow/containers/Navigation/components/OrderActionsDropdown/OrderActionsDropdownButton"
import { EventCategory, trackEvent } from "utilities/tracking"

type Props = {
  className?: string
  orderActionMenu?: boolean
  originator: Originator
  setOrderActionsDropdownOpen?: Dispatch<SetStateAction<boolean>>
}

const CancelButton = ({
  className,
  orderActionMenu = false,
  originator,
  setOrderActionsDropdownOpen,
}: Props) => {
  const [cancelling, setCancelling] = useState(false)
  const [cancelOpen, setCancelOpen] = useState(false)
  const [cancelWithReasonOpen, setCancelWithReasonOpen] = useState(false)
  const onClose = () => {
    cancelWithReasonOpen ? closeCancelWithReasonModal() : setCancelOpen(false)
    setOrderActionsDropdownOpen && setOrderActionsDropdownOpen(false)
  }
  const onClick = () =>
    useCancelWithReason ? openCancelWithReasonModal() : setCancelOpen(true)
  const activatorElementRef = React.useRef<HTMLButtonElement>(null)

  const { isFeatureEnabled } = useFeatureFlags()
  const collectFacilityCancelReasonEnabled = isFeatureEnabled(
    "collectFacilityCancelReason"
  )
  const employerType = getEmployerType()
  const facilityUser =
    employerType === "ClinicalFacility" ||
    employerType === "ClinicalOrganization"
  const sgo = originator === Originator.Csr
  const useCancelWithReason =
    collectFacilityCancelReasonEnabled && sgo && facilityUser

  const openCancelWithReasonModal = () => {
    trackEvent(
      EventCategory.FacilityCancellationWithReason,
      "cancel_order_with_reason_modal_opened"
    )
    setCancelWithReasonOpen(true)
  }

  const closeCancelWithReasonModal = () => {
    trackEvent(
      EventCategory.FacilityCancellationWithReason,
      "cancel_order_with_reason_modal_closed"
    )
    setCancelWithReasonOpen(false)
  }

  const onCancel = (cancelReason?: string, cancelComment?: string) =>
    cancelOrder(cancelReason, cancelComment)
      .then(() => {
        if (document.cookie.includes("incoming_orders_workflow_back_path")) {
          navigate(incomingOrdersUrl)
        } else {
          navigate(employerHomeUrl())
        }
      })
      .catch(handleError)

  const handleCancel = (cancelReason?: string, cancelComment?: string) => {
    setCancelling(true)
    onCancel(cancelReason, cancelComment)
      .then(() => {
        onClose()
        setCancelling(false)
      })
      .catch(() => {
        setCancelling(false)
      })
  }

  return (
    <>
      {orderActionMenu ? (
        <OrderActionsDropdownButton
          iconName="xmark"
          title="Cancel&nbsp;order"
          subtitle={
            useCancelWithReason
              ? "End the order and provide a reason"
              : "Delete the order permanently"
          }
          onClick={onClick}
          danger
        />
      ) : (
        <CanopyButton
          fullWidth
          onClick={onClick}
          type="button"
          style={{ color: canopyColorTextDanger }}
          className={className}
          variant="tertiary"
          loading={cancelling}
          ref={activatorElementRef}
        >
          Cancel Order
        </CanopyButton>
      )}

      <CanopyDialogModal
        activatorElementRef={activatorElementRef}
        onClose={onClose}
        open={cancelOpen}
        header="Cancel Order"
        primaryFooterButton={
          <CanopyButton onClick={() => handleCancel()} variant="danger">
            Confirm order cancellation
          </CanopyButton>
        }
        secondaryFooterButton={
          <CanopyButton onClick={onClose} variant="secondary">
            No, take me back
          </CanopyButton>
        }
        size="small"
      >
        Are you sure you want to cancel this order?
      </CanopyDialogModal>
      <CancelOrderWithReasonModal
        handleCancel={handleCancel}
        onClose={onClose}
        isOpen={cancelWithReasonOpen}
      />
    </>
  )
}

export default CancelButton
