import { isMobile, isTablet } from "react-device-detect"
import { SidebarTab } from "../containers/Navigation/sharedTypes"
import { theme } from "themes/theme"

export const isSidebarFullScreen = (): boolean =>
  window.innerWidth < theme.breakpoints.values.sm

export const isSidebarOpenWithWorkflowVisible = (
  sidebarTab: SidebarTab
): boolean => {
  if (sidebarTab === SidebarTab.Closed) {
    return false
  }
  const isDesktopDevice = !(isMobile || isTablet)
  return isDesktopDevice && !isSidebarFullScreen()
}
