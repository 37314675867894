import React from "react"
import Suggestion from "components/form/Select/Suggestion"
import SearchSuggestionPrice from "./SearchSuggestionPrice"
import { formatSearchResults } from "../../../../utilities/skuSearch"
import { productSuggestionTemplate } from "../utils"
import { SuggestionOption } from "../../sharedTypes"

type Props = {
  suggestion: SuggestionOption
  isHighlighted: boolean
  query: string
  formularyPriceEnabled: boolean
  isInHybridSearch: boolean
}

const SearchResult: React.FunctionComponent<Props> = ({
  suggestion,
  isHighlighted,
  query,
  formularyPriceEnabled,
  isInHybridSearch,
}) => {
  const productSuggestionMobileClasses = () => {
    if (formularyPriceEnabled && isInHybridSearch) {
      return "col-9 col-xl-6"
    } else if (formularyPriceEnabled || isInHybridSearch) {
      return "col-9"
    } else {
      return "col-12"
    }
  }
  return (
    <Suggestion key={suggestion.value} isHighlighted={isHighlighted}>
      <div className="row canopy-mis-0 canopy-mie-0">
        <div className={productSuggestionMobileClasses()}>
          {formatSearchResults(productSuggestionTemplate(suggestion), query)}
        </div>
        {formularyPriceEnabled ? (
          <div className="col-3 text-right">
            <SearchSuggestionPrice
              price={suggestion.price}
              formularyPriceEnabled={formularyPriceEnabled}
            />
          </div>
        ) : null}
        {isInHybridSearch ? (
          <div className="col col-xl-3 text-xl-right">
            <span className="color-primary bold">
              {suggestion.simple ? "Add to cart" : "Configure"}
            </span>
          </div>
        ) : null}
      </div>
    </Suggestion>
  )
}

export default SearchResult
