const navBarHeight = 57
const orderPageContainerMargin = 24
const pageHeaderHeight = 52
const subHeaderHeight = 36
const pushHeight = 50

const baseHeight = () =>
  navBarHeight +
  orderPageContainerMargin +
  pageHeaderHeight +
  subHeaderHeight +
  pushHeight

export const noOrdersContainerHeightCalculation = (): string =>
  `calc(100vh - ${baseHeight()}px )`
