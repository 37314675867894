import * as React from "react"
import { GridRowId } from "@mui/x-data-grid-pro"
import { useEffect, useState } from "react"
import pluralize from "pluralize"

type Props = {
  initiallySelectedRows: GridRowId[]
  selectedSalesRepName: string
  selectedRows: GridRowId[]
}
const ReassignSalesRepsEditMessage: React.FC<Props> = ({
  selectedRows,
  selectedSalesRepName,
  initiallySelectedRows,
}: Props) => {
  const [addedRowsCount, setAddedRows] = useState(0)
  const [removedRowsCount, setRemovedRows] = useState(0)

  useEffect(() => {
    setAddedRows(
      selectedRows.filter((row) => !initiallySelectedRows.includes(row)).length
    )
    setRemovedRows(
      initiallySelectedRows.filter((row) => !selectedRows.includes(row)).length
    )
  }, [selectedRows, initiallySelectedRows])

  return (
    <>
      {selectedSalesRepName && (removedRowsCount > 0 || addedRowsCount > 0) && (
        <div className="well well-info">
          <span>{selectedSalesRepName} will be </span>
          {removedRowsCount > 0 && (
            <span className="bold">
              unassigned from {removedRowsCount}{" "}
              {pluralize("facility", removedRowsCount)}
            </span>
          )}
          {removedRowsCount > 0 && addedRowsCount > 0 && <span> and </span>}
          {addedRowsCount > 0 && (
            <span className="bold">
              assigned to {addedRowsCount}{" "}
              {pluralize("facility", addedRowsCount)}
            </span>
          )}
          <span>.</span>
        </div>
      )}
    </>
  )
}

export default ReassignSalesRepsEditMessage
