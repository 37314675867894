import React, { useContext } from "react"
import { Nullable } from "sharedTypes"
import { numberToCurrency } from "utilities/price"
import GlobalContext from "context/Global"

interface Props {
  price: Nullable<string>
  formularyPriceEnabled: boolean
}

const MISSING_PRICE_COPY = "Price Unknown"

const SearchSuggestionPrice: React.FC<Props> = ({
  price,
  formularyPriceEnabled,
}) => {
  const { featureFlags } = useContext(GlobalContext)

  if (!featureFlags.formularyPricing) return null
  if (!formularyPriceEnabled) return null

  return <>{numberToCurrency(price) || MISSING_PRICE_COPY}</>
}

export default SearchSuggestionPrice
