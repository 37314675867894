import React from "react"
import { DmeOrder, SignatureStatus } from "sharedTypes"
import ActionButton from "./ActionButton"
import ActionButtonWithConfirm from "./ActionButtonWithConfirm"

type Props = {
  className: string
  disabled?: boolean
  dmeOrder: DmeOrder
  onClick(): Promise<void>
  renderButtonAsLink?: boolean
}

function SendSignatureRequestButton({
  className,
  disabled,
  dmeOrder,
  onClick,
  renderButtonAsLink,
}: Props) {
  const { signatureStatus } = dmeOrder
  const buttonText = "Schedule Signature Request"

  const renderLink = () => {
    return (
      <div className="canopy-mt-6x">
        <a onClick={onClick} className="border-left-0 btn-link color-primary">
          {buttonText}
        </a>
      </div>
    )
  }

  if (signatureStatus === SignatureStatus.Completed) {
    return (
      <ActionButtonWithConfirm
        buttonText={buttonText}
        cancelText="Cancel"
        className={className}
        confirmText="Yes, send"
        confirmTitle="This order is already signed"
        confirmDescription="Are you sure you want to send for signature?"
        disabled={disabled}
        onClick={onClick}
      />
    )
  }

  return renderButtonAsLink ? (
    renderLink()
  ) : (
    <ActionButton
      type="submit"
      className={className}
      disabled={disabled}
      onClick={onClick}
    >
      {buttonText}
    </ActionButton>
  )
}

export default SendSignatureRequestButton
