import React, { useCallback, useEffect, useState } from "react"
import classNames from "classnames"
import { getKeyCode } from "utilities/browser/event"
import { Keycode } from "enums/keycodes"

interface Props {
  pages: React.ReactNode[]
  completionText: string
  onComplete: () => void
  onStepChange?: (step: number) => void
}

export default ({ pages, completionText, onComplete, onStepChange }: Props) => {
  const [pageIndex, setPageIndex] = useState(0)

  const nextPage = useCallback(() => {
    setPageIndex((pageIndex) => {
      if (pageIndex === pages.length - 1) {
        return pageIndex
      }
      return pageIndex + 1
    })
  }, [pages.length])

  const onLastStep = pageIndex === pages.length - 1

  const handleKeyUp = useCallback(
    (event) => {
      const keyCode = getKeyCode(event)
      switch (keyCode) {
        case Keycode.ArrowLeft:
          prevPage()
          break
        case Keycode.ArrowRight:
          nextPage()
          break
        case Keycode.Enter:
          if (onLastStep) {
            onComplete()
          } else {
            nextPage()
          }
          break
      }
    },
    [nextPage, onComplete, onLastStep]
  )

  useEffect(() => {
    onStepChange && onStepChange(pageIndex)
    document.addEventListener("keyup", handleKeyUp, true)
    return () => {
      document.removeEventListener("keyup", handleKeyUp, true)
    }
  }, [handleKeyUp, onStepChange, pageIndex])

  const prevPage = () => {
    setPageIndex((pageIndex) => {
      if (pageIndex === 0) {
        return pageIndex
      }
      return pageIndex - 1
    })
  }

  return (
    <div className="stepper">
      <div className="text-center canopy-mbe-12x">{pages[pageIndex]}</div>
      <div className="text-center canopy-mbe-12x">
        <button
          className="btn btn-brand-o canopy-mie-8x"
          disabled={pageIndex === 0}
          onClick={prevPage}
        >
          Back
        </button>
        {!onLastStep && (
          <button className="btn btn-brand" onClick={nextPage}>
            Next
          </button>
        )}
        {onLastStep && (
          <button className="btn btn-brand" onClick={onComplete}>
            {completionText}
          </button>
        )}
      </div>
      <div className="d-flex justify-content-center align-items-center">
        {pages.map((_, index) => (
          <div
            key={index}
            className={classNames("dot canopy-mie-4x", {
              active: index === pageIndex,
            })}
          />
        ))}
      </div>
    </div>
  )
}
