import React, { useState, useCallback, ReactElement } from "react"
import debounce from "awesome-debounce-promise"
import InternalCarrierAuthorization from "./components/InternalCarrierAuthorization"
import { updateCarrierAuthorization } from "./api"
import {
  Context,
  CarrierAuthorization as CarrierAuthorizationType,
} from "sharedTypes"

type Props = {
  context: Context
  initialCarrierAuthorization: CarrierAuthorizationType
}

const CarrierAuthorization = ({
  context,
  initialCarrierAuthorization,
}: Props): ReactElement => {
  const [carrierAuthorization, setCarrierAuthorization] = useState<
    CarrierAuthorizationType
  >(initialCarrierAuthorization)

  const update = useCallback(
    (params) =>
      updateCarrierAuthorization(carrierAuthorization.id, params).then(
        (response) => {
          setCarrierAuthorization(response.data)
        }
      ),
    [carrierAuthorization]
  )

  const debouncedUpdate = useCallback(() => debounce(update, 200), [update])

  return (
    <InternalCarrierAuthorization
      carrierAuthorization={carrierAuthorization}
      context={context}
      updateCarrierAuthorization={debouncedUpdate()}
    />
  )
}

export default CarrierAuthorization
