// @ts-strict-ignore
export default class Matomo {
  options = null

  constructor(opts) {
    const options = opts

    options.enableLinkTracking =
      options.enableLinkTracking !== undefined
        ? options.enableLinkTracking
        : true
    options.jsFilename =
      options.jsFilename !== undefined ? options.jsFilename : "piwik.js"
    options.phpFilename =
      options.phpFilename !== undefined ? options.phpFilename : "piwik.php"

    this.options = options

    if (this.options.url === undefined || this.options.siteId === undefined) {
      throw new Error(
        "MatomoTracker cannot be initialized! SiteId and url are mandatory."
      )
    }

    this.init()
  }

  init() {
    if (typeof window !== "undefined") {
      let url = this.options.url

      if (url.indexOf("http://") !== -1 || url.indexOf("https://") !== -1) {
        url = `${url}/`
      } else {
        url =
          document.location.protocol === "https:"
            ? `https://${url}/`
            : `http://${url}/`
      }

      window._paq = window._paq || []

      Matomo.push(["setSiteId", this.options.siteId])
      Matomo.push(["setTrackerUrl", `${url + this.options.phpFilename}`])

      if (this.options.enableLinkTracking) {
        Matomo.push(["enableLinkTracking"])
      }

      const scriptElement = document.createElement("script")
      const refElement = document.getElementsByTagName("script")[0]

      scriptElement.type = "text/javascript"
      scriptElement.defer = true
      scriptElement.async = true

      let jsFilename = this.options.jsFilename

      if (
        jsFilename.indexOf("http://") !== 0 &&
        jsFilename.indexOf("https://") !== 0
      ) {
        jsFilename = url + jsFilename
      }

      scriptElement.src = jsFilename
      refElement.parentNode.insertBefore(scriptElement, refElement)
    }
  }

  static push(args) {
    if (window._paq !== undefined) {
      window._paq.push(args)
    }
  }
}

const safeGlobalThisUser =
  typeof globalThis !== "undefined"
    ? globalThis?.parachuteSsrApp?.currentUserId
    : undefined

export const initMatomo = () => {
  if (!window.matomo || !window.matomo.serverUrl) {
    return
  }
  new Matomo({
    url: window.matomo.serverUrl,
    siteId: window.matomo.siteId,
  })

  const userId = window.parachute.currentUserId || safeGlobalThisUser

  if (userId) {
    Matomo.push(["setUserId", userId])
  }

  if (window.matomo.analyticsPageTitle) {
    Matomo.push(["setDocumentTitle", window.matomo.analyticsPageTitle])
  }
  if (
    window.matomo.externalVisitIdCustomDimensionId &&
    window.matomo.externalVisitId
  ) {
    Matomo.push([
      "setCustomDimension",
      window.matomo.externalVisitIdCustomDimensionId,
      window.matomo.externalVisitId,
    ])
  }
  Matomo.push(["trackPageView"])
  Matomo.push(["enableHeartBeatTimer"])
}
