import React, { useEffect, useState } from "react"
import TableCell from "./TableCell"
import { Checkbox } from "@material-ui/core"
import classNames from "classnames"
import * as styles from "./table.module.scss"
import TableRow from "@material-ui/core/TableRow"
import MaterialTableCell from "@material-ui/core/TableCell"
import MaterialTable from "@material-ui/core/Table"
import MaterialTableBody from "@material-ui/core/TableBody"
import MaterialTableHead from "@material-ui/core/TableHead"
import MaterialTableContainer from "@material-ui/core/TableContainer"
import { MaterialThemeProvider, useStyles } from "themes/theme"

export interface TableColumnDefinition<T extends { key: string }> {
  title: string
  attr: string
  key?: string
  render?: (record: T) => JSX.Element | string
  headerRender?: () => JSX.Element
}

interface Props<T> {
  records: T[]
  tableColumns: TableColumnDefinition<T & { key: string }>[]
  selectable?: boolean
  selectedRecordKeys?: string[]
  onSelect?: (keys: string[]) => void
  maxHeight?: string
  truncate?: boolean
  overrideHeaderPointerEvents?: string
}

const muiStyles = (overrideHeaderPointerEvents) => ({
  collapsedBorderTable: {
    "&.MuiTable-stickyHeader": {
      borderCollapse: "collapse",
    },
  },
  tableHeaderRow: {
    pointerEvents: overrideHeaderPointerEvents
      ? overrideHeaderPointerEvents
      : "none",
  },
})

const Table = <T extends { key: string }>({
  records,
  tableColumns,
  selectable,
  selectedRecordKeys,
  onSelect,
  maxHeight,
  truncate = true,
  overrideHeaderPointerEvents,
}: Props<T>) => {
  const [selectedRows, setSelectedRows] = useState<{ [key: string]: boolean }>(
    selectedRecordKeys?.reduce((acc, key) => ({ ...acc, [key]: true }), {}) ||
      {}
  )

  const muiClasses = useStyles(muiStyles(overrideHeaderPointerEvents))

  useEffect(() => {
    if (onSelect)
      onSelect(
        Object.entries(selectedRows)
          .filter(([_, selected]) => selected)
          .map(([key]) => key)
      )
  }, [selectedRows, onSelect])

  const renderTableRow = (record: T) => {
    const selected = !!selectedRows[record.key]
    const onSelect = (event) => {
      const isChecked = event.target.checked
      setSelectedRows((prevState) => ({
        ...prevState,
        [record.key]: isChecked,
      }))
    }
    return (
      <TableRow key={record.key}>
        {selectable && (
          <MaterialTableCell
            key={`${record.key}-checkbox`}
            className={classNames(styles.td, {
              selected,
            })}
          >
            <Checkbox
              size="small"
              color="primary"
              checked={selected}
              onChange={onSelect}
            />
          </MaterialTableCell>
        )}
        {tableColumns.map(({ attr, render }) => (
          <TableCell
            key={`${record.key}-${attr}`}
            record={record}
            attr={attr}
            render={render}
            selected={selected}
            truncate={truncate}
          />
        ))}
      </TableRow>
    )
  }

  return (
    <MaterialThemeProvider>
      <div className="table-wrapper">
        <div className="table-container">
          <MaterialTableContainer
            style={{ maxHeight: maxHeight || "calc(100vh - 300px)" }}
          >
            <MaterialTable
              stickyHeader
              aria-label="sticky table"
              className={classNames(
                "table table-striped table-hover table-sortable table-expanded table-dashboard",
                muiClasses.collapsedBorderTable
              )}
            >
              <MaterialTableHead>
                <TableRow>
                  {selectable && (
                    <MaterialTableCell key="select-all-checkbox">
                      <Checkbox
                        size="small"
                        color="primary"
                        checked={
                          Object.values(selectedRows).filter((a) => a)
                            .length === records.length
                        }
                        onChange={() => {
                          setSelectedRows((prevState) => {
                            if (
                              Object.values(prevState).filter((a) => a)
                                .length === records.length
                            )
                              return {}
                            return records.reduce(
                              (acc, record) => ({ ...acc, [record.key]: true }),
                              {}
                            )
                          })
                        }}
                        data-testid="select-all-checkbox"
                      />
                    </MaterialTableCell>
                  )}
                  {tableColumns.map((column) => (
                    <MaterialTableCell
                      key={column.key || column.title}
                      className={muiClasses.tableHeaderRow}
                    >
                      {column.headerRender
                        ? column.headerRender()
                        : column.title}
                    </MaterialTableCell>
                  ))}
                </TableRow>
              </MaterialTableHead>
              <MaterialTableBody>
                {records.map(renderTableRow)}
              </MaterialTableBody>
            </MaterialTable>
          </MaterialTableContainer>
        </div>
      </div>
    </MaterialThemeProvider>
  )
}

export default Table
