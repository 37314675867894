// @ts-strict-ignore
import React from "react"
import {
  Severity,
  DmeOrderWarningSeverity,
  DiagnosisWarning,
} from "sharedTypes"
import Note from "components/Note"
import { toSentence } from "utilities/array"
import { formatCode } from "utilities/icd10Code"

type Props = {
  item: DiagnosisWarning
}

function Component({ item }: Props) {
  const { warnings } = item
  const error = warnings.find(
    (warning) => warning.severity === DmeOrderWarningSeverity.Error
  )
  if (error) {
    return (
      <Note type={Severity.Danger}>
        <strong>{item.name}</strong>
        <br />
        <small>
          Cannot be justified for patients with diagnosis of{" "}
          {toSentence(error.codes.map(formatCode), "or")}.
        </small>
      </Note>
    )
  }
  const warning = warnings.find(
    (warning) => warning.severity === DmeOrderWarningSeverity.Warning
  )
  if (warning) {
    return (
      <Note type={Severity.Warning}>
        <strong>{item.name}</strong>
      </Note>
    )
  }
  return (
    <Note type={Severity.Success}>
      <strong>{item.name}</strong>
    </Note>
  )
}

export default Component
